import { inject, injectable, interfaces } from "inversify";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { MainLayout } from "../../views/main-layout";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import { MojProfilOdjavaView } from "../../views/moj-profil-odjava-view";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";

@injectable()
export class MojProfilOdjavaPage extends PageBase {
    public name = "MojProfilOdjavaPage";
    private _router: Router;
    private _mojProfilOdjavaView: MojProfilOdjavaView;
    private _authenticator: Authenticator;

    constructor(@inject("Provider<MainLayout>") private _mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("Router") router: Router,
        @inject("Authenticator") authenticator: Authenticator)
    {
        super();

        this._router = router;
        this._authenticator = authenticator;
        this._mojProfilOdjavaView = new MojProfilOdjavaView(this._authenticator);
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        if (!url.pathname.startsWith("/odjava-moj-profil")) {
            return true;
        }
        var mainLayout = await this._mainLayoutProvider() as MainLayout;
        try {
            mainLayout.hideFooter();
            this._mojProfilOdjavaView.load();
        } catch (e) {
            mainLayout.errorView.report(e);
        }
        finally {
            
        }
        return false;
    }
}