import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { TranslationService } from "../ts/translation-service";
import template from "./merilno-mesto-dodaj-avtorizacijska-koda-view.html";
import { MerilnoMestoDodajIzbiraView } from "./merilno-mesto-dodaj-izbira-view";
import "jquery-validation-unobtrusive";
import { FullscreenLoader } from "./fullscreen-loader";

@injectable()
export class MerilnoMestoDodajAvtorizacijskaKodaView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _formElement: JQuery<HTMLElement> | null;
    private _btnAddMerilnoMestoElement: JQuery<HTMLElement> | null;
    private _izbranUporabnikId: number | undefined;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        merilnoMestoApiClient: MerilnoMestoApiClient,
        private _notyf: Notyf,
        private _loader: FullscreenLoader)
    {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._formElement = null;
        this._btnAddMerilnoMestoElement = null;
        this._izbranUporabnikId = undefined;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var url = window.location.href.split("/");
            var izbranUporabnikId = Number(url[url.length - 1]);
            if (!isNaN(izbranUporabnikId)) {
                this._izbranUporabnikId = izbranUporabnikId;
            }
            this._renderData();
            if ($("#page-loaded")[0] == undefined) return;
            this._initForm();
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(): void {
        const viewModel = {
            translations: this._translationService.currentTranslations
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _initForm(): void {
        this._formElement = $(("#add-merilno-mesto"));
        $.validator.unobtrusive.parse(this._formElement);
        this._formElement.validate();
        this._btnAddMerilnoMestoElement = $(this._formElement).find("#btnAddMerilnoMesto");
        if (this._btnAddMerilnoMestoElement == null) { return; }
        this._btnAddMerilnoMestoElement.on("click", (ev: Event) => this._onBtnAddMerilnoMestoClick(ev, this._formElement));
    }

    private async _onBtnAddMerilnoMestoClick(ev: Event, formElement: any)
    {
        console.debug(ev);
        ev.preventDefault();
        if (!formElement.valid()) {
            this._notyf.error(this._translationService.currentTranslations["EnterTheActivationCode"] + ".");
            return;
        }
        this._loader.show();
        var formData = new FormData(formElement[0] as HTMLFormElement);
        var activationCode = formData.get("activationCode") as string;
        if (activationCode) {
            await this._merilnoMestoApiClient.getMerilnoMestoByCode(activationCode)
                .then((odgovor: any) => { 
                    if (odgovor.status) {
                        var merilnaMestaSeznam = odgovor.merilnaMesta;
                        var view: MerilnoMestoDodajIzbiraView = new MerilnoMestoDodajIzbiraView(this.rootElement,
                            this._translationService, this._merilnoMestoApiClient, merilnaMestaSeznam, this._notyf, this._loader, this._izbranUporabnikId);
                        view.load();
                        this._loader.hide();
                    }
                    else {
                        this._notyf.error(odgovor.sporocilo);
                        this._loader.hide();
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileDisplayingTheMeasuringConsumptionPoints"]);
                    this._loader.hide();
                }); 
        }
    }
}