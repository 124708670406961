import { inject, injectable } from "inversify";
import { Authenticator } from "../../../skupno/src/ts/authentication/authenticator";
import { ErrorConsoleReporter } from "../../../skupno/src/ts/error-handling/error-console-reporter";
import { Router } from "../../../skupno/src/ts/routing/router";
import { HtmlStorageHelper } from "../../../skupno/src/ts/utils/html-storage-helper";
import { TranslationService } from "../ts/translation-service";
import { CurrentUserAccessor } from "../ts/utilities/current-user-accessor";
import { SiteMapManager } from "../ts/utilities/sitemap-manager";
import { ErrorView } from "./error-view";
import { FooterView } from "./footer-view";
import { RazrezNavigationView } from "./razrez-navigation-view";
import { HeaderView } from "./header-view";
import { KontaktiInPomocApiClient } from "../ts/clients/kontakti-in-pomoc-api-client";
import { Notyf } from "notyf";
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { UserApiClient } from "../ts/clients/users-api-client";

/*
 * Opisuje DOM dokument v katerem teče aplikacija.
 */
@injectable()
export class MainLayout { 
	private _headElement: Element;
	private _htmlTitleElement: Element | null;
	private _razrezNavigationView: RazrezNavigationView;
	private _errorView: ErrorView;
	private _authenticator: Authenticator;
	private _router: Router;
    private _headerView: HeaderView;
	private _footerView: FooterView;
	private _translationService: TranslationService;
	private _kontaktiInPomocApiClient: KontaktiInPomocApiClient;
	private _userApiClient: UserApiClient;
	private _merilnoMestoApiClient: MerilnoMestoApiClient;
	private _notyf: Notyf;
	
	get htmlTitle(): string | null {
		if (this._htmlTitleElement != null) {
			return this._htmlTitleElement.innerHTML;
		}
		return null;
	}

	set htmlTitle(value: string | null) {
		if (this._htmlTitleElement != null) {
			this._htmlTitleElement.innerHTML = value || "";
		}
	}

	get errorView(): ErrorView {
		return this._errorView;
	}

	public constructor(@inject("Document") document: Document,
		@inject("Authenticator") authenticator: Authenticator,
		@inject("Router") router: Router,
		@inject("SiteMapManager") _siteMapManager: SiteMapManager,
		@inject("CurrentUserAccessor") _currentUserAccessor: CurrentUserAccessor,
		@inject("TranslationService") translationService: TranslationService,
		@inject("KontaktiInPomocApiClient") kontaktiInPomocApiClient: KontaktiInPomocApiClient,
		@inject("UserApiClient") userApiClient: UserApiClient,
		@inject("MerilnoMestoApiClient") merilnoMestoApiClient: MerilnoMestoApiClient,
		@inject("Notyf") notyf: Notyf,
	) {
		this._authenticator = authenticator;
		this._router = router;
		this._translationService = translationService;
		this._kontaktiInPomocApiClient = kontaktiInPomocApiClient;
		this._userApiClient = userApiClient;
		this._merilnoMestoApiClient = merilnoMestoApiClient;
		this._notyf = notyf;
		this._errorView = new ErrorView();
		this._setupCallbacks();
		this._headerView = new HeaderView(_siteMapManager, _currentUserAccessor, this._translationService,
			this._kontaktiInPomocApiClient, this._userApiClient, this._notyf, $("#header"));
		this._footerView = new FooterView($("#footer"), this._merilnoMestoApiClient, this._userApiClient);		

		this._razrezNavigationView = new RazrezNavigationView();

		this._headElement = document.getElementsByTagName("head")[0] || null;
		if (this._headElement != null) {
			this._htmlTitleElement = this._headElement.getElementsByTagName("title")[0] || null;
		}
		else {
			this._htmlTitleElement = null;
		}

		try {
			this._razrezNavigationView.load();
			this._errorView.load();


		} catch (e) {

			// Report failures
			this._errorView!.report(e);
		}
	}	

	public async initialize(): Promise<void> {
		this._errorView.load();
		await this._headerView.load();
		await this._footerView.load();
		// await this._dashboard.load();
	}

	public showFooter() {
		$("#footer").show();
    }
	public hideFooter() {
		$("#footer").hide();
	}
	public showHeader() {
		this._headerView.showHeader();
	}
	public hideHeader() {
		this._headerView.hideHeader();
    }
	/*
	 * The home button moves to the home view but also deals with error recovery
	 */
	private async _onHome(): Promise<void> {
		//document.location = "#/";
	}


	/*
	 * This method is for testing only, to make the access token in storage act like it has expired
	 */
	public async expireAccessToken(): Promise<void> {

		const user = await this._authenticator._userManager.getUser();
		if (user) {

			// Add a character to the signature to make it fail validation
			user.access_token = `${user.access_token}x`;
			this._authenticator._userManager.storeUser(user);
		}
	}

	/*
	 * Force both API calls when reload is clicked
	 */
	private async _onReloadData(): Promise<void> {
		this._router.refresh();
	}

	/*
	 * Perform a logout request
	 */
	private async _onLogout(): Promise<void> {

		try {

			// Start the logout redirect
			await this._authenticator!.startLogout();

		} catch (e) {

			// On error, only output logout errors to the console, then move to the logged out view
			ErrorConsoleReporter.output(e);
			location.hash = '#loggedout';
		}
	}

	/*
	 * Handle logout notifications from other browser tabs
	 */
	private _onStorageChange(event: StorageEvent): void {

		if (HtmlStorageHelper.isMultiTabLogoutEvent(event)) {

			this._authenticator!.onExternalLogout();
			location.hash = '#loggedout';
		}
	}

	/*
	 * Force a new access token to be retrieved
	 */
	private async _onExpireToken(): Promise<void> {
		await this.expireAccessToken();
	}


	/*
	* Plumbing to ensure that the this parameter is available in async callbacks
	*/
	private _setupCallbacks(): void {
		this._onHome = this._onHome.bind(this);
		this._onReloadData = this._onReloadData.bind(this);
		this._onLogout = this._onLogout.bind(this);
		this._onStorageChange = this._onStorageChange.bind(this);
		this._onExpireToken = this._onExpireToken.bind(this);
	}
}