import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { TranslationService } from "../ts/translation-service";
import template from "./novice-pregled-view.html";
import { NoviceApiClient } from "../ts/clients/novice-api-client";
import { Novica } from "../ts/models/novica";
import * as moment from "moment";

@injectable()
export class NovicePregledView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _noviceApiClient: NoviceApiClient;
    private _formators: any;
    private _novice: Array<Novica>;
    private _izbranaNovica: Novica | null;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        noviceApiClient: NoviceApiClient,
        private _notyf: Notyf)
    {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._noviceApiClient = noviceApiClient;
        this._formators = null;
        this._novice = new Array<Novica>();
        this._izbranaNovica = null;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            await this._noviceApiClient.getNovice()
                .then((odgovor: any) => {
                    this._novice = odgovor;
                    if (this._novice.length == 0) {
                        this.rootElement.text(this._translationService.currentTranslations["NewsListIsEmpty"]);
                        return;
                    }
                    // poiscemo ali obstaja selektirana novica (v querystringu)
                    var url = window.location.href.split("/");
                    var izbranaNovica = url[url.length - 1];
                    for (let i = 0; i < this._novice.length; i++) {
                        if (this._novice[i].id.toString() == izbranaNovica) {
                            this._izbranaNovica = this._novice[i];
                        }
                    }
                    if (!this._izbranaNovica) {
                        this._izbranaNovica = this._novice[0];
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    return;
                });

            var translations = this._translationService.currentTranslations;
            this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;
            this._renderNovice(this._novice, 1);
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(translations: any): void {
        var localeFormat = moment().creationData().locale.longDateFormat("L");
        this._formators = {
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            }//tukaj se lahko dodajajo formatorji
        };

        const viewModel = {
            translations: translations,
        } as any;
        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _renderNovice(novice: Array<Novica>, page: number) {
        var prikazaneNovice = 7;
        var displayedNovice = new Array<Novica>();
        for (let i = 0; i < prikazaneNovice; i++) {
            if (novice.length <= (page - 1) * prikazaneNovice + i) {
                break;
            }
            displayedNovice.push(novice[(page - 1) * prikazaneNovice + i]);
        }
        var maxPages = Math.ceil(novice.length / prikazaneNovice);
        const templateString = `
                <%#novice%>
                    <a href="#podrobnosti" role="button">
                        <div class="tile-a h-auto btnPodrobnosti">
                            <div>            
                                <p class="margin-xsmall"><%#formators%><%#dateFormat%><%datumNovice%><%/dateFormat%><%/formators%> <%vsebina.kreiranoUra%></p>
                                <h3 class="margin-small"><%{naslov}%></h3>
                            </div>
                            <div class="tile-icon-bar">
                                <div class="circle white">
                                    <img src="../img/icon-message-gray.svg" class="ti">
                                </div>
                            </div>
                        </div>
                    </a>
                <%/novice%>
                <%#obstajaNovica%>
                    <div class="pagination">
                        <%#obstajaPrejsnjaStran%>
                        <div>
                            <a class="btnPreviousPage">&lt</a>
                        </div>
                        <%/obstajaPrejsnjaStran%>
                        <div class="pages"><%{page}%>/<%{maxPages}%></div>
                        <%#obstajaNaslednjaStran%>
                        <div>
                            <a class="btnNextPage">&gt</a>
                        </div>
                    <%/obstajaNaslednjaStran%>
                <%/obstajaNovica%>
            </div>
             `;
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "obstajaNovica": displayedNovice.length > 0 ? true : false,
                "page": page,
                "maxPages": maxPages,
                "obstajaPrejsnjaStran": page == 1 ? false : true,
                "obstajaNaslednjaStran": page == maxPages ? false : true,
                "novice": displayedNovice,
                "translations": this._translationService.currentTranslations,
                "formators": this._formators
            },
            undefined,
            customTags
        );
        $("#novice-seznam").html(html);

        var btnPreviousPage = $(".btnPreviousPage");
        if (btnPreviousPage.length != 0) {
            btnPreviousPage[0].addEventListener("click", () => this._renderNovice(novice, page - 1))
        }
        var btnNextPage = $(".btnNextPage");
        if (btnNextPage.length != 0) {
            btnNextPage[0].addEventListener("click", () => this._renderNovice(novice, page + 1))
        }

        if (displayedNovice.length > 0) {
            var buttonsPodrobnosti = $(".btnPodrobnosti");
            for (let i = 0; i < buttonsPodrobnosti.length; i++) {
                buttonsPodrobnosti[i].addEventListener("click", (ev: Event) => this._onBtnPodrobnostiClick(ev, displayedNovice[i]));
            }
            if (!this._izbranaNovica) {
                this._izbranaNovica = displayedNovice[0];
            }
            this._refreshPodrobnosti();
        }
    }

    private _onBtnPodrobnostiClick(ev: Event, novica: Novica) {
        console.debug(ev);
        ev.preventDefault();
        this._izbranaNovica = novica;
        this._refreshPodrobnosti();
        var podrobnostiElement = $("#podrobnosti");
        if (podrobnostiElement && podrobnostiElement.length > 0) {
            const yOffset = -100;
            const y = podrobnostiElement[0].getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    } 

    private _refreshPodrobnosti() {
        const templateString = `
                <%#obstajaNovicaPodrobnosti%>
                <div class="white-frame border">
                    <div class="news-tile">
                        <div class="image-wrapper">
                            <img src="<%{novicaPodrobnosti.slikaDesktop}%>" alt="" class="image-cover">
                        </div>
                        <div class="tile-padding">
                            <div class="meta-row-2">
                                <div><%#formators%><%#dateFormat%><%novicaPodrobnosti.datumNovice%><%/dateFormat%><%/formators%></div>
                            </div>
                            <h3><%{novicaPodrobnosti.naslov}%></h3>
                            <br></br>
                            <%{novicaPodrobnosti.kratkaVsebina}%>
                        </div>
                    </div>
                </div>
                <%/obstajaNovicaPodrobnosti%>
             `;
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "novicaPodrobnosti": this._izbranaNovica,
                "obstajaNovicaPodrobnosti": this._izbranaNovica ? true : false,
                "translations": this._translationService.currentTranslations,
                "formators": this._formators
            },
            undefined,
            customTags
        );
        $("#novice-podrobnosti").html(html);
    }
}
