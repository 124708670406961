import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";

@injectable()
export class PiskotkiApiClient extends ApiClientBase {
    public name = "PiskotkiApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator") authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async getPiskotki(): Promise<any> {
        return await this._callApi('/piskotki/get-piskotki', 'GET', null, false) as any;
    }
} 
