import { injectable } from "inversify";
import * as Mustache from "mustache";
import { TranslationService } from "../ts/translation-service";
import template from "./merilno-mesto-odstrani-povratna-informacija-view.html";

@injectable()
export class MerilnoMestoOdstraniPovratnaInformacijaView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;

    constructor(rootElement: JQuery<HTMLElement>, translationService: TranslationService)
    {
        this.rootElement = rootElement;
        this._translationService = translationService;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            this._renderData();
            if ($("#page-loaded")[0] == undefined) return;
            window.scrollTo(0, 0);
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(): void {
        const viewModel = {
            translations: this._translationService.currentTranslations
        } as any;
        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }
}