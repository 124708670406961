import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { CurrentUserInfo } from "../models/current-user-info";
import { NotificationHeaderResponse } from "../models/notification-header-response";
import { ProfilSpremeniPodatke } from "../models/profil-spremeni-podatke";
import { NastavitveZaObvescanjeModel } from "../models/user-nastavitve-za-obvescanje";

@injectable()
export class UserApiClient extends ApiClientBase {
    public name = "UserApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator")  authenticator: Authenticator) {
        super(configuration, authenticator);
    }
       
    public async getCurrentUserInfo(): Promise<CurrentUserInfo> {
        return await this._callApi('/user/current-user', 'GET', null, true) as CurrentUserInfo;
    }

    public async profileChangeData(data: ProfilSpremeniPodatke): Promise<void> {
        return await this._callApi('/user/profile/data/change', 'POST', JSON.stringify(data), true, "");
    }

    public async deleteProfile(): Promise<any> {
        return await this._callApi('/user/profile/delete', 'POST', null, true, "") as any;
    }

    public async preberiNastavitveZaObvescanje(): Promise<NastavitveZaObvescanjeModel> {
        return await this._callApi('/user/profile/preberi-obvescanje', 'GET', null, true) as NastavitveZaObvescanjeModel;
    }

    public async shraniNastavitveZaObvescanje(data: NastavitveZaObvescanjeModel): Promise<any> {
        return await this._callApi('/user/profile/nastavi-obvescanje', 'POST', JSON.stringify(data), true) as any;
    }

    public async dodajDatoteko(request: FormData): Promise<void> {
        return await this._callApi('/user/dodaj-datoteko', 'POST', request, true, "", null, null, (settings) => {
            settings.contentType = false;
            settings.processData = false;
        });
    }

    public async odstraniPrvoPrijavo(): Promise<any> {
        return await this._callApi('/user/odstrani-prvo-prijavo', 'POST', null, true) as any;
    }

    public async getHeaderNotifications(take: number, skip: number): Promise<NotificationHeaderResponse> {
        return await this._callApi('/user/get-header-notifications/' + take + "/" + skip, 'GET', null, true) as NotificationHeaderResponse;
    }

    public async markNotificationAsRead(notificationId: string): Promise<any> {
        return await this._callApi('/user/mark-notification-as-read/' + notificationId, 'POST', null, true, "") as any;
    }

    public async markAllNotificationAsRead(): Promise<any> {
        return await this._callApi('/user/mark-all-notifications-as-read', 'POST', null, true, "") as any;
    }

    public async getMobileAppVersion(): Promise<any> {
        return await this._callApi('/user/get-mobile-app-version', 'GET', null, true, "") as any;
    }
} 