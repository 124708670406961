import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { MojPlusApiClient } from "../ts/clients/moj-plus-api-client";
import { CurrentUserInfo } from "../ts/models/current-user-info";
import { Role } from "../ts/models/role";
import { TranslationService } from "../ts/translation-service";
import template from "./admin-urejanje-uporabnika-view.html";
import { FullscreenLoader } from "./fullscreen-loader";
import "jquery-validation-unobtrusive";
import { AdminShraniUporabniskePodatkeRequest } from "../ts/models/admin-shrani-uporabniske-podatke-request";
import { MerilnoMestoOdstrani } from "../ts/models/merilno-mesto-odstrani";
import { MerilnoMesto } from "../ts/models/merilno-mesto";
import { UporabnikMerilnoMestoRequest } from "../ts/models/uporabnik-merilno-mesto-request";
import { UporabnikMerilnoMestoResponse } from "../ts/models/uporabnik-merilno-mesto-response";
import { AdminApiClient } from "../ts/clients/admin-api-client";
import { TipUporabnika } from "../ts/models/tip-uporabnika-enum";
import { OdjavaMojPlus } from "../ts/models/odjava-moj-plus";
import { MojPlusVclanitevRequest } from "../ts/models/moj-plus-vclanitev-request";
import { MojPlusUporabnikVclanitevRequest } from "../ts/models/moj-plus-uporabnik-vclanitev-request";
import { MojPlusUporabnikVclanitevResponse } from "../ts/models/moj-plus-uporabnik-vclanitev-response";
import { MojPlusVclanitev } from "../ts/models/moj-plus-vclanitev";
import { AdminGetUserNotificationsResponse, NotificationDetails } from "../ts/models/admin-get-user-notifications-response";
import * as moment from "moment";

@injectable()
export class AdminUrejanjeUporabnikaView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _adminApiClient: AdminApiClient;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _mojPlusApiClient: MojPlusApiClient;
    private _selectedUser: CurrentUserInfo | null;
    private _merilnaMesta: Array<MerilnoMesto>;
    private _mojPlusSeznam: Array<MojPlusVclanitev>;
    private _roles: Array<Role>;
    private _formUporabniskiPodatki: JQuery<HTMLElement> | null;
    private _formVclanitevProgram: JQuery<HTMLElement> | null;
    private _izbrisiMerilnoMesto: MerilnoMesto | null | undefined;
    private _odjavaProgramId: string;
    private _vclanitevProgramId: string;
    private _izbranUporabnikId: number;
    private _tipUporabnika: TipUporabnika | null;
    private _stevilkeKontoPogodb: Array<string>;
    private _userNotifications: Array<NotificationDetails>;
    private _formators: any;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        adminApiClient: AdminApiClient,
        merilnoMestoApiClient: MerilnoMestoApiClient,
        mojPlusApiClient: MojPlusApiClient,
        private _notyf: Notyf,
        private _loader: FullscreenLoader)
    {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._adminApiClient = adminApiClient;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._mojPlusApiClient = mojPlusApiClient;
        this._selectedUser = null;
        this._merilnaMesta = new Array<MerilnoMesto>();
        this._mojPlusSeznam = new Array<MojPlusVclanitev>();
        this._roles = new Array<Role>();
        this._formUporabniskiPodatki = null;
        this._formVclanitevProgram = null;
        this._izbrisiMerilnoMesto = null;
        this._odjavaProgramId = "";
        this._vclanitevProgramId = "";
        this._izbranUporabnikId = -1;
        this._tipUporabnika = null;
        this._stevilkeKontoPogodb = new Array<string>();
        this._userNotifications = new Array<NotificationDetails>();
        this._formators = null;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var localeFormat = moment().creationData().locale.longDateFormat("L");
            this._formators = {
                dateFormat: function () {
                    return function (timestamp: any, render: any) {
                        return moment(render(timestamp).trim()).format(localeFormat);
                    };
                }//tukaj se lahko dodajajo formatorji
            };

            var translations = this._translationService.currentTranslations;
            var url = window.location.href.split("/");
            this._izbranUporabnikId = Number(url[url.length - 1]);
            if (isNaN(this._izbranUporabnikId)) {
                this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
                this._notyf.error(translations["YouHaveToSelectAUser"]);
                return;
            }

            this._getTipUporabnika();

            await this._adminApiClient.getUserDetails(this._izbranUporabnikId)
                .then((odgovor: CurrentUserInfo | null) => {
                    this._selectedUser = odgovor;
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                });
            if (this._selectedUser) {
                await this._adminApiClient.getAllRoles()
                    .then((odgovor: any) => {
                        this._roles = odgovor;
                    })
                    .catch(() => {
                        this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    });

                await this._getMerilnaMestaInMojPlus();

                await this._adminApiClient.getUserNotifications(this._izbranUporabnikId)
                    .then((odgovor: AdminGetUserNotificationsResponse) => {
                        if (odgovor != null && odgovor.userNotifications != null) {
                            this._userNotifications = odgovor.userNotifications;
                        }
                    })
                    .catch(() => {
                        this._notyf.error(this._translationService.currentTranslations["UnexpectedError"])
                    });     
            }
            else {
                this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
                this._notyf.error(translations["UserDoesNotExist"]);
                return;
            }

            this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;

            this._initModal();
            this._renderTabele();
            this._initForm();
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private async _getMerilnaMestaInMojPlus() {
        this._loader.show();
        var uporabnikMerilnoMestoRequest: UporabnikMerilnoMestoRequest = {
            limit: 0,
            stevilkaStrani: 0,
            saldo: false,
            skupnaPorabaEnergije: false,
            merilnoMesto: "",
            merilnaTocka: ""
        };
        await this._merilnoMestoApiClient.getUporabnikMerilnaMesta(uporabnikMerilnoMestoRequest, this._izbranUporabnikId)
            .then((odgovor: UporabnikMerilnoMestoResponse | null) => {
                if (odgovor?.merilnaMesta && odgovor.merilnaMesta.length > 0) {
                    this._merilnaMesta = odgovor.merilnaMesta;
                }
                else {
                    this._merilnaMesta = new Array<MerilnoMesto>();
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });

        // ne nastavimo številko konto pogodbe, limit in številko strani, ker hočemo pridobiti vse podatke
        var mojPlusData: MojPlusUporabnikVclanitevRequest = {
            kljucProgramZvestobe: "EPLUS",
            limit: 0,
            stevilkaStrani: 0,
            stevilkaKontoPogodbe: ""
        };
        await this._mojPlusApiClient.uporabnikVclanitev(mojPlusData, this._izbranUporabnikId)
            .then((odgovor: MojPlusUporabnikVclanitevResponse) => {
                if (odgovor.status && odgovor.mojPlusVclanitve != null) {
                    this._mojPlusSeznam = odgovor.mojPlusVclanitve;
                }
                else {
                    this._mojPlusSeznam = new Array<MojPlusVclanitev>();
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });

        await this._merilnoMestoApiClient.getStevilkeKontoPogodb(this._izbranUporabnikId)
            .then((odgovor: any) => {
                this._stevilkeKontoPogodb = odgovor.filter((value: any, index: any, array: any) => array.indexOf(value) === index);
                if (this._mojPlusSeznam.length > 0) {
                    for (let i = 0; i < this._mojPlusSeznam.length; i++) {
                        if (this._stevilkeKontoPogodb.includes(this._mojPlusSeznam[i].stevilkaKontoPogodbe)) {
                            var index = this._stevilkeKontoPogodb.indexOf(this._mojPlusSeznam[i].stevilkaKontoPogodbe);
                            this._stevilkeKontoPogodb.splice(index, 1);
                        }
                    }
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
        this._loader.hide();
    }

    private _getTipUporabnika() {
        if (window.location.href.includes("tip-mestna-blagajna")) {
            this._tipUporabnika = TipUporabnika.MestnaBlagajna;
        }
        else if (window.location.href.includes("tip-upravnik")) {
            this._tipUporabnika = TipUporabnika.Upravnik;
        }
        else if (window.location.href.includes("tip-toplota")) {
            this._tipUporabnika = TipUporabnika.ToplotniUporabnik;
        }
        else {
            this._tipUporabnika = TipUporabnika.Uporabnik;
        }
    }

    private _renderData(translations: any): void {
        const viewModel = {
            selectedUser: this._selectedUser,
            roles: this._roles,
            merilnaMesta: this._merilnaMesta,
            mojPlusSeznam: this._mojPlusSeznam,
            translations: translations,
            jeUporabnik: this._tipUporabnika == TipUporabnika.Uporabnik ? true : false,
            jeMestnaBlagajna: this._tipUporabnika == TipUporabnika.MestnaBlagajna ? true : false,
            jeUpravnik: this._tipUporabnika == TipUporabnika.Upravnik ? true : false,
            jeToplotniUporabnik: this._tipUporabnika == TipUporabnika.ToplotniUporabnik ? true : false,
            idUporabnika: this._izbranUporabnikId
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _renderTabele() {
        this._renderTableMerilnaMestaDesktop();
        this._renderTableMerilnaMestaMobile();
        this._renderTableMojPlusDesktop();
        this._renderTableMojPlusMobile();
        this._renderTableNotificationsDesktop();
        this._renderTableNotificationsMobile();
    }

    private _initForm() {
        if (this._selectedUser && this._selectedUser.isActive) {
            ($("#check-input-aktiven")[0] as HTMLInputElement).checked = true;
        }
        else if (this._selectedUser && !this._selectedUser.isEnabled) {
            ($("#check-input-onemogocen")[0] as HTMLInputElement).checked = true;
        }
        if (this._selectedUser) {
            var rolesElement = $(".role-input");
            for (let i = 0; i < rolesElement.length; i++) {
                var roleValue = (rolesElement[i] as HTMLInputElement).value;
                var selectedRole = this._selectedUser.roles.find(a => a.role1 == roleValue);
                if (selectedRole) {
                    (rolesElement[i] as HTMLInputElement).checked = true;
                }
            }
        }

        ($("#ROLE_MESTNA_BLAGAJNA")[0] as HTMLInputElement).disabled = true;
        ($("#ROLE_UPRAVNIK")[0] as HTMLInputElement).disabled = true;
        ($("#ROLE_TOPLOTNI_UPORABNIK")[0] as HTMLInputElement).disabled = true;
        ($("#ROLE_UPORABNIK")[0] as HTMLInputElement).disabled = true;

        this._dodajValidacijskeFunkcije();
        this._formUporabniskiPodatki = $(("#form-uporabniski-podatki"));
        $.validator.unobtrusive.parse(this._formUporabniskiPodatki);
        this._formUporabniskiPodatki.validate();

        this._formVclanitevProgram = $(("#vclanitev-form"));
        $.validator.unobtrusive.parse(this._formVclanitevProgram);
        this._formVclanitevProgram.validate();

        var btnShraniUporabniskePodatke = $("#btnShraniUporabniskePodatke");
        btnShraniUporabniskePodatke.on("click", (ev: Event) => this._onBtnShraniUporabniskePodatkeClick(ev, this._formUporabniskiPodatki));
        var btnShraniUporabniskaSkupina = $("#btnShraniUporabniskaSkupina");
        btnShraniUporabniskaSkupina.on("click", (ev: Event) => this._onBtnShraniUporabniskaSkupinaClick(ev));
        var btnShraniNastavitveDostopa = $("#btnShraniNastavitveDostopa");
        btnShraniNastavitveDostopa.on("click", (ev: Event) => this._onBtnShraniNastavitveDostopaClick(ev));

        $("#check-input-aktiven").on("click", () => {
            if (($("#check-input-aktiven")[0] as HTMLInputElement).checked) {
                ($("#check-input-onemogocen")[0] as HTMLInputElement).checked = false;
            }
        });
        $("#check-input-onemogocen").on("click", () => {
            if (($("#check-input-onemogocen")[0] as HTMLInputElement).checked) {
                ($("#check-input-aktiven")[0] as HTMLInputElement).checked = false;
            }
        });
    }

    private _initModal() {
        // Modal merilna mesta
        $("#btn-close-modal").on("click", () => {
            $("#odstraniMerilnoMestoModal").modal("hide");
        });
        $("#btn-zaprite-modal").on("click", () => {
            $("#odstraniMerilnoMestoModal").modal("hide");
        });
        $("#btn-odstrani-mm-modal").on("click", (ev: Event) => this._onIzbrisiMerilnoMestoClick(ev));

        // Modal moj plus - odjava iz programa
        $("#btn-close-modal-moj-plus-odjava").on("click", () => {
            $("#odjavaProgramModal").modal("hide");
        });
        $("#btn-zaprite-modal-odjava-moj-plus").on("click", () => {
            $("#odjavaProgramModal").modal("hide");
        });
        $("#btn-odjava-moj-plus-modal").on("click", (ev: Event) => this._onOdjavaIzProgramaClick(ev));

        // Modal moj plus - včlanitev v program
        $("#btn-close-modal-moj-plus-vclanitev").on("click", () => {
            $("#vclanitevProgramModal").modal("hide");
            ($("#zelimPostatiClan")[0] as HTMLInputElement).checked = false;
            ($("#strinjamSeSPogoji")[0] as HTMLInputElement).checked = false;
            ($("#privolitev")[0] as HTMLInputElement).checked = false;
            ($("#davcnaStevilka")[0] as HTMLInputElement).value = "";
        });
        $("#btnVclaniteSe").on("click", (ev: Event) => this._onVclanitevVProgramClick(ev, this._formVclanitevProgram));
    }

    private _dodajValidacijskeFunkcije(): void {
        $.validator.addMethod("emailIsValid", function (email) {
            if (!email) {
                return true;
            }
            if (email.match(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-||_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/)) {
                return true;
            }
            return false;
        });
    }

    private async _onBtnShraniUporabniskePodatkeClick(ev: Event, formUporabniskiPodatki: any) {
        console.debug(ev);
        ev.preventDefault();
        if (!this._selectedUser) {
            return;
        }
        this._loader.show(); 
        if (!formUporabniskiPodatki.valid()) {
            this._notyf.error(this._translationService.currentTranslations["CheckAllFields"]);
            this._loader.hide();
            return null;
        }

        var formData = new FormData(formUporabniskiPodatki[0] as HTMLFormElement);
        var upravnikId = undefined;
        if (this._tipUporabnika == TipUporabnika.MestnaBlagajna) {
            upravnikId = Number(formData.get("mestnaBlagajnaId") as string);
        }
        else if (this._tipUporabnika == TipUporabnika.Upravnik) {
            upravnikId = Number(formData.get("upravnikId") as string);
        }

        var polnoIme = formData.get("polnoIme") as string;
        polnoIme = polnoIme.trim();
        polnoIme = polnoIme.replace(/ +(?= )/g, '');

        var data: AdminShraniUporabniskePodatkeRequest = {
            password: formData.get("geslo") as string,
            polnoIme: polnoIme,
            upravnikId: upravnikId
        };
        await this._adminApiClient.uporabniskiPodatkiShrani(this._selectedUser.id, data)
            .then((odgovor: any) => {
                console.log(odgovor);
                this._notyf.success(this._translationService.currentTranslations["SaveSuccessful"]);
            })
            .catch((ex: any) => {
                if (ex.statusCode == 400) {
                    var errorMessage = ex.message;
                    if (errorMessage) {
                        this._notyf.error(this._translationService.currentTranslations[errorMessage]);
                    }
                    else {
                        this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    }
                }
                else {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                }
            });
        this._loader.hide();
    }

    private async _onBtnShraniNastavitveDostopaClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        this._loader.show();
        var url = window.location.href.split("/");
        var izbranUporabnikId = Number(url[url.length - 1]);
        var isActive = Number(($("#check-input-aktiven")[0] as HTMLInputElement).checked);
        var isEnabled = Number(!($("#check-input-onemogocen")[0] as HTMLInputElement).checked);
        await this._adminApiClient.userDetailsNastavitveDostopaShrani(izbranUporabnikId, isActive, isEnabled)
            .then((odgovor: any) => {
                console.log(odgovor);
                this._notyf.success(this._translationService.currentTranslations["SaveSuccessful"]);
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                this._loader.hide();
            });
        this._loader.hide();
    }

    private async _onBtnShraniUporabniskaSkupinaClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        this._loader.show();
        var url = window.location.href.split("/");
        var izbranUporabnikId = Number(url[url.length - 1]);
        var rolesElement = $(".role-input");
        var izbraneVloge = new Array<string>();
        for (let i = 0; i < rolesElement.length; i++) {
            if ((rolesElement[i] as HTMLInputElement).checked) {
                izbraneVloge.push((rolesElement[i] as HTMLInputElement).value);
            }
        }
        await this._adminApiClient.userDetailsVlogeShrani(izbranUporabnikId, izbraneVloge)
            .then((odgovor: any) => {
                console.log(odgovor);
                this._notyf.success(this._translationService.currentTranslations["SaveSuccessful"]);
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                this._loader.hide();
            });
        this._loader.hide();
    }

    private _renderTableMojPlusDesktop() {
        const templateString = `
            <thead>
                <tr>
                    <th scope="col"><%{translations.ContractAccount}%></th>
                    <th scope="col"><%{translations.NumberOfPoints}%></th>
                    <th scope="col">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <%#mojPlusSeznam%>
                <tr>
                    <td scope="row"><%{stevilkaKontoPogodbe}%></td>
                    <td><%{skupajRazpolozljiveTocke}%></td>
                    <td>
                        <a class="link odjava-iz-programa-desktop" data-id="<%{stevilkaKontoPogodbe}%>"><%{translations.LogoutFromProgram}%></a>
                    </td>
                </tr>
                <%/mojPlusSeznam%>

                <%#ostaleKontoPogodbe%>
                <tr>
                    <td scope="row"><%{.}%></td>
                    <td>/</td>
                    <td>
                        <a class="link vclanitev-v-program-desktop" data-id="<%{.}%>"><%{translations.LoginToProgram}%></a>
                    </td>
                </tr>
                <%/ostaleKontoPogodbe%>

            </tbody>
        `;
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "mojPlusSeznam": this._mojPlusSeznam,
                "ostaleKontoPogodbe": this._stevilkeKontoPogodb,
                "translations": this._translationService.currentTranslations
            },
            undefined,
            customTags
        );

        $(".tabela-moj-plus-desktop").addClass("d-lg-block");
        $(".tabela-moj-plus-desktop").removeClass("d-lg-none");
        $("#table-moj-plus-desktop").html(html);

        if (this._mojPlusSeznam.length > 0) {
            var odjavaButtons = $(".odjava-iz-programa-desktop");
            for (let i = 0; i < odjavaButtons.length; i++) {
                odjavaButtons[i].addEventListener("click", (ev: Event) => {
                    ev.preventDefault();
                    this._odjavaProgramId = odjavaButtons[i].dataset["id"] as string;
                    $("#odjavaProgramModal").modal("show");
                });
            }
        }
        if (this._stevilkeKontoPogodb != null && this._stevilkeKontoPogodb.length > 0) {
            var vclanitevButtons = $(".vclanitev-v-program-desktop");
            for (let i = 0; i < vclanitevButtons.length; i++) {
                vclanitevButtons[i].addEventListener("click", (ev: Event) => {
                    ev.preventDefault();
                    this._vclanitevProgramId = vclanitevButtons[i].dataset["id"] as string;
                    $("#vclanitevProgramModal").modal("show");
                });
            }
        }
    }

    private _renderTableMojPlusMobile() {
        const templateString = `
            <%#mojPlusSeznam%>
            <tbody>
                <tr>
                    <th scope="row"><%{translations.ContractAccount}%></th>
                    <td><%{stevilkaKontoPogodbe}%></td>
                </tr>
                <tr>
                    <th scope="row"><%{translations.NumberOfPoints}%></th>
                    <td><%{skupajRazpolozljiveTocke}%></td>
                </tr>
                <tr>
                    <th scope="col">&nbsp;</th>
                    <td>
                        <a class="link odjava-iz-programa-mobile" data-id="<%{stevilkaKontoPogodbe}%>"><%{translations.LogoutFromProgram}%></a>
                    </td>
                </tr>
            </tbody>
            <%/mojPlusSeznam%>

            <%#ostaleKontoPogodbe%>
            <tbody>
                <tr>
                    <th scope="row"><%{translations.ContractAccount}%></th>
                    <td><%{.}%></td>
                </tr>
                <tr>
                    <th scope="row"><%{translations.NumberOfPoints}%></th>
                    <td>/</td>
                </tr>
                <tr>
                    <th scope="col">&nbsp;</th>
                    <td>
                        <a class="link vclanitev-v-program-mobile" data-id="<%{.}%>"><%{translations.LoginToProgram}%></a>
                    </td>
                </tr>
            </tbody>
            <%/ostaleKontoPogodbe%>
        `;
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "mojPlusSeznam": this._mojPlusSeznam,
                "ostaleKontoPogodbe": this._stevilkeKontoPogodb,
                "translations": this._translationService.currentTranslations
            },
            undefined,
            customTags
        );
        $(".tabela-moj-plus-mobile").addClass("d-block");
        $(".tabela-moj-plus-mobile").removeClass("d-none");
        $("#table-moj-plus-mobile").html(html);

        if (this._mojPlusSeznam.length > 0) {
            var odjavaButtons = $(".odjava-iz-programa-mobile");
            for (let i = 0; i < odjavaButtons.length; i++) {
                odjavaButtons[i].addEventListener("click", (ev: Event) => {
                    ev.preventDefault();
                    this._odjavaProgramId = odjavaButtons[i].dataset["id"] as string;
                    $("#odjavaProgramModal").modal("show");
                });
            }
        }
        if (this._stevilkeKontoPogodb != null && this._stevilkeKontoPogodb.length > 0) {
            var vclanitevButtons = $(".vclanitev-v-program-mobile");
            for (let i = 0; i < vclanitevButtons.length; i++) {
                vclanitevButtons[i].addEventListener("click", (ev: Event) => {
                    ev.preventDefault();
                    this._vclanitevProgramId = vclanitevButtons[i].dataset["id"] as string;
                    $("#vclanitevProgramModal").modal("show");
                });
            }
        }
    }

    private async _onVclanitevVProgramClick(ev: Event, formVclanitevVProgram: any) {
        ev.preventDefault();
        if (!formVclanitevVProgram.valid()) {
            this._notyf.error(this._translationService.currentTranslations["CheckAllFields"]);
            return null;
        }
        var formData = new FormData(formVclanitevVProgram[0] as HTMLFormElement);
        var davcnaStevilka = formData.get("davcnaStevilka") as string;
        var mojPlusVclanitevRequest: MojPlusVclanitevRequest = {
            davcnaStevilka: davcnaStevilka,
            uporabnikId: this._izbranUporabnikId,
            kljucProgramZvestobe: "EPLUS",
            stevilkaKontoPogodbe: this._vclanitevProgramId,
            zelimPostatiClan: ($("#zelimPostatiClan")[0] as HTMLInputElement).checked,
            strinjamSeSSplosniPogoji: ($("#strinjamSeSPogoji")[0] as HTMLInputElement).checked,
            privolitevDoPreklica: ($("#privolitev")[0] as HTMLInputElement).checked
        };
        await this._mojPlusApiClient.vclanitevMojPlus(mojPlusVclanitevRequest)
            .then((odgovor) => {
                if (odgovor.status) {
                    this._loader.hide();
                    $("#vclanitevProgramModal").modal("hide");
                    ($("#zelimPostatiClan")[0] as HTMLInputElement).checked = false;
                    ($("#strinjamSeSPogoji")[0] as HTMLInputElement).checked = false;
                    ($("#privolitev")[0] as HTMLInputElement).checked = false;
                    ($("#davcnaStevilka")[0] as HTMLInputElement).value = "";
                    this._notyf.success(this._translationService.currentTranslations["SaveSuccessful"]);
                }
                else
                    this._notyf.error(odgovor.sporocilo);
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
        await this._getMerilnaMestaInMojPlus();
        this._renderTabele();
    }

    private async _onOdjavaIzProgramaClick(ev: Event) {
        ev.preventDefault();
        var odjavaMojPlus: OdjavaMojPlus = {
            stevilkaKontoPogodbe: this._odjavaProgramId,
            kljucProgramZvestobe: "EPLUS"
        };
        await this._mojPlusApiClient.odjavaIzLojalnostnegaPrograma(odjavaMojPlus, this._izbranUporabnikId)
            .then((odgovor) => {
                $("#odjavaProgramModal").modal("hide");
                if (odgovor.status) {
                    this._notyf.success(this._translationService.currentTranslations["SaveSuccessful"]);
                }
                else {
                    this._notyf.error(odgovor.sporocilo);
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
        await this._getMerilnaMestaInMojPlus();
        this._renderTabele();
    }

    private async _onIzbrisiMerilnoMestoClick(ev: Event) {
        ev.preventDefault();
        if (this._merilnaMesta.length == 0) return;
        if (this._izbrisiMerilnoMesto) {
            var merilnoMestoOdstraniSeznam: Array<MerilnoMestoOdstrani> = new Array<MerilnoMestoOdstrani>();
            merilnoMestoOdstraniSeznam.push(this._izbrisiMerilnoMesto);
            await this._merilnoMestoApiClient.odstraniMerilnaMesta(merilnoMestoOdstraniSeznam, this._izbranUporabnikId)
                .then((merilnaMestaOdgovor) => {
                    for (let i = 0; i < merilnaMestaOdgovor.items.length; i++) {
                        if (!merilnaMestaOdgovor.items[i].status) {
                            $("#odstraniMerilnoMestoModal").modal("hide");
                            this._notyf.error(this._translationService.currentTranslations.MeasuringPoint
                                + " " + merilnaMestaOdgovor.items[i].merilnoMesto + ". "
                                + merilnaMestaOdgovor.items[i].sporocilo);
                            return;
                        }
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileRemovingMeasuringSamplingPoints"]);
                });
            $("#odstraniMerilnoMestoModal").modal("hide");
            await this._getMerilnaMestaInMojPlus();
            this._renderTabele();
            this._notyf.success(this._translationService.currentTranslations["TheMeasuringPointHasBeenSuccessfullyRemoved"]);    
        }
    }

    private _renderTableMerilnaMestaDesktop() {
        const templateString = `
            <thead>
                <tr>
                    <th scope="col"><%{translations.ContractAccount}%></th>
                    <th scope="col"><%{translations.MeasuringTakingPlace}%></th>
                    <th scope="col"><%{translations.MeasuringPoint1}%></th>
                    <th scope="col"><%{translations.ActivationCode}%></th>
                    <th scope="col">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <%#merilnaMesta%>
                <tr>
                    <td scope="row"><%{stevilkaKontoPogodbe}%></td>
                    <td><%{merilnoMesto}%></td>
                    <%#jeElektrika%>
                        <td><%{merilnaTocka}%></td>
                    <%/jeElektrika%>
                    <%^jeElektrika%>
                        <td>/</td>
                    <%/jeElektrika%>
                    <td><%{aktivacijskaKoda}%></td>
                    <td>
                        <%#jeElektrika%>
                            <a class="link izbrisi-merilno-mesto-desktop" data-merilnatocka="<%{merilnaTocka}%>" data-stevilkakontopogodbe="<%{stevilkaKontoPogodbe}%>" data-merilnomesto="<%{merilnoMesto}%>"><%{translations.Delete}%></a>
                        <%/jeElektrika%>
                        <%^jeElektrika%>
                            <a class="link izbrisi-merilno-mesto-desktop" data-stevilkakontopogodbe="<%{stevilkaKontoPogodbe}%>" data-merilnomesto="<%{merilnoMesto}%>"><%{translations.Delete}%></a>
                        <%/jeElektrika%>
                    </td>
                </tr>
                <%/merilnaMesta%>
            </tbody>
        `;
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "merilnaMesta": this._merilnaMesta,
                "translations": this._translationService.currentTranslations
            },
            undefined,
            customTags
        );

        $(".tabela-merilna-mesta-desktop").addClass("d-lg-block");
        $(".tabela-merilna-mesta-desktop").removeClass("d-lg-none");
        $("#table-merilna-mesta-desktop").html(html);

        if (this._merilnaMesta.length > 0) {
            var izbrisiMerilnoMestoButtons = $(".izbrisi-merilno-mesto-desktop");
            for (let i = 0; i < izbrisiMerilnoMestoButtons.length; i++) {
                izbrisiMerilnoMestoButtons[i].addEventListener("click", (ev: Event) => {
                    ev.preventDefault();
                    var stevilkaKontoPogodbe = izbrisiMerilnoMestoButtons[i].dataset["stevilkakontopogodbe"] as string;
                    var merilnatocka = izbrisiMerilnoMestoButtons[i].dataset["merilnatocka"] as string;
                    var merilnoMesto = izbrisiMerilnoMestoButtons[i].dataset["merilnomesto"] as string;
                    this._izbrisiMerilnoMesto = this._merilnaMesta.find(a =>
                        (a.jeElektrika == false && a.merilnoMesto == merilnoMesto && a.stevilkaKontoPogodbe == stevilkaKontoPogodbe) ||
                        (a.jeElektrika == true && a.merilnaTocka == merilnatocka && a.merilnoMesto == merilnoMesto && a.stevilkaKontoPogodbe == stevilkaKontoPogodbe));
                    $("#odstraniMerilnoMestoModal").modal("show");
                });
            }
        }
    }

    private _renderTableMerilnaMestaMobile() {
        const templateString = `
            <%#merilnaMesta%>
            <tbody>
                <tr>
                    <th scope="row"><%{translations.ContractAccount}%></th>
                    <td><%{stevilkaKontoPogodbe}%></td>
                </tr>
                <tr>
                    <th scope="row"><%{translations.MeasuringTakingPlace}%></th>
                    <td><%{merilnoMesto}%></td>
                </tr>
                <tr>
                    <th scope="row"><%{translations.MeasuringPoint1}%></th>
                    <%#jeElektrika%>
                        <td><%{merilnaTocka}%></td>
                    <%/jeElektrika%>
                    <%^jeElektrika%>
                        <td>/</td>
                    <%/jeElektrika%>
                </tr>
                <tr>
                    <th scope="row"><%{translations.ActivationCode}%></th>
                    <td><%{aktivacijskaKoda}%></td>
                </tr>
                <tr>
                    <th scope="row">&nbsp;</th>
                    <td>
                        <%#jeElektrika%>
                            <a class="link izbrisi-merilno-mesto-mobile" data-merilnatocka="<%{merilnaTocka}%>" data-stevilkakontopogodbe="<%{stevilkaKontoPogodbe}%>" data-merilnomesto="<%{merilnoMesto}%>"><%{translations.Delete}%></a>
                        <%/jeElektrika%>
                        <%^jeElektrika%>
                            <a class="link izbrisi-merilno-mesto-mobile" data-stevilkakontopogodbe="<%{stevilkaKontoPogodbe}%>" data-merilnomesto="<%{merilnoMesto}%>"><%{translations.Delete}%></a>
                        <%/jeElektrika%>
                    </td>
                </tr>
            </tbody>
            <%/merilnaMesta%>
        `;
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "merilnaMesta": this._merilnaMesta,
                "translations": this._translationService.currentTranslations
            },
            undefined,
            customTags
        );
        $(".tabela-merilna-mesta-mobile").addClass("d-block");
        $(".tabela-merilna-mesta-mobile").removeClass("d-none");
        $("#table-merilna-mesta-mobile").html(html);

        if (this._merilnaMesta.length > 0) {
            var izbrisiMerilnoMestoButtons = $(".izbrisi-merilno-mesto-mobile");
            for (let i = 0; i < izbrisiMerilnoMestoButtons.length; i++) {
                izbrisiMerilnoMestoButtons[i].addEventListener("click", (ev: Event) => {
                    ev.preventDefault();
                    var stevilkaKontoPogodbe = izbrisiMerilnoMestoButtons[i].dataset["stevilkakontopogodbe"] as string;
                    var merilnatocka = izbrisiMerilnoMestoButtons[i].dataset["merilnatocka"] as string;
                    var merilnoMesto = izbrisiMerilnoMestoButtons[i].dataset["merilnomesto"] as string;
                    this._izbrisiMerilnoMesto = this._merilnaMesta.find(a =>
                        (a.jeElektrika == false && a.merilnoMesto == merilnoMesto && a.stevilkaKontoPogodbe == stevilkaKontoPogodbe) ||
                        (a.jeElektrika == true && a.merilnaTocka == merilnatocka && a.merilnoMesto == merilnoMesto && a.stevilkaKontoPogodbe == stevilkaKontoPogodbe));
                    $("#odstraniMerilnoMestoModal").modal("show");
                });
            }
        }
    }

    private _renderTableNotificationsDesktop() {
        const templateString = `
            <thead>
                <tr>
                    <th scope="col">Naslov</th>
                    <th scope="col">Kreiral</th>
                    <th scope="col">Datum</th>
                    <th scope="col">Tip</th>
                    <th scope="col">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <%#userNotifications%>
                <tr>
                    <td scope="row"><%{title}%></td>
                    <td><%{createdBy}%></td>
                    <td><%#formators%><%#dateFormat%><%dateCreated%><%/dateFormat%><%/formators%></td>
                    <td><%{type}%></td>
                    <td><a href="#/admin/notification-details/<%{id}%>">Podrobnosti</a></td>
                </tr>
                <%/userNotifications%>
            </tbody>
        `;
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "formators": this._formators,
                "userNotifications": this._userNotifications,
                "translations": this._translationService.currentTranslations
            },
            undefined,
            customTags
        );
        $(".tabela-notifications-desktop").addClass("d-lg-block");
        $(".tabela-notifications-desktop").removeClass("d-lg-none");
        $("#table-notifications-desktop").html(html);
    }

    private _renderTableNotificationsMobile() {
        const templateString = `
            <%#userNotifications%>
            <tbody>
                <tr>
                    <th scope="row">Naslov</th>
                    <td><%{title}%></td>
                </tr>
                <tr>
                    <th>Kreiral</th>
                    <td><%{createdBy}%></td>
                </tr>
                <tr>
                    <th>Datum</th>
                    <td><%#formators%><%#dateFormat%><%dateCreated%><%/dateFormat%><%/formators%></td>
                </tr>
                <tr>
                    <th>Tip</th>
                    <td><%{type}%></td>
                </tr>
                <tr>
                    <th>&nbsp;</th>
                    <td><a href="#/admin/notification-details/<%{id}%>">Podrobnosti</a></td>
                </tr>
            </tbody>
            <%/userNotifications%>
        `;
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "formators": this._formators,
                "userNotifications": this._userNotifications,
                "translations": this._translationService.currentTranslations
            },
            undefined,
            customTags
        );
        $(".tabela-notifications-mobile").addClass("d-block");
        $(".tabela-notifications-mobile").removeClass("d-none");
        $("#table-notifications-mobile").html(html);
    }
}