import { injectable } from "inversify";
import * as moment from "moment";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { UserApiClient } from "../ts/clients/users-api-client";
import { CurrentUserInfo } from "../ts/models/current-user-info";
import { DashboardData } from "../ts/models/dashboard";
import { EboxSporocilo } from "../ts/models/ebox-sporocila-response";
import { MerilnoMesto } from "../ts/models/merilno-mesto";
import { MojPlusVclanitev } from "../ts/models/moj-plus-vclanitev";
import { TranslationService } from "../ts/translation-service";
import template from "./dashboard-view.html";

@injectable()
export class DashboardView {
    private rootElement: JQuery<HTMLElement>;
    private _currentUser: CurrentUserInfo | null;
    private _translationService: TranslationService;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _userApiClient: UserApiClient;
    private _eboxSporocila: Array<EboxSporocilo>;
    private _merilnaMesta: Array<MerilnoMesto>;
    private _mojPlusVclanitve: Array<MojPlusVclanitev>;
    private _formators: any;
    private _porabaMediana: number;
    private _tockeMediana: number;
    private _steviloNeprebranihSporocil: number;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        merilnoMestoApiClient: MerilnoMestoApiClient,
        userApiClient: UserApiClient,
        private _notyf: Notyf) {
        this.rootElement = rootElement;
        this._currentUser = null;
        this._translationService = translationService;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._userApiClient = userApiClient;
        this._eboxSporocila = new Array<EboxSporocilo>();
        this._merilnaMesta = new Array<MerilnoMesto>();
        this._mojPlusVclanitve = new Array<MojPlusVclanitev>();
        this._formators = null;
        this._porabaMediana = 0;
        this._tockeMediana = 0;
        this._steviloNeprebranihSporocil = 0;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;
            await this.loadUserData();
            await this.loadDashboardData();
            await this._renderData(translations, this._currentUser);
            if ($("#page-loaded")[0] == undefined) return;
            this._initForm();
        } catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private async loadUserData(): Promise<any> {
        await this._userApiClient.getCurrentUserInfo()
            .then((odgovor) => {
                this._currentUser = odgovor;
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSearchingForCurrentUser"]);
            });
    }

    private async loadDashboardData(): Promise<any> {
        await this._merilnoMestoApiClient.getDashboard()
            .then((dashboardData: DashboardData) => {
                if (dashboardData.ebox && dashboardData.ebox.eboxSporocila && dashboardData.ebox.eboxSporocila.length > 0) {
                    this._steviloNeprebranihSporocil = dashboardData.ebox.steviloNeprebranihSporocil;
                    this._eboxSporocila = dashboardData.ebox.eboxSporocila;
                    for (let i = 0; i < this._eboxSporocila.length; i++) {
                        if (this._eboxSporocila[i].vsebina && this._eboxSporocila[i].vsebina.vsebinaSporocila.includes("<img")) {
                            var vsebinaHelper = this._eboxSporocila[i].vsebina.vsebinaSporocila.replace(/<img[^>]*>/g, "");
                            this._eboxSporocila[i].vsebina.vsebinaSporocila = vsebinaHelper;
                        }
                    }
                }
                if (dashboardData.odjemnoMesto && dashboardData.odjemnoMesto.merilnaMesta && dashboardData.odjemnoMesto.merilnaMesta.length > 0) {
                    this._merilnaMesta = dashboardData.odjemnoMesto.merilnaMesta;
                    this._porabaMediana = dashboardData.odjemnoMesto.porabaMediana;
                    this._merilnaMesta.forEach((item) => {
                        if (item.obdobjePorabeOd == null || item.obdobjePorabeDo == null) {
                            item.obstajaOdcitekPorabe = false;
                        }
                        else {
                            item.obstajaOdcitekPorabe = true;
                        }
                    });
                }
                if (dashboardData.mojPlus && dashboardData.mojPlus.mojPlusVclanitve && dashboardData.mojPlus.mojPlusVclanitve.length > 0) {
                    this._mojPlusVclanitve = dashboardData.mojPlus.mojPlusVclanitve;
                    this._tockeMediana = dashboardData.mojPlus.tockeMediana;
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
    }

    private _renderData(translations: any, currentUser: CurrentUserInfo | null): void {
        var localeFormat = moment().creationData().locale.longDateFormat("L");
        this._formators = {
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            }//tukaj se lahko dodajajo formatorji
        };

        const viewModel = {
            currentUser: currentUser,
            uporabnikImaPolnoIme: currentUser?.firstName ? true : false,
            sporocila: this._eboxSporocila,
            steviloSporocil: this._steviloNeprebranihSporocil,
            obstajaNeprebranoSporocilo: this._steviloNeprebranihSporocil > 0 ? true : false,
            merilnaMesta: this._merilnaMesta,
            obstajaMerilnoMesto: this._merilnaMesta.length > 0 ? true : false,
            mojPlusVclanitve: this._mojPlusVclanitve,
            translations: translations,
            formators: this._formators
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _initForm(): void {
        for (let i = 0; i < this._merilnaMesta.length; i++) {
            var porabaProcent = this._merilnaMesta[i].porabaEnergije / this._porabaMediana * 100;
            if (porabaProcent > 100) {
                porabaProcent = 100;
            }
            var tekocaPoraba = $(".progress-bar-tekoca-poraba");
            if (tekocaPoraba.length != 0) {
                tekocaPoraba[i].style.width = porabaProcent.toString() + "%";
                $(".progress-number-tekoca-poraba")[i].style.right = "calc(50% - " + porabaProcent.toString() + "%)";
                $(".progress-number-tekoca-poraba")[i].innerHTML = this._merilnaMesta[i].porabaEnergije + " " + this._merilnaMesta[i].mernaEnota;
            }
        }

        for (let i = 0; i < this._mojPlusVclanitve.length; i++) {
            var progressBar = $(".tocke-progress-bar");
            var tocke = this._mojPlusVclanitve[i].skupajRazpolozljiveTocke / this._tockeMediana * 100;
            if (progressBar.length != 0) {
                progressBar[i].style.width = tocke.toString() + "%";
            }
        }

        var sporocilaPodrobnosti = $(".sporocila-podrobnosti");
        for (let i = 0; i < sporocilaPodrobnosti.length; i++) {
            sporocilaPodrobnosti[i].addEventListener("click", () => {
                var threadId = sporocilaPodrobnosti[i].dataset["threadid"];
                var merilnoMesto = sporocilaPodrobnosti[i].dataset["merilnomesto"];
                var merilnaTocka = sporocilaPodrobnosti[i].dataset["merilnatocka"];
                var stevilkaKontoPogodbe = sporocilaPodrobnosti[i].dataset["kontopogodba"];
                var jeElektrika = sporocilaPodrobnosti[i].dataset["jeelektrika"];
                if (jeElektrika == 'true') {
                    window.location.href = "#/kontakti/ebox/pogovor?" + threadId + "_" + merilnaTocka + "_true_" + merilnoMesto + "_" + stevilkaKontoPogodbe;
                }
                else {
                    window.location.href = "#/kontakti/ebox/pogovor?" + threadId + "_false_" + merilnoMesto + "_" + stevilkaKontoPogodbe;
                }
            });
        }

        var mesecnaPorabaButtons = $(".mesecna-poraba");
        for (let i = 0; i < mesecnaPorabaButtons.length; i++) {
            mesecnaPorabaButtons[i].addEventListener("click", (ev: Event) => this._onMesecnaPorabaClick(ev, i));
        }
    }

    private _onMesecnaPorabaClick(ev: Event, indexMerilnegaMesta: number) {
        console.debug(ev);
        ev.preventDefault();
        var merilnoMesto = this._merilnaMesta[indexMerilnegaMesta];
        if (merilnoMesto.jeElektrika) {
            window.location.href = "#/merilno-mesto/poraba/" + "poraba-pregled/" + merilnoMesto.merilnaTocka;
        }
        else {
            window.location.href = "#/merilno-mesto/poraba/" + "poraba-pregled/" + merilnoMesto.merilnoMesto;
        }
    }
}
