import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { KontaktiInPomocApiClient } from "../ts/clients/kontakti-in-pomoc-api-client";
import { ProdajnoMestoUmbracoResponse } from "../ts/models/prodajno-mesto-umbraco-response";
import { TranslationService } from "../ts/translation-service";
import template from "./klicni-center-seznam-view.html";

@injectable()
export class KlicniCenterSeznamView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _kontaktiInPomocApiClient: KontaktiInPomocApiClient;
    private _prodajnaMestaUmbraco: Array<ProdajnoMestoUmbracoResponse>;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        kontaktiInPomocApiClient: KontaktiInPomocApiClient,
        private _notyf: Notyf) {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._kontaktiInPomocApiClient = kontaktiInPomocApiClient;
        this._prodajnaMestaUmbraco = new Array<ProdajnoMestoUmbracoResponse>();
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;
            await this._getProdajnaMesta();
            this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;
        } catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private async _getProdajnaMesta() {
        await this._kontaktiInPomocApiClient.getProdajnaMestaUmbraco()
            .then((response: Array<ProdajnoMestoUmbracoResponse>) => {
                this._prodajnaMestaUmbraco = response;
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
    }

    private _renderData(translations: any): void {
        var prodajnoMestoId1 = this._prodajnaMestaUmbraco[0].Id;
        var prodajnoMestoId2 = this._prodajnaMestaUmbraco[1].Id;
        var prodajnoMestoId3 = this._prodajnaMestaUmbraco[2].Id;
        var prodajnoMestoId4 = this._prodajnaMestaUmbraco[3].Id;
        var prodajnoMestoId5 = this._prodajnaMestaUmbraco[4].Id;
        var prodajnoMestoId6 = this._prodajnaMestaUmbraco[5].Id;

        const viewModel = {
            translations: translations,
            prodajnoMestoId1: prodajnoMestoId1,
            prodajnoMestoId2: prodajnoMestoId2,
            prodajnoMestoId3: prodajnoMestoId3,
            prodajnoMestoId4: prodajnoMestoId4,
            prodajnoMestoId5: prodajnoMestoId5,
            prodajnoMestoId6: prodajnoMestoId6
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }
}
