import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { PogostaVprasanjaApiClient } from "../ts/clients/pogosta-vprasanja-api-client";
import { KategorijaPogostaVprasanjaResponse } from "../ts/models/kategorija-pogosta-vprasanja-response";
import { PogostoVprasanjeResponse } from "../ts/models/pogosto-vprasanje-response";
import { TranslationService } from "../ts/translation-service";
import template from "./kontakti-pogosta-vprasanja-odgovor-view.html";
import * as tinySlider from "tiny-slider";

@injectable()
export class KontaktiPogostaVprasanjaOdgovorView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _pogostaVprasanjaApiClient: PogostaVprasanjaApiClient;
    private _kategorije: Array<KategorijaPogostaVprasanjaResponse>;
    private _izbranoVprasanje?: PogostoVprasanjeResponse;
    private _izbranaKategorija?: KategorijaPogostaVprasanjaResponse;
    private _displayedSliderItems: number;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        pogostaVprasanjaApiClient: PogostaVprasanjaApiClient,
        private _notyf: Notyf) {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._pogostaVprasanjaApiClient = pogostaVprasanjaApiClient;
        this._kategorije = new Array<KategorijaPogostaVprasanjaResponse>();
        this._displayedSliderItems = 0;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;
            var url = window.location.href.split("/");
            var kategorijaId = url[url.length - 2];
            var vprasanjeId = Number(url[url.length - 1]);
            if (isNaN(vprasanjeId)) {
                this.rootElement.text(this._translationService.currentTranslations["UnexpectedError"]);
                return;
            }
            await this._pridobiKategorije("");
            this._izbranaKategorija = this._kategorije.find(a => a.id == kategorijaId);
            if (this._izbranaKategorija) {
                await this._pridobiPogostoVprasanje(kategorijaId, 1, 10, vprasanjeId, "");
            }
            else {
                this.rootElement.text(this._translationService.currentTranslations["CategoryNotFound"]);
                return;
            }
            this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;
            this._renderSliderPagination();
            this._initForm();
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(translations: any): void {
        const viewModel = {
            translations: translations,
            kategorije: this._kategorije,
            izbranaKategorija: this._izbranaKategorija,
            izbranoVprasanje: this._izbranoVprasanje
        } as any;
        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _initForm() {
        var self = this;
        window.addEventListener('resize', function () {
            if ((window.innerWidth >= 0 && window.innerWidth < 768 && self._displayedSliderItems != 1) ||
                (window.innerWidth >= 768 && window.innerWidth < 992 && self._displayedSliderItems != 2) ||
                (window.innerWidth >= 992 && self._displayedSliderItems != 3)) {
                window.location.reload();
            }
        });

        var slider = tinySlider.tns({
            container: '.tile-slider',
            items: 1,
            slideBy: 'page',
            gutter: 30,
            edgePadding: 5,
            autoplay: false,
            mouseDrag: true,
            swipeAngle: false,
            speed: 400,
            navPosition: 'bottom',
            controlsContainer: ".custom-controls",
            navContainer: ".custom-nav",
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                992: {
                    items: 3
                }
            }
        });
        slider.play();

        var kategorijeSlider = $(".kategorija-slider");
        for (let i = 0; i < kategorijeSlider.length; i++) {
            kategorijeSlider[i].addEventListener("click", () => {
                var id = kategorijeSlider[i].dataset["id"];
                window.location.href = "#/kontakti/pogosta-vprasanja-seznam/" + id;
            })
        }
    }

    private async _pridobiKategorije(filter: string) {
        await this._pogostaVprasanjaApiClient.getKategorije(filter)
            .then((odgovor) => {
                this._kategorije = odgovor;
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
    } 

    private async _pridobiPogostoVprasanje(kategorijaId: string, page: number, take: number, vprasanjeId: number, filter?: string) {
        await this._pogostaVprasanjaApiClient.getPogostaVprasanja(kategorijaId, page, take, filter)
            .then((pogostaVprasanja) => {
                this._izbranoVprasanje = pogostaVprasanja.find(a => a.id == vprasanjeId);
                if (!this._izbranoVprasanje) {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    return;
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
    }

    private _renderSliderPagination() {
        if (window.innerWidth >= 0 && window.innerWidth < 768) {
            this._displayedSliderItems = 1;
        }
        else if (window.innerWidth >= 768 && window.innerWidth < 992) {
            this._displayedSliderItems = 2;
        }
        else {
            this._displayedSliderItems = 3;
        }
        var dots = Math.ceil(this._kategorije.length / this._displayedSliderItems) - 1;
        var sliderIndexes = Array.from({ length: dots }, (_, i) => i + 1);
        const templateString = `
            <ul class="custom-nav" aria-label="Carousel Pagination">
                <li data-nav="0" aria-label="Carousel Page 1 (Current Slide)" aria-controls="tile-slider" class="tns-nav-active"></li>
                <%#sliderIndexes%>
                <li data-nav="<%{.}%>" aria-label="Carousel Page <%{.}%>" aria-controls="tile-slider" class="tns-nav" tabindex="-1"></li>
                <%/sliderIndexes%>   
            </ul>        
        `;
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "sliderIndexes": sliderIndexes
            },
            undefined,
            customTags
        );
        $("#slider-pagination").html(html);
    }
}