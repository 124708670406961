import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { MerilnoMestoInformacijeRequest } from "../ts/models/merilno-mesto-informacije-request";
import { MerilnoMestoInformacijeResponse } from "../ts/models/merilno-mesto-informacije-response";
import { TranslationService } from "../ts/translation-service";
import template from "./merilno-mesto-informacije-prikaz-view.html";
import "jquery-validation-unobtrusive";
import * as moment from "moment";
import { UporabnikMerilnoMestoRequest } from "../ts/models/uporabnik-merilno-mesto-request";
import { MerilnoMesto } from "../ts/models/merilno-mesto";
import { UporabnikMerilnoMestoResponse } from "../ts/models/uporabnik-merilno-mesto-response";
import { KanalRequest, SpremembaTipaKanalaRequest } from "../ts/models/sprememba-tipa-kanala-request";

@injectable()
export class MerilnoMestoInformacijePrikazView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _merilnaMesta: Array<MerilnoMesto> | null;
    private _izbranoMerilnoMesto: MerilnoMesto | null | undefined;
    private _dropdownMerilnaMestaElement: JQuery<HTMLElement> | null;
    private _merilnoMestoInformacije: MerilnoMestoInformacijeResponse | null;
    private _formNacinPosiljanjaRacunov: JQuery<HTMLElement> | null;
    private _izbranKanalId: string;
    private _sodo: string;
    private _sodoNaziv: string;
    private _sodoTelefon: string;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        merilnoMestoApiClient: MerilnoMestoApiClient,
        private _notyf: Notyf)
    {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._merilnaMesta = null;
        this._izbranoMerilnoMesto = null;
        this._dropdownMerilnaMestaElement = null;
        this._merilnoMestoInformacije = null;
        this._formNacinPosiljanjaRacunov = null;
        this._izbranKanalId = "";
        this._sodo = "";
        this._sodoNaziv = "";
        this._sodoTelefon = "";
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;
            var uporabnikMerilnoMestoRequest: UporabnikMerilnoMestoRequest = {
                limit: 0,
                stevilkaStrani: 0,
                saldo: false,
                skupnaPorabaEnergije: false,
                merilnoMesto: "",
                merilnaTocka: ""
            };
            await this._merilnoMestoApiClient.getUporabnikMerilnaMesta(uporabnikMerilnoMestoRequest)
                .then((odgovor: UporabnikMerilnoMestoResponse) => {
                    this._merilnaMesta = odgovor.merilnaMesta;
                    if (this._merilnaMesta && this._merilnaMesta.length > 0) {
                        // poiscemo ali obstaja selektirano MM (v querystringu)
                        var url_string = window.location.href.split("?");
                        var url_data = url_string[url_string.length - 1];
                        var data_list = url_data.split("_");
                        const stevilkaKontoPogodbe = data_list[data_list.length - 1];
                        const merilnoMesto = data_list[data_list.length - 2];
                        const jeElektrika = data_list[data_list.length - 3];
                        const merilnaTocka = data_list[data_list.length - 4];

                        if (jeElektrika == 'true') {
                            this._izbranoMerilnoMesto = this._merilnaMesta.find(
                                a => a.merilnoMesto == merilnoMesto && a.merilnaTocka == merilnaTocka && a.stevilkaKontoPogodbe == stevilkaKontoPogodbe);
                        }
                        else if (jeElektrika == 'false') {
                            this._izbranoMerilnoMesto = this._merilnaMesta.find(
                                a => a.merilnoMesto == merilnoMesto && a.stevilkaKontoPogodbe == stevilkaKontoPogodbe);
                        }
                        else {
                            this._izbranoMerilnoMesto = this._merilnaMesta.find(a => a.stevilkaKontoPogodbe == stevilkaKontoPogodbe);
                        }
                        if (!this._izbranoMerilnoMesto) {
                            this._izbranoMerilnoMesto = this._merilnaMesta[0];
                        }
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSearchingForMeasuringPoints"]);
                });

            if (this._izbranoMerilnoMesto) {
                var informacijeRequest: MerilnoMestoInformacijeRequest = {
                    merilnaTocka: this._izbranoMerilnoMesto.merilnaTocka,
                    merilnoMesto: this._izbranoMerilnoMesto.merilnoMesto,
                    stevilkaKontoPogodbe: this._izbranoMerilnoMesto.stevilkaKontoPogodbe
                };

                await this._merilnoMestoApiClient.getInformacije(informacijeRequest)
                    .then((odgovor: MerilnoMestoInformacijeResponse) => {
                        if (odgovor.status) {
                            this._merilnoMestoInformacije = odgovor;
                            if (this._merilnoMestoInformacije && this._merilnoMestoInformacije.tehnicneInformacije) {
                                this._sodo = this._merilnoMestoInformacije.tehnicneInformacije.sodo;
                                this._pridobiSodoPodatke(this._sodo);
                            }
                        }
                    })
                    .catch(() => {
                        this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    });
            }

            this._renderData(translations);
            if (this._izbranoMerilnoMesto) {
                this._initForm();
            }
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(translations: any): void {
        var merilnaMestaIds = new Array<string>();
        var prikazanaMerilnaMesta = new Array<string>();
        var steviloPrikazanih = 3;
        if (this._merilnaMesta) {
            merilnaMestaIds = Array.from(new Set(this._merilnaMesta.map((item) => item.merilnoMesto)));
            prikazanaMerilnaMesta = merilnaMestaIds.slice(0, steviloPrikazanih);
            if (this._merilnaMesta.length < steviloPrikazanih) {
                prikazanaMerilnaMesta = merilnaMestaIds;
            }
        }
        var localeFormat = moment().creationData().locale.longDateFormat("L");
        var formators = {
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            }//tukaj se lahko dodajajo formatorji
        };
        
        const viewModel = {
            merilnaMestaIds: merilnaMestaIds,
            merilnaMesta: this._merilnaMesta ? this._merilnaMesta : null,
            izbranoMerilnoMesto: this._izbranoMerilnoMesto,
            ostalaMerilnaMestaStevilo: merilnaMestaIds.length - prikazanaMerilnaMesta.length,
            obstajajoOstalaMerilnaMesta: merilnaMestaIds.length - prikazanaMerilnaMesta.length > 0 ? true : false,
            prikazanaMerilnaMesta: prikazanaMerilnaMesta,
            cenik: this._merilnoMestoInformacije ? this._merilnoMestoInformacije.cenik : null,
            obstajaCenik: this._merilnoMestoInformacije?.cenik.veljavnostOd && this._merilnoMestoInformacije?.cenik.veljavnostDo ? true : false,  
            pogodbeneInformacije: this._merilnoMestoInformacije ? this._merilnoMestoInformacije.pogodbeneInformacije : null,
            tehnicneInformacije: this._merilnoMestoInformacije ? this._merilnoMestoInformacije.tehnicneInformacije : null,
            osebe: this._merilnoMestoInformacije ? this._merilnoMestoInformacije.osebe : null,
            translations: translations,
            formators: formators,
            obstajaMerilnoMesto: this._izbranoMerilnoMesto ? true : false,
            nacinPosiljanjaRacunov: this._merilnoMestoInformacije ? $.grep(this._merilnoMestoInformacije.nacinPosiljanjaRacunov, function (el) { return el.kanalAktiven == true }) : null,
            sodoNaziv: this._sodoNaziv,
            sodoTelefon: this._sodoTelefon
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _initForm(): void {
        if (!this._izbranoMerilnoMesto) {
            return;
        }
        this._dropdownMerilnaMestaElement = $("#dropdownMerilnaMesta");
        this._dropdownMerilnaMestaElement.on("change", (ev: Event) => this._onDropDownMerilnoMestoChange(ev));
        if (this._izbranoMerilnoMesto.jeElektrika) {
            this._dropdownMerilnaMestaElement.val(this._izbranoMerilnoMesto.jeElektrika + "_" + this._izbranoMerilnoMesto.stevilkaKontoPogodbe + "_" + this._izbranoMerilnoMesto.merilnoMesto + "_" + this._izbranoMerilnoMesto.merilnaTocka);
        }
        else {
            this._dropdownMerilnaMestaElement.val(this._izbranoMerilnoMesto.jeElektrika + "_" + this._izbranoMerilnoMesto.stevilkaKontoPogodbe + "_" + this._izbranoMerilnoMesto.merilnoMesto);
        }

        var btnMerilnaMestaOthers = $("#btnMerilnaMestaOthers");
        btnMerilnaMestaOthers.on("click", (ev: Event) => this._onBtnMerilnaMestaOthersClick(ev));

        this._dodajValidacijskeFunkcije();
        this._formNacinPosiljanjaRacunov = $(("#nacin-posiljanja-racunov"));
        $.validator.unobtrusive.parse(this._formNacinPosiljanjaRacunov);
        this._formNacinPosiljanjaRacunov.validate();

        var btnShraniNacinPosiljanja = $(this._formNacinPosiljanjaRacunov).find("#btnShraniNacinPosiljanja");
        btnShraniNacinPosiljanja.on("click", (ev: Event) => this._onBtnShraniNacinPosiljanjaClick(ev, this._formNacinPosiljanjaRacunov));

        var papirnataOblikaBtn = $("#radio-papirnaOblika");
        var emailBtn = $("#radio-email");
        var eStoritveBtn = $("#prejem-eStoritve");

        if (papirnataOblikaBtn && papirnataOblikaBtn.length > 0) {
            papirnataOblikaBtn.on("change", () => {
                if ((papirnataOblikaBtn[0] as HTMLInputElement).checked) {
                    $(".email-field").hide();
                }
            });
            emailBtn.on("change", () => {
                if ((emailBtn[0] as HTMLInputElement).checked) {
                    $(".email-field").show();
                }
            });
            eStoritveBtn.on("change", () => {
                if ((eStoritveBtn[0] as HTMLInputElement).checked) {
                    $(".email-field").hide();
                }
            });

            if (this._merilnoMestoInformacije && this._merilnoMestoInformacije.nacinPosiljanjaRacunov) {
                var papirnataOblika = this._merilnoMestoInformacije.nacinPosiljanjaRacunov.find(a => a.id == "00");
                if (papirnataOblika && papirnataOblika.kanalAktiven) {
                    (papirnataOblikaBtn[0] as HTMLInputElement).checked = true;
                    this._izbranKanalId = papirnataOblika.id;
                }
                var emailOblika = this._merilnoMestoInformacije.nacinPosiljanjaRacunov.find(a => a.id == "01");
                if (emailOblika && emailOblika.kanalAktiven) {
                    (emailBtn[0] as HTMLInputElement).checked = true;
                    $(".email-field").show();
                    $("#email").val(emailOblika.emailEracun);
                    this._izbranKanalId = emailOblika.id;
                }
                var eStoritveOblika = this._merilnoMestoInformacije.nacinPosiljanjaRacunov.find(a => a.id == "08");
                if (eStoritveOblika && eStoritveOblika.kanalAktiven) {
                    (eStoritveBtn[0] as HTMLInputElement).checked = true;
                    this._izbranKanalId = eStoritveOblika.id;
                }
            }
        }

        var dropdownSodo = $("#dropdownSodo");
        if (dropdownSodo && dropdownSodo.length > 0) {
            dropdownSodo.val(this._sodo);
            this._nastaviSodoPodatke();

            dropdownSodo.on("change", () => {
                this._sodo = dropdownSodo.val() as string;
                if (this._sodo) {
                    this._pridobiSodoPodatke(this._sodo);
                    this._nastaviSodoPodatke();
                }
            })
        }
    }

    private _nastaviSodoPodatke(): void {
        var sodoTelefon = $("#sodo-telefon");
        if (sodoTelefon && sodoTelefon.length > 0) {
            sodoTelefon[0].innerHTML = this._sodoTelefon;
            var urlTelefon = "tel:" + this._sodoTelefon.replace(/\s/g, "");
            sodoTelefon[0].setAttribute("href", urlTelefon);
        }
    }

    private _pridobiSodoPodatke(sodo: string): void {
        if (sodo == "Elektro Celje") {
            this._sodoNaziv = "Elektro Celje d.o.o.";
            this._sodoTelefon = "03 42 01 240";
        }
        else if (sodo == "Elektro Gorenjska") {
            this._sodoNaziv = "Elektro Gorenjska d.o.o.";
            this._sodoTelefon = "04 20 83 148";
        }
        else if (sodo == "Elektro Ljubljana") {
            this._sodoNaziv = "Elektro Ljubljana d.o.o.";
            this._sodoTelefon = "01 230 40 02";
        }
        else if (sodo == "Elektro Maribor") {
            this._sodoNaziv = "Elektro Maribor d.o.o.";
            this._sodoTelefon = "080 21 05";
        }
        else if (sodo == "Elektro Primorska") {
            this._sodoNaziv = "Elektro Primorska d.o.o.";
            this._sodoTelefon = "080 34 31";
        }
    }

    private _onDropDownMerilnoMestoChange(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        this._dropdownMerilnaMestaElement = $("#dropdownMerilnaMesta");
        var dropdownMerilnoMestoValues = (this._dropdownMerilnaMestaElement[0] as any).value.split("_");

        var selectedJeElektrika = dropdownMerilnoMestoValues[0]
        var selectedStevilkaKontoPogodbe = dropdownMerilnoMestoValues[1]
        var selectedMerilnoMesto = dropdownMerilnoMestoValues[2] 

        if (selectedJeElektrika == 'true') {
            var selectedMerilnaTocka = dropdownMerilnoMestoValues[3]
            location.href = "#/merilno-mesto/informacije?" + selectedMerilnaTocka + "_" + selectedJeElektrika + "_" + selectedMerilnoMesto + "_" + selectedStevilkaKontoPogodbe
        }            
        else
            location.href = "#/merilno-mesto/informacije?" + selectedJeElektrika + "_" + selectedMerilnoMesto + "_" + selectedStevilkaKontoPogodbe  
    }

    private _onBtnMerilnaMestaOthersClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        $(".merilna-mesta-not-all")[0].style.display = "none";
        $(".merilna-mesta-all")[0].style.display = "block";
    }

    private _dodajValidacijskeFunkcije() {
        $.validator.addMethod("emailIsValid", function (email) {
            email = email.toLowerCase();
            if (!($("#radio-email")[0] as HTMLInputElement).checked) {
                return true;
            }
            if (email.match(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-||_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/)) {
                return true;
            }
            return false;
        });
    }

    private async _onBtnShraniNacinPosiljanjaClick(ev: Event, formElement: any) {
        console.debug(ev);
        ev.preventDefault();
        if (!formElement.valid()) {
            this._notyf.error(this._translationService.currentTranslations["CheckAllFields"]);
            return null;
        }
        if (!formElement.find("#radio-papirnaOblika")[0].checked && !formElement.find("#radio-email")[0].checked && !formElement.find("#prejem-eStoritve")[0].checked) {
            this._notyf.error(this._translationService.currentTranslations["YouHaveToSelectAMethodOfSendingInvoices"]);
            return null;
        }
        if (!this._izbranoMerilnoMesto) return;

        var papirnaOblikaChecked = formElement.find("#radio-papirnaOblika")[0].checked;
        var emailPosiljanjeChecked = formElement.find("#radio-email")[0].checked;
        var email = formElement.find("#email")[0].value;
        var eStoritveChecked = formElement.find("#prejem-eStoritve")[0].checked;

        var kanali = new Array<KanalRequest>();

        var kanalPapirnaOblika: KanalRequest = {
            id: "00",
            koda: "PAPER",
            naziv: "Papirnata oblika",
            emailEracun: "",
            iban: "",
            referenca: "",
            telefonskaStevilka: "",
            emailObvestila: "",
            pushSporocilo: "",
            operacija: ""
        };
        var kanalEmail: KanalRequest = {
            id: "01",
            koda: "EMAIL",
            naziv: "Spletni kanal",
            emailEracun: "",
            iban: "",
            referenca: "",
            telefonskaStevilka: "",
            emailObvestila: "",
            pushSporocilo: "",
            operacija: ""
        };
        var kanalEstoritve: KanalRequest = {
            id: "08",
            koda: "ESTORITVE",
            naziv: "Mobilna/Spletna aplikacija sEtoritve",
            emailEracun: "",
            iban: "",
            referenca: "",
            telefonskaStevilka: "",
            emailObvestila: "",
            pushSporocilo: "",
            operacija: ""
        };
        
        if (papirnaOblikaChecked) {
            kanalPapirnaOblika.operacija = this._izbranKanalId == "00" ? "SV" : "PK";
            kanalEmail.operacija = "OK";
            kanalEstoritve.operacija = "OK";
            kanali.push(kanalEmail);
            kanali.push(kanalEstoritve);
            kanali.push(kanalPapirnaOblika);
        }
        else if (emailPosiljanjeChecked) {
            kanalPapirnaOblika.operacija = "OK";
            kanalEmail.operacija = this._izbranKanalId == "01" ? "SV" : "PK";
            kanalEmail.emailEracun = email;
            kanalEstoritve.operacija = "OK";
            kanali.push(kanalEstoritve);
            kanali.push(kanalPapirnaOblika);
            kanali.push(kanalEmail);
        }
        else if (eStoritveChecked) {
            kanalPapirnaOblika.operacija = "OK"
            kanalEmail.operacija = "OK";
            kanalEstoritve.operacija = this._izbranKanalId == "08" ? "SV" : "PK";
            kanali.push(kanalPapirnaOblika);
            kanali.push(kanalEmail);
            kanali.push(kanalEstoritve);
        }

        var uspesno = false;
        var data: SpremembaTipaKanalaRequest = {
            stevilkaKontoPogodbe: this._izbranoMerilnoMesto.stevilkaKontoPogodbe,
            kanal: kanali
        };
        await this._merilnoMestoApiClient.spremembaTipaKanala(data)
            .then((odgovor: any) => {
                if (odgovor.kanal[0].status) {
                    var kanal = data.kanal.find(a => a.operacija != "OK");
                    if (kanal) {
                        this._izbranKanalId = kanal.id;
                    }
                    uspesno = true;   
                }
                else {
                    this._notyf.error(odgovor.kanal[0].sporocilo);
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });

        if (uspesno) {
            var nacinPosiljanjaRacunov = null;
            var informacijeRequest: MerilnoMestoInformacijeRequest = {
                merilnaTocka: this._izbranoMerilnoMesto.merilnaTocka,
                merilnoMesto: this._izbranoMerilnoMesto.merilnoMesto,
                stevilkaKontoPogodbe: this._izbranoMerilnoMesto.stevilkaKontoPogodbe
            };
            await this._merilnoMestoApiClient.getInformacije(informacijeRequest)
                .then((odgovor: MerilnoMestoInformacijeResponse) => {
                    if (odgovor.status) {
                        this._merilnoMestoInformacije = odgovor;
                        nacinPosiljanjaRacunov = this._merilnoMestoInformacije ? $.grep(this._merilnoMestoInformacije.nacinPosiljanjaRacunov, function (el) { return el.kanalAktiven == true }) : null;
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                });
            if (nacinPosiljanjaRacunov != null) {
                const templateString = `<%#nacinPosiljanjaRacunov%>
                                            <ul class="ul-data">
                                                <li class="li-data">
                                                    <div class="data-label"><%{koda}%></div>
                                                    <div class="data"><%{naziv}%></div>
                                                </li>
                                            </ul>
                                        <%/nacinPosiljanjaRacunov%>`;
                var customTags: any = ['<%', '%>'];
                var html = Mustache.render(
                    templateString,
                    {
                        "nacinPosiljanjaRacunov": nacinPosiljanjaRacunov,
                    },
                    undefined,
                    customTags
                );
                $("#posiljanje-racunov").html(html);
            }
            else {
                $("#posiljanje-racunov").html("");
            }
            this._notyf.success(this._translationService.currentTranslations["SaveSuccessful"]);
        }
    }
}