import { injectable } from "inversify";
import * as moment from "moment";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { MerilnoMesto } from "../ts/models/merilno-mesto";
import { UporabnikMerilnoMestoRequest } from "../ts/models/uporabnik-merilno-mesto-request";
import { TranslationService } from "../ts/translation-service";
import template from "./merilno-mesto-seznam-view.html";
import { FullscreenLoader } from "./fullscreen-loader";
import { UporabnikMerilnoMestoResponse } from "../ts/models/uporabnik-merilno-mesto-response";

@injectable()
export class MerilnoMestoSeznamView {
    private rootElement: JQuery<HTMLElement>;
    private _merilnoMestoSeznamElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _apiClient: MerilnoMestoApiClient;
    private _merilnaMesta: Array<MerilnoMesto> | null;
    private _currentPage: number;
    private _limit: number;
    private _maxPages: number;
    private _porabaMediana: number;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        apiClient: MerilnoMestoApiClient,
        private notyf: Notyf,
        private _loader: FullscreenLoader)
    {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._apiClient = apiClient;
        this._merilnaMesta = null;
        this._merilnoMestoSeznamElement = rootElement.find("#merilno-mesto-seznam");
        this._currentPage = 1;
        this._limit = 10;
        this._maxPages = -1;
        this._porabaMediana = 0;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;
            await this._getMerilnaMesta("");
            this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;
            this.rootElement.show();
            this._initForm();
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private async _getMerilnaMesta(merilnoMestoSearch: string) {
        var uporabnikMerilnoMestoRequest: UporabnikMerilnoMestoRequest = {
            limit: this._limit,
            stevilkaStrani: this._currentPage,
            saldo: true,
            skupnaPorabaEnergije: true,
            merilnoMesto: merilnoMestoSearch,
            merilnaTocka: "" // ne iscemo po merilno tocko
        };     
        await this._apiClient.getUporabnikMerilnaMesta(uporabnikMerilnoMestoRequest)
            .then((odgovor: UporabnikMerilnoMestoResponse) => {
                this._maxPages = Math.ceil(odgovor.steviloVsehMerilnihMestUporabniku / this._limit);
                this._merilnaMesta = odgovor.merilnaMesta;
                this._porabaMediana = odgovor.porabaMediana;
                if (this._merilnaMesta && this._merilnaMesta.length > 0) {
                    this._merilnaMesta.forEach((item) => {
                        if (item.obdobjePorabeOd == null || item.obdobjePorabeDo == null) {
                            item.obstajaOdcitekPorabe = false;
                        }
                        else {
                            item.obstajaOdcitekPorabe = true;
                        }
                    })
                }
            })
            .catch(() => {
                this.notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSearchingForMeasuringPoints"]);
            });
    }

    private _renderData(translations: any): void {
        var localeFormat = moment().creationData().locale.longDateFormat("L");
        var formators = {
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            }//tukaj se lahko dodajajo formatorji
        };

        const viewModel = {
            formators: formators,
            merilnaMesta: this._merilnaMesta ? this._merilnaMesta : null,
            obstajaMerilnoMesto: this._merilnaMesta && this._merilnaMesta.length > 0 ? true : false,
            maxPages: this._maxPages,
            currentPage: this._currentPage,
            translations: translations
        } as any;

        const html = Mustache.render(template, viewModel);
        this._merilnoMestoSeznamElement.html(html);
        window.scrollTo(0, 0);
    }

    private _initForm() {
        // v vsakem primeru dodamo event listener iskalniku
        var btnPoiscite = $("#poiscite");
        btnPoiscite.on("click", (ev: Event) => this._onIsciMerilnoMestoClick(ev));

        if (!this._merilnaMesta) {
            return;
        }

        for (let i = 0; i < this._merilnaMesta.length; i++) {
            var porabaProcent = this._merilnaMesta[i].porabaEnergije / this._porabaMediana * 100;
            if (porabaProcent > 100) {
                porabaProcent = 100;
            }
            var tekocaPoraba = $(".progress-bar-tekoca-poraba");
            if (tekocaPoraba.length != 0) {
                tekocaPoraba[i].style.width = porabaProcent.toString() + "%";
                $(".progress-number-tekoca-poraba")[i].style.right = "calc(50% - " + porabaProcent.toString() + "%)";
                $(".progress-number-tekoca-poraba")[i].innerHTML = this._merilnaMesta[i].porabaEnergije + " " + this._merilnaMesta[i].mernaEnota;
            }
        }

        var mesecnaPorabaButtons = $(".mesecna-poraba");
        for (let i = 0; i < mesecnaPorabaButtons.length; i++) {
            mesecnaPorabaButtons[i].addEventListener("click", (ev: Event) => this._onMesecnaPorabaClick(ev, i));
        }

        var btnPreviousPage = $(".btnPreviousPage");
        btnPreviousPage[0].addEventListener("click", async () => {
            if (this._currentPage - 1 == 0) return;
            this._loader.show();
            this._currentPage -= 1;
            var merilnoMestoSearch = ($(".merilno-mesto-search-field")[0] as HTMLInputElement).value;
            await this._getMerilnaMesta(merilnoMestoSearch);
            this._renderData(this._translationService.currentTranslations);
            this._initForm();
            ($(".merilno-mesto-search-field")[0] as HTMLInputElement).value = merilnoMestoSearch;
            this._loader.hide();
        });

        var btnNextPage = $(".btnNextPage");
        btnNextPage[0].addEventListener("click", async () => {
            if (this._currentPage + 1 > this._maxPages) return;
            this._loader.show();
            this._currentPage += 1;
            var merilnoMestoSearch = ($(".merilno-mesto-search-field")[0] as HTMLInputElement).value;
            await this._getMerilnaMesta(merilnoMestoSearch);
            this._renderData(this._translationService.currentTranslations);
            this._initForm();
            ($(".merilno-mesto-search-field")[0] as HTMLInputElement).value = merilnoMestoSearch;
            this._loader.hide();
        });
    }

    private async _onIsciMerilnoMestoClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        this._loader.show();
        var merilnoMestoSearch = ($(".merilno-mesto-search-field")[0] as HTMLInputElement).value;
        this._currentPage = 1;
        await this._getMerilnaMesta(merilnoMestoSearch);
        this._renderData(this._translationService.currentTranslations);
        this._initForm();
        ($(".merilno-mesto-search-field")[0] as HTMLInputElement).value = merilnoMestoSearch;
        if (this._merilnaMesta == null) {
            $(".ni-merilnih-mest")[0].innerHTML = this._translationService.currentTranslations["MeasuringPointDoesNotExist"];
        }
        this._loader.hide();
    }

    private _onMesecnaPorabaClick(ev: Event, indexMerilnegaMesta: number) {
        console.debug(ev);
        ev.preventDefault();
        if (!this._merilnaMesta) {
            return;
        }
        var merilnoMesto = this._merilnaMesta[indexMerilnegaMesta];
        if (merilnoMesto.jeElektrika) {
            window.location.href = "#/merilno-mesto/poraba/" + "poraba-pregled/" + merilnoMesto.merilnaTocka;
        }
        else {
            window.location.href = "#/merilno-mesto/poraba/" + "poraba-pregled/" + merilnoMesto.merilnoMesto;
        }
    }
}