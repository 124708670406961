import { injectable } from "inversify";
import "jquery-validation-unobtrusive";
import { Authenticator } from "../../../skupno/src/ts/authentication/authenticator";

@injectable()
export class MojProfilOdjavaView {

    constructor(
        private authenticator: Authenticator) {
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            this.authenticator.startLogout();
        }
        catch (e) {
            throw e;
        }
    }
}