import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { AKodaMerilnoMestoResponse } from "../models/akoda-merilno-mesto-response";
import { DirektnaObremenitevModel } from "../models/direktna-obremenitev";
import { EnergetskaIzkaznicaRequest } from "../models/energetska-izkaznica.ts-request";
import { InformativniIzracunPorabeModel } from "../models/informativni-izracun-porabe";
import { ListResponse } from "../models/list-response";
import { MerilnoMestoInformacijeRequest } from "../models/merilno-mesto-informacije-request";
import { MerilnoMestoOdstrani } from "../models/merilno-mesto-odstrani";
import { NacinObracunaRequest } from "../models/nacin-obracuna-request";
import { RacuniInPlacilaRequestModel } from "../models/racuni-in-placila-request-model";
import { VnosStanjeStevcaRequest } from "../models/vnos-stanje-stevca-request";
import { ZgodovinaOdcitkovRequest } from "../models/zgodovina-odcitkov-request";
import { VlogeSoglasjeLastnikaZaEvidentiranjePlacnikaRequest } from "../models/vloge-soglasje-lastnika-za-evidentiranje-placnika-request";
import { PorabaOdjemalcaRequest } from "../models/poraba-odjemalca-request";
import { PorabaNapravIzracunRequest } from "../models/poraba-naprav-izracun-request";
import { PorabaNapravPorabnikiResponse } from "../models/poraba-naprav-porabniki-response";
import { DashboardData } from "../models/dashboard";
import { UporabnikMerilnoMestoRequest } from "../models/uporabnik-merilno-mesto-request";
import { SpremembaTipaKanalaRequest } from "../models/sprememba-tipa-kanala-request";
import { NarociloMinutneMeritveRequest } from "../models/narocilo-minutne-meritve";
import { OdbirekVrniZadnjiElektrikaRequest } from "../models/odbirek-vrni-zadnji-elektrika-request";
import { OdbirekVrniZadnjiPlinRequest } from "../models/odbirek-vrni-zadnji-plin-request";
import { MerilnoMestoInformacijeResponse } from "../models/merilno-mesto-informacije-response";
import { OdbirekVrniZadnjiElektrikaResponse } from "../models/odbirek-vrni-zadnji-elektrika-response";
import { OdbirekVrniZadnjiPlinResponse } from "../models/odbirek-vrni-zadnji-plin-response";
import { FakturiranaPorabaRequest } from "../models/fakturirana-poraba-request";
import { FakturiranaPorabaElektrikaResponse } from "../models/fakturirana-poraba-elektrika-response";
import { FakturiranaPorabaPlinResponse } from "../models/fakturirana-poraba-plin-response";
import { FileDownloadInfo } from "../models/file-download-info";
import { UporabnikPoslovnePartnerjeRequest } from "../models/uporabnik-poslovne-partnerje-request";
import { ZbirnikRacunovRequest } from "../models/zbirnik-racunov-request";
import { VlogePrijavaPrejemaElektronskegaRacunaRequest } from "../models/vloge-prijava-prejema-elektronskega-racuna-request";
import { VlogeEvidentiranjeNaslovaZaPosiljanjePosteRequest } from "../models/vloge-evidentiranje-naslova-za-posiljanje-poste-request";
import { VlogeDirektnaObremenitevRequest } from "../models/vloge-direktna-obremenitev-request";
import { DownloadedFile } from "../models/download-file";
import { VlogeZlataUraRequest } from "../models/vloge-zlata-ura-request";
//import { DownloadedFile } from "../models/download-file";
//import { parse } from "content-disposition-header";

@injectable()
export class MerilnoMestoApiClient extends ApiClientBase {
    public name = "MerilnoMestoApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator") authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async getDashboard(): Promise<DashboardData> {
        return await this._callApi('/merilnomesto/dashboard', 'GET', null, false) as DashboardData;
    }

    public async getUporabnikMerilnaMesta(data: UporabnikMerilnoMestoRequest, uporabnikId?: number): Promise<any> {
        if (!uporabnikId) {
            return await this._callApi('/merilnomesto/uporabnik/merilnaMesta/-1', 'POST', JSON.stringify(data), false) as any;
        }
        return await this._callApi('/merilnomesto/uporabnik/merilnaMesta/' + uporabnikId, 'POST', JSON.stringify(data), false) as any;
    }

    public async getMerilnoMestoByCode(code: string): Promise<ListResponse<AKodaMerilnoMestoResponse>> {
        const data: JSON = <JSON><unknown>{
            "code": code,
        };
        return await this._callApi('/merilnomesto/getByCode', 'GET', data, false) as ListResponse<AKodaMerilnoMestoResponse>;
    }

    public async odstraniMerilnaMesta(data: Array<MerilnoMestoOdstrani>, uporabnikId?: number): Promise<any> {
        if (!uporabnikId) {
            return await this._callApi('/merilnomesto/delete/-1', 'POST', JSON.stringify(data), false) as any;
        }
        return await this._callApi('/merilnomesto/delete/' + uporabnikId, 'POST', JSON.stringify(data), false) as any;
    }

    public async dodajMerilnaMesta(data: Array<AKodaMerilnoMestoResponse>, uporabnikId?: number): Promise<any> {
        if (!uporabnikId) {
            return await this._callApi('/merilnomesto/add/-1', 'POST', JSON.stringify(data), false) as any;
        }
        return await this._callApi('/merilnomesto/add/' + uporabnikId, 'POST', JSON.stringify(data), false) as any;
    }

    public async getRacuniInPlacila(data: RacuniInPlacilaRequestModel): Promise<any> {
        return await this._callApi('/merilnomesto/racuni-in-placila', 'POST', JSON.stringify(data), false) as any;
    }

    public async vnosStanjeStevca(data: VnosStanjeStevcaRequest): Promise<any> {
        return await this._callApi('/merilnomesto/vnos-stanje-stevca', 'POST', JSON.stringify(data), false) as any;
    }

    public async informativniIzracunPorabe(data: InformativniIzracunPorabeModel): Promise<any> {
        return await this._callApi('/merilnomesto/informativni-izracun-porabe', 'POST', JSON.stringify(data), false) as any;
    }

    public async podpisPrekoEperes(data: DirektnaObremenitevModel): Promise<any> {
        return await this._callApi('/merilnomesto/direktnaObremenitev/podpisPrekoEperes', 'POST', JSON.stringify(data), false) as any;
    }

    public async prenesiPdf(data: DirektnaObremenitevModel): Promise<any> {
        return await this._callApi('/merilnomesto/direktnaObremenitev/prenesiPdf', 'POST', JSON.stringify(data), false) as any;
    }

    public async getEnergetskaIzkaznica(data: EnergetskaIzkaznicaRequest): Promise<any> {
        return await this._callApi('/merilnomesto/energetska-izkaznica', 'POST', JSON.stringify(data), false) as any;
    }

    public async getZgodovinaOdcitkov(data: ZgodovinaOdcitkovRequest): Promise<any> {
        return await this._callApi('/merilnomesto/zgodovina-odcitkov', 'POST', JSON.stringify(data), false) as any;
    }

    public async spremembaAkontacije(data: NacinObracunaRequest): Promise<any> {
        return await this._callApi('/merilnomesto/sprememba-akontacije', 'POST', JSON.stringify(data), false) as any;
    }

    public async getInformacije(data: MerilnoMestoInformacijeRequest): Promise<MerilnoMestoInformacijeResponse> {
        return await this._callApi('/merilnomesto/informacije', 'POST', JSON.stringify(data), false) as MerilnoMestoInformacijeResponse;
    }

    public async racunPdfDownload(stevilkaDokumenta: string): Promise<FileDownloadInfo> {
        const data = {};
        var file: FileDownloadInfo = null as any;
        var url = "/merilnomesto/racun-pdf/" + stevilkaDokumenta + "/download";
        await this._callApi(url, 'GET', data, true, "json", (data, _status) => {
            var downloadUrl = data.downloadUrl;
            file = {
                downloadUrl: downloadUrl
            } as FileDownloadInfo;
        });
        return file;
    }

    public async getMinutneMeritve(data: PorabaOdjemalcaRequest): Promise<any> {
        return await this._callApi('/merilnomesto/poraba-odjemalca', 'POST', JSON.stringify(data), false) as any;
    }

    public async porabaNapravIzracun(data: PorabaNapravIzracunRequest): Promise<any> {
        return await this._callApi('/merilnomesto/poraba-naprav-izracun', 'POST', JSON.stringify(data), false) as any;
    }

    public async getPorabniki(): Promise<PorabaNapravPorabnikiResponse> {
        return await this._callApi('/merilnomesto/poraba-naprav-porabnik', 'GET', null, false) as PorabaNapravPorabnikiResponse;
    }

    public async spremembaTipaKanala(data: SpremembaTipaKanalaRequest): Promise<any> {
        return await this._callApi('/merilnomesto/sprememba-tipa-kanala', 'POST', JSON.stringify(data), false) as any;
    }

    public async narociMinutneMeritve(data: NarociloMinutneMeritveRequest): Promise<any> {
        return await this._callApi('/merilnomesto/narocilo-minutne-meritve', 'POST', JSON.stringify(data), false) as any;
    }

    public async odbirekVrniZadnjiElektrika(data: OdbirekVrniZadnjiElektrikaRequest): Promise<OdbirekVrniZadnjiElektrikaResponse> {
        return await this._callApi('/merilnomesto/odbirek-vrni-zadnji-elektrika', 'POST', JSON.stringify(data), false) as OdbirekVrniZadnjiElektrikaResponse;
    }

    public async odbirekVrniZadnjiPlin(data: OdbirekVrniZadnjiPlinRequest): Promise<OdbirekVrniZadnjiPlinResponse> {
        return await this._callApi('/merilnomesto/odbirek-vrni-zadnji-plin', 'POST', JSON.stringify(data), false) as OdbirekVrniZadnjiPlinResponse;
    }

    public async fakturiranaPoraba(data: FakturiranaPorabaRequest, jeElektrika: boolean): Promise<any> {
        if (jeElektrika) {
            return await this._callApi('/merilnomesto/fakturirana-poraba-elektrika', 'POST', JSON.stringify(data), false) as FakturiranaPorabaElektrikaResponse;
        }
        else {
            return await this._callApi('/merilnomesto/fakturirana-poraba-plin', 'POST', JSON.stringify(data), false) as FakturiranaPorabaPlinResponse;
        }
    }

    public async getStevilkeKontoPogodb(uporabnikId?: number): Promise<any> {
        if (!uporabnikId) {
            return await this._callApi('/merilnomesto/uporabnik/stevilke-konto-pogodb/-1', 'POST', null, false) as any;
        }
        return await this._callApi('/merilnomesto/uporabnik/stevilke-konto-pogodb/' + uporabnikId, 'POST', null, false) as any;
    }

    public async informacijaOPorabiDownload(porabaSeznam: any, jeElektrika: boolean, jeEnotarifni: boolean, jeDvotarifni: boolean): Promise<FileDownloadInfo> {
        var file: FileDownloadInfo = null as any;
        var url = "";
        // elektrika
        if (jeElektrika) {
            // mesan
            if (jeEnotarifni && jeDvotarifni) {
                url = "/merilnomesto/informacija-o-porabi-elektrika/generate-file/3";
            }
            // dvotarifni
            else if (jeDvotarifni) {
                url = "/merilnomesto/informacija-o-porabi-elektrika/generate-file/2";
            }
            // enotarifni
            else {
                url = "/merilnomesto/informacija-o-porabi-elektrika/generate-file/1";
            }
        }
        // plin
        else {
            url = "/merilnomesto/informacija-o-porabi-plin/download";
        }
        await this._callApi(url, 'POST', JSON.stringify(porabaSeznam), true, "json", (data, _status) => {
            var downloadUrl = data.downloadUrl;
            file = {
                downloadUrl: downloadUrl
            } as FileDownloadInfo;
        });
        return file;
    }

    public async energetskaIzkaznicaPorabaDownload(porabaSeznam: any, jeElektrika: boolean): Promise<FileDownloadInfo> {
        var file: FileDownloadInfo = null as any;
        var url = "";
        if (jeElektrika) {
            url = "/merilnomesto/energetska-izkaznica-poraba-elektrika/download";
        }
        else {
            url = "/merilnomesto/energetska-izkaznica-poraba-plin/download";
        }
        /*await this._callApi(url, 'POST', JSON.stringify(porabaSeznam), true, "json", (data, _status) => {*/
        await this._callApi(url, 'POST', JSON.stringify(porabaSeznam), true, "json", (data, _status) => {
            //var contentDisposition = jqueryXhr.getResponseHeader("Content-Disposition");
            //var filename: string = "";
            //if (contentDisposition) {
            //    var contentDispositionParsed = parse(contentDisposition as string);
            //    filename = contentDispositionParsed.parameters.filename;
            //}
            //var filetype = jqueryXhr.getResponseHeader("Content-Type");
            var downloadUrl = data.downloadUrl;
            file = {
                downloadUrl: downloadUrl
            } as FileDownloadInfo;
        });
        return file;
    }

    public async fakturiranaPorabaDownload(data: any, jeElektrika: boolean, jeSumarno: boolean): Promise<FileDownloadInfo> {
        var file: FileDownloadInfo = null as any;
        var url = "";
        if (jeElektrika) {
            if (jeSumarno) {
                url = "/merilnomesto/fakturirana-poraba/download/1";
            }
            else {
                url = "/merilnomesto/fakturirana-poraba/download/2";
            }
        }
        else {
            if (jeSumarno) {
                url = "/merilnomesto/fakturirana-poraba/download/3";
            }
            else {
                url = "/merilnomesto/fakturirana-poraba/download/4";
            }
        }
        await this._callApi(url, 'POST', JSON.stringify(data), true, "json", (data, _status) => {
            //var contentDisposition = jqueryXhr.getResponseHeader("Content-Disposition");
            //var filename: string = "";
            //if (contentDisposition) {
            //    var contentDispositionParsed = parse(contentDisposition as string);
            //    filename = contentDispositionParsed.parameters.filename;
            //}
            /*var filetype = jqueryXhr.getResponseHeader("Content-Type");*/
            var downloadUrl = data.downloadUrl;
            file = {
                downloadUrl: downloadUrl
            } as FileDownloadInfo;
        });
        return file;
    }

    public async pridobiSodo(): Promise<any> {
        return await this._callApi('/merilnomesto/pridobi-sodo', 'GET', null, false) as any;
    }

    public async uporabnikPoslovnePartnerje(data: UporabnikPoslovnePartnerjeRequest): Promise<any> {
        return await this._callApi('/merilnomesto/uporabnik-poslovne-partnerje', 'POST', JSON.stringify(data), false) as any;
    }

    public async zbirnikRacunov(data: ZbirnikRacunovRequest): Promise<any> {
        return await this._callApi('/merilnomesto/zbirnik-racunov', 'POST', JSON.stringify(data), false) as any;
    }

    public async zbirnikRacunovDownload(seznam: any): Promise<FileDownloadInfo> {
        var file: FileDownloadInfo = null as any;
        var url = "/merilnomesto/download-zbirnik-racunov";
        await this._callApi(url, 'POST', JSON.stringify(seznam), true, "json", (data, _status) => {
            var downloadUrl = data.downloadUrl;
            file = {
                downloadUrl: downloadUrl
            } as FileDownloadInfo;
        });
        return file;
    }

    public async pregledMinutneMeritveDownload(porabaSumarno: any): Promise<FileDownloadInfo> {
        var file: FileDownloadInfo = null as any;
        var url = "/merilnomesto/download-pregled-minutne-meritve";       
        await this._callApi(url, 'POST', JSON.stringify(porabaSumarno), true, "json", (data, _status) => {
            var downloadUrl = data.downloadUrl;
            file = {
                downloadUrl: downloadUrl
            } as FileDownloadInfo;
        });
        return file;
    }

    public async eperoDirektnaObremenitev(data: VlogeDirektnaObremenitevRequest): Promise<any> {
        return await this._callApi('/vloge/epero/direktna-obremenitev', 'POST', JSON.stringify(data), false) as any;
    }

    public async eperoZlataUra(data: VlogeZlataUraRequest): Promise<any> {
        return await this._callApi('/vloge/epero/zlata-ura', 'POST', JSON.stringify(data), false) as any;
    }

    public async eperoPrijavaPrejemaElektronskegaRacuna(data: VlogePrijavaPrejemaElektronskegaRacunaRequest): Promise<any> {
        return await this._callApi('/vloge/epero/prijava-prejema-elektronskega-racuna', 'POST', JSON.stringify(data), false) as any;
    }

    public async eperoSoglasjeLastnikaZaEvidentiranjePlacnika(data: VlogeSoglasjeLastnikaZaEvidentiranjePlacnikaRequest): Promise<any> {
        return await this._callApi('/vloge/epero/soglasje-lastnika-za-evidentiranje-placnika', 'POST', JSON.stringify(data), false) as any;
    }

    public async eperoEvidentiranjeNaslovaZaPosiljanjePoste(data: VlogeEvidentiranjeNaslovaZaPosiljanjePosteRequest): Promise<any> {
        return await this._callApi('/vloge/epero/evidentiranje-naslova-za-posiljanje-poste', 'POST', JSON.stringify(data), false) as any;
    }

    public async eperoSpremembaLastnikaMerilnoMesto(data: FormData): Promise<DownloadedFile> {
        //return await this._callApi('/vloge/epero/sprememba-lastnika-merilno-mesto', 'POST', JSON.stringify(data), false) as any;
        return await this._callApi('/vloge/epero/sprememba-lastnika-merilno-mesto', 'POST', data, true, "", null, null, (settings) => {
            settings.processData = false;
            settings.contentType = false;
        }) as any;
    }

    public async vlogeDirektnaObremenitevPdfDownload(data: VlogeDirektnaObremenitevRequest): Promise<FileDownloadInfo> {
        var file: FileDownloadInfo = null as any;
        var url = "/vloge/download-pdf/direktna-obremenitev";
        await this._callApi(url, 'POST', JSON.stringify(data), true, "json", (data, _status) => {
            var downloadUrl = data.downloadUrl;
            file = {
                downloadUrl: downloadUrl
            } as FileDownloadInfo;
        });
        return file;
    }

    public async vlogeZlataUraPdfDownload(data: VlogeZlataUraRequest): Promise<FileDownloadInfo> {
        var file: FileDownloadInfo = null as any;
        var url = "/vloge/download-pdf/zlata-ura";
        await this._callApi(url, 'POST', JSON.stringify(data), true, "json", (data, _status) => {
            var downloadUrl = data.downloadUrl;
            file = {
                downloadUrl: downloadUrl
            } as FileDownloadInfo;
        });
        return file;
    }

    public async vlogePrijavaPrejemanjeElektronskegaRacunaDownload(data: VlogePrijavaPrejemaElektronskegaRacunaRequest): Promise<FileDownloadInfo> {
        var file: FileDownloadInfo = null as any;
        var url = "/vloge/download-pdf/prijava-prejema-elektronskega-racuna";
        await this._callApi(url, 'POST', JSON.stringify(data), true, "", (data, _status) => {
            var downloadUrl = data.downloadUrl;
            file = {
                downloadUrl: downloadUrl
            } as FileDownloadInfo;
        });
        return file;
    }

    public async vlogeSoglasjeLastnikaZaEvidentiranjePlacnikaDownload(data: VlogeSoglasjeLastnikaZaEvidentiranjePlacnikaRequest): Promise<FileDownloadInfo> {
        var file: FileDownloadInfo = null as any;
        var url = "/vloge/download-pdf/soglasje-lastnika-za-evidentiranje-placnika";
        await this._callApi(url, 'POST', JSON.stringify(data), true, "json", (data, _status) => {
            var downloadUrl = data.downloadUrl;
            file = {
                downloadUrl: downloadUrl
            } as FileDownloadInfo;
        });
        return file;
    }

    public async vlogeEvidentiranjeNaslovaZaPosiljanjePosteDownload(data: VlogeEvidentiranjeNaslovaZaPosiljanjePosteRequest): Promise<FileDownloadInfo> {
        var file: FileDownloadInfo = null as any;
        var url = "/vloge/download-pdf/evidentiranje-naslova-za-posiljanje-poste";
        await this._callApi(url, 'POST', JSON.stringify(data), true, "json", (data, _status) => {
            var downloadUrl = data.downloadUrl;
            file = {
                downloadUrl: downloadUrl
            } as FileDownloadInfo;
        });
        return file;
    }

    public async vlogeSpremembaLastnikaMerilnoMestoDownload(data: FormData): Promise<FileDownloadInfo> {
        debugger;
        var file: FileDownloadInfo = null as any;
        var url = "/vloge/download-pdf/sprememba-lastnika-merilno-mesto";
        var response = await this._callApi(url, 'POST', data, true, "", null, null, (settings) => {
            settings.processData = false;
            settings.contentType = false; 
        });
        debugger;
        var downloadUrl = response.downloadUrl;
        file = {
            downloadUrl: downloadUrl
        } as FileDownloadInfo;
        return file;
    }
} 
