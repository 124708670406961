import { injectable } from "inversify";
import * as moment from "moment";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { TranslationService } from "../ts/translation-service";
import * as DateRangePicker from 'daterangepicker';
import template from "./merilno-mesto-racuni-view.html";
import { RacuniInPlacilaRequestModel } from "../ts/models/racuni-in-placila-request-model";
import * as ApexCharts from 'apexcharts';
import { FullscreenLoader } from "./fullscreen-loader";
import racuniGrafView from "./merilno-mesto-racuni-graf-view.html";
import { UporabnikMerilnoMestoRequest } from "../ts/models/uporabnik-merilno-mesto-request";
import { MerilnoMesto } from "../ts/models/merilno-mesto";
import { UporabnikMerilnoMestoResponse } from "../ts/models/uporabnik-merilno-mesto-response";
import primerjavaGrafView from "./merilno-mesto-primerjava-graf-view.html";

@injectable()
export class MerilnoMestoRacuniView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _dropdownMerilnaMestaElement: JQuery<HTMLElement> | null;
    private _formElement: JQuery<HTMLElement> | null;
    private _racuniObdobjeOdPicker: DateRangePicker = null!;
    private _racuniObdobjeDoPicker: DateRangePicker = null!;
    private _izbranoMerilnoMesto: MerilnoMesto | null | undefined;
    private _merilnaMesta: Array<MerilnoMesto> | null;
    private _limit: number;
    private _formators: any;
    private _racuni1PrimerjavaOdPicker: DateRangePicker = null!;
    private _racuni1PrimerjavaDoPicker: DateRangePicker = null!;
    private _racuni2PrimerjavaOdPicker: DateRangePicker = null!;
    private _racuni2PrimerjavaDoPicker: DateRangePicker = null!;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        merilnoMestoApiClient: MerilnoMestoApiClient,
        private _notyf: Notyf,
        private _loader: FullscreenLoader) {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._dropdownMerilnaMestaElement = null;
        this._formElement = null;
        this._izbranoMerilnoMesto = null;
        this._merilnaMesta = null;
        this._limit = 10;
        this._formators = null;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;
            var uporabnikMerilnoMestoRequest: UporabnikMerilnoMestoRequest = {
                limit: 0,
                stevilkaStrani: 0,
                saldo: false,
                skupnaPorabaEnergije: false,
                merilnoMesto: "",
                merilnaTocka: ""
            };
            await this._merilnoMestoApiClient.getUporabnikMerilnaMesta(uporabnikMerilnoMestoRequest)
                .then((odgovor: UporabnikMerilnoMestoResponse) => {
                    this._merilnaMesta = odgovor.merilnaMesta;
                    if (this._merilnaMesta && this._merilnaMesta.length > 0) {
                        // poiscemo ali obstaja selektirano MM (v querystringu)
                        var url_string = window.location.href.split("?");
                        var url_data = url_string[url_string.length - 1];
                        var data_list = url_data.split("_");
                        const stevilkaKontoPogodbe = data_list[data_list.length - 1];
                        const merilnoMesto = data_list[data_list.length - 2];
                        const jeElektrika = data_list[data_list.length - 3];
                        const merilnaTocka = data_list[data_list.length - 4];

                        if (jeElektrika == 'true') {
                            this._izbranoMerilnoMesto = this._merilnaMesta.find(
                                a => a.merilnoMesto == merilnoMesto && a.merilnaTocka == merilnaTocka && a.stevilkaKontoPogodbe == stevilkaKontoPogodbe);
                        }
                        else if (jeElektrika == 'false') {
                            this._izbranoMerilnoMesto = this._merilnaMesta.find(
                                a => a.merilnoMesto == merilnoMesto && a.stevilkaKontoPogodbe == stevilkaKontoPogodbe);
                        }
                        // ce v querystringu ni doloceno MM, selektiramo prvega MM iz liste
                        if (!this._izbranoMerilnoMesto) {
                            this._izbranoMerilnoMesto = this._merilnaMesta[0];
                        }
                    }
                    else {
                        this.rootElement.text(this._translationService.currentTranslations["YouDoNotHaveAnyMeasuringPoint"]);
                        return;
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSearchingForMeasuringPoints"]);
                });

            await this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;
            this._initForm();
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(translations: any): void {
        if (!this._merilnaMesta) {
            return;
        }
        var merilnaMestaIds = Array.from(new Set(this._merilnaMesta.map((item) => item.merilnoMesto)));
        var steviloPrikazanih = 3;
        var prikazanaMerilnaMesta = merilnaMestaIds.slice(0, steviloPrikazanih);
        if (this._merilnaMesta.length < steviloPrikazanih) {
            prikazanaMerilnaMesta = merilnaMestaIds;
        }

        var localeFormat = moment().creationData().locale.longDateFormat("L");
        this._formators = {
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            }//tukaj se lahko dodajajo formatorji
        };
        const viewModel = {
            merilnaMestaIds: merilnaMestaIds,
            merilnaMesta: this._merilnaMesta,
            izbranoMerilnoMesto: this._izbranoMerilnoMesto,
            ostalaMerilnaMestaStevilo: merilnaMestaIds.length - prikazanaMerilnaMesta.length,
            obstajajoOstalaMerilnaMesta: merilnaMestaIds.length - prikazanaMerilnaMesta.length > 0 ? true : false,
            prikazanaMerilnaMesta: prikazanaMerilnaMesta,
            translations: translations,
            formators: this._formators
        } as any;
        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _initForm(): void {
        if (!this._izbranoMerilnoMesto) {
            return;
        }
        require('jquery-validation-unobtrusive');
        jQuery.validator.unobtrusive.parse($('#main').find(".prikaz-racunov"));

        this._dropdownMerilnaMestaElement = $("#dropdownMerilnaMesta");
        this._dropdownMerilnaMestaElement.on("change", (ev: Event) => this._onDropDownMerilnoMestoChange(ev));
        if (this._izbranoMerilnoMesto.jeElektrika) {
            this._dropdownMerilnaMestaElement.val(this._izbranoMerilnoMesto.jeElektrika + "_" + this._izbranoMerilnoMesto.stevilkaKontoPogodbe + "_" + this._izbranoMerilnoMesto.merilnoMesto + "_" + this._izbranoMerilnoMesto.merilnaTocka);
        }
        else {
            this._dropdownMerilnaMestaElement.val(this._izbranoMerilnoMesto.jeElektrika + "_" + this._izbranoMerilnoMesto.stevilkaKontoPogodbe + "_" + this._izbranoMerilnoMesto.merilnoMesto);
        }

        var btnMerilnaMestaOthers = $("#btnMerilnaMestaOthers");
        btnMerilnaMestaOthers.on("click", () => {
            $(".merilna-mesta-not-all")[0].style.display = "none";
            $(".merilna-mesta-all")[0].style.display = "block";
        });

        this._racuniObdobjeOdPicker = new DateRangePicker($('#main').find("#racuniObdobjeOd")[0], {
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            minDate: moment().add(-20, 'years'),
            maxDate: moment()
        });
        $("#racuniObdobjeOd").on("change", () => {
            $("#racuni-skupaj-v-izbranem-obdobju").hide();
            $("#racuni-graf").hide();
            $(".tabela-racuni").hide();
            $(".racuni-ne-obstajajo").hide();
            $("#primerjava-graf").hide();
        });

        this._racuniObdobjeDoPicker = new DateRangePicker($('#main').find("#racuniObdobjeDo")[0], {
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            minDate: moment().add(-20, 'years'),
            maxDate: moment()
        });
        $("#racuniObdobjeDo").on("change", () => {
            $("#racuni-skupaj-v-izbranem-obdobju").hide();
            $("#racuni-graf").hide();
            $(".tabela-racuni").hide();
            $(".racuni-ne-obstajajo").hide();
            $("#primerjava-graf").hide();
        });

        var dropdownObdobje = $("#dropdownObdobje");
        dropdownObdobje.on("change", () => {
            if (dropdownObdobje.val() == "1") {
                var now = new Date();
                this._racuniObdobjeOdPicker.setStartDate(new Date(now.getFullYear(), now.getMonth() - 1, 1));
                this._racuniObdobjeDoPicker.setStartDate(new Date(now.getFullYear(), now.getMonth(), 1));
            }
            else if (dropdownObdobje.val() == "2") {
                var now = new Date();
                this._racuniObdobjeOdPicker.setStartDate(new Date(now.getFullYear(), now.getMonth() - 6, 1));
                this._racuniObdobjeDoPicker.setStartDate(new Date(now.getFullYear(), now.getMonth(), 1));
            }
            else if (dropdownObdobje.val() == "3") {
                var now = new Date();
                this._racuniObdobjeOdPicker.setStartDate(new Date(now.getFullYear() - 1, now.getMonth(), 1));
                this._racuniObdobjeDoPicker.setStartDate(new Date(now.getFullYear(), now.getMonth(), 1));
            }
            else if (dropdownObdobje.val() == "4") {
                var now = new Date();
                this._racuniObdobjeOdPicker.setStartDate(new Date(now.getFullYear(), 0, 1));
                this._racuniObdobjeDoPicker.setStartDate(new Date(now.getFullYear(), now.getMonth(), 1));
            }
            else if (dropdownObdobje.val() == "5") {
                var now = new Date();
                this._racuniObdobjeOdPicker.setStartDate(new Date(now.getFullYear() - 1, 0, 1));
                this._racuniObdobjeDoPicker.setStartDate(new Date(now.getFullYear(), 0, 1));
            }
            $("#racuni-skupaj-v-izbranem-obdobju").hide();
            $("#racuni-graf").hide();
            $(".tabela-racuni").hide();
            $(".racuni-ne-obstajajo").hide();
            $("#primerjava-graf").hide();
        });

        var dropdownPrimerjavaRacuni = $("#dropdownPrimerjavaRacuni");
        dropdownPrimerjavaRacuni.on("change", () => {
            if (dropdownPrimerjavaRacuni.val() == "3") {
                $("#primerjava-koledar").show();
            }
            else {
                $("#primerjava-koledar").hide();
            }
            $("#racuni-skupaj-v-izbranem-obdobju").hide();
            $("#racuni-graf").hide();
            $(".tabela-racuni").hide();
            $(".racuni-ne-obstajajo").hide();
            $("#primerjava-graf").hide();
        });

        var now = new Date();
        this._racuni1PrimerjavaOdPicker = new DateRangePicker($('#main').find("#racuni1PrimerjavaOd")[0], {
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            startDate: new Date(now.getFullYear() - 1, now.getMonth() - 1, 1),
            minDate: moment().add(-20, 'years'),
            maxDate: moment()
        });
        $("#racuni1PrimerjavaOd").on("change", () => {
            $("#racuni-skupaj-v-izbranem-obdobju").hide();
            $("#racuni-graf").hide();
            $(".tabela-racuni").hide();
            $(".racuni-ne-obstajajo").hide();
            $("#primerjava-graf").hide();
        });

        this._racuni1PrimerjavaDoPicker = new DateRangePicker($('#main').find("#racuni1PrimerjavaDo")[0], {
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            startDate: new Date(now.getFullYear() - 1, now.getMonth(), 1),
            minDate: moment().add(-20, 'years'),
            maxDate: moment()
        });
        $("#racuni1PrimerjavaDo").on("change", () => {
            $("#racuni-skupaj-v-izbranem-obdobju").hide();
            $("#racuni-graf").hide();
            $(".tabela-racuni").hide();
            $(".racuni-ne-obstajajo").hide();
            $("#primerjava-graf").hide();
        });

        this._racuni2PrimerjavaOdPicker = new DateRangePicker($('#main').find("#racuni2PrimerjavaOd")[0], {
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            startDate: new Date(now.getFullYear(), now.getMonth() - 1, 1),
            minDate: moment().add(-20, 'years'),
            maxDate: moment()
        });
        $("#racuni2PrimerjavaOd").on("change", () => {
            $("#racuni-skupaj-v-izbranem-obdobju").hide();
            $("#racuni-graf").hide();
            $(".tabela-racuni").hide();
            $(".racuni-ne-obstajajo").hide();
            $("#primerjava-graf").hide();
        });

        this._racuni2PrimerjavaDoPicker = new DateRangePicker($('#main').find("#racuni2PrimerjavaDo")[0], {
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            startDate: new Date(now.getFullYear(), now.getMonth(), 1),
            minDate: moment().add(-20, 'years'),
            maxDate: moment()
        });
        $("#racuni2PrimerjavaDo").on("change", () => {
            $("#racuni-skupaj-v-izbranem-obdobju").hide();
            $("#racuni-graf").hide();
            $(".tabela-racuni").hide();
            $(".racuni-ne-obstajajo").hide();
            $("#primerjava-graf").hide();
        });

        console.log(this._racuni1PrimerjavaOdPicker);
        console.log(this._racuni1PrimerjavaDoPicker);
        console.log(this._racuni2PrimerjavaOdPicker);
        console.log(this._racuni2PrimerjavaDoPicker);

        var btnPregledRacunovElement = $("#btnPregledRacunov");
        btnPregledRacunovElement.on("click", (ev: Event) => {
            $("#racuni-skupaj-v-izbranem-obdobju").hide();
            $("#racuni-graf").hide();
            $(".tabela-racuni").hide();
            $(".racuni-ne-obstajajo").hide();
            $("#primerjava-graf").hide();
            this._onBtnPregledRacunovClick(ev);
        });

        var btnPrimerjajRacune = $("#btnPrimerjajRacune");
        btnPrimerjajRacune.on("click", (ev: Event) => {
            $("#racuni-skupaj-v-izbranem-obdobju").hide();
            $("#racuni-graf").hide();
            $(".tabela-racuni").hide();
            $(".racuni-ne-obstajajo").hide();
            $("#primerjava-graf").hide();
            this._onBtnPrimerjajRacuneClick(ev);
        });
    }

    private _onBtnPrimerjajRacuneClick(ev: Event) {
        ev.preventDefault();
        if (!this._izbranoMerilnoMesto || !this._merilnaMesta) {
            return;
        }
        this._loader.show();
        var dropdownPrimerjavaRacuni = $("#dropdownPrimerjavaRacuni");
        if (dropdownPrimerjavaRacuni.val() == "1") {
            this._primerjajMesec(this._izbranoMerilnoMesto);
        }
        else if (dropdownPrimerjavaRacuni.val() == "2") {
            this._primerjajPrejsnoLeto(this._izbranoMerilnoMesto);
        }
        else {
            this._primerjajCustom(this._izbranoMerilnoMesto);
        }
        this._loader.hide();
    }

    private async _primerjajPrejsnoLeto(izbranoMerilnoMesto: any) {
        var formElement = $(("#prikaz-racunov"));
        var formData = new FormData(formElement[0] as HTMLFormElement);
        var obdobjeString = formData.get("racuniObdobjeOd") as string;
        var obdobjeSplit = obdobjeString.split(".");
        var datumOd1 = new Date(Number(obdobjeSplit[2]) - 1, Number(obdobjeSplit[1]) - 1, Number(obdobjeSplit[0]));
        var datumOd2 = new Date(Number(obdobjeSplit[2]), Number(obdobjeSplit[1]) - 1, Number(obdobjeSplit[0]));
        obdobjeString = formData.get("racuniObdobjeDo") as string;
        obdobjeSplit = obdobjeString.split(".");
        var datumDo1 = new Date(Number(obdobjeSplit[2]) - 1, Number(obdobjeSplit[1]) - 1, Number(obdobjeSplit[0]));
        var datumDo2 = new Date(Number(obdobjeSplit[2]), Number(obdobjeSplit[1]) - 1, Number(obdobjeSplit[0]));

        var celotnoObdobje1 = datumOd1.getDate().toString() + "." + (datumOd1.getMonth() + 1).toString() + "." + datumOd1.getFullYear()
            + " - " + datumDo1.getDate().toString() + "." + (datumDo1.getMonth() + 1).toString() + "." + datumDo1.getFullYear();
        var celotnoObdobje2 = datumOd2.getDate().toString() + "." + (datumOd2.getMonth() + 1).toString() + "." + datumOd2.getFullYear()
            + " - " + datumDo2.getDate().toString() + "." + (datumDo2.getMonth() + 1).toString() + "." + datumDo2.getFullYear();

        var racuni1 = null;
        var racuni2 = null;

        var racuniInPlacilaRequestModel1: RacuniInPlacilaRequestModel = {
            merilnoMesto: izbranoMerilnoMesto.merilnoMesto,
            merilnaTocka: izbranoMerilnoMesto.merilnaTocka,
            stevilkaKontoPogodbe: izbranoMerilnoMesto.stevilkaKontoPogodbe,
            obdobjeOd: datumOd1,
            obdobjeDo: datumDo1
        };
        await this._merilnoMestoApiClient.getRacuniInPlacila(racuniInPlacilaRequestModel1)
            .then((racuniInPlacilaOdgovor: any) => {
                if (racuniInPlacilaOdgovor.fakture != null && racuniInPlacilaOdgovor.fakture.length > 0) {
                    racuni1 = racuniInPlacilaOdgovor.fakture;
                    // sortiramo od nastarej�ega do najnovej�ega
                    racuni1.sort((a: any, b: any) => (a.datumKnjizenja > b.datumKnjizenja) ? 1 : ((b.datumKnjizenja > a.datumKnjizenja) ? -1 : 0));
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSearchingForInvoicesAndPayments"]);
                this._loader.hide();
            });

        var racuniInPlacilaRequestModel2: RacuniInPlacilaRequestModel = {
            merilnoMesto: izbranoMerilnoMesto.merilnoMesto,
            merilnaTocka: izbranoMerilnoMesto.merilnaTocka,
            stevilkaKontoPogodbe: izbranoMerilnoMesto.stevilkaKontoPogodbe,
            obdobjeOd: datumOd2,
            obdobjeDo: datumDo2
        };
        await this._merilnoMestoApiClient.getRacuniInPlacila(racuniInPlacilaRequestModel2)
            .then((racuniInPlacilaOdgovor: any) => {
                if (racuniInPlacilaOdgovor.fakture != null && racuniInPlacilaOdgovor.fakture.length > 0) {
                    racuni2 = racuniInPlacilaOdgovor.fakture;
                    // sortiramo od nastarej�ega do najnovej�ega
                    racuni2.sort((a: any, b: any) => (a.datumKnjizenja > b.datumKnjizenja) ? 1 : ((b.datumKnjizenja > a.datumKnjizenja) ? -1 : 0));
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSearchingForInvoicesAndPayments"]);
                this._loader.hide();
            });

        this._loadPrimerjavaGraf(racuni1, racuni2, celotnoObdobje1, celotnoObdobje2);
    }

    private async _primerjajCustom(izbranoMerilnoMesto: any) {
        var formElement = $(("#primerjava-racuni"));
        var formData = new FormData(formElement[0] as HTMLFormElement);
        var obdobjeString = formData.get("racuni1PrimerjavaOd") as string;
        var obdobjeSplit = obdobjeString.split(".");
        var datumOd1 = new Date(Number(obdobjeSplit[2]), Number(obdobjeSplit[1]) - 1, Number(obdobjeSplit[0]));
        obdobjeString = formData.get("racuni1PrimerjavaDo") as string;
        obdobjeSplit = obdobjeString.split(".");
        var datumDo1 = new Date(Number(obdobjeSplit[2]), Number(obdobjeSplit[1]) - 1, Number(obdobjeSplit[0]));
        obdobjeString = formData.get("racuni2PrimerjavaOd") as string;
        obdobjeSplit = obdobjeString.split(".");
        var datumOd2 = new Date(Number(obdobjeSplit[2]), Number(obdobjeSplit[1]) - 1, Number(obdobjeSplit[0]));
        obdobjeString = formData.get("racuni2PrimerjavaDo") as string;
        obdobjeSplit = obdobjeString.split(".");
        var datumDo2 = new Date(Number(obdobjeSplit[2]), Number(obdobjeSplit[1]) - 1, Number(obdobjeSplit[0]));

        var celotnoObdobje1 = datumOd1.getDate().toString() + "." + (datumOd1.getMonth() + 1).toString() + "." + datumOd1.getFullYear()
            + " - " + datumDo1.getDate().toString() + "." + (datumDo1.getMonth() + 1).toString() + "." + datumDo1.getFullYear();
        var celotnoObdobje2 = datumOd2.getDate().toString() + "." + (datumOd2.getMonth() + 1).toString() + "." + datumOd2.getFullYear()
            + " - " + datumDo2.getDate().toString() + "." + (datumDo2.getMonth() + 1).toString() + "." + datumDo2.getFullYear();

        var racuni1 = null;
        var racuni2 = null;

        var racuniInPlacilaRequestModel1: RacuniInPlacilaRequestModel = {
            merilnoMesto: izbranoMerilnoMesto.merilnoMesto,
            merilnaTocka: izbranoMerilnoMesto.merilnaTocka,
            stevilkaKontoPogodbe: izbranoMerilnoMesto.stevilkaKontoPogodbe,
            obdobjeOd: datumOd1,
            obdobjeDo: datumDo1
        };
        await this._merilnoMestoApiClient.getRacuniInPlacila(racuniInPlacilaRequestModel1)
            .then((racuniInPlacilaOdgovor: any) => {
                if (racuniInPlacilaOdgovor.fakture != null && racuniInPlacilaOdgovor.fakture.length > 0) {
                    racuni1 = racuniInPlacilaOdgovor.fakture;
                    // sortiramo od nastarej�ega do najnovej�ega
                    racuni1.sort((a: any, b: any) => (a.datumKnjizenja > b.datumKnjizenja) ? 1 : ((b.datumKnjizenja > a.datumKnjizenja) ? -1 : 0));
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSearchingForInvoicesAndPayments"]);
                this._loader.hide();
            });

        var racuniInPlacilaRequestModel2: RacuniInPlacilaRequestModel = {
            merilnoMesto: izbranoMerilnoMesto.merilnoMesto,
            merilnaTocka: izbranoMerilnoMesto.merilnaTocka,
            stevilkaKontoPogodbe: izbranoMerilnoMesto.stevilkaKontoPogodbe,
            obdobjeOd: datumOd2,
            obdobjeDo: datumDo2
        };
        await this._merilnoMestoApiClient.getRacuniInPlacila(racuniInPlacilaRequestModel2)
            .then((racuniInPlacilaOdgovor: any) => {
                if (racuniInPlacilaOdgovor.fakture != null && racuniInPlacilaOdgovor.fakture.length > 0) {
                    racuni2 = racuniInPlacilaOdgovor.fakture;
                    // sortiramo od nastarej�ega do najnovej�ega
                    racuni2.sort((a: any, b: any) => (a.datumKnjizenja > b.datumKnjizenja) ? 1 : ((b.datumKnjizenja > a.datumKnjizenja) ? -1 : 0));
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSearchingForInvoicesAndPayments"]);
                this._loader.hide();
            });

        this._loadPrimerjavaGraf(racuni1, racuni2, celotnoObdobje1, celotnoObdobje2);
    }

    private async _primerjajMesec(izbranoMerilnoMesto: any) {
        var now = new Date();
        var racuniInPlacilaRequestModel: RacuniInPlacilaRequestModel = {
            merilnoMesto: izbranoMerilnoMesto.merilnoMesto,
            merilnaTocka: izbranoMerilnoMesto.merilnaTocka,
            stevilkaKontoPogodbe: izbranoMerilnoMesto.stevilkaKontoPogodbe,
            obdobjeOd: new Date(now.getFullYear() - 5, now.getMonth(), 1),
            obdobjeDo: new Date(now.getFullYear(), now.getMonth(), 1)
        };
        await this._merilnoMestoApiClient.getRacuniInPlacila(racuniInPlacilaRequestModel)
            .then((racuniInPlacilaOdgovor: any) => {
                if (racuniInPlacilaOdgovor.fakture != null && racuniInPlacilaOdgovor.fakture.length > 0) {
                    $(".racuni-ne-obstajajo").hide();
                    var fakture = racuniInPlacilaOdgovor.fakture;
                    var zadnjiMesec = fakture[0];
                    var datum = new Date(zadnjiMesec.datumKnjizenja);
                    var celotnoObdobje2 = datum.getDate().toString() + "." + (datum.getMonth() + 1).toString() + "." + datum.getFullYear();
                    var predzadnjiMesec = null;
                    var celotnoObdobje1 = "";
                    if (fakture.length > 1) {
                        predzadnjiMesec = fakture[1];
                        var datum = new Date(predzadnjiMesec.datumKnjizenja);
                        celotnoObdobje1 = datum.getDate().toString() + "." + (datum.getMonth() + 1).toString() + "." + datum.getFullYear();
                    }
                    this._loadPrimerjavaGraf([predzadnjiMesec], [zadnjiMesec], celotnoObdobje1, celotnoObdobje2);
                }
                else {
                    $(".racuni-ne-obstajajo").show();
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSearchingForInvoicesAndPayments"]);
                this._loader.hide();
            });
    }

    private _loadPrimerjavaGraf(racuni1Seznam: any, racuni2Seznam: any, obdobje1: string, obdobje2: string) {
        if (racuni1Seznam == null && racuni2Seznam == null) {
            $(".odcitki-ne-obstajajo").show();
            return;
        }
        // Nastavimo datume (x os)
        var datume = new Array<string>();
        var valuta = racuni1Seznam != null ? racuni1Seznam[0].valuta : racuni2Seznam[0].valuta;
        var zneskiRacuni1 = new Array<number>();
        var zneskiRacuni2 = new Array<number>();

        if (racuni1Seznam != null && racuni1Seznam.length > 0) {
            for (let i = 0; i < racuni1Seznam.length; i++) {
                var datum = new Date(racuni1Seznam[i].datumKnjizenja);
                var datumString = (datum.getMonth() + 1).toString() + "." + datum.getFullYear().toString();
                datume.push(datumString);
                zneskiRacuni1.push(racuni1Seznam[i].znesekRacuna);
            }
        }

        if (racuni2Seznam != null && racuni2Seznam.length > 0) {
            for (let i = 0; i < racuni2Seznam.length; i++) {
                var datum = new Date(racuni2Seznam[i].datumKnjizenja);
                var datumString = (datum.getMonth() + 1).toString() + "." + datum.getFullYear().toString();
                if (i >= datume.length) {
                    datume.push(datumString);
                }
                else {
                    datume[i] += " / " + datumString;
                }
                zneskiRacuni2.push(racuni2Seznam[i].znesekRacuna);
            }
        }

        const html = Mustache.render(primerjavaGrafView, {
            translations: this._translationService.currentTranslations
        });

        $("#primerjava-graf").html(html);

        var chartOptions = {
            chart: {
                width: "100%",
                height: 380,
                type: 'line',
                toolbar: {
                    tools: {
                        download: false
                    }
                }
            },
            series: [
                {
                    name: obdobje1,
                    data: zneskiRacuni1
                },
                {
                    name: obdobje2,
                    data: zneskiRacuni2
                }
            ],

            xaxis: {
                categories: datume
            },

            title: {
                alight: 'left',
                // text: "Skupna poraba", 
                floating: true,
                offsetX: -10,
                offsetY: 10,
                style: {
                    fontFamily: 'Red Hat Display',
                    fontWeight: '400',
                    fontSize: '15px',
                    colour: 'rgb(33, 37, 41)',

                }
            },
            yaxis: {
                labels: {
                    show: true,
                    formatter: ((val: any) => { return val + " " + valuta })
                }
            },
            legend: {
                show: true,
                position: 'top',
                horizontalAlign: 'right',
                offsetY: 10,
                labels: {
                    useSeriesColors: false
                }
            },

            fill: {
                colors: ['#008FFB', '#00E396', '#FFB019']
            },

            grid: {
                show: true,
                borderColor: "#e8e4e4",
                strokeDashArray: 0,
                position: 'back',
                yaxis: {
                    lines: {
                        show: true
                    }
                },
                xaxis: {
                    lines: {
                        show: true
                    }
                },
            },

            dataLabels: {
                enabled: false
            }
        };

        //.apexcharts-legend-series.custom-marker-down .apexcharts-legend-marker
        var chart = new ApexCharts($("#primerjava-graf").find("#primerjava-graf-div")[0], chartOptions);
        chart.render();
        $("#primerjava-graf").show();
    }

    private _onDropDownMerilnoMestoChange(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        this._dropdownMerilnaMestaElement = $("#dropdownMerilnaMesta");
        var dropdownMerilnoMestoValues = (this._dropdownMerilnaMestaElement[0] as any).value.split("_");
        var selectedJeElektrika = dropdownMerilnoMestoValues[0]
        var selectedStevilkaKontoPogodbe = dropdownMerilnoMestoValues[1]
        var selectedMerilnoMesto = dropdownMerilnoMestoValues[2]
        if (selectedJeElektrika == 'true') {
            var selectedMerilnaTocka = dropdownMerilnoMestoValues[3]
            location.href = "#/merilno-mesto/racuni?" + selectedMerilnaTocka + "_" + selectedJeElektrika + "_" + selectedMerilnoMesto + "_" + selectedStevilkaKontoPogodbe
        }
        else {
            location.href = "#/merilno-mesto/racuni?" + selectedJeElektrika + "_" + selectedMerilnoMesto + "_" + selectedStevilkaKontoPogodbe;
        }
    }

    private async _onBtnPregledRacunovClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        this._loader.show();
        this._formElement = $(("#prikaz-racunov"));
        var formData = new FormData(this._formElement[0] as HTMLFormElement);
        var obdobjeOdString = formData.get("racuniObdobjeOd") as string;
        var obdobjeOdSplit = obdobjeOdString.split(".");
        var obdobjeOd = new Date(Number(obdobjeOdSplit[2]), Number(obdobjeOdSplit[1]) - 1, Number(obdobjeOdSplit[0]));
        var obdobjeDoString = formData.get("racuniObdobjeDo") as string;
        var obdobjeDoSplit = obdobjeDoString.split(".");
        var obdobjeDo = new Date(Number(obdobjeDoSplit[2]), Number(obdobjeDoSplit[1]) - 1, Number(obdobjeDoSplit[0]));
        if (this._izbranoMerilnoMesto != null) {
            var racuniInPlacilaRequestModel: RacuniInPlacilaRequestModel = {
                merilnoMesto: this._izbranoMerilnoMesto.merilnoMesto,
                merilnaTocka: this._izbranoMerilnoMesto.merilnaTocka,
                stevilkaKontoPogodbe: this._izbranoMerilnoMesto.stevilkaKontoPogodbe,
                obdobjeOd: obdobjeOd,
                obdobjeDo: obdobjeDo
            };
            await this._merilnoMestoApiClient.getRacuniInPlacila(racuniInPlacilaRequestModel)
                .then((racuniInPlacilaOdgovor: any) => {
                    // zapisemo cene
                    $(".skupajZaPlacilo")[0].innerHTML = racuniInPlacilaOdgovor.skupajZaPlacilo + " EUR";
                    $(".skupajPlacano")[0].innerHTML = racuniInPlacilaOdgovor.skupajPlacano + " EUR";
                    $(".razlika")[0].innerHTML = racuniInPlacilaOdgovor.razlika + " EUR";
                    $("#racuni-skupaj-v-izbranem-obdobju")[0].style.display = "block";

                    if (racuniInPlacilaOdgovor.fakture != null && racuniInPlacilaOdgovor.fakture.length > 0) {
                        $(".racuni-ne-obstajajo")[0].style.display = "none";

                        // renderiramo grafa
                        this._loadRacuniGraf(racuniInPlacilaOdgovor);

                        // renderiramo tabelo
                        this._renderRacuniTables(racuniInPlacilaOdgovor.fakture.reverse(), 1);

                        // izpolnimo podatke pod tabelo
                        $(".tabela-skupajZaPlacilo")[0].innerHTML = racuniInPlacilaOdgovor.skupajZaPlacilo + " EUR";
                        $(".tabela-skupajPlacano")[0].innerHTML = racuniInPlacilaOdgovor.skupajPlacano + " EUR";
                        $(".tabela-razlika")[0].innerHTML = racuniInPlacilaOdgovor.razlika + " EUR";

                        // prikazemo vse 
                        $(".tabela-racuni")[0].style.display = "block";
                    }
                    else {
                        $(".racuni-ne-obstajajo")[0].style.display = "block";
                    }

                    // skrijemo loadera
                    this._loader.hide();

                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSearchingForInvoicesAndPayments"]);
                    this._loader.hide();
                });
        }
    }

    private _renderRacuniTables(seznam: any, page: number) {
        this._renderRacuniInPlacilaTableDesktop(seznam, page);
        $(".tabela-racuni-desktop").addClass("d-lg-block");
        $(".tabela-racuni-desktop").removeClass("d-lg-none");
        this._renderRacuniInPlacilaTableMobile(seznam, page);
        $(".tabela-racuni-mobile").addClass("d-block");
        $(".tabela-racuni-mobile").removeClass("d-none");
        this._refreshPaginationRacuni(seznam, page);
    }

    private _refreshPaginationRacuni(seznam: Array<any>, page: number) {
        const templateString = `
             <div class="pagination">
                <%#obstajaPrejsnjaStran%>
                <div>
                    <a class="btnPreviousPage">&lt</a>
                </div>
                <%/obstajaPrejsnjaStran%>
                <div class="pages"><%{page}%>/<%{maxPage}%></div>
                <%#obstajaNaslednjaStran%>
                <div>
                    <a class="btnNextPage">&gt</a>
                </div>
                <%/obstajaNaslednjaStran%>
            </div>
        `;
        var customTags: any = ['<%', '%>'];
        var maxPage = Math.ceil(seznam.length / this._limit);
        var html = Mustache.render(
            templateString,
            {
                "page": page,
                "maxPage": maxPage,
                "obstajaPrejsnjaStran": page != 1 ? true : false,
                "obstajaNaslednjaStran": page != maxPage ? true : false
            },
            undefined,
            customTags
        );
        $("#pagination-racuni")[0].style.display = "block";
        $("#pagination-racuni").html(html);
        $(".btnPreviousPage").on("click", (ev: Event) => this._onBtnPageChangeClick(ev, true, seznam));
        $(".btnNextPage").on("click", (ev: Event) => this._onBtnPageChangeClick(ev, false, seznam));
    }

    private _onBtnPageChangeClick(ev: Event, previous: boolean, seznam: any) {
        console.debug(ev);
        ev.preventDefault();
        this._loader.show();
        var pagesString = $(".pages")[0].innerHTML;
        var currentPage = Number(pagesString.split("/")[0]);
        if (previous) {
            currentPage -= 1;
        }
        else {
            currentPage += 1;
        }
        this._renderRacuniTables(seznam, currentPage);
        this._loader.hide();
    }

    private _renderRacuniInPlacilaTableDesktop(seznam: any, page: number) {
        // izpolnimo podatke v tabeli
        var displayedRacuniInPlacila = new Array<any>();
        for (let i = (page - 1) * this._limit; i < page * this._limit; i++) {
            if (i >= seznam.length) {
                break;
            }
            displayedRacuniInPlacila.push(seznam[i])
        }
        const templateString = `
                <%#obstajaFaktura%>
                <table class="table racuni-placila">
                    <thead>
                        <tr>
                            <td scope="col"><%{translations.Period}%></td>
                            <td scope="col"><%{translations.IdNumber}%></td>
                            <td scope="col"><%{translations.ReferenceNumber}%></td>
                            <td scope="col"><%{translations.Value}%></td>
                            <td scope="col"><%{translations.Paid}%></td>
                            <td scope="col"><%{translations.Bill}%></td>
                        </tr>
                    </thead>
                    <tbody>
                        <%#fakture%>
                        <tr>
                            <td scope="row"><%#formators%><%#dateFormat%><%datumKnjizenja%><%/dateFormat%><%/formators%></td>
                            <td><%{stevilkaDokumenta}%></td>
                            <td><%{stevilkaReference}%></td>
                            <td><%{znesekRacuna}%> <%{valuta}%></td>
                            <%#placano%>
                            <td><img src="../img/icon-reg-checkmark.png" class="img-fluid" /></td>
                            <%/placano%>
                            <%^placano%>
                            <td><img src="../img/icon-box-important.svg" class="img-fluid" /></td>
                            <%/placano%>
                            <td><a type="button"><img src="../img/icon-export-pdf.svg" class="img-fluid download-pdf-desktop" /></a></td>
                        </tr>
                        <%/fakture%>
                    </tbody>
                </table>
                <%/obstajaFaktura%>
                <%^obstajaFaktura%>
                <div><%{translations.NoData}%></div>
                <%/obstajaFaktura%>
             `;
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "formators": this._formators,
                "obstajaFaktura": displayedRacuniInPlacila.length > 0 ? true : false,
                "fakture": displayedRacuniInPlacila,
                "translations": this._translationService.currentTranslations
            },
            undefined,
            customTags
        );
        $(".tabela-racuni-desktop").html(html);
        if (displayedRacuniInPlacila.length > 0) {
            var buttonsDownloadPdf = $(".download-pdf-desktop");
            for (let i = 0; i < buttonsDownloadPdf.length; i++) {
                buttonsDownloadPdf[i].addEventListener("click", (ev: Event) => this._onBtnDownloadPdfClick(ev, displayedRacuniInPlacila[i]));
            }
        }
    }

    private _renderRacuniInPlacilaTableMobile(seznam: any, page: number) {
        // izpolnimo podatke v tabeli
        var displayedRacuniInPlacila = new Array<any>();
        for (let i = (page - 1) * this._limit; i < page * this._limit; i++) {
            if (i >= seznam.length) {
                break;
            }
            displayedRacuniInPlacila.push(seznam[i])
        }
        const templateString = `
                <%#obstajaFaktura%>
                <table class="table vloge-obrazci-mobile table-borderless">
                    <%#fakture%>
                    <tbody>
                        <tr>
                            <th scope="row"><%{translations.Period}%></th>
                            <td scope="row"><%#formators%><%#dateFormat%><%datumKnjizenja%><%/dateFormat%><%/formators%></td>
                        </tr>
                        <tr>
                            <th scope="row"><%{translations.IdNumber}%></th>
                            <td><%{stevilkaDokumenta}%></td>
                        </tr>
                         <tr>
                            <th scope="row"><%{translations.ReferenceNumber}%></th>
                            <td><%{stevilkaReference}%></td>
                        </tr>
                        <tr>
                            <th scope="row"><%{translations.Value}%></th>
                            <td><%{znesekRacuna}%> <%{valuta}%></td>
                        </tr>
                        <tr>
                            <th scope="row"><%{translations.Paid}%></th>
                            <%#placano%>
                            <td><img src="../img/icon-reg-checkmark.png" class="img-fluid" /></td>
                            <%/placano%>
                            <%^placano%>
                            <td><img src="../img/icon-box-important.svg" class="img-fluid" /></td>
                            <%/placano%>
                        </tr>
                        <tr>
                            <th scope="row"><%{translations.Bill}%></th>
                            <td><a type="button"><img src="../img/icon-export-pdf.svg" class="img-fluid download-pdf-mobile" /></a></td>
                        </tr>
                    </tbody>
                    <%/fakture%>
                </table>
                <%/obstajaFaktura%>
                <%^obstajaFaktura%>
                <div><%{translations.NoData}%></div>
                <%/obstajaFaktura%>
             `;
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "formators": this._formators,
                "obstajaFaktura": displayedRacuniInPlacila.length > 0 ? true : false,
                "fakture": displayedRacuniInPlacila,
                "translations": this._translationService.currentTranslations
            },
            undefined,
            customTags
        );
        $(".tabela-racuni-mobile").html(html);
        if (displayedRacuniInPlacila.length > 0) {
            var buttonsDownloadPdf = $(".download-pdf-mobile");
            for (let i = 0; i < buttonsDownloadPdf.length; i++) {
                buttonsDownloadPdf[i].addEventListener("click", (ev: Event) => this._onBtnDownloadPdfClick(ev, displayedRacuniInPlacila[i]));
            }
        }
    }

    private async _onBtnDownloadPdfClick(ev: Event, racun: any) {
        console.debug(ev);
        ev.preventDefault();
        var translations = this._translationService.currentTranslations;
        var downloadingNotyf = this._notyf.open({
            type: "info",
            message: translations.Downloading,
            duration: 50000
        });
        await this._merilnoMestoApiClient.racunPdfDownload(racun.stevilkaDokumenta)
            .then((response) => {
                const downloadUrl = response.downloadUrl;
                if (downloadUrl != 'undefined') {
                    const fileLink = document.createElement('a');
                    fileLink.href = downloadUrl;
                    fileLink.click();
                }
            })
            .catch(() => {
                this._notyf.error(translations.ErrorWhileDownloadingFile);
            }).finally(() => {
                this._notyf.dismiss(downloadingNotyf);
            })
    }

    private _loadRacuniGraf(racuniInPlacilaOdgovor: any) {
        var fakture = racuniInPlacilaOdgovor.fakture;
        fakture = fakture.reverse();
        var datume = new Array<String>();
        if (fakture.length > 0) {
            var cene = new Array<Number>();
            for (let i = 0; i < fakture.length; i++) {
                var datum = new Date(fakture[i].datumKnjizenja);
                var datumString = (datum.getMonth() + 1).toString() + "." + datum.getFullYear().toString();
                datume.push(datumString);
                cene.push(fakture[i].znesekRacuna);
            }
            const html = Mustache.render(racuniGrafView, {
                translations: this._translationService.currentTranslations
            });
            $("#racuni-graf").html(html);
            var chartOptions = {
                chart: {
                    width: "100%",
                    height: 380,
                    type: 'bar',
                    toolbar: {
                        tools: {
                            download: false
                        }
                    }
                },
                series: [
                    {
                        //name:
                        data: cene
                    }
                ],

                xaxis: {
                    categories: datume
                },

                title: {
                    alight: 'left',
                    // text: 
                    floating: true,
                    offsetX: -10,
                    offsetY: 10,
                    style: {
                        fontFamily: 'Red Hat Display',
                        fontWeight: '400',
                        fontSize: '15px',
                        colour: 'rgb(33, 37, 41)',

                    }
                },
                yaxis: {
                    labels: {
                        show: true,
                        formatter: ((val: any) => { return val + " EUR" })
                    }
                },
                legend: {
                    show: true,
                    position: 'top',
                    horizontalAlign: 'right',
                    offsetY: 10,
                    labels: {
                        useSeriesColors: false
                    }
                },

                fill: {
                    colors: ['#008FFB', '#00E396', '#FFB019']
                },

                grid: {
                    show: true,
                    borderColor: "#e8e4e4",
                    strokeDashArray: 0,
                    position: 'back',
                    yaxis: {
                        lines: {
                            show: true
                        }
                    },
                    xaxis: {
                        lines: {
                            show: true
                        }
                    },
                },

                dataLabels: {
                    enabled: false
                }
            };
            //.apexcharts-legend-series.custom-marker-down .apexcharts-legend-marker
            var chart = new ApexCharts($("#racuni-graf").find("#racuni-graf-div")[0], chartOptions);
            chart.render();
            $("#racuni-graf").show();
        }
        else {
            $("#racuni-graf").hide();
        }
    }
}
