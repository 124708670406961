import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { AkcijeApiClient } from "../ts/clients/akcije-api-client";
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { TranslationService } from "../ts/translation-service";
import template from "./akcije-ugodnosti-view.html";
import { FullscreenLoader } from "./fullscreen-loader";
import * as moment from "moment";
import { MerilnoMesto } from "../ts/models/merilno-mesto";
import { UporabnikMerilnoMestoRequest } from "../ts/models/uporabnik-merilno-mesto-request";
import { UporabnikMerilnoMestoResponse } from "../ts/models/uporabnik-merilno-mesto-response";
import { UgodnostUmbracoResponse } from "../ts/models/ugodnosti-umbraco-response";

@injectable()
export class AkcijeUgodnostiView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _akcijeApiClient: AkcijeApiClient;
    private _merilnaMesta: Array<MerilnoMesto> | null;
    private _izbranoMerilnoMesto: MerilnoMesto | null | undefined;
    private _ugodnosti: Array<UgodnostUmbracoResponse>;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        akcijeApiClient: AkcijeApiClient,
        merilnoMestoApiClient: MerilnoMestoApiClient,
        private _notyf: Notyf,
        private _loader: FullscreenLoader) {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._akcijeApiClient = akcijeApiClient;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._merilnaMesta = null;
        this._izbranoMerilnoMesto = null;
        this._ugodnosti = new Array<UgodnostUmbracoResponse>();
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            console.log(this._loader); // TODO: remove

            var translations = this._translationService.currentTranslations;
            var uporabnikMerilnoMestoRequest: UporabnikMerilnoMestoRequest = {
                limit: 0,
                stevilkaStrani: 0,
                saldo: false,
                skupnaPorabaEnergije: false,
                merilnoMesto: "",
                merilnaTocka: ""
            };
            await this._merilnoMestoApiClient.getUporabnikMerilnaMesta(uporabnikMerilnoMestoRequest)
                .then((odgovor: UporabnikMerilnoMestoResponse) => {
                    this._merilnaMesta = odgovor.merilnaMesta;
                    if (this._merilnaMesta && this._merilnaMesta.length > 0) {
                        // poiscemo ali obstaja selektirano MM (v querystringu)
                        var url_string = window.location.href.split("?");
                        var url_data = url_string[url_string.length - 1];
                        var data_list = url_data.split("_");
                        const stevilkaKontoPogodbe = data_list[data_list.length - 1];
                        const merilnoMesto = data_list[data_list.length - 2];
                        const jeElektrika = data_list[data_list.length - 3];
                        const merilnaTocka = data_list[data_list.length - 4];

                        if (jeElektrika == 'true') {
                            this._izbranoMerilnoMesto = this._merilnaMesta.find(
                                a => a.merilnoMesto == merilnoMesto && a.merilnaTocka == merilnaTocka && a.stevilkaKontoPogodbe == stevilkaKontoPogodbe);
                        }
                        else if (jeElektrika == 'false') {
                            this._izbranoMerilnoMesto = this._merilnaMesta.find(
                                a => a.merilnoMesto == merilnoMesto && a.stevilkaKontoPogodbe == stevilkaKontoPogodbe);
                        }
                        // ce v querystringu ni doloceno MM, selektiramo prvega MM iz liste
                        if (!this._izbranoMerilnoMesto) {
                            this._izbranoMerilnoMesto = this._merilnaMesta[0];
                        }
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSearchingForMeasuringPoints"]);
                });

            await this._akcijeApiClient.getUgodnosti()
                .then((odgovor) => {
                    if (odgovor.status) {
                        this._ugodnosti = odgovor.ugodnosti;
                    }
                    else {
                        this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                });

            this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;
            if (this._izbranoMerilnoMesto) {
                this._initForm();
            }
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(translations: any): void {
        var merilnaMestaIds = new Array<string>();
        var prikazanaMerilnaMesta = new Array<string>();
        var steviloPrikazanih = 3;
        if (this._merilnaMesta) {
            merilnaMestaIds = Array.from(new Set(this._merilnaMesta.map((item) => item.merilnoMesto)));
            prikazanaMerilnaMesta = merilnaMestaIds.slice(0, steviloPrikazanih);
            if (this._merilnaMesta.length < steviloPrikazanih) {
                prikazanaMerilnaMesta = merilnaMestaIds;
            }
        }
        var localeFormat = moment().creationData().locale.longDateFormat("L");
        var formators = {
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            }//tukaj se lahko dodajajo formatorji
        };

        var ugodnostiClane = this._ugodnosti.filter(a => a.kategorija == "Ugodnosti za člane Moj PLUS");
        var ugodnostiOdjemalce = this._ugodnosti.filter(a => a.kategorija == "Ugodnosti za odjemalce");
        var ugodnostiSplosne = this._ugodnosti.filter(a => a.kategorija == "Splošne ugodnosti");

        const viewModel = {
            translations: translations,
            formators: formators,
            obstajaMerilnoMesto: this._izbranoMerilnoMesto ? true : false,
            jeElektrika: this._izbranoMerilnoMesto?.panoga == "01" ? true : false,
            merilnaMesta: this._merilnaMesta,
            izbranoMerilnoMesto: this._izbranoMerilnoMesto,
            ostalaMerilnaMestaStevilo: merilnaMestaIds.length - prikazanaMerilnaMesta.length,
            obstajajoOstalaMerilnaMesta: merilnaMestaIds.length - prikazanaMerilnaMesta.length > 0 ? true : false,
            prikazanaMerilnaMesta: prikazanaMerilnaMesta,
            prikaziUgodnostiClane: ugodnostiClane.length == 0 ? false : true,
            prikaziUgodnostiOdjemalce: ugodnostiOdjemalce.length == 0 ? false : true,
            prikaziUgodnostiSplosne: ugodnostiSplosne.length == 0 ? false : true,
            ugodnostiClane: ugodnostiClane,
            ugodnostiOdjemalce: ugodnostiOdjemalce,
            ugodnostiSplosne: ugodnostiSplosne,
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _initForm(): void {
        if (!this._merilnaMesta || !this._izbranoMerilnoMesto) {
            return;
        }

        var dropdownMerilnaMestaElement = $("#dropdownMerilnaMesta");
        dropdownMerilnaMestaElement.on("change", (ev: Event) => this._onDropDownMerilnoMestoChange(ev));
        if (this._izbranoMerilnoMesto.jeElektrika) {
            dropdownMerilnaMestaElement.val(this._izbranoMerilnoMesto.jeElektrika + "_" + this._izbranoMerilnoMesto.stevilkaKontoPogodbe + "_" + this._izbranoMerilnoMesto.merilnoMesto + "_" + this._izbranoMerilnoMesto.merilnaTocka);
        }
        else {
            dropdownMerilnaMestaElement.val(this._izbranoMerilnoMesto.jeElektrika + "_" + this._izbranoMerilnoMesto.stevilkaKontoPogodbe + "_" + this._izbranoMerilnoMesto.merilnoMesto);
        }

        var btnMerilnaMestaOthers = $("#btnMerilnaMestaOthers");
        btnMerilnaMestaOthers.on("click", (ev: Event) => {
            ev.preventDefault();
            $(".merilna-mesta-not-all")[0].style.display = "none";
            $(".merilna-mesta-all")[0].style.display = "block";
        });

        var btnPreveriteUgodnostiClane = $("#btn-preverite-ugodnosti-za-clane");
        btnPreveriteUgodnostiClane.on("click", (ev: Event) => {
            ev.preventDefault();
            var element = $(".preverite-ugodnosti-za-clane")[0].offsetTop;
            window.scrollTo({ top: element - 70, behavior: 'smooth' });
        });

        var btnPreveriteUgodnostiOdjemalce = $("#btn-preverite-ugodnosti-za-odjemalce");    
        btnPreveriteUgodnostiOdjemalce.on("click", (ev: Event) => {
            ev.preventDefault();
            var element = $(".preverite-ugodnosti-za-odjemalce")[0].offsetTop;
            window.scrollTo({ top: element - 70, behavior: 'smooth' });
        });

        var btnPreveriteUgodnostiSplosne = $("#btn-preverite-ugodnosti-splosne");
        btnPreveriteUgodnostiSplosne.on("click", (ev: Event) => {
            ev.preventDefault();
            var element = $(".preverite-ugodnosti-splosne")[0].offsetTop;
            window.scrollTo({ top: element - 70, behavior: 'smooth' });
        });
    }

    private _onDropDownMerilnoMestoChange(ev: Event) {
        ev.preventDefault();
        var dropdownMerilnaMestaElement = $("#dropdownMerilnaMesta");
        var dropdownMerilnoMestoValues = (dropdownMerilnaMestaElement[0] as any).value.split("_");

        var selectedJeElektrika = dropdownMerilnoMestoValues[0]
        var selectedStevilkaKontoPogodbe = dropdownMerilnoMestoValues[1]
        var selectedMerilnoMesto = dropdownMerilnoMestoValues[2]

        if (selectedJeElektrika == 'true') {
            var selectedMerilnaTocka = dropdownMerilnoMestoValues[3]
            location.href = "#/akcije/ugodnosti?" + selectedMerilnaTocka + "_" + selectedJeElektrika + "_" + selectedMerilnoMesto + "_" + selectedStevilkaKontoPogodbe
        }
        else
            location.href = "#/akcije/ugodnosti?" + selectedJeElektrika + "_" + selectedMerilnoMesto + "_" + selectedStevilkaKontoPogodbe
    }
}