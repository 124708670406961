import { injectable } from "inversify";
import * as moment from "moment";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { KontaktiInPomocApiClient } from "../ts/clients/kontakti-in-pomoc-api-client";
import { GetProdajnoMestoKoledarDay, GetProdajnoMestoKoledarTerminData } from "../ts/models/get-prodajno-mesto-koledar-day";
import { GetProdajnoMestoKoledarRequest } from "../ts/models/get-prodajno-mesto-koledar-request";
import { SetDanOnemogocenRequest } from "../ts/models/set-dan-onemogocen-request";
import { SetTerminOnemogocenRequest } from "../ts/models/set-termin-onemogocen-request";
import { TranslationService } from "../ts/translation-service";
import { FullscreenLoader } from "./fullscreen-loader";
import template from "./klicni-center-pregled-view.html";
import "jquery-validation-unobtrusive";
import { TerminDodajKomentarRequest } from "../ts/models/termin-dodaj-komentar-request";
import { ProdajnoMestoUmbracoResponse } from "../ts/models/prodajno-mesto-umbraco-response";

@injectable()
export class KlicniCenterPregledView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _kontaktiInPomocApiClient: KontaktiInPomocApiClient;
    private _formators: any;
    private _startDate: Date;
    private _endDate: Date;
    private _koledarData: Array<GetProdajnoMestoKoledarDay>;
    private _idTermina: string;
    private _onemogociTerminaDatum: string;
    private _onemogociDanDatum: string;
    private _onemogociDan: boolean;
    private _formKomentar: JQuery<HTMLElement> | null;
    private _prodajnaMestaUmbraco: Array<ProdajnoMestoUmbracoResponse>;
    private _izbranoProdajnoMestoUmbraco: ProdajnoMestoUmbracoResponse | null | undefined;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        kontaktiInPomocApiClient: KontaktiInPomocApiClient,
        private _notyf: Notyf,
        private _loader: FullscreenLoader) {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._kontaktiInPomocApiClient = kontaktiInPomocApiClient;
        this._formators = null;
        this._startDate = new Date();
        this._endDate = new Date();
        this._koledarData = new Array<GetProdajnoMestoKoledarDay>();
        this._idTermina = "";
        this._onemogociTerminaDatum = "";
        this._onemogociDanDatum = "";
        this._onemogociDan = false;
        this._formKomentar = null;
        this._prodajnaMestaUmbraco = new Array<ProdajnoMestoUmbracoResponse>();
        this._izbranoProdajnoMestoUmbraco = null;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;
            await this._getProdajnaMesta();
            await this._getIzbranoProdajnoMesto();
            await this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;
            this._startDate = this._getStartDate();
            this._endDate = this._getEndDate();
            await this._getKoledarData();
            this._renderTables();
            this._initForms();
            this._initModals();
        } catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _getIzbranoProdajnoMesto() {
        var url_string = window.location.href.split("/");
        var prodajnoMestoUmbracoId = Number(url_string[url_string.length - 1]);
        this._izbranoProdajnoMestoUmbraco = this._prodajnaMestaUmbraco.find(x => x.Id == prodajnoMestoUmbracoId);
        if (!this._izbranoProdajnoMestoUmbraco) {
            this._izbranoProdajnoMestoUmbraco = this._prodajnaMestaUmbraco[0];
        }
        window.history.pushState(null, "", "#/kontakti/prodajna-mesta");
    }

    private async _getProdajnaMesta() {
        await this._kontaktiInPomocApiClient.getProdajnaMestaUmbraco()
            .then((response: Array<ProdajnoMestoUmbracoResponse>) => {
                this._prodajnaMestaUmbraco = response;
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
    }

    private _renderData(translations: any): void {
        if (!this._izbranoProdajnoMestoUmbraco) return;
        this._formators = this.getFormators();
        const viewModel = {
            translations: translations,
            "prodajnoMesto": this._izbranoProdajnoMestoUmbraco.ImeProdajnegaMesta
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _getStartDate(): Date {
        // get monday of this week
        var today = new Date();
        var day = today.getDay(), diff = today.getDate() - day + (day == 0 ? -6 : 1);
        var result = new Date(today.setDate(diff));
        return result;
    }

    private _getEndDate(): Date {
        // get saturday of this week
        var day = this._getStartDate();
        day.setDate(day.getDate() + 5);
        return day;
    }

    private async _getKoledarData() {
        if (!this._izbranoProdajnoMestoUmbraco) return;
        var data: GetProdajnoMestoKoledarRequest = {
            prodajnoMestoUmbracoId: this._izbranoProdajnoMestoUmbraco.Id,
            datumOd: this._startDate.getFullYear() + "-" + (this._startDate.getMonth() + 1).toString() + "-" + this._startDate.getDate(),
            datumDo: this._endDate.getFullYear() + "-" + (this._endDate.getMonth() + 1).toString() + "-" + this._endDate.getDate(),
        };
        await this._kontaktiInPomocApiClient.getProdajnaMestaKoledar(data)
            .then((response) => {
                this._koledarData = response;
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
    }

    private _renderTables() {
        this._renderTableDesktop();
        this._renderTableMobile();
        this._refreshPagination();
        window.scrollTo(0, 0);
    } 

    private _refreshPagination() {
        const templateString = `
             <div class="pagination">
                <div>
                    <a class="btnPreviousPage">&lt</a>
                </div>
                <div><%#formators%><%#fullDateFormat%><%startDate%><%/fullDateFormat%><%/formators%> - <%#formators%><%#fullDateFormat%><%endDate%><%/fullDateFormat%><%/formators%></div>
                <div>
                    <a class="btnNextPage">&gt</a>
                </div>
            </div>
        `;
        var customTags: any = ['<%', '%>'];
        var endDate = new Date(this._endDate.getFullYear(), this._endDate.getMonth(), this._endDate.getDate());
        endDate.setDate(endDate.getDate() - 1);
        var html = Mustache.render(
            templateString,
            {
                "formators": this._formators,
                "startDate": this._startDate,
                "endDate": endDate
            },
            undefined,
            customTags
        );
        $("#pagination")[0].style.display = "block";
        $("#pagination").html(html);
        $(".btnPreviousPage").on("click", async () => {
            this._startDate.setDate(this._startDate.getDate() - 7);
            this._endDate.setDate(this._endDate.getDate() - 7);
            await this._getKoledarData();
            this._renderTables();
        });
        $(".btnNextPage").on("click", async () => {
            this._startDate.setDate(this._startDate.getDate() + 7);
            this._endDate.setDate(this._endDate.getDate() + 7);
            await this._getKoledarData();
            this._renderTables();
        });
    }

    private _renderTableMobile() {
        const templateString = `
                <table class="table zgodovina-odcitkov-mobile tabela-kc">
                    <tbody>
                    <%#koledarData%>
                        <tr>
                            <td class="table-inner-wrap">
                                <div class="table-responsive">
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <%#datumVPreteklosti%>
                                                    <th scope="row" style="color:grey; background-color:#EFEFEF">
                                                        <p><%{danVTednu}%> (<%#formators%><%#fullDateFormat%><%datum%><%/fullDateFormat%><%/formators%>)</p>
                                                    </th>
                                                <%/datumVPreteklosti%>
                                                <%^datumVPreteklosti%>
                                                    <%#prost%>
                                                        <th scope="row" style="color:green; background-color:#dde9df">
                                                            <p><%{danVTednu}%> (<%#formators%><%#fullDateFormat%><%datum%><%/fullDateFormat%><%/formators%>)</p>
                                                            <a class="btn-onemogoci-dan-mobile" data-onemogocidan="true" data-datumdan="<%{datum}%>">Onemogoči</a>
                                                        </th>
                                                    <%/prost%>
                                                    <%#onemogocen%>
                                                        <th scope="row" style="color:grey; background-color:#EFEFEF">
                                                            <p><%{danVTednu}%> (<%#formators%><%#fullDateFormat%><%datum%><%/fullDateFormat%><%/formators%>)</p>
                                                            <a class="btn-onemogoci-dan-mobile" data-onemogocidan="false" data-datumdan="<%{datum}%>">Omogoči</a>
                                                        </th>
                                                    <%/onemogocen%>
                                                    <%#rezerviran%>
                                                        <th scope="row" style="color:#4c4c4c; background-color:#a3cfbb; opacity">
                                                            <p><%{danVTednu}%> (<%#formators%><%#fullDateFormat%><%datum%><%/fullDateFormat%><%/formators%>)</p>
                                                        </th>
                                                    <%/rezerviran%>
                                                    <%#cakaNaPotrditev%>
                                                        <th scope="row" style="color:#0096c7; background-color:#feffe8">
                                                            <p><%{danVTednu}%> (<%#formators%><%#fullDateFormat%><%datum%><%/fullDateFormat%><%/formators%>)</p>
                                                        </th>
                                                    <%/cakaNaPotrditev%>
                                                    <%#zunajIzDelovnegaCasa%>
                                                        <th scope="row" style="color:grey; background-color:#EFEFEF">
                                                            <p><%{danVTednu}%> (<%#formators%><%#fullDateFormat%><%datum%><%/fullDateFormat%><%/formators%>)</p>
                                                        </th>
                                                    <%/zunajIzDelovnegaCasa%>
                                                    <%#praznik%>
                                                        <th scope="row" style="color:grey; background-color:#EFEFEF">
                                                            <p><%{danVTednu}%> (<%#formators%><%#fullDateFormat%><%datum%><%/fullDateFormat%><%/formators%>)</p>
                                                        </th>
                                                    <%/praznik%> 
                                                <%/datumVPreteklosti%>
                                            </tr>

                                            <%#terminiData%>
                                                <tr>
                                                    <th scope="row"><p></p></th>
                                                    <%#datumVPreteklosti%>
                                                        <%#prost%>
                                                            <td style="color:grey; background-color:#EFEFEF">
                                                                <p><%#formators%><%#dateTimeFormat%><%datumInCas%><%/dateTimeFormat%><%/formators%></p>
                                                            </td>
                                                        <%/prost%>
                                                        <%#onemogocen%>
                                                            <td style="color:grey; background-color:#EFEFEF">
                                                                <p><%#formators%><%#dateTimeFormat%><%datumInCas%><%/dateTimeFormat%><%/formators%>
                                                            </p></td>
                                                        <%/onemogocen%>
                                                        <%#rezerviran%>
                                                            <td scope="row" style="color:grey; background-color:#EFEFEF">
                                                                <div class="d-flex justify-content-between align-items-center mb-2">
                                                                    <p class="mb-0"><strong><%#formators%><%#dateTimeFormat%><%datumInCas%><%/dateTimeFormat%><%/formators%></strong></p>
                                                                    <div style="color:white; background-color:#198754; font-size: 11px; line-height: 12px; display: block; padding: 5px;">
                                                                        <p class="mb-0">Potrjeno</p>  
                                                                    </div> 
                                                                </div>
                                                                <p>Email: <%{email}%></p>
                                                                <p>Ime in priimek: <%{imeInPriimek}%></p>
                                                                <p>Namen: <%{namenObiska}%></p>
                                                                <%#obstajaAdminKomentar%>
                                                                    <p>Komentar: <%{adminKomentar}%></p>
                                                                    <a class="btn-dodaj-komentar-mobile" data-trenutniKomentar="<%{adminKomentar}%>" data-idtermina="<%{id}%>"><small>Spremeni komentar</small></a> 
                                                                <%/obstajaAdminKomentar%>
                                                                <%^obstajaAdminKomentar%>
                                                                    <a class="btn-dodaj-komentar-mobile" data-trenutniKomentar="" data-idtermina="<%{id}%>"><small>Dodaj komentar</small></a> 
                                                                <%/obstajaAdminKomentar%> 
                                                            </td> 
                                                        <%/rezerviran%>
                                                        <%#cakaNaPotrditev%>
                                                            <td scope="row" style="color:grey; background-color:#EFEFEF">
                                                                <div class="d-flex justify-content-between align-items-center mb-2">
                                                                    <p class="mb-0"><strong><%#formators%><%#dateTimeFormat%><%datumInCas%><%/dateTimeFormat%><%/formators%></strong></p>
                                                                    <div style="color:white; background-color:#00a9e2; font-size: 11px; line-height: 12px; display: block; padding: 5px;">
                                                                        <p class="mb-0">Čaka potrditev</p> 
                                                                    </div> 
                                                                </div>
                                                                <p>Email: <%{email}%></p>
                                                                <p>Ime in priimek: <%{imeInPriimek}%></p>
                                                                <p>Namen: <%{namenObiska}%></p>
                                                            </td>
                                                        <%/cakaNaPotrditev%>
                                                        <%#zunajIzDelovnegaCasa%>
                                                            <td scope="row" style="color:grey; background-color:#EFEFEF">
                                                                <p> / </p>
                                                            </td>
                                                        <%/zunajIzDelovnegaCasa%>
                                                        <%#praznik%>
                                                            <td scope="row" style="color:grey; background-color:#EFEFEF">
                                                                <p> / </p>
                                                            </td>
                                                        <%/praznik%>
                                                    <%/datumVPreteklosti%>
                                                    <%^datumVPreteklosti%>
                                                        <%#prost%>
                                                            <td scope="row" style="color:green; background-color:#dde9df">
                                                                <p><%#formators%><%#dateTimeFormat%><%datumInCas%><%/dateTimeFormat%><%/formators%></p>
                                                                <a class="btn-onemogoci-termina-mobile" data-onemogoci="true" data-datumincas="<%{datumInCas}%>"><small>Onemogoči termin</small></a>
                                                            </td>
                                                        <%/prost%>
                                                        <%#onemogocen%>
                                                            <td scope="row" style="color:grey; background-color:#EFEFEF">
                                                                <p><%#formators%><%#dateTimeFormat%><%datumInCas%><%/dateTimeFormat%><%/formators%></p>
                                                                <a class="btn-onemogoci-termina-mobile" data-onemogoci="false" data-idtermina="<%{id}%>" data-datumincas="<%{datumInCas}%>">Omogoči termin</a>
                                                                </td>
                                                        <%/onemogocen%>
                                                        <%#rezerviran%>
                                                            <td scope="row" style="color:#4c4c4c; background-color:#a3cfbb; opacity">
                                                                <div class="d-flex justify-content-between align-items-center mb-2">
                                                                    <p class="mb-0"><strong><%#formators%><%#dateTimeFormat%><%datumInCas%><%/dateTimeFormat%><%/formators%></strong></p>
                                                                    <div style="color:white; background-color:#198754; font-size: 11px; line-height: 12px; display: block; padding: 5px;">
                                                                        <p class="mb-0">Potrjeno</p>  
                                                                    </div>                                          
                                                                </div>
                                                                <p>Email: <%{email}%></p>
                                                                <p>Ime in priimek: <%{imeInPriimek}%></p>
                                                                <p>Namen: <%{namenObiska}%></p>
                                                                <%#obstajaAdminKomentar%>
                                                                    <p>Komentar: <%{adminKomentar}%></p>
                                                                    <div class="d-flex justify-content-between mt-2">
                                                                        <a class="btn-dodaj-komentar-mobile" data-trenutniKomentar="<%{adminKomentar}%>" data-idtermina="<%{id}%>"><small>Spremeni komentar</small></a>
                                                                        <a class="btn-preklici-termina-mobile" data-idtermina="<%{id}%>"><small>Prekliči termin</small></a>
                                                                    </div> 
                                                                <%/obstajaAdminKomentar%>
                                                                <%^obstajaAdminKomentar%>
                                                                    <div class="d-flex justify-content-between mt-2">
                                                                        <a class="btn-dodaj-komentar-mobile" data-trenutniKomentar="" data-idtermina="<%{id}%>"><small>Dodaj komentar</small></a>
                                                                        <a class="btn-preklici-termina-mobile" data-idtermina="<%{id}%>"><small>Prekliči termin</small></a>
                                                                    </div> 
                                                                <%/obstajaAdminKomentar%>                                             
                                                            </td>
                                                        <%/rezerviran%>
                                                        <%#cakaNaPotrditev%>
                                                            <td scope="row" style="color:#4c4c4c; background-color:#cceef9">
                                                                <div class="d-flex justify-content-between align-items-center mb-2">
                                                                    <p class="mb-0"><strong><%#formators%><%#dateTimeFormat%><%datumInCas%><%/dateTimeFormat%><%/formators%></strong></p>
                                                                    <div style="color:white; background-color:#00a9e2; font-size: 11px; line-height: 12px; display: block; padding: 5px;">
                                                                        <p class="mb-0">Čaka potrditev</p> 
                                                                    </div> 
                                                                </div>
                                                                <p>Email: <%{email}%></p>
                                                                <p>Ime in priimek: <%{imeInPriimek}%></p>
                                                                <p>Namen: <%{namenObiska}%></p>
                                                                <div class="d-flex justify-content-between mt-2">
                                                                    <a class="btn-potrdi-termina-mobile" data-idtermina="<%{id}%>"><small>Potrdi termin</small></a>
                                                                    <a class="btn-preklici-termina-mobile" data-idtermina="<%{id}%>"><small>Prekliči termin</small></a>
                                                                </div>
                                                            </td>
                                                        <%/cakaNaPotrditev%>
                                                        <%#zunajIzDelovnegaCasa%>
                                                            <td scope="row" style="color:grey; background-color:#EFEFEF">
                                                                <p> / </p>
                                                            </td>
                                                        <%/zunajIzDelovnegaCasa%>
                                                        <%#praznik%>
                                                            <td scope="row" style="color:grey; background-color:#EFEFEF">
                                                                <p> / </p>
                                                            </td>
                                                        <%/praznik%>
                                                    <%/datumVPreteklosti%>
                                                </tr>
                                            <%/terminiData%>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <%/koledarData%>
                </table>
             `;

        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "formators": this._formators,
                "koledarData": this._koledarData
            },
            undefined,
            customTags
        );

        $(".tabela-mobile").addClass("d-block");
        $(".tabela-mobile").removeClass("d-none");
        $(".tabela-mobile").html(html);

        var buttonsDodajKomentar = $(".btn-dodaj-komentar-mobile");
        for (let i = 0; i < buttonsDodajKomentar.length; i++) {
            buttonsDodajKomentar[i].addEventListener("click", async () => {
                this._idTermina = buttonsDodajKomentar[i].dataset["idtermina"] as string;
                var trenutniKomentar = buttonsDodajKomentar[i].dataset["trenutnikomentar"] as string;
                $("#komentar").val(trenutniKomentar);
                $("#dodajKomentarModal").modal("show");
            });
        }

        var buttonsPotrditeTermina = $(".btn-potrdi-termina-mobile");
        for (let i = 0; i < buttonsPotrditeTermina.length; i++) {
            buttonsPotrditeTermina[i].addEventListener("click", async () => {
                this._idTermina = buttonsPotrditeTermina[i].dataset["idtermina"] as string;
                $("#rezerviranTerminModal").modal("show");
            });
        }

        var buttonsPrekliciTermina = $(".btn-preklici-termina-mobile");
        for (let i = 0; i < buttonsPrekliciTermina.length; i++) {
            buttonsPrekliciTermina[i].addEventListener("click", async () => {
                this._idTermina = buttonsPrekliciTermina[i].dataset["idtermina"] as string;
                $("#prekliciTerminModal").modal("show");
            });
        }

        var buttonsOnemogociDan = $(".btn-onemogoci-dan-mobile");
        for (let i = 0; i < buttonsOnemogociDan.length; i++) {
            buttonsOnemogociDan[i].addEventListener("click", async () => {
                this._onemogociDanDatum = buttonsOnemogociDan[i].dataset["datumdan"] as string;
                var onemogociDan = buttonsOnemogociDan[i].dataset["onemogocidan"] as string;
                if (onemogociDan == "false") {
                    $("#modal-onemogocen-dan-vprasanje")[0].innerText = this._translationService.currentTranslations["AreYouSureYouWantToEnableTheSelectedDay"];
                    this._onemogociDan = false;
                }
                else {
                    $("#modal-onemogocen-dan-vprasanje")[0].innerText = this._translationService.currentTranslations["AreYouSureYouWantToDisableTheSelectedDay"];
                    this._onemogociDan = true;
                }
                $("#onemogocenDanModal").modal("show");
            });
        }

        var buttonsOnemogociTermina = $(".btn-onemogoci-termina-mobile");
        for (let i = 0; i < buttonsOnemogociTermina.length; i++) {
            buttonsOnemogociTermina[i].addEventListener("click", async () => {
                this._onemogociTerminaDatum = buttonsOnemogociTermina[i].dataset["datumincas"] as string;
                var onemogoci = buttonsOnemogociTermina[i].dataset["onemogoci"] as string;
                if (onemogoci == "false") {
                    $("#modal-onemogocen-termin-vprasanje")[0].innerText = this._translationService.currentTranslations["AreYouSureYouWantToEnableTheSelectedTerm"];
                    this._idTermina = buttonsOnemogociTermina[i].dataset["idtermina"] as string;
                }
                else {
                    $("#modal-onemogocen-termin-vprasanje")[0].innerText = this._translationService.currentTranslations["AreYouSureYouWantToDisableTheSelectedTerm"];
                    this._idTermina = "";
                }
                $("#onemogocenTerminModal").modal("show");
            });
        }
    }


    private _renderTableDesktop() {
        const templateString = `
                <table class="table racuni-placila klicni-center-table">
                    <thead>
                        <tr>
                            <%#koledarData%>
                                <%#datumVPreteklosti%>
                                    <td scope="col" style="color:grey; background-color:#EFEFEF">
                                        <p><%{danVTednu}%> (<%#formators%><%#fullDateFormat%><%datum%><%/fullDateFormat%><%/formators%>)</p> 
                                    </td>
                                <%/datumVPreteklosti%>
                                <%^datumVPreteklosti%>
                                    <%#prost%>
                                        <td scope="col" style="color:green; background-color:#dde9df">
                                            <p><%{danVTednu}%> (<%#formators%><%#fullDateFormat%><%datum%><%/fullDateFormat%><%/formators%>)</p> 
                                            <a class="btn-onemogoci-dan-desktop" data-onemogocidan="true" data-datumdan="<%{datum}%>">Onemogoči</a>
                                        </td>
                                    <%/prost%>
                                    <%#onemogocen%>
                                        <td scope="col" style="color:grey; background-color:#EFEFEF">
                                            <p><%{danVTednu}%> (<%#formators%><%#fullDateFormat%><%datum%><%/fullDateFormat%><%/formators%>)</p> 
                                            <a class="btn-onemogoci-dan-desktop" data-onemogocidan="false" data-datumdan="<%{datum}%>">Omogoči</a>
                                        </td>
                                    <%/onemogocen%>
                                    <%#rezerviran%>
                                        <td scope="col" style="color:orange; background-color:#fee3d1">
                                            <p><%{danVTednu}%> (<%#formators%><%#fullDateFormat%><%datum%><%/fullDateFormat%><%/formators%>)</p> 
                                        </td>
                                    <%/rezerviran%>
                                    <%#cakaNaPotrditev%>
                                        <td scope="col" style="color:#0096c7; background-color:#feffe8">
                                            <p><%{danVTednu}%> (<%#formators%><%#fullDateFormat%><%datum%><%/fullDateFormat%><%/formators%>)</p> 
                                        </td>
                                    <%/cakaNaPotrditev%>
                                    <%#zunajIzDelovnegaCasa%>
                                        <td scope="col" style="color:grey; background-color:#EFEFEF">
                                            <p><%{danVTednu}%> (<%#formators%><%#fullDateFormat%><%datum%><%/fullDateFormat%><%/formators%>)</p> 
                                        </td>
                                    <%/zunajIzDelovnegaCasa%>
                                    <%#praznik%>
                                        <td scope="col" style="color:grey; background-color:#EFEFEF">
                                            <p><%{danVTednu}%> (<%#formators%><%#fullDateFormat%><%datum%><%/fullDateFormat%><%/formators%>)</p> 
                                        </td>
                                    <%/praznik%>
                                <%/datumVPreteklosti%>
                            <%/koledarData%>
                        </tr>
                    </thead>
                    <tbody>
                        <%#vseTermine%>
                        <tr>
                            <%#.%>                               
                                <%#datumVPreteklosti%>
                                        <%#prost%>
                                            <td scope="row" style="color:grey; background-color:#EFEFEF">
                                                <p><%#formators%><%#dateTimeFormat%><%datumInCas%><%/dateTimeFormat%><%/formators%></p>
                                            </td> 
                                        <%/prost%>
                                        <%#onemogocen%>
                                            <td scope="row" style="color:grey; background-color:#EFEFEF">
                                                <p><%#formators%><%#dateTimeFormat%><%datumInCas%><%/dateTimeFormat%><%/formators%></p>                                              
                                            </td> 
                                        <%/onemogocen%>
                                        <%#rezerviran%>
                                            <td scope="row" style="color:grey; background-color:#EFEFEF">
                                                <div class="d-flex justify-content-between align-items-center mb-2">
                                                    <p class="mb-0"><%#formators%><%#dateTimeFormat%><%datumInCas%><%/dateTimeFormat%><%/formators%></p>
                                                    <div style="color:white; background-color:#198754; font-size: 11px; line-height: 12px; display: block; padding: 5px;">
                                                        <p class="mb-0">Potrjeno</p>  
                                                    </div> 
                                                </div>
                                                <p>Email: <%{email}%></p>
                                                <p>Ime in priimek: <%{imeInPriimek}%></p>
                                                <p>Namen: <%{namenObiska}%></p>
                                                <%#obstajaAdminKomentar%>
                                                    <p>Komentar: <%{adminKomentar}%></p>
                                                    <a class="btn-dodaj-komentar-desktop" data-trenutniKomentar="<%{adminKomentar}%>" data-idtermina="<%{id}%>"><small>Spremeni komentar</small></a> 
                                                <%/obstajaAdminKomentar%>
                                                <%^obstajaAdminKomentar%>
                                                    <a class="btn-dodaj-komentar-desktop" data-trenutniKomentar="" data-idtermina="<%{id}%>"><small>Dodaj komentar</small></a> 
                                                <%/obstajaAdminKomentar%> 
                                            </td> 
                                        <%/rezerviran%>
                                        <%#cakaNaPotrditev%>
                                            <td scope="row" style="color:grey; background-color:#EFEFEF">
                                                <div class="d-flex justify-content-between align-items-center mb-2">
                                                    <p class="mb-0"><strong><%#formators%><%#dateTimeFormat%><%datumInCas%><%/dateTimeFormat%><%/formators%></strong></p>
                                                    <div style="color:white; background-color:#00a9e2; font-size: 11px; line-height: 12px; display: block; padding: 5px;">
                                                        <p class="mb-0">Čaka potrditev</p> 
                                                    </div> 
                                                </div>
                                                <p>Email: <%{email}%></p>
                                                <p>Ime in priimek: <%{imeInPriimek}%></p>
                                                <p>Namen: <%{namenObiska}%></p>
                                            </td>
                                        <%/cakaNaPotrditev%>
                                        <%#zunajIzDelovnegaCasa%>
                                            <td scope="row" style="color:grey; background-color:#EFEFEF">
                                                <p> / </p>
                                            </td>
                                        <%/zunajIzDelovnegaCasa%>
                                        <%#praznik%>
                                            <td scope="row" style="color:grey; background-color:#EFEFEF">
                                                <p> / </p>
                                            </td>
                                        <%/praznik%>                                                                    
                                <%/datumVPreteklosti%>

                                <%^datumVPreteklosti%>
                                    <%#prost%>
                                        <td scope="row" style="color:green; background-color:#dde9df">
                                            <p><%#formators%><%#dateTimeFormat%><%datumInCas%><%/dateTimeFormat%><%/formators%></p>
                                            <a class="btn-onemogoci-termina-desktop" data-onemogoci="true" data-datumincas="<%{datumInCas}%>"><small>Onemogoči termin</small></a>
                                        </td>
                                    <%/prost%>
                                    <%#onemogocen%>
                                        <td scope="row" style="color:grey; background-color:#EFEFEF">
                                            <p><%#formators%><%#dateTimeFormat%><%datumInCas%><%/dateTimeFormat%><%/formators%></p>
                                            <a class="btn-onemogoci-termina-desktop" data-onemogoci="false" data-idtermina="<%{id}%>" data-datumincas="<%{datumInCas}%>">Omogoči termin</a>
                                            </td>
                                    <%/onemogocen%>
                                    <%#rezerviran%>
                                        <td scope="row" style="color:#4c4c4c; background-color:#a3cfbb; opacity">
                                            <div class="d-flex justify-content-between align-items-center mb-2">
                                                <p class="mb-0"><strong><%#formators%><%#dateTimeFormat%><%datumInCas%><%/dateTimeFormat%><%/formators%></strong></p>
                                                <div style="color:white; background-color:#198754; font-size: 11px; line-height: 12px; display: block; padding: 5px;">
                                                    <p class="mb-0">Potrjeno</p>  
                                                </div>                                          
                                            </div>
                                            <p>Email: <%{email}%></p>
                                            <p>Ime in priimek: <%{imeInPriimek}%></p>
                                            <p>Namen: <%{namenObiska}%></p>
                                            <%#obstajaAdminKomentar%>
                                                <p>Komentar: <%{adminKomentar}%></p> 
                                                <div class="d-flex justify-content-between mt-2">
                                                    <a class="btn-dodaj-komentar-desktop" data-trenutniKomentar="<%{adminKomentar}%>" data-idtermina="<%{id}%>"><small>Spremeni komentar</small></a>
                                                    <a class="btn-preklici-termina-desktop" data-idtermina="<%{id}%>"><small>Prekliči termin</small></a>
                                                </div> 
                                            <%/obstajaAdminKomentar%>
                                            <%^obstajaAdminKomentar%>
                                                <div class="d-flex justify-content-between mt-2">
                                                    <a class="btn-dodaj-komentar-desktop" data-trenutniKomentar="" data-idtermina="<%{id}%>"><small>Dodaj komentar</small></a>
                                                    <a class="btn-preklici-termina-desktop" data-idtermina="<%{id}%>"><small>Prekliči termin</small></a>
                                                </div> 
                                            <%/obstajaAdminKomentar%>                                             
                                        </td>
                                    <%/rezerviran%>
                                    <%#cakaNaPotrditev%>
                                        <td scope="row" style="color:#4c4c4c; background-color:#cceef9">
                                            <div class="d-flex justify-content-between align-items-center mb-2">
                                                <p class="mb-0"><strong><%#formators%><%#dateTimeFormat%><%datumInCas%><%/dateTimeFormat%><%/formators%></strong></p>
                                                <div style="color:white; background-color:#00a9e2; font-size: 11px; line-height: 12px; display: block; padding: 5px;">
                                                    <p class="mb-0">Čaka potrditev</p> 
                                                </div> 
                                            </div>
                                            <p>Email: <%{email}%></p>
                                            <p>Ime in priimek: <%{imeInPriimek}%></p>
                                            <p>Namen: <%{namenObiska}%></p>
                                            <div class="d-flex justify-content-between mt-2">
                                                <a class="btn-potrdi-termina-desktop" data-idtermina="<%{id}%>"><small>Potrdi termin</small></a>
                                                <a class="btn-preklici-termina-desktop" data-idtermina="<%{id}%>"><small>Prekliči termin</small></a>
                                            </div>
                                        </td>
                                    <%/cakaNaPotrditev%>
                                    <%#zunajIzDelovnegaCasa%>
                                        <td scope="row" style="color:grey; background-color:#EFEFEF">
                                            <p> / </p>
                                        </td>
                                    <%/zunajIzDelovnegaCasa%>
                                    <%#praznik%>
                                        <td scope="row" style="color:grey; background-color:#EFEFEF">
                                            <p> / </p>
                                        </td>
                                    <%/praznik%>
                                <%/datumVPreteklosti%>

                            <%/.%>
                        </tr>
                        <%/vseTermine%>
                    </tbody>
                </table>
             `;
        var vseTermine = this._getVseTermine(this._koledarData);
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "formators": this._formators,
                "koledarData": this._koledarData,
                "vseTermine": vseTermine
            },
            undefined,
            customTags
        );

        $(".tabela-desktop").addClass("d-lg-block");
        $(".tabela-desktop").removeClass("d-lg-none");
        $(".tabela-desktop").html(html);

        var buttonsDodajKomentar = $(".btn-dodaj-komentar-desktop");
        for (let i = 0; i < buttonsDodajKomentar.length; i++) {
            buttonsDodajKomentar[i].addEventListener("click", async () => {
                this._idTermina = buttonsDodajKomentar[i].dataset["idtermina"] as string;
                var trenutniKomentar = buttonsDodajKomentar[i].dataset["trenutnikomentar"] as string;
                $("#komentar").val(trenutniKomentar);
                $("#dodajKomentarModal").modal("show");
            });
        }

        var buttonsPotrditeTermina = $(".btn-potrdi-termina-desktop");
        for (let i = 0; i < buttonsPotrditeTermina.length; i++) {
            buttonsPotrditeTermina[i].addEventListener("click", async () => {
                this._idTermina = buttonsPotrditeTermina[i].dataset["idtermina"] as string;
                $("#rezerviranTerminModal").modal("show");
            });
        }

        var buttonsPrekliciTermina = $(".btn-preklici-termina-desktop");
        for (let i = 0; i < buttonsPrekliciTermina.length; i++) {
            buttonsPrekliciTermina[i].addEventListener("click", async () => {
                this._idTermina = buttonsPrekliciTermina[i].dataset["idtermina"] as string;
                $("#prekliciTerminModal").modal("show");
            });
        }

        var buttonsOnemogociDan = $(".btn-onemogoci-dan-desktop");
        for (let i = 0; i < buttonsOnemogociDan.length; i++) {
            buttonsOnemogociDan[i].addEventListener("click", async () => {
                this._onemogociDanDatum = buttonsOnemogociDan[i].dataset["datumdan"] as string;
                var onemogociDan = buttonsOnemogociDan[i].dataset["onemogocidan"] as string;
                if (onemogociDan == "false") {
                    $("#modal-onemogocen-dan-vprasanje")[0].innerText = this._translationService.currentTranslations["AreYouSureYouWantToEnableTheSelectedDay"];
                    this._onemogociDan = false;
                }
                else {
                    $("#modal-onemogocen-dan-vprasanje")[0].innerText = this._translationService.currentTranslations["AreYouSureYouWantToDisableTheSelectedDay"];
                    this._onemogociDan = true;
                }
                $("#onemogocenDanModal").modal("show");
            });
        }

        var buttonsOnemogociTermina = $(".btn-onemogoci-termina-desktop");
        for (let i = 0; i < buttonsOnemogociTermina.length; i++) {
            buttonsOnemogociTermina[i].addEventListener("click", async () => { 
                this._onemogociTerminaDatum = buttonsOnemogociTermina[i].dataset["datumincas"] as string;
                var onemogoci = buttonsOnemogociTermina[i].dataset["onemogoci"] as string;
                if (onemogoci == "false") {
                    $("#modal-onemogocen-termin-vprasanje")[0].innerText = this._translationService.currentTranslations["AreYouSureYouWantToEnableTheSelectedTerm"];
                    this._idTermina = buttonsOnemogociTermina[i].dataset["idtermina"] as string;
                }
                else {
                    $("#modal-onemogocen-termin-vprasanje")[0].innerText = this._translationService.currentTranslations["AreYouSureYouWantToDisableTheSelectedTerm"];
                    this._idTermina = "";
                }
                $("#onemogocenTerminModal").modal("show");
            });
        }
    }

    private _initForms() {
        this._formKomentar = $("#form-komentar");
        $.validator.unobtrusive.parse(this._formKomentar);
        this._formKomentar.validate();
        this._formKomentar.on("submit", async (e) => {
            e.preventDefault();
            await this._dodajKomentar(); 
        });
    }

    private async _dodajKomentar() {
        if (!this._formKomentar?.valid()) {
            this._notyf.error(this._translationService.currentTranslations.CheckAllFields);
            return;
        }
        $("#dodajKomentarModal").modal("hide");
        this._loader.show();
        var data: TerminDodajKomentarRequest = {
            id: this._idTermina,
            komentar: $("#komentar").val() as string
        };
        await this._kontaktiInPomocApiClient.terminDodajKomentar(data)
            .then((response) => {
                if (response) {
                    this._notyf.success(this._translationService.currentTranslations["SaveSuccessful"]);
                    $("#komentar").val("");
                }
                else {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
        await this._getKoledarData();
        this._renderTables();
        this._loader.hide();
    }

    private _initModals() {
        // Dodaj komentar modal
        var btnZapriteModalKomentar = $("#btn-zaprite-modal-komentar");
        btnZapriteModalKomentar.on("click", () => {
            $("#dodajKomentarModal").modal("hide");
        });
        var btnModalDodajKomentar = $("#btn-modal-dodaj-komentar");
        btnModalDodajKomentar.on("click", async () => {
            await this._dodajKomentar();    
        });

        // Rezerviran termin modal
        var btnRezervirajTerminZapriModal = $("#btn-zaprite-modal-rezerviran-termin");
        btnRezervirajTerminZapriModal.on("click", () => {
            $("#rezerviranTerminModal").modal("hide");
        });
        var btnRezervirajTermin = $("#btn-modal-rezerviraj-termin");
        btnRezervirajTermin.on("click", async () => {
            $("#rezerviranTerminModal").modal("hide");
            this._loader.show();
            await this._kontaktiInPomocApiClient.setTerminRezerviran(this._idTermina)
                    .then((response) => {
                        if (response.status) {
                            this._notyf.success(this._translationService.currentTranslations["SaveSuccessful"]);
                        }
                        else {
                            this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                        }
                    })
                    .catch(() => {
                        this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    }); 
            await this._getKoledarData();
            this._renderTables();
            this._loader.hide();
        });

        // Preklici termin modal
        var btnPrekliciTerminZapriModal = $("#btn-zaprite-modal-preklici-termin");
        btnPrekliciTerminZapriModal.on("click", () => {
            $("#prekliciTerminModal").modal("hide");
        });
        var btnPrekliciTermin = $("#btn-modal-preklici-termin");
        btnPrekliciTermin.on("click", async () => {
            $("#prekliciTerminModal").modal("hide");
            this._loader.show();
            await this._kontaktiInPomocApiClient.prekliciTermin(this._idTermina)
                .then((response) => {
                    if (response.status) {
                        this._notyf.success(this._translationService.currentTranslations["SaveSuccessful"]);
                    }
                    else {
                        this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                });
            await this._getKoledarData();
            this._renderTables();
            this._loader.hide();
        });

        // Onemogocen termin modal
        var btnOnemogocenTerminZapriModal = $("#btn-zaprite-modal-onemogocen-termin");
        btnOnemogocenTerminZapriModal.on("click", () => {
            $("#onemogocenTerminModal").modal("hide");
        });
        var btnOnemogociTermin = $("#btn-modal-onemogocen-termin");
        btnOnemogociTermin.on("click", async () => {
            if (!this._izbranoProdajnoMestoUmbraco) return;
            $("#onemogocenTerminModal").modal("hide");
            this._loader.show();
            var data: SetTerminOnemogocenRequest = {
                prodajnoMesto: this._izbranoProdajnoMestoUmbraco.ImeProdajnegaMesta,
                datum: this._onemogociTerminaDatum,
                onemogocen: true
            };
            if (this._idTermina != "") {
                data.id = this._idTermina;
                data.onemogocen = false;
            }
            await this._kontaktiInPomocApiClient.setTerminOnemogocen(data)
                .then((response) => {
                    if (response.status) {
                        this._notyf.success(this._translationService.currentTranslations["SaveSuccessful"]);
                    }
                    else {
                        this._notyf.error(response.sporocilo);
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                });
            await this._getKoledarData();
            this._renderTables();
            this._loader.hide();
        });

        // Onemogocen dan modal
        var btnOnemogocenDanZapriModal = $("#btn-zaprite-modal-onemogocen-dan");
        btnOnemogocenDanZapriModal.on("click", () => {
            $("#onemogocenDanModal").modal("hide");
        });
        var btnOnemogociDan = $("#btn-modal-onemogocen-dan");
        btnOnemogociDan.on("click", async () => {
            if (!this._izbranoProdajnoMestoUmbraco) return;

            $("#onemogocenDanModal").modal("hide");
            this._loader.show();
            var termini = Array<SetTerminOnemogocenRequest>(); 

            // Dodamo termine, ki jih moramo onemogočiti (ker jih v bazi ni)
            if (this._onemogociDan) {
                var izbranDatum = new Date(this._onemogociDanDatum);
                var dan = null;
                for (let i = 0; i < this._koledarData.length; i++) {
                    var datum = new Date(this._koledarData[i].datum);
                    if (datum.getFullYear() == izbranDatum.getFullYear()
                        && datum.getMonth() == izbranDatum.getMonth()
                        && datum.getDate() == izbranDatum.getDate()) {
                        dan = this._koledarData[i];
                        break;
                    }
                }
                if (dan != null) {
                    var terminiData = dan.terminiData.filter(a => a.zunajIzDelovnegaCasa == false);
                    if (terminiData != null) {
                        for (let i = 0; i < terminiData.length; i++) {
                            if (!terminiData[i].prost || terminiData[i].datumVPreteklosti) {
                                continue;
                            }
                            var datum = new Date(terminiData[i].datumInCas);
                            var cas = datum.getFullYear();
                            var month = datum.getMonth() + 1;
                            var day = datum.getDate();
                            var hour = datum.getHours();
                            var minutes = datum.getMinutes();
                            var datumInCas = cas.toString() + "-" + month.toString() + "-" + day.toString() + " " + hour.toString() + ":" + minutes.toString() + ":00";
                            var termin: SetTerminOnemogocenRequest = {
                                prodajnoMesto: this._izbranoProdajnoMestoUmbraco.ImeProdajnegaMesta,
                                datum: datumInCas,
                                onemogocen: false
                            };
                            termini.push(termin);
                        }
                    }
                }
            }
            // Če hočemo omogočiti termine na backendu poiščemo vse termine, ki jih je treba zbrisati
            else { }

            var data: SetDanOnemogocenRequest = {
                prodajnoMesto: this._izbranoProdajnoMestoUmbraco.ImeProdajnegaMesta,
                datum: this._onemogociDanDatum,
                onemogociDan: this._onemogociDan,
                termini: termini
            };
            await this._kontaktiInPomocApiClient.setDanOnemogocen(data)
                .then((response) => {
                    if (response) {
                        this._notyf.success(this._translationService.currentTranslations["SaveSuccessful"]);
                    }
                    else {
                        this._notyf.error(this._translationService.currentTranslations["DisableDayNotPossible"]);
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                });
            await this._getKoledarData();
            this._renderTables();
            this._loader.hide();
        });
    }

    private _getVseTermine(termini: Array<GetProdajnoMestoKoledarDay>) {
        var data = new Array<Array<GetProdajnoMestoKoledarTerminData>>();
        for (var i = 0; i < termini[0].terminiData.length; i++) {
            data[i] = new Array<GetProdajnoMestoKoledarTerminData>();
            for (var j = 0; j < termini.length; j++) {
                data[i].push(termini[j].terminiData[i]);
            }
        }
        return data;
    }

    public getFormators() {
        const userLocale = document.documentElement.lang
            ? document.documentElement.lang
            : 'sl';
        moment.locale(userLocale);
        return {
            dayFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format('dddd');
                };
            },
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format('D.M.');
                };
            },
            dateTimeFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format('HH:mm');
                };
            },
            fullDateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format('DD.MM.yyyy');
                };
            },
            decimalFormat: function () {
                return function (timestamp: any, render: any) {
                    var decimal = render(timestamp).trim() as number;
                    return Number(decimal).toLocaleString('sl-SI');
                };
            }
        };
    }
}
