import { inject, injectable, interfaces } from "inversify";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import { MainLayout } from "../../views/main-layout";

@injectable()
export class HomePage extends PageBase
{   
    public constructor(@inject("Provider<MainLayout>") private _mainLayoutProvider: interfaces.Provider<MainLayout>) {
        super();
        
    }

    public async execute(): Promise<boolean> {
        var mainLayout = await this._mainLayoutProvider() as MainLayout;
        try {
            // Indicate busy

        } catch (e) {
            // Report failures
            mainLayout.errorView.report(e);
        }

        return false;
    }



}