import { injectable } from "inversify";
import * as Mustache from "mustache";
import { TranslationService } from "../ts/translation-service";
import template from "./admin-notification-details-view.html";
import { AdminApiClient } from "../ts/clients/admin-api-client";
import { AdminNotificationDetailsResponse } from "../ts/models/admin-notification-details-response";
import { Notyf } from "notyf";
import * as moment from "moment";

@injectable()
export class AdminNotificationDetailsView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _adminApiClient: AdminApiClient;
    private _formators: any;
    private _notificationId: string;
    private _notification: AdminNotificationDetailsResponse | null;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        adminApiClient: AdminApiClient,
        private _notyf: Notyf) {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._adminApiClient = adminApiClient;
        this._formators = null;
        this._notificationId = "";
        this._notification = null;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var localeFormat = moment().creationData().locale.longDateFormat("L");
            this._formators = {
                dateFormat: function () {
                    return function (timestamp: any, render: any) {
                        return moment(render(timestamp).trim()).format(localeFormat);
                    };
                }//tukaj se lahko dodajajo formatorji
            };

            this._getNotificationId();

            await this._adminApiClient.getNotification(this._notificationId)
                .then((odgovor: any) => {
                    this._notification = odgovor;
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                });

            var translations = this._translationService.currentTranslations;
            this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;
            this._initForm();
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _getNotificationId() {
        var urlData = window.location.href.split("/");
        this._notificationId = urlData[urlData.length - 1];
    }

    private _renderData(translations: any): void {
        const viewModel = {
            translations: translations,
            formators: this._formators,
            notification: this._notification
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _initForm() {
        
    }
}
