import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { TranslationService } from "../ts/translation-service";
import template from "./zbirnik-racunov-view.html";
import { FullscreenLoader } from "./fullscreen-loader";
import { UporabnikPoslovnePartnerjeResponse, UporabnikPoslovniPartnerResponseData } from "../ts/models/uporabnik-poslovne-partnerje-response";
import { UporabnikPoslovnePartnerjeRequest } from "../ts/models/uporabnik-poslovne-partnerje-request";
import { ZbirnikRacunovRequest } from "../ts/models/zbirnik-racunov-request";
import { ZbirnikRacunovResponse, ZbirnikRacunovSeznamFakturResponse } from "../ts/models/zbirnik-racunov-response";
import * as moment from "moment";
//import fakturiGrafView from "./zbirnik-racunov-graf-view.html";
//import * as ApexCharts from 'apexcharts';

@injectable()
export class ZbirnikRacunovView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _poslovniPartnerji: Array<UporabnikPoslovniPartnerResponseData>;
    private _izbraniPoslovniPartner: UporabnikPoslovniPartnerResponseData | undefined;
    private _dropdownPoslovnePartnerje: JQuery<HTMLElement> | null;
    private _seznamFaktur: Array<ZbirnikRacunovSeznamFakturResponse>;
    private _limit: number;
    private _formators: any;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        merilnoMestoApiClient: MerilnoMestoApiClient,
        private _notyf: Notyf,
        private _loader: FullscreenLoader) {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._poslovniPartnerji = new Array<UporabnikPoslovniPartnerResponseData>;
        this._dropdownPoslovnePartnerje = null;
        this._seznamFaktur = new Array<ZbirnikRacunovSeznamFakturResponse>;
        this._limit = 10;
        this._formators = null;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;
            var data: UporabnikPoslovnePartnerjeRequest = {
                stStrani: 0
            };
            await this._merilnoMestoApiClient.uporabnikPoslovnePartnerje(data)
                .then((odgovor: UporabnikPoslovnePartnerjeResponse) => {
                    if (odgovor.status) {
                        this._poslovniPartnerji = odgovor.poslovniPartnerData;
                        if (this._poslovniPartnerji != null && this._poslovniPartnerji.length > 0) {
                            // poiscemo ali obstaja selektiran poslovni partner (v querystringu)
                            var url_string = window.location.href.split("/");
                            var stevilkaPoslovniPartner = url_string[url_string.length - 1];
                            this._izbraniPoslovniPartner = this._poslovniPartnerji.find(a => a.stevilkaPoslovniPartner == stevilkaPoslovniPartner);
                            if (this._izbraniPoslovniPartner == undefined) {
                                this._izbraniPoslovniPartner = this._poslovniPartnerji[0];
                            }
                        }
                        else {
                            this.rootElement.text("Nimate nobenega poslovnega partnerja.");
                            return;
                        }
                    }
                    else {
                        this._notyf.error(odgovor.sporocilo);
                    }
                })
                .catch(() => {
                    this._notyf.error("Prišlo je do napake pri iskanju poslovnih partnerjev.");
                });

            this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;
            this._initForm();
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(translations: any): void {
        if (!this._poslovniPartnerji) return;

        var localeFormat = moment().creationData().locale.longDateFormat("L");
        this._formators = {
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            }//tukaj se lahko dodajajo formatorji
        };

        var now = new Date();
        var filterYearFrom = now.getFullYear();
        var filterYearTo = now.getFullYear();
        var filterMonthFrom = now.getMonth();
        var filterMonthTo = now.getMonth() + 1;
        if (now.getMonth() === 0) {
            filterYearFrom = filterYearFrom - 1;
        }
        var leta = Array<Number>();
        for (let i = 2015; i <= now.getFullYear(); i++) {
            leta.push(i);
        }

        const viewModel = {
            izbraniPoslovniPartner: this._izbraniPoslovniPartner,
            poslovniPartnerji: this._poslovniPartnerji,
            monthFromOptions: this.renderMonthDropDown(filterMonthFrom),
            monthToOptions: this.renderMonthDropDown(filterMonthTo),
            yearFromOptions: this.renderYearDropDown(filterYearFrom),
            yearToOptions: this.renderYearDropDown(filterYearTo),
            translations: translations
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _initForm(): void {
        if (this._izbraniPoslovniPartner == undefined) return;
        this._dropdownPoslovnePartnerje = $("#dropdownPoslovnePartnerje");
        this._dropdownPoslovnePartnerje.val(this._izbraniPoslovniPartner.stevilkaPoslovniPartner);
        this._dropdownPoslovnePartnerje.on("change", () => {
            if (this._dropdownPoslovnePartnerje == null) return;
            window.location.href = "#/zbirnik-racunov/" + this._dropdownPoslovnePartnerje.val();
        });

        var btnPregledRacunov = $("#btnPregledRacunov");
        btnPregledRacunov.on("click", (ev: Event) => {
            // $("#fakturi-graf").hide();
            $(".tabela-fakturi").hide();
            $(".fakturi-ne-obstajajo").hide();
            this._onBtnPregledRacunovClick(ev)
        });

        var btnPrenesiZbirnikRacunov = $("#prenesite-zbirnik-racunov-btn");
        btnPrenesiZbirnikRacunov.on("click", (ev: Event) => this.__onBtnPrenesiteZbirnikRacunovClick(ev) );
    }

    private async __onBtnPrenesiteZbirnikRacunovClick(ev: Event) {
        ev.preventDefault();
        if (!this._seznamFaktur) return;
        var downloadingNotyf = this._notyf.open({
            type: "info",
            message: this._translationService.currentTranslations["Downloading"],
            duration: 50000
        });
        await this._merilnoMestoApiClient.zbirnikRacunovDownload(this._seznamFaktur)
            .then((response) => {
                const downloadUrl = response.downloadUrl;
                const fileLink = document.createElement('a');
                fileLink.href = downloadUrl;
                fileLink.click();
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["ErrorWhileDownloadingFile"]);
            }).finally(() => {
                this._notyf.dismiss(downloadingNotyf);
            });
    }

    private async _onBtnPregledRacunovClick(ev: Event) {
        ev.preventDefault();
        if (this._izbraniPoslovniPartner == undefined) return;
        this._loader.show();
        var mesecOd = Number($("#obdobjeMesecOd").val());
        var letoOd = Number($("#obdobjeLetoOd").val());
        var mesecDo = Number($("#obdobjeMesecDo").val());
        var letoDo = Number($("#obdobjeLetoDo").val());
        var obdobjeOd = new Date(letoOd, mesecOd - 1, 1);
        var obdobjeDo = new Date(letoDo, mesecDo - 1, 1);
        var stevilkaPoslovniPartner = $("#dropdownPoslovnePartnerje").val() as string;
        var data: ZbirnikRacunovRequest = {
            obdobjeOd: obdobjeOd,
            obdobjeDo: obdobjeDo,
            stevilkaPoslovniPartner: stevilkaPoslovniPartner
        };
        await this._merilnoMestoApiClient.zbirnikRacunov(data)
            .then((odgovor: ZbirnikRacunovResponse) => {
                if (odgovor.status) {
                    if (odgovor.seznamFaktur != null && odgovor.seznamFaktur.length > 0) {
                        this._seznamFaktur = odgovor.seznamFaktur;

                        //// sortiramo seznam faktur po datumu dokumenta (od najstarejše do najnovejše)
                        //this._seznamFaktur.sort((a, b) => (a.datumDokumenta > b.datumDokumenta) ? 1 : ((b.datumDokumenta > a.datumDokumenta) ? -1 : 0));
                        //// renderiramo grafa
                        //this._renderFakturiGraf(this._seznamFaktur);

                        // sortiramo seznam faktur po datumu dokumenta (od najnovejše do najstarejše)
                        this._seznamFaktur.sort((a, b) => (a.datumDokumenta < b.datumDokumenta) ? 1 : ((b.datumDokumenta < a.datumDokumenta) ? -1 : 0));

                        // renderiramo tabelo
                        this._renderFakturiTables(this._seznamFaktur, 1, odgovor.obstajaFakturaZVecMerilnihMest);
                        $(".tabela-fakturi").show();
                        $(".fakturi-ne-obstajajo").hide();
                    }
                    else {
                        // $("#fakturi-graf").hide();
                        $(".tabela-fakturi").hide();
                        $(".fakturi-ne-obstajajo").show();
                    }
                }
                else {
                    this._notyf.error(odgovor.sporocilo);
                }
            })
            .catch(() => {
                this._notyf.error("Prišlo je do napake pri iskanju zbirnika računov.");
            });
        this._loader.hide();
    }

    //private _renderFakturiGraf(seznam: Array<ZbirnikRacunovSeznamFakturResponse>) {
    //    var datume = new Array<String>();
    //    var zneske = new Array<Number>();
    //    for (let i = 0; i < seznam.length; i++) {
    //        var datum = new Date(seznam[i].datumDokumenta);
    //        var datumString = (datum.getDate().toString() + "." + (datum.getMonth() + 1).toString() + "." + datum.getFullYear().toString());
    //        datume.push(datumString);
    //        zneske.push(seznam[i].znesekRacuna);
    //    }
    //    const html = Mustache.render(fakturiGrafView, {
    //        translations: this._translationService.currentTranslations
    //    });

    //    $("#fakturi-graf").html(html);
    //    var chartOptions = {
    //        chart: {
    //            width: "100%",
    //            height: 380,
    //            type: 'bar',
    //            toolbar: {
    //                tools: {
    //                    download: false
    //                }
    //            }
    //        },
    //        series: [
    //            {
    //                name: "EUR",
    //                data: zneske
    //            },
    //        ],

    //        xaxis: {
    //            categories: datume
    //        },

    //        title: {
    //            alight: 'left',
    //            // text: "Skupna poraba", 
    //            floating: true,
    //            offsetX: -10,
    //            offsetY: 10,
    //            style: {
    //                fontFamily: 'Red Hat Display',
    //                fontWeight: '400',
    //                fontSize: '15px',
    //                colour: 'rgb(33, 37, 41)',

    //            }
    //        },
    //        yaxis: {
    //            labels: {
    //                show: true,
    //                formatter: ((val: any) => { return val.toFixed(2) + " EUR" })
    //            }
    //        },
    //        legend: {
    //            show: true,
    //            position: 'top',
    //            horizontalAlign: 'right',
    //            offsetY: 10,
    //            labels: {
    //                useSeriesColors: false
    //            }
    //        },

    //        fill: {
    //            colors: ['#008FFB', '#00E396', '#FFB019']
    //        },

    //        grid: {
    //            show: true,
    //            borderColor: "#e8e4e4",
    //            strokeDashArray: 0,
    //            position: 'back',
    //            yaxis: {
    //                lines: {
    //                    show: true
    //                }
    //            },
    //            xaxis: {
    //                lines: {
    //                    show: true
    //                }
    //            },
    //        },

    //        dataLabels: {
    //            enabled: false
    //        }
    //    };
    //    //.apexcharts-legend-series.custom-marker-down .apexcharts-legend-marker
    //    var chart = new ApexCharts($("#fakturi-graf").find("#fakturi-graf-div")[0], chartOptions);
    //    chart.render();
    //    $("#fakturi-graf").show();
    //}

    private _renderFakturiTables(seznam: any, page: number, obstajaFakturaZVecMerilnihMest: boolean) {
        var displayedFakturi = new Array<any>();
        if (seznam && seznam.length > 0) {
            for (let i = (page - 1) * this._limit; i < page * this._limit; i++) {
                if (i >= seznam.length) {
                    break;
                }
                displayedFakturi.push(seznam[i]);
            }
        }
        this._renderFakturiTableDesktop(displayedFakturi, obstajaFakturaZVecMerilnihMest);
        $(".tabela-fakturi-desktop").addClass("d-lg-block");
        $(".tabela-fakturi-desktop").removeClass("d-lg-none");
        this._renderFakturiTableMobile(displayedFakturi, obstajaFakturaZVecMerilnihMest);
        $(".tabela-fakturi-mobile").addClass("d-block");
        $(".tabela-fakturi-mobile").removeClass("d-none");
        this._refreshPaginationFakturi(seznam, page, obstajaFakturaZVecMerilnihMest);
    }

    private _onBtnPageChangeClick(ev: Event, previous: boolean, seznam: any, obstajaFakturaZVecMerilnihMest: boolean) {
        console.debug(ev);
        ev.preventDefault();
        this._loader.show();
        var pagesString = $(".pages")[0].innerHTML;
        var currentPage = Number(pagesString.split("/")[0]);
        if (previous) {
            currentPage -= 1;
        }
        else {
            currentPage += 1;
        }
        this._renderFakturiTables(seznam, currentPage, obstajaFakturaZVecMerilnihMest);
        this._loader.hide();
    }

    private _refreshPaginationFakturi(seznam: Array<any>, page: number, obstajaFakturaZVecMerilnihMest: boolean) {
        if (seznam) {
            const templateString = `
             <div class="pagination">
                <%#obstajaPrejsnjaStran%>
                <div>
                    <a class="btnPreviousPage">&lt</a>
                </div>
                <%/obstajaPrejsnjaStran%>
                <div class="pages"><%{page}%>/<%{maxPage}%></div>
                <%#obstajaNaslednjaStran%>
                <div>
                    <a class="btnNextPage">&gt</a>
                </div>
                <%/obstajaNaslednjaStran%>
            </div>
        `;
            var customTags: any = ['<%', '%>'];
            var maxPage = Math.ceil(seznam.length / this._limit);
            var html = Mustache.render(
                templateString,
                {
                    "page": page,
                    "maxPage": maxPage,
                    "obstajaPrejsnjaStran": page != 1 ? true : false,
                    "obstajaNaslednjaStran": page != maxPage ? true : false
                },
                undefined,
                customTags
            );
            $("#pagination-fakturi").show();
            $("#pagination-fakturi").html(html);
            $(".btnPreviousPage").on("click", (ev: Event) => this._onBtnPageChangeClick(ev, true, seznam, obstajaFakturaZVecMerilnihMest));
            $(".btnNextPage").on("click", (ev: Event) => this._onBtnPageChangeClick(ev, false, seznam, obstajaFakturaZVecMerilnihMest));
        }
        else {
            $("#pagination-fakturi").hide();
        }
    }

    private _renderFakturiTableMobile(displayedFakturi: any, obstajaFakturaZVecMerilnihMest: boolean) {
        const templateString = `
                <%#obstajaFaktura%>
                <table class="table zgodovina-odcitkov-mobile">
                    <tbody>
                    <%#fakturi%>
                        <tr>
                            <td class="table-inner-wrap">
                                <div class="table-responsive">
                                    <table class="table table-borderless">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Uradna št. dokumenta</th>
                                                <td><%{uradnaStevilkaDokumenta}%></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Veljavnost od</th>
                                                <td><%#formators%><%#dateFormat%><%veljavnoOd%><%/dateFormat%><%/formators%></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Veljavnost do</th>
                                                <td><%#formators%><%#dateFormat%><%veljavnoDo%><%/dateFormat%><%/formators%></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Datum dokumenta</th>
                                                <td><%#formators%><%#dateFormat%><%datumDokumenta%><%/dateFormat%><%/formators%></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Plačilni pogoj</th>
                                                <td><%{placilniPogoj}%></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Znesek</th>
                                                <td><%{znesekRacuna}%></td>
                                            </tr>
                                            <%#obstajaVecMerilnihMest%>
                                            <tr>
                                                <th scope="row">Naziv MM</th>
                                                <td>*</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Naslov MM</th>
                                                <td>*</td>
                                            </tr>
                                            <%/obstajaVecMerilnihMest%>
                                            <%^obstajaVecMerilnihMest%>
                                                <tr>
                                                    <th scope="row">Naziv MM</th>
                                                    <td><%{vsaMerilnaMestaNaziv}%></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Naslov MM</th>
                                                    <td><%{vsaMerilnaMestaNaslov}%></td>
                                                </tr>
                                            <%/obstajaVecMerilnihMest%>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <%/fakturi%>
                </table>
                <%#obstajaFakturaZVecMerilnihMest%>
                    <p>* - Več merilnih mest. Za pregled merilnih mest je potreben prenos zbirnika računov</p>
                <%/obstajaFakturaZVecMerilnihMest%> 
                <%/obstajaFaktura%>
                <%^obstajaFaktura%>
                <div><%{translations.NoData}%></div>
                <%/obstajaFaktura%>
             `;
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "formators": this._formators,
                "obstajaFaktura": displayedFakturi.length > 0 ? true : false,
                "fakturi": displayedFakturi,
                "obstajaFakturaZVecMerilnihMest": obstajaFakturaZVecMerilnihMest,
                "translations": this._translationService.currentTranslations
            },
            undefined,
            customTags
        );
        $(".tabela-fakturi-mobile").html(html);
    }

    private _renderFakturiTableDesktop(displayedFakturi: any, obstajaFakturaZVecMerilnihMest: boolean) {
        const templateString = `
                <%#obstajaFaktura%>
                    <table class="table zgodovina-odcitkov">
                        <thead>
                            <tr>
                                <td scope="col">Uradna št. dokumenta</td>
                                <td scope="col">Veljavnost od</td>
                                <td scope="col">Veljavnost do</td>
                                <td scope="col">Datum dokumenta</td>
                                <td scope="col">Plačilni pogoj</td>
                                <td scope="col">Znesek</td>
                                <td scope="col">Naziv MM</td>
                                <td scope="col">Naslov MM</td>
                            </tr>
                        </thead>
                        <tbody>
                            <%#fakturi%>
                            <tr>
                                <td><%{uradnaStevilkaDokumenta}%></td>
                                <td scope="row"><%#formators%><%#dateFormat%><%veljavnoOd%><%/dateFormat%><%/formators%></td>
                                <td scope="row"><%#formators%><%#dateFormat%><%veljavnoDo%><%/dateFormat%><%/formators%></td>
                                <td scope="row"><%#formators%><%#dateFormat%><%datumDokumenta%><%/dateFormat%><%/formators%></td>  
                                <td><%{placilniPogoj}%></td>
                                <td><%{znesekRacuna}%></td>
                                <%#obstajaVecMerilnihMest%>
                                    <td>*</td>
                                    <td>*</td>
                                <%/obstajaVecMerilnihMest%>
                                <%^obstajaVecMerilnihMest%>
                                    <td><%{vsaMerilnaMestaNaziv}%></td>
                                    <td><%{vsaMerilnaMestaNaslov}%></td>
                                <%/obstajaVecMerilnihMest%>                              
                            </tr>
                            <%/fakturi%>
                        </tbody>
                    </table>
                    <%#obstajaFakturaZVecMerilnihMest%>
                        <p>* - Več merilnih mest. Za pregled merilnih mest je potreben prenos zbirnika računov</p>
                    <%/obstajaFakturaZVecMerilnihMest%> 
                <%/obstajaFaktura%>
                <%^obstajaFaktura%>
                    <div><%{translations.NoData}%></div>
                <%/obstajaFaktura%>
             `;
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "formators": this._formators,
                "obstajaFaktura": displayedFakturi.length > 0 ? true : false,
                "fakturi": displayedFakturi,
                "obstajaFakturaZVecMerilnihMest": obstajaFakturaZVecMerilnihMest,
                "translations": this._translationService.currentTranslations
            },
            undefined,
            customTags
        );
        $(".tabela-fakturi-desktop").html(html);
    }

    private renderMonthDropDown(selectedMoth: number) {
        return [
            { val: 1, txt: this._translationService.currentTranslations["January"], sel: selectedMoth == 1 ? true : false },
            { val: 2, txt: this._translationService.currentTranslations["February"], sel: selectedMoth == 2 ? true : false },
            { val: 3, txt: this._translationService.currentTranslations["March"], sel: selectedMoth == 3 ? true : false },
            { val: 4, txt: this._translationService.currentTranslations["April"], sel: selectedMoth == 4 ? true : false },
            { val: 5, txt: this._translationService.currentTranslations["May"], sel: selectedMoth == 5 ? true : false },
            { val: 6, txt: this._translationService.currentTranslations["June"], sel: selectedMoth == 6 ? true : false },
            { val: 7, txt: this._translationService.currentTranslations["July"], sel: selectedMoth == 7 ? true : false },
            { val: 8, txt: this._translationService.currentTranslations["August"], sel: selectedMoth == 8 ? true : false },
            { val: 9, txt: this._translationService.currentTranslations["September"], sel: selectedMoth == 9 ? true : false },
            { val: 10, txt: this._translationService.currentTranslations["October"], sel: selectedMoth == 10 ? true : false },
            { val: 11, txt: this._translationService.currentTranslations["November"], sel: selectedMoth == 11 ? true : false },
            { val: 12, txt: this._translationService.currentTranslations["December"], sel: selectedMoth == 12 ? true : false },
        ];
    }

    private renderYearDropDown(selectedYear: number) {
        var now = new Date();
        var options = [];
        for (let i = 2015; i <= now.getFullYear(); i++) {
            options.push({ val: i, txt: i, sel: selectedYear == i ? true : false });
        }
        return options;
    }
}
