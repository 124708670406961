import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import * as ApexCharts from 'apexcharts';
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { FakturiranaPorabaElektrikaResponse } from "../ts/models/fakturirana-poraba-elektrika-response";
import { FakturiranaPorabaPlinResponse } from "../ts/models/fakturirana-poraba-plin-response";
import { FakturiranaPorabaOdjemnoMestoRequest, FakturiranaPorabaRequest } from "../ts/models/fakturirana-poraba-request";
import { MerilnoMesto } from "../ts/models/merilno-mesto";
import { UporabnikMerilnoMestoRequest } from "../ts/models/uporabnik-merilno-mesto-request";
import { UporabnikMerilnoMestoResponse } from "../ts/models/uporabnik-merilno-mesto-response";
import { TranslationService } from "../ts/translation-service";
import { FullscreenLoader } from "./fullscreen-loader";
import template from "./merilno-mesto-poraba-sestevek-porabe-view.html";
import porabaGrafView from "./merilno-mesto-poraba-graf-view.html";
import { GrafMerilnoMestoModel } from "../ts/models/grafMerilnoMestoModel";

@injectable()
export class MerilnoMestoPorabaSestevekPorabeView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _merilnaMestaElektrika: Array<MerilnoMesto>;
    private _merilnaMestaPlin: Array<MerilnoMesto>;
    private _fakturiranaPorabaElektrika: FakturiranaPorabaElektrikaResponse | null;
    private _fakturiranaPorabaPlin: FakturiranaPorabaPlinResponse | null;
    private _obstajaMerilnoMesto: boolean;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        merilnoMestoApiClient: MerilnoMestoApiClient,
        private _notyf: Notyf,
        private _loader: FullscreenLoader)
    {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._merilnaMestaElektrika = new Array<MerilnoMesto>();
        this._merilnaMestaPlin = new Array<MerilnoMesto>();
        this._obstajaMerilnoMesto = false;
        this._fakturiranaPorabaElektrika = null;
        this._fakturiranaPorabaPlin = null;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;
            var uporabnikMerilnoMestoRequest: UporabnikMerilnoMestoRequest = {
                limit: 0,
                stevilkaStrani: 0,
                saldo: false,
                skupnaPorabaEnergije: false,
                merilnoMesto: "",
                merilnaTocka: ""
            };
            await this._merilnoMestoApiClient.getUporabnikMerilnaMesta(uporabnikMerilnoMestoRequest)
                .then((odgovor: UporabnikMerilnoMestoResponse) => {
                    if (odgovor.merilnaMesta && odgovor.merilnaMesta.length > 0) {
                        this._merilnaMestaElektrika = odgovor.merilnaMesta.filter(a => a.panoga == "01");
                        this._merilnaMestaPlin = odgovor.merilnaMesta.filter(a => a.panoga == "02");
                        this._obstajaMerilnoMesto = true;
                    }
                    else {
                        this._obstajaMerilnoMesto = false;
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                });

            this._renderData(translations, this._merilnaMestaElektrika, this._merilnaMestaPlin);
            if ($("#page-loaded")[0] == undefined) return;
            if (this._obstajaMerilnoMesto) {
                this._initForm();
            }
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(translations: any, merilnaMestaElektrika: any, merilnaMestaPlin: any): void
    {
        var now = new Date();

        var filterYearFrom = now.getFullYear();
        var filterYearTo = now.getFullYear();
        var filterMonthFrom = 1;
        var filterMonthTo = now.getMonth() + 1;
        if (now.getMonth() === 0) {
            filterYearFrom = filterYearFrom - 1;
        }
        var leta = Array<Number>();
        for (let i = 2015; i <= now.getFullYear(); i++) {
            leta.push(i);
        }
        const viewModel = {
            merilnaMestaElektrika: merilnaMestaElektrika,
            merilnaMestaPlin: merilnaMestaPlin,
            leta: leta,
            translations: translations,
            obstajaMerilnoMesto: this._obstajaMerilnoMesto,
            monthFromOptions: this.renderMonthDropDown(filterMonthFrom),
            monthToOptions: this.renderMonthDropDown(filterMonthTo),
            yearFromOptions: this.renderYearDropDown(filterYearFrom),
            yearToOptions: this.renderYearDropDown(filterYearTo)
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private renderMonthDropDown(selectedMoth: number) {
        return [
            { val: 1, txt: this._translationService.currentTranslations["January"], sel: selectedMoth == 1 ? true : false },
            { val: 2, txt: this._translationService.currentTranslations["February"], sel: selectedMoth == 2 ? true : false },
            { val: 3, txt: this._translationService.currentTranslations["March"], sel: selectedMoth == 3 ? true : false },
            { val: 4, txt: this._translationService.currentTranslations["April"], sel: selectedMoth == 4 ? true : false },
            { val: 5, txt: this._translationService.currentTranslations["May"], sel: selectedMoth == 5 ? true : false },
            { val: 6, txt: this._translationService.currentTranslations["June"], sel: selectedMoth == 6 ? true : false },
            { val: 7, txt: this._translationService.currentTranslations["July"], sel: selectedMoth == 7 ? true : false },
            { val: 8, txt: this._translationService.currentTranslations["August"], sel: selectedMoth == 8 ? true : false },
            { val: 9, txt: this._translationService.currentTranslations["September"], sel: selectedMoth == 9 ? true : false },
            { val: 10, txt: this._translationService.currentTranslations["October"], sel: selectedMoth == 10 ? true : false },
            { val: 11, txt: this._translationService.currentTranslations["November"], sel: selectedMoth == 11 ? true : false },
            { val: 12, txt: this._translationService.currentTranslations["December"], sel: selectedMoth == 12 ? true : false },
        ];
    } 

    private renderYearDropDown(selectedYear: number) {
        var now = new Date();
        var options = [];
        for (let i = 2015; i <= now.getFullYear(); i++) {
            options.push({ val: i, txt: i, sel: selectedYear == i ? true : false });
        }
        return options;
    }

    private _initForm(): void {
        var allCheckBoxes = $(".icon-bar-checkbox :checkbox");
        allCheckBoxes.on("change", function () {
            var clickedCheckBox = $(this).closest(".tile-icon-bar")[0];
            clickedCheckBox = $(clickedCheckBox).find(".form-check-input")[0];
            $(this).closest(".tile-icon-bar").toggleClass("checked", (clickedCheckBox as HTMLInputElement).checked);
        });

        var izberiVsaMerilnaMestaElektrikaBtn = $(".select-all-elektrika");
        izberiVsaMerilnaMestaElektrikaBtn.on("click", function () {
            var allCheckBoxes = $(".form-check-input-elektrika");
            if (izberiVsaMerilnaMestaElektrikaBtn.data("id") == "select-all-yes") {
                for (let i = 0; i < allCheckBoxes.length; i++) {
                    (allCheckBoxes[i] as HTMLInputElement).checked = true;
                }
                $(".tile-icon-bar-elektrika").toggleClass("checked", true);
                izberiVsaMerilnaMestaElektrikaBtn.data("id", "select-all-no");
            }
            else {
                for (let i = 0; i < allCheckBoxes.length; i++) {
                    (allCheckBoxes[i] as HTMLInputElement).checked = false;
                }
                $(".tile-icon-bar-elektrika").toggleClass("checked", false);
                izberiVsaMerilnaMestaElektrikaBtn.data("id", "select-all-yes");
            }
        });

        var izberiVsaMerilnaMestaPlinBtn = $(".select-all-plin");
        izberiVsaMerilnaMestaPlinBtn.on("click", function () {
            var allCheckBoxes = $(".form-check-input-plin");
            if (izberiVsaMerilnaMestaPlinBtn.data("id") == "select-all-yes") {
                for (let i = 0; i < allCheckBoxes.length; i++) {
                    (allCheckBoxes[i] as HTMLInputElement).checked = true;
                }
                $(".tile-icon-bar-plin").toggleClass("checked", true);
                izberiVsaMerilnaMestaPlinBtn.data("id", "select-all-no");
            }
            else {
                for (let i = 0; i < allCheckBoxes.length; i++) {
                    (allCheckBoxes[i] as HTMLInputElement).checked = false;
                }
                $(".tile-icon-bar-plin").toggleClass("checked", false);
                izberiVsaMerilnaMestaPlinBtn.data("id", "select-all-yes");
            }
        });

        var btnRadioLoceno = $("#radioLoceno");
        btnRadioLoceno.on("change", () => {
            if ((btnRadioLoceno[0] as HTMLInputElement).checked) {
                $(".prikaz-porabe-text").hide();
            }
        });
        var btnRadioSumarno = $("#radioSumarno");
        btnRadioSumarno.on("change", () => {
            if ((btnRadioSumarno[0] as HTMLInputElement).checked) {
                $(".prikaz-porabe-text").show();
            }
        });

        var btnPregledPorabe = $("#btn-pregled-porabe");
        btnPregledPorabe.on("click", (ev: Event) => this._onPregledPorabeClick(ev));

        var btnDownloadExcel = $("#btn-download-excel");
        btnDownloadExcel.on("click", (ev: Event) => this._onPrenesiDatotekoClick(ev));
    }

    private _preberiZacetniDatum(izbranEnMesec: boolean) {
        if (izbranEnMesec) {
            var mesec = Number($(".izbira1-mesec-dropdown").val());
            var leto = Number($(".izbira1-leto-dropdown").val());
            var datum = new Date(leto, mesec - 1, 1);
        }
        else {
            var mesec = Number($(".izbira2-mesec-dropdown").val());
            var leto = Number($(".izbira2-leto-dropdown").val());
            var datum = new Date(leto, mesec - 1, 1);
        }
        datum.setDate(datum.getDate() + 1); // dodamo 1 dan za da, na backend pridejo pravilni podatki
        return datum;
    }

    private _preberiKoncniDatum() {
        var mesec = Number($(".izbira3-mesec-dropdown").val());
        var leto = Number($(".izbira3-leto-dropdown").val());
        var datum = new Date(leto, mesec - 1, 1);
        datum.setDate(datum.getDate() + 1); // dodamo 1 dan za da, na backend pridejo pravilni podatki
        return datum;
    }

    private async _onPrenesiDatotekoClick(ev: Event) {
        ev.preventDefault();       
        // Preberemo izbrana merilna mesta
        var elektrikaTab = $("#poraba-elektrika-tab");
        var plinTab = $("#poraba-plin-tab");
        var jeElektrika = true;
        var merilnaMesta = new Array<FakturiranaPorabaOdjemnoMestoRequest>();

        // elektrika
        if (elektrikaTab.hasClass("active")) {
            jeElektrika = true;
            var elektrikaCheckBoxes = $(".form-check-input-elektrika");
            for (let i = 0; i < elektrikaCheckBoxes.length; i++) {
                if ((elektrikaCheckBoxes[i] as HTMLInputElement).checked) {
                    var merilnoMesto: FakturiranaPorabaOdjemnoMestoRequest = {
                        merilnoMesto: this._merilnaMestaElektrika[i].merilnoMesto,
                        merilnaTocka: this._merilnaMestaElektrika[i].merilnaTocka,
                        stevilkaKontoPogodbe: this._merilnaMestaElektrika[i].stevilkaKontoPogodbe,
                    }
                    merilnaMesta.push(merilnoMesto);
                }
            }
        }
        // plin
        else if (plinTab.hasClass("active")) {
            jeElektrika = false;
            var plinCheckBoxes = $(".form-check-input-plin");
            for (let i = 0; i < plinCheckBoxes.length; i++) {
                if ((plinCheckBoxes[i] as HTMLInputElement).checked) {
                    var merilnoMesto: FakturiranaPorabaOdjemnoMestoRequest = {
                        merilnoMesto: this._merilnaMestaPlin[i].merilnoMesto,
                        merilnaTocka: this._merilnaMestaPlin[i].merilnaTocka,
                        stevilkaKontoPogodbe: this._merilnaMestaPlin[i].stevilkaKontoPogodbe,
                    }
                    merilnaMesta.push(merilnoMesto);
                }
            }
        }

        if (merilnaMesta.length == 0) {
            this._notyf.error(this._translationService.currentTranslations["YouMustSelectAtLeastOneMeasuringSamplingPoint"]);
            this._loader.hide();
            return;
        }

        // Preberemo datume
        var porabaMesecTab = $("#poraba-mesec-tab");
        var datumOd = new Date();
        var datumDo = new Date();
        // izbran samo en mesec
        if (porabaMesecTab.hasClass("active")) {
            datumOd = this._preberiZacetniDatum(true);
            datumDo = new Date(datumOd.getFullYear(), datumOd.getMonth(), datumOd.getDate());
            datumDo = new Date(datumDo.setMonth(datumDo.getMonth() + 1));
            datumDo = new Date(datumDo.setDate(datumDo.getDate() - 1));
        }
        // izbrano celo obdobje
        else {
            datumOd = this._preberiZacetniDatum(false);
            datumDo = this._preberiKoncniDatum();
        }

        // Fakturirana poraba
        var data: FakturiranaPorabaRequest = {
            odjemnoMesto: merilnaMesta,
            datumOd: datumOd,
            datumDo: datumDo,
            agregirano: ($("#radioSumarno")[0] as HTMLInputElement).checked
        };
        if (jeElektrika) {
            await this._merilnoMestoApiClient.fakturiranaPoraba(data, true)
                .then((odgovor: FakturiranaPorabaElektrikaResponse) => {
                    this._fakturiranaPorabaElektrika = odgovor;
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                });
        }
        else {
            await this._merilnoMestoApiClient.fakturiranaPoraba(data, false)
                .then((odgovor: FakturiranaPorabaPlinResponse) => {
                    this._fakturiranaPorabaPlin = odgovor;
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                });
        }

        // Poklicemo metodo za download datoteke
        var downloadingNotyf = this._notyf.open({
            type: "info",
            message: this._translationService.currentTranslations["Downloading"],
            duration: 50000
        });
        if (jeElektrika) {
            if (($("#radioSumarno")[0] as HTMLInputElement).checked) {
                // Elektrika sumarno
                await this._merilnoMestoApiClient.fakturiranaPorabaDownload(this._fakturiranaPorabaElektrika, true, true)                
                    .then((response) => {
                        const downloadUrl = response.downloadUrl;

                        const fileLink = document.createElement('a');
                        fileLink.href = downloadUrl;
                        fileLink.click();
                    })                   
                    .catch(() => {
                        this._notyf.error(this._translationService.currentTranslations["ErrorWhileDownloadingFile"]);
                    }).finally(() => {
                        this._notyf.dismiss(downloadingNotyf);
                    });
            }
            else {
                // Elektrika loceno
                await this._merilnoMestoApiClient.fakturiranaPorabaDownload(this._fakturiranaPorabaElektrika, true, false)
                    .then((response) => {
                        const downloadUrl = response.downloadUrl;

                        const fileLink = document.createElement('a');
                        fileLink.href = downloadUrl;
                        fileLink.click();
                    })
                    .catch(() => {
                        this._notyf.error(this._translationService.currentTranslations["ErrorWhileDownloadingFile"]);
                    }).finally(() => {
                        this._notyf.dismiss(downloadingNotyf);
                    });
            }
        }
        else {
            if (($("#radioSumarno")[0] as HTMLInputElement).checked) { 
                // Plin loceno
                await this._merilnoMestoApiClient.fakturiranaPorabaDownload(this._fakturiranaPorabaPlin, false, true)
                    .then((response) => {
                        const downloadUrl = response.downloadUrl;

                        const fileLink = document.createElement('a');
                        fileLink.href = downloadUrl;
                        fileLink.click();
                    })
                    .catch(() => {
                        this._notyf.error(this._translationService.currentTranslations["ErrorWhileDownloadingFile"]);
                    }).finally(() => {
                        this._notyf.dismiss(downloadingNotyf);
                    });
            }
            else {
                // Plin sumarno
                await this._merilnoMestoApiClient.fakturiranaPorabaDownload(this._fakturiranaPorabaPlin, false, false)
                    .then((response) => {
                        const downloadUrl = response.downloadUrl;

                        const fileLink = document.createElement('a');
                        fileLink.href = downloadUrl;
                        fileLink.click();
                    })
                    .catch(() => {
                        this._notyf.error(this._translationService.currentTranslations["ErrorWhileDownloadingFile"]);
                    }).finally(() => {
                        this._notyf.dismiss(downloadingNotyf);
                    });
            }
        }
    }

    private _onPregledPorabeClick(ev: Event) {
        ev.preventDefault();
        this._loader.show();

        // Preberemo izbrana merilna mesta
        var elektrikaTab = $("#poraba-elektrika-tab");
        var plinTab = $("#poraba-plin-tab");
        var jeElektrika = true;
        var merilnaMesta = new Array<FakturiranaPorabaOdjemnoMestoRequest>();

        // elektrika
        if (elektrikaTab.hasClass("active")) {
            jeElektrika = true;
            var elektrikaCheckBoxes = $(".form-check-input-elektrika");
            for (let i = 0; i < elektrikaCheckBoxes.length; i++) {
                if ((elektrikaCheckBoxes[i] as HTMLInputElement).checked) {
                    var merilnoMesto: FakturiranaPorabaOdjemnoMestoRequest = {
                        merilnoMesto: this._merilnaMestaElektrika[i].merilnoMesto,
                        merilnaTocka: this._merilnaMestaElektrika[i].merilnaTocka,
                        stevilkaKontoPogodbe: this._merilnaMestaElektrika[i].stevilkaKontoPogodbe,
                    }
                    merilnaMesta.push(merilnoMesto);
                }
            }
        }
        // plin
        else if (plinTab.hasClass("active")) {
            jeElektrika = false;
            var plinCheckBoxes = $(".form-check-input-plin");
            for (let i = 0; i < plinCheckBoxes.length; i++) {
                if ((plinCheckBoxes[i] as HTMLInputElement).checked) {
                    var merilnoMesto: FakturiranaPorabaOdjemnoMestoRequest = {
                        merilnoMesto: this._merilnaMestaPlin[i].merilnoMesto,
                        merilnaTocka: this._merilnaMestaPlin[i].merilnaTocka,
                        stevilkaKontoPogodbe: this._merilnaMestaPlin[i].stevilkaKontoPogodbe,
                    }
                    merilnaMesta.push(merilnoMesto);
                }
            }
        }

        if (merilnaMesta.length == 0) {
            this._notyf.error(this._translationService.currentTranslations["YouMustSelectAtLeastOneMeasuringSamplingPoint"]);
            this._loader.hide();
            return;
        }

        // Preberemo datume
        var porabaMesecTab = $("#poraba-mesec-tab");
        var datumOd = new Date();
        var datumDo = new Date();
        // izbran samo en mesec
        if (porabaMesecTab.hasClass("active")) {
            datumOd = this._preberiZacetniDatum(true);
            datumDo = new Date(datumOd.getFullYear(), datumOd.getMonth(), datumOd.getDate());
            datumDo = new Date(datumDo.setMonth(datumDo.getMonth() + 1));
            datumDo = new Date(datumDo.setDate(datumDo.getDate() - 1));
        }
        // izbrano celo obdobje
        else {
            datumOd = this._preberiZacetniDatum(false);
            datumDo = this._preberiKoncniDatum();
        }

        // Fakturirana poraba
        var data: FakturiranaPorabaRequest = {
            odjemnoMesto: merilnaMesta,
            datumOd: datumOd,
            datumDo: datumDo,
            agregirano: ($("#radioSumarno")[0] as HTMLInputElement).checked
        };
        if (jeElektrika) {
            this._merilnoMestoApiClient.fakturiranaPoraba(data, true)
                .then((odgovor: FakturiranaPorabaElektrikaResponse) => {
                    this._fakturiranaPorabaElektrika = odgovor;
                    this._loadPorabaGraf(this._fakturiranaPorabaElektrika, true, ($("#radioSumarno")[0] as HTMLInputElement).checked);
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                });
        }
        else {
            this._merilnoMestoApiClient.fakturiranaPoraba(data, false)
                .then((odgovor: FakturiranaPorabaPlinResponse) => {
                    this._fakturiranaPorabaPlin = odgovor;
                    this._loadPorabaGraf(this._fakturiranaPorabaPlin, false, ($("#radioSumarno")[0] as HTMLInputElement).checked);
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                });
        }
        this._loader.hide();
    }

    private _loadPorabaGraf(fakturiranaPoraba: any, jeElektrika: boolean, jeSumarno: boolean) {
        var datume = new Array<String>();
        var vtList = new Array<Number>();
        var mtList = new Array<Number>();
        var etList = new Array<Number>();
        var m3List = new Array<Number>();
        //var firstItemList = new Array<Number | null>();

        var odjemnaMestaModel = new Array<GrafMerilnoMestoModel>();

        var enotaMere = "";
        // elektrika sumarno
        if (jeElektrika && jeSumarno) {
            if (fakturiranaPoraba.porabaSumarno) {
                var odjemnaMesta = fakturiranaPoraba.porabaSumarno;
                for (let i = 0; i < odjemnaMesta.length; i++) {
                    var datum = new Date(odjemnaMesta[i].datum);
                    var datumString = (datum.getMonth() + 1).toString() + "." + datum.getFullYear().toString();
                    datume.push(datumString);
                    vtList.push(odjemnaMesta[i].vt);
                    mtList.push(odjemnaMesta[i].mt);
                    etList.push(odjemnaMesta[i].et);
                    enotaMere = odjemnaMesta[i].enotaMere;
                }
                const html = Mustache.render(porabaGrafView, {
                    translations: this._translationService.currentTranslations
                });
                $("#poraba-graf").html(html);
                var chartOptions = {
                    chart: {
                        width: "100%",
                        height: 380,
                        type: 'bar',
                        toolbar: {
                            tools: {
                                download: false
                            }
                        }
                    },
                    series: [
                        {
                            name: "VT",
                            data: vtList
                        },
                        {
                            name: "MT",
                            data: mtList
                        },
                        {
                            name: "ET",
                            data: etList
                        }
                    ],

                    xaxis: {
                        categories: datume
                    },

                    title: {
                        alight: 'left',
                        // text: "Skupna poraba", 
                        floating: true,
                        offsetX: -10,
                        offsetY: 10,
                        style: {
                            fontFamily: 'Red Hat Display',
                            fontWeight: '400',
                            fontSize: '15px',
                            colour: 'rgb(33, 37, 41)',

                        }
                    },
                    yaxis: {
                        labels: {
                            show: true,
                            formatter: ((val: any) => { return val + " " + enotaMere })
                        }
                    },
                    legend: {
                        show: true,
                        position: 'top',
                        horizontalAlign: 'right',
                        offsetY: 10,
                        labels: {
                            useSeriesColors: false
                        }
                    },

                    fill: {
                        colors: ['#008FFB', '#00E396', '#FFB019']
                    },

                    grid: {
                        show: true,
                        borderColor: "#e8e4e4",
                        strokeDashArray: 0,
                        position: 'back',
                        yaxis: {
                            lines: {
                                show: true
                            }
                        },
                        xaxis: {
                            lines: {
                                show: true
                            }
                        },
                    },

                    dataLabels: {
                        enabled: false
                    }
                };
                //.apexcharts-legend-series.custom-marker-down .apexcharts-legend-marker
                var chart = new ApexCharts($("#poraba-graf").find("#poraba-graf-div")[0], chartOptions);
                chart.render();
                $("#poraba-graf").show();
                $("#ni-odcitkov").hide();
            }
            // Ni najden odcitek
            else {
                $("#ni-odcitkov").show();
                $("#poraba-graf").hide();
            }
        }
        // plin sumarno
        else if (!jeElektrika && jeSumarno) {
            if (fakturiranaPoraba.porabaSumarno) {
                var odjemnaMesta = fakturiranaPoraba.porabaSumarno;
                for (let i = 0; i < odjemnaMesta.length; i++) {
                    var datum = new Date(odjemnaMesta[i].datum);
                    var datumString = (datum.getMonth() + 1).toString() + "." + datum.getFullYear().toString();
                    datume.push(datumString);
                    m3List.push(odjemnaMesta[i].m3);
                    enotaMere = odjemnaMesta[i].enotaMere;
                }
                const html = Mustache.render(porabaGrafView, {
                    translations: this._translationService.currentTranslations
                });
                $("#poraba-graf").html(html);
                var chartOptions = {
                    chart: {
                        width: "100%",
                        height: 380,
                        type: 'bar',
                        toolbar: {
                            tools: {
                                download: false
                            }
                        }
                    },
                    series: [
                        {
                            name: "V3",
                            data: m3List
                        }
                    ],

                    xaxis: {
                        categories: datume
                    },

                    title: {
                        alight: 'left',
                        // text: "Skupna poraba", 
                        floating: true,
                        offsetX: -10,
                        offsetY: 10,
                        style: {
                            fontFamily: 'Red Hat Display',
                            fontWeight: '400',
                            fontSize: '15px',
                            colour: 'rgb(33, 37, 41)',

                        }
                    },
                    yaxis: {
                        labels: {
                            show: true,
                            formatter: ((val: any) => { return val + " " + enotaMere })
                        }
                    },
                    legend: {
                        show: true,
                        position: 'top',
                        horizontalAlign: 'right',
                        offsetY: 10,
                        labels: {
                            useSeriesColors: false
                        }
                    },

                    fill: {
                        colors: ['#008FFB', '#00E396', '#FFB019']
                    },

                    grid: {
                        show: true,
                        borderColor: "#e8e4e4",
                        strokeDashArray: 0,
                        position: 'back',
                        yaxis: {
                            lines: {
                                show: true
                            }
                        },
                        xaxis: {
                            lines: {
                                show: true
                            }
                        },
                    },

                    dataLabels: {
                        enabled: false
                    }
                };
                //.apexcharts-legend-series.custom-marker-down .apexcharts-legend-marker
                var chart = new ApexCharts($("#poraba-graf").find("#poraba-graf-div")[0], chartOptions);
                chart.render();
                $("#poraba-graf").show();
                $("#ni-odcitkov").hide();
            }
            // Ni najden odcitek
            else {
                $("#ni-odcitkov").show();
                $("#poraba-graf").hide();
            }
        }
        // elektrika loceno
        else if (jeElektrika && !jeSumarno) {
            if (fakturiranaPoraba.porabaOdjemnegaMesta) {
                var vsiDatumi = new Array<Date>();
                var odjemnaMesta = fakturiranaPoraba.porabaOdjemnegaMesta; 
                
                //Nastavimo datume (x os)
                for (let i = 0; i < odjemnaMesta.length; i++) {
                    if (!odjemnaMesta[i].poraba) {
                        continue;
                    }
                    for (let j = 0; j < odjemnaMesta[i].poraba.length; j++) {
                        if (odjemnaMesta[i].poraba[j]) {
                            var datum = new Date(odjemnaMesta[i].poraba[j].datum);
                            var dateExists = vsiDatumi.find(date => date.getTime() === datum.getTime());
                            if (!dateExists) {
                                vsiDatumi.push(datum);
                            }
                        }
                        
                    }
                }

                vsiDatumi.sort(function (a, b) { return a.getTime() - b.getTime() });
                for (let d = 0; d < vsiDatumi.length; d++) {
                    var datumString = (vsiDatumi[d].getMonth() + 1).toString() + "." + vsiDatumi[d].getFullYear().toString();
                    datume.push(datumString);
                }
                
                //Nastavimo podatke
                for (let i = 0; i < odjemnaMesta.length; i++) {
                    var odjemnoMesto = odjemnaMesta[i];

                    const odjemnoMestoModel: GrafMerilnoMestoModel = {
                        merilnoMesto: odjemnoMesto.merilnoMesto,
                        poraba: new Array<number>()
                    };

                    var setEmptyValue = true;

                    if (!odjemnaMesta[i].poraba) {
                        continue;
                    }

                    for (let j = 0; j < odjemnoMesto.poraba.length; j++) {

                        var poraba = odjemnoMesto.poraba[j];
                        var datum = new Date(poraba.datum);
                        if (setEmptyValue) {
                            if (datum.getTime() === vsiDatumi[0].getTime()) {
                                setEmptyValue = false;
                            }
                            else {
                                var index = vsiDatumi.map(Number).indexOf(+datum);
                                for (var x = 0; x < index; x++) {
                                    odjemnoMestoModel.poraba.push(null);
                                }
                                setEmptyValue = false;
                            }
                        }

                        odjemnoMestoModel.poraba.push(poraba.sum);
                        enotaMere = poraba.enotaMere;
                    }
                    
                    odjemnaMestaModel.push(odjemnoMestoModel);                  
                }

                
                if (odjemnaMestaModel.length < 1) {
                    $("#ni-odcitkov").show();
                    $("#poraba-graf").hide();
                    return;
                }
                
                const html = Mustache.render(porabaGrafView, {
                    translations: this._translationService.currentTranslations
                });
                $("#poraba-graf").html(html);

                var chartOptions = {
                    chart: {
                        width: "100%",
                        height: 380,
                        type: 'line',
                        toolbar: {
                            tools: {
                                download: false
                            }
                        }
                    },
                    series: [
                        {
                            name: odjemnaMestaModel[0].merilnoMesto as string,
                            data: vtList
                        }
                    ],

                    xaxis: {
                        categories: datume
                    },

                    title: {
                        alight: 'left',
                        // text: "Skupna poraba", 
                        floating: true,
                        offsetX: -10,
                        offsetY: 10,
                        style: {
                            fontFamily: 'Red Hat Display',
                            fontWeight: '400',
                            fontSize: '15px',
                            colour: 'rgb(33, 37, 41)',

                        }
                    },
                    yaxis: {
                        labels: {
                            show: true,
                            formatter: ((val: any) => { return val + " " + enotaMere })
                        }
                    },
                    legend: {
                        show: true,
                        position: 'top',
                        horizontalAlign: 'right',
                        offsetY: 10,
                        labels: {
                            useSeriesColors: false
                        }
                    },

                    fill: {
                        colors: ['#008FFB', '#00E396', '#FFB019']
                    },

                    grid: {
                        show: true,
                        borderColor: "#e8e4e4",
                        strokeDashArray: 0,
                        position: 'back',
                        yaxis: {
                            lines: {
                                show: true
                            }
                        },
                        xaxis: {
                            lines: {
                                show: true
                            }
                        },
                    },

                    dataLabels: {
                        enabled: false
                    }
                };

                chartOptions.series = [];
                for (let x = 0; x < odjemnaMestaModel.length; x++) {
                    chartOptions.series.push({
                        name: odjemnaMestaModel[x].merilnoMesto as string,
                        data: odjemnaMestaModel[x].poraba as any
                    });
                }
                
                //.apexcharts-legend-series.custom-marker-down .apexcharts-legend-marker
                var chart = new ApexCharts($("#poraba-graf").find("#poraba-graf-div")[0], chartOptions);
                chart.render();
                $("#poraba-graf").show();
                $("#ni-odcitkov").hide();
            }
            // Ni najden odcitek
            else {
                $("#ni-odcitkov").show();
                $("#poraba-graf").hide();
            }
        }
        // plin loceno
        else if (!jeElektrika && !jeSumarno) {
            if (fakturiranaPoraba.porabaOdjemnegaMesta) {
                var vsiDatumi = new Array<Date>();
                var odjemnaMesta = fakturiranaPoraba.porabaOdjemnegaMesta;

                //Nastavimo datume (x os)
                for (let i = 0; i < odjemnaMesta.length; i++) {
                    if (!odjemnaMesta[i].poraba) {
                        continue;
                    }
                    for (let j = 0; j < odjemnaMesta[i].poraba.length; j++) {
                        var datum = new Date(odjemnaMesta[i].poraba[j].datum);
                        var dateExists = vsiDatumi.find(date => date.getTime() === datum.getTime());
                        if (!dateExists) {
                            vsiDatumi.push(datum);
                        }
                    }
                }

                vsiDatumi.sort(function (a, b) { return a.getTime() - b.getTime() });
                for (let d = 0; d < vsiDatumi.length; d++) {
                    var datumString = (vsiDatumi[d].getMonth() + 1).toString() + "." + vsiDatumi[d].getFullYear().toString();
                    datume.push(datumString);
                }

                //Nastavimo podatke
                for (let i = 0; i < odjemnaMesta.length; i++) {
                    var odjemnoMesto = odjemnaMesta[i];

                    const odjemnoMestoModel: GrafMerilnoMestoModel = {
                        merilnoMesto: odjemnoMesto.merilnoMesto,
                        poraba: new Array<number>()
                    };

                    var setEmptyValue = true;

                    if (!odjemnaMesta[i].poraba) {
                        continue;
                    }

                    for (let j = 0; j < odjemnoMesto.poraba.length; j++) {

                        var poraba = odjemnoMesto.poraba[j];
                        var datum = new Date(poraba.datum);
                        if (setEmptyValue) {
                            if (datum.getTime() === vsiDatumi[0].getTime()) {
                                setEmptyValue = false;
                            }
                            else {
                                var index = vsiDatumi.map(Number).indexOf(+datum);
                                for (var x = 0; x < index; x++) {
                                    odjemnoMestoModel.poraba.push(null);
                                }
                                setEmptyValue = false;
                            }
                        }

                        odjemnoMestoModel.poraba.push(poraba.m3);
                        enotaMere = poraba.enotaMere;
                    }

                    odjemnaMestaModel.push(odjemnoMestoModel);
                }

                if (odjemnaMestaModel.length < 1) {
                    $("#ni-odcitkov").show();
                    $("#poraba-graf").hide();
                    return;
                }

                const html = Mustache.render(porabaGrafView, {
                    translations: this._translationService.currentTranslations
                });
                $("#poraba-graf").html(html);

                var chartOptions = {
                    chart: {
                        width: "100%",
                        height: 380,
                        type: 'line',
                        toolbar: {
                            tools: {
                                download: false
                            }
                        }
                    },
                    series: [
                        {
                            name: "test",
                            data: vtList
                        }
                    ],

                    xaxis: {
                        categories: datume
                    },

                    title: {
                        alight: 'left',
                        // text: "Skupna poraba", 
                        floating: true,
                        offsetX: -10,
                        offsetY: 10,
                        style: {
                            fontFamily: 'Red Hat Display',
                            fontWeight: '400',
                            fontSize: '15px',
                            colour: 'rgb(33, 37, 41)',

                        }
                    },
                    yaxis: {
                        labels: {
                            show: true,
                            formatter: ((val: any) => { return val + " " + enotaMere })
                        }
                    },
                    legend: {
                        show: true,
                        position: 'top',
                        horizontalAlign: 'right',
                        offsetY: 10,
                        labels: {
                            useSeriesColors: false
                        }
                    },

                    fill: {
                        colors: ['#008FFB', '#00E396', '#FFB019']
                    },

                    grid: {
                        show: true,
                        borderColor: "#e8e4e4",
                        strokeDashArray: 0,
                        position: 'back',
                        yaxis: {
                            lines: {
                                show: true
                            }
                        },
                        xaxis: {
                            lines: {
                                show: true
                            }
                        },
                    },

                    dataLabels: {
                        enabled: false
                    }
                };

                chartOptions.series = [];
                for (let x = 0; x < odjemnaMestaModel.length; x++) {
                    chartOptions.series.push({
                        name: odjemnaMestaModel[x].merilnoMesto as string,
                        data: odjemnaMestaModel[x].poraba as any
                    });
                }

                //.apexcharts-legend-series.custom-marker-down .apexcharts-legend-marker
                var chart = new ApexCharts($("#poraba-graf").find("#poraba-graf-div")[0], chartOptions);
                chart.render();
                $("#poraba-graf").show();
                $("#ni-odcitkov").hide();
            }
            // Ni najden odcitek
            else {
                $("#ni-odcitkov").show();
                $("#poraba-graf").hide();
            }
        }
    }
}