import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { ListResponse } from "../models/list-response";
import { AdminRequestLogModel } from "../models/admin-request-log-model";
import { AdminGetRequestLogRequest } from "../models/admin-get-request-log-request";
import { DownloadedFile } from "../models/download-file";
import { parse } from "content-disposition-header";
import { StatistikaResponse } from "../models/statistika-response";
import { GetUsersRequest } from "../models/get-users-request";
import { User } from "../models/user";
import { CurrentUserInfo } from "../models/current-user-info";
import { AdminShraniUporabniskePodatkeRequest } from "../models/admin-shrani-uporabniske-podatke-request";
import { AdminDodajUporabnikRequest } from "../models/admin-dodaj-uporabnik-request";
import { UserDto } from "../models/userDto";
import { AdminGetUserNotificationsResponse } from "../models/admin-get-user-notifications-response";
import { AdminNotificationDetailsResponse } from "../models/admin-notification-details-response";

@injectable()
export class AdminApiClient extends ApiClientBase {
    public name = "AdminApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator")  authenticator: Authenticator) {
        super(configuration, authenticator);
    }
       
    public async getRequestLogs(data: AdminGetRequestLogRequest): Promise<ListResponse<AdminRequestLogModel>>
    {
        return await this._callApi('/admin/requestLog', 'POST', JSON.stringify(data), true) as ListResponse<AdminRequestLogModel>;
    }

    public async getUsers(data: GetUsersRequest): Promise<ListResponse<User>> {
        return await this._callApi('/admin/users/list', 'POST', JSON.stringify(data), true) as ListResponse<User>;
    }

    public async getUsersDto(data: GetUsersRequest): Promise<ListResponse<UserDto>> {
        return await this._callApi('/admin/users/listDto', 'POST', JSON.stringify(data), true) as ListResponse<UserDto>;
    }

    public async getUserDetails(userId: number): Promise<CurrentUserInfo> {
        return await this._callApi('/admin/user-details/' + userId, 'GET', null, true) as CurrentUserInfo;
    }

    public async uporabniskiPodatkiShrani(userId: number, data: AdminShraniUporabniskePodatkeRequest): Promise<any> {
        return await this._callApi('/admin/user-details/' + userId + '/shrani-uporabniske-podatke', 'POST', JSON.stringify(data), true) as any;
    }

    public async getAllRoles(): Promise<any> {
        return await this._callApi('/admin/roles/list', 'GET', null, true) as any;
    }

    public async userDetailsVlogeShrani(userId: number, data: Array<string>): Promise<any> {
        return await this._callApi('/admin/user-details/' + userId + '/vloge', 'POST', JSON.stringify(data), true) as any;
    }

    public async userDetailsNastavitveDostopaShrani(userId: number, isActive: number, isEnabled: number): Promise<any> {
        var url = '/admin/user-details/' + userId + '/nastavitve-dostopa/' + isActive + '/' + isEnabled;
        return await this._callApi(url, 'POST', null, true) as any;
    }

    public async addUser(data: AdminDodajUporabnikRequest): Promise<any> {
        return await this._callApi('/admin/add-user', 'POST', JSON.stringify(data), true) as any;
    }

    public async getStatistika(): Promise<StatistikaResponse> {
        return await this._callApi('/admin/statistika', 'GET', null, true) as StatistikaResponse;
    }

    public async seznamUporabnikovDownload(): Promise<DownloadedFile> {
        const data = {};
        var file: DownloadedFile = null as any;
        var url = "/admin/uporabniki/download";
        await this._callApi(url, 'GET', data, true, "binary", (data, _status, jqueryXhr: JQueryXHR) => {
            var contentDisposition = jqueryXhr.getResponseHeader("Content-Disposition");
            var filename: string = "";
            if (contentDisposition) {
                var contentDispositionParsed = parse(contentDisposition as string);
                filename = contentDispositionParsed.parameters.filename;
            }
            var filetype = jqueryXhr.getResponseHeader("Content-Type");
            file = {
                fileName: filename,
                fileType: filetype,
                fileContent: data
            } as DownloadedFile;
        });
        return file;
    }

    public async getUserNotifications(userId: number): Promise<AdminGetUserNotificationsResponse> {
        return await this._callApi('/admin/getUserNotifications/' + userId, 'GET', null, true) as AdminGetUserNotificationsResponse;
    }

    public async getNotification(notificationId: string): Promise<AdminNotificationDetailsResponse> {
        return await this._callApi('/admin/getNotification/' + notificationId, 'GET', null, true) as AdminNotificationDetailsResponse;
    }
} 