import { inject, injectable, interfaces } from "inversify";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { MainLayout } from "../../views/main-layout";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import * as Mustache from "mustache";
import template from "../pages/merilno-mesto-odstrani-page.html";
import { TranslationService } from "../translation-service";
import { MerilnoMestoOdstraniView } from "../../views/merilno-mesto-odstrani-view";
import { MerilnoMestoApiClient } from "../clients/merilno-mesto-api-client";
import { Notyf } from "notyf";
import { FullscreenLoader } from "../../views/fullscreen-loader";


@injectable()
export class MerilnoMestoOdstraniPage extends PageBase {
    public name = "MerilnoMestoOdstraniPage";
    private _router: Router;
    private _translationService: TranslationService;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _notyf: Notyf;
    private _merilnoMestoOdstraniView: MerilnoMestoOdstraniView;
    private _merilnoMestoOdstraniElement: JQuery<HTMLElement>;
    private _loader: FullscreenLoader;

    constructor(@inject("Provider<MainLayout>") private _mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("Router") router: Router,
        @inject("TranslationService") translationService: TranslationService,
        @inject("MerilnoMestoApiClient") merilnoMestoApiClient: MerilnoMestoApiClient,
        @inject("Notyf") notyf: Notyf,
        @inject("FullscreenLoader") loader: FullscreenLoader) {
        super();

        this._router = router;
        this._translationService = translationService;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._notyf = notyf;
        this._loader = loader;

        const html = Mustache.render(template, {});
        $('#main').html(html);

        this._merilnoMestoOdstraniElement = $('#main').find("#merilno-mesto-odstrani");
        this._merilnoMestoOdstraniView = new MerilnoMestoOdstraniView(this._merilnoMestoOdstraniElement,
            this._translationService, this._merilnoMestoApiClient, this._notyf, this._loader);
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        if (!url.pathname.startsWith("/merilno-mesto/odstrani")) {
            return true;
        }
        var mainLayout = await this._mainLayoutProvider() as MainLayout;
        try {
            mainLayout.hideFooter();
            this._loader.show();
            await this._merilnoMestoOdstraniView.load();
            window.scrollTo(0, 0);
            $(".title-strani")[0].innerHTML = this._translationService.currentTranslations["RemovalOfMeasuringSamplingPoints"];
        } catch (e) {
            mainLayout.errorView.report(e);
        }
        finally {
            this._loader.hide();
            mainLayout.showFooter();
        }
        return false;
    }
}