import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { DownloadedFile } from "../models/download-file";
import { ListResponse } from "../models/list-response";
import { MojPlusPodatkiVclanitevRequest } from "../models/moj-plus-podatki-vclanitev-request";
import { MojPlusUgodnostiRequest } from "../models/moj-plus-ugodnosti-request";
import { MojPlusUporabnikVclanitevRequest } from "../models/moj-plus-uporabnik-vclanitev-request";
import { MojPlusVclanitevRequest } from "../models/moj-plus-vclanitev-request";
import { OdjavaMojPlus } from "../models/odjava-moj-plus";
import { PodatkiMojPlusResponse } from "../models/podatki-moj-plus-response";
import { PovabitePrijateljaRequest } from "../models/povabite-prijatelja-request";
import { UnovcitevMojPlusUgodnostiRequest } from "../models/unovcitev-moj-plus-ugodnosti-request";
import { parse } from "content-disposition-header";
import { ZbiranjeTockAkcijeResponse } from "../models/zbiranje-tock-akcije-response";

@injectable()
export class MojPlusApiClient extends ApiClientBase {
    public name = "MojPlusApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator") authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async getMojPlusPodatki(stevilkaKontoPogodbe: string): Promise<any> {
        const data = {
            "stevilkaKontoPogodbe": stevilkaKontoPogodbe
        };
        return await this._callApi('/mojplus/podatki', 'GET', data, false) as any;
    }

    public async getMojPlusPodatkiById(userId: number): Promise<ListResponse<PodatkiMojPlusResponse>> {
        const data = {
            "userId": userId
        };
        return await this._callApi('/mojplus/podatkiByUserId', 'GET', data, false) as any;
    }

    public async povabitePrijatelja(data: PovabitePrijateljaRequest): Promise<any> {
        return await this._callApi('/mojplus/povabite-prijatelja', 'POST', JSON.stringify(data), false) as any;
    }

    public async getUgodnosti(data: MojPlusUgodnostiRequest): Promise<any> {
        return await this._callApi('/mojplus/ugodnosti', 'POST', JSON.stringify(data), false) as any;
    }

    public async vclanitevMojPlus(data: MojPlusVclanitevRequest): Promise<any> {
        return await this._callApi('/mojplus/vclanitev', 'POST', JSON.stringify(data), false) as any;
    }

    public async podatkiVclanitev(data: MojPlusPodatkiVclanitevRequest): Promise<any> {
        return await this._callApi('/mojplus/podatki-vclanitev', 'POST', JSON.stringify(data), false) as any;
    }

    public async uporabnikVclanitev(data: MojPlusUporabnikVclanitevRequest, userId: number): Promise<any> {
        return await this._callApi('/mojplus/uporabnik-vclanitev/' + userId, 'POST', JSON.stringify(data), false) as any;
    }

    public async unovcitevUgodnosti(data: UnovcitevMojPlusUgodnostiRequest): Promise<any> {
        return await this._callApi('/mojplus/unovcitev-ugodnosti', 'POST', JSON.stringify(data), false) as any;
    }

    public async odjavaIzLojalnostnegaPrograma(data: OdjavaMojPlus, uporabnikId: number): Promise<any> {
        return await this._callApi('/mojplus/odjava/' + uporabnikId, 'POST', JSON.stringify(data), false) as any;
    }

    public async izpisMojPlusNagrade(stevilkaZahtevka: number): Promise<any> {
        var file: DownloadedFile = null as any;
        var url = "/mojplus/izpis-nagrade/" + stevilkaZahtevka + "/download";
        await this._callApi(url, 'GET', null, true, "binary", (data, _status, jqueryXhr: JQueryXHR) => {
            var contentDisposition = jqueryXhr.getResponseHeader("Content-Disposition");
            var filename: string = "";
            if (contentDisposition) {
                var contentDispositionParsed = parse(contentDisposition as string);
                filename = contentDispositionParsed.parameters.filename;
            }
            var filetype = jqueryXhr.getResponseHeader("Content-Type");
            file = {
                fileName: filename,
                fileType: filetype,
                fileContent: data
            } as DownloadedFile;
        });
        return file;
    }

    public async getAkcijeZaZbiranjeTock(page?: number, take?: number): Promise<ZbiranjeTockAkcijeResponse> {
        if (page === undefined || page === undefined) {
            return await this._callApi('/mojplus/akcije-zbiranje-tock', 'GET', null, false) as ZbiranjeTockAkcijeResponse;
        }
        return await this._callApi('/mojplus/akcije-zbiranje-tock/' + page + '/' + take, 'GET', null, false) as ZbiranjeTockAkcijeResponse;
    }
} 
