import * as DateRangePicker from 'daterangepicker';
import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { KontaktiInPomocApiClient } from "../ts/clients/kontakti-in-pomoc-api-client";
import { TranslationService } from "../ts/translation-service";
import { FullscreenLoader } from "./fullscreen-loader";
import { RezervirajSestanekRequest } from '../ts/models/rezerviraj-sestanek-request';
import template from "./kontakti-prodajna-mesta-view.html";
import "jquery-validation-unobtrusive";
import { GetProdajnoMestoKoledarRequest } from '../ts/models/get-prodajno-mesto-koledar-request';
import { GetProdajnoMestoKoledarDay, GetProdajnoMestoKoledarTerminData } from '../ts/models/get-prodajno-mesto-koledar-day';
import { ProdajnoMestoUmbracoResponse } from '../ts/models/prodajno-mesto-umbraco-response';
import { MerilnoMestoApiClient } from '../ts/clients/merilno-mesto-api-client';
import { MerilnoMesto } from '../ts/models/merilno-mesto';
import { UporabnikMerilnoMestoResponse } from '../ts/models/uporabnik-merilno-mesto-response';
import { UporabnikMerilnoMestoRequest } from '../ts/models/uporabnik-merilno-mesto-request';

@injectable()
export class KontaktiProdajnaMestaView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _kontaktiInPomocApiClient: KontaktiInPomocApiClient;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _datumObiska: DateRangePicker = null!;
    private _datumKlica: DateRangePicker = null!;
    private _formObisk: JQuery<HTMLElement> | null;
    private _formKlic: JQuery<HTMLElement> | null;
    private _koledarObiskDni: Array<GetProdajnoMestoKoledarDay>;
    private _termini: Array<GetProdajnoMestoKoledarTerminData>;
    private _zasedaniDniObisk: Array<Date>;
    private _prviProstiDanObisk: Date | undefined;
    private _zasedaniDniKlic: Array<Date>;
    private _prviProstiDanKlic: Date | undefined;
    private _prodajnaMestaUmbraco: Array<ProdajnoMestoUmbracoResponse>;
    private _izbranoProdajnoMestoUmbraco: ProdajnoMestoUmbracoResponse | null | undefined;
    private _merilnaMesta: Array<MerilnoMesto> | null;
    private _izbranoMerilnoMestoObisk: MerilnoMesto | null | undefined;
    private _izbranoMerilnoMestoKlic: MerilnoMesto | null | undefined;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        kontaktiInPomocApiClient: KontaktiInPomocApiClient,
        merilnoMestoApiClient: MerilnoMestoApiClient,
        private _notyf: Notyf,
        private _loader: FullscreenLoader)
    {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._kontaktiInPomocApiClient = kontaktiInPomocApiClient;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._formObisk = null;
        this._formKlic = null;
        this._koledarObiskDni = new Array<GetProdajnoMestoKoledarDay>();
        this._termini = new Array<GetProdajnoMestoKoledarTerminData>();
        this._zasedaniDniObisk = new Array<Date>();
        this._zasedaniDniKlic = new Array<Date>();
        this._prodajnaMestaUmbraco = new Array<ProdajnoMestoUmbracoResponse>();
        this._izbranoProdajnoMestoUmbraco = null;
        this._merilnaMesta = null;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            await this._getProdajnaMesta();
            await this._getMerilnaMesta();
            await this._renderData();
            if ($("#page-loaded")[0] == undefined) return;
            await this._getIzbranoProdajnoMesto();
            this._setProdajnoMestoData();
            await this._getCalendarData();
            await this._initForm();
            await this._setTerminiDropdown();
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(): void {
        var prodajnoMestoId1 = this._prodajnaMestaUmbraco[0].Id;
        var prodajnoMestoId2 = this._prodajnaMestaUmbraco[1].Id;
        var prodajnoMestoId3 = this._prodajnaMestaUmbraco[2].Id;
        var prodajnoMestoId4 = this._prodajnaMestaUmbraco[3].Id;
        var prodajnoMestoId5 = this._prodajnaMestaUmbraco[4].Id;
        var prodajnoMestoId6 = this._prodajnaMestaUmbraco[5].Id;
        const viewModel = {
            translations: this._translationService.currentTranslations,
            prodajnoMestoId1: prodajnoMestoId1,
            prodajnoMestoId2: prodajnoMestoId2,
            prodajnoMestoId3: prodajnoMestoId3,
            prodajnoMestoId4: prodajnoMestoId4,
            prodajnoMestoId5: prodajnoMestoId5,
            prodajnoMestoId6: prodajnoMestoId6,
            merilnaMesta: this._merilnaMesta
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _getIzbranoProdajnoMesto() {
        var url_string = window.location.href.split("/");
        var prodajnoMestoUmbracoId = Number(url_string[url_string.length - 1]);
        this._izbranoProdajnoMestoUmbraco = this._prodajnaMestaUmbraco.find(x => x.Id == prodajnoMestoUmbracoId);
        if (!this._izbranoProdajnoMestoUmbraco) {
            this._izbranoProdajnoMestoUmbraco = this._prodajnaMestaUmbraco[0];
        }
        window.history.pushState(null, "", "#/kontakti/prodajna-mesta");
    }

    private async _getProdajnaMesta() {
        await this._kontaktiInPomocApiClient.getProdajnaMestaUmbraco()
            .then((response: Array<ProdajnoMestoUmbracoResponse>) => {
                this._prodajnaMestaUmbraco = response;
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
    }

    private async _getMerilnaMesta() {
        var uporabnikMerilnoMestoRequest: UporabnikMerilnoMestoRequest = {
            limit: 0,
            stevilkaStrani: 0,
            saldo: false,
            skupnaPorabaEnergije: false,
            merilnoMesto: "",
            merilnaTocka: ""
        };
        await this._merilnoMestoApiClient.getUporabnikMerilnaMesta(uporabnikMerilnoMestoRequest)
            .then((odgovor: UporabnikMerilnoMestoResponse) => {
                this._merilnaMesta = odgovor.merilnaMesta;
                if (this._merilnaMesta && this._merilnaMesta.length > 0) {
                    this._izbranoMerilnoMestoObisk = this._merilnaMesta[0];
                    this._izbranoMerilnoMestoKlic = this._merilnaMesta[0];
                }
                else {
                    this.rootElement.text(this._translationService.currentTranslations["YouDoNotHaveAnyMeasuringPoint"]);
                    return;
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSearchingForMeasuringPoints"]);
            });
    }

    private async _getCalendarData() {
        if (!this._izbranoProdajnoMestoUmbraco) return;
        this._koledarObiskDni = new Array<GetProdajnoMestoKoledarDay>();
        this._zasedaniDniObisk = new Array<Date>();
        this._zasedaniDniKlic = new Array<Date>();
        var now = new Date();
        var after2months = new Date();
        after2months.setMonth(now.getMonth() + 2);
        var data: GetProdajnoMestoKoledarRequest = {
            prodajnoMestoUmbracoId: this._izbranoProdajnoMestoUmbraco.Id,
            datumOd: now.getFullYear() + "-" + (now.getMonth() + 1).toString() + "-" + now.getDate(),
            datumDo: after2months.getFullYear() + "-" + (after2months.getMonth() + 1).toString() + "-" + after2months.getDate()
        };
        await this._kontaktiInPomocApiClient.getProdajnaMestaKoledar(data)
            .then((response: Array<GetProdajnoMestoKoledarDay>) => {
                this._koledarObiskDni = response;
                var zasedaniDni = response.filter(x => x.prost == false);
                if (zasedaniDni.length > 0) {
                    for (let i = 0; i < zasedaniDni.length; i++) {
                        var datum = new Date(zasedaniDni[i].datum);
                        this._zasedaniDniObisk.push(datum);
                    }
                }
                this._prviProstiDanObisk = response.find(x => x.prost == true)?.datum;
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });

        await this._kontaktiInPomocApiClient.getProdajnaMestaKlicKoledar(data)
            .then((response: Array<GetProdajnoMestoKoledarDay>) => {
                var zasedaniDni = response.filter(x => x.prost == false);
                if (zasedaniDni.length > 0) {
                    for (let i = 0; i < zasedaniDni.length; i++) {
                        var datum = new Date(zasedaniDni[i].datum);
                        this._zasedaniDniKlic.push(datum);
                    }
                }
                this._prviProstiDanKlic = response.find(x => x.prost == true)?.datum;
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });

        // Date pickers
        await this._setDatePickers();
    }

    private async _setDatePickers() {
        var self = this;

        if (this._prviProstiDanObisk == undefined) {
            return;
        }
        var startDateObisk = new Date(this._prviProstiDanObisk);
        var endDateObisk = new Date;
        endDateObisk.setMonth(endDateObisk.getMonth() + 2);

        this._datumObiska = new DateRangePicker($('#main').find("#datum-obiska")[0], {
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            startDate: startDateObisk,
            minDate: startDateObisk,
            maxDate: endDateObisk,
            locale: {
                format: 'DD. MM. YYYY'
            },
            isInvalidDate: function (date) {
                var datum = new Date(date.toString());
                for (let i = 0; i < self._zasedaniDniObisk.length; i++) {
                    if (self._zasedaniDniObisk[i].getFullYear() == datum.getFullYear() && self._zasedaniDniObisk[i].getMonth() == datum.getMonth() && self._zasedaniDniObisk[i].getDate() == datum.getDate()) {
                        return true;
                    }
                }
                return false;
            }
        });
        $("#datum-obiska").on("change", async () => {
            await this._setTerminiDropdown();
        });

        if (this._prviProstiDanKlic == undefined) {
            return;
        }
        var startDateKlic = new Date(this._prviProstiDanKlic);
        var endDateKlic = new Date;
        endDateKlic.setMonth(endDateKlic.getMonth() + 2);
        this._datumKlica = new DateRangePicker($('#main').find("#datum-klica")[0], {
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            startDate: startDateKlic,
            minDate: startDateKlic,
            maxDate: endDateKlic,
            locale: {
                format: 'DD. MM. YYYY'
            },
            isInvalidDate: function (date) {
                var datum = new Date(date.toString());
                for (let i = 0; i < self._zasedaniDniKlic.length; i++) {
                    if (self._zasedaniDniKlic[i].getFullYear() == datum.getFullYear() && self._zasedaniDniKlic[i].getMonth() == datum.getMonth() && self._zasedaniDniKlic[i].getDate() == datum.getDate()) {
                        return true;
                    }
                }
                return false;
            }
        });
    }

    private async _initForm(): Promise<void> {
        this._dodajValidacijskeFunkcije();

        // Forms
        this._formObisk = $("#form-obisk");
        $.validator.unobtrusive.parse(this._formObisk);
        this._formObisk.validate();
        this._formKlic = $("#form-klic");
        $.validator.unobtrusive.parse(this._formKlic);
        this._formKlic.validate();

        // Buttons
        $("#btn-narocilo-obisk-ali-klic")[0].addEventListener("click", () => {
            $("#prodajno-mesto-informacije").hide();
            $("#prodajno-mesto-tip-sestanka").show();
        });
        $("#btn-preklici-sestanka-obisk")[0].addEventListener("click", () => {
            $("#prodajno-mesto-informacije").show();
            $("#prodajno-mesto-tip-sestanka").hide();
        });
        $("#btn-preklici-sestanka-klic")[0].addEventListener("click", () => {
            $("#prodajno-mesto-informacije").show();
            $("#prodajno-mesto-tip-sestanka").hide();
        });
        $("#btn-oddajte-narocilo-obisk")[0].addEventListener("click", (ev: Event) => this._onBtnOddajteNarociloFizicniObiskClick(ev, this._formObisk));
        $("#btn-oddajte-narocilo-klic")[0].addEventListener("click", (ev: Event) => this._onBtnOddajteNarociloKlicClick(ev, this._formKlic));

        // Dropdowns
        var merilnaMestaObiskDropdown = $("#dropdown-merilno-mesto-obisk");
        merilnaMestaObiskDropdown.on("change", () => {
            var dropdownMerilnoMestoValues = (merilnaMestaObiskDropdown[0] as any).value.split("_");
            var selectedJeElektrika = dropdownMerilnoMestoValues[0];
            var selectedStevilkaKontoPogodbe = dropdownMerilnoMestoValues[1];
            var selectedMerilnoMesto = dropdownMerilnoMestoValues[2];
            if (selectedJeElektrika == "true") {
                var selectedMerilnaTocka = dropdownMerilnoMestoValues[3];
                this._izbranoMerilnoMestoObisk = this._merilnaMesta?.find(
                    a => a.merilnoMesto == selectedMerilnoMesto && a.merilnaTocka == selectedMerilnaTocka && a.stevilkaKontoPogodbe == selectedStevilkaKontoPogodbe);
            }
            else {
                this._izbranoMerilnoMestoObisk = this._merilnaMesta?.find(
                    a => a.merilnoMesto == selectedMerilnoMesto && a.stevilkaKontoPogodbe == selectedStevilkaKontoPogodbe);
            }
        });
        var merilnaMestaKlicDropdown = $("#dropdown-merilno-mesto-klic");
        merilnaMestaKlicDropdown.on("change", () => {
            var dropdownMerilnoMestoValues = (merilnaMestaKlicDropdown[0] as any).value.split("_");
            var selectedJeElektrika = dropdownMerilnoMestoValues[0];
            var selectedStevilkaKontoPogodbe = dropdownMerilnoMestoValues[1];
            var selectedMerilnoMesto = dropdownMerilnoMestoValues[2];
            if (selectedJeElektrika == "true") {
                var selectedMerilnaTocka = dropdownMerilnoMestoValues[3];
                this._izbranoMerilnoMestoKlic = this._merilnaMesta?.find(
                    a => a.merilnoMesto == selectedMerilnoMesto && a.merilnaTocka == selectedMerilnaTocka && a.stevilkaKontoPogodbe == selectedStevilkaKontoPogodbe);
            }
            else {
                this._izbranoMerilnoMestoKlic = this._merilnaMesta?.find(
                    a => a.merilnoMesto == selectedMerilnoMesto && a.stevilkaKontoPogodbe == selectedStevilkaKontoPogodbe);
            }
        });

        var namenObiskaDropdown = $("#dropdown-namen-obiska");
        namenObiskaDropdown.on("change", () => {
            if (namenObiskaDropdown.val() == "Drugo") {
                $("#namen-obiska-drugo-data").show();
            }
            else {
                $("#namen-obiska-drugo-data").hide();
            }
        });
        var namenKlicaDropdown = $("#dropdown-namen-klica");
        namenKlicaDropdown.on("change", () => {
            if (namenKlicaDropdown.val() == "Drugo") {
                $("#namen-klica-drugo-data").show();
            }
            else {
                $("#namen-klica-drugo-data").hide();
            }
        });

        // Tabs
        var tabs = $(".nav-link-prodajno-mesto");
        for (let i = 0; i < tabs.length; i++) {
            tabs[i].addEventListener("click", async () => {
                var id = tabs[i].dataset["prodajnomesto"];
                window.location.href = "#/kontakti/prodajna-mesta/" + id;
            });
        }
    } 

    private async _onBtnOddajteNarociloFizicniObiskClick(ev: Event, formObisk: any) {
        ev.preventDefault();
        if (!this._izbranoMerilnoMestoObisk) return;
        if (!this._izbranoProdajnoMestoUmbraco) return;
        if (!formObisk.valid()) {
            this._notyf.error(this._translationService.currentTranslations.CheckAllFields);
            return null;
        }
        this._loader.show();

        var casTerminaIndex = Number($("#dropdown-termini").val());
        if (isNaN(casTerminaIndex)) {
            this._notyf.error(this._translationService.currentTranslations["CheckAllFields"]);
            return;
        }
        var izbranTermin = this._termini[casTerminaIndex];
        var namenObiska = "";
        var namenObiskaDropdown = $("#dropdown-namen-obiska");
        if (namenObiskaDropdown.val() == "Drugo") {
            namenObiska = ($("#namen-obiska-drugo")[0] as HTMLInputElement).value;
        }
        else {
            namenObiska = namenObiskaDropdown.val() as string;
        }
        var rezervirajSestanekRequest: RezervirajSestanekRequest = {
            prodajnoMesto: this._izbranoProdajnoMestoUmbraco.ImeProdajnegaMesta,
            merilnoMesto: this._izbranoMerilnoMestoObisk.merilnoMesto,
            merilnaTocka: this._izbranoMerilnoMestoObisk.jeElektrika ? this._izbranoMerilnoMestoObisk.merilnaTocka : "",
            stevilkaKontoPogodbe: this._izbranoMerilnoMestoObisk.stevilkaKontoPogodbe,
            naslovMerilnegaMesta: this._izbranoMerilnoMestoObisk.naslovMesta,
            datum: izbranTermin.datumInCas,
            fizicniObisk: true,
            email: ($("#email")[0] as HTMLInputElement).value,
            naslov: $("#naslov")[0].innerHTML,
            namen: namenObiska
        };

        await this._kontaktiInPomocApiClient.rezervirajSestanek(rezervirajSestanekRequest)
            .then((odgovor: any) => {
                this._loader.hide();
                if (odgovor.status) {
                    this._notyf.success(this._translationService.currentTranslations["YourPhysicalVisitOrderHasBeenSuccessfullySent"]);
                    this._setTerminiDropdown();
                }
                else {
                    this._notyf.error(odgovor.sporocilo);
                }
            })
            .catch(() => {
                this._loader.hide();
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
    }

    private async _onBtnOddajteNarociloKlicClick(ev: Event, formKlic: any) {
        ev.preventDefault();
        if (!this._izbranoProdajnoMestoUmbraco) return;
        if (!this._izbranoMerilnoMestoKlic) return;
        if (!formKlic.valid()) {
            this._notyf.error(this._translationService.currentTranslations.CheckAllFields);
            return null;
        }
        this._loader.show();
        var termin = new Date(this._datumKlica.startDate.year(), this._datumKlica.startDate.month(), this._datumKlica.startDate.date(), 0, 0, 0);
        var namenKlica = "";
        var namenKlicaDropdown = $("#dropdown-namen-klica");
        if (namenKlicaDropdown.val() == "Drugo") {
            namenKlica = ($("#namen-klica-drugo")[0] as HTMLInputElement).value;
        }
        else {
            namenKlica = namenKlicaDropdown.val() as string;
        }
        var rezervirajSestanekRequest: RezervirajSestanekRequest = {
            prodajnoMesto: this._izbranoProdajnoMestoUmbraco.ImeProdajnegaMesta,
            merilnoMesto: this._izbranoMerilnoMestoKlic.merilnoMesto,
            merilnaTocka: this._izbranoMerilnoMestoKlic.jeElektrika ? this._izbranoMerilnoMestoKlic.merilnaTocka : "",
            stevilkaKontoPogodbe: this._izbranoMerilnoMestoKlic.stevilkaKontoPogodbe,
            naslovMerilnegaMesta: this._izbranoMerilnoMestoKlic.naslovMesta,
            datum: termin,
            fizicniObisk: false,
            telefon: ($("#telefon")[0] as HTMLInputElement).value,
            namen: namenKlica,
            naslov: $("#naslov")[0].innerHTML
        };
        await this._kontaktiInPomocApiClient.rezervirajSestanek(rezervirajSestanekRequest)
            .then((odgovor) => {
                this._loader.hide();
                if (odgovor.status) {
                    this._notyf.success(this._translationService.currentTranslations["YourCallOrderHasBeenSuccessfullySent"]);
                }
                else {
                    this._notyf.error(odgovor.sporocilo);
                }
            })
            .catch(() => {
                this._loader.hide();
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
    }

    private async _setTerminiDropdown() {
        this._termini = new Array<GetProdajnoMestoKoledarTerminData>();
        var dropdownTermini = $("#dropdown-termini");
        var selectedDate = this._datumObiska.startDate;
        var startDate = new Date(selectedDate.year(), selectedDate.month(), selectedDate.date(), 0, 0, 0);
        var dan = this._koledarObiskDni.find(x =>
            new Date(x.datum).getFullYear() == startDate.getFullYear() &&
            new Date(x.datum).getMonth() == startDate.getMonth() &&
            new Date(x.datum).getDate() == startDate.getDate());
        if (!dan) {
            return;
        }
        var terminiData = dan.terminiData;
        this._termini = terminiData.filter(a => a.prost == true && a.datumVPreteklosti == false);
        dropdownTermini.empty();
        var text = this._translationService.currentTranslations["SelectTime"];
        dropdownTermini.append("<option value=''>" + text + "</option>");
        if (this._termini.length > 0) {
            for (let i = 0; i < this._termini.length; i++) {
                var date = new Date(this._termini[i].datumInCas);
                var hours = date.getHours();
                var minutes = date.getMinutes();
                text = hours + ":" + (minutes < 10 ? "0" + minutes : minutes);
                dropdownTermini.append("<option value='" + i + "'>" + text + "</option>");
            }
        }
    }

    private _dodajValidacijskeFunkcije(): void {
        $.validator.addMethod("emailIsValid", function (email) {
            if (email.match(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-||_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/)) {
                return true;
            }
            return false;
        });
    }

    private _setProdajnoMestoData() {
        if (!this._izbranoProdajnoMestoUmbraco) return;

        // Monday
        if (this._izbranoProdajnoMestoUmbraco.PonedeljekOd != "" && this._izbranoProdajnoMestoUmbraco.PonedeljekDo != "") {
            var zacetek = this._izbranoProdajnoMestoUmbraco.PonedeljekOd.split(" ")[1];
            var konec = this._izbranoProdajnoMestoUmbraco.PonedeljekDo.split(" ")[1];
            $("#hours-monday")[0].innerHTML = this._translationService.currentTranslations["Monday"] + ": " + zacetek + " - " + konec;
        }
        else {
            $("#hours-monday")[0].innerHTML = this._translationService.currentTranslations["Monday"] + ": " + this._translationService.currentTranslations["Closed"];
        }
        // Tuesday
        if (this._izbranoProdajnoMestoUmbraco.TorekOd != "" && this._izbranoProdajnoMestoUmbraco.TorekDo != "") {
            var zacetek = this._izbranoProdajnoMestoUmbraco.TorekOd.split(" ")[1];
            var konec = this._izbranoProdajnoMestoUmbraco.TorekDo.split(" ")[1];
            $("#hours-tuesday")[0].innerHTML = this._translationService.currentTranslations["Tuesday"] + ": " + zacetek + " - " + konec;
        }
        else {
            $("#hours-tuesday")[0].innerHTML = this._translationService.currentTranslations["Tuesday"] + ": " + this._translationService.currentTranslations["Closed"];
        }
        // Wednesday
        if (this._izbranoProdajnoMestoUmbraco.SredaOd != "" && this._izbranoProdajnoMestoUmbraco.SredaDo != "") {
            var zacetek = this._izbranoProdajnoMestoUmbraco.SredaOd.split(" ")[1];
            var konec = this._izbranoProdajnoMestoUmbraco.SredaDo.split(" ")[1];
            $("#hours-wednesday")[0].innerHTML = this._translationService.currentTranslations["Wednesday"] + ": " + zacetek + " - " + konec;
        }
        else {
            $("#hours-wednesday")[0].innerHTML = this._translationService.currentTranslations["Wednesday"] + ": " + this._translationService.currentTranslations["Closed"];
        }
        // Thursday
        if (this._izbranoProdajnoMestoUmbraco.CetrtekOd != "" && this._izbranoProdajnoMestoUmbraco.CetrtekDo != "") {
            var zacetek = this._izbranoProdajnoMestoUmbraco.CetrtekOd.split(" ")[1];
            var konec = this._izbranoProdajnoMestoUmbraco.CetrtekDo.split(" ")[1];
            $("#hours-thursday")[0].innerHTML = this._translationService.currentTranslations["Thursday"] + ": " + zacetek + " - " + konec;
        }
        else {
            $("#hours-thursday")[0].innerHTML = this._translationService.currentTranslations["Thursday"] + ": " + this._translationService.currentTranslations["Closed"];
        }
        // Friday
        if (this._izbranoProdajnoMestoUmbraco.PetekOd != "" && this._izbranoProdajnoMestoUmbraco.PetekDo != "") {
            var zacetek = this._izbranoProdajnoMestoUmbraco.PetekOd.split(" ")[1];
            var konec = this._izbranoProdajnoMestoUmbraco.PetekDo.split(" ")[1];
            $("#hours-friday")[0].innerHTML = this._translationService.currentTranslations["Friday"] + ": " + zacetek + " - " + konec;
        }
        else {
            $("#hours-friday")[0].innerHTML = this._translationService.currentTranslations["Friday"] + ": " + this._translationService.currentTranslations["Closed"];
        }
        // Pause
        if (this._izbranoProdajnoMestoUmbraco.PavzaOd != "" && this._izbranoProdajnoMestoUmbraco.PavzaDo != "") {
            var zacetek = this._izbranoProdajnoMestoUmbraco.PavzaOd.split(" ")[1];
            var konec = this._izbranoProdajnoMestoUmbraco.PavzaDo.split(" ")[1];
            $("#hours-pause")[0].innerHTML = zacetek + " - " + konec + " - " + "Zaprto za kupce";
        }
        else {
            $("#hours-pause")[0].innerHTML = "";
        }
        // Weekend
        $("#hours-weekend")[0].innerHTML = this._translationService.currentTranslations["SaturdaySundayHolidaysClosed"];

        if (this._izbranoProdajnoMestoUmbraco.ImeProdajnegaMesta == "Maribor - Vetrinjska") {
            $("#prodajno-mesto-naslov")[0].innerHTML = "Prodajno mesto Maribor - Vetrinjska";
            $("#naslov")[0].innerHTML = "Vetrinjska ulica 2, 2000 Maribor";
            $("#navodila-za-pot")[0].setAttribute("href", "https://www.google.com/maps/dir//Energija+plus+d.o.o.,+Vetrinjska+ulica+2,+2000+Maribor/@46.5578556,15.6126798,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x476f77a8efe54ded:0x94070356e9081f7d!2m2!1d15.6476991!2d46.5578019");
            $("#prodajno-mesto-slika1").show();
            $("#prodajno-mesto-slika2").show();
            $("#prodajno-mesto-slika3").show();
            $("#slika1")[0].setAttribute("src", "../img/vetrinjska.jpg");
            $("#slika2")[0].setAttribute("src", "../img/_PSI1345.jpg");
            $("#slika3")[0].setAttribute("src", "../img/_PSI1439.jpg");
            $("#pm-1-tab")[0].classList.add("active");
            $("#pm-2-tab")[0].classList.remove("active");
            $("#pm-3-tab")[0].classList.remove("active");
            $("#pm-4-tab")[0].classList.remove("active");
            $("#pm-5-tab")[0].classList.remove("active");
            $("#pm-6-tab")[0].classList.remove("active");
        }
        else if (this._izbranoProdajnoMestoUmbraco.ImeProdajnegaMesta == "Maribor - Veselova") {
            $("#prodajno-mesto-naslov")[0].innerHTML = "Prodajno mesto Maribor - Veselova";
            $("#naslov")[0].innerHTML = "Veselova ulica 10, 2000 Maribor";
            $("#navodila-za-pot")[0].setAttribute("href", "https://www.google.com/maps/dir//Energija+plus+-+PE+Maribor+-+Veselova+Veselova+ulica+10+2000+Maribor/@46.5429949,15.6232061,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x476f773ede04c9b1:0xb856e84fa6f08f41!2m2!1d15.6232061!2d46.5429949");
            $("#prodajno-mesto-slika1").show();
            $("#prodajno-mesto-slika2").show();
            $("#prodajno-mesto-slika3").show();
            $("#slika1")[0].setAttribute("src", "../img/veselinova.png");
            $("#slika2")[0].setAttribute("src", "../img/veselova-dvorisce1.jpg");
            $("#slika3")[0].setAttribute("src", "../img/veselova-dvorisce2.jpg");
            $("#pm-1-tab")[0].classList.remove("active");
            $("#pm-2-tab")[0].classList.add("active");
            $("#pm-3-tab")[0].classList.remove("active");
            $("#pm-4-tab")[0].classList.remove("active");
            $("#pm-5-tab")[0].classList.remove("active");
            $("#pm-6-tab")[0].classList.remove("active");
        }
        else if (this._izbranoProdajnoMestoUmbraco.ImeProdajnegaMesta == "Murska Sobota") {
            $("#prodajno-mesto-naslov")[0].innerHTML = "Prodajno mesto Murska Sobota";
            $("#naslov")[0].innerHTML = "Lendavska ulica 31 a, 9000 Murska Sobota";
            $("#navodila-za-pot")[0].setAttribute("href", "https://www.google.com/maps/dir//Energijaplus+-+PE+Murska+Sobota+Lendavska+ulica+31a+9000+Murska+Sobota/@46.6645756,16.1691308,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x476f3eee961fdb49:0x22887d4e70b938a2");
            $("#prodajno-mesto-slika1").show();
            $("#prodajno-mesto-slika2").hide();
            $("#prodajno-mesto-slika3").hide();
            $("#slika1")[0].setAttribute("src", "../img/murska_sobota.png");
            $("#pm-1-tab")[0].classList.remove("active");
            $("#pm-2-tab")[0].classList.remove("active");
            $("#pm-3-tab")[0].classList.add("active");
            $("#pm-4-tab")[0].classList.remove("active");
            $("#pm-5-tab")[0].classList.remove("active");
            $("#pm-6-tab")[0].classList.remove("active");
        }
        else if (this._izbranoProdajnoMestoUmbraco.ImeProdajnegaMesta == "Gornja Radgona") {
            $("#prodajno-mesto-naslov")[0].innerHTML = "Prodajno mesto Gornja Radgona";
            $("#naslov")[0].innerHTML = "Lackova ulica 4, 9250 Gornja Radgona";
            $("#navodila-za-pot")[0].setAttribute("href", "https://www.google.com/maps/dir//Energija+plus+-+PE+Gornja+Radgona+Lackova+ulica+4+9250+Gornja+Radgona/@46.6813254,15.9927723,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x476f1311784e9855:0x2205573d63463f02");
            $("#prodajno-mesto-slika1").show();
            $("#prodajno-mesto-slika2").hide();
            $("#prodajno-mesto-slika3").hide();
            $("#slika1")[0].setAttribute("src", "../img/gornja_Radgona.png");
            $("#pm-1-tab")[0].classList.remove("active");
            $("#pm-2-tab")[0].classList.remove("active");
            $("#pm-3-tab")[0].classList.remove("active");
            $("#pm-4-tab")[0].classList.add("active");
            $("#pm-5-tab")[0].classList.remove("active");
            $("#pm-6-tab")[0].classList.remove("active");
        }
        else if (this._izbranoProdajnoMestoUmbraco.ImeProdajnegaMesta == "Ptuj") {
            $("#prodajno-mesto-naslov")[0].innerHTML = "Prodajno mesto Ptuj";
            $("#naslov")[0].innerHTML = "Ormoška cesta 26 a, 2250 Ptuj";
            $("#navodila-za-pot")[0].setAttribute("href", "https://www.google.com/maps/dir//Energija+plus+-+PE+Ptuj+Ormo%C5%A1ka+cesta+26a+2250+Ptuj/@46.4165361,15.8810492,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x476f671a7d6122ed:0x572345e144c5f116");
            $("#prodajno-mesto-slika1").show();
            $("#prodajno-mesto-slika2").hide();
            $("#prodajno-mesto-slika3").hide();
            $("#slika1")[0].setAttribute("src", "../img/ptuj.png");
            $("#pm-1-tab")[0].classList.remove("active");
            $("#pm-2-tab")[0].classList.remove("active");
            $("#pm-3-tab")[0].classList.remove("active");
            $("#pm-4-tab")[0].classList.remove("active");
            $("#pm-5-tab")[0].classList.add("active");
            $("#pm-6-tab")[0].classList.remove("active");
        }
        else if (this._izbranoProdajnoMestoUmbraco.ImeProdajnegaMesta == "Slovenska Bistrica") {
            $("#prodajno-mesto-naslov")[0].innerHTML = "Prodajno mesto Slovenska Bistrica";
            $("#naslov")[0].innerHTML = "Kolodvorska ulica 21 a, 2310 Slovenska Bistrica";
            $("#navodila-za-pot")[0].setAttribute("href", "https://www.google.com/maps/dir//Energija+plus+-+PE+Slovenska+Bistrica+Kolodvorska+ulica+21a+2310+Slovenska+Bistrica/@46.3924993,15.5753552,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x476f7fff1aa981b5:0x99f7406c9e638a18");
            $("#prodajno-mesto-slika1").show();
            $("#prodajno-mesto-slika2").hide();
            $("#prodajno-mesto-slika3").hide();
            $("#slika1")[0].setAttribute("src", "../img/slovenska_bistrica.png");
            $("#pm-1-tab")[0].classList.remove("active");
            $("#pm-2-tab")[0].classList.remove("active");
            $("#pm-3-tab")[0].classList.remove("active");
            $("#pm-4-tab")[0].classList.remove("active");
            $("#pm-5-tab")[0].classList.remove("active");
            $("#pm-6-tab")[0].classList.add("active");
        }
    }
}
