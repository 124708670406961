import { NavigationNode } from "../models/navigation-node";
import { Role } from "../models/role";

export class SiteMapManager {

    constructor(private rootNodes: Array<NavigationNode>) {

    }

    public getNodesForUser(permissions: number, roles: Array<Role>): Array<NavigationNode> {
        return this.getNodesForUserInternal(permissions, this.rootNodes, roles);
    }

    private getNodesForUserInternal(permissions: number, childNodes: Array<NavigationNode>, roles: Array<Role>): Array<NavigationNode> {
        var result = new Array<NavigationNode>();
        var adminRole = roles.find(a => a.role1 == "ROLE_ADMINISTRATOR");
        var klicniCenterRole = roles.find(a => a.role1 == "ROLE_KLICNI_CENTER");
        var specialUserRole = roles.find(a => a.role1 == "ROLE_MESTNA_BLAGAJNA" || a.role1 == "ROLE_UPRAVNIK" || a.role1 == "ROLE_TOPLOTNI_UPORABNIK"); 

        for (var i = 0; i < childNodes.length; i++) { 
            var currentNode = childNodes[i];
            if (currentNode.permissions && (currentNode.permissions & permissions) == 0) {
                continue; //nima dostopa
            }

            if (currentNode.onlyForAdmin && !adminRole) {
                continue; // nima dostapa ker ni admin
            }

            if (currentNode.onlyForKlicniCenter && !klicniCenterRole) {
                continue; // nima dostapa ker ni klicni center
            }

            if (currentNode.onlyForSpecialUsers && !specialUserRole) {
                continue; // nima dostopa ker ni mestna blagajna / upravnik / toplotni uporabnik
            }

            if (!currentNode.hasChildren || !currentNode.children) {
                result.push(currentNode);
                continue; //nima otrok, objekt se ne bo spreminjal
            }

            //skopiramo
            var newChildNodes = this.getNodesForUserInternal(permissions, currentNode.children, roles);
            var newNode: NavigationNode = {
                children: newChildNodes,
                class: currentNode.class,
                class2: currentNode.class2,
                code: currentNode.code,
                hasChildren: true,
                permissions: currentNode.permissions,
                title: currentNode.title,
                url: currentNode.url
            };
            result.push(newNode);
        }

        return result;

    }


}