import { inject, injectable, interfaces } from "inversify";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { MainLayout } from "../../views/main-layout";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import * as Mustache from "mustache";
import template from "../pages/vloge-vnos-podatkov-page.html";
import { TranslationService } from "../translation-service";
import { MerilnoMestoApiClient } from "../clients/merilno-mesto-api-client";
import { Notyf } from "notyf";
import { FullscreenLoader } from "../../views/fullscreen-loader";
import { CurrentUserAccessor } from "../utilities/current-user-accessor";
import { VlogeVnosPodatkovView } from "../../views/vloge-vnos-podatkov-view";

@injectable()
export class VlogeVnosPodatkovPage extends PageBase {
    public name = "VlogeVnosPodatkovPage";
    private _router: Router;
    private _translationService: TranslationService;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;  
    private _notyf: Notyf;
    private _vlogeVnosPodatkovView: VlogeVnosPodatkovView;
    private _vnosPodatkovElement: JQuery<HTMLElement>;
    private _loader: FullscreenLoader;
    private _currentUserAccessor: CurrentUserAccessor;

    constructor(@inject("Provider<MainLayout>") private _mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("Router") router: Router,
        @inject("TranslationService") translationService: TranslationService,
        @inject("MerilnoMestoApiClient") merilnoMestoApiClient: MerilnoMestoApiClient,
        @inject("Notyf") notyf: Notyf,
        @inject("FullscreenLoader") loader: FullscreenLoader,
        @inject("CurrentUserAccessor") currentUserAccessor: CurrentUserAccessor) {
        super();

        this._router = router;
        this._translationService = translationService;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._notyf = notyf;
        this._loader = loader;
        this._currentUserAccessor = currentUserAccessor;

        const html = Mustache.render(template, {});
        $('#main').html(html);

        this._vnosPodatkovElement = $('#main').find("#vloge-vnos-podatkov");
        this._vlogeVnosPodatkovView = new VlogeVnosPodatkovView(
            this._vnosPodatkovElement, this._translationService, this._merilnoMestoApiClient, this._currentUserAccessor, this._notyf, this._loader);
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        if (!url.pathname.startsWith("/vloge/vnos-podatkov")) {
            return true;
        }
        var mainLayout = await this._mainLayoutProvider() as MainLayout;
        try {
            mainLayout.hideFooter();
            this._loader.show();
            await this._vlogeVnosPodatkovView.load();
            window.scrollTo(0, 0);
            $(".title-strani")[0].innerHTML = this._translationService.currentTranslations["RolesAndForms"];
        } catch (e) {
            mainLayout.errorView.report(e);
        }
        finally {
            this._loader.hide();
            mainLayout.showFooter();
        }
        return false;
    }
}