import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { MerilnoMesto } from "../ts/models/merilno-mesto";
import { MerilnoMestoOdstrani } from "../ts/models/merilno-mesto-odstrani";
import { TranslationService } from "../ts/translation-service";
import { FullscreenLoader } from "./fullscreen-loader";
import { MerilnoMestoOdstraniPovratnaInformacijaView } from "./merilno-mesto-odstrani-povratna-informacija-view";
import template from "./merilno-mesto-odstrani-varnostni-korak-view.html";

@injectable()
export class MerilnoMestoOdstraniVarnostniKorakView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _izbranaMerilnaMesta: Array<MerilnoMesto>;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        merilnoMestoApiClient: MerilnoMestoApiClient,
        izbranaMerilnaMesta: Array<MerilnoMesto>,
        private notyf: Notyf,
        private _loader: FullscreenLoader)   
    {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._izbranaMerilnaMesta = izbranaMerilnaMesta;
    }

    /*
    * Wait for data then render it
    */
    public async load(): Promise<void> {
        try {
            var translations: any = this._translationService.currentTranslations;
            this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;
            this._initForm();
            window.scrollTo(0, 0);
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(translations: any): void {
        const viewModel = {
            merilnaMesta: this._izbranaMerilnaMesta,
            translations: translations
        } as any;
        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _initForm(): void {
        var btnOdstraniMerilnoMestoElement = $(("#btnOdstraniMerilnoMesto"));
        btnOdstraniMerilnoMestoElement.on("click", (ev: Event) => this._onBtnOdstraniMerilnoMestoClick(ev));
        var btnPrekliciElement = $(("#btnPreklici"));
        btnPrekliciElement.on("click", () => {
            location.reload();
        });
    }

    private async _onBtnOdstraniMerilnoMestoClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        this._loader.show();
        var merilnoMestoOdstraniSeznam: Array<MerilnoMestoOdstrani> = new Array<MerilnoMestoOdstrani>();
        for (let i = 0; i < this._izbranaMerilnaMesta.length; i++) {
            var merilnoMestoOdstrani: MerilnoMestoOdstrani = {
                merilnoMesto: this._izbranaMerilnaMesta[i].merilnoMesto,
                merilnaTocka: this._izbranaMerilnaMesta[i].merilnaTocka,
                aktivacijskaKoda: this._izbranaMerilnaMesta[i].aktivacijskaKoda
            };
            merilnoMestoOdstraniSeznam.push(merilnoMestoOdstrani);
        }
        await this._merilnoMestoApiClient.odstraniMerilnaMesta(merilnoMestoOdstraniSeznam)
            .then((merilnaMestaOdgovor) => {
                for (let i = 0; i < merilnaMestaOdgovor.items.length; i++) {
                    if (!merilnaMestaOdgovor.items[i].status) {
                        this._loader.hide();
                        this.notyf.error(this._translationService.currentTranslations.MeasuringPoint
                            + " " + merilnaMestaOdgovor.items[i].merilnoMesto + ". "
                            + merilnaMestaOdgovor.items[i].sporocilo);
                        return;
                    }
                }
                var view: MerilnoMestoOdstraniPovratnaInformacijaView = new MerilnoMestoOdstraniPovratnaInformacijaView(this.rootElement,
                    this._translationService);
                view.load();
                this._loader.hide();
            })
            .catch(() => {
                this.notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileRemovingMeasuringSamplingPoints"]);
                this._loader.hide();
            });
    }
}