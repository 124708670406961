import { Container, interfaces } from "inversify";
import { Application } from "../../../skupno/src/ts/application";
import { Authenticator } from "../../../skupno/src/ts/authentication/authenticator";
import { ConfigurationLoader } from "../../../skupno/src/ts/configuration/configuration-loader";
import { Router } from "../../../skupno/src/ts/routing/router";
import { MiddleWareFunction } from "../../../skupno/src/ts/routing/page-base";
import { MainLayout } from "../views/main-layout";
import { OpenIdLoginHandler } from "../../../skupno/src/ts/authentication/openid-login-handler";
import { OpenIdIframeApplication } from "../../../skupno/src/ts/authentication/openid-iframe-application";
import { HomePage } from "./pages/home-page";
import { MerilnoMestoInformacijePage } from "./pages/merilno-mesto-informacije-page";
import { MerilnoMestoPorabaInformativniIzracunPage } from "./pages/merilno-mesto-poraba-informativni-izracun-page";
import { MerilnoMestoPorabaNapravPage } from "./pages/merilno-mesto-poraba-naprav-page";
import { MerilnoMestoPorabaSestevekPorabePage } from "./pages/merilno-mesto-poraba-sestevek-porabe-page";
import { MerilnoMestoRacuniPage } from "./pages/merilno-mesto-racuni-page";
import { VlogePage } from "./pages/vloge-page";
import { MerilnoMestoVnosStanjaStevcaPage } from "./pages/merilno-mesto-vnos-stanja-stevca-page";
import { MojPlusMojeTockePage } from "./pages/moj-plus-moje-tocke-page";
import { MojPlusPomembneInformacijePage } from "./pages/moj-plus-pomembne-informacije-page";
import { MojPlusUgodnostiPage } from "./pages/moj-plus-ugodnosti-page";
import { MojPlusZbiranjeTockPage } from "./pages/moj-plus-zbiranje-tock-page";
import { MojProfilPage } from "./pages/moj-profil-page";
import { Lock } from "../../../skupno/src/ts/utils/lock";
import { TranslationService } from "./translation-service";
import { SiteMapManager } from "./utilities/sitemap-manager";
import { CurrentUserAccessor } from "./utilities/current-user-accessor";
import { Configuration } from "../../../skupno/src/ts/configuration/configuration";
import { TranslationsApiClient } from "./clients/translations-api-client";
import { UserApiClient } from "./clients/users-api-client";
import { KontaktiInPomocApiClient } from "./clients/kontakti-in-pomoc-api-client";
import { NoviceApiClient } from "./clients/novice-api-client";
import { MerilnoMestoApiClient } from "./clients/merilno-mesto-api-client";
import rootNodes from "./sitemap-data";
import { Notyf } from "notyf";
import { FullscreenLoader } from "../views/fullscreen-loader";
import "select2";
import "jquery-validation-unobtrusive";
import { MojPlusApiClient } from "./clients/moj-plus-api-client";
import { AkcijeApiClient } from "./clients/akcije-api-client";
import { IntranetApiClient } from "./clients/intranet-api-client";
import { DashboardPage } from "./pages/dashboard-page";
import { TestPage } from "./pages/test-page";
import { AkcijePage } from "./pages/akcije-page";
import { AkcijeAnketniVprasalnikPage } from "./pages/akcije-anketni-vprasalnik-page";
import { MerilnoMestoInformacijeNacinObracunaPage } from "./pages/merilno-mesto-informacije-nacin-obracuna-page";
import { MerilnoMestoSeznamPage } from "./pages/merilno-mesto-seznam-page";
import { MerilnoMestoOdstraniPage } from "./pages/merilno-mesto-odstrani-page";
import { MerilnoMestoDodajAvtorizacijskaKodaPage } from "./pages/merilno-mesto-dodaj-avtorizacijska-koda-page";
import { MerilnoMestoPorabaEnergetskaIzkaznicaPage } from "./pages/merilno-mesto-poraba-energetska-izkaznica-page";
import { MerilnoMestoMinutneMeritvePage } from "./pages/merilno-mesto-minutne-meritve-page";
import { VlogeVnosPodatkovPage } from "./pages/vloge-vnos-podatkov-page";
import { MojPlusPregledPage } from "./pages/moj-plus-pregled-page";
import { MojPlusKoriscenjeKuponaPage } from "./pages/moj-plus-koriscenje-kupona-page";
import { MojPlusPovabitePrijateljaPage } from "./pages/moj-plus-povabite-prijatelja-page";
import { MojPlusVclanitevPage } from "./pages/moj-plus-vclanitev-page";
import { KontaktiEboxPage } from "./pages/kontakti-ebox-page";
import { KontaktiPogostaVprasanjaSeznamPage } from "./pages/kontakti-pogosta-vprasanja-seznam-page";
import { KontaktiPogostaVprasanjaOdgovorPage } from "./pages/kontakti-pogosta-vprasanja-odgovor-page";
import { KontaktiProdajnaMestaPage } from "./pages/kontakti-prodajna-mesta-page";
import { MerilnoMestoPorabaPage } from "./pages/merilno-mesto-poraba-page";
import { Test2Page } from "./pages/test-2-page";
import { KontaktiPogostaVprasanjaKategorijePage } from "./pages/kontakti-pogosta-vprasanja-kategorije-page";
import { AdminDodajanjeNovicePage } from "./pages/admin-dodajanje-novice-page";
import { AdminUporabnikiPregledPage } from "./pages/admin-uporabniki-pregled-page";
import { AdminUrejanjeUporabnikaPage } from "./pages/admin-urejanje-uporabnika-page";
import { AdminDodajUporabnikPage } from "./pages/admin-dodaj-uporabnik-page";
import { MojProfilOdjavaPage } from "./pages/moj-profil-odjava-page";
import { NovicePregledPage } from "./pages/novice-pregled-page";
import { AdminZahteveInNapakePage } from "./pages/admin-zahteve-in-napake-page";
import { AdminStatistikaPage } from "./pages/admin-statistika-page";
import { PogostaVprasanjaApiClient } from "./clients/pogosta-vprasanja-api-client";
import { AdminApiClient } from "./clients/admin-api-client";
import { MerilnoMestoNarociloMinutneMeritvePage } from "./pages/merilno-mesto-narocilo-minutne-meritve-page";
import { DatotekaNaloziPage } from "./pages/datoteka-nalozi-page";
import { AdminDashboardPage } from "./pages/admin-dashboard-page";
import * as Sentry from "@sentry/browser";
import { KlicniCenterSeznamPage } from "./pages/klicni-center-seznam-page";
import { KlicniCenterPregledPage } from "./pages/klicni-center-pregled-page";
import { ZbirnikRacunovPage } from "./pages/zbirnik-racunov-page";
import { VlogeZahvalnaPage } from "./pages/vloge-zahvalna-page";
import { AkcijeUgodnostiPage } from "./pages/akcije-ugodnosti-page";
import { PiskotkiApiClient } from "./clients/piskotki-api-client";
import { PiskotkiPage } from "./pages/piskotki-page";
import { AdminNotificationDetailsPage } from "./pages/admin-notification-details-page";

document.addEventListener('DOMContentLoaded', init, false);
function init() {
    /*
     * The application entry point
     */
    if (window.top === window.self) {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/service-worker.js')
                .then((reg) => {
                    console.log('Service worker registered -->', reg);
                }, (err) => {
                    console.error('Service worker not registered -->', err);
                });
        }

        // Run the main app
        const app = new ProfilApplication();
        app.run();

    } else {

        // If our SPA is running on an iframe, handle token renewal responses
        const app = new OpenIdIframeApplication();
        app.run();
    }    
}

class ProfilApplication extends Application
{    
    public constructor() {
        super();      
    }

    protected override async configureServices(services: Container): Promise<void> {
        // https://inversify.io/
        // https://github.com/inversify/InversifyJS/blob/master/wiki/value_injection.md
        //services.bind<ApplicationConfiguration>("Katana").toConstantValue(new Katana());
        //services.bind<Katana>("Katana").toDynamicValue((context: interfaces.Context) => { return new Katana(); });
        // a dynamic value can return a promise that will resolve to the value
        //services.bind<Katana>("Katana").toDynamicValue((context: interfaces.Context) => { return Promise.resolve(new Katana()); });       

        // Download application configuration
        var configuration: Configuration = await ConfigurationLoader.download('spa.config.json?V=00001');
        services.bind<Configuration>("Configuration").toConstantValue(configuration);

        //Sentry
        if (configuration.sentryDsn) {
            Sentry.init({
                dsn: configuration.sentryDsn,
                integrations: [new Sentry.BrowserTracing()],
                tracesSampleRate: 1.0
            });
        }

        // Initialise our wrapper class around oidc-client
        var authenticator = new Authenticator(configuration.oauth);
        services.bind<Authenticator>("Authenticator").toConstantValue(authenticator);

        var translationsApiClient = new TranslationsApiClient(configuration, authenticator);
        services.bind<TranslationsApiClient>("TranslationsApiClient").toConstantValue(translationsApiClient);

        var translationService = new TranslationService(translationsApiClient);
        services.bind<TranslationService>("TranslationService").toConstantValue(translationService);

        var userApiClient = new UserApiClient(configuration, authenticator);
        services.bind<UserApiClient>("UserApiClient").toConstantValue(userApiClient);

        var kontaktiInPomocApiClient = new KontaktiInPomocApiClient(configuration, authenticator);
        services.bind<KontaktiInPomocApiClient>("KontaktiInPomocApiClient").toConstantValue(kontaktiInPomocApiClient);

        var noviceApiClient = new NoviceApiClient(configuration, authenticator);
        services.bind<NoviceApiClient>("NoviceApiClient").toConstantValue(noviceApiClient);

        var pogostaVprasanjaApiClient = new PogostaVprasanjaApiClient(configuration, authenticator);
        services.bind<PogostaVprasanjaApiClient>("PogostaVprasanjaApiClient").toConstantValue(pogostaVprasanjaApiClient);

        var merilnoMestoApiClient = new MerilnoMestoApiClient(configuration, authenticator);
        services.bind<MerilnoMestoApiClient>("MerilnoMestoApiClient").toConstantValue(merilnoMestoApiClient);

        var mojPlusApiClient = new MojPlusApiClient(configuration, authenticator);
        services.bind<MojPlusApiClient>("MojPlusApiClient").toConstantValue(mojPlusApiClient);

        var akcijeApiClient = new AkcijeApiClient(configuration, authenticator);
        services.bind<AkcijeApiClient>("AkcijeApiClient").toConstantValue(akcijeApiClient);

        var intranetApiClient = new IntranetApiClient(configuration, authenticator);
        services.bind<IntranetApiClient>("IntranetApiClient").toConstantValue(intranetApiClient);

        var adminApiClient = new AdminApiClient(configuration, authenticator);
        services.bind<AdminApiClient>("AdminApiClient").toConstantValue(adminApiClient);

        var piskotkiApiClient = new PiskotkiApiClient(configuration, authenticator);
        services.bind<PiskotkiApiClient>("PiskotkiApiClient").toConstantValue(piskotkiApiClient);

        services.bind<CurrentUserAccessor>("CurrentUserAccessor").to(CurrentUserAccessor).inSingletonScope();

        var sitemapManager: SiteMapManager = new SiteMapManager(rootNodes);
        services.bind<SiteMapManager>("SiteMapManager").toConstantValue(sitemapManager);


        services.bind<HomePage>(HomePage.name).to(HomePage).inTransientScope();
        services.bind<DashboardPage>("DashboardPage").to(DashboardPage).inTransientScope();
        services.bind<TestPage>("TestPage").to(TestPage).inTransientScope();
        services.bind<Test2Page>("Test2Page").to(Test2Page).inTransientScope();
        services.bind<AkcijePage>("AkcijePage").to(AkcijePage).inTransientScope();
        services.bind<AkcijeAnketniVprasalnikPage>("AkcijeAnketniVprasalnikPage").to(AkcijeAnketniVprasalnikPage).inTransientScope();
        services.bind<MerilnoMestoSeznamPage>("MerilnoMestoSeznamPage").to(MerilnoMestoSeznamPage).inTransientScope();
        services.bind<MerilnoMestoOdstraniPage>("MerilnoMestoOdstraniPage").to(MerilnoMestoOdstraniPage).inTransientScope();
        services.bind<MerilnoMestoDodajAvtorizacijskaKodaPage>("MerilnoMestoDodajAvtorizacijskaKodaPage").to(MerilnoMestoDodajAvtorizacijskaKodaPage).inTransientScope();
        services.bind<MerilnoMestoInformacijePage>("MerilnoMestoInformacijePage").to(MerilnoMestoInformacijePage).inTransientScope();
        services.bind<MerilnoMestoPorabaPage>("MerilnoMestoPorabaPage").to(MerilnoMestoPorabaPage).inTransientScope();
        services.bind<MerilnoMestoInformacijeNacinObracunaPage>("MerilnoMestoInformacijeNacinObracunaPage").to(MerilnoMestoInformacijeNacinObracunaPage).inTransientScope();
        services.bind<MerilnoMestoPorabaInformativniIzracunPage>("MerilnoMestoPorabaInformativniIzracunPage").to(MerilnoMestoPorabaInformativniIzracunPage).inTransientScope();
        services.bind<MerilnoMestoPorabaEnergetskaIzkaznicaPage>("MerilnoMestoPorabaEnergetskaIzkaznicaPage").to(MerilnoMestoPorabaEnergetskaIzkaznicaPage).inTransientScope();
        services.bind<MerilnoMestoMinutneMeritvePage>("MerilnoMestoMinutneMeritvePage").to(MerilnoMestoMinutneMeritvePage).inTransientScope();
        services.bind<MerilnoMestoNarociloMinutneMeritvePage>("MerilnoMestoNarociloMinutneMeritvePage").to(MerilnoMestoNarociloMinutneMeritvePage).inTransientScope();
        services.bind<MerilnoMestoPorabaNapravPage>("MerilnoMestoPorabaNapravPage").to(MerilnoMestoPorabaNapravPage).inTransientScope();
        services.bind<MerilnoMestoPorabaSestevekPorabePage>("MerilnoMestoPorabaSestevekPorabePage").to(MerilnoMestoPorabaSestevekPorabePage).inTransientScope();
        services.bind<MerilnoMestoRacuniPage>("MerilnoMestoRacuniPage").to(MerilnoMestoRacuniPage).inTransientScope();
        services.bind<VlogePage>("VlogePage").to(VlogePage).inTransientScope();
        services.bind<VlogeVnosPodatkovPage>("VlogeVnosPodatkovPage").to(VlogeVnosPodatkovPage).inTransientScope();
        services.bind<VlogeZahvalnaPage>("VlogeZahvalnaPage").to(VlogeZahvalnaPage).inTransientScope();
        services.bind<MerilnoMestoVnosStanjaStevcaPage>("MerilnoMestoVnosStanjaStevcaPage").to(MerilnoMestoVnosStanjaStevcaPage).inTransientScope();
        services.bind<MojPlusPregledPage>("MojPlusPregledPage").to(MojPlusPregledPage).inTransientScope();
        services.bind<MojPlusKoriscenjeKuponaPage>("MojPlusKoriscenjeKuponaPage").to(MojPlusKoriscenjeKuponaPage).inTransientScope();
        services.bind<MojPlusMojeTockePage>("MojPlusMojeTockePage").to(MojPlusMojeTockePage).inTransientScope();
        services.bind<MojPlusPomembneInformacijePage>("MojPlusPomembneInformacijePage").to(MojPlusPomembneInformacijePage).inTransientScope();
        services.bind<MojPlusUgodnostiPage>("MojPlusUgodnostiPage").to(MojPlusUgodnostiPage).inTransientScope();
        services.bind<MojPlusZbiranjeTockPage>("MojPlusZbiranjeTockPage").to(MojPlusZbiranjeTockPage).inTransientScope();
        services.bind<MojPlusPovabitePrijateljaPage>("MojPlusPovabitePrijateljaPage").to(MojPlusPovabitePrijateljaPage).inTransientScope();
        services.bind<MojPlusVclanitevPage>("MojPlusVclanitevPage").to(MojPlusVclanitevPage).inTransientScope();
        services.bind<MojProfilPage>("MojProfilPage").to(MojProfilPage).inTransientScope();
        services.bind<MojProfilOdjavaPage>("MojProfilOdjavaPage").to(MojProfilOdjavaPage).inTransientScope();
        services.bind<KontaktiEboxPage>("KontaktiEboxPage").to(KontaktiEboxPage).inTransientScope();
        services.bind<KontaktiProdajnaMestaPage>("KontaktiProdajnaMestaPage").to(KontaktiProdajnaMestaPage).inTransientScope();
        services.bind<KontaktiPogostaVprasanjaSeznamPage>("KontaktiPogostaVprasanjaSeznamPage").to(KontaktiPogostaVprasanjaSeznamPage).inTransientScope();
        services.bind<KontaktiPogostaVprasanjaOdgovorPage>("KontaktiPogostaVprasanjaOdgovorPage").to(KontaktiPogostaVprasanjaOdgovorPage).inTransientScope();
        services.bind<KontaktiPogostaVprasanjaKategorijePage>("KontaktiPogostaVprasanjaKategorijePage").to(KontaktiPogostaVprasanjaKategorijePage).inTransientScope();
        services.bind<AdminDodajanjeNovicePage>("AdminDodajanjeNovicePage").to(AdminDodajanjeNovicePage).inTransientScope();
        services.bind<AdminUporabnikiPregledPage>("AdminUporabnikiPregledPage").to(AdminUporabnikiPregledPage).inTransientScope();
        services.bind<AdminUrejanjeUporabnikaPage>("AdminUrejanjeUporabnikaPage").to(AdminUrejanjeUporabnikaPage).inTransientScope();
        services.bind<AdminNotificationDetailsPage>("AdminNotificationDetailsPage").to(AdminNotificationDetailsPage).inTransientScope();
        services.bind<AdminDodajUporabnikPage>("AdminDodajUporabnikPage").to(AdminDodajUporabnikPage).inTransientScope();
        services.bind<AdminZahteveInNapakePage>("AdminZahteveInNapakePage").to(AdminZahteveInNapakePage).inTransientScope();
        services.bind<AdminStatistikaPage>("AdminStatistikaPage").to(AdminStatistikaPage).inTransientScope();
        services.bind<NovicePregledPage>("NovicePregledPage").to(NovicePregledPage).inTransientScope();
        services.bind<DatotekaNaloziPage>("DatotekaNaloziPage").to(DatotekaNaloziPage).inTransientScope();
        services.bind<AdminDashboardPage>("AdminDashboardPage").to(AdminDashboardPage).inTransientScope();
        services.bind<KlicniCenterSeznamPage>("KlicniCenterSeznamPage").to(KlicniCenterSeznamPage).inTransientScope();
        services.bind<KlicniCenterPregledPage>("KlicniCenterPregledPage").to(KlicniCenterPregledPage).inTransientScope();
        services.bind<ZbirnikRacunovPage>("ZbirnikRacunovPage").to(ZbirnikRacunovPage).inTransientScope();
        services.bind<AkcijeUgodnostiPage>("AkcijeUgodnostiPage").to(AkcijeUgodnostiPage).inTransientScope();
        services.bind<PiskotkiPage>("PiskotkiPage").to(PiskotkiPage).inTransientScope();

        //configure router
        var middlewareFunctions: MiddleWareFunction[] = new Array<MiddleWareFunction>();
        middlewareFunctions.push(OpenIdLoginHandler.handleLogin);

        $.fn.select2.defaults.set("dropdownAutoWidth", true);

        //popravek, ker ni fokusa na search fieldih
        $(document).on('select2:open', (e) => {
            const selectId = e.target.id;
            $(".select2-search__field[aria-controls='select2-" + selectId + "-results']").each(function (_key, value,) {
                value.focus();
            });
        });

        // jQuery unobtrusive validation defauls

        $.validator.setDefaults({
            errorClass: "",
            validClass: "",
            highlight: function (element, _errorClass, _validClass) {
                $(element).addClass("is-invalid").removeClass("is-valid");
                if (element.id) {
                    $((element as HTMLInputElement).form!).find("[data-valmsg-for=" + element.id + "]").addClass("invalid-feedback");
                }
            },
            unhighlight: function (element, _errorClass, _validClass) {
                $(element).addClass("is-valid").removeClass("is-invalid");
                if (element.id) {
                    $((element as HTMLInputElement).form!).find("[data-valmsg-for=" + element.id + "]").removeClass("invalid-feedback");
                }
            },
        });
        var notyf = new Notyf({
            dismissible: true,
            duration: 5000,
            ripple: true,
            position: {
                x: 'center',
                y: 'bottom'
            },
            types: [
                {
                    type: 'info',
                    icon: false,
                    className: "bg-primary"
                }
            ]
        });
        services.bind<Notyf>("Notyf").toConstantValue(notyf);

        //routing
        var fullScreenLoader = new FullscreenLoader($("body"));
        services.bind<FullscreenLoader>("FullscreenLoader").toConstantValue(fullScreenLoader);

        $(window).on("router:pagechanging", (_ev) => {
            fullScreenLoader.show();
        });
        $(window).on("router:pagechanged", (_ev) => {
            fullScreenLoader.hide();
        });
        $(window).on("router:pageloadingerror", (_ev) => {
            console.log("pageloadingerror:", _ev);
        });

        try {
            await translationService.initialize();
        }
        catch (e) {
            notyf.error({
                dismissible: false,
                position: {
                    x: 'center',
                    y: 'center'
                },
                duration: 0,
                message: "Trenutno poteka vzdrževanje sistema. Prosimo poizkusite čez nekaj minut..."
            })
            throw e;
        }

        


         // region Dashboard = Homepage
        middlewareFunctions.push(async () => {
            //var virtualUrl = router.getVirtualUrl();
            //if (virtualUrl.pathname == "" || virtualUrl.pathname == "/") {
            //    var page = services.get<DashboardPage>("DashboardPage");
            //    return await page.execute();
            //}
            //return true;
            var page = services.get<DashboardPage>("DashboardPage");
            return await page.execute();
        });

        // region Testna stran
        middlewareFunctions.push(async () => {
            var page = services.get<TestPage>("TestPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<Test2Page>("Test2Page");
            return await page.execute();
        });
        // region Testna stran END

        // region Popusti in akcije
        middlewareFunctions.push(async () => {
            var page = services.get<AkcijePage>("AkcijePage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<AkcijeUgodnostiPage>("AkcijeUgodnostiPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<AkcijeAnketniVprasalnikPage>("AkcijeAnketniVprasalnikPage");
            return await page.execute();
        });
        // end region

        // region Kontakti in pomoc
        middlewareFunctions.push(async () => {
            var page = services.get<KontaktiEboxPage>("KontaktiEboxPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<KontaktiProdajnaMestaPage>("KontaktiProdajnaMestaPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<KontaktiPogostaVprasanjaSeznamPage>("KontaktiPogostaVprasanjaSeznamPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<KontaktiPogostaVprasanjaOdgovorPage>("KontaktiPogostaVprasanjaOdgovorPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<KontaktiPogostaVprasanjaKategorijePage>("KontaktiPogostaVprasanjaKategorijePage");
            return await page.execute();
        });
        // end region

        // region Merilno / odjemno mesto
        middlewareFunctions.push(async () => {
            var page = services.get<MerilnoMestoSeznamPage>("MerilnoMestoSeznamPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<MerilnoMestoOdstraniPage>("MerilnoMestoOdstraniPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<MerilnoMestoDodajAvtorizacijskaKodaPage>("MerilnoMestoDodajAvtorizacijskaKodaPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<MerilnoMestoInformacijePage>("MerilnoMestoInformacijePage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<MerilnoMestoPorabaPage>("MerilnoMestoPorabaPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<MerilnoMestoInformacijeNacinObracunaPage>("MerilnoMestoInformacijeNacinObracunaPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<MerilnoMestoPorabaInformativniIzracunPage>("MerilnoMestoPorabaInformativniIzracunPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<MerilnoMestoPorabaEnergetskaIzkaznicaPage>("MerilnoMestoPorabaEnergetskaIzkaznicaPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<MerilnoMestoMinutneMeritvePage>("MerilnoMestoMinutneMeritvePage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<MerilnoMestoNarociloMinutneMeritvePage>("MerilnoMestoNarociloMinutneMeritvePage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<MerilnoMestoPorabaNapravPage>("MerilnoMestoPorabaNapravPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<MerilnoMestoPorabaSestevekPorabePage>("MerilnoMestoPorabaSestevekPorabePage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<MerilnoMestoRacuniPage>("MerilnoMestoRacuniPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<VlogePage>("VlogePage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<VlogeVnosPodatkovPage>("VlogeVnosPodatkovPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<VlogeZahvalnaPage>("VlogeZahvalnaPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<MerilnoMestoVnosStanjaStevcaPage>("MerilnoMestoVnosStanjaStevcaPage");
            return await page.execute();
        });
        // end region

        // region Moj PLUS
        middlewareFunctions.push(async () => {
            var page = services.get<MojPlusPregledPage>("MojPlusPregledPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<MojPlusKoriscenjeKuponaPage>("MojPlusKoriscenjeKuponaPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<MojPlusMojeTockePage>("MojPlusMojeTockePage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<MojPlusPomembneInformacijePage>("MojPlusPomembneInformacijePage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<MojPlusUgodnostiPage>("MojPlusUgodnostiPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<MojPlusZbiranjeTockPage>("MojPlusZbiranjeTockPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<MojPlusPovabitePrijateljaPage>("MojPlusPovabitePrijateljaPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<MojPlusVclanitevPage>("MojPlusVclanitevPage");
            return await page.execute();
        });
        // end region

        // region Profil
        middlewareFunctions.push(async () => {
            var page = services.get<MojProfilPage>("MojProfilPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<MojProfilOdjavaPage>("MojProfilOdjavaPage");
            return await page.execute();
        });
        // end region

        //region Klicni center
        middlewareFunctions.push(async () => {
            var page = services.get<KlicniCenterSeznamPage>("KlicniCenterSeznamPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<KlicniCenterPregledPage>("KlicniCenterPregledPage");
            return await page.execute();
        });
        // end region

        // region Admin
        middlewareFunctions.push(async () => {
            var page = services.get<AdminDashboardPage>("AdminDashboardPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<AdminDodajanjeNovicePage>("AdminDodajanjeNovicePage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<AdminUporabnikiPregledPage>("AdminUporabnikiPregledPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<AdminUrejanjeUporabnikaPage>("AdminUrejanjeUporabnikaPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<AdminDodajUporabnikPage>("AdminDodajUporabnikPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<AdminNotificationDetailsPage>("AdminNotificationDetailsPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<AdminZahteveInNapakePage>("AdminZahteveInNapakePage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<AdminStatistikaPage>("AdminStatistikaPage");
            return await page.execute();
        });
        // end region

        // region Novice
        middlewareFunctions.push(async () => {
            var page = services.get<NovicePregledPage>("NovicePregledPage");
            return await page.execute();
        });
        // end region

        // region Naloži datoteko
        middlewareFunctions.push(async () => {
            var page = services.get<DatotekaNaloziPage>("DatotekaNaloziPage");
            return await page.execute();
        });
        // end region

        middlewareFunctions.push(async () => {
            var page = services.get<ZbirnikRacunovPage>("ZbirnikRacunovPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<PiskotkiPage>("PiskotkiPage");
            return await page.execute();
        });

        var router = new Router(middlewareFunctions, services);
        services.bind<Router>("Router").toConstantValue(router);

        var lock = new Lock();
        var mainLayoutSingleton: MainLayout;

        services.bind<interfaces.Provider<MainLayout>>("Provider<MainLayout>")
            .toProvider<MainLayout>((context) => {
                return () => {
                    if (mainLayoutSingleton) {
                        return Promise.resolve(mainLayoutSingleton);
                    }

                    return new Promise<MainLayout>(async (resolve, reject) => {
                        var releaser = await lock.acquire();
                        try {
                            if (mainLayoutSingleton) {
                                return mainLayoutSingleton;
                            }

                            let mainLayout = new MainLayout(
                                window.document,
                                authenticator,
                                router,
                                context.container.get<SiteMapManager>("SiteMapManager"),
                                context.container.get<CurrentUserAccessor>("CurrentUserAccessor"),
                                translationService,
                                kontaktiInPomocApiClient,
                                userApiClient,
                                merilnoMestoApiClient,
                                notyf
                            );

                            mainLayout.initialize().then(() => {
                                mainLayoutSingleton = mainLayout;
                                resolve(mainLayout);
                            })
                                .catch((e: Error) => {
                                    console.log("mainLayout  error", e);
                                    reject(e);
                                });

                            return mainLayout;
                        }
                        finally {
                            releaser();
                        }
                    });
                };
            }); 

        

    }    
}