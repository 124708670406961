import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { DownloadedFile } from "../models/download-file";
import { EboxPogovorRequest } from "../models/ebox-pogovor-request";
import { EboxPrenosDokumentaRequest } from "../models/ebox-prenos-dokumenta-request";
import { EboxSporocilaRequest } from "../models/ebox-sporocila-request";
import { EboxSporociloPrebranoRequest } from "../models/ebox-sporocilo-prebrano-request";
import { RezervirajSestanekRequest } from "../models/rezerviraj-sestanek-request";
import { EboxPogovorResponse } from "../models/ebox-pogovor-response";
import { parse } from "content-disposition-header";
import { EboxSporocilaResponse } from "../models/ebox-sporocila-response";
import { EboxPogovorPrebranRequest } from "../models/ebox-pogovor-prebran-request";
import { GetProdajnoMestoKoledarRequest } from "../models/get-prodajno-mesto-koledar-request";
import { GetProdajnoMestoKoledarDay } from "../models/get-prodajno-mesto-koledar-day";
import { SetTerminOnemogocenRequest } from "../models/set-termin-onemogocen-request";
import { SetDanOnemogocenRequest } from "../models/set-dan-onemogocen-request";
import { TerminDodajKomentarRequest } from "../models/termin-dodaj-komentar-request";
import { EboxUpravljajPogovorRequest } from "../models/ebox-upravljaj-pogovor-request";
import { ProdajnoMestoUmbracoResponse } from "../models/prodajno-mesto-umbraco-response";

@injectable()
export class KontaktiInPomocApiClient extends ApiClientBase {
    public name = "KontaktiInPomocApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator") authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async rezervirajSestanek(data: RezervirajSestanekRequest): Promise<any> {
        return await this._callApi('/kontaktiInPomoc/rezerviraj-sestanek/', 'POST', JSON.stringify(data), false) as any;
    }

    public async eboxPogovor(data: EboxPogovorRequest): Promise<EboxPogovorResponse> {
        return await this._callApi('/kontaktiInPomoc/ebox/pogovor/', 'POST', JSON.stringify(data), false) as EboxPogovorResponse;
    }

    public async eboxUpravljajPogovor(data: EboxUpravljajPogovorRequest): Promise<any> {
        return await this._callApi('/kontaktiInPomoc/ebox/upravljaj-pogovor', 'POST', JSON.stringify(data), false) as any;
    }

    public async eboxSporocila(data: EboxSporocilaRequest): Promise<EboxSporocilaResponse> {
        return await this._callApi('/kontaktiInPomoc/ebox/sporocila/', 'POST', JSON.stringify(data), false) as EboxSporocilaResponse;
    }

    public async eboxSporocilaHeader(): Promise<any> {
        return await this._callApi('/kontaktiInPomoc/ebox/sporocila-header/', 'GET', null, false) as any;
    }

    public async eboxNovoSporocilo(request: FormData): Promise<void> {
        return await this._callApi('/kontaktiInPomoc/ebox/novo-sporocilo', 'POST', request, true, "", null, null, (settings) => {
            settings.contentType = false;
            settings.processData = false;
        });
    }

    public async eboxNovoSporociloMass(request: FormData): Promise<void> {
        return await this._callApi('/kontaktiInPomoc/ebox/novo-sporocilo-mass', 'POST', request, true, "", null, null, (settings) => {
            settings.contentType = false;
            settings.processData = false;
        });
    }

    public async eboxOdgovorNaSporocilo(request: FormData): Promise<void> {
        return await this._callApi('/kontaktiInPomoc/ebox/odgovor-na-sporocilo', 'POST', request, true, "", null, null, (settings) => {
            settings.contentType = false;
            settings.processData = false;
        });
    }

    public async eboxSporociloPrebrano(data: EboxSporociloPrebranoRequest): Promise<any> {
        return await this._callApi('/kontaktiInPomoc/ebox/sporocilo-prebrano/', 'POST', JSON.stringify(data), false) as any;
    }

    public async eboxPogovorPrebran(data: EboxPogovorPrebranRequest): Promise<any> {
        return await this._callApi('/kontaktiInPomoc/ebox/pogovor-prebran/', 'POST', JSON.stringify(data), false) as any;
    }

    public async getProdajnaMestaKoledar(data: GetProdajnoMestoKoledarRequest): Promise<Array<GetProdajnoMestoKoledarDay>> {
        return await this._callApi('/kontaktiInPomoc/get-prodajno-mesto-koledar', 'POST', JSON.stringify(data), false) as Array<GetProdajnoMestoKoledarDay>;
    }

    public async getProdajnaMestaKlicKoledar(data: GetProdajnoMestoKoledarRequest): Promise<Array<GetProdajnoMestoKoledarDay>> {
        return await this._callApi('/kontaktiInPomoc/get-prodajno-mesto-klic-koledar', 'POST', JSON.stringify(data), false) as Array<GetProdajnoMestoKoledarDay>;
    }

    public async setTerminRezerviran(terminId: string): Promise<any> {
        return await this._callApi('/kontaktiInPomoc/set-termin-rezerviran/' + terminId, 'POST', null, false) as any;
    }

    public async prekliciTermin(terminId: string): Promise<any> {
        return await this._callApi('/kontaktiInPomoc/preklici-termin/' + terminId, 'POST', null, false) as any;
    }

    public async setTerminOnemogocen(data: SetTerminOnemogocenRequest): Promise<any> {
        return await this._callApi('/kontaktiInPomoc/set-termin-onemogocen', 'POST', JSON.stringify(data), false) as any;
    }

    public async setDanOnemogocen(data: SetDanOnemogocenRequest): Promise<any> {
        return await this._callApi('/kontaktiInPomoc/set-dan-onemogocen', 'POST', JSON.stringify(data), false) as any;
    }

    public async terminDodajKomentar(data: TerminDodajKomentarRequest): Promise<boolean> {
        return await this._callApi('/kontaktiInPomoc/termin-dodaj-komentar', 'POST', JSON.stringify(data), false) as boolean;
    }

    public async eboxPrenosDokumenta(data: EboxPrenosDokumentaRequest): Promise<DownloadedFile> {
        var file: DownloadedFile = null as any;
        var url = "/kontaktiInPomoc/ebox/prenos-dokumenta";
        await this._callApi(url, 'POST', JSON.stringify(data), true, "binary", (data, _status, jqueryXhr: JQueryXHR) => {
            var contentDisposition = jqueryXhr.getResponseHeader("Content-Disposition");
            var filename: string = "";
            if (contentDisposition) {
                var contentDispositionParsed = parse(contentDisposition as string);
                filename = contentDispositionParsed.parameters.filename;
            }
            var filetype = jqueryXhr.getResponseHeader("Content-Type");
            file = {
                fileName: filename,
                fileType: filetype,
                fileContent: data
            } as DownloadedFile;
        });
        return file;
    }

    public async getProdajnaMestaUmbraco(): Promise<Array<ProdajnoMestoUmbracoResponse>> {
        return await this._callApi('/kontaktiInPomoc/get-prodajna-mesta-umbraco', 'GET', null, false) as Array<ProdajnoMestoUmbracoResponse>;
    }
} 
