import { inject, injectable, interfaces } from "inversify";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { MainLayout } from "../../views/main-layout";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import * as Mustache from "mustache";
import template from "../pages/kontakti-ebox-page.html";
import { TranslationService } from "../translation-service";
import { KontaktiInPomocApiClient } from "../clients/kontakti-in-pomoc-api-client";
import { Notyf } from "notyf";
import { FullscreenLoader } from "../../views/fullscreen-loader";
import { MerilnoMestoApiClient } from "../clients/merilno-mesto-api-client";
import { KontaktiEboxDesktopView } from "../../views/kontakti-ebox-desktop-view";
import { KontaktiEboxMobileView } from "../../views/kontakti-ebox-mobile-view";

@injectable()
export class KontaktiEboxPage extends PageBase {
    public name = "KontaktiEboxPage";
    private _router: Router;
    private _translationService: TranslationService;
    private _kontaktiInPomocApiClient: KontaktiInPomocApiClient;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _notyf: Notyf;
    private _kontaktiEboxView: KontaktiEboxDesktopView | KontaktiEboxMobileView;
    private _eboxElement: JQuery<HTMLElement>;
    private _loader: FullscreenLoader;
    private _widthMobile: boolean;

    constructor(@inject("Provider<MainLayout>") private _mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("Router") router: Router,
        @inject("TranslationService") translationService: TranslationService,
        @inject("KontaktiInPomocApiClient") kontaktiInPomocApiClient: KontaktiInPomocApiClient,
        @inject("MerilnoMestoApiClient") merilnoMestoApiClient: MerilnoMestoApiClient,
        @inject("Notyf") notyf: Notyf,
        @inject("FullscreenLoader") loader: FullscreenLoader)
    {
        super();

        this._router = router;
        this._translationService = translationService;
        this._kontaktiInPomocApiClient = kontaktiInPomocApiClient;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._notyf = notyf;
        this._loader = loader;
        this._widthMobile = window.innerWidth <= 991;

        const html = Mustache.render(template, {});
        $('#main').html(html);

        var width = window.innerWidth;

        // Mobile   
        if (width <= 991) {
            this._eboxElement = $('#main').find("#kontakti-ebox");
            this._kontaktiEboxView = new KontaktiEboxMobileView(this._eboxElement, this._translationService, this._kontaktiInPomocApiClient,
                this._merilnoMestoApiClient, this._notyf, this._loader);
        }
        // Desktop
        else {
            this._eboxElement = $('#main').find("#kontakti-ebox");
            this._kontaktiEboxView = new KontaktiEboxDesktopView(this._eboxElement, this._translationService, this._kontaktiInPomocApiClient,
                this._merilnoMestoApiClient, this._notyf, this._loader);
        }

        // Event listener for resize
        var self = this;
        window.addEventListener('resize', function () {
            if (self._widthMobile && window.innerWidth > 991) {
                self._widthMobile = false;
                window.location.reload();
            }
            else if (!self._widthMobile && window.innerWidth <= 991) {
                self._widthMobile = true;
                window.location.reload();
            }
        });
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        if (!url.pathname.startsWith("/kontakti/ebox")) {
            return true;
        }
        var mainLayout = await this._mainLayoutProvider() as MainLayout;
        try {
            mainLayout.hideFooter();
            this._loader.show();
            await this._kontaktiEboxView.load();
            window.scrollTo(0, 0);
            $(".title-strani")[0].innerHTML = this._translationService.currentTranslations["Ebox"];
        } catch (e) {
            mainLayout.errorView.report(e);
        }
        finally {
            this._loader.hide();
            mainLayout.showFooter();
        }
        return false;
    }
}