import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { AKodaMerilnoMestoResponse } from "../ts/models/akoda-merilno-mesto-response";
import { MerilnoMesto } from "../ts/models/merilno-mesto";
import { UporabnikMerilnoMestoRequest } from "../ts/models/uporabnik-merilno-mesto-request";
import { UporabnikMerilnoMestoResponse } from "../ts/models/uporabnik-merilno-mesto-response";
import { TranslationService } from "../ts/translation-service";
import { FullscreenLoader } from "./fullscreen-loader";
import template from "./merilno-mesto-dodaj-izbira-view.html";
import { MerilnoMestoDodajPovratnaInformacijaView } from "./merilno-mesto-dodaj-povratna-informacija-view";

@injectable()
export class MerilnoMestoDodajIzbiraView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _merilnaMesta: Array<AKodaMerilnoMestoResponse>;
    private _merilnaMestaElektrika: Array<AKodaMerilnoMestoResponse>;
    private _merilnaMestaPlin: Array<AKodaMerilnoMestoResponse>;
    private _btnDodajAktualnoElement: JQuery<HTMLElement> | null;
    private _avtorizacijskaKodaElement: JQuery<HTMLElement> | null;
    private _aktualnoMerilnoMesto: AKodaMerilnoMestoResponse | null | undefined;
    private _merilnaMestaUporabnik: Array<MerilnoMesto> | null;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        merilnoMestoApiClient: MerilnoMestoApiClient,
        merilnaMesta: Array<AKodaMerilnoMestoResponse>,
        private _notyf: Notyf,
        private _loader: FullscreenLoader,
        private _izbranUporabnikId: number | undefined)
    {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._merilnaMesta = merilnaMesta;
        this._merilnaMestaElektrika = new Array<AKodaMerilnoMestoResponse>();
        this._merilnaMestaPlin = new Array<AKodaMerilnoMestoResponse>();
        this._btnDodajAktualnoElement = null;
        this._avtorizacijskaKodaElement = null;
        this._aktualnoMerilnoMesto = null;
        this._merilnaMestaUporabnik = null;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var uporabnikMerilnoMestoRequest: UporabnikMerilnoMestoRequest = {
                limit: 0,
                stevilkaStrani: 0,
                saldo: false,
                skupnaPorabaEnergije: false,
                merilnoMesto: "",
                merilnaTocka: ""
            };
            await this._merilnoMestoApiClient.getUporabnikMerilnaMesta(uporabnikMerilnoMestoRequest, this._izbranUporabnikId)
                .then((odgovor: UporabnikMerilnoMestoResponse) => {
                    this._merilnaMestaUporabnik = odgovor.merilnaMesta;
                    if (this._merilnaMestaUporabnik) {
                        for (let i = 0; i < this._merilnaMesta.length; i++) {
                            if (this._merilnaMesta[i].panoga == "01") {
                                
                                if (this._merilnaMestaUporabnik.find(a =>
                                    a.merilnaTocka == this._merilnaMesta[i].merilnaTocka && a.merilnaTocka == this._merilnaMesta[i].merilnaTocka && a.aktivacijskaKoda == this._merilnaMesta[i].aktivacijskaKoda) != null) {
                                    this._merilnaMesta[i].jeDodano = true;                                    
                                }
                                else {
                                    
                                    this._merilnaMesta[i].jeDodano = false;
                                }
                            }
                            else if (this._merilnaMesta[i].panoga == "02") {
                                if (this._merilnaMestaUporabnik.find(a =>
                                    a.merilnoMesto == this._merilnaMesta[i].merilnoMesto && a.aktivacijskaKoda == this._merilnaMesta[i].aktivacijskaKoda) != null) {
                                    this._merilnaMesta[i].jeDodano = true;                                    
                                }
                                else {                                    
                                    this._merilnaMesta[i].jeDodano = false;
                                }
                            }
                        }
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSearchingForMeasuringPoints"]);
                    return;
                });

            this._renderData();
            if ($("#page-loaded")[0] == undefined) return;
            this._initForm();
            window.scrollTo(0, 0);
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(): void {
        // Razvrstimo merilna mesta glede panoge (elektrika / plin)
        for (let i = 0; i < this._merilnaMesta.length; i++) {
            if (this._merilnaMesta[i].panoga == "01") {
                this._merilnaMesta[i].jeElektrika = true;
                this._merilnaMestaElektrika.push(this._merilnaMesta[i]);
            }
            else {
                this._merilnaMesta[i].jeElektrika = false;
                this._merilnaMestaPlin.push(this._merilnaMesta[i]);
            }
        }

        // Poiscemo katero merilno mesto je aktualno
        this._aktualnoMerilnoMesto = this._merilnaMesta.find(a => a.vhodnaAkoda == true);

        const viewModel = {
            merilnaMestaElektrika: this._merilnaMestaElektrika,
            merilnaMestaPlin: this._merilnaMestaPlin,
            aktualnoMerilnoMesto: this._aktualnoMerilnoMesto,
            obstajaAktualnoMerilnoMesto: this._aktualnoMerilnoMesto != null ? true : false,
            steviloMerilnihMest: this._merilnaMesta.length,
            translations: this._translationService.currentTranslations
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _initForm(): void {
        require('jquery-validation-unobtrusive');

        var allCheckBoxes = $(".icon-bar-checkbox :checkbox");
        allCheckBoxes.on("change", function () {
            var clickedCheckBox = $(this).closest(".tile-icon-bar")[0];
            clickedCheckBox = $(clickedCheckBox).find(".form-check-input")[0];
            $(this).closest(".tile-icon-bar").toggleClass("checked", (clickedCheckBox as HTMLInputElement).checked);
        });

        $('.icon-bar-checkbox :checkbox:not(.je-dodano, .je-dodano *)');
        var izberiVsaMerilnaMestaElektrikaBtn = $(".select-all-elektrika");
        izberiVsaMerilnaMestaElektrikaBtn.on("click", function () {
            allCheckBoxes = $(".form-check-input-elektrika:not(.je-dodano)");          
            if (izberiVsaMerilnaMestaElektrikaBtn.data("id") == "select-all-yes") {
                for (let i = 0; i < allCheckBoxes.length; i++) {
                    (allCheckBoxes[i] as HTMLInputElement).checked = true;
                }
                $(".tile-icon-bar-elektrika:not(.je-dodano)").toggleClass("checked", true);
                izberiVsaMerilnaMestaElektrikaBtn.data("id", "select-all-no");
            }
            else {
                for (let i = 0; i < allCheckBoxes.length; i++) {
                    (allCheckBoxes[i] as HTMLInputElement).checked = false;
                }
                $(".tile-icon-bar-elektrika").toggleClass("checked", false);
                izberiVsaMerilnaMestaElektrikaBtn.data("id", "select-all-yes");
            }
        });

        var izberiVsaMerilnaMestaPlinBtn = $(".select-all-plin");
        izberiVsaMerilnaMestaPlinBtn.on("click", function () {
            var allCheckBoxes = $(".form-check-input-plin:not(.je-dodano)");
            if (izberiVsaMerilnaMestaPlinBtn.data("id") == "select-all-yes") {
                for (let i = 0; i < allCheckBoxes.length; i++) {
                    (allCheckBoxes[i] as HTMLInputElement).checked = true;
                }
                $(".tile-icon-bar-plin:not(.je-dodano)").toggleClass("checked", true);
                izberiVsaMerilnaMestaPlinBtn.data("id", "select-all-no");
            }
            else {
                for (let i = 0; i < allCheckBoxes.length; i++) {
                    (allCheckBoxes[i] as HTMLInputElement).checked = false;
                }
                $(".tile-icon-bar-plin").toggleClass("checked", false);
                izberiVsaMerilnaMestaPlinBtn.data("id", "select-all-yes");
            }
        });

        this._btnDodajAktualnoElement = $(("#btnDodajAktualno"));
        if (this._btnDodajAktualnoElement) {
            this._btnDodajAktualnoElement.on("click", (ev: Event) => this._onBtnDodajAktualnoClick(ev));
        }
        
        this._avtorizacijskaKodaElement = $(("#avtorizacijskaKoda"));
        this._avtorizacijskaKodaElement.on("click", (ev: Event) => this._onBtnVnosAvtorizacijskeKodeClick(ev));

        var dodajIzbranaMerilnaMestaButtons = $((".btnDodajIzbranaMerilnaMesta"));
        if (dodajIzbranaMerilnaMestaButtons && dodajIzbranaMerilnaMestaButtons.length > 0) {
            for (let i = 0; i < dodajIzbranaMerilnaMestaButtons.length; i++) {
                dodajIzbranaMerilnaMestaButtons[i].addEventListener("click", (ev: Event) => this._onBtnDodajIzbranaMerilnaMestaClick(ev));
            }
        }

        var dodajVsaMerilnaMestaButtons = $((".btnDodajVsaMerilnaMesta"));
        if (dodajVsaMerilnaMestaButtons && dodajVsaMerilnaMestaButtons.length > 0) {
            for (let i = 0; i < dodajVsaMerilnaMestaButtons.length; i++) {
                dodajVsaMerilnaMestaButtons[i].addEventListener("click", (ev: Event) => this._onBtnDodajVsaMerilnaMestaClick(ev));
            }
        }
    }

    private _onBtnDodajIzbranaMerilnaMestaClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();        
        this._loader.show();
        var checkBoxes = $((".form-check-input"));
        var obstajaSelektiran = false;
        for (let i = 0; i < checkBoxes.length; i++) {
            if ((checkBoxes[i] as HTMLInputElement).checked) {
                obstajaSelektiran = true;
                break;
            }
        }
        if (obstajaSelektiran) {
            var merilnaMestaDodaj = Array<AKodaMerilnoMestoResponse>();
            var checkBoxesElektrika = $((".form-check-input-elektrika"));
            for (let i = 0; i < checkBoxesElektrika.length; i++) {
                if ((checkBoxesElektrika[i] as HTMLInputElement).checked) {
                    merilnaMestaDodaj.push(this._merilnaMestaElektrika[i]);
                }
            }
            var checkBoxesPlin = $((".form-check-input-plin"));
            for (let i = 0; i < checkBoxesPlin.length; i++) {
                if ((checkBoxesPlin[i] as HTMLInputElement).checked) {
                    merilnaMestaDodaj.push(this._merilnaMestaPlin[i]);
                }
            }
            this._merilnoMestoApiClient.dodajMerilnaMesta(merilnaMestaDodaj, this._izbranUporabnikId)
                .then((odgovor: any) => {
                    if (odgovor.status) {
                        var neuspesnoDodana = new Array<any>;
                        for (let i = 0; i < odgovor.merilnoMestoAkodaStatus.length; i++) {
                            if (!odgovor.merilnoMestoAkodaStatus[i].status) {
                                neuspesnoDodana.push(odgovor.merilnoMestoAkodaStatus[i]);
                            }
                        }
                        var view: MerilnoMestoDodajPovratnaInformacijaView = new MerilnoMestoDodajPovratnaInformacijaView(this.rootElement, this._translationService, neuspesnoDodana);
                        view.load();
                        this._loader.hide();
                    }
                    else {
                        this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileAddingMeasuringConsumptionPoints"]);
                        this._loader.hide();
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileAddingMeasuringConsumptionPoints"]);
                    this._loader.hide();
                });
        }
        else {
            this._notyf.error(this._translationService.currentTranslations["YouMustSelectAtLeastOneMeasuringSamplingPoint"]);
            this._loader.hide();
        }
    }

    private _onBtnDodajVsaMerilnaMestaClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        this._loader.show();
        var checkBoxes = $((".form-check-input"));
        var obstajaSelektiran = false;
        for (let i = 0; i < checkBoxes.length; i++) {
            if (!(checkBoxes[i] as HTMLInputElement).disabled) {
                obstajaSelektiran = true;
                break;
            }
        }
        if (obstajaSelektiran) {
            var merilnaMestaDodaj = Array<AKodaMerilnoMestoResponse>();
            var checkBoxesElektrika = $((".form-check-input-elektrika"));
            for (let i = 0; i < checkBoxesElektrika.length; i++) {
                if (!(checkBoxesElektrika[i] as HTMLInputElement).disabled) {
                    merilnaMestaDodaj.push(this._merilnaMestaElektrika[i]);
                }
            }
            var checkBoxesPlin = $((".form-check-input-plin"));
            for (let i = 0; i < checkBoxesPlin.length; i++) {
                if (!(checkBoxesPlin[i] as HTMLInputElement).disabled) {
                    merilnaMestaDodaj.push(this._merilnaMestaPlin[i]);
                }
            }
            this._merilnoMestoApiClient.dodajMerilnaMesta(merilnaMestaDodaj, this._izbranUporabnikId)
                .then((odgovor: any) => {
                    if (odgovor.status) {
                        var neuspesnoDodana = new Array<any>;
                        for (let i = 0; i < odgovor.merilnoMestoAkodaStatus.length; i++) {
                            if (!odgovor.merilnoMestoAkodaStatus[i].status) {
                                neuspesnoDodana.push(odgovor.merilnoMestoAkodaStatus[i]);
                            }
                        }
                        var view: MerilnoMestoDodajPovratnaInformacijaView = new MerilnoMestoDodajPovratnaInformacijaView(this.rootElement, this._translationService, neuspesnoDodana);
                        view.load();
                        this._loader.hide();
                    }
                    else {
                        this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileAddingMeasuringConsumptionPoints"]);
                        this._loader.hide();
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileAddingMeasuringConsumptionPoints"]);
                    this._loader.hide();
                });
        }
        else {
            this._notyf.error(this._translationService.currentTranslations["YouHaveAlreadyAddedAllMeasuringSamplingPoints"]);
            this._loader.hide();
        }
    }

    private async _onBtnDodajAktualnoClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        if (this._aktualnoMerilnoMesto != null) {
            var merilnaMesta = new Array<AKodaMerilnoMestoResponse>();
            merilnaMesta.push(this._aktualnoMerilnoMesto);
            this._merilnoMestoApiClient.dodajMerilnaMesta(merilnaMesta, this._izbranUporabnikId)
                .then((odgovor: any) => {
                    if (odgovor.status) {
                        var neuspesnoDodana = new Array<any>;
                        for (let i = 0; i < odgovor.merilnoMestoAkodaStatus.length; i++) {
                            if (!odgovor.merilnoMestoAkodaStatus[i].status) {
                                neuspesnoDodana.push(odgovor.merilnoMestoAkodaStatus[i]);
                            }
                        }
                        var view: MerilnoMestoDodajPovratnaInformacijaView = new MerilnoMestoDodajPovratnaInformacijaView(this.rootElement, this._translationService, neuspesnoDodana);
                        view.load();
                    }
                    else {
                        this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileAddingMeasuringConsumptionPoints"]);
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileAddingMeasuringConsumptionPoints"]);
                });
        }
    }

    private async _onBtnVnosAvtorizacijskeKodeClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        location.reload();
    }
}