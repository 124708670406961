import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { MojPlusApiClient } from "../ts/clients/moj-plus-api-client";
import { MojPlusPodatkiVclanitevRequest } from "../ts/models/moj-plus-podatki-vclanitev-request";
import { MojPlusUgodnostiRequest } from "../ts/models/moj-plus-ugodnosti-request";
import { MojPlusUgodnostiResponse } from "../ts/models/moj-plus-ugodnosti-response";
import { MojPlusVclanitev } from "../ts/models/moj-plus-vclanitev";
import { UnovcitevMojPlusUgodnostiRequest } from "../ts/models/unovcitev-moj-plus-ugodnosti-request";
import { TranslationService } from "../ts/translation-service";
import { FullscreenLoader } from "./fullscreen-loader";
import template from "./moj-plus-ugodnosti-view.html";

@injectable()
export class MojPlusUgodnostiView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _mojPlusApiClient: MojPlusApiClient;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _dropdownStevilkaRacuna: JQuery<HTMLElement> | null;
    private _ugodnosti: Array<MojPlusUgodnostiResponse> | null;
    private _vclanitev: MojPlusVclanitev | null;
    private _stevilkeKontoPogodb: Array<string>;
    private _izbranaKontoPogodba: string;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        mojPlusApiClient: MojPlusApiClient,
        merilnoMestoApiClient: MerilnoMestoApiClient,
        private _notyf: Notyf,
        private _loader: FullscreenLoader)
    {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._mojPlusApiClient = mojPlusApiClient;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._dropdownStevilkaRacuna = null;
        this._ugodnosti = null;
        this._izbranaKontoPogodba = "";
        this._vclanitev = null;
        this._stevilkeKontoPogodb = new Array<string>();
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;
            await this._merilnoMestoApiClient.getStevilkeKontoPogodb()
                .then((odgovor) => {
                    this._stevilkeKontoPogodb = odgovor.filter((value: any, index: any, array: any) => array.indexOf(value) === index);
                    if (this._stevilkeKontoPogodb.length > 0) {
                        // poiscemo ali obstaja selektira številka racuna (v querystringu)
                        var url = window.location.href.split("/");
                        var izbranaKontoPogodbaId = url[url.length - 1];
                        var kontoPogodba = this._stevilkeKontoPogodb.find(a => a == izbranaKontoPogodbaId);
                        if (kontoPogodba) {
                            this._izbranaKontoPogodba = kontoPogodba;
                        }
                        else {
                            // ce v querystringu ni dolocena konto pogodba, selektiramo prvo pogodbo iz liste
                            this._izbranaKontoPogodba = this._stevilkeKontoPogodb[0];
                        }
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    return;
                });


            if (this._izbranaKontoPogodba) {
                var mojPlusPodatkiVclanitevRequest: MojPlusPodatkiVclanitevRequest = {
                    stevilkaKontoPogodbe: this._izbranaKontoPogodba,
                    kljucProgramZvestobe: "EPLUS"
                };
                await this._mojPlusApiClient.podatkiVclanitev(mojPlusPodatkiVclanitevRequest)
                    .then((odgovor) => {
                        this._vclanitev = odgovor;
                        if (!odgovor.kreiranoDne) {  //Racun ne obstaja v programu zvestobe MojPlus
                            this._vclanitev = null;
                        }
                    })
                    .catch(() => {
                        this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    });

                if (this._vclanitev) {
                    var mojPlusUgodnostiRequest: MojPlusUgodnostiRequest = {
                        stevilkaKontoPogodbe: this._izbranaKontoPogodba,
                        kljucProgramZvestobe: "EPLUS"
                    };
                    await this._mojPlusApiClient.getUgodnosti(mojPlusUgodnostiRequest)
                        .then((odgovor: any) => {
                            if (odgovor.status) {
                                if (odgovor.razpolozljiveUgodnosti) {
                                    this._ugodnosti = odgovor.razpolozljiveUgodnosti;
                                }
                            }
                            else {
                                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                            }
                        })
                        .catch(() => {
                            this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                        });
                }
            }

            this._renderData(translations, this._ugodnosti);
            if ($("#page-loaded")[0] == undefined) return;
            if (this._izbranaKontoPogodba) {
                this._initForm();
            }
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(translations: any, ugodnosti: any): void {
        const viewModel = {
            vclanitev: this._vclanitev,
            mojPlusRacunObstaja: this._vclanitev != null ? true : false,
            ugodnosti: ugodnosti,
            ugodnostNeObstaja: !ugodnosti ? true : false,
            translations: translations,
            stevilkeKontoPogodbe: this._stevilkeKontoPogodb,
            izbranaKontoPogodba: this._izbranaKontoPogodba,
            obstajaKontoPogodba: this._izbranaKontoPogodba == "" ? false : true
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _initForm(): void {
        this._dropdownStevilkaRacuna = $("#dropdownStevilkaRacuna");
        this._dropdownStevilkaRacuna.on("change", (ev: Event) => this._onDropDownStevilkaRacunaChange(ev));
        if (this._izbranaKontoPogodba) {
            this._dropdownStevilkaRacuna.val(this._izbranaKontoPogodba);
        }

        var btnMerilnaMestaOthers = $("#btnMerilnaMestaOthers");
        btnMerilnaMestaOthers.on("click", () => {
            $(".merilna-mesta-not-all")[0].style.display = "none";
            $(".merilna-mesta-all")[0].style.display = "block";
        });

        var buttonsKoristiteUgodnost = $(".btnKoristiteUgodnost");
        for (let i = 0; i < buttonsKoristiteUgodnost.length; i++) {
            buttonsKoristiteUgodnost[i].addEventListener("click", (ev: Event) => this._onBtnKoristiteUgodnostClick(ev, i));
        }

        var btnPreklici = $("#btnPreklici");
        btnPreklici.on("click", () => {
            $(".koristite-ugodnost-validacijski-korak").hide();
            $(".ugodnosti-seznam").show();
        });

        var btnKoristiteIzbraneUgodnosti = $("#btnKoristiteIzbraneUgodnosti");
        btnKoristiteIzbraneUgodnosti.on("click", (ev: Event) => this._onBtnKoristiteIzbraneUgodnostiClick(ev));

        var btnKoristiteVseUgodnosti = $("#btnKoristiteVseUgodnosti");
        btnKoristiteVseUgodnosti.on("click", (ev: Event) => this._onBtnKoristiteVseUgodnostiClick(ev));

        var btnNazajNaPregledUgodnosti = $("#btnNazajNaPregledUgodnosti");
        btnNazajNaPregledUgodnosti.on("click", () => {
            $(".koristite-ugodnost-premalo-tock").hide();
            $(".ugodnosti-seznam").show();
        });

        var btnKoristiteUgodnostZakljuci = $("#btnKoristiteUgodnostZakljuci");
        btnKoristiteUgodnostZakljuci.on("click", (ev: Event) => this._onBtnKoristiteUgodnostZakljuciClick(ev));

        var btnNazajNaPregledUgodnostiZakljeno = $("#btnNazajNaPregledUgodnostiZakljeno");
        btnNazajNaPregledUgodnostiZakljeno.on("click", () => {
            location.reload();
        });

        var allCheckBoxes = $(".icon-bar-checkbox :checkbox");
        allCheckBoxes.on("change", function () {
            var clickedCheckBox = $(this).closest(".tile-icon-bar")[0];
            clickedCheckBox = $(clickedCheckBox).find(".form-check-input")[0];
            $(this).closest(".tile-icon-bar").toggleClass("checked", (clickedCheckBox as HTMLInputElement).checked);
        });
    }

    private _onBtnKoristiteIzbraneUgodnostiClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        this._loader.show();
        if (this._ugodnosti && this._vclanitev) {
            var obstajaSelektiran = false;
            var clickedCheckBox = $(".form-check-input");
            var izbraneUgodnosti = Array<MojPlusUgodnostiResponse>();
            var skupajTock = 0;
            for (let i = 0; i < clickedCheckBox.length; i++) {
                if ((clickedCheckBox[i] as HTMLInputElement).checked) {
                    obstajaSelektiran = true;
                    izbraneUgodnosti.push(this._ugodnosti[i]);
                    skupajTock += this._ugodnosti[i].potrebnoSteviloTock;
                }
            }
            if (!obstajaSelektiran) {
                this._notyf.error(this._translationService.currentTranslations["YouMustChooseAtLeastOneBenefit"]);
            }
            else {
                if (this._vclanitev.skupajRazpolozljiveTocke < skupajTock) {
                    $(".ugodnosti-seznam")[0].style.display = "none";
                    $(".koristite-ugodnost-premalo-tock")[0].style.display = "block";
                    this._premaloTock(this._vclanitev, skupajTock)
                }
                else {
                    $(".ugodnosti-seznam")[0].style.display = "none";
                    $(".koristite-ugodnost-validacijski-korak")[0].style.display = "block";
                    this._prikaziIzbrane(izbraneUgodnosti);
                }
            }
        }
        window.scrollTo(0, 0);
        this._loader.hide();
    }

    private _premaloTock(izbranMojPlusPodatek: any, skupajTock: number) {
        const templateString = `
            <p class="text-size-large margin-medium"><%{translations.ForUsingTheBenefitsYouAreMissing}%> <strong><%{manjkajoceTocke}%></strong> <%{translations.Points}%></p>
            <div class="graph-wrapper">
                <div class="graph-headline-row">
                    <div class="data-label"><strong><%{translations.TotalPoints}%>:</strong></div>
                    <div class="data"><%{izbranMojPlusPodatek.skupajRazpolozljiveTocke}%></div>
                </div>
                <div class="progress-wrap no-margin">
                    <div class="progress">
                        <div class="progress-bar green" role="progressbar" aria-label="Example with label" style="width: <%{tocke}%>%;" aria-valuenow="38" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <div class="small-progress-labels">
                        <div>500</div>
                        <div>1000</div>
                        <div>1500</div>
                    </div>
                </div>
            </div>
             `;
        var customTags: any = ['<%', '%>'];
        var tocke = 100 / 2000 * izbranMojPlusPodatek.skupajRazpolozljiveTocke; // TODO: ali v vsakem primeru imamo od 0 do 2000 točk?
        var html = Mustache.render(
            templateString,
            {
                "tocke": tocke,
                "manjkajoceTocke": Math.abs(izbranMojPlusPodatek.skupajRazpolozljiveTocke - skupajTock),
                "izbranMojPlusPodatek": izbranMojPlusPodatek,
                "translations": this._translationService.currentTranslations
            },
            undefined,
            customTags
        );
        $(".ugodnosti-seznam")[0].style.display = "none";
        $(".koristite-ugodnost-premalo-tock")[0].style.display = "block";
        $("#premalo-tock").html(html);
    }

    private _prikaziIzbrane(izbraneUgodnosti: any) {
        const templateString = `
            <%#izbraneUgodnosti%>
            <div class="tile-a h-auto mb-4 izbraneUgodnosti" data-kljucNagrade="<%{kljucNagrade}%>" data-obstajaLokacija="<%{obstajaLokacija}%>">
                <div class="coupon-wrap">
                    <div>
                        <h3 class="margin-xsmall"><%{opisPremije}%></h3>
                        <p class="margin-xsmall"><%{opisKategorije}%></p>
                        <p class="margin-xsmall"><%{steviloPotrebnihTock}%>: <span class="no-green"><%{potrebnoSteviloTock}%></span></p>
                    </div>
                </div>
                <div class="tile-icon-bar">
                    <div class="circle">
                        <img src="../img/icon-coupon.svg" class="ti">
                    </div>
                </div>
            </div>
            <%/izbraneUgodnosti%>
             `;
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "translations": this._translationService.currentTranslations,
                "izbraneUgodnosti": izbraneUgodnosti,
                "steviloPotrebnihTock": this._translationService.currentTranslations.NumberOfPointsRequired
            },
            undefined,
            customTags
        );
        $(".ugodnosti-seznam")[0].style.display = "none";
        $(".koristite-ugodnost-validacijski-korak")[0].style.display = "block";
        $("#ugodnost-validacijski-korak-seznam").html(html);
    }

    private _onBtnKoristiteVseUgodnostiClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        this._loader.show();
        if (this._ugodnosti && this._vclanitev) {
            var skupajTock = 0;
            for (let i = 0; i < this._ugodnosti.length; i++) {
                skupajTock += this._ugodnosti[i].potrebnoSteviloTock;
            }
            if (this._vclanitev.skupajRazpolozljiveTocke < skupajTock) {
                this._premaloTock(this._vclanitev, skupajTock)
            }
            else {
                this._prikaziIzbrane(this._ugodnosti);
            }
        }
        this._loader.hide();
    }

    private _onBtnKoristiteUgodnostClick(ev: Event, index: number) {
        console.debug(ev);
        ev.preventDefault();
        window.scrollTo(0, 0);
        if (this._ugodnosti && this._vclanitev) {
            var izbraneUgodnosti = Array<MojPlusUgodnostiResponse>();
            izbraneUgodnosti.push(this._ugodnosti[index]);
            if (this._vclanitev.skupajRazpolozljiveTocke < this._ugodnosti[index].potrebnoSteviloTock) {
                this._premaloTock(this._vclanitev, this._ugodnosti[index].potrebnoSteviloTock)
            }
            else {
                this._prikaziIzbrane(izbraneUgodnosti);
            }
        }
    }

    private _onDropDownStevilkaRacunaChange(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        this._dropdownStevilkaRacuna = $("#dropdownStevilkaRacuna");
        var dropdownMerilnoMestoValue = (this._dropdownStevilkaRacuna[0] as any).value;
        location.href = "#/moj-plus/ugodnosti/" + dropdownMerilnoMestoValue;
    }

    private async _onBtnKoristiteUgodnostZakljuciClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        var izbraneUgodnosti = new Array<UnovcitevMojPlusUgodnostiRequest>();
        var ugodnostiElement = $(".izbraneUgodnosti");
        if (ugodnostiElement && ugodnostiElement.length > 0) {
            for (let i = 0; i < ugodnostiElement.length; i++) {
                var kljucNagrade = ugodnostiElement[i].dataset["kljucnagrade"] as string;
                var obstajaLokacija = ugodnostiElement[i].dataset["obstajalokacija"];
                var data: UnovcitevMojPlusUgodnostiRequest = {
                    kljucNagrade: kljucNagrade,
                    kljucProgramZvestobe: "EPLUS",
                    stevilkaKontoPogodbe: this._izbranaKontoPogodba,
                    lokacijaNagradeK: obstajaLokacija == "true" ? "002" : ""
                };
                izbraneUgodnosti.push(data);
            }

            var vseOk = true;
            for (let i = 0; i < izbraneUgodnosti.length; i++) {
                await this._mojPlusApiClient.unovcitevUgodnosti(izbraneUgodnosti[i])
                    .then((odgovor) => {
                        if (!odgovor.status) {
                            vseOk = false;
                            this._notyf.error(odgovor.sporocilo);
                        }
                    })
                    .catch(() => {
                        this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                        return;
                    });
            }
            if (vseOk) {
                this._notyf.success(this._translationService.currentTranslations["SaveSuccessful"]);
                $(".koristite-ugodnost-validacijski-korak")[0].style.display = "none";
                $(".koristite-ugodnost-uspesno")[0].style.display = "block";
                window.scrollTo(0, 0);
            }
        }
    }
}
