import { injectable } from "inversify";
import * as moment from "moment";
import * as Mustache from "mustache";
import * as DateRangePicker from 'daterangepicker';
import { Notyf } from "notyf";
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { MerilnoMesto } from "../ts/models/merilno-mesto";
import { UporabnikMerilnoMestoRequest } from "../ts/models/uporabnik-merilno-mesto-request";
import { UporabnikMerilnoMestoResponse } from "../ts/models/uporabnik-merilno-mesto-response";
import { TranslationService } from "../ts/translation-service";
import { FullscreenLoader } from "./fullscreen-loader";
import template from "./merilno-mesto-narocilo-minutne-meritve-view.html";
import { NarociloMinutneMeritveRequest } from "../ts/models/narocilo-minutne-meritve";

@injectable()
export class MerilnoMestoNarociloMinutneMeritveView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _merilnaMesta: Array<MerilnoMesto> | null;
    private _izbranoMerilnoMesto: MerilnoMesto | null | undefined;
    private _dropdownMerilnaMestaElement: JQuery<HTMLElement> | null;
    private _minutneMeritveObdobjeOdPicker: DateRangePicker = null!;
    private _minutneMeritveObdobjeDoPicker: DateRangePicker = null!;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        merilnoMestoApiClient: MerilnoMestoApiClient,
        private _notyf: Notyf,
        private _loader: FullscreenLoader) {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._merilnaMesta = null;
        this._izbranoMerilnoMesto = null;
        this._dropdownMerilnaMestaElement = null;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;
            var uporabnikMerilnoMestoRequest: UporabnikMerilnoMestoRequest = {
                limit: 0,
                stevilkaStrani: 0,
                saldo: false,
                skupnaPorabaEnergije: false,
                merilnoMesto: "",
                merilnaTocka: ""
            };
            await this._merilnoMestoApiClient.getUporabnikMerilnaMesta(uporabnikMerilnoMestoRequest)
                .then((odgovor: UporabnikMerilnoMestoResponse) => {
                    this._merilnaMesta = odgovor.merilnaMesta;
                    if (this._merilnaMesta && this._merilnaMesta.length > 0) {
                        this._merilnaMesta = this._merilnaMesta.filter(a => a.jeElektrika == true);
                    }
                    if (this._merilnaMesta && this._merilnaMesta.length > 0) {
                        // poiscemo ali obstaja selektirano MM (v querystringu)
                        var url = window.location.href.split("/");
                        var izbranoMerilnoMesto = url[url.length - 1];
                        this._izbranoMerilnoMesto = this._merilnaMesta.find(a => a.merilnoMesto == izbranoMerilnoMesto || a.merilnaTocka == izbranoMerilnoMesto);
                        // ce v querystringu ni doloceno MM, selektiramo prvega MM iz liste
                        if (!this._izbranoMerilnoMesto) {
                            this._izbranoMerilnoMesto = this._merilnaMesta[0];
                        }
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSearchingForMeasuringPoints"]);
                    return;
                });

            this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;
            if (this._izbranoMerilnoMesto) {
                this._initForm();
            }
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(translations: any): void {
        var merilnaMestaIds = new Array<string>();
        var prikazanaMerilnaMesta = new Array<string>();
        var steviloPrikazanih = 3;
        if (this._merilnaMesta) {
            merilnaMestaIds = Array.from(new Set(this._merilnaMesta.map((item) => item.merilnoMesto)));
            prikazanaMerilnaMesta = merilnaMestaIds.slice(0, steviloPrikazanih);
            if (this._merilnaMesta.length < steviloPrikazanih) {
                prikazanaMerilnaMesta = merilnaMestaIds;
            }
        }

        const viewModel = {
            merilnaMesta: this._merilnaMesta,
            izbranoMerilnoMesto: this._izbranoMerilnoMesto,
            ostalaMerilnaMestaStevilo: merilnaMestaIds.length - prikazanaMerilnaMesta.length,
            obstajajoOstalaMerilnaMesta: merilnaMestaIds.length - prikazanaMerilnaMesta.length > 0 ? true : false,
            prikazanaMerilnaMesta: prikazanaMerilnaMesta,
            translations: translations,
            obstajaMerilnoMesto: this._izbranoMerilnoMesto ? true : false
        } as any;
        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _initForm(): void {
        if (!this._merilnaMesta || !this._izbranoMerilnoMesto) {
            return;
        }

        this._dropdownMerilnaMestaElement = $("#dropdownMerilnaMesta");
        this._dropdownMerilnaMestaElement.on("change", (ev: Event) => this._onDropDownMerilnoMestoChange(ev));
        if (this._izbranoMerilnoMesto.jeElektrika) {
            this._dropdownMerilnaMestaElement.val(this._izbranoMerilnoMesto.merilnaTocka);
        }
        else {
            this._dropdownMerilnaMestaElement.val(this._izbranoMerilnoMesto.merilnoMesto);
        }

        var btnMerilnaMestaOthers = $("#btnMerilnaMestaOthers");
        btnMerilnaMestaOthers.on("click", () => {
            $(".merilna-mesta-not-all")[0].style.display = "none";
            $(".merilna-mesta-all")[0].style.display = "block";
        });

        this._minutneMeritveObdobjeOdPicker = new DateRangePicker($('#main').find("#minutneMeritveObdobjeOd")[0], {
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            minDate: moment().add(-20, 'years'),
            maxDate: moment()
        });

        this._minutneMeritveObdobjeDoPicker = new DateRangePicker($('#main').find("#minutneMeritveObdobjeDo")[0], {
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            minDate: moment().add(-20, 'years'),
            maxDate: moment()
        });

        var btnMinutneMeritvePrejsnjiDanElement = $("#btn-minutne-meritve-prejsnji-dan");
        btnMinutneMeritvePrejsnjiDanElement.on("click", (ev: Event) => this._onBtnPrejsnjiDanClick(ev, this._minutneMeritveObdobjeOdPicker, this._minutneMeritveObdobjeDoPicker));

        var btnMinutneMeritveZadnjiTedenElement = $("#btn-minutne-meritve-zadnji-teden");
        btnMinutneMeritveZadnjiTedenElement.on("click", (ev: Event) => this._onBtnZadnjiTedenClick(ev, this._minutneMeritveObdobjeOdPicker, this._minutneMeritveObdobjeDoPicker));

        var btnMinutneMeritveZadnjiMesecElement = $("#btn-minutne-meritve-zadnji-mesec");
        btnMinutneMeritveZadnjiMesecElement.on("click", (ev: Event) => this._onBtnZadnjiMesecClick(ev, this._minutneMeritveObdobjeOdPicker, this._minutneMeritveObdobjeDoPicker)); 

        var btnNarociMinutneMeritve = $("#btn-naroci-minutne-meritve");
        btnNarociMinutneMeritve.on("click", (ev: Event) => this._onBtnNarociMinutneMeritveClick(ev)); 

    }

    private _onDropDownMerilnoMestoChange(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        this._dropdownMerilnaMestaElement = $("#dropdownMerilnaMesta");
        var dropdownMerilnoMestoValue = (this._dropdownMerilnaMestaElement[0] as any).value;
        location.href = "#/merilno-mesto/narocilo-minutne-meritve/" + dropdownMerilnoMestoValue;
    }

    private _onBtnPrejsnjiDanClick(ev: Event, obdobjeOdPicker: DateRangePicker, obdobjeDoPicker: DateRangePicker) {
        console.debug(ev);
        ev.preventDefault();
        var now = new Date();
        var prejsnjiDan = new Date();
        prejsnjiDan.setDate(prejsnjiDan.getDate() - 1);
        obdobjeOdPicker.setStartDate(new Date(prejsnjiDan.getFullYear(), prejsnjiDan.getMonth(), prejsnjiDan.getDate(), 0, 0, 0));
        obdobjeDoPicker.setStartDate(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0));
    }

    private _onBtnZadnjiTedenClick(ev: Event, obdobjeOdPicker: DateRangePicker, obdobjeDoPicker: DateRangePicker) {
        console.debug(ev);
        ev.preventDefault();
        var now = new Date();
        var prejsnjiTedenStart = new Date();
        prejsnjiTedenStart.setDate(prejsnjiTedenStart.getDate() - 7);
        obdobjeOdPicker.setStartDate(new Date(prejsnjiTedenStart.getFullYear(), prejsnjiTedenStart.getMonth(), prejsnjiTedenStart.getDate()));
        obdobjeDoPicker.setStartDate(new Date(now.getFullYear(), now.getMonth(), now.getDate()));
    }

    private _onBtnZadnjiMesecClick(ev: Event, obdobjeOdPicker: DateRangePicker, obdobjeDoPicker: DateRangePicker) {
        console.debug(ev);
        ev.preventDefault();
        var now = new Date();
        obdobjeOdPicker.setStartDate(new Date(now.getFullYear(), now.getMonth() - 1, 1));
        obdobjeDoPicker.setStartDate(new Date(now.getFullYear(), now.getMonth(), 1));
    }

    private async _onBtnNarociMinutneMeritveClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        if (!this._izbranoMerilnoMesto) return;
        this._loader.show();

        var formElement = $(("#prikaz-narocila"));
        var formData = new FormData(formElement[0] as HTMLFormElement);

        var obdobjeOdString = formData.get("minutneMeritveObdobjeOd") as string;
        var obdobjeOdSplit = obdobjeOdString.split(".");
        var obdobjeOd = new Date(Number(obdobjeOdSplit[2]), Number(obdobjeOdSplit[1]) - 1, Number(obdobjeOdSplit[0]));
        var obdobjeDoString = formData.get("minutneMeritveObdobjeDo") as string;
        var obdobjeDoSplit = obdobjeDoString.split(".");
        var obdobjeDo = new Date(Number(obdobjeDoSplit[2]), Number(obdobjeDoSplit[1]) - 1, Number(obdobjeDoSplit[0]));
        obdobjeOd.setDate(obdobjeOd.getDate() + 1); // dodamo 1 dan za da, na backend pridejo pravilni podatki
        obdobjeDo.setDate(obdobjeDo.getDate() + 1); // dodamo 1 dan za da, na backend pridejo pravilni podatki

        if (!($("#checkbox-email")[0] as HTMLInputElement).checked) {
            this._notyf.error(this._translationService.currentTranslations["YouHaveToChooseWhereYouWantToReceiveTheData"]);
            this._loader.hide();
            return;
        }
        else {
            var data: NarociloMinutneMeritveRequest = {
                merilnoMesto: this._izbranoMerilnoMesto.merilnoMesto,
                merilnaTocka: this._izbranoMerilnoMesto.merilnaTocka,
                stevilkaKontoPogodbe: this._izbranoMerilnoMesto.stevilkaKontoPogodbe,
                idKanala: 1,
                kodaKanala: "EMAIL",
                nazivKanala: "email",
                obdobjeOd: obdobjeOd,
                obdobjeDo: obdobjeDo,
                casCiklaMinute: 15
            };
            await this.narociMinutneMeritve(data);
            this._loader.hide();
        }
    }

    private async narociMinutneMeritve(data: NarociloMinutneMeritveRequest) {
        await this._merilnoMestoApiClient.narociMinutneMeritve(data)
            .then((odgovor: any) => {
                if (odgovor.status) {
                    this._notyf.success(this._translationService.currentTranslations["YouHaveSuccessfullySubscribedTo15MinuteMeasurements"]);
                }
                else {
                    this._notyf.error(odgovor.sporocilo);
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                return;
            });
    }
}
