import { injectable } from "inversify";
import * as moment from "moment";
import * as Mustache from "mustache";
import * as DateRangePicker from 'daterangepicker';
import { Notyf } from "notyf";
import { KontaktiInPomocApiClient } from "../ts/clients/kontakti-in-pomoc-api-client";
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { EboxPogovorPrebranRequest } from "../ts/models/ebox-pogovor-prebran-request";
import { EboxPogovorRequest } from "../ts/models/ebox-pogovor-request";
import { EboxPogovorResponse } from "../ts/models/ebox-pogovor-response";
import { EboxPrenosDokumentaRequest } from "../ts/models/ebox-prenos-dokumenta-request";
import { EboxSporocilaRequest } from "../ts/models/ebox-sporocila-request";
import { EboxSporocilaResponse, EboxSporocilo } from "../ts/models/ebox-sporocila-response";
import { MerilnoMesto } from "../ts/models/merilno-mesto";
import { UporabnikMerilnoMestoRequest } from "../ts/models/uporabnik-merilno-mesto-request";
import { UporabnikMerilnoMestoResponse } from "../ts/models/uporabnik-merilno-mesto-response";
import { TranslationService } from "../ts/translation-service";
import { FullscreenLoader } from "./fullscreen-loader";
import { EboxNovoSporociloOdjemnoMestoRequest, EboxNovoSporociloRequest } from "../ts/models/ebox-novo-sporocilo-request";
import { EboxUpravljajPogovorRequest } from "../ts/models/ebox-upravljaj-pogovor-request";
import template from "./kontakti-ebox-mobile-view.html";
import eboxSporocilaWidget from "./widget-ebox-sporocila-mobile.html";
import eboxPogovorWidget from "./widget-ebox-pogovor-mobile.html";
import priponkeTemplateNovoSporocilo from "./widget-priponke-novo-sporocilo-mobile.html";
import priponkeTemplateOdgovorNaSporocilo from "./widget-priponke-odgovor-na-sporocilo-mobile.html";
import novoSporociloModal from "./widget-novo-sporocilo-modal-mobile.html";

@injectable()
export class KontaktiEboxMobileView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _kontaktiInPomocApiClient: KontaktiInPomocApiClient;
    private _eboxSporocila: {
        prejetaSporocila: Array<EboxSporocilo> | null,
        poslanaSporocila: Array<EboxSporocilo> | null,
        vsaSporocila: Array<EboxSporocilo> | null,
    };
    private _threadId: number | null;
    private _formators: any;
    private _izbranoMerilnoMesto: MerilnoMesto | null | undefined;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _merilnaMesta: Array<MerilnoMesto> | null;
    private _obdobjeOdPicker: DateRangePicker = null!;
    private _obdobjeDoPicker: DateRangePicker = null!;
    private _izbranoSporociloIndex: number;
    private _izbranoSporocilo: any;
    private _fileList: any;
    private _fileViewList: Array<any>;
    private _uploadFilesElement: HTMLInputElement | null;
    private _pogovor: EboxPogovorResponse | null;
    private _textLengthLimit: number;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        kontaktiInPomocApiClient: KontaktiInPomocApiClient,
        merilnoMestoApiClient: MerilnoMestoApiClient,
        private _notyf: Notyf,
        private _loader: FullscreenLoader) {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._kontaktiInPomocApiClient = kontaktiInPomocApiClient;
        this._threadId = null;
        this._formators = null;
        this._izbranoMerilnoMesto = null;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._merilnaMesta = null;
        this._eboxSporocila = {
            prejetaSporocila: null,
            poslanaSporocila: null,
            vsaSporocila: null
        };
        this._izbranoSporociloIndex = -1;
        this._izbranoSporocilo = null;
        this._fileList = new Array<File>();
        this._fileViewList = new Array<any>();
        this._uploadFilesElement = null;
        this._pogovor = null;
        this._textLengthLimit = 1200;
    }

    public _getMerilnoMestoFromURL(merilnaMesta: Array<MerilnoMesto>): MerilnoMesto | undefined {
        var url_string = window.location.href.split("?");
        var url_data = url_string[url_string.length - 1];
        var data_list = url_data.split("_");
        const stevilkaKontoPogodbe = data_list[data_list.length - 1];
        const merilnoMesto = data_list[data_list.length - 2];
        const jeElektrika = data_list[data_list.length - 3];

        if (jeElektrika == 'true') {
            const merilnaTocka = data_list[data_list.length - 4];
            if (window.location.href.includes("pogovor")) {
                this._threadId = Number(data_list[data_list.length - 5]);
            }
            history.pushState({}, "", "#/kontakti/ebox");
            return merilnaMesta.find(
                a => a.merilnoMesto == merilnoMesto && a.merilnaTocka == merilnaTocka && a.stevilkaKontoPogodbe == stevilkaKontoPogodbe);
        }
        else {
            if (window.location.href.includes("pogovor")) {
                this._threadId = Number(data_list[data_list.length - 4]);
            }
            history.pushState({}, "", "#/kontakti/ebox");
            return merilnaMesta.find(
                a => a.merilnoMesto == merilnoMesto && a.stevilkaKontoPogodbe == stevilkaKontoPogodbe);
        }
    }

    public _getMerilnoMestoFromDropdown(dropdownValue: string): MerilnoMesto | undefined {
        if (!this._merilnaMesta) return;
        var data_list = dropdownValue.split("_");
        const jeElektrika = data_list[0];
        const stevilkaKontoPogodbe = data_list[1];
        const merilnoMesto = data_list[2];
        const merilnaTocka = jeElektrika == 'true' ? data_list[3] : "";
        if (jeElektrika == 'true') {
            return this._merilnaMesta.find(
                a => a.merilnoMesto == merilnoMesto && a.merilnaTocka == merilnaTocka && a.stevilkaKontoPogodbe == stevilkaKontoPogodbe);
        }
        else {
            return this._merilnaMesta.find(
                a => a.merilnoMesto == merilnoMesto && a.stevilkaKontoPogodbe == stevilkaKontoPogodbe);
        }
    }

    public async load(): Promise<void> {
        try {
            var uporabnikMerilnoMestoRequest: UporabnikMerilnoMestoRequest = {
                limit: 0,
                stevilkaStrani: 0,
                saldo: false,
                skupnaPorabaEnergije: false,
                merilnoMesto: "",
                merilnaTocka: ""
            };
            await this._merilnoMestoApiClient.getUporabnikMerilnaMesta(uporabnikMerilnoMestoRequest)
                .then((odgovor: UporabnikMerilnoMestoResponse) => {
                    this._merilnaMesta = odgovor.merilnaMesta;
                    if (this._merilnaMesta && this._merilnaMesta.length > 0) {
                        // poiscemo ali obstaja selektirano MM (v querystringu)
                        this._izbranoMerilnoMesto = this._getMerilnoMestoFromURL(this._merilnaMesta);
                        // ce v querystringu ni doloceno MM, selektiramo prvega MM iz liste
                        if (!this._izbranoMerilnoMesto) {
                            this._izbranoMerilnoMesto = this._merilnaMesta[0];
                        }
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSearchingForMeasuringPoints"]);
                });

            this._renderData();
            if ($("#page-loaded")[0] == undefined) return;
            if (this._izbranoMerilnoMesto) {
                // uporabnik je kliknil na sporočilo iz dashboarda
                if (this._threadId != null) {
                    await this._getSporocila(this._izbranoMerilnoMesto, null, null);
                    var najdenoSporocilo = false;
                    if (this._eboxSporocila.vsaSporocila != null) {
                        this._izbranoSporocilo = this._eboxSporocila.vsaSporocila.find(a => a.glava.threadId == this._threadId);
                        if (this._izbranoSporocilo) {
                            najdenoSporocilo = true;
                        }
                    }
                    // Če sporočilo ni najdeno => nastavimo dropdown na arhivirana sporočila
                    if (!najdenoSporocilo) {
                        $("#dropdown-prejeta-poslana").val("4");
                    }
                }
                if (!this._izbranoSporocilo) {
                    await this._getSporocila(this._izbranoMerilnoMesto, null, null);
                }
                await this._renderSporocila();
                this._initForm();
            }
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderAttachments(odgovorNaSporociloPriponke: boolean): void {
        // Build a view model from the API data
        const viewModel = {
            "imageUrlDelete": "../img/icon-delete.svg",
            "files": this._fileViewList,
            "translations": this._translationService.currentTranslations
        } as any;
        // Construct a template
        var htmlTemplate = null;

        if (odgovorNaSporociloPriponke) {
            htmlTemplate = priponkeTemplateOdgovorNaSporocilo;
        }
        else {
            htmlTemplate = priponkeTemplateNovoSporocilo;      
        }

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        if (odgovorNaSporociloPriponke) {
            $("#attachments-widget-pogovor").html(html);
        }
        else {
            $('#attachments-widget-novo-sporocilo').html(html);
            var btnAddAttachmentNovoSporocilo = $("#btn-add-attachment-novo-sporocilo");
            btnAddAttachmentNovoSporocilo.on("click", () => {
                $("#attachments-novo-sporocilo")[0].click();
            });
        }

        this._initUploadField(odgovorNaSporociloPriponke);
        this._initDeleteAttachment(odgovorNaSporociloPriponke);
    }

    //inicializira file upload polje
    public _initUploadField(odgovorNaSporociloPriponke: boolean) {
        this._uploadFilesElement = odgovorNaSporociloPriponke ?
            document.getElementById("attachments-odgovor-na-sporocilo") as HTMLInputElement :
            document.getElementById("attachments-novo-sporocilo") as HTMLInputElement;
        this._uploadFilesElement.addEventListener("change", (ev: Event) => this._onUploadChange(ev, odgovorNaSporociloPriponke));
    }

    //inicializira gumb za brisanje priponk
    public _initDeleteAttachment(odgovorNaSporociloPriponke: boolean) {
        var self = this;
        if (odgovorNaSporociloPriponke) {
            $(".delete-attachment-btn-odgovor-na-sporocilo").each(function () {
                var element = this as HTMLInputElement;
                element.addEventListener("click", (ev: Event) => self._onDeleteAttachment(ev, odgovorNaSporociloPriponke));
                //$(this).on("click", (ev: Event) => self._onDeleteAttachment(ev));
            });
        }
        else {
            $(".delete-attachment-btn-novo-sporocilo").each(function () {
                var element = this as HTMLInputElement;
                element.addEventListener("click", (ev: Event) => self._onDeleteAttachment(ev, odgovorNaSporociloPriponke));
                //$(this).on("click", (ev: Event) => self._onDeleteAttachment(ev));
            });
        }
    }

    //shrani pripete dokumente v seznam za kasnejše pošiljanje na strežnik
    public _onUploadChange(ev: Event, odgovorNaSporociloPriponke: boolean) {
        var th = this;
        const target = ev.target as HTMLInputElement;
        let list: FileList | null = target.files;
        if (list) {
            $(list).each(function (_index, element) {
                if (th._fileList.length >= 5) {
                    th._notyf.error("Dovoljeno je dodati največ pet prilog!");
                    return;
                }
                th._fileList.push(element);
            })
        }

        //Datoteke prekopiramo v view model, zaradi indexa
        this._fileViewList = [];
        for (let i in this._fileList) {
            this._fileViewList.push({ 'index': i, 'name': this._fileList[i].name });
        }

        this._renderAttachments(odgovorNaSporociloPriponke);
    }

    //event za brisanje priponk
    public _onDeleteAttachment(ev: Event, odgovorNaSporociloPriponke: boolean) {
        const target = ev.currentTarget as HTMLInputElement;
        var indexString = target.getAttribute("data-index");
        if (!indexString) {
            return;
        }
        var index = parseInt(indexString);
        if (index || index == 0) {
            this._fileList.splice(index, 1);
        }
        this._fileViewList = [];
        for (let idx in this._fileList) {
            this._fileViewList.push({ 'index': idx, 'name': this._fileList[idx].name });
        }

        this._renderAttachments(odgovorNaSporociloPriponke);
    }

    private _renderData(): void {
        var localeFormat = moment().creationData().locale.longDateFormat("L");
        this._formators = {
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            }//tukaj se lahko dodajajo formatorji
        };

        const viewModel = {
            formators: this._formators,
            translations: this._translationService.currentTranslations,
            izbranoMerilnoMesto: this._izbranoMerilnoMesto,
            merilnaMesta: this._merilnaMesta ? this._merilnaMesta : null,
            obstajaMerilnoMesto: this._izbranoMerilnoMesto ? true : false
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _initForm(): void {
        if (!this._izbranoMerilnoMesto) {
            return;
        }

        var filterMerilnoMestoDropdown = $("#filter-merilno-mesto-dropdown");
        if (this._izbranoMerilnoMesto.jeElektrika) {
            filterMerilnoMestoDropdown.val(this._izbranoMerilnoMesto.jeElektrika + "_" + this._izbranoMerilnoMesto.stevilkaKontoPogodbe + "_" + this._izbranoMerilnoMesto.merilnoMesto + "_" + this._izbranoMerilnoMesto.merilnaTocka);
        }
        else {
            filterMerilnoMestoDropdown.val(this._izbranoMerilnoMesto.jeElektrika + "_" + this._izbranoMerilnoMesto.stevilkaKontoPogodbe + "_" + this._izbranoMerilnoMesto.merilnoMesto);
        }
        filterMerilnoMestoDropdown.on("change", async () => {
            await this._onFilterChange();
        });

        console.log(this._obdobjeOdPicker);
        this._obdobjeOdPicker = new DateRangePicker($('#main').find("#obdobjeOd")[0], {
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            autoUpdateInput: false,
            minDate: moment().add(-20, 'years'),
            maxDate: moment()
        });
        $("#obdobjeOd").on("apply.daterangepicker", async (ev, picker) => {
            ev.preventDefault();
            var year = picker.startDate.year();
            var month = picker.startDate.month() + 1;
            var day = picker.startDate.date();
            $('input[name="obdobjeOd"]').val(day.toString() + ". " + month.toString() + ". " + year.toString());
            await this._onFilterChange();
        });
        $('input[name="obdobjeOd"]').on("change", async () => {
            await this._onFilterChange();
        });

        console.log(this._obdobjeDoPicker);
        this._obdobjeDoPicker = new DateRangePicker($('#main').find("#obdobjeDo")[0], {
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            autoUpdateInput: false,
            minDate: moment().add(-20, 'years'),
            maxDate: moment()
        });
        $("#obdobjeDo").on("apply.daterangepicker", async (ev, picker) => {
            ev.preventDefault();
            var year = picker.startDate.year();
            var month = picker.startDate.month() + 1;
            var day = picker.startDate.date();
            $('input[name="obdobjeDo"]').val(day.toString() + ". " + month.toString() + ". " + year.toString());
            await this._onFilterChange();
        });
        $('input[name="obdobjeDo"]').on("change", async () => {
            await this._onFilterChange();
        });

        var dropdownPrejetaPoslana = $("#dropdown-prejeta-poslana");
        dropdownPrejetaPoslana.on("change", async () => {
            await this._onFilterChange();
        });

        var dropdownPrebranaNeprebrana = $("#dropdown-prebrana-neprebrana");
        dropdownPrebranaNeprebrana.on("change", async () => {
            await this._onFilterChange();
        });

        var eboxPrejetoTab = $("#ebox-prejeto-tab");
        eboxPrejetoTab.on("click", async () => {
            await this._onFilterChange();
        });

        var eboxPoslanoTab = $("#ebox-poslano-tab");
        eboxPoslanoTab.on("click", async () => {
            await this._onFilterChange();
        });

        var eboxVsaTab = $("#ebox-vsa-tab");
        eboxVsaTab.on("click", () => {
            this._renderSporocila();
        });

        // TODO: treba je dodati ko se renderira
        var btnDodajSporocilo = $("#btn-dodaj-sporocilo");
        btnDodajSporocilo.on("click", (ev: Event) => {
            this._onBtnDodajSporociloClick(ev);
        });

        var btnPocistiFiltre = $("#btn-pocisti-filtre");
        btnPocistiFiltre.on("click", async () => {
            $("#dropdown-prejeta-poslana").val("1");
            $("#dropdown-prebrana-neprebrana").val("1");
            $('input[name="obdobjeOd"]').val("");
            $('input[name="obdobjeDo"]').val("");
            await this._onFilterChange();
        });

        var btnNovoSporociloModal = $("#btn-novo-sporocilo-modal");
        btnNovoSporociloModal.on("click", async () => {
            await this._renderNovoSporociloModal();
            await this._renderAttachments(false);
            var btnNovoSprocilo = $("#btn-novo-sporocilo");
            btnNovoSprocilo.on("click", (ev: Event) => this._onBtnNovoSporociloClick(ev));
            var self = this;
            $('#EboxNovoSporocilo').on('hidden.bs.modal', function () {
                self._fileList = new Array<File>();
                self._fileViewList = new Array<any>();
            })
            $("#EboxNovoSporocilo").modal("show");
        });
    }

    private async _onBtnNovoSporociloClick(ev: Event) {
        ev.preventDefault();
        if (!this._izbranoMerilnoMesto) {
            return;
        }

        // validacija
        var novoSporociloForm = $(("#form-novo-sporocilo"));
        $.validator.unobtrusive.parse(novoSporociloForm);
        novoSporociloForm.validate();

        var vsebinaSporocila = $("#novo-sporocilo-floating-textarea").val();
        if (!novoSporociloForm.valid()) {
            // dodamo samo za izpis napake
            if (vsebinaSporocila == "") {
                this._notyf.error(this._translationService.currentTranslations["MessageContentIsARequiredField"]);
            }
            this._notyf.error(this._translationService.currentTranslations["CheckAllFields"]);
            return;
        }
        if (vsebinaSporocila == "") {
            this._notyf.error(this._translationService.currentTranslations["MessageContentIsARequiredField"]);
            return;
        }

        this._loader.show();
        var formData = this._createFormData();
        var sporociloUspesnoDodano = false;
        await this._kontaktiInPomocApiClient.eboxNovoSporocilo(formData)
            .then((odgovor: any) => {
                if (odgovor.status) {
                    sporociloUspesnoDodano = true;
                }
                else {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                }
            })
            .catch((ex: any) => {
                if (ex.statusCode == 400) {
                    this._loader.hide();
                    this._notyf.error(ex.message);
                }
                else {
                    this._loader.hide();
                    this._notyf.error(this._translationService.currentTranslations["SaveError"]);
                }
            });

        if (sporociloUspesnoDodano) {
            $("#EboxNovoSporocilo").modal("hide");
            this._fileList = new Array<File>();
            this._fileViewList = new Array<any>();
            await this._getSporocila(this._izbranoMerilnoMesto, null, null);
            await this._renderSporocila();
        }

        this._loader.hide();
    }

    private _readValuesFromInputs(): EboxNovoSporociloRequest | null {
        if (!this._izbranoMerilnoMesto) {
            return null;
        }
        var form = $("#form-novo-sporocilo");
        var odjemnoMesto: EboxNovoSporociloOdjemnoMestoRequest = {
            merilnoMesto: this._izbranoMerilnoMesto.merilnoMesto,
            merilnaTocka: this._izbranoMerilnoMesto.merilnaTocka,
            stevilkaKontoPogodbe: this._izbranoMerilnoMesto.stevilkaKontoPogodbe
        };
        return {
            odjemnoMesto: odjemnoMesto,
            zadeva: form.find("[name=zadeva]").val() as string,
            vsebinaSporocila: form.find("[name=novo-sporocilo-floating-textarea]").val() as string
        };
    }

    private _createFormData(): FormData {
        var inputsData = this._readValuesFromInputs();
        if (!inputsData || !this._izbranoMerilnoMesto) {
            return new FormData;
        }
        var formData = new FormData();
        formData.set("odjemnoMesto", JSON.stringify(inputsData.odjemnoMesto));
        formData.set("zadeva", inputsData.zadeva);
        formData.set("vsebinaSporocila", inputsData.vsebinaSporocila);
        for (var i = 0; i < this._fileList.length; i++) {
            formData.append("priloge", this._fileList[i]);
        }
        return formData;
    }

    private _renderNovoSporociloModal(): void {
        const viewModel = {
            textLengthLimit: this._textLengthLimit
        } as any;
        var htmlTemplate = novoSporociloModal;
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#EboxNovoSporocilo').html(html);
    }

    private async _onBtnDodajSporociloClick(ev: Event) {
        ev.preventDefault();
        if (!this._izbranoMerilnoMesto) return;
        var zadeva = $("#zadeva").val() as string;
        var vsebina = $("#vsebina-sporocila").val() as string;
        if (vsebina == "" || zadeva == "") {
            this._notyf.error("Morate vpisati zadevo in vsebino sporočila.");
            return;
        }
        this._loader.show();
        var odjemnoMesto: EboxNovoSporociloOdjemnoMestoRequest = {
            merilnoMesto: this._izbranoMerilnoMesto.merilnoMesto,
            merilnaTocka: this._izbranoMerilnoMesto.merilnaTocka,
            stevilkaKontoPogodbe: this._izbranoMerilnoMesto.stevilkaKontoPogodbe
        };
        var formData = new FormData();
        formData.set("odjemnoMesto", JSON.stringify(odjemnoMesto));
        formData.set("zadeva", zadeva);
        formData.set("vsebinaSporocila", vsebina);
        for (var i = 0; i < this._fileList.length; i++) {
            formData.append("priloge", this._fileList[i]);
        }
        var uspesno = false;
        await this._kontaktiInPomocApiClient.eboxNovoSporocilo(formData)
            .then((odgovor: any) => {
                if (odgovor.status) {
                    uspesno = true;
                }
                else {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                }
            })
            .catch((ex: any) => {
                if (ex.statusCode == 400) {
                    this._loader.hide();
                    this._notyf.error(this._translationService.currentTranslations["SaveError"] + "\n" + ex.message);
                }
                else {
                    this._loader.hide();
                    this._notyf.error(this._translationService.currentTranslations["SaveError"]);
                }
            });
        if (uspesno) {
            await this._getSporocila(this._izbranoMerilnoMesto, null, null);
            await this._renderSporocila();
            $("#zadeva").val("");
            $("#vsebina-sporocila").val("");
            this._notyf.success(this._translationService.currentTranslations["SaveSuccessful"]);
            this._fileList = new Array<File>();
            this._fileViewList = new Array<any>();
            // this._renderAttachments(true); mozda false
        }
        this._loader.hide();
    }

    private async _onFilterChange() {
        var filterMerilnoMestoDropdown = $("#filter-merilno-mesto-dropdown");
        var formElement = $(("#filter-form"));
        var formData = new FormData(formElement[0] as HTMLFormElement);
        var obdobjeOdString = formData.get("obdobjeOd") as string;
        var obdobjeDoString = formData.get("obdobjeDo") as string;
        var obdobjeOd = this._convertStringListToDateTime(obdobjeOdString);
        var obdobjeDo = this._convertStringListToDateTime(obdobjeDoString);
        var dropdownValue = filterMerilnoMestoDropdown.val() as string;
        this._izbranoMerilnoMesto = this._getMerilnoMestoFromDropdown(dropdownValue);
        await this._getSporocila(this._izbranoMerilnoMesto, obdobjeOd, obdobjeDo);
        await this._renderSporocila();
    }
    
    private async _getSporocila(izbranoMerilnoMesto: any, obdobjeOd: any, obdobjeDo: any) {
        this._loader.show();
        var data: EboxSporocilaRequest = {
            vlogaOdjemnegaMesta: "", // vrnemo vsa sporočila ("" - vsa, "R" - prejeta, "S" - poslana)
            merilnoMesto: izbranoMerilnoMesto.merilnoMesto,
            merilnaTocka: izbranoMerilnoMesto.merilnaTocka,
            stevilkaKontoPogodbe: izbranoMerilnoMesto.stevilkaKontoPogodbe,
            brezPriloge: false,
            arhivirano: false,
            obdobjeOd: obdobjeOd,
            obdobjeDo: obdobjeDo
        };

        // Filter arhivirana / nearhivirana
        var dropdownPrejetaPoslana = $("#dropdown-prejeta-poslana");
        if (dropdownPrejetaPoslana.val() == "4") {
            data.arhivirano = true;
        }
        else {
            data.arhivirano = false;
        }

        await this._kontaktiInPomocApiClient.eboxSporocila(data)
            .then((odgovor: EboxSporocilaResponse) => {
                if (odgovor.sporocila) {
                    // Prejeta sporocila
                    var prejeta = odgovor.sporocila.filter(a => a.glava.vlogaOdjemnegaMesta == "R");
                    if (prejeta) {
                        this._eboxSporocila.prejetaSporocila = prejeta;
                    }
                    // Poslana sporocila
                    var poslana = odgovor.sporocila.filter(a => a.glava.vlogaOdjemnegaMesta == "S");
                    if (poslana) {
                        this._eboxSporocila.poslanaSporocila = poslana;
                    }
                    // Vsa sporocila
                    this._eboxSporocila.vsaSporocila = odgovor.sporocila;
                }
                else {
                    this._eboxSporocila.vsaSporocila = [];
                    this._eboxSporocila.poslanaSporocila = [];
                    this._eboxSporocila.prejetaSporocila = [];
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
        this._loader.hide();
    }

    private _convertStringListToDateTime(obdobjeString: string) {
        if (obdobjeString) {
            var obdobjeSplit = obdobjeString.split(".");
            var obdobje = new Date(Number(obdobjeSplit[2]), Number(obdobjeSplit[1]) - 1, Number(obdobjeSplit[0]));
            return obdobje;
        }
        return undefined;
    }

    private async _renderSporocila() {
        this._loader.show();
        var sporocila: any[] | null | undefined = null;

        // Filter poslana / prejeta
        var dropdownPrejetaPoslana = $("#dropdown-prejeta-poslana");
        if (dropdownPrejetaPoslana.val() == "1") {
            sporocila = this._eboxSporocila.vsaSporocila;
        }
        else if (dropdownPrejetaPoslana.val() == "2") {
            sporocila = this._eboxSporocila.poslanaSporocila;
        }
        else if (dropdownPrejetaPoslana.val() == "3") {
            sporocila = this._eboxSporocila.prejetaSporocila;
        }
        else if (dropdownPrejetaPoslana.val() == "4") {
            sporocila = this._eboxSporocila.vsaSporocila;
        }

        // Filter prebrana / neprebrana
        var dropdownPrebranaNeprebana = $("#dropdown-prebrana-neprebrana");
        if (dropdownPrebranaNeprebana.val() == "2") {
            sporocila = sporocila?.filter(a => a.glava.pogovorPrebranStranka == false);
        }
        else if (dropdownPrebranaNeprebana.val() == "3") {
            sporocila = sporocila?.filter(a => a.glava.pogovorPrebranStranka == true);
        }

        const viewModel = {
            formators: this._formators,
            sporocila: sporocila,
            obstajaSporocilo: sporocila != null && sporocila.length > 0
        } as any;

        const html = Mustache.render(eboxSporocilaWidget, viewModel);
        $("#widget-ebox-sporocila").html(html);

        var zadevaSporocila = $(".zadeva-sporocila");
        for (let i = 0; i < zadevaSporocila.length; i++) {
            zadevaSporocila[i].addEventListener("click", async (ev: Event) => {
                ev.preventDefault();
                this._izbranoSporociloIndex = i;
                this._izbranoSporocilo = sporocila != null ? sporocila[i] : null;
                this._fileList = new Array<File>();
                this._fileViewList = new Array<any>();
                await this._onZadevaSporocilaClick();
            });
        }

        // uporabnik je kliknil na sporocilo iz dashboarda
        if (sporocila != null && sporocila.length > 0) {
            if (this._threadId != null) {
                this._izbranoSporocilo = sporocila.find(a => a.glava.threadId == this._threadId);
                this._izbranoSporociloIndex = sporocila.findIndex(a => a.glava.threadId == this._threadId);
                $(".zadeva-div")[this._izbranoSporociloIndex].click(); // odpremo pogovora za izbrano sporočilo
                this._threadId = null; // resetiramo threadId
            }
        }

        var izbrisiSporociloButtons = $(".btn-izbrisi-sporocilo");
        for (let i = 0; i < izbrisiSporociloButtons.length; i++) {
            izbrisiSporociloButtons[i].addEventListener("click", async (ev: Event) => {
                var threadId = Number(izbrisiSporociloButtons[i].dataset["threadid"] as string);
                await this._onIzbrisiAliArhivirajSporocilaClick(ev, true, threadId);
            });
        }

        var arhivirajSporociloButtons = $(".btn-arhiviraj-sporocilo");
        for (let i = 0; i < arhivirajSporociloButtons.length; i++) {
            arhivirajSporociloButtons[i].addEventListener("click", async (ev: Event) => {
                var threadId = Number(arhivirajSporociloButtons[i].dataset["threadid"] as string);
                await this._onIzbrisiAliArhivirajSporocilaClick(ev, false, threadId);
            });
        }

        var oznaciPrebranoSporociloButtons = $(".btn-oznaci-kot-prebrano-sporocilo");
        for (let i = 0; i < oznaciPrebranoSporociloButtons.length; i++) {
            oznaciPrebranoSporociloButtons[i].addEventListener("click", async (ev: Event) => {
                var threadId = Number(oznaciPrebranoSporociloButtons[i].dataset["threadid"] as string);
                await this._onOznaciPogovorKotPrebranClick(ev, true, threadId);
            });
        }

        var oznaciNeprebranoSporociloButtons = $(".btn-oznaci-kot-neprebrano-sporocilo");
        for (let i = 0; i < oznaciNeprebranoSporociloButtons.length; i++) {
            oznaciNeprebranoSporociloButtons[i].addEventListener("click", async (ev: Event) => {
                var threadId = Number(oznaciNeprebranoSporociloButtons[i].dataset["threadid"] as string);
                await this._onOznaciPogovorKotPrebranClick(ev, false, threadId);
            });
        }

        this._loader.hide();
    }

    private async _onOznaciPogovorKotPrebranClick(ev: Event, prebrano: boolean, threadId: number) {
        ev.preventDefault();
        this._loader.show();    
        var uspesno = await this._oznaciPogovorPrebran(threadId, prebrano);
        if (uspesno) {
            var message = prebrano ? "Pogovor je označen kot prebran" : "Pogovor je označen kot neprebran";
            this._notyf.success(message);
            await this._getSporocila(this._izbranoMerilnoMesto, null, null);
            await this._renderSporocila();
        }
        this._loader.hide();
    }

    private async _onIzbrisiAliArhivirajSporocilaClick(ev: Event, izbrisiSporocila: boolean, threadId: number) {
        ev.preventDefault();
        var threadIds = new Array<number>();
        threadIds.push(threadId);
        var request: EboxUpravljajPogovorRequest = {
            opravilo: "",
            threadIds: threadIds
        };
        if (izbrisiSporocila) {
            request.opravilo = "DEL";
        }
        else {
            request.opravilo = "ARCH";
        }
        var uspesno = false;
        await this._kontaktiInPomocApiClient.eboxUpravljajPogovor(request)
            .then(async (odgovor) => {
                if (!odgovor.status) {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    return;
                }
                else {
                    uspesno = true;
                    if (izbrisiSporocila) {
                        this._notyf.success("Pogovor je izbrisan");
                    }
                    else {
                        this._notyf.success("Pogovor je arhiviran");
                    }
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                return;
            });
        if (uspesno) {
            await this._getSporocila(this._izbranoMerilnoMesto, null, null);
            await this._renderSporocila();
        }
    }

    private async _onZadevaSporocilaClick() {
        this._loader.show();
        var threadId = Number($(".zadeva-sporocila")[this._izbranoSporociloIndex].dataset["threadid"] as string);
        var pogovorPrebran = $(".zadeva-div")[this._izbranoSporociloIndex].dataset["pogovorprebran"] as string;
        // označimo pogovor kot prebran
        if (pogovorPrebran == "false") {
            await this._oznaciPogovorPrebran(threadId, true);
            $(".zadeva-div")[this._izbranoSporociloIndex].dataset["pogovorprebran"] = "true";
        }
        // prikažemo pogovora
        await this._prikaziPogovor(threadId);
        this._loader.hide();
    }

    private async _prikaziPogovor(threadId: number) {
        var eboxPogovorRequest: EboxPogovorRequest = {
            threadId: threadId
        };
        await this._kontaktiInPomocApiClient.eboxPogovor(eboxPogovorRequest)
            .then((odgovor: EboxPogovorResponse) => {
                if (odgovor.status) {
                    this._pogovor = odgovor;
                }
                else {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    return;
                }
            });
        if (this._pogovor != null) {
            var pogovor = this._pogovor.pogovor;
            if (pogovor != null && pogovor.vsebina != null && pogovor.vsebina.length > 0) {
                pogovor.vsebina = pogovor.vsebina.reverse();
            }

            const viewModel = {
                obstajaPogovor: pogovor != null,
                glava: pogovor != null ? pogovor.glava : null,
                vsebina: pogovor != null ? pogovor.vsebina : null,
                zadeva: this._izbranoSporocilo != null ? this._izbranoSporocilo.vsebina.zadeva : null,
                datum: this._izbranoSporocilo != null ? this._izbranoSporocilo.vsebina.kreiranoDan : null,
                ura: this._izbranoSporocilo != null ? this._izbranoSporocilo.vsebina.kreiranoUra : null,
                formators: this._formators,
                textLengthLimit: this._textLengthLimit
            } as any;
            const html = Mustache.render(eboxPogovorWidget, viewModel);
            var id = "#widget-ebox-pogovor-" + this._izbranoSporocilo.glava.threadId;
            $(id).html(html);

            var buttonsDownloadPdf = $(".download-pdf");
            for (let i = 0; i < buttonsDownloadPdf.length; i++) {
                buttonsDownloadPdf[i].addEventListener("click", (ev: Event) => {
                    ev.preventDefault();
                    var threadId = Number(buttonsDownloadPdf[i].dataset["threadid"] as string);
                    var vsebinaId = Number(buttonsDownloadPdf[i].dataset["vsebinaid"] as string);
                    var postavka = Number(buttonsDownloadPdf[i].dataset["postavka"] as string);
                    var naziv = buttonsDownloadPdf[i].dataset["naziv"] as string;
                    this._onBtnDokumentClick(naziv, threadId, vsebinaId, postavka);
                });
            }

            var btnBackToSporocila = $("#btn-back-to-sporocila");
            btnBackToSporocila.on("click", () => {
                var id = "#widget-ebox-pogovor-" + this._izbranoSporocilo.glava.threadId;
                $(id).html("");
                this._fileList = new Array<File>();
                this._fileViewList = new Array<any>();
            });

            var trashDeleteSporocilo = $("#trash-delete-sporocilo");
            trashDeleteSporocilo.on("click", async (ev: Event) => {
                var threadId = Number($("#trash-delete-sporocilo")[0].dataset["threadid"] as string);
                await this._onIzbrisiAliArhivirajSporocilaClick(ev, true, threadId);
            });

            if (pogovor.glava.mozenOdgovor) {
                var btnPriponkePogovor = $("#attachments-pogovor-svg-div");
                btnPriponkePogovor.on("click", () => {
                    $("#attachments-odgovor-na-sporocilo")[0].click();
                });

                var btnOdgovorPoslji = $("#btn-odgovor-poslji");
                btnOdgovorPoslji.on("click", (ev: Event) => {
                    this._onPosljiOdgovorClick(ev, threadId);
                });
                this._renderAttachments(true);
            } 
        }
    }

    private async _onPosljiOdgovorClick(ev: Event, threadId: number) {
        ev.preventDefault();
        var vsebinaSporocila = $("#odgovor-sporocilo-floating-textarea").val() as string;
        if (vsebinaSporocila == "") {
            this._notyf.error("Prosimo vnesite vsebino sporočila.");
            return;
        }
        this._loader.show();
        var formData = new FormData();
        formData.set("threadId", threadId.toString());
        formData.set("vsebinaSporocila", vsebinaSporocila);
        for (var i = 0; i < this._fileList.length; i++) {
            formData.append("priloge", this._fileList[i]);
        }
        var odgovorUspesen = false;
        await this._kontaktiInPomocApiClient.eboxOdgovorNaSporocilo(formData)
            .then((odgovor: any) => {
                if (odgovor.status) {
                    this._loader.hide();
                    odgovorUspesen = true;
                    this._notyf.success(this._translationService.currentTranslations.SaveSuccessful);
                }
                else {
                    this._notyf.error("Prišlo je do napake pri pošiljanju odgovora.");
                }
            })
            .catch((ex: any) => {
                if (ex.statusCode == 400) {
                    this._loader.hide();
                    this._notyf.error(this._translationService.currentTranslations.SaveError + "\n" + ex.message);
                }
                else {
                    this._loader.hide();
                    this._notyf.error(this._translationService.currentTranslations.SaveError);
                }
            });
        if (odgovorUspesen) {
            await this._getSporocila(this._izbranoMerilnoMesto, null, null);
            this._fileList = new Array<File>();
            this._fileViewList = new Array<any>();
            await this._prikaziPogovor(threadId);
        }
    }

    private async _oznaciPogovorPrebran(threadId: number, pogovorPrebran: boolean) {
        var threadIds = new Array<number>;
        threadIds.push(threadId);
        var pogovorPrebranRequest: EboxPogovorPrebranRequest = {
            threadIds: threadIds,
            pogovorPrebran: pogovorPrebran
        };
        var uspesno = false;
        await this._kontaktiInPomocApiClient.eboxPogovorPrebran(pogovorPrebranRequest)
            .then(async (odgovor) => {
                if (odgovor.status) {
                    uspesno = true;
                }
                else {
                    this._notyf.error(odgovor.sporocilo);
                    return false;
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                return;
            });
        if (uspesno) {
            await this.updateMenu();
            return uspesno;
        }
    }

    private async updateMenu() {
        await this._kontaktiInPomocApiClient.eboxSporocilaHeader()
            .then((odgovor) => {
                var msgsNo = $("#msgs-no-mobile");
                if (msgsNo) {
                    msgsNo[0].innerHTML = odgovor.steviloNeprebranihSporocil.toString();
                    var btnSporocila = $("#btnSporocilaMobile");
                    if (odgovor.steviloNeprebranihSporocil > 0) {
                        btnSporocila.on("click", () => {
                            if (odgovor.jeElektrika) {
                                window.location.href = "#/kontakti/ebox/pogovor?" + odgovor.threadId + "_"
                                    + odgovor.merilnaTocka + "_true_" + odgovor.merilnoMesto + "_" +
                                    odgovor.stevilkaKontoPogodbe;
                            }
                            else {
                                window.location.href = "#/kontakti/ebox/pogovor?" + odgovor.threadId + "_"
                                    + "_false_" + odgovor.merilnoMesto + "_" + odgovor.stevilkaKontoPogodbe;
                            }
                        });
                        msgsNo.show();
                    }
                    else {
                        btnSporocila.on("click", () => {
                            window.location.href = "#/kontakti/ebox";
                        });
                        msgsNo.hide();
                    }
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSearchingForNotifications"]);
            });
    }

    private _onBtnDokumentClick(nazivDokumenta: string, threadId: number, vsebinaId: number, postavkaDokumenta: number) {
        var translations = this._translationService.currentTranslations;
        var downloadingNotyf = this._notyf.open({
            type: "info",
            message: translations.Downloading,
            duration: 50000
        });
        var eboxPrenosDokumentaRequest: EboxPrenosDokumentaRequest = {
            threadId: threadId,
            vsebinaId: vsebinaId,
            postavkaDokumenta: postavkaDokumenta,
            sistemId: "EST"
        };
        this._kontaktiInPomocApiClient.eboxPrenosDokumenta(eboxPrenosDokumentaRequest)
            .then((fileContent) => {
                var blob = new Blob([fileContent.fileContent], { type: fileContent.fileType });
                var url = URL.createObjectURL(blob);

                var fileName: string = fileContent.fileName;
                var documentName = nazivDokumenta;
                if (!fileName) {
                    fileName = documentName;
                }

                var fileLink = document.createElement('a');
                fileLink.href = url;
                fileLink.download = fileName;
                fileLink.click();

            })
            .catch(() => {
                this._notyf.error(translations.ErrorWhileDownloadingFile);
            }).finally(() => {
                this._notyf.dismiss(downloadingNotyf);
            });
    }
}
