import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { EnergetskaIzkaznicaResponse } from "../ts/models/energetska-izkaznica-response";
import { EnergetskaIzkaznicaRequest } from "../ts/models/energetska-izkaznica.ts-request";
import { MerilnoMesto } from "../ts/models/merilno-mesto";
import { UporabnikMerilnoMestoRequest } from "../ts/models/uporabnik-merilno-mesto-request";
import { UporabnikMerilnoMestoResponse } from "../ts/models/uporabnik-merilno-mesto-response";
import { VrstaEnergenta } from "../ts/models/vrsta-energenta-enum";
import { TranslationService } from "../ts/translation-service";
import template from "./merilno-mesto-poraba-energetska-izkaznica-view.html";

@injectable()
export class MerilnoMestoPorabaEnergetskaIzkaznicaView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _izbranoMerilnoMesto: MerilnoMesto | null | undefined;
    private _dropdownMerilnaMestaElement: JQuery<HTMLElement> | null;
    private _btnPregledElement: JQuery<HTMLElement> | null;
    private _btnPrenesiteInformacijoOPorabiElement: JQuery<HTMLElement> | null;
    private _merilnaMesta: Array<MerilnoMesto> | null;
    private _btnMerilnaMestaOthers: JQuery<HTMLElement> | null;
    private _porabaSeznam: Array<any>;
    private _limit: number;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        merilnoMestoApiClient: MerilnoMestoApiClient,
        private _notyf: Notyf)
    {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._izbranoMerilnoMesto = null;
        this._dropdownMerilnaMestaElement = null;
        this._btnPregledElement = null;
        this._btnPrenesiteInformacijoOPorabiElement = null;
        this._merilnaMesta = null;
        this._btnMerilnaMestaOthers = null;
        this._porabaSeznam = new Array<any>();
        this._limit = 10;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;
            var uporabnikMerilnoMestoRequest: UporabnikMerilnoMestoRequest = {
                limit: 0,
                stevilkaStrani: 0,
                saldo: false,
                skupnaPorabaEnergije: false,
                merilnoMesto: "",
                merilnaTocka: ""
            };
            await this._merilnoMestoApiClient.getUporabnikMerilnaMesta(uporabnikMerilnoMestoRequest)
                .then((odgovor: UporabnikMerilnoMestoResponse) => {
                    this._merilnaMesta = odgovor.merilnaMesta;
                    if (this._merilnaMesta && this._merilnaMesta.length > 0) {
                        // poiscemo ali obstaja selektirano MM (v querystringu)
                        var url_string = window.location.href.split("?");
                        var url_data = url_string[url_string.length - 1];
                        var data_list = url_data.split("_");
                        const stevilkaKontoPogodbe = data_list[data_list.length - 1];
                        const merilnoMesto = data_list[data_list.length - 2];
                        const jeElektrika = data_list[data_list.length - 3];
                        const merilnaTocka = data_list[data_list.length - 4];
                        
                        if (jeElektrika == 'true') {
                            this._izbranoMerilnoMesto = this._merilnaMesta.find(
                                a => a.merilnoMesto == merilnoMesto && a.merilnaTocka == merilnaTocka && a.stevilkaKontoPogodbe == stevilkaKontoPogodbe);
                        }
                        else if (jeElektrika == 'false') {
                            this._izbranoMerilnoMesto = this._merilnaMesta.find(
                                a => a.merilnoMesto == merilnoMesto && a.stevilkaKontoPogodbe == stevilkaKontoPogodbe);
                        }

                        // ce v querystringu ni doloceno MM, selektiramo prvega MM iz liste
                        if (!this._izbranoMerilnoMesto) {
                            this._izbranoMerilnoMesto = this._merilnaMesta[0];
                        }
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSearchingForMeasuringPoints"]);
                });

            this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;
            if (this._izbranoMerilnoMesto) {
                this._initForm();
            }
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(translations: any): void {
        var merilnaMestaIds = new Array<string>();
        var prikazanaMerilnaMesta = new Array<string>();
        var steviloPrikazanih = 3;
        var leta = new Array<number>();
        if (this._merilnaMesta) {
            merilnaMestaIds = Array.from(new Set(this._merilnaMesta.map((item) => item.merilnoMesto)));
            prikazanaMerilnaMesta = merilnaMestaIds.slice(0, steviloPrikazanih);
            if (this._merilnaMesta.length < steviloPrikazanih) {
                prikazanaMerilnaMesta = merilnaMestaIds;
            }
            var now = new Date();
            // V dropwdown dodamo zadnja 10 leta
            for (let i = now.getFullYear(); i >= now.getFullYear() - 10; i--) {
                leta.push(i);
            }
        }
        
        const viewModel = {
            merilnaMestaIds: merilnaMestaIds,
            merilnaMesta: this._merilnaMesta,
            izbranoMerilnoMesto: this._izbranoMerilnoMesto,
            ostalaMerilnaMestaStevilo: merilnaMestaIds.length - prikazanaMerilnaMesta.length,
            obstajajoOstalaMerilnaMesta: merilnaMestaIds.length - prikazanaMerilnaMesta.length > 0 ? true : false,
            prikazanaMerilnaMesta: prikazanaMerilnaMesta,  
            leta: leta,
            translations: translations,
            obstajaMerilnoMesto: this._izbranoMerilnoMesto ? true : false
        } as any;
        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _initForm(): void {
        if (!this._izbranoMerilnoMesto) {
            return;
        }
        this._dropdownMerilnaMestaElement = $("#dropdownMerilnaMesta");
        this._dropdownMerilnaMestaElement.on("change", (ev: Event) => this._onDropDownMerilnoMestoChange(ev));
        if (this._izbranoMerilnoMesto.jeElektrika) {
            this._dropdownMerilnaMestaElement.val(this._izbranoMerilnoMesto.jeElektrika + "_" + this._izbranoMerilnoMesto.stevilkaKontoPogodbe + "_" + this._izbranoMerilnoMesto.merilnoMesto + "_" + this._izbranoMerilnoMesto.merilnaTocka);
        }
        else {
            this._dropdownMerilnaMestaElement.val(this._izbranoMerilnoMesto.jeElektrika + "_" + this._izbranoMerilnoMesto.stevilkaKontoPogodbe + "_" + this._izbranoMerilnoMesto.merilnoMesto);
        }
        this._btnPregledElement = $("#btnPregled");
        this._btnPregledElement.on("click", (ev: Event) => this._onBtnPregledClick(ev));
        this._btnMerilnaMestaOthers = $("#btnMerilnaMestaOthers");
        this._btnMerilnaMestaOthers.on("click", (ev: Event) => this._onBtnMerilnaMestaOthersClick(ev));
        this._btnPrenesiteInformacijoOPorabiElement = $("#btn-prenesite-informacijo-o-porabi");
        this._btnPrenesiteInformacijoOPorabiElement.on("click", (ev: Event) => this._onBtnPrenesiteInfoOPorabiClick(ev));
    }

    private _onDropDownMerilnoMestoChange(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        this._dropdownMerilnaMestaElement = $("#dropdownMerilnaMesta");

        var dropdownMerilnoMestoValues = (this._dropdownMerilnaMestaElement[0] as any).value.split("_");

        var selectedJeElektrika = dropdownMerilnoMestoValues[0]
        var selectedStevilkaKontoPogodbe = dropdownMerilnoMestoValues[1]
        var selectedMerilnoMesto = dropdownMerilnoMestoValues[2]         


        if (selectedJeElektrika == 'true') {
            var selectedMerilnaTocka = dropdownMerilnoMestoValues[3]
            location.href = "#/merilno-mesto/energetska-izkaznica?" + selectedMerilnaTocka + "_" + selectedJeElektrika + "_" + selectedMerilnoMesto + "_" + selectedStevilkaKontoPogodbe
        }
            
        else
            location.href = "#/merilno-mesto/energetska-izkaznica?" + selectedJeElektrika + "_" + selectedMerilnoMesto + "_" + selectedStevilkaKontoPogodbe
    }

    private async _onBtnPrenesiteInfoOPorabiClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        if (!this._izbranoMerilnoMesto) return;
        var downloadingNotyf = this._notyf.open({
            type: "info",
            message: this._translationService.currentTranslations["Downloading"],
            duration: 50000
        });
        await this._merilnoMestoApiClient.energetskaIzkaznicaPorabaDownload(this._porabaSeznam, this._izbranoMerilnoMesto.jeElektrika)
            .then((response) => {               
                const downloadUrl = response.downloadUrl;

                const fileLink = document.createElement('a');
                fileLink.href = downloadUrl;
                fileLink.click();
            })
            //.then((fileContent) => {
            //    var blob = new Blob([fileContent.fileContent], { type: fileContent.fileType });
            //    var url = URL.createObjectURL(blob);
            //    var fileName: string = fileContent.fileName;
            //    var documentName = "energetska_izkaznica_poraba" + ".xlsx";
            //    if (!fileName) {
            //        fileName = documentName;
            //    }
            //    var fileLink = document.createElement('a');
            //    fileLink.href = url;
            //    fileLink.download = fileName;
            //    fileLink.click();
            //})
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["ErrorWhileDownloadingFile"]);
            }).finally(() => {
                this._notyf.dismiss(downloadingNotyf);
            });
    }

    private _onBtnPregledClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        this._showLoader();
        if (this._izbranoMerilnoMesto != null) {
            var letoOd = $(".letoOd").val();
            var letoDo = $(".letoDo").val();
            var energetskaIzkaznicaRequest: EnergetskaIzkaznicaRequest = {
                vrstaEnergenta: this._izbranoMerilnoMesto.jeElektrika ? VrstaEnergenta.Elektrika : VrstaEnergenta.Plin,
                merilnoMesto: this._izbranoMerilnoMesto.merilnoMesto,
                merilnaTocka: this._izbranoMerilnoMesto.merilnaTocka,
                stevilkaKontoPogodbe: this._izbranoMerilnoMesto.stevilkaKontoPogodbe,
                letoOd: !isNaN(Number(letoOd)) ? Number(letoOd) : null,
                letoDo: !isNaN(Number(letoDo)) ? Number(letoDo) : null     
            };
            this._merilnoMestoApiClient.getEnergetskaIzkaznica(energetskaIzkaznicaRequest)
                .then((odgovor: EnergetskaIzkaznicaResponse) => {
                    if (odgovor.status) {
                        if (odgovor.poraba && odgovor.poraba.length > 0) {
                            // renderiramo tabelo (za mobile in desktop)
                            this._refreshTables(odgovor.poraba, 1);
                            if (this._btnPrenesiteInformacijoOPorabiElement) {
                                this._btnPrenesiteInformacijoOPorabiElement.show();
                            }
                            this._porabaSeznam = odgovor.poraba;
                            $(".poraba-ni-podatkov")[0].style.display = "none";
                            $(".spinner-grow").hide();
                        }
                        else {
                            $(".poraba-ni-podatkov")[0].style.display = "block";
                            if ($(".pagination") != null) {
                                $(".pagination").hide();
                            }
                            $(".spinner-grow").hide();
                        }
                    }
                    else {
                        this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSearchingForAnEnergyCard"]);
                        $(".spinner-grow").hide();
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSearchingForAnEnergyCard"]);
                    $(".spinner-grow").hide();
                }); 
        }
    }

    private _refreshTables(seznam: any, page: number) {
        this._refreshPorabaMobile(seznam, page);
        this._refreshPorabaDesktop(seznam, page);
        $(".tabela-desktop").addClass("d-lg-block");
        $(".tabela-desktop").removeClass("d-lg-none");
        $(".tabela-mobile").addClass("d-block");
        $(".tabela-mobile").removeClass("d-none");
        this._refreshPagination(seznam, page);
    }

    private _onBtnMerilnaMestaOthersClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        $(".merilna-mesta-not-all")[0].style.display = "none";
        $(".merilna-mesta-all")[0].style.display = "block";
    }

    private _refreshPorabaMobile(porabaSeznam: any, page: number) {
        var displayedPoraba = new Array<any>();
        for (let i = (page - 1) * this._limit; i < page * this._limit; i++) {
            if (i >= porabaSeznam.length) {
                break;
            }
            displayedPoraba.push(porabaSeznam[i])
        }
        const templateString = `
            <%#obstajaElement%>
            <h3 class="margin-small"><%{translations.Consumption}%></h3>
            <table class="table energetska-izkaznica-mobile">    
                <tbody>
                    <%#porabaSeznam%>
                    <tr>
                        <td class="table-inner-wrap">
                            <div class="table-responsive">
                                <table class="table table-borderless">
                                    <tbody>
                                        <tr>
                                            <th scope="row"><%{translations.Year}%></th>
                                            <td><%{leto}%></td>
                                        </tr>
                                        <%#jeElektrika%>
                                            <tr>
                                                <th scope="row">VT</th>
                                                <td><%{vt}%> <%{enotaMere}%></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">MT</th>
                                                <td><%{mt}%> <%{enotaMere}%></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">ET</th>
                                                <td><%{et}%> <%{enotaMere}%></td>
                                            </tr>
                                        <%/jeElektrika%>
                                        <tr>
                                            <th scope="row"><%{translations.TotalConsumption}%></th>
                                            <td><%{skupaj}%> <%{enotaMere}%></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>
                <%/porabaSeznam%>
                </tbody>
            </table> 
            <%/obstajaElement%>
            <%^obstajaElement%>
            <div><%{translations.NoData}%></div>
            <%/obstajaElement%>
             `;
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "jeElektrika": this._izbranoMerilnoMesto?.jeElektrika,
                "obstajaElement": displayedPoraba.length > 0 ? true : false,
                "porabaSeznam": displayedPoraba,
                "translations": this._translationService.currentTranslations
            },
            undefined,
            customTags
        );
        $(".tabela-mobile").html(html);
    }

    private _refreshPorabaDesktop(porabaSeznam: any, page: number) {
        var displayedPoraba = new Array<any>();
        for (let i = (page - 1) * this._limit; i < page * this._limit; i++) {
            if (i >= porabaSeznam.length) {
                break;
            }
            displayedPoraba.push(porabaSeznam[i])
        }
        const templateString = `
            <%#obstajaElement%>
            <h3 class="margin-small"><%{translations.Consumption}%></h3>
            <table class="table energetska-izkaznica">
                <thead>
                    <tr>
                        <td scope="col"><%{translations.Year}%></td>
                        <%#jeElektrika%>
                            <td scope="col">VT</td>
                            <td scope="col">MT</td>
                            <td scope="col">ET</td>
                        <%/jeElektrika%>
                        <td scope="col"><%{translations.TotalConsumption}%></td>
                    </tr>
                </thead>
                <tbody>
                    <%#porabaSeznam%>
                    <tr>
                        <td scope="row"><%{leto}%></td>
                        <%#jeElektrika%>
                            <td><%{vt}%> <%{enotaMere}%></td>
                            <td><%{mt}%> <%{enotaMere}%></td>
                            <td><%{et}%> <%{enotaMere}%></td>
                        <%/jeElektrika%>
                        <td><%{skupaj}%> <%{enotaMere}%></td>
                    </tr>
                <%/porabaSeznam%>
                </tbody>
            </table>
            <%/obstajaElement%>
            <%^obstajaElement%>
            <div><%{translations.NoData}%></div>
            <%/obstajaElement%>
             `;
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "jeElektrika": this._izbranoMerilnoMesto?.jeElektrika,
                "obstajaElement": displayedPoraba.length > 0 ? true : false,
                "porabaSeznam": displayedPoraba,
                "translations": this._translationService.currentTranslations
            },
            undefined,
            customTags
        );
        $(".tabela-desktop").html(html);
    }

    private _refreshPagination(seznam: any, page: number) {
        if (seznam.length <= 0) {
            $("#pagination")[0].style.display = "none";
            return;
        }
        const templateString = `
             <div class="pagination">
                <%#obstajaPrejsnjaStran%>
                <div>
                    <a class="btnPreviousPage">&lt</a>
                </div>
                <%/obstajaPrejsnjaStran%>
                <div class="pages"><%{page}%>/<%{maxPage}%></div>
                <%#obstajaNaslednjaStran%>
                <div>
                    <a class="btnNextPage">&gt</a>
                </div>
                <%/obstajaNaslednjaStran%>
            </div>
        `;
        var customTags: any = ['<%', '%>'];
        var maxPage = Math.ceil(seznam.length / this._limit);
        var html = Mustache.render(
            templateString,
            {
                "page": page,
                "maxPage": maxPage,
                "obstajaPrejsnjaStran": page != 1 ? true : false,
                "obstajaNaslednjaStran": page != maxPage ? true : false
            },
            undefined,
            customTags
        );
        $("#pagination")[0].style.display = "block";
        $("#pagination").html(html);
        $(".btnPreviousPage").on("click", (ev: Event) => this._onBtnPageChangeClick(ev, true, seznam));
        $(".btnNextPage").on("click", (ev: Event) => this._onBtnPageChangeClick(ev, false, seznam));
    }

    private async _onBtnPageChangeClick(ev: Event, previousClicked: boolean, seznam: any) {
        console.debug(ev);
        ev.preventDefault();
        this._showLoader();
        var pagesString = $(".pages")[0].innerHTML;
        var currentPage = Number(pagesString.split("/")[0]);
        if (previousClicked) {
            currentPage -= 1;
        }
        else {
            currentPage += 1;
        }
        this._refreshTables(seznam, currentPage);
    }

    private _showLoader() {
        this.rootElement.find(".table-responsive").html(`<div class="text-center"><div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status"></div></div>`);
    }
}