import { injectable } from "inversify";
import * as Mustache from "mustache";
import { IntranetApiClient } from "../ts/clients/intranet-api-client";
import { TranslationService } from "../ts/translation-service";
import template from "./admin-dashboard-view.html";

@injectable()
export class AdminDashboardView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _intranetApiClient: IntranetApiClient;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        intranetApiClient: IntranetApiClient) {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._intranetApiClient = intranetApiClient;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;
            this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;
            this._initForm();
        } catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(translations: any): void {
        const viewModel = {
            translations: translations
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _initForm(): void {
        var noviceElement = $("#novice");
        noviceElement.on("click", () => this._onNoviceClick());
    }

    private async _onNoviceClick() {
        await this._intranetApiClient.getNoviceUrejanjeUrl().then((data) => {
            window.open(data.url, "_blank");
        });
    }
}
