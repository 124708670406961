import { injectable } from "inversify";
import * as moment from "moment";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { TranslationService } from "../ts/translation-service";
import * as DateRangePicker from 'daterangepicker';
import template from "./merilno-mesto-poraba-view.html";
import * as ApexCharts from 'apexcharts';
import { FullscreenLoader } from "./fullscreen-loader";
import porabaGrafView from "./merilno-mesto-poraba-graf-view.html";
import primerjavaGrafView from "./merilno-mesto-primerjava-graf-view.html";
import { UporabnikMerilnoMestoRequest } from "../ts/models/uporabnik-merilno-mesto-request";
import { MerilnoMesto } from "../ts/models/merilno-mesto";
import { UporabnikMerilnoMestoResponse } from "../ts/models/uporabnik-merilno-mesto-response";
import { FakturiranaPorabaOdjemnoMestoRequest, FakturiranaPorabaRequest } from "../ts/models/fakturirana-poraba-request";
import { FakturiranaPorabaElektrikaResponse } from "../ts/models/fakturirana-poraba-elektrika-response";
import { FakturiranaPorabaPlinResponse } from "../ts/models/fakturirana-poraba-plin-response";
//import { MerilnoMestoInformacijeRequest } from "../ts/models/merilno-mesto-informacije-request";
//import { MerilnoMestoInformacijeResponse } from "../ts/models/merilno-mesto-informacije-response";

@injectable()
export class MerilnoMestoPorabaView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _izbranoMerilnoMesto: MerilnoMesto | null | undefined;
    private _merilnaMesta: Array<MerilnoMesto> | null;
    private _porabaObdobjeOdPicker: DateRangePicker = null!;
    private _porabaObdobjeDoPicker: DateRangePicker = null!;
    private _poraba1PrimerjavaOdPicker: DateRangePicker = null!;
    private _poraba1PrimerjavaDoPicker: DateRangePicker = null!;
    private _poraba2PrimerjavaOdPicker: DateRangePicker = null!;
    private _poraba2PrimerjavaDoPicker: DateRangePicker = null!;
    private _limit: number;
    private _formators: any;
    private _fakturiranaPorabaElektrika: FakturiranaPorabaElektrikaResponse | null;
    private _fakturiranaPorabaPlin: FakturiranaPorabaPlinResponse | null;
    private _jeEnotarifni: boolean;
    private _jeDvotarifni: boolean;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        merilnoMestoApiClient: MerilnoMestoApiClient,
        private _notyf: Notyf,
        private _loader: FullscreenLoader) {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._izbranoMerilnoMesto = null;
        this._merilnaMesta = null;
        this._limit = 10;
        this._formators = null;
        this._fakturiranaPorabaElektrika = null;
        this._fakturiranaPorabaPlin = null;
        this._jeEnotarifni = false;
        this._jeDvotarifni = false;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;
            var uporabnikMerilnoMestoRequest: UporabnikMerilnoMestoRequest = {
                limit: 0,
                stevilkaStrani: 0,
                saldo: false,
                skupnaPorabaEnergije: false,
                merilnoMesto: "",
                merilnaTocka: ""
            };
            await this._merilnoMestoApiClient.getUporabnikMerilnaMesta(uporabnikMerilnoMestoRequest)
                .then((odgovor: UporabnikMerilnoMestoResponse) => {
                    this._merilnaMesta = odgovor.merilnaMesta;
                    if (this._merilnaMesta && this._merilnaMesta.length > 0) {
                        // poiscemo ali obstaja selektirano MM (v querystringu)
                        var url_string = window.location.href.split("?");
                        var url_data = url_string[url_string.length - 1];
                        var data_list = url_data.split("_");
                        const stevilkaKontoPogodbe = data_list[data_list.length - 1];
                        const merilnoMesto = data_list[data_list.length - 2];
                        const jeElektrika = data_list[data_list.length - 3];
                        const merilnaTocka = data_list[data_list.length - 4];
                        if (jeElektrika == 'true') {
                            this._izbranoMerilnoMesto = this._merilnaMesta.find(
                                a => a.merilnoMesto == merilnoMesto && a.merilnaTocka == merilnaTocka && a.stevilkaKontoPogodbe == stevilkaKontoPogodbe);
                        }
                        else if (jeElektrika == 'false') {
                            this._izbranoMerilnoMesto = this._merilnaMesta.find(
                                a => a.merilnoMesto == merilnoMesto && a.stevilkaKontoPogodbe == stevilkaKontoPogodbe);
                        }
                        // ce v querystringu ni doloceno MM, selektiramo prvega MM iz liste
                        if (!this._izbranoMerilnoMesto) {
                            this._izbranoMerilnoMesto = this._merilnaMesta[0];
                        }
                    }
                    else {
                        this.rootElement.text(this._translationService.currentTranslations["YouDoNotHaveAnyMeasuringPoint"]);
                        return;
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSearchingForMeasuringPoints"]);
                });

            //if (this._izbranoMerilnoMesto && this._izbranoMerilnoMesto) {
            //    var data: MerilnoMestoInformacijeRequest = {
            //        merilnoMesto: this._izbranoMerilnoMesto.merilnoMesto,
            //        merilnaTocka: this._izbranoMerilnoMesto.merilnaTocka,
            //        stevilkaKontoPogodbe: this._izbranoMerilnoMesto.stevilkaKontoPogodbe
            //    };
            //    await this._merilnoMestoApiClient.getInformacije(data)
            //        .then((odgovor: MerilnoMestoInformacijeResponse) => {
            //            if (odgovor.tehnicneInformacije && odgovor.tehnicneInformacije.naprave) {
            //                var naprava = odgovor.tehnicneInformacije.naprave[0];
            //                if (naprava.vrstaStevca == "ET") {
            //                    this._jeDvotarifni = false;
            //                }
            //                else {
            //                    this._jeDvotarifni = true;
            //                }
            //            }
            //        })
            //        .catch(() => {
            //            this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            //        });
            //}

            await this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;
            this._initForm();
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    public _porabaPregled() {
        // preverimo ce smo iz dashboarda (klik stanje po mesecih) prisli na to stran
        // ce smo, prikazemo tabelo
        if (window.location.href.includes("poraba-pregled")) {
            this._loader.show();
            var btnPregledPorabeElement = $("#btnPregledPorabe");
            if (btnPregledPorabeElement) {
                btnPregledPorabeElement[0].click();
            }
            this._loader.hide();
        }
    }

    private _renderData(translations: any): void {
        if (!this._merilnaMesta) {
            return;
        }
        var merilnaMestaIds = Array.from(new Set(this._merilnaMesta.map((item) => item.merilnoMesto)));
        var steviloPrikazanih = 3;
        var prikazanaMerilnaMesta = merilnaMestaIds.slice(0, steviloPrikazanih);
        if (this._merilnaMesta.length < steviloPrikazanih) {
            prikazanaMerilnaMesta = merilnaMestaIds;
        }

        var localeFormat = moment().creationData().locale.longDateFormat("L");
        this._formators = {
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            }//tukaj se lahko dodajajo formatorji
        };
        const viewModel = {
            merilnaMestaIds: merilnaMestaIds,
            merilnaMesta: this._merilnaMesta,
            izbranoMerilnoMesto: this._izbranoMerilnoMesto,
            ostalaMerilnaMestaStevilo: merilnaMestaIds.length - prikazanaMerilnaMesta.length,
            obstajajoOstalaMerilnaMesta: merilnaMestaIds.length - prikazanaMerilnaMesta.length > 0 ? true : false,
            prikazanaMerilnaMesta: prikazanaMerilnaMesta,
            translations: translations,
            formators: this._formators
        } as any;
        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _initForm(): void {
        if (!this._izbranoMerilnoMesto) {
            return;
        }
        require('jquery-validation-unobtrusive');

        var dropdownMerilnaMestaElement = $("#dropdownMerilnaMesta");
        dropdownMerilnaMestaElement.on("change", () => {
            var dropdownMerilnoMestoValues = (dropdownMerilnaMestaElement[0] as any).value.split("_");
            var selectedJeElektrika = dropdownMerilnoMestoValues[0]
            var selectedStevilkaKontoPogodbe = dropdownMerilnoMestoValues[1]
            var selectedMerilnoMesto = dropdownMerilnoMestoValues[2]
            if (selectedJeElektrika == 'true') {
                var selectedMerilnaTocka = dropdownMerilnoMestoValues[3]
                location.href = "#/merilno-mesto/poraba?" + selectedMerilnaTocka + "_" + selectedJeElektrika + "_" + selectedMerilnoMesto + "_" + selectedStevilkaKontoPogodbe;
            }
            else {
                location.href = "#/merilno-mesto/poraba?" + selectedJeElektrika + "_" + selectedMerilnoMesto + "_" + selectedStevilkaKontoPogodbe;
            }
        });
        if (this._izbranoMerilnoMesto.jeElektrika) {
            dropdownMerilnaMestaElement.val(this._izbranoMerilnoMesto.jeElektrika + "_" + this._izbranoMerilnoMesto.stevilkaKontoPogodbe + "_" + this._izbranoMerilnoMesto.merilnoMesto + "_" + this._izbranoMerilnoMesto.merilnaTocka);
        }
        else {
            dropdownMerilnaMestaElement.val(this._izbranoMerilnoMesto.jeElektrika + "_" + this._izbranoMerilnoMesto.stevilkaKontoPogodbe + "_" + this._izbranoMerilnoMesto.merilnoMesto);
        }

        var btnMerilnaMestaOthers = $("#btnMerilnaMestaOthers");
        btnMerilnaMestaOthers.on("click", () => {
            $(".merilna-mesta-not-all")[0].style.display = "none";
            $(".merilna-mesta-all")[0].style.display = "block";
        });

        var now = new Date();
        this._porabaObdobjeOdPicker = new DateRangePicker($('#main').find("#porabaObdobjeOd")[0], {
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            startDate: new Date(now.getFullYear(), now.getMonth() - 1, 1),
            minDate: moment().add(-20, 'years'),
            maxDate: moment()
        });
        $("#porabaObdobjeOd").on("change", () => {
            $(".odcitki-ne-obstajajo").hide();
            $("#poraba-graf").hide();
            $(".tabela-poraba").hide();
            $("#primerjava-graf").hide();
        });

        this._porabaObdobjeDoPicker = new DateRangePicker($('#main').find("#porabaObdobjeDo")[0], {
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            startDate: new Date(now.getFullYear(), now.getMonth(), 1),
            minDate: moment().add(-20, 'years'),
            maxDate: moment()
        });
        $("#porabaObdobjeDo").on("change", () => {
            $(".odcitki-ne-obstajajo").hide();
            $("#poraba-graf").hide();
            $(".tabela-poraba").hide();
            $("#primerjava-graf").hide();
        });

        var dropdownObdobje = $("#dropdownObdobje");
        dropdownObdobje.on("change", () => {
            if (dropdownObdobje.val() == "1") {
                var now = new Date();
                this._porabaObdobjeOdPicker.setStartDate(new Date(now.getFullYear(), now.getMonth() - 1, 1));
                this._porabaObdobjeDoPicker.setStartDate(new Date(now.getFullYear(), now.getMonth(), 1));
            }
            else if (dropdownObdobje.val() == "2") {
                var now = new Date();
                this._porabaObdobjeOdPicker.setStartDate(new Date(now.getFullYear(), now.getMonth() - 6, 1));
                this._porabaObdobjeDoPicker.setStartDate(new Date(now.getFullYear(), now.getMonth(), 1));
            }
            else if (dropdownObdobje.val() == "3") {
                var now = new Date();
                this._porabaObdobjeOdPicker.setStartDate(new Date(now.getFullYear() - 1, now.getMonth(), 1));
                this._porabaObdobjeDoPicker.setStartDate(new Date(now.getFullYear(), now.getMonth(), 1));
            }
            else if (dropdownObdobje.val() == "4") {
                var now = new Date();
                this._porabaObdobjeOdPicker.setStartDate(new Date(now.getFullYear(), 0, 1));
                this._porabaObdobjeDoPicker.setStartDate(new Date(now.getFullYear(), now.getMonth(), 1));
            }
            else if (dropdownObdobje.val() == "5") {
                var now = new Date();
                this._porabaObdobjeOdPicker.setStartDate(new Date(now.getFullYear() - 1, 0, 1));
                this._porabaObdobjeDoPicker.setStartDate(new Date(now.getFullYear(), 0, 1));
            }
        });

        var dropdownPrimerjavaPorabe = $("#dropdownPrimerjavaPorabe");
        dropdownPrimerjavaPorabe.on("change", () => {
            if (dropdownPrimerjavaPorabe.val() == "3") {
                $("#primerjava-koledar").show();
            }
            else {
                $("#primerjava-koledar").hide();
            }
            $(".odcitki-ne-obstajajo").hide();
            $("#poraba-graf").hide();
            $(".tabela-poraba").hide();
            $("#primerjava-graf").hide();
        });

        this._poraba1PrimerjavaOdPicker = new DateRangePicker($('#main').find("#poraba1PrimerjavaOd")[0], {
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            startDate: new Date(now.getFullYear() - 1, now.getMonth() - 1, 1),
            minDate: moment().add(-20, 'years'),
            maxDate: moment()
        });
        $("#poraba1PrimerjavaOd").on("change", () => {
            $(".odcitki-ne-obstajajo").hide();
            $("#poraba-graf").hide();
            $(".tabela-poraba").hide();
            $("#primerjava-graf").hide();
        });

        this._poraba1PrimerjavaDoPicker = new DateRangePicker($('#main').find("#poraba1PrimerjavaDo")[0], {
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            startDate: new Date(now.getFullYear() - 1, now.getMonth(), 1),
            minDate: moment().add(-20, 'years'),
            maxDate: moment()
        });
        $("#poraba1PrimerjavaDo").on("change", () => {
            $(".odcitki-ne-obstajajo").hide();
            $("#poraba-graf").hide();
            $(".tabela-poraba").hide();
            $("#primerjava-graf").hide();
        });

        this._poraba2PrimerjavaOdPicker = new DateRangePicker($('#main').find("#poraba2PrimerjavaOd")[0], {
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            startDate: new Date(now.getFullYear(), now.getMonth() - 1, 1),
            minDate: moment().add(-20, 'years'),
            maxDate: moment()
        });
        $("#poraba2PrimerjavaOd").on("change", () => {
            $(".odcitki-ne-obstajajo").hide();
            $("#poraba-graf").hide();
            $(".tabela-poraba").hide();
            $("#primerjava-graf").hide();
        });

        this._poraba2PrimerjavaDoPicker = new DateRangePicker($('#main').find("#poraba2PrimerjavaDo")[0], {
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            startDate: new Date(now.getFullYear(), now.getMonth(), 1),
            minDate: moment().add(-20, 'years'),
            maxDate: moment()
        });
        $("#poraba2PrimerjavaDo").on("change", () => {
            $(".odcitki-ne-obstajajo").hide();
            $("#poraba-graf").hide();
            $(".tabela-poraba").hide();
            $("#primerjava-graf").hide();
        });

        console.log(this._poraba1PrimerjavaOdPicker);
        console.log(this._poraba2PrimerjavaOdPicker);
        console.log(this._poraba1PrimerjavaDoPicker);
        console.log(this._poraba2PrimerjavaDoPicker);

        var btnPrimerjajPorabo = $("#btnPrimerjajPorabo");
        btnPrimerjajPorabo.on("click", (ev: Event) => {
            $(".odcitki-ne-obstajajo").hide();
            $("#poraba-graf").hide();
            $(".tabela-poraba").hide();
            $("#primerjava-graf").hide();
            this._onBtnPrimerjajPoraboClick(ev);
        });

        var btnPregledPorabeElement = $("#btnPregledPorabe");
        btnPregledPorabeElement.on("click", (ev: Event) => {
            $(".odcitki-ne-obstajajo").hide();
            $("#poraba-graf").hide();
            $(".tabela-poraba").hide();
            $("#primerjava-graf").hide();
            this._onBtnPregledPorabeClick(ev);
        });

        var btnPrenesiteInfoOPorabi = $("#prenesite-informacijo-o-porabi-btn");
        btnPrenesiteInfoOPorabi.on("click", (ev: Event) => this._onBtnPrenesiteInfoOPorabiClick(ev));
    }

    private async _primerjajMesec(merilnaMesta: Array<FakturiranaPorabaOdjemnoMestoRequest>, jeElektrika: boolean) {
        var now = new Date();
        var data: FakturiranaPorabaRequest = {
            odjemnoMesto: merilnaMesta,
            datumOd: new Date(now.getFullYear() - 5, now.getMonth(), 1),
            datumDo: new Date(now.getFullYear(), now.getMonth(), 1),
            agregirano: true
        };
        await this._merilnoMestoApiClient.fakturiranaPoraba(data, jeElektrika)
            .then((odgovor: FakturiranaPorabaElektrikaResponse) => {
                if (odgovor && odgovor.porabaOdjemnegaMesta[0].poraba) {
                    var poraba = odgovor.porabaOdjemnegaMesta[0].poraba;
                    var zadnjiMesec = poraba[poraba.length - 1];
                    var datum = new Date(zadnjiMesec.datum);
                    var celotnoObdobje2 = datum.getDate().toString() + "." + (datum.getMonth() + 1).toString() + "." + datum.getFullYear();
                    var predzadnjiMesec = null;
                    var celotnoObdobje1 = "";
                    if (poraba.length > 1) {
                        predzadnjiMesec = poraba[poraba.length - 2];
                        var datum = new Date(predzadnjiMesec.datum);
                        celotnoObdobje1 = datum.getDate().toString() + "." + (datum.getMonth() + 1).toString() + "." + datum.getFullYear();
                    }
                    this._loadPrimerjavaGraf([predzadnjiMesec], [zadnjiMesec], celotnoObdobje1, celotnoObdobje2);
                }
                else {
                    $(".odcitki-ne-obstajajo").show();
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
    }

    private async _primerjajPrejsnoLeto(merilnaMesta: Array<FakturiranaPorabaOdjemnoMestoRequest>, jeElektrika: boolean) {
        var formElement = $(("#prikaz-porabe"));
        var formData = new FormData(formElement[0] as HTMLFormElement);
        var obdobjeString = formData.get("porabaObdobjeOd") as string;
        var obdobjeSplit = obdobjeString.split(".");
        var datumOd1 = new Date(Number(obdobjeSplit[2]) - 1, Number(obdobjeSplit[1]) - 1, Number(obdobjeSplit[0]));
        var datumOd2 = new Date(Number(obdobjeSplit[2]), Number(obdobjeSplit[1]) - 1, Number(obdobjeSplit[0]));
        obdobjeString = formData.get("porabaObdobjeDo") as string;
        obdobjeSplit = obdobjeString.split(".");
        var datumDo1 = new Date(Number(obdobjeSplit[2]) - 1, Number(obdobjeSplit[1]) - 1, Number(obdobjeSplit[0]));
        var datumDo2 = new Date(Number(obdobjeSplit[2]), Number(obdobjeSplit[1]) - 1, Number(obdobjeSplit[0]));

        var celotnoObdobje1 = datumOd1.getDate().toString() + "." + (datumOd1.getMonth() + 1).toString() + "." + datumOd1.getFullYear()
            + " - " + datumDo1.getDate().toString() + "." + (datumDo1.getMonth() + 1).toString() + "." + datumDo1.getFullYear();
        var celotnoObdobje2 = datumOd2.getDate().toString() + "." + (datumOd2.getMonth() + 1).toString() + "." + datumOd2.getFullYear()
            + " - " + datumDo2.getDate().toString() + "." + (datumDo2.getMonth() + 1).toString() + "." + datumDo2.getFullYear();

        var poraba1 = null;
        var poraba2 = null;

        var data1: FakturiranaPorabaRequest = {
            odjemnoMesto: merilnaMesta,
            datumOd: datumOd1,
            datumDo: datumDo1,
            agregirano: true
        }
        await this._merilnoMestoApiClient.fakturiranaPoraba(data1, jeElektrika)
            .then((odgovor: FakturiranaPorabaElektrikaResponse) => {
                if (odgovor && odgovor.porabaOdjemnegaMesta[0].poraba) {
                    poraba1 = odgovor.porabaOdjemnegaMesta[0].poraba;
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
        var data2: FakturiranaPorabaRequest = {
            odjemnoMesto: merilnaMesta,
            datumOd: datumOd2,
            datumDo: datumDo2,
            agregirano: true
        }
        await this._merilnoMestoApiClient.fakturiranaPoraba(data2, jeElektrika)
            .then((odgovor: FakturiranaPorabaElektrikaResponse) => {
                if (odgovor && odgovor.porabaOdjemnegaMesta[0].poraba) {
                    poraba2 = odgovor.porabaOdjemnegaMesta[0].poraba;
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });

        this._loadPrimerjavaGraf(poraba1, poraba2, celotnoObdobje1, celotnoObdobje2);
    }

    private async _primerjajCustom(merilnaMesta: Array<FakturiranaPorabaOdjemnoMestoRequest>, jeElektrika: boolean) {
        var formElement = $(("#primerjava-porabe"));
        var formData = new FormData(formElement[0] as HTMLFormElement);
        var obdobjeString = formData.get("poraba1PrimerjavaOd") as string;
        var obdobjeSplit = obdobjeString.split(".");
        var datumOd1 = new Date(Number(obdobjeSplit[2]), Number(obdobjeSplit[1]) - 1, Number(obdobjeSplit[0]));
        obdobjeString = formData.get("poraba1PrimerjavaDo") as string;
        obdobjeSplit = obdobjeString.split(".");
        var datumDo1 = new Date(Number(obdobjeSplit[2]), Number(obdobjeSplit[1]) - 1, Number(obdobjeSplit[0]));
        obdobjeString = formData.get("poraba2PrimerjavaOd") as string;
        obdobjeSplit = obdobjeString.split(".");
        var datumOd2 = new Date(Number(obdobjeSplit[2]), Number(obdobjeSplit[1]) - 1, Number(obdobjeSplit[0]));
        obdobjeString = formData.get("poraba2PrimerjavaDo") as string;
        obdobjeSplit = obdobjeString.split(".");
        var datumDo2 = new Date(Number(obdobjeSplit[2]), Number(obdobjeSplit[1]) - 1, Number(obdobjeSplit[0]));

        var celotnoObdobje1 = datumOd1.getDate().toString() + "." + (datumOd1.getMonth() + 1).toString() + "." + datumOd1.getFullYear()
            + " - " + datumDo1.getDate().toString() + "." + (datumDo1.getMonth() + 1).toString() + "." + datumDo1.getFullYear();
        var celotnoObdobje2 = datumOd2.getDate().toString() + "." + (datumOd2.getMonth() + 1).toString() + "." + datumOd2.getFullYear()
            + " - " + datumDo2.getDate().toString() + "." + (datumDo2.getMonth() + 1).toString() + "." + datumDo2.getFullYear();

        var poraba1 = null;
        var poraba2 = null;

        var data1: FakturiranaPorabaRequest = {
            odjemnoMesto: merilnaMesta,
            datumOd: datumOd1,
            datumDo: datumDo1,
            agregirano: true
        }
        await this._merilnoMestoApiClient.fakturiranaPoraba(data1, jeElektrika)
            .then((odgovor: FakturiranaPorabaElektrikaResponse) => {
                if (odgovor && odgovor.porabaOdjemnegaMesta[0].poraba) {
                    poraba1 = odgovor.porabaOdjemnegaMesta[0].poraba;
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
        var data2: FakturiranaPorabaRequest = {
            odjemnoMesto: merilnaMesta,
            datumOd: datumOd2,
            datumDo: datumDo2,
            agregirano: true
        }
        await this._merilnoMestoApiClient.fakturiranaPoraba(data2, jeElektrika)
            .then((odgovor: FakturiranaPorabaElektrikaResponse) => {
                if (odgovor && odgovor.porabaOdjemnegaMesta[0].poraba) {
                    poraba2 = odgovor.porabaOdjemnegaMesta[0].poraba;
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });

        this._loadPrimerjavaGraf(poraba1, poraba2, celotnoObdobje1, celotnoObdobje2);
    }

    private _onBtnPrimerjajPoraboClick(ev: Event) {
        ev.preventDefault();
        if (!this._izbranoMerilnoMesto || !this._merilnaMesta) {
            return;
        }
        this._loader.show();
        var merilnoMesto: FakturiranaPorabaOdjemnoMestoRequest = {
            merilnoMesto: this._izbranoMerilnoMesto.merilnoMesto,
            merilnaTocka: this._izbranoMerilnoMesto.merilnaTocka,
            stevilkaKontoPogodbe: this._izbranoMerilnoMesto.stevilkaKontoPogodbe,
        }
        var merilnaMesta = new Array<FakturiranaPorabaOdjemnoMestoRequest>();
        merilnaMesta.push(merilnoMesto);

        var dropdownPrimerjavaPorabe = $("#dropdownPrimerjavaPorabe");
        if (dropdownPrimerjavaPorabe.val() == "1") {
            this._primerjajMesec(merilnaMesta, this._izbranoMerilnoMesto.jeElektrika);
        }
        else if (dropdownPrimerjavaPorabe.val() == "2") {
            this._primerjajPrejsnoLeto(merilnaMesta, this._izbranoMerilnoMesto.jeElektrika);
        }
        else {
            this._primerjajCustom(merilnaMesta, this._izbranoMerilnoMesto.jeElektrika);
        }
        this._loader.hide();
    }

    private _loadPrimerjavaGraf(poraba1Seznam: any, poraba2Seznam: any, obdobje1: string, obdobje2: string) {
        if (poraba1Seznam == null && poraba2Seznam == null) {
            $(".odcitki-ne-obstajajo").show();
            return;
        }

        // Nastavimo datume (x os)
        var datume = new Array<string>();
        var enotaMere = poraba1Seznam != null ? poraba1Seznam[0].enotaMere : poraba2Seznam[0].enotaMere;
        if (poraba1Seznam != null) {
            for (let i = 0; i < poraba1Seznam.length; i++) {
                var datum = new Date(poraba1Seznam[i].datum);
                var datumString = (datum.getMonth() + 1).toString() + "." + datum.getFullYear().toString();
                datume.push(datumString);
            }
        }
        if (poraba2Seznam != null) {
            for (let i = 0; i < poraba2Seznam.length; i++) {
                var datum = new Date(poraba2Seznam[i].datum);
                var datumString = (datum.getMonth() + 1).toString() + "." + datum.getFullYear().toString();
                if (i >= datume.length) {
                    datume.push(datumString);
                }
                else {
                    datume[i] += " / " + datumString;
                }
            }
        }

        if (this._izbranoMerilnoMesto?.jeElektrika) {
            var vtList1 = new Array<Number>();
            var mtList1 = new Array<Number>();
            var etList1 = new Array<Number>();
            var vtList2 = new Array<Number>();
            var mtList2 = new Array<Number>();
            var etList2 = new Array<Number>();

            // Nastavimo podatke (y os)
            if (poraba1Seznam != null) {
                for (let i = 0; i < poraba1Seznam.length; i++) {
                    vtList1.push(poraba1Seznam[i].vt);
                    mtList1.push(poraba1Seznam[i].mt);
                    etList1.push(poraba1Seznam[i].et);
                }
            }
            if (poraba2Seznam != null) {
                for (let i = 0; i < poraba2Seznam.length; i++) {
                    vtList2.push(poraba2Seznam[i].vt);
                    mtList2.push(poraba2Seznam[i].mt);
                    etList2.push(poraba2Seznam[i].et);
                }
            }

            const html = Mustache.render(primerjavaGrafView, {
                translations: this._translationService.currentTranslations
            });

            $("#primerjava-graf").html(html);

            if (this._jeDvotarifni) {
                var chartOptions = {
                    chart: {
                        width: "100%",
                        height: 380,
                        type: 'line',
                        toolbar: {
                            tools: {
                                download: false
                            }
                        }
                    },
                    series: [
                        {
                            name: "VT (" + obdobje1 + ")",
                            data: vtList1
                        },
                        {
                            name: "MT (" + obdobje1 + ")",
                            data: mtList1
                        },
                        {
                            name: "VT (" + obdobje2 + ")",
                            data: vtList2
                        },
                        {
                            name: "MT (" + obdobje2 + ")",
                            data: mtList2
                        }
                    ],

                    xaxis: {
                        categories: datume
                    },

                    title: {
                        alight: 'left',
                        // text: "Skupna poraba", 
                        floating: true,
                        offsetX: -10,
                        offsetY: 10,
                        style: {
                            fontFamily: 'Red Hat Display',
                            fontWeight: '400',
                            fontSize: '15px',
                            colour: 'rgb(33, 37, 41)',

                        }
                    },
                    yaxis: {
                        labels: {
                            show: true,
                            formatter: ((val: any) => { return val + " " + enotaMere })
                        }
                    },
                    legend: {
                        show: true,
                        position: 'top',
                        horizontalAlign: 'right',
                        offsetY: 10,
                        labels: {
                            useSeriesColors: false
                        }
                    },

                    fill: {
                        colors: ['#008FFB', '#00E396', '#FFB019']
                    },

                    grid: {
                        show: true,
                        borderColor: "#e8e4e4",
                        strokeDashArray: 0,
                        position: 'back',
                        yaxis: {
                            lines: {
                                show: true
                            }
                        },
                        xaxis: {
                            lines: {
                                show: true
                            }
                        },
                    },

                    dataLabels: {
                        enabled: false
                    }
                };
            }
            else {
                var chartOptions = {
                    chart: {
                        width: "100%",
                        height: 380,
                        type: 'line',
                        toolbar: {
                            tools: {
                                download: false
                            }
                        }
                    },
                    series: [
                        {
                            name: "ET (" + obdobje1 + ")",
                            data: etList1
                        },
                        {
                            name: "ET (" + obdobje2 + ")",
                            data: etList2
                        }
                    ],

                    xaxis: {
                        categories: datume
                    },

                    title: {
                        alight: 'left',
                        // text: "Skupna poraba", 
                        floating: true,
                        offsetX: -10,
                        offsetY: 10,
                        style: {
                            fontFamily: 'Red Hat Display',
                            fontWeight: '400',
                            fontSize: '15px',
                            colour: 'rgb(33, 37, 41)',

                        }
                    },
                    yaxis: {
                        labels: {
                            show: true,
                            formatter: ((val: any) => { return val + " " + enotaMere })
                        }
                    },
                    legend: {
                        show: true,
                        position: 'top',
                        horizontalAlign: 'right',
                        offsetY: 10,
                        labels: {
                            useSeriesColors: false
                        }
                    },

                    fill: {
                        colors: ['#008FFB', '#00E396', '#FFB019']
                    },

                    grid: {
                        show: true,
                        borderColor: "#e8e4e4",
                        strokeDashArray: 0,
                        position: 'back',
                        yaxis: {
                            lines: {
                                show: true
                            }
                        },
                        xaxis: {
                            lines: {
                                show: true
                            }
                        },
                    },

                    dataLabels: {
                        enabled: false
                    }
                };
            }
        }
        else {
            var m3List1 = new Array<Number>();
            var m3List2 = new Array<Number>();

            // Nastavimo podatke (y os)
            if (poraba1Seznam != null) {
                for (let i = 0; i < poraba1Seznam.length; i++) {
                    m3List1.push(poraba1Seznam[i].m3);
                }
            }
            if (poraba2Seznam != null) {
                for (let i = 0; i < poraba2Seznam.length; i++) {
                    m3List2.push(poraba2Seznam[i].m3);
                }
            }

            const html = Mustache.render(primerjavaGrafView, {
                translations: this._translationService.currentTranslations
            });
            $("#primerjava-graf").html(html);

            var chartOptions = {
                chart: {
                    width: "100%",
                    height: 380,
                    type: 'line',
                    toolbar: {
                        tools: {
                            download: false
                        }
                    }
                },
                series: [
                    {
                        name: "M3 (" + obdobje1 + ")",
                        data: m3List1
                    },
                    {
                        name: "M3 (" + obdobje2 + ")",
                        data: m3List2
                    }
                ],

                xaxis: {
                    categories: datume
                },

                title: {
                    alight: 'left',
                    // text: "Skupna poraba", 
                    floating: true,
                    offsetX: -10,
                    offsetY: 10,
                    style: {
                        fontFamily: 'Red Hat Display',
                        fontWeight: '400',
                        fontSize: '15px',
                        colour: 'rgb(33, 37, 41)',

                    }
                },
                yaxis: {
                    labels: {
                        show: true,
                        formatter: ((val: any) => { return val + " " + enotaMere })
                    }
                },
                legend: {
                    show: true,
                    position: 'top',
                    horizontalAlign: 'right',
                    offsetY: 10,
                    labels: {
                        useSeriesColors: false
                    }
                },

                fill: {
                    colors: ['#008FFB', '#00E396', '#FFB019']
                },

                grid: {
                    show: true,
                    borderColor: "#e8e4e4",
                    strokeDashArray: 0,
                    position: 'back',
                    yaxis: {
                        lines: {
                            show: true
                        }
                    },
                    xaxis: {
                        lines: {
                            show: true
                        }
                    },
                },

                dataLabels: {
                    enabled: false
                }
            };
        }

        //.apexcharts-legend-series.custom-marker-down .apexcharts-legend-marker
        var chart = new ApexCharts($("#primerjava-graf").find("#primerjava-graf-div")[0], chartOptions);
        chart.render();
        $("#primerjava-graf").show();

    }

    private async _onBtnPregledPorabeClick(ev: Event) {
        ev.preventDefault();
        if (!this._izbranoMerilnoMesto || !this._merilnaMesta) {
            return;
        }
        this._loader.show();
        var formElement = $(("#prikaz-porabe"));
        var formData = new FormData(formElement[0] as HTMLFormElement);
        var obdobjeOdString = formData.get("porabaObdobjeOd") as string;
        var obdobjeOdSplit = obdobjeOdString.split(".");
        var obdobjeOd = new Date(Number(obdobjeOdSplit[2]), Number(obdobjeOdSplit[1]) - 1, Number(obdobjeOdSplit[0]));
        var obdobjeDoString = formData.get("porabaObdobjeDo") as string;
        var obdobjeDoSplit = obdobjeDoString.split(".");
        var obdobjeDo = new Date(Number(obdobjeDoSplit[2]), Number(obdobjeDoSplit[1]) - 1, Number(obdobjeDoSplit[0]));

        var merilnoMesto: FakturiranaPorabaOdjemnoMestoRequest = {
            merilnoMesto: this._izbranoMerilnoMesto.merilnoMesto,
            merilnaTocka: this._izbranoMerilnoMesto.merilnaTocka,
            stevilkaKontoPogodbe: this._izbranoMerilnoMesto.stevilkaKontoPogodbe,
        }
        var merilnaMesta = new Array<FakturiranaPorabaOdjemnoMestoRequest>();
        merilnaMesta.push(merilnoMesto);
        var data: FakturiranaPorabaRequest = {
            odjemnoMesto: merilnaMesta,
            datumOd: obdobjeOd,
            datumDo: obdobjeDo,
            agregirano: true
        };
        if (this._izbranoMerilnoMesto.jeElektrika) {
            await this._merilnoMestoApiClient.fakturiranaPoraba(data, true)
                .then((odgovor: FakturiranaPorabaElektrikaResponse) => {
                    this._fakturiranaPorabaElektrika = odgovor;
                    if (this._fakturiranaPorabaElektrika && this._fakturiranaPorabaElektrika.porabaOdjemnegaMesta[0].poraba) {
                        // renderiramo grafa
                        this._loadPorabaGraf(this._fakturiranaPorabaElektrika.porabaOdjemnegaMesta[0].poraba);

                        // renderiramo tabelo
                        this._renderPorabaTables(this._fakturiranaPorabaElektrika.porabaOdjemnegaMesta[0].poraba, 1);
                        $(".tabela-poraba").show();
                    }
                    else {
                        $(".odcitki-ne-obstajajo").show();
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                });
        }
        else {
            this._merilnoMestoApiClient.fakturiranaPoraba(data, false)
                .then((odgovor: FakturiranaPorabaPlinResponse) => {
                    this._fakturiranaPorabaPlin = odgovor;
                    if (this._fakturiranaPorabaPlin && this._fakturiranaPorabaPlin.porabaOdjemnegaMesta[0].poraba) {
                        // renderiramo grafa
                        this._loadPorabaGraf(this._fakturiranaPorabaPlin.porabaOdjemnegaMesta[0].poraba);

                        // renderiramo tabelo
                        this._renderPorabaTables(this._fakturiranaPorabaPlin.porabaOdjemnegaMesta[0].poraba, 1);
                        $(".tabela-poraba").show();
                        $(".odcitki-ne-obstajajo").hide();
                    }
                    else {
                        $("#poraba-graf").hide();
                        $(".tabela-poraba").hide();
                        $(".odcitki-ne-obstajajo").show();
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                });
        }
        this._loader.hide();
    }

    private _renderPorabaTables(seznam: any, page: number) {
        var displayedOdcitki = new Array<any>();
        if (seznam && seznam.length > 0) {
            seznam.sort(function (a: any, b: any) {
                if (a.datum > b.datum) return -1;
                if (a.datum < b.datum) return 1;
            });
            displayedOdcitki = new Array<any>();
            for (let i = (page - 1) * this._limit; i < page * this._limit; i++) {
                if (i >= seznam.length) {
                    break;
                }
                displayedOdcitki.push(seznam[i]);
            }
        }

        this._renderPorabaTableDesktop(displayedOdcitki);
        $(".tabela-poraba-desktop").addClass("d-lg-block");
        $(".tabela-poraba-desktop").removeClass("d-lg-none");
        this._renderPorabaTableMobile(displayedOdcitki);
        $(".tabela-poraba-mobile").addClass("d-block");
        $(".tabela-poraba-mobile").removeClass("d-none");
        this._refreshPaginationPoraba(seznam, page);
    }

    private _renderPorabaTableDesktop(displayedOdcitki: any) {
        // izpolnimo podatke v tabeli     
        const templateString = `
                <%#obstajaOdcitek%>
                    <table class="table zgodovina-odcitkov">
                        <thead>
                            <tr>
                                <td scope="col"><%{translations.Period}%></td>
                                <%#jeMesan%>
                                    <td scope="col">VT</td>
                                    <td scope="col">MT</td>
                                    <td scope="col">ET</td>
                                    <td scope="col">SUM</td>
                                <%/jeMesan%>
                                <%^jeMesan%>
                                    <%#jeElektrikaDvotarifni%>
                                        <td scope="col">VT</td>
                                        <td scope="col">MT</td>
                                        <td scope="col">SUM</td>
                                    <%/jeElektrikaDvotarifni%>
                                    <%#jeElektrikaEnotarifni%>
                                        <td scope="col">ET</td>
                                    <%/jeElektrikaEnotarifni%>
                                <%/jeMesan%>
                                <%#jePlin%>
                                    <td scope="col"><%{translations.GasConsumption}%></td>
                                <%/jePlin%>
                                <td scope="col"><%{translations.MeasureUnit}%></td>
                            </tr>
                        </thead>
                        <tbody>
                            <%#odcitki%>
                            <tr>
                                <td scope="row"><%#formators%><%#dateFormat%><%datum%><%/dateFormat%><%/formators%></td>
                                <%#jeMesan%>
                                    <td><%{vt}%></td>
                                    <td><%{mt}%></td>
                                    <td><%{et}%></td>
                                    <td><%{sum}%></td>
                                <%/jeMesan%>
                                <%^jeMesan%>
                                    <%#jeElektrikaDvotarifni%>
                                        <td><%{vt}%></td>
                                        <td><%{mt}%></td>
                                        <td><%{sum}%></td>
                                    <%/jeElektrikaDvotarifni%>
                                    <%#jeElektrikaEnotarifni%>
                                        <td><%{et}%></td>
                                    <%/jeElektrikaEnotarifni%>
                                <%/jeMesan%>
                                <%#jePlin%>
                                    <td><%{m3}%></td>
                                <%/jePlin%>
                                <td><%{enotaMere}%></td>
                            </tr>
                            <%/odcitki%>
                        </tbody>
                    </table>
                <%/obstajaOdcitek%>
                <%^obstajaOdcitek%>
                    <div><%{translations.NoData}%></div>
                <%/obstajaOdcitek%>
             `;
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "jeMesan": this._izbranoMerilnoMesto?.jeElektrika && this._jeEnotarifni && this._jeDvotarifni,
                "jeElektrikaDvotarifni": this._izbranoMerilnoMesto?.jeElektrika && this._jeDvotarifni,
                "jeElektrikaEnotarifni": this._izbranoMerilnoMesto?.jeElektrika && !this._jeDvotarifni,
                "jePlin": !this._izbranoMerilnoMesto?.jeElektrika,
                "formators": this._formators,
                "jeElektrika": this._izbranoMerilnoMesto?.jeElektrika,
                "obstajaOdcitek": displayedOdcitki.length > 0 ? true : false,
                "odcitki": displayedOdcitki,
                "translations": this._translationService.currentTranslations
            },
            undefined,
            customTags
        );
        $(".tabela-poraba-desktop").html(html);
    }

    private _refreshPaginationPoraba(seznam: Array<any>, page: number) {
        if (seznam) {
            const templateString = `
             <div class="pagination">
                <%#obstajaPrejsnjaStran%>
                <div>
                    <a class="btnPreviousPage">&lt</a>
                </div>
                <%/obstajaPrejsnjaStran%>
                <div class="pages"><%{page}%>/<%{maxPage}%></div>
                <%#obstajaNaslednjaStran%>
                <div>
                    <a class="btnNextPage">&gt</a>
                </div>
                <%/obstajaNaslednjaStran%>
            </div>
        `;
            var customTags: any = ['<%', '%>'];
            var maxPage = Math.ceil(seznam.length / this._limit);
            var html = Mustache.render(
                templateString,
                {
                    "page": page,
                    "maxPage": maxPage,
                    "obstajaPrejsnjaStran": page != 1 ? true : false,
                    "obstajaNaslednjaStran": page != maxPage ? true : false
                },
                undefined,
                customTags
            );
            $("#pagination-poraba").show();
            $("#pagination-poraba").html(html);
            $(".btnPreviousPage").on("click", (ev: Event) => this._onBtnPageChangeClick(ev, true, seznam));
            $(".btnNextPage").on("click", (ev: Event) => this._onBtnPageChangeClick(ev, false, seznam));
        }
        else {
            $("#pagination-poraba").hide();
        }
    }

    private _onBtnPageChangeClick(ev: Event, previous: boolean, seznam: any) {
        console.debug(ev);
        ev.preventDefault();
        this._loader.show();
        var pagesString = $(".pages")[0].innerHTML;
        var currentPage = Number(pagesString.split("/")[0]);
        if (previous) {
            currentPage -= 1;
        }
        else {
            currentPage += 1;
        }
        this._renderPorabaTables(seznam, currentPage);
        this._loader.hide();
    }

    private _renderPorabaTableMobile(displayedOdcitki: any) {
        // izpolnimo podatke v tabeli
        const templateString = `
                <%#obstajaOdcitek%>
                <table class="table zgodovina-odcitkov-mobile">
                    <tbody>
                    <%#odcitki%>
                        <tr>
                            <td class="table-inner-wrap">
                                <div class="table-responsive">
                                    <table class="table table-borderless">
                                        <tbody>
                                            <tr>
                                                <th scope="row"><%{translations.Period}%></th>
                                                <td><%#formators%><%#dateFormat%><%datum%><%/dateFormat%><%/formators%></td>
                                            </tr>
                                            <%#jeMesan%>
                                                <tr>
                                                    <th scope="row">VT</th>
                                                    <td><%{vt}%></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">MT</th>
                                                    <td><%{mt}%></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">ET</th>
                                                    <td><%{et}%></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">SUM</th>
                                                    <td><%{sum}%></td>
                                                </tr>
                                            <%/jeMesan%>
                                            <%^jeMesan%>
                                                <%#jeElektrikaDvotarifni%>
                                                    <tr>
                                                        <th scope="row">VT</th>
                                                        <td><%{vt}%></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">MT</th>
                                                        <td><%{mt}%></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">SUM</th>
                                                        <td><%{sum}%></td>
                                                    </tr>
                                                <%/jeElektrikaDvotarifni%>
                                                <%#jeElektrikaEnotarifni%>
                                                    <tr>
                                                        <th scope="row">ET</th>
                                                        <td><%{et}%></td>
                                                    </tr>
                                                <%/jeElektrikaEnotarifni%>
                                            <%/jeMesan%>
                                            <%#jePlin%>
                                                <tr>
                                                    <th scope="row"><%{translations.GasConsumption}%></th>
                                                    <td><%{m3}%></td>
                                                </tr>
                                            <%/jePlin%>
                                            <tr>
                                                <th scope="row"><%{translations.MeasureUnit}%></th>
                                                <td><%{enotaMere}%></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <%/odcitki%>
                </table>
                <%/obstajaOdcitek%>
                <%^obstajaOdcitek%>
                <div><%{translations.NoData}%></div>
                <%/obstajaOdcitek%>
             `;
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "jeMesan": this._izbranoMerilnoMesto?.jeElektrika && this._jeEnotarifni && this._jeDvotarifni,
                "jeElektrikaDvotarifni": this._izbranoMerilnoMesto?.jeElektrika && this._jeDvotarifni,
                "jeElektrikaEnotarifni": this._izbranoMerilnoMesto?.jeElektrika && !this._jeDvotarifni,
                "jePlin": !this._izbranoMerilnoMesto?.jeElektrika,
                "formators": this._formators,
                "jeElektrika": this._izbranoMerilnoMesto?.jeElektrika,
                "obstajaOdcitek": displayedOdcitki.length > 0 ? true : false,
                "odcitki": displayedOdcitki,
                "translations": this._translationService.currentTranslations
            },
            undefined,
            customTags
        );
        $(".tabela-poraba-mobile").html(html);
    }

    private _loadPorabaGraf(porabaSeznam: any) {
        var enotaMere = "";
        if (this._izbranoMerilnoMesto?.jeElektrika) {
            var datume = new Array<String>();
            var vtList = new Array<Number>();
            var mtList = new Array<Number>();
            var etList = new Array<Number>();
            var vtSum = 0;
            var mtSum = 0;
            var etSum = 0;
            for (let i = 0; i < porabaSeznam.length; i++) {
                var datum = new Date(porabaSeznam[i].datum);
                var datumString = (datum.getMonth() + 1).toString() + "." + datum.getFullYear().toString();
                datume.push(datumString);
                vtList.push(porabaSeznam[i].vt);
                mtList.push(porabaSeznam[i].mt);
                etList.push(porabaSeznam[i].et);
                enotaMere = porabaSeznam[i].enotaMere;
                vtSum += porabaSeznam[i].vt;
                mtSum += porabaSeznam[i].mt;
                etSum += porabaSeznam[i].et;
            }
            if (etSum > 0) {
                this._jeEnotarifni = true;
            }
            if (vtSum > 0 || mtSum > 0) {
                this._jeDvotarifni = true;
            }
            const html = Mustache.render(porabaGrafView, {
                translations: this._translationService.currentTranslations
            });

            // Obstajajo samo VT/MT zapisi
            if (!this._jeEnotarifni && this._jeDvotarifni) {
                $("#poraba-graf").html(html);
                var chartOptions = {
                    chart: {
                        width: "100%",
                        height: 380,
                        type: 'bar',
                        toolbar: {
                            tools: {
                                download: false
                            }
                        }
                    },
                    colors: ['#d20a10', '#374e5a'],
                    series: [
                        {
                            name: "VT",
                            data: vtList
                        },
                        {
                            name: "MT",
                            data: mtList
                        }
                    ],

                    xaxis: {
                        categories: datume
                    },

                    title: {
                        alight: 'left',
                        // text: "Skupna poraba", 
                        floating: true,
                        offsetX: -10,
                        offsetY: 10,
                        style: {
                            fontFamily: 'Red Hat Display',
                            fontWeight: '400',
                            fontSize: '15px',
                            colour: 'rgb(33, 37, 41)',

                        }
                    },
                    yaxis: {
                        labels: {
                            show: true,
                            formatter: ((val: any) => { return val + " " + enotaMere })
                        }
                    },
                    legend: {
                        show: true,
                        position: 'top',
                        horizontalAlign: 'right',
                        offsetY: 10,
                        labels: {
                            useSeriesColors: false
                        }
                    },

                    fill: {
                        colors: ['#d20a10', '#374e5a']
                    },

                    grid: {
                        show: true,
                        borderColor: "#e8e4e4",
                        strokeDashArray: 0,
                        position: 'back',
                        yaxis: {
                            lines: {
                                show: true
                            }
                        },
                        xaxis: {
                            lines: {
                                show: true
                            }
                        },
                    },

                    dataLabels: {
                        enabled: false
                    }
                };
            }
            // Obstajajo samo ET zapisi
            else if (this._jeEnotarifni && !this._jeDvotarifni) {
                $("#poraba-graf").html(html);
                var chartOptions = {
                    chart: {
                        width: "100%",
                        height: 380,
                        type: 'bar',
                        toolbar: {
                            tools: {
                                download: false
                            }
                        }
                    },
                    colors: ['#00aae4'],
                    series: [
                        {
                            name: "ET",
                            data: etList
                        },
                    ],

                    xaxis: {
                        categories: datume
                    },

                    title: {
                        alight: 'left',
                        // text: "Skupna poraba", 
                        floating: true,
                        offsetX: -10,
                        offsetY: 10,
                        style: {
                            fontFamily: 'Red Hat Display',
                            fontWeight: '400',
                            fontSize: '15px',
                            colour: 'rgb(33, 37, 41)',

                        }
                    },
                    yaxis: {
                        labels: {
                            show: true,
                            formatter: ((val: any) => { return val + " " + enotaMere })
                        }
                    },
                    legend: {
                        show: true,
                        position: 'top',
                        horizontalAlign: 'right',
                        offsetY: 10,
                        labels: {
                            useSeriesColors: false
                        }
                    },

                    fill: {
                        colors: ['#00aae4']
                    },

                    grid: {
                        show: true,
                        borderColor: "#e8e4e4",
                        strokeDashArray: 0,
                        position: 'back',
                        yaxis: {
                            lines: {
                                show: true
                            }
                        },
                        xaxis: {
                            lines: {
                                show: true
                            }
                        },
                    },

                    dataLabels: {
                        enabled: false
                    }
                };
            }
            // Obstajajo ET in VT/MT zapisi
            else {
                $("#poraba-graf").html(html);
                var chartOptions = {
                    chart: {
                        width: "100%",
                        height: 380,
                        type: 'bar',
                        toolbar: {
                            tools: {
                                download: false
                            }
                        }
                    },
                    colors: ['#d20a10', '#374e5a', '#00aae4'],
                    series: [
                        {
                            name: "VT",
                            data: vtList
                        },
                        {
                            name: "MT",
                            data: mtList
                        },
{
                            name: "ET",
                            data: etList
                        },
                    ],

                    xaxis: {
                        categories: datume
                    },

                    title: {
                        alight: 'left',
                        // text: "Skupna poraba", 
                        floating: true,
                        offsetX: -10,
                        offsetY: 10,
                        style: {
                            fontFamily: 'Red Hat Display',
                            fontWeight: '400',
                            fontSize: '15px',
                            colour: 'rgb(33, 37, 41)',

                        }
                    },
                    yaxis: {
                        labels: {
                            show: true,
                            formatter: ((val: any) => { return val + " " + enotaMere })
                        }
                    },
                    legend: {
                        show: true,
                        position: 'top',
                        horizontalAlign: 'right',
                        offsetY: 10,
                        labels: {
                            useSeriesColors: false
                        }
                    },

                    fill: {
                        colors: ['#d20a10', '#374e5a', '#00aae4']
                    },

                    grid: {
                        show: true,
                        borderColor: "#e8e4e4",
                        strokeDashArray: 0,
                        position: 'back',
                        yaxis: {
                            lines: {
                                show: true
                            }
                        },
                        xaxis: {
                            lines: {
                                show: true
                            }
                        },
                    },

                    dataLabels: {
                        enabled: false
                    }
                };
            }
        }
        else {
            var datume = new Array<String>();
            var m3List = new Array<Number>();
            for (let i = 0; i < porabaSeznam.length; i++) {
                var datum = new Date(porabaSeznam[i].datum);
                var datumString = (datum.getMonth() + 1).toString() + "." + datum.getFullYear().toString();
                datume.push(datumString);
                m3List.push(Number(porabaSeznam[i].m3));
                enotaMere = porabaSeznam[i].enotaMere;
            }
            const html = Mustache.render(porabaGrafView, {
                translations: this._translationService.currentTranslations
            });
            $("#poraba-graf").html(html);
            var chartOptions = {
                chart: {
                    width: "100%",
                    height: 380,
                    type: 'bar',
                    toolbar: {
                        tools: {
                            download: false
                        }
                    }
                },
                colors: ['#00aae4'],
                series: [
                    {
                        name: "M3",
                        data: m3List
                    },
                ],

                xaxis: {
                    categories: datume
                },

                title: {
                    alight: 'left',
                    // text: "Skupna poraba", 
                    floating: true,
                    offsetX: -10,
                    offsetY: 10,
                    style: {
                        fontFamily: 'Red Hat Display',
                        fontWeight: '400',
                        fontSize: '15px',
                        colour: 'rgb(33, 37, 41)',

                    }
                },
                yaxis: {
                    labels: {
                        show: true,
                        formatter: ((val: any) => { return val + " " + enotaMere })
                    }
                },
                legend: {
                    show: true,
                    position: 'top',
                    horizontalAlign: 'right',
                    offsetY: 10,
                    labels: {
                        useSeriesColors: false
                    }
                },

                fill: {
                    colors: ['#00aae4']
                },

                grid: {
                    show: true,
                    borderColor: "#e8e4e4",
                    strokeDashArray: 0,
                    position: 'back',
                    yaxis: {
                        lines: {
                            show: true
                        }
                    },
                    xaxis: {
                        lines: {
                            show: true
                        }
                    },
                },

                dataLabels: {
                    enabled: false
                }
            };
        }
        //.apexcharts-legend-series.custom-marker-down .apexcharts-legend-marker
        var chart = new ApexCharts($("#poraba-graf").find("#poraba-graf-div")[0], chartOptions);
        chart.render();
        $("#poraba-graf").show();
    }

    private async _onBtnPrenesiteInfoOPorabiClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        if (!this._izbranoMerilnoMesto) return;
        var downloadingNotyf = this._notyf.open({
            type: "info",
            message: this._translationService.currentTranslations["Downloading"],
            duration: 50000
        });
        var poraba = null;
        if (this._izbranoMerilnoMesto.jeElektrika && this._fakturiranaPorabaElektrika) {
            poraba = this._fakturiranaPorabaElektrika.porabaOdjemnegaMesta[0].poraba;
        }
        else if (!this._izbranoMerilnoMesto.jeElektrika && this._fakturiranaPorabaPlin) {
            poraba = this._fakturiranaPorabaPlin.porabaOdjemnegaMesta[0].poraba;
        }
        await this._merilnoMestoApiClient.informacijaOPorabiDownload(poraba, this._izbranoMerilnoMesto.jeElektrika, this._jeEnotarifni, this._jeDvotarifni)
            .then((response) => {
                const downloadUrl = response.downloadUrl;
                const fileLink = document.createElement('a');
                fileLink.href = downloadUrl;
                fileLink.click();
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["ErrorWhileDownloadingFile"]);
            }).finally(() => {
                this._notyf.dismiss(downloadingNotyf);
            });
    }
}
