import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { Role } from "../ts/models/role";
import { TranslationService } from "../ts/translation-service";
import template from "./admin-dodaj-uporabnik-view.html";
import { FullscreenLoader } from "./fullscreen-loader";
import "jquery-validation-unobtrusive";
import { AdminDodajUporabnikRequest } from "../ts/models/admin-dodaj-uporabnik-request";
import { AdminApiClient } from "../ts/clients/admin-api-client";
import { TipUporabnika } from "../ts/models/tip-uporabnika-enum";

@injectable()
export class AdminDodajUporabnikView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _adminApiClient: AdminApiClient;
    private _roles: Array<Role>;
    private _formUporabniskiPodatki: JQuery<HTMLElement> | null;
    private _tipUporabnika: TipUporabnika | null;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        adminApiClient: AdminApiClient,
        private _notyf: Notyf,
        private _loader: FullscreenLoader) {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._adminApiClient = adminApiClient;
        this._roles = new Array<Role>();
        this._formUporabniskiPodatki = null;
        this._tipUporabnika = null;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;
            this._getTipUporabnika();
            await this._adminApiClient.getAllRoles()
                .then((odgovor: any) => {
                    this._roles = odgovor;
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                });

            this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;
            this._initForm();
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _getTipUporabnika() {
        // preverimo kaksen uporabnik hocemo dodati
        var url = window.location.href.split("/");
        var tipUporabnika = url[url.length - 1];
        if (Number(tipUporabnika) == 2) {
            this._tipUporabnika = TipUporabnika.MestnaBlagajna;
        }
        else if (Number(tipUporabnika) == 3) {
            this._tipUporabnika = TipUporabnika.Upravnik;
        }
        else if (Number(tipUporabnika) == 4) {
            this._tipUporabnika = TipUporabnika.ToplotniUporabnik;
        }
        else {
            this._tipUporabnika = TipUporabnika.Uporabnik;
        }
    }

    private _renderData(translations: any): void {
        const viewModel = {
            roles: this._roles,
            translations: translations,
            jeUporabnik: this._tipUporabnika == TipUporabnika.Uporabnik ? true : false,
            jeMestnaBlagajna: this._tipUporabnika == TipUporabnika.MestnaBlagajna ? true : false,
            jeUpravnik: this._tipUporabnika == TipUporabnika.Upravnik ? true : false,
            jeToplotniUporabnik: this._tipUporabnika == TipUporabnika.ToplotniUporabnik ? true : false
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _initForm() {
        if (this._tipUporabnika == TipUporabnika.MestnaBlagajna) {
            ($("#ROLE_MESTNA_BLAGAJNA")[0] as HTMLInputElement).checked = true;
        }
        else if (this._tipUporabnika == TipUporabnika.Upravnik) {
            ($("#ROLE_UPRAVNIK")[0] as HTMLInputElement).checked = true;
        }
        else if (this._tipUporabnika == TipUporabnika.ToplotniUporabnik) {
            ($("#ROLE_TOPLOTNI_UPORABNIK")[0] as HTMLInputElement).checked = true;
        }
        else {
            ($("#ROLE_UPORABNIK")[0] as HTMLInputElement).checked = true;
        }

        ($("#ROLE_MESTNA_BLAGAJNA")[0] as HTMLInputElement).disabled = true;
        ($("#ROLE_UPRAVNIK")[0] as HTMLInputElement).disabled = true;
        ($("#ROLE_TOPLOTNI_UPORABNIK")[0] as HTMLInputElement).disabled = true;
        ($("#ROLE_UPORABNIK")[0] as HTMLInputElement).disabled = true;

        this._dodajValidacijskeFunkcije();
        this._formUporabniskiPodatki = $(("#form-uporabniski-podatki"));
        $.validator.unobtrusive.parse(this._formUporabniskiPodatki);
        this._formUporabniskiPodatki.validate();

        var btnDodajUporabnika = $("#btnDodajUporabnika");
        btnDodajUporabnika.on("click", (ev: Event) => this._onBtnDodajUporabnikaClick(ev, this._formUporabniskiPodatki));

        $("#check-input-aktiven").on("click", () => {
            if (($("#check-input-aktiven")[0] as HTMLInputElement).checked) {
                ($("#check-input-onemogocen")[0] as HTMLInputElement).checked = false;
            }
        });
        $("#check-input-onemogocen").on("click", () => {
            if (($("#check-input-onemogocen")[0] as HTMLInputElement).checked) {
                ($("#check-input-aktiven")[0] as HTMLInputElement).checked = false;
            }
        });
    }

    private _dodajValidacijskeFunkcije(): void {
        $.validator.addMethod("emailIsValid", function (email) {
            if (!email) {
                return true;
            }
            if (email.match(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-||_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/)) {
                return true;
            }
            return false;
        });
    }

    private async _onBtnDodajUporabnikaClick(ev: Event, formUporabniskiPodatki: any) {
        console.debug(ev);
        ev.preventDefault();
        this._loader.show();
        if (!formUporabniskiPodatki.valid()) {
            this._notyf.error(this._translationService.currentTranslations.CheckAllFields);
            this._loader.hide();
            return null;
        }
        var formData = new FormData(formUporabniskiPodatki[0] as HTMLFormElement);
        var izbraneVloge = new Array<string>();
        var roleElement = $(".role-input");
        for (let i = 0; i < roleElement.length; i++) {
            if ((roleElement[i] as HTMLInputElement).checked) {
                izbraneVloge.push((roleElement[i] as HTMLInputElement).value);
            }
        }

        var upravnikId = undefined;
        if (this._tipUporabnika == TipUporabnika.MestnaBlagajna) {
            upravnikId = Number(formData.get("mestnaBlagajnaId") as string);
        }
        else if (this._tipUporabnika == TipUporabnika.Upravnik) {
            upravnikId = Number(formData.get("upravnikId") as string);
        }

        var data: AdminDodajUporabnikRequest = {
            email: formData.get("email") as string,
            password: formData.get("geslo") as string,
            polnoIme: formData.get("polnoIme") as string,
            isActive: Number(($("#check-input-aktiven")[0] as HTMLInputElement).checked),
            isEnabled: Number(!($("#check-input-onemogocen")[0] as HTMLInputElement).checked),
            izbraneVloge: izbraneVloge,
            upravnikId: upravnikId
        }
        await this._adminApiClient.addUser(data)
            .then(() => {
                this._loader.hide();
                this._notyf.success(this._translationService.currentTranslations["SaveSuccessful"]);
            })
            .catch((ex: any) => {
                if (ex.statusCode == 400) {
                    var errorMessage = ex.message;
                    if (errorMessage) {
                        this._notyf.error(this._translationService.currentTranslations[errorMessage]);
                    }
                    else {
                        this._notyf.error(this._translationService.currentTranslations.UnexpectedError);
                    }
                    this._loader.hide();
                }
                else {
                    console.log(ex);
                    this._loader.hide();
                    this._notyf.error(this._translationService.currentTranslations.UnexpectedError);
                }
            });
    }
}
