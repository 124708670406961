import { inject, injectable, interfaces } from "inversify";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { MainLayout } from "../../views/main-layout";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import * as Mustache from "mustache";
import template from "../pages/kontakti-pogosta-vprasanja-kategorije-page.html";
import { TranslationService } from "../translation-service";
import { KontaktiPogostaVprasanjaKategorijeView } from "../../views/kontakti-pogosta-vprasanja-kategorije-view";
import { FullscreenLoader } from "../../views/fullscreen-loader";
import { PogostaVprasanjaApiClient } from "../clients/pogosta-vprasanja-api-client";
import { Notyf } from "notyf";
import { IntranetApiClient } from "../clients/intranet-api-client";
import { CurrentUserAccessor } from "../utilities/current-user-accessor";


@injectable()
export class KontaktiPogostaVprasanjaKategorijePage extends PageBase {
    public name = "KontaktiPogostaVprasanjaKategorijePage";
    private _router: Router;
    private _translationService: TranslationService;
    private _kontaktiPogostaVprasanjaOdgovorView: KontaktiPogostaVprasanjaKategorijeView;
    private _pogostaVprasanjaKategorijeElement: JQuery<HTMLElement>;
    private _pogostaVprasanjaApiClient: PogostaVprasanjaApiClient;
    private _intranetApiClient: IntranetApiClient;
    private _notyf: Notyf;
    private _loader: FullscreenLoader;
    private _currentUserAccessor: CurrentUserAccessor;

    constructor(@inject("Provider<MainLayout>") private _mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("Router") router: Router,
        @inject("TranslationService") translationService: TranslationService,
        @inject("PogostaVprasanjaApiClient") pogostaVprasanjaApiClient: PogostaVprasanjaApiClient,
        @inject("IntranetApiClient") intranetApiClient: IntranetApiClient,
        @inject("Notyf") notyf: Notyf,
        @inject("FullscreenLoader") loader: FullscreenLoader,
        @inject("CurrentUserAccessor") currentUserAccessor: CurrentUserAccessor) {
        super();

        this._router = router;
        this._translationService = translationService;
        this._pogostaVprasanjaApiClient = pogostaVprasanjaApiClient;
        this._intranetApiClient = intranetApiClient;
        this._notyf = notyf;
        this._loader = loader;
        this._currentUserAccessor = currentUserAccessor;

        const html = Mustache.render(template, {});
        $('#main').html(html);

        this._pogostaVprasanjaKategorijeElement = $('#main').find("#kontakti-pogosta-vprasanja-kategorije");
        this._kontaktiPogostaVprasanjaOdgovorView = new KontaktiPogostaVprasanjaKategorijeView(this._pogostaVprasanjaKategorijeElement,
            this._translationService, this._pogostaVprasanjaApiClient, this._intranetApiClient, this._notyf, this._currentUserAccessor);
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        if (!url.pathname.startsWith("/kontakti/kategorije-pogosta-vprasanja")) {
            return true;
        }
        var mainLayout = await this._mainLayoutProvider() as MainLayout;
        try {
            mainLayout.hideFooter();
            this._loader.show();
            await this._kontaktiPogostaVprasanjaOdgovorView.load();
            window.scrollTo(0, 0);
            var title = this._translationService.currentTranslations["FrequentlyAskedQuestionsAndAnswers"] + " - " + this._translationService.currentTranslations["Categories"];
            $(".title-strani")[0].innerHTML = title;
        } catch (e) {
            mainLayout.errorView.report(e);
        }
        finally {
            this._loader.hide();
            mainLayout.showFooter();
        }
        return false;
    }
}