import { injectable } from "inversify";
import * as Mustache from "mustache";
import { TranslationService } from "../ts/translation-service";
import template from "./test-2-view.html";

@injectable()
export class Test2View {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;

    constructor(rootElement: JQuery<HTMLElement>, translationService: TranslationService) {
        this.rootElement = rootElement;
        this._translationService = translationService;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        console.log("test 2 load.");
        this.rootElement.html(template);
        this._renderData();
    }

    private _renderData(): void {
        const viewModel = {
            translations: this._translationService.currentTranslations
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }
}