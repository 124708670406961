import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { UserApiClient } from "../ts/clients/users-api-client";
import { CurrentUserInfo } from "../ts/models/current-user-info";
import { ProfilSpremeniPodatke } from "../ts/models/profil-spremeni-podatke";
import { TranslationService } from "../ts/translation-service";
import { FullscreenLoader } from "./fullscreen-loader";
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { MerilnoMesto } from "../ts/models/merilno-mesto";
import { UporabnikMerilnoMestoRequest } from "../ts/models/uporabnik-merilno-mesto-request";
import { UporabnikMerilnoMestoResponse } from "../ts/models/uporabnik-merilno-mesto-response";
import { MerilnoMestoOdstrani } from "../ts/models/merilno-mesto-odstrani";
import { Authenticator } from "../../../skupno/src/ts/authentication/authenticator";
import { NastavitveZaObvescanjeModel } from "../ts/models/user-nastavitve-za-obvescanje";
import { MerilnoMestoInformacijeRequest } from "../ts/models/merilno-mesto-informacije-request";
import { MerilnoMestoInformacijeResponse } from "../ts/models/merilno-mesto-informacije-response";
import template from "./moj-profil-view.html";
import { KanalRequest, SpremembaTipaKanalaRequest } from "../ts/models/sprememba-tipa-kanala-request";

@injectable()
export class MojProfilView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _userApiClient: UserApiClient;
    private userData: CurrentUserInfo | null;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _merilnaMesta: Array<MerilnoMesto> | null;
    private _izbranoMerilnoMesto?: MerilnoMesto;
    private _dropdownMerilnaMestaElement: JQuery<HTMLElement> | null;
    private _authenticator: Authenticator;
    private _kanalAktivenOdbirki: boolean;
    private _kanalAktivenOpomini: boolean;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        userApiClient: UserApiClient,
        private _notyf: Notyf,
        private _loader: FullscreenLoader,
        merilnoMestoApiClient: MerilnoMestoApiClient,
        authenticator: Authenticator)
    {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._userApiClient = userApiClient;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._authenticator = authenticator;
        this.userData = null;
        this._merilnaMesta = null;
        this._dropdownMerilnaMestaElement = null;
        this._kanalAktivenOdbirki = false;
        this._kanalAktivenOpomini = false;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;
            await this._userApiClient.getCurrentUserInfo()
                .then((data: any) => {
                    this.userData = data;
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSearchingForCurrentUser"]);
                });
            var uporabnikMerilnoMestoRequest: UporabnikMerilnoMestoRequest = {
                limit: 0,
                stevilkaStrani: 0,
                saldo: false,
                skupnaPorabaEnergije: false,
                merilnoMesto: "",
                merilnaTocka: ""
            };
            await this._merilnoMestoApiClient.getUporabnikMerilnaMesta(uporabnikMerilnoMestoRequest)
                .then((odgovor: UporabnikMerilnoMestoResponse) => {
                    if (odgovor && odgovor.merilnaMesta && odgovor.merilnaMesta.length > 0) {
                        this._merilnaMesta = odgovor.merilnaMesta;
                        this._izbranoMerilnoMesto = this._merilnaMesta[0];
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSearchingForMeasuringPoints"]);
                });

            this._renderData(translations, this.userData);
            if ($("#page-loaded")[0] == undefined) return;
            this._initForm();
            await this._nastaviCheckboxObvestila();
            if (this._izbranoMerilnoMesto) {
                await this._nastaviCheckboxInformacijeMerilnoMesto();
            }
            await this._odstraniPrvoPrijavo();
            this._odpriObvescanje();
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(translations: any, userData: any): void
    {
        if (this._merilnaMesta) {
            var merilnaMestaIds = Array.from(new Set(this._merilnaMesta.map((item) => item.merilnoMesto)));
            var steviloPrikazanih = 3;
            var prikazanaMerilnaMesta = merilnaMestaIds.slice(0, steviloPrikazanih);
            if (merilnaMestaIds.length < steviloPrikazanih) {
                prikazanaMerilnaMesta = merilnaMestaIds;
            }
            var stevilkeKontoPogodbe = Array.from(new Set(this._merilnaMesta.map((item) => item.stevilkaKontoPogodbe)));
            const viewModel = {
                userData: userData,
                merilnaMestaIds: merilnaMestaIds,
                izbranoMerilnoMesto: this._izbranoMerilnoMesto,
                ostalaMerilnaMestaStevilo: merilnaMestaIds.length - prikazanaMerilnaMesta.length,
                obstajajoOstalaMerilnaMesta: merilnaMestaIds.length - prikazanaMerilnaMesta.length > 0 ? true : false,
                prikazanaMerilnaMesta: prikazanaMerilnaMesta,
                translations: translations,
                stevilkeKontoPogodbe: stevilkeKontoPogodbe,
                obstajaMerilnoMesto: true,
                prvaPrijava: this.userData?.prvaPrijava
            } as any;
            const html = Mustache.render(template, viewModel);
            this.rootElement.html(html);
        }
        else {
            const viewModel = {
                userData: userData,
                translations: translations,
                obstajaMerilnoMesto: false,
                prvaPrijava: this.userData?.prvaPrijava
            } as any;
            const html = Mustache.render(template, viewModel);
            this.rootElement.html(html);
        }
    }

    private _initForm(): void {

        // Podatki racuna
        $("#unlockInputs").on("click", () => {
            $("#podatki-racuna-form").find("input[id=polnoIme]").prop('disabled', false);
            $("#podatki-racuna-form").find("input[id=davcnaStevilka]").prop('disabled', false);
        });

        if (this.userData?.biomericLogin == true) {
            $("#checkbox-biometricna-prijava").prop("checked", "true")
        }
        else {
            $("#checkbox-biometricna-prijava").removeAttr("checked")
        }
        
        var btnShraniteSpremembePodatkiRacuna = $("#btnShraniSpremembePodatkiRacuna");
        btnShraniteSpremembePodatkiRacuna.on("click", (ev: Event) => this._onBtnShraniteSpremembePodatkiRacunaClick(ev));

        var btnOdstraniteRacun = $("#btnOdstraniteRacun");
        if (btnOdstraniteRacun) {
            btnOdstraniteRacun[0].addEventListener("click", () => {
                $(".odstrani-racuna").hide();
                $(".odstrani-racuna-varnostni-korak").show();
            });
        }

        var btnOdstraniRacunNo = $("#btn-odstrani-racuna-no");
        if (btnOdstraniRacunNo) {
            btnOdstraniRacunNo[0].addEventListener("click", () => {
                $(".odstrani-racuna").show();
                $(".odstrani-racuna-varnostni-korak").hide();
            });
        }

        var btnOdstraniRacunYes = $("#btn-odstrani-racuna-yes");
        if (btnOdstraniRacunYes) {
            btnOdstraniRacunYes[0].addEventListener("click", (ev: Event) => this._onBtnOdstraniteRacunClick(ev));
        }

        // Obvescanje
        this._dropdownMerilnaMestaElement = $("#dropdownMerilnaMesta");
        this._dropdownMerilnaMestaElement.on("change", (ev: Event) => this._onDropDownMerilnoMestoChange(ev));
        if (this._izbranoMerilnoMesto) {
            this._dropdownMerilnaMestaElement.val(this._izbranoMerilnoMesto.stevilkaKontoPogodbe);
        }

        var btnMerilnaMestaOthers = $("#btnMerilnaMestaOthers");
        btnMerilnaMestaOthers.on("click", () => {
            $(".merilna-mesta-not-all")[0].style.display = "none";
            $(".merilna-mesta-all")[0].style.display = "block";
        });  

        var btnIzberiteVsaObvestila = $(".btn-izberite-vsa-obvestila");
        if (btnIzberiteVsaObvestila) {
            btnIzberiteVsaObvestila[0].addEventListener("click", () => {
                ($("#checkbox-sistemska-obvestila")[0] as HTMLInputElement).checked = true;
                ($("#checkbox-posebne-akcije")[0] as HTMLInputElement).checked = true;
                ($("#checkbox-aktualne-novice")[0] as HTMLInputElement).checked = true;
                ($("#checkbox-push-obvestila")[0] as HTMLInputElement).checked = true;
            });
        }       

        var btnShraniSpremembeObvestila = $("#btn-shranite-nastavitve-obvestila");
        if (btnShraniSpremembeObvestila) {
            btnShraniSpremembeObvestila[0].addEventListener("click", (ev: Event) => this._onBtnShraniNastavitveObvescanjeClick(ev));
        }

        var odbirkeEmailCheckbox = $("#checkbox-odbirke-email");
        odbirkeEmailCheckbox.on("change", () => {
            if ((odbirkeEmailCheckbox[0] as HTMLInputElement).checked) {
                $(".div-odbirke-email").show();
                //($("#checkbox-odbirke-sms")[0] as HTMLInputElement).checked = false;
                //$(".div-odbirke-telefon").hide();
            }
            else {
                $(".div-odbirke-email").hide();
            }
        });

        var odbirkeSmsCheckBox = $("#checkbox-odbirke-sms");
        odbirkeSmsCheckBox.on("change", () => {
            if ((odbirkeSmsCheckBox[0] as HTMLInputElement).checked) {
                $(".div-odbirke-telefon").show();
                //($("#checkbox-odbirke-email")[0] as HTMLInputElement).checked = false;
                //$(".div-odbirke-email").hide();
            }
            else {
                $(".div-odbirke-telefon").hide();
            }
        });

        var opomineEmailCheckbox = $("#checkbox-opomine-email");
        opomineEmailCheckbox.on("change", () => {
            if ((opomineEmailCheckbox[0] as HTMLInputElement).checked) {
                $(".div-opomine-email").show();
                //($("#checkbox-opomine-sms")[0] as HTMLInputElement).checked = false;
                //$(".div-opomine-telefon").hide();
            }
            else {
                $(".div-opomine-email").hide();
            }
        });

        var opomineSmsCheckBox = $("#checkbox-opomine-sms");
        opomineSmsCheckBox.on("change", () => {
            if ((opomineSmsCheckBox[0] as HTMLInputElement).checked) {
                $(".div-opomine-telefon").show();
                //($("#checkbox-opomine-email")[0] as HTMLInputElement).checked = false;
                //$(".div-opomine-email").hide();
            }
            else {
                $(".div-opomine-telefon").hide();
            }
        });

        var btnShraniOdbirke = $("#btn-shranite-odbirke");
        btnShraniOdbirke.on("click", (ev: Event) => this._onBtnShraniOdbirkeClick(ev));

        var btnShraniOpomine = $("#btn-shranite-opomine");
        btnShraniOpomine.on("click", (ev: Event) => this._onBtnShraniOpomineClick(ev));

        if (this.userData && this.userData.prvaPrijava) {
            $("#obvescanje-tab")[0].click();
        }

        var novoGeslo = $("#novoGeslo");
        novoGeslo.on("keyup", () => {
            var input = $("#novoGeslo").val() as string;
            if (input.length >= 8) document.getElementsByClassName('regs atLeast')[0].classList.add("done");
            else document.getElementsByClassName('regs atLeast')[0].classList.remove("done");
            if (/[0-9]/.test(input)) document.getElementsByClassName('regs num')[0].classList.add("done");
            else document.getElementsByClassName('regs num')[0].classList.remove("done");
            if (!!input && input != input.toLocaleLowerCase()) document.getElementsByClassName('regs caps')[0].classList.add("done");
            else document.getElementsByClassName('regs caps')[0].classList.remove("done");
        });
    }

    private _odpriObvescanje() {
        if (window.location.href.includes("obvescanje")) {
            $("#obvescanje-tab")[0].click();
            window.history.pushState(null, "", "#/moj-profil");
        }
    }

    private _checkEmail(email: string) {
        email = email.toLowerCase();
        if (!email) {
            this._notyf.error(this._translationService.currentTranslations["CheckAllFields"]);
            return false;
        }
        else if (!email.match(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-||_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/)) {
            this._notyf.error(this._translationService.currentTranslations["EmailAddressIsNotInTheCorrectFormat"]);
            return false;
        }
        return true;
    }

    private async _spremeniTipaKanala(kanal: KanalRequest) {
        if (!this._izbranoMerilnoMesto) return;
        var kanali = new Array<KanalRequest>();
        kanali.push(kanal);
        var data: SpremembaTipaKanalaRequest = {
            stevilkaKontoPogodbe: this._izbranoMerilnoMesto.stevilkaKontoPogodbe,
            kanal: kanali
        };
        await this._merilnoMestoApiClient.spremembaTipaKanala(data)
            .then((odgovor: any) => {
                if (odgovor.kanal[0].status) {
                    if (data.kanal[0].id == "10") {
                        if (data.kanal[0].operacija == "OK") {
                            this._kanalAktivenOpomini = false;
                        }
                        else {
                            this._kanalAktivenOpomini = true;
                        }
                    }
                    else if (data.kanal[0].id = "11") {
                        if (data.kanal[0].operacija == "OK") {
                            this._kanalAktivenOdbirki = false;
                        }
                        else {
                            this._kanalAktivenOdbirki = true;
                        }
                    }
                    this._notyf.success(this._translationService.currentTranslations["SaveSuccessful"]);
                }
                else {
                    this._notyf.error(odgovor.kanal[0].sporocilo);
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
    }

    private async _onBtnShraniOdbirkeClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        var emailChecked = ($("#checkbox-odbirke-email")[0] as HTMLInputElement).checked;
        var smsChecked = ($("#checkbox-odbirke-sms")[0] as HTMLInputElement).checked;
        if (!emailChecked && !smsChecked) {
            // odjavimo ga iz kanala
            var kanal: KanalRequest = {
                id: "11",
                koda: "OBVODB",
                naziv: "obvestila odbirki",
                emailEracun: "",
                emailObvestila: "",
                iban: "",
                operacija: "OK",
                pushSporocilo: "",
                referenca: "",
                telefonskaStevilka: ""
            };
        }
        else {
            // prijavimo / spremenimo vrednost v kanal
            var kanal: KanalRequest = {
                id: "11",
                koda: "OBVODB",
                naziv: "obvestila odbirki",
                emailEracun: "",
                emailObvestila: "",
                iban: "",
                operacija: this._kanalAktivenOdbirki == true ? "SV" : "PK",
                pushSporocilo: "",
                referenca: "",
                telefonskaStevilka: ""
            };
            if (emailChecked) {
                var email = $("#odbirke-email").val() as string;
                if (!this._checkEmail(email)) {
                    return;
                }
                kanal.emailObvestila = email;
            }
            if (smsChecked) {
                var telefon = $("#odbirke-telefon").val() as string;
                if (!telefon) {
                    this._notyf.error(this._translationService.currentTranslations["CheckAllFields"]);
                    return false;
                }
                kanal.telefonskaStevilka = telefon;
            }
        }
        await this._spremeniTipaKanala(kanal);
    }

    private async _onBtnShraniOpomineClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        var emailChecked = ($("#checkbox-opomine-email")[0] as HTMLInputElement).checked;
        var smsChecked = ($("#checkbox-opomine-sms")[0] as HTMLInputElement).checked;
        if (!emailChecked && !smsChecked) {
            // odjavimo ga iz kanala
            var kanal: KanalRequest = {
                id: "10",
                koda: "OBVOPO",
                naziv: "obvestila opomini",
                emailEracun: "",
                emailObvestila: "",
                iban: "",
                operacija: "OK",
                pushSporocilo: "",
                referenca: "",
                telefonskaStevilka: ""
            };
        }
        else {
            // prijavimo / spremenimo vrednost v kanal
            var kanal: KanalRequest = {
                id: "10",
                koda: "OBVOPO",
                naziv: "obvestila opomini",
                emailEracun: "",
                emailObvestila: "",
                iban: "",
                operacija: this._kanalAktivenOpomini == true ? "SV" : "PK",
                pushSporocilo: "",
                referenca: "",
                telefonskaStevilka: ""
            };
            if (emailChecked) {
                var email = $("#opomine-email").val() as string;
                if (!this._checkEmail(email)) {
                    return;
                }
                kanal.emailObvestila = email;
            }
            if (smsChecked) {
                var telefon = $("#opomine-telefon").val() as string;
                if (!telefon) {
                    this._notyf.error(this._translationService.currentTranslations["CheckAllFields"]);
                    return false;
                }
                kanal.telefonskaStevilka = telefon;
            }
        }
        await this._spremeniTipaKanala(kanal);
    }

    private async _nastaviCheckboxInformacijeMerilnoMesto() {
        if (!this._izbranoMerilnoMesto) return;
        var data: MerilnoMestoInformacijeRequest = {
            merilnoMesto: this._izbranoMerilnoMesto.merilnoMesto,
            merilnaTocka: this._izbranoMerilnoMesto.merilnaTocka,
            stevilkaKontoPogodbe: this._izbranoMerilnoMesto.stevilkaKontoPogodbe
        };
        await this._merilnoMestoApiClient.getInformacije(data)
            .then((odgovor: MerilnoMestoInformacijeResponse) => {
                if (odgovor.obvestila && odgovor.obvestila.length > 0) {
                    // Odbirki
                    var odbirki = odgovor.obvestila.find(a => a.koda == "OBVODB");
                    if (odbirki) {
                        this._kanalAktivenOdbirki = odbirki.kanalAktiven;
                        // Odbirki - kanal aktiven
                        if (odbirki && odbirki.kanalAktiven) {
                            // email
                            if (odbirki.emailObvestila) {
                                ($("#checkbox-odbirke-email")[0] as HTMLInputElement).checked = true;
                                $(".div-odbirke-email").show();
                                ($("#odbirke-email")[0] as HTMLInputElement).value = odbirki.emailObvestila;
                            }
                            // telefon
                            if (odbirki.telefonskaStevilka) {
                                ($("#checkbox-odbirke-sms")[0] as HTMLInputElement).checked = true;
                                $(".div-odbirke-telefon").show();
                                ($("#odbirke-telefon")[0] as HTMLInputElement).value = odbirki.telefonskaStevilka;
                            }
                        }
                        // Odbirki - kanal ni aktiven
                        else {
                            ($("#checkbox-odbirke-email")[0] as HTMLInputElement).checked = false;
                            $(".div-odbirke-email").hide();
                            ($("#odbirke-email")[0] as HTMLInputElement).value = "";
                            ($("#checkbox-odbirke-sms")[0] as HTMLInputElement).checked = false;
                            $(".div-odbirke-telefon").hide();
                            ($("#odbirke-telefon")[0] as HTMLInputElement).value = "";
                        }
                    }
                    
                    // Opomini
                    var opomini = odgovor.obvestila.find(a => a.koda == "OBVOPO");
                    if (opomini) {
                        this._kanalAktivenOpomini = opomini.kanalAktiven;
                        // Opomini - kanal aktiven
                        if (opomini && opomini.kanalAktiven) {
                            // email
                            if (opomini.emailObvestila) {
                                ($("#checkbox-opomine-email")[0] as HTMLInputElement).checked = true;
                                $(".div-opomine-email").show();
                                ($("#opomine-email")[0] as HTMLInputElement).value = opomini.emailObvestila;
                            }
                            // telefon
                            if (opomini.telefonskaStevilka) {
                                ($("#checkbox-opomine-sms")[0] as HTMLInputElement).checked = true;
                                $(".div-opomine-telefon").show();
                                ($("#opomine-telefon")[0] as HTMLInputElement).value = opomini.telefonskaStevilka;
                            }
                        }
                        // Opomini - kanal ni aktiven
                        else {
                            ($("#checkbox-opomine-email")[0] as HTMLInputElement).checked = false;
                            $(".div-opomine-email").hide();
                            ($("#opomine-email")[0] as HTMLInputElement).value = "";
                            ($("#checkbox-opomine-sms")[0] as HTMLInputElement).checked = false;
                            $(".div-opomine-telefon").hide();
                            ($("#opomine-telefon")[0] as HTMLInputElement).value = "";
                        }
                    }
                }
                else {
                    // Odbirki skrijemo
                    ($("#checkbox-odbirke-email")[0] as HTMLInputElement).checked = false;
                    $(".div-odbirke-email").hide();
                    ($("#checkbox-odbirke-sms")[0] as HTMLInputElement).checked = false;
                    $(".div-odbirke-telefon").hide();

                    // Opomini skrijemo
                    ($("#checkbox-opomine-email")[0] as HTMLInputElement).checked = false;
                    $(".div-opomine-email").hide();
                    ($("#checkbox-opomine-sms")[0] as HTMLInputElement).checked = false;
                    $(".div-opomine-telefon").hide();
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
    }

    private async _nastaviCheckboxObvestila() {
        await this._userApiClient.preberiNastavitveZaObvescanje()
            .then((nastavitve: NastavitveZaObvescanjeModel) => {
                if (nastavitve.sistemskaObvestila) {
                    ($("#checkbox-sistemska-obvestila")[0] as HTMLInputElement).checked = true;
                }
                if (nastavitve.posebneAkcije) {
                    ($("#checkbox-posebne-akcije")[0] as HTMLInputElement).checked = true;
                }
                if (nastavitve.aktualneNovice) {
                    ($("#checkbox-aktualne-novice")[0] as HTMLInputElement).checked = true;
                }
                if (nastavitve.pushObvestila) {
                    ($("#checkbox-push-obvestila")[0] as HTMLInputElement).checked = true;
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
    }

    private async _onBtnShraniNastavitveObvescanjeClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        var data: NastavitveZaObvescanjeModel = {
            sistemskaObvestila: ($("#checkbox-sistemska-obvestila")[0] as HTMLInputElement).checked,
            posebneAkcije: ($("#checkbox-posebne-akcije")[0] as HTMLInputElement).checked,
            aktualneNovice: ($("#checkbox-aktualne-novice")[0] as HTMLInputElement).checked,
            pushObvestila: ($("#checkbox-push-obvestila")[0] as HTMLInputElement).checked
        };
        await this._userApiClient.shraniNastavitveZaObvescanje(data)
            .then((odgovor: any) => {
                if (odgovor) {
                    this._notyf.success(this._translationService.currentTranslations["SaveSuccessful"]);
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
    }

    private async _onDropDownMerilnoMestoChange(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        if (!this._merilnaMesta) {
            return;
        }
        this._dropdownMerilnaMestaElement = $("#dropdownMerilnaMesta");
        var dropdownMerilnoMestoValue = (this._dropdownMerilnaMestaElement[0] as any).value;
        this._izbranoMerilnoMesto = this._merilnaMesta.find(i => i.stevilkaKontoPogodbe == dropdownMerilnoMestoValue);
        if (this._izbranoMerilnoMesto) {
            $(".nazivPlacnika")[0].innerHTML = this._izbranoMerilnoMesto.nazivPlacnika;
            $(".naslovMesta")[0].innerHTML = this._izbranoMerilnoMesto.naslovMesta;
            $(".stevilkaKontoPogodbe")[0].innerHTML = this._izbranoMerilnoMesto.stevilkaKontoPogodbe;
            await this._nastaviCheckboxInformacijeMerilnoMesto();
        }
    }

    private async _onBtnShraniteSpremembePodatkiRacunaClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        var polnoIme = $("#polnoIme").val() as string;
        polnoIme = polnoIme.trim();
        polnoIme = polnoIme.replace(/ +(?= )/g, '');
        var davcnaStevilka = $("#davcnaStevilka").val() as string;
        var staroGeslo = $("#staroGeslo").val() as string;
        var novoGeslo = $("#novoGeslo").val() as string;
        var ponovljenoGeslo = $("#ponovljenoGeslo").val() as string;
     
        var biomericLogin = $("#checkbox-biometricna-prijava").prop("checked");
        // vsi podatki so prazni
        if (!polnoIme && !davcnaStevilka && !staroGeslo && !novoGeslo && !ponovljenoGeslo) {
            this._notyf.error(this._translationService.currentTranslations.YouMustEnterAtLeastOneInformationThatYouWantToChange);
            return;
        }
        // Uporabnik hoče spremeniti geslo, ampak je novo/ponovljeno geslo prazno
        else if ((novoGeslo || ponovljenoGeslo) && (!novoGeslo || !ponovljenoGeslo)) {
            this._notyf.error(this._translationService.currentTranslations.IfYouWantToChangePasswordText);
            return;
        }

        var data: ProfilSpremeniPodatke = {
            FullName: polnoIme,
            DavcnaStevilka: davcnaStevilka,
            BiomericLogin: biomericLogin,
            OldPassword: staroGeslo,
            NewPassword: novoGeslo,
            NewPasswordRepeat: ponovljenoGeslo
        }

        await this._userApiClient.profileChangeData(data)
            .then((odgovor: any) => {
                if (odgovor.status) {
                    this._notyf.success(this._translationService.currentTranslations["SaveSuccessful"]);
                }
                else {
                    this._notyf.error(this._translationService.currentTranslations[odgovor.sporocilo]);
                }
                this._loader.hide();
            })
            .catch((ex: any) => {
                if (ex.statusCode == 400) {
                    var errorMessage = ex.message;
                    if (errorMessage) {
                        this._notyf.error(this._translationService.currentTranslations[errorMessage]);
                    }
                    else {
                        this._notyf.error(this._translationService.currentTranslations.UnexpectedError);
                    }
                }
                else {
                    console.log(ex);
                    this._notyf.error(this._translationService.currentTranslations.UnexpectedError);
                }
                this._loader.hide();
            });
    }

    private async _onBtnOdstraniteRacunClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();

        // pridobimo vsa merilna mesta uporabnika
        var uporabnikMerilnoMestoRequest: UporabnikMerilnoMestoRequest = {
            limit: 0,
            stevilkaStrani: 0,
            saldo: false,
            skupnaPorabaEnergije: false,
            merilnoMesto: "",
            merilnaTocka: ""
        };
        var merilnaMesta = new Array<MerilnoMestoOdstrani>();
        await this._merilnoMestoApiClient.getUporabnikMerilnaMesta(uporabnikMerilnoMestoRequest)
            .then((odgovor: any) => {
                if (odgovor.merilnaMesta && odgovor.merilnaMesta.length > 0) {
                    for (let i = 0; i < odgovor.merilnaMesta.length; i++) {
                        var item: MerilnoMestoOdstrani = {
                            merilnoMesto: odgovor.merilnaMesta[i].merilnoMesto,
                            merilnaTocka: odgovor.merilnaMesta[i].merilnaTocka,
                            aktivacijskaKoda: odgovor.merilnaMesta[i].aktivacijskaKoda
                        };
                        merilnaMesta.push(item);
                    }
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileDeletingTheUserAccount"]);
            });

        // odstranimo vsa merilna mesta uporabniku
        if (merilnaMesta.length > 0) {
            await this._merilnoMestoApiClient.odstraniMerilnaMesta(merilnaMesta)
                .then(() => {
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileDeletingTheUserAccount"]);
                });
        }

        // izbrišemo uporabnika iz baze
        await this._userApiClient.deleteProfile()
            .then((data: any) => {
                if (data && data.success) {
                    //this._authenticator._clearLogin();
                    console.log(this._authenticator);
                    this._authenticator.onExternalLogout();
                    window.location = data.url;
                }
                else {
                    this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileDeletingTheUserAccount"]);
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileDeletingTheUserAccount"]);
            });
    }

    private async _odstraniPrvoPrijavo() {
        await this._userApiClient.odstraniPrvoPrijavo()
            .then(() => {
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
    }
}
