import { injectable } from "inversify";
import * as moment from "moment";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { UserApiClient } from "../ts/clients/users-api-client";
import { TranslationService } from "../ts/translation-service";
import template from "./datoteka-nalozi-view.html";
import { FullscreenLoader } from "./fullscreen-loader";
import priponkeTemplate from "./widget-nalozi-datoteko.html";

@injectable()
export class DatotekaNaloziView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _userApiClient: UserApiClient;
    private _fileViewList: Array<any>;
    private _uploadFilesElement: HTMLInputElement | null;
    private _fileList: any;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        userApiClient: UserApiClient,
        private _notyf: Notyf,
        private _loader: FullscreenLoader)
    {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._userApiClient = userApiClient;
        this._fileViewList = new Array<any>();
        this._uploadFilesElement = null;
        this._fileList = new Array<File>();
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;
            this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;
            this._renderAttachments();
        } catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }
    
    private _renderData(translations: any): void {
        var localeFormat = moment().creationData().locale.longDateFormat("L");
        var formators = {
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            }//tukaj se lahko dodajajo formatorji
        };
        const viewModel = {
            translations: translations,
            formators: formators
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _renderAttachments(): void {
        // Build a view model from the API data
        const viewModel = {
            "imageUrlDelete": "../img/icon-delete.svg",
            "files": this._fileViewList,
            "translations": this._translationService.currentTranslations
        } as any;
        // Construct a template
        const htmlTemplate = priponkeTemplate;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#attachments-widget').html(html);

        this._initUploadField();
        this._initDeleteAttachment();
    }

    //inicializira file upload polje
    public _initUploadField() {
        this._uploadFilesElement = document.getElementById("attachments") as HTMLInputElement;
        this._uploadFilesElement.addEventListener("change", (ev: Event) => this._onUploadChange(ev));
        var btnDodajIzbraneDatoteke = $("#btn-dodaj-izbrane-datoteke");
        btnDodajIzbraneDatoteke.on("click", (ev: Event) => this._onDodajIzbraneDatotekeClick(ev));
    }

    //shrani pripete dokumente v seznam za kasnej�e po�iljanje na stre�nik
    public _onUploadChange(ev: Event) {
        var th = this;
        const target = ev.target as HTMLInputElement;
        let list: FileList | null = target.files;
        if (list) {
            $(list).each(function (_index, element) {

                th._fileList.push(element);
            })
        }
        //Datoteke prekopiramo v view model, zaradi indexa
        this._fileViewList = [];
        for (let i in this._fileList) {
            this._fileViewList.push({ 'index': i, 'name': this._fileList[i].name });
        }
        this._renderAttachments();
    }

    //inicializira gumb za brisanje priponk
    public _initDeleteAttachment() {
        var self = this;
        $(".delete-attachment-btn").each(function () {
            var element = this as HTMLInputElement;
            element.addEventListener("click", (ev: Event) => self._onDeleteAttachment(ev));
            //$(this).on("click", (ev: Event) => self._onDeleteAttachment(ev));
        });
    }

    //event za brisanje priponk
    public _onDeleteAttachment(ev: Event) {
        const target = ev.currentTarget as HTMLInputElement;
        var indexString = target.getAttribute("data-index");
        if (!indexString) {
            return;
        }
        var index = parseInt(indexString);
        if (index || index == 0) {
            this._fileList.splice(index, 1);
        }
        this._fileViewList = [];
        for (let idx in this._fileList) {
            this._fileViewList.push({ 'index': idx, 'name': this._fileList[idx].name });
        }
        this._renderAttachments();
    }

    private async _onDodajIzbraneDatotekeClick(ev: Event) {
        ev.preventDefault();
        if (this._fileList.length == 0) {
            this._notyf.error(this._translationService.currentTranslations["YouHaveToSelectAFile"]);
            return;
        }

        this._loader.show();
        var formData = new FormData();
        for (var i = 0; i < this._fileList.length; i++) {
            formData.append("priloge", this._fileList[i]);
        }

        var uspesno = false;
        await this._userApiClient.dodajDatoteko(formData)
            .then((odgovor: any) => {
                if (odgovor.status) {
                    this._notyf.success(this._translationService.currentTranslations["SaveSuccessful"]);
                    uspesno = true;
                }
                else {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                }
            })
            .catch((ex: any) => {
                if (ex.statusCode == 400) {
                    this._loader.hide();
                    this._notyf.error(this._translationService.currentTranslations["SaveError"] + "\n" + ex.message);
                }
                else {
                    this._loader.hide();
                    this._notyf.error(this._translationService.currentTranslations["SaveError"]);
                }
            });
        
        this._loader.hide();

        if (uspesno) {
            this._fileList = new Array<File>();
            this._fileViewList = new Array<any>();
            this._renderAttachments();
        }
    }
}
