import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { PiskotkiApiClient } from "../ts/clients/piskotki-api-client";
import { TranslationService } from "../ts/translation-service";
import template from "./piskotki-view.html";

@injectable()
export class PiskotkiView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _piskotkiApiClient: PiskotkiApiClient;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        piskotkiApiClient: PiskotkiApiClient,
        private _notyf: Notyf) {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._piskotkiApiClient = piskotkiApiClient;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;
            var piskotki = await this._piskotkiApiClient.getPiskotki();
            if (!piskotki.status) {
                this._notyf.error(translations["UnexpectedError"]);
            }

            this._renderData(translations, piskotki.besedilo);
            if ($("#page-loaded")[0] == undefined) return;
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(translations: any, piskotkiBesedilo: string): void {
        const viewModel = {
            "piskotkiBesedilo": piskotkiBesedilo,
            translations: translations
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }
}