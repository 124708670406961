import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { MerilnoMesto } from "../ts/models/merilno-mesto";
import { PorabaNapravIzracunRequest } from "../ts/models/poraba-naprav-izracun-request";
import { PorabaNapravPorabnikiResponse, Porabnik } from "../ts/models/poraba-naprav-porabniki-response";
import { UporabnikMerilnoMestoRequest } from "../ts/models/uporabnik-merilno-mesto-request";
import { UporabnikMerilnoMestoResponse } from "../ts/models/uporabnik-merilno-mesto-response";
import { TranslationService } from "../ts/translation-service";
import template from "./merilno-mesto-poraba-naprav-view.html";

@injectable()
export class MerilnoMestoPorabaNapravView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _merilnaMesta: Array<MerilnoMesto> | null;
    private _izbranoMerilnoMesto: MerilnoMesto | null | undefined;
    private _dropdownMerilnaMestaElement: JQuery<HTMLElement> | null;
    private _dropdownNapraveElement: JQuery<HTMLElement> | null;
    private _btnIzracunajte: JQuery<HTMLElement> | null;
    private _btnUreTeden: JQuery<HTMLElement> | null;
    private _btnUreDan: JQuery<HTMLElement> | null;
    private _porabniki: Array<Porabnik>;
    private _izbranPorabnik: Porabnik | null;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        merilnoMestoApiClient: MerilnoMestoApiClient,
        private _notyf: Notyf)
    {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._merilnaMesta = null;
        this._izbranoMerilnoMesto = null;
        this._dropdownMerilnaMestaElement = null;
        this._dropdownNapraveElement = null;
        this._btnIzracunajte = null;
        this._btnUreTeden = null;
        this._btnUreDan = null;
        this._porabniki = new Array<Porabnik>();
        this._izbranPorabnik = null;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;
            var uporabnikMerilnoMestoRequest: UporabnikMerilnoMestoRequest = {
                limit: 0,
                stevilkaStrani: 0,
                saldo: false,
                skupnaPorabaEnergije: false,
                merilnoMesto: "",
                merilnaTocka: ""
            };
            await this._merilnoMestoApiClient.getUporabnikMerilnaMesta(uporabnikMerilnoMestoRequest)
                .then((odgovor: UporabnikMerilnoMestoResponse) => {
                    this._merilnaMesta = odgovor.merilnaMesta;
                    if (this._merilnaMesta && this._merilnaMesta.length > 0) {
                        // poiscemo ali obstaja selektirano MM (v querystringu)
                        var url_string = window.location.href.split("?");
                        var url_data = url_string[url_string.length - 1];
                        var data_list = url_data.split("_");
                        const stevilkaKontoPogodbe = data_list[data_list.length - 1];
                        const merilnoMesto = data_list[data_list.length - 2];
                        const jeElektrika = data_list[data_list.length - 3];
                        const merilnaTocka = data_list[data_list.length - 4];
                        
                        if (jeElektrika == 'true') {
                            this._izbranoMerilnoMesto = this._merilnaMesta.find(
                                a => a.merilnoMesto == merilnoMesto && a.merilnaTocka == merilnaTocka && a.stevilkaKontoPogodbe == stevilkaKontoPogodbe);
                        }
                        else if (jeElektrika == 'false') {
                            this._izbranoMerilnoMesto = this._merilnaMesta.find(
                                a => a.merilnoMesto == merilnoMesto && a.stevilkaKontoPogodbe == stevilkaKontoPogodbe);
                        }
                        // ce v querystringu ni doloceno MM, selektiramo prvega MM iz liste
                        if (!this._izbranoMerilnoMesto) {
                            this._izbranoMerilnoMesto = this._merilnaMesta[0];
                        }
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSearchingForMeasuringPoints"]);
                    return;
                });

            if (this._izbranoMerilnoMesto) {
                await this._merilnoMestoApiClient.getPorabniki()
                    .then((odgovor: PorabaNapravPorabnikiResponse) => {
                        if (odgovor.status) {
                            this._porabniki = odgovor.porabniki;
                            if (this._porabniki.length > 0) {
                                this._izbranPorabnik = this._porabniki[0];
                            }
                        }
                        else {
                            this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                            return;
                        }
                    })
                    .catch(() => {
                        this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                        return;
                    });
            }
            
            this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;
            if (this._izbranoMerilnoMesto) {
                this._initForm();
            }
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(translations: any): void {
        const viewModel = {
            merilnaMesta: this._merilnaMesta,
            porabniki: this._porabniki,
            izbranoMerilnoMesto: this._izbranoMerilnoMesto,
            translations: translations,
            obstajaMerilnoMesto: this._izbranoMerilnoMesto ? true : false
        } as any;
        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _nastaviMocInCas() {      
        var mocNapraveSlider = $("#moc-naprave-range");
        var casUporabeSlider = $("#cas-uporabe-range");

        var mocNapraveSliderElement = (mocNapraveSlider[0] as HTMLInputElement);
        var casUporabeSliderElement = (casUporabeSlider[0] as HTMLInputElement);

        if (this._izbranPorabnik) {
            mocNapraveSliderElement.value = this._izbranPorabnik.moc.toString();
            casUporabeSliderElement.value = this._izbranPorabnik.casDelovanja.toString();

            // add event listeners to update this._izbranPorabnik.moc and this._izbranPorabnik.casDelovanja when the sliders are changed
            mocNapraveSliderElement.addEventListener('input', () => {
                if (this._izbranPorabnik != null) 
                    this._izbranPorabnik.moc = Number(mocNapraveSliderElement.value)
                    
            });

            casUporabeSliderElement.addEventListener('input', () => {
                if (this._izbranPorabnik != null) 
                    this._izbranPorabnik.casDelovanja = Number(casUporabeSliderElement.value);
            });
           
            if (this._izbranPorabnik.interval == "URDAN") {
                ($("#radioUrTeden")[0] as HTMLInputElement).checked = false;
                ($("#radioUrDan")[0] as HTMLInputElement).checked = true;
            }
            else {
                ($("#radioUrDan")[0] as HTMLInputElement).checked = false;
                ($("#radioUrTeden")[0] as HTMLInputElement).checked = true;
            }
        }

        mocNapraveSliderElement.style.setProperty('--value', mocNapraveSliderElement.value)
        mocNapraveSliderElement.style.setProperty('--min', mocNapraveSliderElement.min == '' ? '0' : mocNapraveSliderElement.min)
        mocNapraveSliderElement.style.setProperty('--max', mocNapraveSliderElement.max == '' ? '0' : mocNapraveSliderElement.max)
        mocNapraveSliderElement.addEventListener('input', () => mocNapraveSliderElement.style.setProperty('--value', mocNapraveSliderElement.value));
        var mocNapraveOutput = $("#moc-naprave");
        mocNapraveOutput[0].innerHTML = mocNapraveSliderElement.value;
        mocNapraveSliderElement.oninput = function () {
            mocNapraveOutput[0].innerHTML = mocNapraveSliderElement.value;
        }

        casUporabeSliderElement.style.setProperty('--value', casUporabeSliderElement.value)
        casUporabeSliderElement.style.setProperty('--min', casUporabeSliderElement.min == '' ? '0' : casUporabeSliderElement.min)
        casUporabeSliderElement.style.setProperty('--max', casUporabeSliderElement.max == '' ? '0' : casUporabeSliderElement.max)
        casUporabeSliderElement.addEventListener('input', () => casUporabeSliderElement.style.setProperty('--value', casUporabeSliderElement.value));
        var casUporabeOutput = $("#cas-uporabe");
        casUporabeOutput[0].innerHTML = casUporabeSliderElement.value;
        casUporabeSliderElement.oninput = function () {
            casUporabeOutput[0].innerHTML = casUporabeSliderElement.value;
        }
    }

    private _initForm(): void {
        if (!this._merilnaMesta || !this._izbranoMerilnoMesto) {
            return;
        }

        this._dropdownMerilnaMestaElement = $("#dropdownMerilnaMesta");
        this._dropdownMerilnaMestaElement.on("change", (ev: Event) => this._onDropDownMerilnoMestoChange(ev));
        if (this._izbranoMerilnoMesto.jeElektrika) {
            this._dropdownMerilnaMestaElement.val(this._izbranoMerilnoMesto.jeElektrika + "_" + this._izbranoMerilnoMesto.stevilkaKontoPogodbe + "_" + this._izbranoMerilnoMesto.merilnoMesto + "_" + this._izbranoMerilnoMesto.merilnaTocka);
        }
        else {
            this._dropdownMerilnaMestaElement.val(this._izbranoMerilnoMesto.jeElektrika + "_" + this._izbranoMerilnoMesto.stevilkaKontoPogodbe + "_" + this._izbranoMerilnoMesto.merilnoMesto);
        }

        this._dropdownNapraveElement = $("#dropdownNaprave");
        this._dropdownNapraveElement.on("change", (ev: Event) => this._onDropDownNapraveChange(ev));

        this._nastaviMocInCas();

        this._btnIzracunajte = $("#btnIzracunajte");
        this._btnIzracunajte.on("click", (ev: Event) => this._onBtnIzracunajteClick(ev));

        this._btnUreTeden = $("#radioUrTeden");
        this._btnUreTeden.on("click", (ev: Event) => this._onBtnUreTedenClick(ev));

        this._btnUreDan = $("#radioUrDan");
        this._btnUreDan.on("click", (ev: Event) => this._onBtnUreDanClick(ev));
    }

    private _onDropDownMerilnoMestoChange(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        this._dropdownMerilnaMestaElement = $("#dropdownMerilnaMesta");

        this._dropdownMerilnaMestaElement = $("#dropdownMerilnaMesta");
        var dropdownMerilnoMestoValues = (this._dropdownMerilnaMestaElement[0] as any).value.split("_");

        var selectedJeElektrika = dropdownMerilnoMestoValues[0]
        var selectedStevilkaKontoPogodbe = dropdownMerilnoMestoValues[1]
        var selectedMerilnoMesto = dropdownMerilnoMestoValues[2]    

        if (selectedJeElektrika == 'true') {
            var selectedMerilnaTocka = dropdownMerilnoMestoValues[3]
            location.href = "#/merilno-mesto/naprave?" + selectedMerilnaTocka + "_" + selectedJeElektrika + "_" + selectedMerilnoMesto + "_" + selectedStevilkaKontoPogodbe;
        }
            
        else
            location.href = "#/merilno-mesto/naprave?" + selectedJeElektrika + "_" + selectedMerilnoMesto + "_" + selectedStevilkaKontoPogodbe;        
    }

    private _onDropDownNapraveChange(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        this._dropdownNapraveElement = $("#dropdownNaprave");
        var dropdownNapraveValue = (this._dropdownNapraveElement[0] as any).value;
        var novPorabnik = this._porabniki.find(a => a.naziv == dropdownNapraveValue);
        if (novPorabnik) {
            this._izbranPorabnik = novPorabnik;
        }
        else {
            this._izbranPorabnik = null;
        }
        this._nastaviMocInCas();
    }

    private _onBtnIzracunajteClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        if (!this._izbranoMerilnoMesto || !this._izbranPorabnik) {
            return;
        }

        var interval = "";
        if (($("#radioUrDan")[0] as HTMLInputElement).checked) {
            interval = "URDAN";
        }
        else {
            interval = "URTEDEN";
        }

        var porabaNapravIzracunRequest: PorabaNapravIzracunRequest = {
            merilnoMesto: this._izbranoMerilnoMesto.merilnoMesto,
            merilnaTocka: this._izbranoMerilnoMesto.merilnaTocka,
            stevilkaKontoPogodbe: this._izbranoMerilnoMesto.stevilkaKontoPogodbe,
            koda: this._izbranPorabnik.koda,
            naziv: this._izbranPorabnik.naziv,
            moc: Number($("#moc-naprave")[0].innerHTML),
            casDelovanja: Number($("#cas-uporabe")[0].innerHTML),
            interval: interval
            
        };
    
        this._merilnoMestoApiClient.porabaNapravIzracun(porabaNapravIzracunRequest)
            .then((odgovor) => {
                this._dropdownNapraveElement = $("#dropdownNaprave");
                var dropdownNapraveValue = (this._dropdownNapraveElement[0] as any).value;                
                $("#poraba-text")[0].innerHTML = this._translationService.currentTranslations["CalculatedConsumptionFor"] + ": " + dropdownNapraveValue;
                
                if (odgovor.sporocilo) {
                    $("#sporocilo")[0].innerHTML = odgovor.sporocilo;
                }
                else {
                    $("#sporocilo")[0].innerHTML = this._translationService.currentTranslations["CalculatedConsumptionText"];
                }
                $("#dnevna-poraba-kwh")[0].innerHTML = odgovor.porabaKWH + " KWH";
                if (!odgovor.valuta) {
                    $("#znesek-eur")[0].innerHTML = odgovor.znesekDDV + " EUR";
                }
                else {
                    $("#znesek-eur")[0].innerHTML = odgovor.znesekDDV + " " + odgovor.valuta;
                }
                $("#izracunana-poraba").show();
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                return;
            });
    }
    private _onBtnUreTedenClick(ev: Event) {
        console.debug(ev)    
        
        if (this._izbranPorabnik != null) {
            this._izbranPorabnik.interval = "URTEDEN"
        }
       
    }
    private _onBtnUreDanClick(ev: Event) {
        console.debug(ev)      
        
        if (this._izbranPorabnik != null) {
            this._izbranPorabnik.interval == "URDAN"
        }
    }
    

}