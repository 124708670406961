import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { IntranetApiClient } from "../ts/clients/intranet-api-client";
import { PogostaVprasanjaApiClient } from "../ts/clients/pogosta-vprasanja-api-client";
import { KategorijaPogostaVprasanjaResponse } from "../ts/models/kategorija-pogosta-vprasanja-response";
import { TranslationService } from "../ts/translation-service";
import { CurrentUserAccessor } from "../ts/utilities/current-user-accessor";
import template from "./kontakti-pogosta-vprasanja-kategorije-view.html";

@injectable()
export class KontaktiPogostaVprasanjaKategorijeView {
    private rootElement: JQuery<HTMLElement>;
    private _pogostaVprasanjaApiClient: PogostaVprasanjaApiClient;
    private _intranetApiClient: IntranetApiClient;
    private _translationService: TranslationService;
    private _kategorije: Array<KategorijaPogostaVprasanjaResponse>;
    private _currentUserAccessor: CurrentUserAccessor;
    private _jeAdmin: boolean;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        pogostaVprasanjaApiClient: PogostaVprasanjaApiClient,
        intranetApiClient: IntranetApiClient,
        private _notyf: Notyf,
        currentUserAccessor: CurrentUserAccessor)
    {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._pogostaVprasanjaApiClient = pogostaVprasanjaApiClient;
        this._intranetApiClient = intranetApiClient;
        this._currentUserAccessor = currentUserAccessor;
        this._kategorije = new Array<KategorijaPogostaVprasanjaResponse>();
        this._jeAdmin = false;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;
            var user = await this._currentUserAccessor.getUser();
            var adminRole = user.roles.find(a => a.role1 == "ROLE_ADMINISTRATOR");
            if (adminRole) {
                this._jeAdmin = true;
            }
            await this._pridobiKategorije("");
            this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;
            this._initForm(translations);
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(translations: any): void {
        const viewModel = {
            translations: translations,
            kategorije: this._kategorije,
            jeAdmin: this._jeAdmin
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _initForm(translations: any) {
        var isciBtn = $("#button-isci");
        isciBtn[0].addEventListener("click", async () => {
            var filter = ($(".kategorija-search-field")[0] as HTMLInputElement).value;
            await this._pridobiKategorije(filter);
            this._renderData(translations);
            this._initForm(translations);
            ($(".kategorija-search-field")[0] as HTMLInputElement).value = filter;
        });

        var dodajKategorijoBtn = $("#btn-dodaj-kategorijo");
        if (dodajKategorijoBtn.length > 0) {
            dodajKategorijoBtn[0].addEventListener("click", async () => {
                try {
                    await this._intranetApiClient.getDodajanjeKategorijoUrl().then((data) => {
                        window.open(data.url, "_blank");
                    });
                } catch (e) {
                    this._notyf.error("Pri�lo je do napake pri preusmeritvi!");
                    console.debug(e);
                }
            });
        }

        var izbrisiKategorijoBtn = $("#btn-izbrisi-kategorijo");
        if (izbrisiKategorijoBtn.length > 0) {
            izbrisiKategorijoBtn[0].addEventListener("click", async () => {
                try {
                    await this._intranetApiClient.getBrisanjeKategorijoUrl().then((data) => {
                        window.open(data.url, "_blank");
                    });
                } catch (e) {
                    this._notyf.error("Pri�lo je do napake pri preusmeritvi!");
                    console.debug(e);
                }
            });
        } 

        var kategorijeElement = $(".kategorija");
        for (let i = 0; i < kategorijeElement.length; i++) {
            kategorijeElement[i].addEventListener("click", () => {
                window.location.href = "#/kontakti/pogosta-vprasanja-seznam/" + this._kategorije[i].id;
            })
        }
    }

    private async _pridobiKategorije(filter: string) {
        await this._pogostaVprasanjaApiClient.getKategorije(filter)
            .then((odgovor) => {
                this._kategorije = odgovor;
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
    }
}