import { inject, injectable, interfaces } from "inversify";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { MainLayout } from "../../views/main-layout";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import * as Mustache from "mustache";
import template from "../pages/merilno-mesto-poraba-page.html";
import { TranslationService } from "../translation-service";
import { MerilnoMestoApiClient } from "../clients/merilno-mesto-api-client";
import { Notyf } from "notyf";
import { FullscreenLoader } from "../../views/fullscreen-loader";
import { MerilnoMestoPorabaView } from "../../views/merilno-mesto-poraba-view";

@injectable()
export class MerilnoMestoPorabaPage extends PageBase {
    public name = "MerilnoMestoPorabaPage";
    private _router: Router;
    private _translationService: TranslationService;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _merilnoMestoPorabaView: MerilnoMestoPorabaView;
    private _merilnoMestoPorabaElement: JQuery<HTMLElement>;
    private _notyf: Notyf;
    private _loader: FullscreenLoader;

    constructor(@inject("Provider<MainLayout>") private _mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("Router") router: Router,
        @inject("TranslationService") translationService: TranslationService,
        @inject("MerilnoMestoApiClient") merilnoMestoApiClient: MerilnoMestoApiClient,
        @inject("Notyf") notyf: Notyf,
        @inject("FullscreenLoader") loader: FullscreenLoader) {
        super();

        this._router = router;
        this._translationService = translationService;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._notyf = notyf;
        this._loader = loader;

        const viewModel = {
            translations: this._translationService.currentTranslations
        } as any;
        const html = Mustache.render(template, viewModel);
        $('#main').html(html);

        this._merilnoMestoPorabaElement = $('#main').find("#merilno-mesto-poraba");
        this._merilnoMestoPorabaView = new MerilnoMestoPorabaView(this._merilnoMestoPorabaElement,
            this._translationService, this._merilnoMestoApiClient, this._notyf, this._loader);
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        if (!url.pathname.startsWith("/merilno-mesto/poraba")) {
            return true;
        }
        var mainLayout = await this._mainLayoutProvider() as MainLayout;
        try {
            mainLayout.hideFooter();
            this._loader.show();
            await this._merilnoMestoPorabaView.load();
            window.scrollTo(0, 0);
            $(".title-strani")[0].innerHTML = this._translationService.currentTranslations["Consumption"];
            this._merilnoMestoPorabaView._porabaPregled();
        } catch (e) {
            mainLayout.errorView.report(e);
        }
        finally {
            this._loader.hide();
            mainLayout.showFooter();
        }
        return false;
    }
}