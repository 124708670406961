import { injectable } from "inversify";
import * as moment from "moment";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { MojPlusApiClient } from "../ts/clients/moj-plus-api-client";
import { PodatkiMojPlusResponse } from "../ts/models/podatki-moj-plus-response";
import { TranslationService } from "../ts/translation-service";
import template from "./moj-plus-moje-tocke-pregled-view.html";

@injectable()
export class MojPlusMojeTockeView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _mojPlusApiClient: MojPlusApiClient;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _dropdownStevilkaRacuna: JQuery<HTMLElement> | null;
    private _stevilkeKontoPogodb: Array<string>;
    private _izbranaKontoPogodba: string;
    private _podatkiMojPlus: PodatkiMojPlusResponse | null;
    private _limit: number;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        mojPlusApiClient: MojPlusApiClient,
        merilnoMestoApiClient: MerilnoMestoApiClient,
        private _notyf: Notyf) {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._mojPlusApiClient = mojPlusApiClient;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._dropdownStevilkaRacuna = null;
        this._stevilkeKontoPogodb = new Array<string>();
        this._izbranaKontoPogodba = "";
        this._podatkiMojPlus = null;
        this._limit = 10;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;
            await this._merilnoMestoApiClient.getStevilkeKontoPogodb()
                .then((odgovor) => {
                    this._stevilkeKontoPogodb = odgovor.filter((value: any, index: any, array: any) => array.indexOf(value) === index);
                    if (this._stevilkeKontoPogodb.length > 0) {
                        // poiscemo ali obstaja selektira �tevilka racuna (v querystringu)
                        var url = window.location.href.split("/");
                        var izbranaKontoPogodbaId = url[url.length - 1];
                        var kontoPogodba = this._stevilkeKontoPogodb.find(a => a == izbranaKontoPogodbaId);
                        if (kontoPogodba) {
                            this._izbranaKontoPogodba = kontoPogodba;
                        }
                        else {
                            // ce v querystringu ni dolocena konto pogodba, selektiramo prvo pogodbo iz liste
                            this._izbranaKontoPogodba = this._stevilkeKontoPogodb[0];
                        }
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    return;
                });

            if (this._izbranaKontoPogodba) {
                await this._mojPlusApiClient.getMojPlusPodatki(this._izbranaKontoPogodba)
                    .then((odgovor) => {
                        if (odgovor.status) {
                            this._podatkiMojPlus = odgovor;
                        }
                    })
                    .catch(() => {
                        this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    });
            }

            this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;
            if (this._izbranaKontoPogodba) {
                this._initForm();
            }
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(translations: any): void {
        const viewModel = {
            translations: translations,
            stevilkeKontoPogodbe: this._stevilkeKontoPogodb,
            podatkiMojPlus: this._podatkiMojPlus,
            mojPlusRacunObstaja: this._podatkiMojPlus != null ? true : false,
            izbranaKontoPogodba: this._izbranaKontoPogodba,
            zbraneTocke: this._podatkiMojPlus && this._podatkiMojPlus.seznamZbranihTock ? this._podatkiMojPlus.seznamZbranihTock : null,
            obstajajoZbraneTocke: this._podatkiMojPlus && this._podatkiMojPlus.seznamZbranihTock && this._podatkiMojPlus.seznamZbranihTock.length > 0 ? true : false,
            obstajaKontoPogodba: this._izbranaKontoPogodba == "" ? false : true
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);

        if (this._podatkiMojPlus && this._podatkiMojPlus.seznamVsehTock && this._podatkiMojPlus.seznamVsehTock.length > 0) {
            this._refreshTables(this._podatkiMojPlus.seznamVsehTock, 1, "vse");
        }
    }

    private _initForm(): void {
        this._dropdownStevilkaRacuna = $("#dropdownStevilkaRacuna");
        this._dropdownStevilkaRacuna.on("change", (ev: Event) => this._onDropDownStevilkaRacunaChange(ev));
        if (this._izbranaKontoPogodba) {
            this._dropdownStevilkaRacuna.val(this._izbranaKontoPogodba);
        }
        var pridobljeneTockeElement = $(".statusPridobljeneTocke");
        if (pridobljeneTockeElement) {
            pridobljeneTockeElement.on("click", () => {
                if (this._podatkiMojPlus) {
                    this._refreshTables(this._podatkiMojPlus.seznamZbranihTock, 1, "pridobljene");
                }
                else {
                    this._refreshTables(new Array<any>, 1, "pridobljene");
                }
            });
        }
        var korisceneTockeElement = $(".statusKorisceneTocke");
        if (korisceneTockeElement) {
            korisceneTockeElement.on("click", () => {
                if (this._podatkiMojPlus) {
                    this._refreshTables(this._podatkiMojPlus.seznamKoriscenihTock, 1, "koriscene");
                }
                else {
                    this._refreshTables(new Array<any>, 1, "koriscene");
                }
            });
        }
        var vseTockeElement = $(".statusVseTocke");
        if (vseTockeElement) {
            vseTockeElement.on("click", () => {
                if (this._podatkiMojPlus) {
                    this._refreshTables(this._podatkiMojPlus.seznamVsehTock, 1, "vse");
                }
                else {
                    this._refreshTables(new Array<any>, 1, "vse");
                }
            });
        }
    }

    private _onDropDownStevilkaRacunaChange(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        this._dropdownStevilkaRacuna = $("#dropdownStevilkaRacuna");
        var dropdownMerilnoMestoValue = (this._dropdownStevilkaRacuna[0] as any).value;
        location.href = "#/moj-plus/moje-tocke/" + dropdownMerilnoMestoValue;
    }

    private _refreshTables(seznamVsehTock: Array<any>, page: number, tipTock: string) {
        var displayedSeznamTock = new Array<any>();
        if (seznamVsehTock && seznamVsehTock.length > 0) {
            for (let i = (page - 1) * this._limit; i < page * this._limit; i++) {
                if (i >= seznamVsehTock.length) {
                    break;
                }
                displayedSeznamTock.push(seznamVsehTock[i]);
            }
        }
        this._refreshMojeTockeMobile(displayedSeznamTock, tipTock);
        this._refreshMojeTockeDesktop(displayedSeznamTock, tipTock);
        $(".tabela-desktop").addClass("d-lg-block");
        $(".tabela-desktop").removeClass("d-lg-none");
        $(".tabela-mobile").addClass("d-block");
        $(".tabela-mobile").removeClass("d-none");
        this._refreshPagination(seznamVsehTock, page);
    }

    private _refreshMojeTockeMobile(displayedSeznamVsehTock: any, tipTock: string) {
        var localeFormat = moment().creationData().locale.longDateFormat("L");
        var formators = {
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            }//tukaj se lahko dodajajo formatorji
        };
        const templateString = `
            <h3 class="margin-small"><%{translations.OverviewOfYourPoints}%></h3>
            <table class="table energetska-izkaznica-mobile">    
                <tbody>
                    <%#tocke%>
                    <tr>
                        <td class="table-inner-wrap">
                            <div class="table-responsive">
                                <table class="table table-borderless">
                                    <tbody>
                                        <tr>
                                            <th scope="row"><%{translations.ClaimNumber}%></th>
                                            <%#izpisNagrade%>
                                                <td><a data-stevilkazahtevka="<%{stevilkaZahtevka}%>" class="link btn-stevilka-zahtevka-mobile" role="button"><%{stevilkaZahtevka}%></a></td>
                                            <%/izpisNagrade%>
                                            <%^izpisNagrade%>
                                                <td><%{stevilkaZahtevka}%></td>
                                            <%/izpisNagrade%>
                                        </tr>
                                         <tr>
                                            <th scope="row"><%{translations.Type}%></th>
                                            <td><%{tip}%></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><%{translations.Code}%></th>
                                            <td><%{koda}%></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><%{translations.Activity}%></th>
                                            <td><%{aktivnost}%></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><%{translations.Status}%></th>
                                            <td><%{status}%></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><%{translations.Date}%></th>
                                            <td><%#formators%><%#dateFormat%><%datum%><%/dateFormat%><%/formators%></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><%{translations.NumberOfPoints}%></th>
                                            <td><%{steviloTock}%></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>
                <%/tocke%>
                </tbody>
            </table> 
             `;
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                formators: formators,
                "tocke": displayedSeznamVsehTock,
                "soKorisceneTocke": tipTock == "koriscene",
                "soPridobljeneTocke": tipTock == "pridobljene",
                "soVseTocke": tipTock == "vse",
                "translations": this._translationService.currentTranslations
            },
            undefined,
            customTags
        );
        $(".tabela-mobile").html(html);

        if (displayedSeznamVsehTock && displayedSeznamVsehTock.length > 0) {
            var stevilkeZahtevButtons = $(".btn-stevilka-zahtevka-mobile");
            for (let i = 0; i < stevilkeZahtevButtons.length; i++) {
                stevilkeZahtevButtons[i].addEventListener("click", (ev: Event) => {
                    var stevilkaZahtevka = stevilkeZahtevButtons[i].dataset["stevilkazahtevka"];
                    this._onBtnIzpisNagrade(ev, stevilkaZahtevka);
                });
            }
        }
    }

    private _refreshMojeTockeDesktop(displayedSeznamVsehTock: any, tipTock: string) {
        var localeFormat = moment().creationData().locale.longDateFormat("L");
        var formators = {
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            }//tukaj se lahko dodajajo formatorji
        };
        const templateString = `
            <h3 class="margin-small"><%{translations.OverviewOfYourPoints}%></h3>
            <table class="table energetska-izkaznica">
                <thead>
                    <tr>
                        <td scope="col"><%{translations.ClaimNumber}%></td>
                        <td scope="col"><%{translations.Type}%></td>
                        <td scope="col"><%{translations.Code}%></td>
                        <td scope="col"><%{translations.Activity}%></td>
                        <td scope="col"><%{translations.Status}%></td>
                        <td scope="col"><%{translations.Date}%></td>
                        <td scope="col"><%{translations.NumberOfPoints}%></td>
                    </tr>
                </thead>
                <tbody>
                    <%#tocke%>
                    <tr>
                        <%#izpisNagrade%>
                            <td scope="row"><a data-stevilkazahtevka="<%{stevilkaZahtevka}%>" class="link btn-stevilka-zahtevka-desktop" role="button"><%{stevilkaZahtevka}%></a></td>
                        <%/izpisNagrade%>
                        <%^izpisNagrade%>
                            <td scope="row"><%{stevilkaZahtevka}%></td>
                        <%/izpisNagrade%>
                        <td><%{tip}%></td>
                        <td><%{koda}%></td>
                        <td><%{aktivnost}%></td>
                        <td><%{status}%></td>
                        <td><%#formators%><%#dateFormat%><%datum%><%/dateFormat%><%/formators%></td>
                        <td><%{steviloTock}%></td>
                    </tr>
                <%/tocke%>
                </tbody>
            </table>
             `;
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                formators: formators,
                "tocke": displayedSeznamVsehTock,
                "soKorisceneTocke": tipTock == "koriscene",
                "soPridobljeneTocke": tipTock == "pridobljene",
                "soVseTocke": tipTock == "vse",
                "translations": this._translationService.currentTranslations
            },
            undefined,
            customTags
        );
        $(".tabela-desktop").html(html);

        if (displayedSeznamVsehTock && displayedSeznamVsehTock.length > 0) {
            var stevilkeZahtevButtons = $(".btn-stevilka-zahtevka-desktop");
            for (let i = 0; i < stevilkeZahtevButtons.length; i++) {
                stevilkeZahtevButtons[i].addEventListener("click", (ev: Event) => {
                    var stevilkaZahtevka = stevilkeZahtevButtons[i].dataset["stevilkazahtevka"];
                    this._onBtnIzpisNagrade(ev, stevilkaZahtevka);
                });
            }
        }
    }

    private async _onBtnIzpisNagrade(ev: Event, stevilkazahtevka: any) {
        ev.preventDefault();
        var downloadingNotyf = this._notyf.open({
            type: "info",
            message: this._translationService.currentTranslations["Downloading"],
            duration: 50000
        });

        await this._mojPlusApiClient.izpisMojPlusNagrade(stevilkazahtevka)
            .then((fileContent) => {
                var blob = new Blob([fileContent.fileContent], { type: fileContent.fileType });
                var url = URL.createObjectURL(blob);

                var fileName: string = fileContent.fileName;
                var documentName = "nagrada_" + stevilkazahtevka + ".pdf"
                if (!fileName) {
                    fileName = documentName;
                }

                var fileLink = document.createElement('a');
                fileLink.href = url;
                fileLink.download = fileName;
                fileLink.click();

            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["ErrorWhileDownloadingFile"]);
            }).finally(() => {
                this._notyf.dismiss(downloadingNotyf);
            });
    }

    private _refreshPagination(seznam: any, page: number) {
        if (seznam.length <= 0) {
            $("#pagination")[0].style.display = "none";
            return;
        }
        const templateString = `
             <div class="pagination">
                <%#obstajaPrejsnjaStran%>
                <div>
                    <a class="btnPreviousPage">&lt</a>
                </div>
                <%/obstajaPrejsnjaStran%>
                <div class="pages"><%{page}%>/<%{maxPage}%></div>
                <%#obstajaNaslednjaStran%>
                <div>
                    <a class="btnNextPage">&gt</a>
                </div>
                <%/obstajaNaslednjaStran%>
            </div>
        `;
        var customTags: any = ['<%', '%>'];
        var maxPage = Math.ceil(seznam.length / this._limit);
        var html = Mustache.render(
            templateString,
            {
                "page": page,
                "maxPage": maxPage,
                "obstajaPrejsnjaStran": page != 1 ? true : false,
                "obstajaNaslednjaStran": page != maxPage ? true : false
            },
            undefined,
            customTags
        );
        $("#pagination")[0].style.display = "block";
        $("#pagination").html(html);
        $(".btnPreviousPage").on("click", (ev: Event) => this._onBtnPageChangeClick(ev, true, seznam));
        $(".btnNextPage").on("click", (ev: Event) => this._onBtnPageChangeClick(ev, false, seznam));
    }

    private async _onBtnPageChangeClick(ev: Event, previousClicked: boolean, seznam: any) {
        console.debug(ev);
        ev.preventDefault();
        var pagesString = $(".pages")[0].innerHTML;
        var currentPage = Number(pagesString.split("/")[0]);
        if (previousClicked) {
            currentPage -= 1;
        }
        else {
            currentPage += 1;
        }
        var tipTock = "";
        if (($(".statusPridobljeneTocke")[0] as HTMLInputElement).checked) {
            tipTock = "pridobljene";
        }
        else if (($(".statusKorisceneTocke")[0] as HTMLInputElement).checked) {
            tipTock = "koriscene";
        }
        else if (($(".statusVseTocke")[0] as HTMLInputElement).checked) {
            tipTock = "vse";
        }
        this._refreshTables(seznam, currentPage, tipTock);
    }
}