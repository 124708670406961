import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { MojPlusApiClient } from "../ts/clients/moj-plus-api-client";
import { TranslationService } from "../ts/translation-service";
import { FullscreenLoader } from "./fullscreen-loader";
import template from "./moj-plus-pomembne-informacije-view.html";

@injectable()
export class MojPlusPomembneInformacijeView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _mojPlusApiClient: MojPlusApiClient;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        mojPlusApiClient: MojPlusApiClient,
        private _notyf: Notyf,
        private _loader: FullscreenLoader)
    {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._mojPlusApiClient = mojPlusApiClient;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            this._renderData();
            if ($("#page-loaded")[0] == undefined) return;
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(): void {
        console.log(this._mojPlusApiClient); // TODO: odstrani
        console.log(this._notyf); // TODO: odstrani
        console.log(this._loader); // TODO: odstrani
        const viewModel = {
            translations: this._translationService.currentTranslations
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }
}