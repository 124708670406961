import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { VnosStanjeStevcaRequest } from "../ts/models/vnos-stanje-stevca-request";
import { TranslationService } from "../ts/translation-service";
import template from "./merilno-mesto-vnos-stanja-stevca-view.html";
import "jquery-validation-unobtrusive";
import { VrstaEnergenta } from "../ts/models/vrsta-energenta-enum";
import { FullscreenLoader } from "./fullscreen-loader";
import * as moment from "moment";
import { UporabnikMerilnoMestoRequest } from "../ts/models/uporabnik-merilno-mesto-request";
import { MerilnoMesto } from "../ts/models/merilno-mesto";
import { UporabnikMerilnoMestoResponse } from "../ts/models/uporabnik-merilno-mesto-response";
import { OdbirekVrniZadnjiElektrikaRequest } from "../ts/models/odbirek-vrni-zadnji-elektrika-request";
import { OdbirekVrniZadnjiPlinRequest } from "../ts/models/odbirek-vrni-zadnji-plin-request";
import { MerilnoMestoInformacijeRequest } from "../ts/models/merilno-mesto-informacije-request";
import { MerilnoMestoInformacijeResponse } from "../ts/models/merilno-mesto-informacije-response";
import { OdbirekVrniZadnjiElektrikaResponse } from "../ts/models/odbirek-vrni-zadnji-elektrika-response";
import { OdbirekVrniZadnjiPlinResponse } from "../ts/models/odbirek-vrni-zadnji-plin-response";
import { ZgodovinaOdcitkovRequest } from "../ts/models/zgodovina-odcitkov-request";

@injectable()
export class MerilnoMestoVnosStanjaStevcaView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _izbranoMerilnoMesto: MerilnoMesto | null | undefined;
    private _dropdownMerilnaMestaElement: JQuery<HTMLElement> | null;
    private _btnShraniElement: JQuery<HTMLElement> | null;
    private _formElement: JQuery<HTMLElement> | null;
    private _merilnaMesta: Array<MerilnoMesto> | null;
    private _btnMerilnaMestaOthers: JQuery<HTMLElement> | null;
    private _jeDvotarifni: boolean;
    private _jeAktivna: boolean;
    private _zadnjiDniVMesecu: boolean;
    private _odbirekVrniZadnjiElektrikaModel: OdbirekVrniZadnjiElektrikaResponse | null;
    private _odbirekVrniZadnjiPlinModel: OdbirekVrniZadnjiPlinResponse | null;
    private _zgodovinaOdcitkov: any;
    private _limit: number;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        merilnoMestoApiClient: MerilnoMestoApiClient,
        private _notyf: Notyf,
        private _loader: FullscreenLoader)
    {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._izbranoMerilnoMesto = null;
        this._dropdownMerilnaMestaElement = null;
        this._btnShraniElement = null;
        this._formElement = null;
        this._merilnaMesta = null;
        this._btnMerilnaMestaOthers = null;
        this._jeDvotarifni = true;
        this._jeAktivna = false;
        this._zadnjiDniVMesecu = this._soZadnjiDniVMesecu();
        this._odbirekVrniZadnjiElektrikaModel = null;
        this._odbirekVrniZadnjiPlinModel = null;
        this._zgodovinaOdcitkov = null;
        this._limit = 10;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;

            // Merilna mesta
            var uporabnikMerilnoMestoRequest: UporabnikMerilnoMestoRequest = {
                limit: 0,
                stevilkaStrani: 0,
                saldo: false,
                skupnaPorabaEnergije: false,
                merilnoMesto: "",
                merilnaTocka: ""
            };
            await this._merilnoMestoApiClient.getUporabnikMerilnaMesta(uporabnikMerilnoMestoRequest)
                .then((odgovor: UporabnikMerilnoMestoResponse) => {
                    this._merilnaMesta = odgovor.merilnaMesta;
                    if (this._merilnaMesta && this._merilnaMesta.length > 0) {
                        // poiscemo ali obstaja selektirano MM (v querystringu)
                        var url_string = window.location.href.split("?");
                        var url_data = url_string[url_string.length - 1];
                        var data_list = url_data.split("_");
                        const stevilkaKontoPogodbe = data_list[data_list.length - 1];
                        const merilnoMesto = data_list[data_list.length - 2];
                        const jeElektrika = data_list[data_list.length - 3];
                        const merilnaTocka = data_list[data_list.length - 4];

                        if (jeElektrika == 'true') {
                            this._izbranoMerilnoMesto = this._merilnaMesta.find(
                                a => a.merilnoMesto == merilnoMesto && a.merilnaTocka == merilnaTocka && a.stevilkaKontoPogodbe == stevilkaKontoPogodbe);
                        }
                        else if (jeElektrika == 'false') {
                            this._izbranoMerilnoMesto = this._merilnaMesta.find(
                                a => a.merilnoMesto == merilnoMesto && a.stevilkaKontoPogodbe == stevilkaKontoPogodbe);
                        }
                        // ce v querystringu ni doloceno MM, selektiramo prvega MM iz liste
                        if (!this._izbranoMerilnoMesto) {
                            this._izbranoMerilnoMesto = this._merilnaMesta[0];
                        }
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSearchingForMeasuringPoints"]);
                });

            if (this._izbranoMerilnoMesto) {
                await this._informacijeMerilnoMesto();
                await this._odbirekVrniZadnji();
            }

            await this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;
            if (this._izbranoMerilnoMesto) {
                this._initForm();
                await this._getZgodovinaOdcitkov();
                if (this._zgodovinaOdcitkov.status && this._zgodovinaOdcitkov.zgodovinaOdcitkov != null
                    && this._zgodovinaOdcitkov.zgodovinaOdcitkov.length > 0) {
                    this._refreshTables(this._zgodovinaOdcitkov.zgodovinaOdcitkov, 1);
                }
            }
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _refreshTables(seznam: any, page: number) {
        var displayedSeznam = new Array<any>();
        if (seznam && seznam.length > 0) {
            seznam.sort(function (a: any, b: any) {
                if (a.obdobjeOdcitka > b.obdobjeOdcitka) return -1;
                if (a.obdobjeOdcitka < b.obdobjeOdcitka) return 1;
            });
            for (let i = (page - 1) * this._limit; i < page * this._limit; i++) {
                if (i >= seznam.length) {
                    break;
                }
                displayedSeznam.push(seznam[i]);
            }
        }
        this._refreshPorabaMobile(displayedSeznam);
        this._refreshPorabaDesktop(displayedSeznam);
        this._refreshPagination(seznam, page);
    }

    private _refreshPorabaMobile(displayedSeznam: any) {
        const templateString = `
            <%#obstajaElement%>
            <h3 class="margin-small"><%{translations.ReadingHistory}%></h3>
            <table class="table energetska-izkaznica-mobile">    
                <tbody>
                    <%#seznam%>
                    <tr>
                        <td class="table-inner-wrap">
                            <div class="table-responsive">
                                <table class="table table-borderless">
                                    <tbody>
                                        <tr>
                                            <th scope="row"><%{translations.ReadingPeriod}%></th>
                                            <td><%#formators%><%#dateTimeFormat%><%{obdobjeOdcitka}%><%/dateTimeFormat%><%/formators%> </td>
                                        </tr>
                                        <%#jeElektrika%>
                                            <tr>
                                                <th scope="row">VT</th>
                                                <td><%{vt}%> KWH</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">MT</th>
                                                <td><%{mt}%> KWH</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">ET</th>
                                                <td><%{et}%> KWH</td>
                                            </tr>
                                            <tr>
                                                <th scope="row"><%{translations.ReadingType}%></th>
                                                <td><%{tipOdcitka}%></td>
                                            </tr>
                                        <%/jeElektrika%>
                                        <%^jeElektrika%>
                                             <tr>
                                                <th scope="row">ET</th>
                                                <td><%{odcitek}%> <%{enotaMere}%></td>
                                            </tr>
                                            <tr>
                                                <th scope="row"><%{translations.ReadingInterpretation}%></th>
                                                <td><%{razlagaOdcitka}%></td>
                                            </tr>
                                            <tr>
                                                <th scope="row"><%{translations.ReadingType}%></th>
                                                <td><%{vrstaOdcitka}%></td>
                                            </tr>
                                        <%/jeElektrika%>
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>
                <%/seznam%>
                </tbody>
            </table> 
            <%/obstajaElement%>
            <%^obstajaElement%>
            <div><%{translations.NoData}%></div>
            <%/obstajaElement%>
             `;
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "jeElektrika": this._izbranoMerilnoMesto?.jeElektrika,
                "obstajaElement": displayedSeznam.length > 0 ? true : false,
                "seznam": displayedSeznam,
                "translations": this._translationService.currentTranslations,
                "formators": this.getFormators()
            },
            undefined,
            customTags
        );
        $(".tabela-mobile").html(html);
    }

    private _refreshPorabaDesktop(displayedSeznam: any) {
        const templateString = `
            <%#obstajaElement%>
            <h3 class="margin-small"><%{translations.ReadingHistory}%></h3>
            <table class="table energetska-izkaznica">
                <thead>
                    <tr>
                        <td scope="col"><%{translations.ReadingPeriod}%></td>
                        <%#jeElektrika%>
                            <td scope="col">VT</td>
                            <td scope="col">MT</td>
                            <td scope="col">ET</td>
                            <td scope="col"><%{translations.ReadingType}%></td>
                        <%/jeElektrika%>
                        <%^jeElektrika%>
                            <td scope="col">ET</td>
                            <td scope="col"><%{translations.ReadingInterpretation}%></td>
                            <td scope="col"><%{translations.ReadingType}%></td>
                        <%/jeElektrika%>
                    </tr>
                </thead>
                <tbody>
                    <%#seznam%>
                    <tr>
                        <td scope="row">
                            <%#formators%><%#dateTimeFormat%><%{obdobjeOdcitka}%><%/dateTimeFormat%><%/formators%>   
                        </td>
                        <%#jeElektrika%>
                            <td><%{vt}%> KWH</td>
                            <td><%{mt}%> KWH</td>
                            <td><%{et}%> KWH</td>
                            <td><%{tipOdcitka}%></td>
                        <%/jeElektrika%>
                        <%^jeElektrika%>
                            <td><%{odcitek}%> <%{enotaMere}%></td>
                            <td><%{razlagaOdcitka}%></td>
                            <td><%{vrstaOdcitka}%></td>
                        <%/jeElektrika%>
                    </tr>
                <%/seznam%>
                </tbody>
            </table>
            <%/obstajaElement%>
            <%^obstajaElement%>
            <div><%{translations.NoData}%></div>
            <%/obstajaElement%>
             `;
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "jeElektrika": this._izbranoMerilnoMesto?.jeElektrika,
                "obstajaElement": displayedSeznam.length > 0 ? true : false,
                "seznam": displayedSeznam,
                "translations": this._translationService.currentTranslations,
                "formators": this.getFormators()
            },
            undefined,
            customTags
        );
        $(".tabela-desktop").html(html);
    }

    private getFormators() {
        const userLocale = document.documentElement.lang
            ? document.documentElement.lang
            : 'en';
        moment.locale(userLocale);
        var localeFormat = moment().creationData().locale.longDateFormat("L");
        return {
            dateTimeFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            },
        };
    }

    private _refreshPagination(seznam: any, page: number) {
        if (seznam.length <= 0) {
            $("#pagination")[0].style.display = "none";
            return;
        }
        const templateString = `
             <div class="pagination">
                <%#obstajaPrejsnjaStran%>
                <div>
                    <a class="btnPreviousPage">&lt</a>
                </div>
                <%/obstajaPrejsnjaStran%>
                <div class="pages"><%{page}%>/<%{maxPage}%></div>
                <%#obstajaNaslednjaStran%>
                <div>
                    <a class="btnNextPage">&gt</a>
                </div>
                <%/obstajaNaslednjaStran%>
            </div>
        `;
        var customTags: any = ['<%', '%>'];
        var maxPage = Math.ceil(seznam.length / this._limit);
        var html = Mustache.render(
            templateString,
            {
                "page": page,
                "maxPage": maxPage,
                "obstajaPrejsnjaStran": page != 1 ? true : false,
                "obstajaNaslednjaStran": page != maxPage ? true : false
            },
            undefined,
            customTags
        );
        $("#pagination")[0].style.display = "block";
        $("#pagination").html(html);
        $(".btnPreviousPage").on("click", (ev: Event) => this._onBtnPageChangeClick(ev, true, seznam));
        $(".btnNextPage").on("click", (ev: Event) => this._onBtnPageChangeClick(ev, false, seznam));
    }

    private async _onBtnPageChangeClick(ev: Event, previousClicked: boolean, seznam: any) {
        console.debug(ev);
        ev.preventDefault();
        this._showLoader();
        var pagesString = $(".pages")[0].innerHTML;
        var currentPage = Number(pagesString.split("/")[0]);
        if (previousClicked) {
            currentPage -= 1;
        }
        else {
            currentPage += 1;
        }
        this._refreshTables(seznam, currentPage);
    }

    private _showLoader() {
        this.rootElement.find(".table-responsive").html(`<div class="text-center"><div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status"></div></div>`);
    }

    private async _informacijeMerilnoMesto() {
        // Poiscemo ali je enotarifni ali dvotarifni
        if (this._izbranoMerilnoMesto) {
            if (this._izbranoMerilnoMesto) {                
                var data: MerilnoMestoInformacijeRequest = {
                    merilnoMesto: this._izbranoMerilnoMesto.merilnoMesto,
                    merilnaTocka: this._izbranoMerilnoMesto.merilnaTocka,
                    stevilkaKontoPogodbe: this._izbranoMerilnoMesto.stevilkaKontoPogodbe
                };
                await this._merilnoMestoApiClient.getInformacije(data)
                    .then((odgovor: MerilnoMestoInformacijeResponse) => {
                        if (odgovor.pogodbeneInformacije) {
                            var pogodba = odgovor.pogodbeneInformacije;
                            if (pogodba.pogodbaAktivna) {
                                this._jeAktivna = true;
                            }
                            else {
                                this._jeAktivna = false;
                            }
                        }
                    })
                    .catch(() => {
                        this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    });
            }
           
        }
    }

    private async _odbirekVrniZadnji() {
        if (this._izbranoMerilnoMesto) {
            if (this._izbranoMerilnoMesto.jeElektrika) {
                var data: OdbirekVrniZadnjiElektrikaRequest = {
                    merilnoMesto: this._izbranoMerilnoMesto.merilnoMesto,
                    merilnaTocka: this._izbranoMerilnoMesto.merilnaTocka
                };
                await this._merilnoMestoApiClient.odbirekVrniZadnjiElektrika(data)
                    .then((odgovor: OdbirekVrniZadnjiElektrikaResponse) => {
                        this._odbirekVrniZadnjiElektrikaModel = odgovor;
                        var registri = this._odbirekVrniZadnjiElektrikaModel.registerVrednosti;
                        if (registri) {
                            var registerEnotarifni = registri.find(a => a.tipRegistra == "PREJETA_DELOVNA_ENERGIJA_ET");
                            if (registerEnotarifni) {
                                this._jeDvotarifni = false;
                            }
                            var registerDvotarifni = registri.find(a => a.tipRegistra == "PREJETA_DELOVNA_ENERGIJA_VT"
                                || a.tipRegistra == "PREJETA_DELOVNA_ENERGIJA_MT");
                            if (registerDvotarifni) {
                                this._jeDvotarifni = true;
                            }
                        }
                    })
                    .catch(() => {
                        this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    });
            }
            else {
                var data: OdbirekVrniZadnjiPlinRequest = {
                    merilnoMesto: this._izbranoMerilnoMesto.merilnoMesto,
                    merilnaTocka: this._izbranoMerilnoMesto.merilnaTocka
                };
                await this._merilnoMestoApiClient.odbirekVrniZadnjiPlin(data)
                    .then((odgovor: OdbirekVrniZadnjiPlinResponse) => {
                        this._odbirekVrniZadnjiPlinModel = odgovor;
                        this._jeDvotarifni = false; // Plin je vedno enotarifni
                    })
                    .catch(() => {
                        this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    });
            }
        }
    }

    private async _getZgodovinaOdcitkov() {
        if (!this._izbranoMerilnoMesto) return;
        var now = new Date();
        var previousTenYears = new Date(now.getFullYear() - 10, now.getMonth(), now.getDay());
        var data: ZgodovinaOdcitkovRequest = {
            vrstaEnergenta: this._izbranoMerilnoMesto.jeElektrika ? VrstaEnergenta.Elektrika : VrstaEnergenta.Plin,
            merilnoMesto: this._izbranoMerilnoMesto.merilnoMesto,
            merilnaTocka: this._izbranoMerilnoMesto.merilnaTocka,
            stevilkaKontoPogodbe: this._izbranoMerilnoMesto.stevilkaKontoPogodbe,
            obdobjeOd: previousTenYears,
            obdobjeDo: now
        };
        await this._merilnoMestoApiClient.getZgodovinaOdcitkov(data)
            .then((odgovor: any) => {
                this._zgodovinaOdcitkov = odgovor;
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
    }

    private _renderData(translations: any): void
    {
        var merilnaMestaIds = new Array<string>();
        var prikazanaMerilnaMesta = new Array<string>();
        var steviloPrikazanih = 3;
        if (this._merilnaMesta) {
            merilnaMestaIds = Array.from(new Set(this._merilnaMesta.map((item) => item.merilnoMesto)));
            prikazanaMerilnaMesta = merilnaMestaIds.slice(0, steviloPrikazanih);
            if (this._merilnaMesta.length < steviloPrikazanih) {
                prikazanaMerilnaMesta = merilnaMestaIds;
            }
        }
        var localeFormat = moment().creationData().locale.longDateFormat("L");
        var formators = {
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            }//tukaj se lahko dodajajo formatorji
        };

        var et = "";
        var vt = "";
        var mt = "";
        var datumEt = new Date();
        var datumVt = new Date();
        var datumMt = new Date();
        var uspesno = true;
        var sporocilo = "";
        var jeDaljinskoOdcitavanje = false;

        if (this._izbranoMerilnoMesto) {
            // dvotarifni (lahko je samo elektrika)
            if (this._jeDvotarifni && this._odbirekVrniZadnjiElektrikaModel && this._odbirekVrniZadnjiElektrikaModel.registerVrednosti &&
                this._odbirekVrniZadnjiElektrikaModel.registerVrednosti.length > 0) {
                var register = this._odbirekVrniZadnjiElektrikaModel.registerVrednosti;
                for (let i = 0; i < register.length; i++) {
                    if (register[i].tipRegistra == "PREJETA_DELOVNA_ENERGIJA_VT") {
                        vt = register[i].vrednost.toString() + " " + register[i].oznakaEm;
                        datumVt = this._odbirekVrniZadnjiElektrikaModel.datumOdcitavanja;
                    }
                    else if (register[i].tipRegistra == "PREJETA_DELOVNA_ENERGIJA_MT") {
                        mt = register[i].vrednost.toString() + " " + register[i].oznakaEm;
                        datumMt = this._odbirekVrniZadnjiElektrikaModel.datumOdcitavanja;
                    }
                }
            }

            // enotarifni (lahko je elektrika / plin)
            if (!this._jeDvotarifni) {
                // elektrika
                if (this._izbranoMerilnoMesto.jeElektrika && this._odbirekVrniZadnjiElektrikaModel &&
                    this._odbirekVrniZadnjiElektrikaModel.registerVrednosti && this._odbirekVrniZadnjiElektrikaModel.registerVrednosti.length > 0) {
                    var register = this._odbirekVrniZadnjiElektrikaModel.registerVrednosti;
                    for (let i = 0; i < register.length; i++) {
                        if (register[i].tipRegistra == "PREJETA_DELOVNA_ENERGIJA_ET") {
                            et = register[i].vrednost.toString() + " " + register[i].oznakaEm;
                            datumEt = this._odbirekVrniZadnjiElektrikaModel.datumOdcitavanja;
                        }
                    }
                }
                // plin
                if (!this._izbranoMerilnoMesto.jeElektrika && this._odbirekVrniZadnjiPlinModel) {
                    et = this._odbirekVrniZadnjiPlinModel.vrednostOdcitavanja + " " + this._odbirekVrniZadnjiPlinModel.oznakaEm;
                    datumEt = this._odbirekVrniZadnjiPlinModel.datumOdcitavanja;
                }
            }

            // preverimo ali je daljinsko odcitavanje
            if (this._izbranoMerilnoMesto.jeElektrika && this._odbirekVrniZadnjiElektrikaModel
                && this._odbirekVrniZadnjiElektrikaModel.nacinEvidentiranjaMeritve == "EDP_DALJINSKO") {
                jeDaljinskoOdcitavanje = true;
            }

            // preverimo ali je vse bilo ok
            if (!this._jeAktivna) {
                uspesno = false;
                sporocilo = this._translationService.currentTranslations["ContractNotActive"];
            }
            else if (!this._zadnjiDniVMesecu && !this._izbranoMerilnoMesto.jeElektrika) {
                uspesno = false;
                sporocilo = this._translationService.currentTranslations["EnteringTheGasBalanceIsOnlyPossibleForTheLast5DaysOfTheMonth"];
            }
            else if (this._izbranoMerilnoMesto.jeElektrika && this._odbirekVrniZadnjiElektrikaModel && !this._odbirekVrniZadnjiElektrikaModel.status) {
                uspesno = false;
                sporocilo = this._odbirekVrniZadnjiElektrikaModel.sporocilo;
            }
            else if (!this._izbranoMerilnoMesto.jeElektrika && this._odbirekVrniZadnjiPlinModel && !this._odbirekVrniZadnjiPlinModel.status) {
                uspesno = false;
                sporocilo = this._odbirekVrniZadnjiPlinModel.sporocilo;
            }
        }
        

        const viewModel = {
            merilnaMestaIds: merilnaMestaIds,
            merilnaMesta: this._merilnaMesta,
            izbranoMerilnoMesto: this._izbranoMerilnoMesto,
            jeElektrika: this._izbranoMerilnoMesto && this._izbranoMerilnoMesto.jeElektrika,
            ostalaMerilnaMestaStevilo: merilnaMestaIds.length - prikazanaMerilnaMesta.length,
            obstajajoOstalaMerilnaMesta: merilnaMestaIds.length - prikazanaMerilnaMesta.length > 0 ? true : false,
            prikazanaMerilnaMesta: prikazanaMerilnaMesta,
            obstajaOdcitek: true,
            translations: translations,
            formators: formators,
            et: et,
            vt: vt,
            mt: mt,
            datumEt: datumEt,
            datumVt: datumVt,
            datumMt: datumMt,
            jeDvotarifni: this._jeDvotarifni,
            jeDaljinskoOdcitavanje: jeDaljinskoOdcitavanje,
            neuspesno: !uspesno,
            sporocilo: sporocilo,
            obstajaMerilnoMesto: this._izbranoMerilnoMesto ? true : false
        } as any;
        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _soZadnjiDniVMesecu(): boolean {
        var today = new Date();
        var daysInCurrentMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
        if (daysInCurrentMonth - today.getDate() < 5) {
            return true;
        }
        return false;
    }

    private _initForm(): void {
        if (!this._izbranoMerilnoMesto) {
            return;
        }
        this._formElement = $(("#vnos-stanje-stevca-form"));
        this._dropdownMerilnaMestaElement = $("#dropdownMerilnaMesta");
        this._dropdownMerilnaMestaElement.on("change", (ev: Event) => this._onDropDownMerilnoMestoChange(ev));
        if (this._izbranoMerilnoMesto.jeElektrika) {
            this._dropdownMerilnaMestaElement.val(this._izbranoMerilnoMesto.jeElektrika + "_" + this._izbranoMerilnoMesto.stevilkaKontoPogodbe + "_" + this._izbranoMerilnoMesto.merilnoMesto + "_" + this._izbranoMerilnoMesto.merilnaTocka);
        }
        else {
            this._dropdownMerilnaMestaElement.val(this._izbranoMerilnoMesto.jeElektrika + "_" + this._izbranoMerilnoMesto.stevilkaKontoPogodbe + "_" + this._izbranoMerilnoMesto.merilnoMesto);
        }
        this._btnShraniElement = $("#btnShrani");
        this._btnShraniElement.on("click", (ev: Event) => this._onbtnShraniClick(ev, this._formElement));
        this._btnMerilnaMestaOthers = $("#btnMerilnaMestaOthers");
        this._btnMerilnaMestaOthers.on("click", () => {
            $(".merilna-mesta-not-all")[0].style.display = "none";
            $(".merilna-mesta-all")[0].style.display = "block";
        });
    }

    private _onDropDownMerilnoMestoChange(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        this._loader.show();
        this._dropdownMerilnaMestaElement = $("#dropdownMerilnaMesta");
        var dropdownMerilnoMestoValues = (this._dropdownMerilnaMestaElement[0] as any).value.split("_");

        var selectedJeElektrika = dropdownMerilnoMestoValues[0]
        var selectedStevilkaKontoPogodbe = dropdownMerilnoMestoValues[1]
        var selectedMerilnoMesto = dropdownMerilnoMestoValues[2] 

        if (selectedJeElektrika == 'true') {
            var selectedMerilnaTocka = dropdownMerilnoMestoValues[3]
            location.href = "#/merilno-mesto/vnos-stanja-stevca?" + selectedMerilnaTocka + "_" + selectedJeElektrika + "_" + selectedMerilnoMesto + "_" + selectedStevilkaKontoPogodbe
        }
            
        else
            location.href = "#/merilno-mesto/vnos-stanja-stevca?" + selectedJeElektrika + "_" + selectedMerilnoMesto + "_" + selectedStevilkaKontoPogodbe 
      
        this._loader.hide();
    }

    private _onbtnShraniClick(ev: Event, formElement: any) {
        console.debug(ev);
        ev.preventDefault();
        if (this._izbranoMerilnoMesto == null) {
            return;
        }
        this._loader.show();
        var formData = new FormData(formElement[0] as HTMLFormElement);
        var vt = formData.get("vt-input") as any;
        var mt = formData.get("mt-input") as any;
        var et = formData.get("et-input") as any;
        var m3 = 0;

        if ((this._jeDvotarifni && !vt && !mt) || (!this._jeDvotarifni && !et)) {
            this._loader.hide();
            this._notyf.error(this._translationService.currentTranslations["CheckAllFields"]);
            return;
        }

        // ce je plin nastavimo M3 in odstranimo ET
        if (!this._izbranoMerilnoMesto.jeElektrika) {
            m3 = et;
            et = 0;
        }

        var vnosStanjeStevcaRequest: VnosStanjeStevcaRequest = {
            vrstaEnergenta: this._izbranoMerilnoMesto.jeElektrika ? VrstaEnergenta.Elektrika : VrstaEnergenta.Plin,
            merilnoMesto: this._izbranoMerilnoMesto.merilnoMesto,
            merilnaTocka: this._izbranoMerilnoMesto.merilnaTocka,
            stevilkaKontoPogodbe: this._izbranoMerilnoMesto.stevilkaKontoPogodbe,
            vt: vt ? Number(vt) : 0,
            mt: mt ? Number(mt) : 0,
            et: et ? Number(et) : 0,
            m3: m3 ? Number(m3) : 0
        };
        this._merilnoMestoApiClient.vnosStanjeStevca(vnosStanjeStevcaRequest)
            .then((odgovor) => {
                if (odgovor.status) {
                    var povratnaInformacija = $(".povratna-informacija");
                    povratnaInformacija[0].style.display = "block";
                    var vnosStanjeStevca = $(".vnos-stanje-stevca");
                    vnosStanjeStevca[0].style.display = "none";
                }
                else {
                    if (odgovor.sporocilo) {
                        this._notyf.error(odgovor.sporocilo);
                    }
                    else {
                        this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileEnteringTheCounterStatus"]);
                    }
                    this._loader.hide();
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileEnteringTheCounterStatus"]);
                this._loader.hide();
            });
        this._loader.hide();
    }
}