import { injectable } from "inversify";
import * as moment from "moment";
import * as Mustache from "mustache";
import { NoviceApiClient } from "../ts/clients/novice-api-client";
import { Novica } from "../ts/models/novica";
import { TranslationService } from "../ts/translation-service";
import template from "./novice-view.html";

@injectable()
export class NoviceView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _apiClient: NoviceApiClient;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        apiClient: NoviceApiClient)
    {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._apiClient = apiClient;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try
        {
            var translations = this._translationService.currentTranslations;
            var novice = await this._apiClient.getNovice();
            this._renderData(translations, novice);
            if ($("#page-loaded")[0] == undefined) return;
        }
        catch(e)
        {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(translations: any, novice: any): void {
        var localeFormat = moment().creationData().locale.longDateFormat("L");
        var formators = {
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            }//tukaj se lahko dodajajo formatorji
        };

        var prikazaneNovice = 3;
        var displayedNovice = new Array<Novica>();
        if (novice.length > prikazaneNovice) {
            displayedNovice = novice.splice(0, prikazaneNovice);
        }
        else {
            displayedNovice = novice;
        }

        const viewModel = {
            "novice": displayedNovice,
            "obstajaNovica": displayedNovice && displayedNovice.length > 0 ? true : false,
            "formators": formators,
            translations: translations
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }
}