import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { MerilnoMesto } from "../ts/models/merilno-mesto";
import { MerilnoMestoInformacijeRequest } from "../ts/models/merilno-mesto-informacije-request";
import { MerilnoMestoInformacijeResponse } from "../ts/models/merilno-mesto-informacije-response";
import { NacinObracunaRequest } from "../ts/models/nacin-obracuna-request";
import { OdbirekVrniZadnjiElektrikaRequest } from "../ts/models/odbirek-vrni-zadnji-elektrika-request";
import { OdbirekVrniZadnjiElektrikaResponse } from "../ts/models/odbirek-vrni-zadnji-elektrika-response";
import { UporabnikMerilnoMestoRequest } from "../ts/models/uporabnik-merilno-mesto-request";
import { UporabnikMerilnoMestoResponse } from "../ts/models/uporabnik-merilno-mesto-response";
import { TranslationService } from "../ts/translation-service";
import { FullscreenLoader } from "./fullscreen-loader";
import template from "./merilno-mesto-informacije-nacin-obracuna-view.html";

@injectable()
export class MerilnoMestoInformacijeNacinObracunaView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _merilnaMesta: Array<MerilnoMesto> | null;
    private _izbranoMerilnoMesto: MerilnoMesto | null | undefined;
    private _merilnoMestoInformacije: MerilnoMestoInformacijeResponse | null;
    private _dropdownMerilnaMestaElement: JQuery<HTMLElement> | null;
    private _btnMerilnaMestaOthers: JQuery<HTMLElement> | null;
    private _btnShrani: JQuery<HTMLElement> | null;
    private _zadnjiOdbirek: OdbirekVrniZadnjiElektrikaResponse | null;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        merilnoMestoApiClient: MerilnoMestoApiClient,
        private _notyf: Notyf,
        private _loader: FullscreenLoader)
    {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._merilnaMesta = null;
        this._izbranoMerilnoMesto = null;
        this._dropdownMerilnaMestaElement = null;
        this._merilnoMestoInformacije = null;
        this._zadnjiOdbirek = null;
        this._btnMerilnaMestaOthers = null;
        this._btnShrani = null;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;
            var uporabnikMerilnoMestoRequest: UporabnikMerilnoMestoRequest = {
                limit: 0,
                stevilkaStrani: 0,
                saldo: false,
                skupnaPorabaEnergije: false,
                merilnoMesto: "",
                merilnaTocka: "",
            };
            await this._merilnoMestoApiClient.getUporabnikMerilnaMesta(uporabnikMerilnoMestoRequest)
                .then((odgovor: UporabnikMerilnoMestoResponse) => {
                    if (odgovor.merilnaMesta && odgovor.merilnaMesta.length > 0) {
                        odgovor.merilnaMesta = odgovor.merilnaMesta.filter(a => a.jeElektrika == true);
                    }
                    this._merilnaMesta = odgovor.merilnaMesta;
                    if (this._merilnaMesta && this._merilnaMesta.length > 0) {
                        // poiscemo ali obstaja selektirano MM (v querystringu)
                        var url_string = window.location.href.split("?");
                        var url_data = url_string[url_string.length - 1];
                        var data_list = url_data.split("_");
                        const stevilkaKontoPogodbe = data_list[data_list.length - 1];
                        const merilnoMesto = data_list[data_list.length - 2];
                        const jeElektrika = data_list[data_list.length - 3];
                        const merilnaTocka = data_list[data_list.length - 4];
                        
                        if (jeElektrika == 'true') {
                            this._izbranoMerilnoMesto = this._merilnaMesta.find(
                                a => a.merilnoMesto == merilnoMesto && a.merilnaTocka == merilnaTocka && a.stevilkaKontoPogodbe == stevilkaKontoPogodbe);
                        }
                        else if (jeElektrika == 'false') {
                            this._izbranoMerilnoMesto = this._merilnaMesta.find(
                                a => a.merilnoMesto == merilnoMesto && a.stevilkaKontoPogodbe == stevilkaKontoPogodbe);
                        }
                        // ce v querystringu ni doloceno MM, selektiramo prvega MM iz liste
                        if (!this._izbranoMerilnoMesto) {
                            this._izbranoMerilnoMesto = this._merilnaMesta[0];
                        }
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSearchingForMeasuringPoints"]);
                });

            if (this._izbranoMerilnoMesto) {
                var data: MerilnoMestoInformacijeRequest = {
                    merilnoMesto: this._izbranoMerilnoMesto.merilnoMesto,
                    merilnaTocka: this._izbranoMerilnoMesto.merilnaTocka,
                    stevilkaKontoPogodbe: this._izbranoMerilnoMesto.stevilkaKontoPogodbe
                };
                await this._merilnoMestoApiClient.getInformacije(data)
                    .then((odgovor: MerilnoMestoInformacijeResponse) => {
                        this._merilnoMestoInformacije = odgovor;
                    })
                    .catch(() => {
                        this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    });
            }

            // Uporabimo za da preverimo ali je daljinski števec (gre samo za elektrika)
            if (this._izbranoMerilnoMesto && this._izbranoMerilnoMesto.jeElektrika) {
                await this._getZadnjiOdbirek();
            } 

            this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;
            if (this._izbranoMerilnoMesto) {
                this._initForm();
            }
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _initForm(): void {
        if (!this._izbranoMerilnoMesto) {
            return;
        }
        this._dropdownMerilnaMestaElement = $("#dropdownMerilnaMesta");
        this._dropdownMerilnaMestaElement.on("change", (ev: Event) => this._onDropDownMerilnoMestoChange(ev));
        if (this._izbranoMerilnoMesto.jeElektrika) {
            this._dropdownMerilnaMestaElement.val(this._izbranoMerilnoMesto.jeElektrika + "_" + this._izbranoMerilnoMesto.stevilkaKontoPogodbe + "_" + this._izbranoMerilnoMesto.merilnoMesto + "_" + this._izbranoMerilnoMesto.merilnaTocka);
        }
        else {
            this._dropdownMerilnaMestaElement.val(this._izbranoMerilnoMesto.jeElektrika + "_" + this._izbranoMerilnoMesto.stevilkaKontoPogodbe + "_" + this._izbranoMerilnoMesto.merilnoMesto);
        }
        this._btnMerilnaMestaOthers = $("#btnMerilnaMestaOthers");
        this._btnMerilnaMestaOthers.on("click", (ev: Event) => this._onBtnMerilnaMestaOthersClick(ev));
        this._btnShrani = $("#btnShrani");
        this._btnShrani.on("click", (ev: Event) => this._onBtnShraniClick(ev));
    }

    private _renderData(translations: any): void {
        var merilnaMestaIds = new Array<string>();
        var prikazanaMerilnaMesta = new Array<string>();
        var steviloPrikazanih = 3;
        if (this._merilnaMesta) {
            merilnaMestaIds = Array.from(new Set(this._merilnaMesta.map((item) => item.merilnoMesto)));
            prikazanaMerilnaMesta = merilnaMestaIds.slice(0, steviloPrikazanih);
            if (this._merilnaMesta.length < steviloPrikazanih) {
                prikazanaMerilnaMesta = merilnaMestaIds;
            }
        }

        var pogodbaAktivna = false;
        var akontacija = true;

        if (this._merilnoMestoInformacije) {
            if (this._merilnoMestoInformacije.pogodbeneInformacije) {
                pogodbaAktivna = this._merilnoMestoInformacije.pogodbeneInformacije.pogodbaAktivna;
            }
            if (this._merilnoMestoInformacije.tehnicneInformacije) {
                akontacija = this._merilnoMestoInformacije.tehnicneInformacije.akontacija;
            }
        }

        const viewModel = {
            merilnaMestaIds: merilnaMestaIds,
            merilnaMesta: this._merilnaMesta,
            izbranoMerilnoMesto: this._izbranoMerilnoMesto,
            ostalaMerilnaMestaStevilo: merilnaMestaIds.length - prikazanaMerilnaMesta.length,
            obstajajoOstalaMerilnaMesta: merilnaMestaIds.length - prikazanaMerilnaMesta.length > 0 ? true : false,
            prikazanaMerilnaMesta: prikazanaMerilnaMesta,
            translations: translations,
            akontacija: akontacija,
            pogodbaAktivna: pogodbaAktivna,
            daljinskiStevec: this._zadnjiOdbirek && this._zadnjiOdbirek.nacinEvidentiranjaMeritve == "EDP_DALJINSKO" ? true : false,
            neuspesno: this._zadnjiOdbirek && this._zadnjiOdbirek.status == false ? true : false,
            sporocilo: this._zadnjiOdbirek && this._zadnjiOdbirek.status == false ? this._zadnjiOdbirek.sporocilo : "",
            obstajaMerilnoMesto: this._izbranoMerilnoMesto ? true : false
        } as any;
        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private async _getZadnjiOdbirek() {
        if (!this._izbranoMerilnoMesto) return;
        var data: OdbirekVrniZadnjiElektrikaRequest = {
            merilnoMesto: this._izbranoMerilnoMesto.merilnoMesto,
            merilnaTocka: this._izbranoMerilnoMesto.merilnaTocka
        };
        await this._merilnoMestoApiClient.odbirekVrniZadnjiElektrika(data)
            .then((odgovor: OdbirekVrniZadnjiElektrikaResponse) => {
                this._zadnjiOdbirek = odgovor;
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
    }

    private _onBtnShraniClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        if (!this._izbranoMerilnoMesto) {
            return;
        }
        this._loader.show();
        var akontacijskiNacinChecked = ($("#akontacijskiNacin")[0] as HTMLInputElement).checked;
        var nacinObracunaRequest: NacinObracunaRequest = {
            merilnoMesto: this._izbranoMerilnoMesto.merilnoMesto,
            merilnaTocka: this._izbranoMerilnoMesto.merilnaTocka,
            stevilkaKontoPogodbe: this._izbranoMerilnoMesto.stevilkaKontoPogodbe,
            koda: akontacijskiNacinChecked ? "AKON" : "NEAKON",
            opis: akontacijskiNacinChecked ? "Akontacijski način obračunjavanja" : "Neakontacijski način obračunjavanja"
        };
        this._merilnoMestoApiClient.spremembaAkontacije(nacinObracunaRequest)
            .then((odgovor: any) => {
                if (odgovor.status) {
                    this._notyf.success(this._translationService.currentTranslations["TheRequestHasBeenSubmittedSuccessfully"]);
                    this._loader.hide();
                }
                else {
                    this._notyf.error(odgovor.sporocilo);
                    this._loader.hide();
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSavingChanges"]);
                this._loader.hide();
            });
    }

    private _onDropDownMerilnoMestoChange(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        this._dropdownMerilnaMestaElement = $("#dropdownMerilnaMesta");
        var dropdownMerilnoMestoValues = (this._dropdownMerilnaMestaElement[0] as any).value.split("_");

        var selectedJeElektrika = dropdownMerilnoMestoValues[0]
        var selectedStevilkaKontoPogodbe = dropdownMerilnoMestoValues[1]
        var selectedMerilnoMesto = dropdownMerilnoMestoValues[2]    

        if (selectedJeElektrika == 'true') {
            var selectedMerilnaTocka = dropdownMerilnoMestoValues[3]
            location.href = "#/merilno-mesto/nacin-obracuna?" + selectedMerilnaTocka + "_" + selectedJeElektrika + "_" + selectedMerilnoMesto + "_" + selectedStevilkaKontoPogodbe
        }
            
        else
            location.href = "#/merilno-mesto/nacin-obracuna?" + selectedJeElektrika + "_" + selectedMerilnoMesto + "_" + selectedStevilkaKontoPogodbe 
    }

    private _onBtnMerilnaMestaOthersClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        $(".merilna-mesta-not-all")[0].style.display = "none";
        $(".merilna-mesta-all")[0].style.display = "block";
    }
}
