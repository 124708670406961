import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { MojPlusApiClient } from "../ts/clients/moj-plus-api-client";
import { TranslationService } from "../ts/translation-service";
import { PovabitePrijateljaRequest } from "../ts/models/povabite-prijatelja-request";
import { FullscreenLoader } from "./fullscreen-loader";
import template from "./moj-plus-povabite-prijatelja-view.html";
import "jquery-validation-unobtrusive";
import { MojPlusVclanitev } from "../ts/models/moj-plus-vclanitev";
import { MojPlusPodatkiVclanitevRequest } from "../ts/models/moj-plus-podatki-vclanitev-request";

@injectable()
export class MojPlusPovabitePrijateljaView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _mojPlusApiClient: MojPlusApiClient;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _dropdownStevilkaRacuna: JQuery<HTMLElement> | null;
    private _formElement: JQuery<HTMLElement> | null;
    private _stevilkeKontoPogodb: Array<string>;
    private _izbranaKontoPogodba: string;
    private _vclanitev: MojPlusVclanitev | null;
    private _steviloPrijateljev: number;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        mojPlusApiClient: MojPlusApiClient,
        merilnoMestoApiClient: MerilnoMestoApiClient,
        private _notyf: Notyf,
        private _loader: FullscreenLoader)
    {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._mojPlusApiClient = mojPlusApiClient;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._dropdownStevilkaRacuna = null;
        this._formElement = null;
        this._stevilkeKontoPogodb = new Array<string>();
        this._izbranaKontoPogodba = "";
        this._vclanitev = null;
        this._steviloPrijateljev = 1;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;
            await this._merilnoMestoApiClient.getStevilkeKontoPogodb()
                .then((odgovor) => {
                    this._stevilkeKontoPogodb = odgovor.filter((value: any, index: any, array: any) => array.indexOf(value) === index);
                    if (this._stevilkeKontoPogodb.length > 0) {
                        // poiscemo ali obstaja selektira �tevilka racuna (v querystringu)
                        var url = window.location.href.split("/");
                        var izbranaKontoPogodbaId = url[url.length - 1];
                        var kontoPogodba = this._stevilkeKontoPogodb.find(a => a == izbranaKontoPogodbaId);
                        if (kontoPogodba) {
                            this._izbranaKontoPogodba = kontoPogodba;
                        }
                        else {
                            // ce v querystringu ni dolocena konto pogodba, selektiramo prvo pogodbo iz liste
                            this._izbranaKontoPogodba = this._stevilkeKontoPogodb[0];
                        }
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    return;
                });

            if (this._izbranaKontoPogodba) {
                var mojPlusPodatkiVclanitevRequest: MojPlusPodatkiVclanitevRequest = {
                    stevilkaKontoPogodbe: this._izbranaKontoPogodba,
                    kljucProgramZvestobe: "EPLUS"
                };
                await this._mojPlusApiClient.podatkiVclanitev(mojPlusPodatkiVclanitevRequest)
                    .then((odgovor) => {
                        this._vclanitev = odgovor;
                        if (!odgovor.kreiranoDne) {  //Racun ne obstaja v programu zvestobe MojPlus
                            this._vclanitev = null;
                        }
                    })
                    .catch(() => {
                        this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    });
            }

            this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;
            if (this._izbranaKontoPogodba) {
                this._initForm();
            }
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(translations: any): void {
        const viewModel = {
            translations: translations,
            stevilkeKontoPogodbe: this._stevilkeKontoPogodb,
            vclanitev: this._vclanitev,
            mojPlusRacunObstaja: this._vclanitev != null ? true : false,
            izbranaKontoPogodba: this._izbranaKontoPogodba,
            obstajaKontoPogodba: this._izbranaKontoPogodba == "" ? false : true
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _initForm(): void {
        this._dodajValidacijskeFunkcije();
        this._formElement = $(("#povabite-prijatelja-form"));
        $.validator.unobtrusive.parse(this._formElement);
        this._formElement.validate();

        this._dropdownStevilkaRacuna = $("#dropdownStevilkaRacuna");
        this._dropdownStevilkaRacuna.on("change", (ev: Event) => this._onDropDownStevilkaRacunaChange(ev));
        if (this._izbranaKontoPogodba) {
            this._dropdownStevilkaRacuna.val(this._izbranaKontoPogodba);
        }

        var btnMerilnaMestaOthers = $("#btnMerilnaMestaOthers");
        btnMerilnaMestaOthers.on("click", (ev: Event) => this._onBtnMerilnaMestaOthersClick(ev));

        var btnPosljitePovabilo = $("#btnPosljitePovabilo");
        btnPosljitePovabilo.on("click", (ev: Event) => this._onBtnPosljitePovabiloClick(ev, this._formElement));

        var btnPovabiVecPrijateljev = $("#btn-povabi-vec-prijateljev");
        btnPovabiVecPrijateljev.on("click", (ev: Event) => this._onPovabiVecPrijateljevClick(ev));
    }

    private _onDropDownStevilkaRacunaChange(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        this._dropdownStevilkaRacuna = $("#dropdownStevilkaRacuna");
        var dropdownMerilnoMestoValue = (this._dropdownStevilkaRacuna[0] as any).value;
        location.href = "#/moj-plus/povabite-prijatelja/" + dropdownMerilnoMestoValue;
    }

    private _onBtnMerilnaMestaOthersClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        $(".merilna-mesta-not-all")[0].style.display = "none";
        $(".merilna-mesta-all")[0].style.display = "block";
    }

    private async _onBtnPosljitePovabiloClick(ev: Event, formElement: any) {
        console.debug(ev);
        ev.preventDefault();
        if (!formElement.valid()) {
            this._notyf.error(this._translationService.currentTranslations.CheckAllFields);
            return null;
        }
        this._loader.show();
        var formData = new FormData(formElement[0] as HTMLFormElement);
        var vseOk = true;
        for (let i = 1; i <= this._steviloPrijateljev; i++) {
            var email = "email-" + i.toString();
            var emailPrijatelja = formData.get(email) as string;
            var povabitePrijateljaRequest: PovabitePrijateljaRequest = {
                emailPrijatelja: emailPrijatelja,
                kljucProgramZvestobe: "EPLUS",
                stevilkaKontoPogodbe: this._izbranaKontoPogodba
            };
            await this._mojPlusApiClient.povabitePrijatelja(povabitePrijateljaRequest)
                .then((odgovor: any) => {
                    if (odgovor.status) {
                        var message = this._translationService.currentTranslations["FriendHasBeenSuccessfullyInvited"] + " Email: " + povabitePrijateljaRequest.emailPrijatelja;
                        this._notyf.success(message);
                    }
                    else {
                        vseOk = false;
                        var message = odgovor.sporocilo + ". Email: " + povabitePrijateljaRequest.emailPrijatelja;
                        this._notyf.error(message);
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["AnErrorWasOccurredWhileSendingInvitationToFriend"]);
                });
        }
        if (vseOk) {
            $(".povabite-prijatelja")[0].style.display = "none";
            $(".povratna-informacija")[0].style.display = "block";
        }
        this._loader.hide();
    }

    private _onPovabiVecPrijateljevClick(ev: Event) {
        ev.preventDefault();
        this._steviloPrijateljev += 1;
        const templateString = `
            <label for="email-<%{steviloPrijateljev}%>" class="form-label"><%{translations.FriendsEmail}%>:</label>
            <input type="text" class="form-control" id="email-<%{steviloPrijateljev}%>" name="email-<%{steviloPrijateljev}%>"
                    data-val-required="<%{translations.FieldIsRequired}%>"
                    data-val="true"
                    data-rule-emailIsValid="true"
                    data-msg-emailIsValid="<%{translations.EmailAddressIsNotInTheCorrectFormat}%>">
            <span class="text-danger field-validation-valid" data-valmsg-for="email-<%{steviloPrijateljev}%>" data-valmsg-replace="true"></span>
             `;
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "steviloPrijateljev": this._steviloPrijateljev,
                "translations": this._translationService.currentTranslations
            },
            undefined,
            customTags
        );
        $("#povabi-vec-prijateljev").append(html);
    }

    private _dodajValidacijskeFunkcije(): void {
        $.validator.addMethod("emailIsValid", function (email) {
            if (email.match(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-||_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/)) {
                return true;
            }
            return false;
        });
    }
}