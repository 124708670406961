import { inject, injectable, interfaces } from "inversify";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { MainLayout } from "../../views/main-layout";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import * as Mustache from "mustache";
import template from "../pages/admin-notification-details-page.html";
import { TranslationService } from "../translation-service";
import { Notyf } from "notyf";
import { FullscreenLoader } from "../../views/fullscreen-loader";
import { AdminApiClient } from "../clients/admin-api-client";
import { CurrentUserAccessor } from "../utilities/current-user-accessor";
import { AdminNotificationDetailsView } from "../../views/admin-notification-details-view";

@injectable()
export class AdminNotificationDetailsPage extends PageBase {
    public name = "AdminNotificationDetailsPage";
    private _router: Router;
    private _translationService: TranslationService;
    private _adminApiClient: AdminApiClient;
    private _currentUserAccessor: CurrentUserAccessor;
    private _adminNotificationDetailsView: AdminNotificationDetailsView;
    private _adminNotificationDetailsElement: JQuery<HTMLElement>;
    private _loader: FullscreenLoader;

    public constructor(@inject("Provider<MainLayout>") private _mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("Router") router: Router,
        @inject("TranslationService") translationService: TranslationService,
        @inject("AdminApiClient") adminApiClient: AdminApiClient,
        @inject("CurrentUserAccessor") currentUserAccessor: CurrentUserAccessor,
        @inject("Notyf") notyf: Notyf,
        @inject("FullscreenLoader") loader: FullscreenLoader) {
        super();

        this._router = router;
        this._translationService = translationService;
        this._adminApiClient = adminApiClient;
        this._currentUserAccessor = currentUserAccessor;
        this._loader = loader;

        const html = Mustache.render(template, {});
        $('#main').html(html);

        this._adminNotificationDetailsElement = $('#main').find("#admin-notification-details");
        this._adminNotificationDetailsView = new AdminNotificationDetailsView(this._adminNotificationDetailsElement,
            this._translationService, this._adminApiClient, notyf);
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        if (!url.pathname.startsWith("/admin/notification-details")) {
            return true;
        }
        var mainLayout = await this._mainLayoutProvider() as MainLayout;
        try {
            var user = await this._currentUserAccessor.getUser();
            var adminRole = user.roles.find(a => a.role1 == "ROLE_ADMINISTRATOR");
            if (adminRole) {
                mainLayout.hideFooter();
                this._loader.show();
                await this._adminNotificationDetailsView.load();
                window.scrollTo(0, 0);
                $(".title-strani")[0].innerHTML = this._translationService.currentTranslations["AddUser1"];
            }
        } catch (e) {
            mainLayout.errorView.report(e);
        }
        finally {
            this._loader.hide();
            mainLayout.showFooter();
        }
        return false;
    }
}