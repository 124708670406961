import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { TranslationService } from "../ts/translation-service";
import template from "./merilno-mesto-poraba-informativni-izracun-porabe-view.html";
import { VrstaEnergenta } from "../ts/models/vrsta-energenta-enum";
import { InformativniIzracunPorabeModel } from "../ts/models/informativni-izracun-porabe";
import { FullscreenLoader } from "./fullscreen-loader";
import { UporabnikMerilnoMestoRequest } from "../ts/models/uporabnik-merilno-mesto-request";
import { MerilnoMesto } from "../ts/models/merilno-mesto";
import { UporabnikMerilnoMestoResponse } from "../ts/models/uporabnik-merilno-mesto-response";
import { MerilnoMestoInformacijeRequest } from "../ts/models/merilno-mesto-informacije-request";
import { MerilnoMestoInformacijeResponse } from "../ts/models/merilno-mesto-informacije-response";
import { OdbirekVrniZadnjiElektrikaRequest } from "../ts/models/odbirek-vrni-zadnji-elektrika-request";
import { OdbirekVrniZadnjiElektrikaResponse } from "../ts/models/odbirek-vrni-zadnji-elektrika-response";
import { OdbirekVrniZadnjiPlinRequest } from "../ts/models/odbirek-vrni-zadnji-plin-request";
import { OdbirekVrniZadnjiPlinResponse } from "../ts/models/odbirek-vrni-zadnji-plin-response";
import * as moment from "moment";

@injectable()
export class MerilnoMestoPorabaInformativniIzracunPorabeView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _izbranoMerilnoMesto: MerilnoMesto | null | undefined;
    private _dropdownMerilnaMestaElement: JQuery<HTMLElement> | null;
    private _btnIzracunajteElement: JQuery<HTMLElement> | null;
    private _formElement: JQuery<HTMLElement> | null;
    private _merilnaMesta: Array<MerilnoMesto> | null;
    private _jeDvotarifni: boolean;
    private _odbirekVrniZadnjiElektrikaModel: OdbirekVrniZadnjiElektrikaResponse | null;
    private _odbirekVrniZadnjiPlinModel: OdbirekVrniZadnjiPlinResponse | null;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        merilnoMestoApiClient: MerilnoMestoApiClient,
        private _notyf: Notyf,
        private _loader: FullscreenLoader)
    {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._izbranoMerilnoMesto = null;
        this._dropdownMerilnaMestaElement = null;
        this._btnIzracunajteElement = null;
        this._formElement = null;
        this._merilnaMesta = null;
        this._jeDvotarifni = true;
        this._odbirekVrniZadnjiElektrikaModel = null;
        this._odbirekVrniZadnjiPlinModel = null;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;
            var uporabnikMerilnoMestoRequest: UporabnikMerilnoMestoRequest = {
                limit: 0,
                stevilkaStrani: 0,
                saldo: false,
                skupnaPorabaEnergije: true,
                merilnoMesto: "",
                merilnaTocka: ""
            };
            await this._merilnoMestoApiClient.getUporabnikMerilnaMesta(uporabnikMerilnoMestoRequest)
                .then((odgovor: UporabnikMerilnoMestoResponse) => {
                    this._merilnaMesta = odgovor.merilnaMesta;
                    if (this._merilnaMesta && this._merilnaMesta.length > 0) {
                        // poiscemo ali obstaja selektirano MM (v querystringu)
                        var url = window.location.href.split("/");
                        var izbranoMerilnoMesto = url[url.length - 1];
                        this._izbranoMerilnoMesto = this._merilnaMesta.find(
                            a => a.merilnaTocka == izbranoMerilnoMesto || a.merilnoMesto == izbranoMerilnoMesto);
                        // ce v querystringu ni doloceno MM, selektiramo prvega MM iz liste
                        if (!this._izbranoMerilnoMesto) {
                            this._izbranoMerilnoMesto = this._merilnaMesta[0];
                        }
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSearchingForMeasuringPoints"]);
                });

            if (this._izbranoMerilnoMesto) {
                await this._informacijeMerilnoMesto();
                await this._odbirekVrniZadnji();
            }
            this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;
            if (this._izbranoMerilnoMesto) {
                this._initForm();
            }
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(translations: any): void
    {
        var merilnaMestaIds = new Array<string>();
        var prikazanaMerilnaMesta = new Array<string>();
        var steviloPrikazanih = 3;
        if (this._merilnaMesta) {
            merilnaMestaIds = Array.from(new Set(this._merilnaMesta.map((item) => item.merilnoMesto)));
            prikazanaMerilnaMesta = merilnaMestaIds.slice(0, steviloPrikazanih);
            if (this._merilnaMesta.length < steviloPrikazanih) {
                prikazanaMerilnaMesta = merilnaMestaIds;
            }
        }
        var localeFormat = moment().creationData().locale.longDateFormat("L");
        var formators = {
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            }//tukaj se lahko dodajajo formatorji
        };

        var et = "";
        var vt = "";
        var mt = "";
        var datumEt = new Date();
        var datumVt = new Date();
        var datumMt = new Date();
        var uspesno = true;
        var sporocilo = "";
        var jeDaljinskoOdcitavanje = false;

        if (this._izbranoMerilnoMesto) {
            // dvotarifni (lahko je samo elektrika)
            if (this._jeDvotarifni && this._odbirekVrniZadnjiElektrikaModel && this._odbirekVrniZadnjiElektrikaModel.registerVrednosti &&
                this._odbirekVrniZadnjiElektrikaModel.registerVrednosti.length > 0) {
                var register = this._odbirekVrniZadnjiElektrikaModel.registerVrednosti;
                for (let i = 0; i < register.length; i++) {
                    if (register[i].tipRegistra.includes("VT")) {
                        vt = register[i].vrednost.toString() + " " + register[i].oznakaEm;
                        datumVt = this._odbirekVrniZadnjiElektrikaModel.datumOdcitavanja;
                    }
                    else if (register[i].tipRegistra.includes("MT")) {
                        mt = register[i].vrednost.toString() + " " + register[i].oznakaEm;
                        datumMt = this._odbirekVrniZadnjiElektrikaModel.datumOdcitavanja;
                    }
                }
            }

            // enotarifni (lahko je elektrika / plin)
            if (!this._jeDvotarifni) {
                // elektrika
                if (this._izbranoMerilnoMesto.jeElektrika && this._odbirekVrniZadnjiElektrikaModel &&
                    this._odbirekVrniZadnjiElektrikaModel.registerVrednosti && this._odbirekVrniZadnjiElektrikaModel.registerVrednosti.length > 0) {
                    var register = this._odbirekVrniZadnjiElektrikaModel.registerVrednosti;
                    for (let i = 0; i < register.length; i++) {
                        if (register[i].tipRegistra.includes("ET")) {
                            et = register[i].vrednost.toString() + " " + register[i].oznakaEm;
                            datumEt = this._odbirekVrniZadnjiElektrikaModel.datumOdcitavanja;
                        }
                    }
                }
                // plin
                if (!this._izbranoMerilnoMesto.jeElektrika && this._odbirekVrniZadnjiPlinModel) {
                    et = this._odbirekVrniZadnjiPlinModel.vrednostOdcitavanja + " " + this._odbirekVrniZadnjiPlinModel.oznakaEm;
                    datumEt = this._odbirekVrniZadnjiPlinModel.datumOdcitavanja;
                }
            }

            // preverimo ali je daljinsko odcitavanje
            if (this._izbranoMerilnoMesto.jeElektrika && this._odbirekVrniZadnjiElektrikaModel
                && this._odbirekVrniZadnjiElektrikaModel.nacinEvidentiranjaMeritve == "EDP_DALJINSKO") {
                jeDaljinskoOdcitavanje = true;
            }

            // preverimo ali je vse bilo ok
            if (this._izbranoMerilnoMesto.jeElektrika && this._odbirekVrniZadnjiElektrikaModel && !this._odbirekVrniZadnjiElektrikaModel.status) {
                uspesno = false;
                sporocilo = this._odbirekVrniZadnjiElektrikaModel.sporocilo;
            }
            else if (!this._izbranoMerilnoMesto.jeElektrika && this._odbirekVrniZadnjiPlinModel && !this._odbirekVrniZadnjiPlinModel.status) {
                uspesno = false;
                sporocilo = this._odbirekVrniZadnjiPlinModel.sporocilo;
            }
        }
        

        const viewModel = {
            merilnaMestaIds: merilnaMestaIds,
            merilnaMesta: this._merilnaMesta,
            izbranoMerilnoMesto: this._izbranoMerilnoMesto,
            jeElektrika: this._izbranoMerilnoMesto && this._izbranoMerilnoMesto.jeElektrika,
            ostalaMerilnaMestaStevilo: merilnaMestaIds.length - prikazanaMerilnaMesta.length,
            obstajajoOstalaMerilnaMesta: merilnaMestaIds.length - prikazanaMerilnaMesta.length > 0 ? true : false,
            prikazanaMerilnaMesta: prikazanaMerilnaMesta,
            obstajaOdcitek: true,
            translations: translations,
            formators: formators,
            et: et,
            vt: vt,
            mt: mt,
            datumEt: datumEt,
            datumVt: datumVt,
            datumMt: datumMt,
            jeDvotarifni: this._jeDvotarifni,
            jeDaljinskoOdcitavanje: jeDaljinskoOdcitavanje,
            neuspesno: !uspesno,
            sporocilo: sporocilo,
            obstajaMerilnoMesto: this._izbranoMerilnoMesto ? true : false
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _initForm(): void {
        if (!this._izbranoMerilnoMesto) {
            return;
        }
        this._formElement = $(("#informativni-izracun-porabe-form"));
        this._dropdownMerilnaMestaElement = $("#dropdownMerilnaMesta");
        this._dropdownMerilnaMestaElement.on("change", (ev: Event) => this._onDropDownMerilnoMestoChange(ev));
        if (this._izbranoMerilnoMesto.jeElektrika) {
            this._dropdownMerilnaMestaElement.val(this._izbranoMerilnoMesto.merilnaTocka);
        }
        else {
            this._dropdownMerilnaMestaElement.val(this._izbranoMerilnoMesto.merilnoMesto);
        }
        this._btnIzracunajteElement = $("#btnIzracunajte");
        this._btnIzracunajteElement.on("click", (ev: Event) => this._onBtnIzracunajteClick(ev, this._formElement));
        var btnMerilnaMestaOthers = $("#btnMerilnaMestaOthers");
        btnMerilnaMestaOthers.on("click", () => {
            $(".merilna-mesta-not-all")[0].style.display = "none";
            $(".merilna-mesta-all")[0].style.display = "block";
        });
    }

    private _onDropDownMerilnoMestoChange(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        this._dropdownMerilnaMestaElement = $("#dropdownMerilnaMesta");
        var dropdownMerilnoMestoValue = (this._dropdownMerilnaMestaElement[0] as any).value;
        location.href = "#/merilno-mesto/informativni-izracun/" + dropdownMerilnoMestoValue;
    }

    private async _informacijeMerilnoMesto() {
        // Poiscemo ali je enotarifni ali dvotarifni
        if (this._izbranoMerilnoMesto) {
            if (this._izbranoMerilnoMesto.jeElektrika) {
                var data: MerilnoMestoInformacijeRequest = {
                    merilnoMesto: this._izbranoMerilnoMesto.merilnoMesto,
                    merilnaTocka: this._izbranoMerilnoMesto.merilnaTocka,
                    stevilkaKontoPogodbe: this._izbranoMerilnoMesto.stevilkaKontoPogodbe
                };
                await this._merilnoMestoApiClient.getInformacije(data)
                    .then((odgovor: MerilnoMestoInformacijeResponse) => {
                        if (odgovor.tehnicneInformacije && odgovor.tehnicneInformacije.naprave) {
                            var naprava = odgovor.tehnicneInformacije.naprave[0];
                            if (naprava.vrstaStevca == "ET") {
                                this._jeDvotarifni = false;
                            }
                            else {
                                this._jeDvotarifni = true;
                            }
                        }
                    })
                    .catch(() => {
                        this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    });
            }
            // Plin je vedno enotarifni
            else {
                this._jeDvotarifni = false;
            }
        }
    }

    private async _odbirekVrniZadnji() {
        if (this._izbranoMerilnoMesto) {
            if (this._izbranoMerilnoMesto.jeElektrika) {
                var data: OdbirekVrniZadnjiElektrikaRequest = {
                    merilnoMesto: this._izbranoMerilnoMesto.merilnoMesto,
                    merilnaTocka: this._izbranoMerilnoMesto.merilnaTocka
                };
                await this._merilnoMestoApiClient.odbirekVrniZadnjiElektrika(data)
                    .then((odgovor: OdbirekVrniZadnjiElektrikaResponse) => {
                        this._odbirekVrniZadnjiElektrikaModel = odgovor;
                    })
                    .catch(() => {
                        this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    });
            }
            else {
                var data: OdbirekVrniZadnjiPlinRequest = {
                    merilnoMesto: this._izbranoMerilnoMesto.merilnoMesto,
                    merilnaTocka: this._izbranoMerilnoMesto.merilnaTocka
                };
                await this._merilnoMestoApiClient.odbirekVrniZadnjiPlin(data)
                    .then((odgovor: OdbirekVrniZadnjiPlinResponse) => {
                        this._odbirekVrniZadnjiPlinModel = odgovor;
                    })
                    .catch(() => {
                        this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    });
            }
        }
    }

    private _onBtnIzracunajteClick(ev: Event, formElement: any) {       
        console.debug(ev);
        ev.preventDefault();
        if (this._izbranoMerilnoMesto == null) {
            return;
        }
        this._loader.show();

        var formData = new FormData(formElement[0] as HTMLFormElement);
        var vt = formData.get("vt-input") as any;
        var mt = formData.get("mt-input") as any;
        var et = formData.get("et-input") as any;
        var kt = formData.get("kt-input") as any;
        var m3 = 0;

        if ((this._jeDvotarifni && !vt && !mt) || (!this._jeDvotarifni && !et)) {
            this._loader.hide();
            this._notyf.error(this._translationService.currentTranslations["CheckAllFields"]);
            return;
        }

        // ce je plin nastavimo M3 in odstranimo ET
        if (!this._izbranoMerilnoMesto.jeElektrika) {
            m3 = et;
            et = 0;
        }

        var informativniIzracunPorabeModel: InformativniIzracunPorabeModel = {
            vrstaEnergenta: this._izbranoMerilnoMesto.jeElektrika ? VrstaEnergenta.Elektrika : VrstaEnergenta.Plin,
            merilnoMesto: this._izbranoMerilnoMesto.merilnoMesto,
            merilnaTocka: this._izbranoMerilnoMesto.merilnaTocka,
            stevilkaKontoPogodbe: this._izbranoMerilnoMesto.stevilkaKontoPogodbe,
            vt: vt ? vt : null,
            mt: mt ? mt : null,
            et: et ? et : null,
            kt: kt ? kt : null,
            m3: m3 ? m3 : null
        };
        this._merilnoMestoApiClient.informativniIzracunPorabe(informativniIzracunPorabeModel)
            .then((odgovor) => {
                if (odgovor.status) {
                    $(".izracunajte-predvideno-porabo")[0].style.display = "none";
                    $(".povratna-informacija")[0].style.display = "block";
                    $(".skupni-znesek-brez-ddv")[0].innerHTML = odgovor.skupniZnesekBrezDDV + odgovor.valuta;
                    $(".znesek-ddv")[0].innerHTML = odgovor.znesekDDV + odgovor.valuta;
                    $(".skupni-znesek-ddv")[0].innerHTML = odgovor.skupniZnesekDDV + odgovor.valuta;
                    this._loader.hide();
                }
                else {
                    this._loader.hide();
                    this._notyf.error(odgovor.sporocilo);
                }
            })
            .catch(() => {
                this._loader.hide();
                this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSearchingForAnInformativeConsumptionCalculation"]);
            });
    }
}
