import { injectable } from "inversify";
import * as Mustache from "mustache";
import { TranslationService } from "../ts/translation-service";
import template from "./merilno-mesto-dodaj-povratna-informacija-view.html";

@injectable()
export class MerilnoMestoDodajPovratnaInformacijaView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _neuspesnoDodana: Array<any>;

    constructor(rootElement: JQuery<HTMLElement>, translationService: TranslationService,
        neuspesnoDodana: Array<any>)
    {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._neuspesnoDodana = neuspesnoDodana;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            this._renderData();
            if ($("#page-loaded")[0] == undefined) return;
            window.scrollTo(0, 0);
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(): void {
        const viewModel = {
            obstajaNeuspesno: this._neuspesnoDodana.length > 0 ? true : false,
            neuspesnoDodana: this._neuspesnoDodana,
            translations: this._translationService.currentTranslations
        } as any;
        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }
}