import { NavigationNode } from "./models/navigation-node";
 
var rootNodes: Array<NavigationNode> = [
    {
        title: "Popusti in akcije",
        "class": "",
        "class2": "",
        url: "#/akcije",
        hasUrl: true,
        hasChildren: true,
        children: [
            { "title": "Akcijske ponudbe", "class": "btnAkcije", "url": "#/akcije/prikaz", hasUrl: true, "code": "" },
            { "title": "Ugodnosti", "class": "btnUgodnosti", "url": "#/akcije/ugodnosti", hasUrl: true, "code": "" },
            /*{ "title": "Anketni vprašalnik", "class": "btnAkcijeAnketniVprasalnik", "url": "#/akcije/anketni-vprasalnik", "code": "" }*/
        ]
    },
    {
        title: "Kontakti in pomoč",
        "class": "",
        "class2": "",
        url: "#/kontakti",
        hasUrl: true,
        hasChildren: true,
        children: [
            { "title": "Ebox", "class": "btnKontaktiEbox", "url": "#/kontakti/ebox", hasUrl:true, "code": "" },
            { "title": "Prodajna mesta", "class": "btnProdajnaMesta", "url": "#/kontakti/prodajna-mesta", hasUrl: true, "code": "" },
            { "title": "Vloge in obrazci", "class": "btnVloge", "url": "#/vloge/vloge-in-obrazci", hasUrl: true, "code": "" },
            { "title": "Pogosta vprašanja", "class": "btnKontaktiPogostaVprasanjaKategorije", "url": "#/kontakti/kategorije-pogosta-vprasanja", hasUrl: true, "code": "" },
            { "title": "Novice", "class": "btnNovicePregled", "url": "#/novice-pregled", hasUrl: true, "code": "" },
        ]
    },
    {
        title: "Merilno / odjemno mesto",
        "class": "",
        "class2": "",
        url: "#/merilno-mesto",
        hasUrl: true,
        hasChildren: true,
        children: [
            { "title": "Računi in plačila", "class": "btnMerilnoMestoRacuni", "url": "#/merilno-mesto/racuni", hasUrl: true, "code": "" },
            { "title": "Poraba merilnih/odjemnih mest", "class": "btnMerilnoMestoPoraba", "url": "#/merilno-mesto/poraba", hasUrl: true, "code": "" },
            { "title": "Zbirnik računov", "class": "btnZbirnikRacunov", "url": "#/zbirnik-racunov", hasUrl: true, "code": "" },
            { "title": "Preglejte 15 minutne meritve", "class": "btnMerilnoMestoPorabaMinutneMeritve", "url": "#/merilno-mesto/minutne-meritve", hasUrl: true, "code": "" },
            { "title": "Seznam merilnih mest", "class": "btnMerilnoMesto", "url": "#/merilno-mesto/seznam", hasUrl: true, "code": "" },
            { "title": "Vnos stanja števca", "class": "btnMerilnoMestoVnosStanjaStevca", "url": "#/merilno-mesto/vnos-stanja-stevca", hasUrl: true, "code": "" },
            { "title": "Informacije o merilnem/odjemnem mestu", "class": "btnMerilnoMestoInformacije", "url": "#/merilno-mesto/informacije", hasUrl: true, "code": "" },
            { "title": "Naročilo direktne bremenitve", "class": "btnMerilnoMestoRacuniDirektnaObremenitev", "url": "#/vloge/vnos-podatkov/direktna-obremenitev", hasUrl: true, "code": "" },
            { "title": "Prilagajanje prikaza porabe", "class": "btnMerilnoMestoPorabaSestevekPorabe", "url": "#/merilno-mesto/sestevek-porabe", hasUrl: true, "code": "" },
            { "title": "Izvoz podatkov za energetsko izkaznico", "class": "btnMerilnoMestoPorabaEnergetskaIzkaznica", "url": "#/merilno-mesto/energetska-izkaznica", hasUrl: true, "code": "" },
            { "title": "Izračun porabe naprav", "class": "btnMerilnoMestoPorabaNaprav", "url": "#/merilno-mesto/naprave", hasUrl: true, "code": "" },
            { "title": "Izbira načina obračuna", "class": "btnMerilnoMestoNacinObracuna", "url": "#/merilno-mesto/nacin-obracuna", hasUrl: true, "code": "" },
            /*{ "title": "Informativni izračun porabe", "class": "btnMerilnoMestoPorabaInformativniIzracun", "url": "#/merilno-mesto/informativni-izracun", hasUrl: true, code: ""}*/
            /*{ "title": "Naročilo 15 minutne meritve", "class": "btnMerilnoMestoNarociloMinutneMeritve", "url": "#/merilno-mesto/narocilo-minutne-meritve", hasUrl: true, "code": "" },*/
        ]
    },

    {
        title: "Moj PLUS",
        "class": "",
        "class2": "",
        url: "#/moj-plus",
        hasUrl: true,
        hasChildren: true,
        children: [
            { "title": "Pregled stanja", "class": "btnMojPlusPregled", "url": "#/moj-plus/pregled", hasUrl: true, "code": "" },
            { "title": "Koriščenje kupona", "class": "btnMojPlusKoriscenjeKupona", "url": "#/moj-plus/koriscenje-kupona", hasUrl: true, "code": "" },
            { "title": "Moje točke", "class": "btnMojPlusMojeTocke", "url": "#/moj-plus/moje-tocke", hasUrl: true, "code": "" },
            { "title": "Pomembne informacije", "class": "btnMojPlusPomembneInformacije", "url": "#/moj-plus/pomembne-informacije", hasUrl: true, "code": "" },
            { "title": "Ugodnosti", "class": "btnMojPlusUgodnosti", "url": "#/moj-plus/ugodnosti", hasUrl: true, "code": "" },
            { "title": "Zbiranje točk", "class": "btnMojPlusZbiranjeTock", "url": "#/moj-plus/zbiranje-tock", hasUrl: true, "code": "" },
            { "title": "Povabite prijatelja", "class": "btnMojPlusPovabitePrijatelja", "url": "#/moj-plus/povabite-prijatelja", hasUrl: true, "code": "" },
            { "title": "Včlanitev", "class": "btnMojPlusVclanitev", "url": "#/moj-plus/vclanitev", hasUrl: true, "code": "" },
        ]
    },

    {
        title: "Uporabniški račun",
        "class": "moj-profil",
        "class2": "moj-profil-alert",
        url: "#/profil",
        hasUrl: true,
        hasChildren: true,
        children: [
            { "title": "Admin", "class": "btnAdmin", "url": "#/admin/dashboard", hasUrl: true, "code": "", onlyForAdmin: true },
            { "title": "Klicni center", "class": "btnKlicniCenter", "url": "#/klicni-center-seznam", hasUrl: true, "code": "", onlyForKlicniCenter: true },
            { "title": "Sporočila", "class": "btnSporocilaHeader", "url": "", hasUrl: false, "code": '<span id="msgs-no-header" class="msgs-no"></span>' },
            { "title": "Nastavitve računa", "class": "btnProfil", "url": "#/moj-profil", hasUrl: true, "code": "" },
            { "title": "Naloži datoteko", "class": "btnNaloziDatoteko", "url": "#/nalozi-datoteko", hasUrl: true, "code": "", onlyForSpecialUsers: true },
            { "title": "Odjava", "class": "btnOdjava", "url": "#/odjava-moj-profil", hasUrl: true, "code": "" },
        ]
    }
];
export default rootNodes;