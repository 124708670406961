import { inject, injectable } from "inversify";
import * as Mustache from "mustache";
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { UserApiClient } from "../ts/clients/users-api-client";
import template from "./footer-view.html";


@injectable()
export class FooterView {

    private _footer: JQuery<HTMLElement> = null!;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _userApiClient: UserApiClient;
    private _sodo: string;
    private _naziv: string;
    private _telefon: string;
    private _appVersion: string;
    private _isMobile: boolean;

    constructor(footer: JQuery<HTMLElement>,
        @inject("MerilnoMestoApiClient") merilnoMestoApiClient: MerilnoMestoApiClient,
        @inject("UserApiClient") userApiClient: UserApiClient) {
        this._footer = footer;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._userApiClient = userApiClient;
        this._sodo = "";
        this._naziv = "";
        this._telefon = "";
        this._appVersion = "1.0.1";
        this._isMobile = false;
    }

    public async load(): Promise<void> {
        try {
            var resultSodo = await this._merilnoMestoApiClient.pridobiSodo();
            if (resultSodo) {
                this._sodo = resultSodo.sodo;
            }

            var width = window.innerWidth;
            // Mobile
            if (width <= 991) {
                this._isMobile = true;
                var resultAppVersion = await this._userApiClient.getMobileAppVersion();
                if (resultAppVersion.success) {
                    this._appVersion = resultAppVersion.mobileAppVersion;
                }
            }

            await this._renderData();
            this._initForm();
        }
        catch (e) {
            // Clear previous content on error
            //$('#main').text('Napaka pri nalaganju');
            throw e;
        }
    }

    private async _renderData(): Promise<void> {
        this._pridobiPodatke(this._sodo);

        const viewModel = {
            sodoObstaja: this._sodo == "" ? false : true,
            naziv: this._naziv,
            telefon: this._telefon,
            appVersion: this._appVersion,
            isMobile: this._isMobile
        } as any;

        const htmlTemplate = template;
        const html = Mustache.render(htmlTemplate, viewModel);
        this._footer.replaceWith(html);
        // return Promise.resolve();
    }

    private _pridobiPodatke(sodo: string) {
        if (sodo == "Elektro Celje") {
            this._naziv = "Elektro Celje d.o.o.";
            this._telefon = "03 42 01 240";
        }
        else if (sodo == "Elektro Gorenjska") {
            this._naziv = "Elektro Gorenjska d.o.o.";
            this._telefon = "04 20 83 148";
        }
        else if (sodo == "Elektro Ljubljana") {
            this._naziv = "Elektro Ljubljana d.o.o.";
            this._telefon = "01 230 40 02";
        }
        else if (sodo == "Elektro Maribor") {
            this._naziv = "Elektro Maribor d.o.o.";
            this._telefon = "080 21 05";
        }
        else if (sodo == "Elektro Primorska") {
            this._naziv = "Elektro Primorska d.o.o.";
            this._telefon = "080 34 31";
        }
    }

    private _initForm() {
        var dropdownSodo = $("#dropdownSodo");
        if (dropdownSodo && dropdownSodo.length > 0) {
            dropdownSodo.val(this._sodo);
            dropdownSodo.on("change", () => {
                this._sodo = dropdownSodo.val() as string;
                if (this._sodo) {
                    this._pridobiPodatke(this._sodo);
                    var sodoNaziv = $("#sodo-naziv");
                    if (sodoNaziv && sodoNaziv.length > 0) {
                        sodoNaziv[0].innerHTML = "Vaš dežurni SODO je: " + this._naziv;
                    }
                    var sodoTelefon = $("#sodo-telefon");
                    if (sodoTelefon && sodoTelefon.length > 0) {
                        sodoTelefon[0].innerHTML = this._telefon;
                        var urlTelefon = "tel:" + this._telefon.replace(/\s/g, "");
                        sodoTelefon[0].setAttribute("href", urlTelefon);
                    }
                }
            })
        }
    }
}