import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { AdminApiClient } from "../ts/clients/admin-api-client";
import { AdminGetRequestLogRequest } from "../ts/models/admin-get-request-log-request";
import { AdminRequestLogModel } from "../ts/models/admin-request-log-model";
import { ListResponse } from "../ts/models/list-response";
import { TranslationService } from "../ts/translation-service";
import template from "./admin-zahteve-in-napake-view.html";
import * as moment from "moment";

@injectable()
export class AdminZahteveInNapakeView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _adminApiClient: AdminApiClient;
    private _btnPocistiFiltre: JQuery<HTMLElement> | null;
    private _limit: number;
    private _maxPages: number;
    private _items: Array<any>;
    private _itemsCount: number;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        adminApiClient: AdminApiClient,
        private _notyf: Notyf) {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._adminApiClient = adminApiClient;
        this._btnPocistiFiltre = null;
        this._limit = 25;
        this._maxPages = 1;
        this._items = new Array<AdminRequestLogModel>();
        this._itemsCount = 0;
    }

    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;
            this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;
            this._showLoader();
            await this._getLogs(1);
            this._refreshTables(1);
            this._initForm();
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(translations: any): void {
        const viewModel = {
            translations: translations
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _initForm() {
        this._btnPocistiFiltre = $("#btnPocistiFiltre");
        this._btnPocistiFiltre.on("click", (ev: Event) => this._onBtnPocistiFiltreClick(ev));
        var btnPoiscite = $("#poiscite");
        btnPoiscite.on("click", (ev: Event) => this._onBtnSearchClick(ev));

        // radio buttons
        var statusZahteveUspesno = $(".statusZahteveUspesno");
        statusZahteveUspesno.on("click", async () => {
            this._showLoader();
            await this._getLogs(1);
            this._refreshTables(1);
        });

        var statusZahteveNeuspesno = $(".statusZahteveNeuspesno");
        statusZahteveNeuspesno.on("click", async () => {
            this._showLoader();
            await this._getLogs(1);
            this._refreshTables(1);
        });

        var statusZahteveVse = $(".statusZahteveVse");
        statusZahteveVse.on("click", async () => {
            this._showLoader();
            await this._getLogs(1);
            this._refreshTables(1);
        });

        var dropdownTipZahteve = $("#dropdownTipZahteve");
        dropdownTipZahteve.on("change", async () => {
            this._showLoader();
            await this._getLogs(1);
            this._refreshTables(1);
        })
    }

    private async _onBtnPocistiFiltreClick(ev: Event) {
        ev.preventDefault();
        this._showLoader();
        ($(".statusZahteveVse")[0] as HTMLInputElement).checked = true;
        $("#dropdownTipZahteve").val("");
        $(".user-search-field").val("");
        await this._getLogs(1);
        this._refreshTables(1);
    }

    private async _onBtnSearchClick(ev: Event) {
        ev.preventDefault();
        this._showLoader();
        await this._getLogs(1);
        this._refreshTables(1);
    }

    private async _getLogs(page: number) {
        var getLogsRequest: AdminGetRequestLogRequest = {
            search: ($(".user-search-field")[0] as HTMLInputElement).value,
            page: page,
            limit: this._limit,
            type: this._getTypeFilter(),
            status: this._getStatusFilter()
        };
        await this._adminApiClient.getRequestLogs(getLogsRequest)
            .then((odgovor: ListResponse<AdminRequestLogModel>) => {
                this._items = odgovor.items;
                this._maxPages = odgovor.pageCount;
                this._itemsCount = odgovor.totalCount;
            })
            .catch((err) => {
                console.log(err);
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
    }

    private _refreshTables(page: number) {
        this._refreshTableDesktop();
        this._refreshTableMobile();
        this._refreshPagination(page);
        $(".tabela-desktop").addClass("d-lg-block");
        $(".tabela-desktop").removeClass("d-lg-none");
        $(".tabela-mobile").addClass("d-block");
        $(".tabela-mobile").removeClass("d-none");
        $("input:radio").prop("disabled", false);
    }

    private async _onBtnPageChangeClick(ev: Event, previousClicked: boolean) {
        console.debug(ev);
        ev.preventDefault();
        this._showLoader();
        var pagesString = $(".pages")[0].innerHTML;
        var currentPage = Number(pagesString.split("/")[0]);
        if (previousClicked) {
            currentPage -= 1;
        }
        else {
            currentPage += 1;
        }
        await this._getLogs(currentPage);
        this._refreshTables(currentPage);
    }

    private _refreshPagination(page: number) {
        if (this._items.length <= 0) {
            $("#pagination")[0].style.display = "none";
            return;
        }
        const templateString = `
             <div class="pagination">
                <%#obstajaPrejsnjaStran%>
                <div>
                    <a href="javascript:void(0)" class="btnPreviousPage">&lt</a>
                </div>
                <%/obstajaPrejsnjaStran%>
                <div class="pages"><%{page}%>/<%{maxPage}%></div>
                <%#obstajaNaslednjaStran%>
                <div>
                    <a href="javascript:void(0)" class="btnNextPage">&gt</a>
                </div>
                <%/obstajaNaslednjaStran%>
            </div>
        `;
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "page": page,
                "maxPage": this._maxPages,
                "obstajaPrejsnjaStran": page != 1 ? true : false,
                "obstajaNaslednjaStran": page != this._maxPages ? true : false
            },
            undefined,
            customTags
        );
        $("#pagination")[0].style.display = "block";
        $("#pagination").html(html);
        $(".btnPreviousPage").on("click", async (ev: Event) => await this._onBtnPageChangeClick(ev, true));
        $(".btnNextPage").on("click", async (ev: Event) => await this._onBtnPageChangeClick(ev, false));
    }

    private _refreshTableDesktop() {
        const templateString = `
             <%#itemsExist%>
             <h3 class="margin-small"><%{translations.Requests}%> (<%{itemsCount}%>)</h3>
             <table class="table vloge-obrazci">
                 <thead>
                    <tr>
                        <th scope="col"><%{translations.Id}%></th>
                        <th scope="col"><%{translations.Type}%></th>
                        <th scope="col"><%{translations.Data}%></th>
                        <th scope="col"><%{translations.Status}%></th>
                        <th scope="col"><%{translations.Message}%></th>
                        <th scope="col"><%{translations.User}%></th>
                        <th scope="col"><%{translations.Reference}%></th>
                        <th scope="col"><%{translations.Created}%></th>
                    </tr>
                </thead>
                <tbody>
                    <%#logItems%>
                    <tr>
                        <td scope="row"><%{id}%></td>
                        <td><%{type}%></td>
                        <td><%{data}%></td>
                        <%#success%>
                        <td><img src="../img/icon-reg-checkmark.png" class="img-fluid" /></td>
                        <%/success%>
                        <%^success%>
                        <td><img src="../img/icon-box-important.svg" class="img-fluid" /></td>
                        <%/success%>
                        <td><%{message}%></td>
                        <td><%{user}%></td>
                        <td><%{reference}%></td>
                        <td>
                            <%#formators%><%#dateTimeFormat%><%{created}%><%/dateTimeFormat%><%/formators%>                           
                        </td>                       
                    </tr>
                    <%/logItems%>
                </tbody>
            </table>
            <%/itemsExist%>
            <%^itemsExist%>
            <div><%{translations.NoRequestsFound}%></div>
            <%/itemsExist%>
        `
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "itemsExist": this._items.length > 0 ? true : false,
                "logItems": this._items,
                "itemsCount": this._itemsCount,
                "translations": this._translationService.currentTranslations,
                "formators": this.getFormators(),
            },
            undefined,
            customTags
        );
        $(".tabela-desktop").html(html);
    }

    public getFormators() {
        const userLocale = document.documentElement.lang
            ? document.documentElement.lang
            : 'en';
        moment.locale(userLocale);
        var localeFormat = moment().creationData().locale.longDateFormat("L");
        return {
            dateTimeFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat + ' HH:mm');
                };
            },
        };
    }

    private _refreshTableMobile() {
        const templateString = `
            <%#itemsExist%>
            <h3 class="margin-small"><%{translations.Requests}%> (<%{itemsCount}%>)</h3>
            <table class="table vloge-obrazci-mobile table-borderless">
                <%#logItems%>
                <tbody>
                    <tr>
                        <th scope="row"><%{translations.Id}%></th>
                        <td><%{id}%></td>
                    </tr>
                    <tr>
                        <th scope="row"><%{translations.Type}%></th>
                        <td><%{type}%></td>
                    </tr>
                    <tr>
                        <th scope="row"><%{translations.Data}%></th>
                        <td><%{data}%></td>
                    </tr>
                    <tr>
                        <th scope="row"><%{translations.Status}%></th>
                        <%#success%>
                        <td><img src="../img/icon-reg-checkmark.png" class="img-fluid" /></td>
                        <%/success%>
                        <%^success%>
                        <td><img src="../img/icon-box-important.svg" class="img-fluid" /></td>
                        <%/success%>
                    </tr>
                    <tr>
                        <th scope="row"><%{translations.Message}%></th>
                        <td><%{message}%></td>
                    </tr>
                    <tr>
                        <th scope="row"><%{translations.User}%></th>
                        <td><%{user}%></td>
                    </tr>
                    <tr>
                        <th scope="row"><%{translations.Reference}%></th>
                        <td><%{reference}%></td>
                    </tr>
                    <tr>
                        <th scope="row"><%{translations.Created}%></th>
                        <td>
                            <%#formators%><%#dateTimeFormat%><%{created}%><%/dateTimeFormat%><%/formators%>                           
                        </td>
                    </tr>                   
                </tbody>
                <%/logItems%>
            </table>  
            <%/itemsExist%>
            <%^itemsExist%>
            <div><%{translations.NoRequestsFound}%></div>
            <%/itemsExist%>
        `;
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "itemsExist": this._items.length > 0 ? true : false,
                "logItems": this._items,
                "translations": this._translationService.currentTranslations,
                "itemsCount": this._itemsCount,
            },
            undefined,
            customTags
        );
        $(".tabela-mobile").html(html);
    }

    private _getTypeFilter() {
        var type = $("#dropdownTipZahteve").val() as string;
        if (type) {
            return parseInt(type);
        }
        return null;
    }

    private _getStatusFilter() {
        if (($(".statusZahteveUspesno")[0] as HTMLInputElement).checked) {
            return true;
        }
        else if (($(".statusZahteveNeuspesno")[0] as HTMLInputElement).checked) {
            return false;
        }
        else {
            return null;
        }
    }

    private _showLoader() {
        $("input:radio").prop("disabled", true);
        this.rootElement.find(".table-responsive").html(`<div class="text-center"><div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status"></div></div>`);
    }
}
