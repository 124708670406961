import { injectable } from "inversify";
import * as moment from "moment";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { TranslationService } from "../ts/translation-service";
import * as DateRangePicker from 'daterangepicker';
import template from "./merilno-mesto-minutne-meritve-view.html";
import * as ApexCharts from 'apexcharts';
import { FullscreenLoader } from "./fullscreen-loader";
import { PorabaOdjemalcaOdjemnoMesto, PorabaOdjemalcaRequest } from "../ts/models/poraba-odjemalca-request";
import minutneMeritveGrafView from "./merilno-mesto-minutne-meritve-graf-view.html";
import { UporabnikMerilnoMestoRequest } from "../ts/models/uporabnik-merilno-mesto-request";
import { MerilnoMesto } from "../ts/models/merilno-mesto";
import { UporabnikMerilnoMestoResponse } from "../ts/models/uporabnik-merilno-mesto-response";

@injectable()
export class MerilnoMestoMinutneMeritveView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;   
    private _dropdownMerilnaMestaElement: JQuery<HTMLElement> | null;
    private _formElement: JQuery<HTMLElement> | null;
    private _izbranoMerilnoMesto: MerilnoMesto | null | undefined;
    private _minutneMeritveObdobjeOdPicker: DateRangePicker = null!;
    private _minutneMeritveObdobjeDoPicker: DateRangePicker = null!;
    private _merilnaMesta: Array<MerilnoMesto> | null;
    private _formators: any;
    private _porabaSumarno: any;
    private _casovnaEnota: string;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        merilnoMestoApiClient: MerilnoMestoApiClient,
        private _notyf: Notyf,
        private _loader: FullscreenLoader)
    {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._dropdownMerilnaMestaElement = null;
        this._formElement = null;
        this._izbranoMerilnoMesto = null;
        this._merilnaMesta = null;
        this._porabaSumarno = null;
        this._casovnaEnota = "";
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;
            var uporabnikMerilnoMestoRequest: UporabnikMerilnoMestoRequest = {
                limit: 0,
                stevilkaStrani: 0,
                saldo: false,
                skupnaPorabaEnergije: false,
                merilnoMesto: "",
                merilnaTocka: ""
            };
            await this._merilnoMestoApiClient.getUporabnikMerilnaMesta(uporabnikMerilnoMestoRequest)
                .then((odgovor: UporabnikMerilnoMestoResponse) => {
                    this._merilnaMesta = odgovor.merilnaMesta;
                    if (this._merilnaMesta && this._merilnaMesta.length > 0) {
                        this._merilnaMesta = this._merilnaMesta.filter(a => a.jeElektrika == true);
                    }
                    if (this._merilnaMesta && this._merilnaMesta.length > 0) {
                        // poiscemo ali obstaja selektirano MM (v querystringu)
                        var url_string = window.location.href.split("?");
                        var url_data = url_string[url_string.length - 1];
                        var data_list = url_data.split("_");
                        const stevilkaKontoPogodbe = data_list[data_list.length - 1];
                        const merilnoMesto = data_list[data_list.length - 2];
                        const jeElektrika = data_list[data_list.length - 3];
                        const merilnaTocka = data_list[data_list.length - 4];
                        if (jeElektrika == 'true') {
                            this._izbranoMerilnoMesto = this._merilnaMesta.find(
                                a => a.merilnoMesto == merilnoMesto && a.merilnaTocka == merilnaTocka && a.stevilkaKontoPogodbe == stevilkaKontoPogodbe);
                        }
                        else if (jeElektrika == 'false') {
                            this._izbranoMerilnoMesto = this._merilnaMesta.find(
                                a => a.merilnoMesto == merilnoMesto && a.stevilkaKontoPogodbe == stevilkaKontoPogodbe);
                        }
                        // ce v querystringu ni doloceno MM, selektiramo prvega MM iz liste
                        if (!this._izbranoMerilnoMesto) {
                            this._izbranoMerilnoMesto = this._merilnaMesta[0];
                        }
                    }
                    else {
                        this.rootElement.text(this._translationService.currentTranslations["YouDoNotHaveAnyMeasuringPoint"]);
                        return;
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSearchingForMeasuringPoints"]);
                });

            this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;
            this._initForm();
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(translations: any): void {
        if (!this._merilnaMesta) {
            return;
        }
        var merilnaMestaIds = Array.from(new Set(this._merilnaMesta.map((item) => item.merilnoMesto)));
        var steviloPrikazanih = 3;
        var prikazanaMerilnaMesta = merilnaMestaIds.slice(0, steviloPrikazanih);
        if (this._merilnaMesta.length < steviloPrikazanih) {
            prikazanaMerilnaMesta = merilnaMestaIds;
        }

        var localeFormat = moment().creationData().locale.longDateFormat("L");
        this._formators = {
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            }//tukaj se lahko dodajajo formatorji
        };
        const viewModel = {
            merilnaMestaIds: merilnaMestaIds,
            merilnaMesta: this._merilnaMesta,
            izbranoMerilnoMesto: this._izbranoMerilnoMesto,
            ostalaMerilnaMestaStevilo: merilnaMestaIds.length - prikazanaMerilnaMesta.length,
            obstajajoOstalaMerilnaMesta: merilnaMestaIds.length - prikazanaMerilnaMesta.length > 0 ? true : false,
            prikazanaMerilnaMesta: prikazanaMerilnaMesta,
            translations: translations,
            formators: this._formators
        } as any;
        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _initForm(): void {
        if (!this._izbranoMerilnoMesto) {
            return;
        }
        require('jquery-validation-unobtrusive');
        jQuery.validator.unobtrusive.parse($('#main').find(".prikaz-racunov"));

        this._dropdownMerilnaMestaElement = $("#dropdownMerilnaMesta");
        this._dropdownMerilnaMestaElement.on("change", (ev: Event) => this._onDropDownMerilnoMestoChange(ev)); 
        if (this._izbranoMerilnoMesto.jeElektrika) {
            this._dropdownMerilnaMestaElement.val(this._izbranoMerilnoMesto.jeElektrika + "_" + this._izbranoMerilnoMesto.stevilkaKontoPogodbe + "_" + this._izbranoMerilnoMesto.merilnoMesto + "_" + this._izbranoMerilnoMesto.merilnaTocka);
        }
        else {
            this._dropdownMerilnaMestaElement.val(this._izbranoMerilnoMesto.jeElektrika + "_" + this._izbranoMerilnoMesto.stevilkaKontoPogodbe + "_" + this._izbranoMerilnoMesto.merilnoMesto);
        }

        var btnMerilnaMestaOthers = $("#btnMerilnaMestaOthers");
        btnMerilnaMestaOthers.on("click", (ev: Event) => this._onBtnMerilnaMestaOthersClick(ev));

        this._minutneMeritveObdobjeOdPicker = new DateRangePicker($('#main').find("#minutneMeritveObdobjeOd")[0], {
            timePicker: true,
            timePicker24Hour: true,
            timePickerIncrement: 15,
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            locale: {
                format: 'DD.MM.YYYY HH:mm'
            },
            minDate: moment().add(-20, 'years'),
            maxDate: moment()
        });
        $(".cancelBtn")[0].innerHTML = "Prekliči";
        $(".applyBtn")[0].innerHTML = "Potrdi";

        this._minutneMeritveObdobjeDoPicker = new DateRangePicker($('#main').find("#minutneMeritveObdobjeDo")[0], {
            timePicker: true,
            timePicker24Hour: true,
            timePickerIncrement: 15,
            showDropdowns: true,
            singleDatePicker: true,
            autoApply: true,
            locale: {
                format: 'DD.MM.YYYY HH:mm'
            },
            minDate: moment().add(-20, 'years'),
            maxDate: moment()
        });
        $(".cancelBtn")[1].innerHTML = "Prekliči";
        $(".applyBtn")[1].innerHTML = "Potrdi";

        var btnMinutneMeritvePrejsnjiDanElement = $("#btn-minutne-meritve-prejsnji-dan");
        btnMinutneMeritvePrejsnjiDanElement.on("click", (ev: Event) => this._onBtnPrejsnjiDanClick(ev, this._minutneMeritveObdobjeOdPicker, this._minutneMeritveObdobjeDoPicker));

        var btnMinutneMeritveZadnjiTedenElement = $("#btn-minutne-meritve-zadnji-teden");
        btnMinutneMeritveZadnjiTedenElement.on("click", (ev: Event) => this._onBtnZadnjiTedenClick(ev, this._minutneMeritveObdobjeOdPicker, this._minutneMeritveObdobjeDoPicker));

        var btnMinutneMeritveZadnjiMesecElement = $("#btn-minutne-meritve-zadnji-mesec");
        btnMinutneMeritveZadnjiMesecElement.on("click", (ev: Event) => this._onBtnZadnjiMesecClick(ev, this._minutneMeritveObdobjeOdPicker, this._minutneMeritveObdobjeDoPicker)); 

        var btnPregledMinutneMeritveElement = $("#btnPregledMinutneMeritve");
        btnPregledMinutneMeritveElement.on("click", (ev: Event) => this._onBtnPregledMerilneMeritveClick(ev));

        var btnPrenesiMinutneMeritve = $("#prenesite-minutne-meritve");
        btnPrenesiMinutneMeritve.on("click", (ev: Event) => this._onBtnPrenesiMinutneMeritveClick(ev));
    }

    private async _onBtnPrenesiMinutneMeritveClick(ev: Event) {
        ev.preventDefault();

        var downloadingNotyf = this._notyf.open({
            type: "info",
            message: this._translationService.currentTranslations["Downloading"],
            duration: 50000
        });

        if (this._casovnaEnota != "15") {
            await this._getMinutneMeritveZaDownload();
        }

        if (this._porabaSumarno == null) {
            this._notyf.error("Ni najden noben odčitek.");
        }
        
        await this._merilnoMestoApiClient.pregledMinutneMeritveDownload(this._porabaSumarno)
            .then((response) => {
                const downloadUrl = response.downloadUrl;
                const fileLink = document.createElement('a');
                fileLink.href = downloadUrl;
                fileLink.click();
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["ErrorWhileDownloadingFile"]);
            }).finally(() => {
                this._notyf.dismiss(downloadingNotyf);
            });
    }

    private async _getMinutneMeritveZaDownload() {
        if (!this._izbranoMerilnoMesto) {
            return;
        }

        this._loader.show();

        var odjemnoMesto: PorabaOdjemalcaOdjemnoMesto = {
            merilnoMesto: this._izbranoMerilnoMesto.merilnoMesto,
            merilnaTocka: this._izbranoMerilnoMesto.merilnaTocka,
            stevilkaKontoPogodbe: this._izbranoMerilnoMesto.stevilkaKontoPogodbe
        };
        var datumOd = new Date(
            this._minutneMeritveObdobjeOdPicker.startDate.year(), this._minutneMeritveObdobjeOdPicker.startDate.month(),
            this._minutneMeritveObdobjeOdPicker.startDate.date(), this._minutneMeritveObdobjeOdPicker.startDate.hours(),
            this._minutneMeritveObdobjeOdPicker.startDate.minutes());
        var datumDo = new Date(
            this._minutneMeritveObdobjeDoPicker.startDate.year(), this._minutneMeritveObdobjeDoPicker.startDate.month(),
            this._minutneMeritveObdobjeDoPicker.startDate.date(), this._minutneMeritveObdobjeDoPicker.startDate.hours(),
            this._minutneMeritveObdobjeDoPicker.startDate.minutes());

        if (this._preveriCasovnoEnoto(datumOd, datumDo) == "") {
            this._loader.hide();
            this._notyf.error("Izbrali ste neveljavno obdobje!");
            return;
        }

        var porabaOdjemalcaRequest: PorabaOdjemalcaRequest = {
            datumOd: datumOd,
            datumDo: datumDo,
            casovnaEnota: "15",
            agregirano: true,
            odjemnaMesta: [odjemnoMesto]
        };
        await this._merilnoMestoApiClient.getMinutneMeritve(porabaOdjemalcaRequest)
            .then((odgovor: any) => {
                if (odgovor.status) {
                    if (odgovor.porabaSumarno.length > 0) {
                        this._porabaSumarno = odgovor.porabaSumarno;  
                    }
                    this._loader.hide();
                }
                else {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    this._loader.hide();
                    return;
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                this._loader.hide();
            });
    }

    private _onDropDownMerilnoMestoChange(ev: Event) {
        ev.preventDefault();
        this._dropdownMerilnaMestaElement = $("#dropdownMerilnaMesta");
        var dropdownMerilnoMestoValues = (this._dropdownMerilnaMestaElement[0] as any).value.split("_");
        var selectedJeElektrika = dropdownMerilnoMestoValues[0]
        var selectedStevilkaKontoPogodbe = dropdownMerilnoMestoValues[1]
        var selectedMerilnoMesto = dropdownMerilnoMestoValues[2]       
        if (selectedJeElektrika == 'true') {
            var selectedMerilnaTocka = dropdownMerilnoMestoValues[3];
            location.href = "#/merilno-mesto/minutne-meritve?" + selectedMerilnaTocka + "_" + selectedJeElektrika + "_" + selectedMerilnoMesto + "_" + selectedStevilkaKontoPogodbe;
        }
        else {
            location.href = "#/merilno-mesto/minutne-meritve?" + selectedJeElektrika + "_" + selectedMerilnoMesto + "_" + selectedStevilkaKontoPogodbe;
        }        
    }

    private async _onBtnPregledMerilneMeritveClick(ev: Event) {
        ev.preventDefault();
        if (!this._izbranoMerilnoMesto) {
            return;
        }
        this._loader.show();

        var odjemnoMesto: PorabaOdjemalcaOdjemnoMesto = {
            merilnoMesto: this._izbranoMerilnoMesto.merilnoMesto,
            merilnaTocka: this._izbranoMerilnoMesto.merilnaTocka,
            stevilkaKontoPogodbe: this._izbranoMerilnoMesto.stevilkaKontoPogodbe
        };

        var datumOd = new Date(
            this._minutneMeritveObdobjeOdPicker.startDate.year(), this._minutneMeritveObdobjeOdPicker.startDate.month(),
            this._minutneMeritveObdobjeOdPicker.startDate.date(), this._minutneMeritveObdobjeOdPicker.startDate.hours(),
            this._minutneMeritveObdobjeOdPicker.startDate.minutes());
        var datumDo = new Date(
            this._minutneMeritveObdobjeDoPicker.startDate.year(), this._minutneMeritveObdobjeDoPicker.startDate.month(),
            this._minutneMeritveObdobjeDoPicker.startDate.date(), this._minutneMeritveObdobjeDoPicker.startDate.hours(),
            this._minutneMeritveObdobjeDoPicker.startDate.minutes());

        this._casovnaEnota = this._preveriCasovnoEnoto(datumOd, datumDo);
        if (this._casovnaEnota == "") {
            this._loader.hide();
            this._notyf.error("Izbrali ste neveljavno obdobje!");
            return;
        }

        var porabaOdjemalcaRequest: PorabaOdjemalcaRequest = {
            datumOd: datumOd,
            datumDo: datumDo,
            casovnaEnota: this._casovnaEnota,
            agregirano: true,
            odjemnaMesta: [odjemnoMesto]
        };
        await this._merilnoMestoApiClient.getMinutneMeritve(porabaOdjemalcaRequest)
            .then((odgovor: any) => {
                if (odgovor.status) {
                    if (odgovor.porabaSumarno.length > 0) {
                        this._porabaSumarno = odgovor.porabaSumarno;

                        // renderiramo podatke o skupni porabi
                        this._loadSkupnaPorabaInfo(odgovor.porabaSumarno);

                        // renderiramo grafa
                        this._loadMinutmeMeritveGraf(odgovor.porabaSumarno);

                        $(".minutne-meritve-ne-obstajajo").hide();
                        $(".graf-meritve").show();
                        $(".minutne-meritve-izvoz").show();
                    }
                    else {
                        $(".minutne-meritve-ne-obstajajo").show();
                        $(".graf-meritve").hide();
                        $(".minutne-meritve-izvoz").hide();
                    }
                    this._loader.hide();
                }
                else {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    this._loader.hide();
                    return;
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                this._loader.hide();
            });
    }

    private _preveriCasovnoEnoto(datumOd: Date, datumDo: Date) {
        var diffTime = Math.abs(datumOd.getTime() - datumDo.getTime());
        var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (datumOd.getTime() >= datumDo.getTime()) {
            return "";
        }
        if (diffDays <= 0) {
            return "";
        }
        else if (diffDays <= 2) {
            return "15";
        }
        else if (diffDays <= 4) {
            return "60";
        }
        else if (diffDays <= 62) {
            return "DAN";
        }
        else if (diffDays <= 365 * 2) {
            return "MESEC";
        }
        else {
            return "LETO";
        }
    }

    private _onBtnMerilnaMestaOthersClick(ev: Event) {
        ev.preventDefault();
        $(".merilna-mesta-not-all")[0].style.display = "none";
        $(".merilna-mesta-all")[0].style.display = "block";
    }

    private _onBtnPrejsnjiDanClick(ev: Event, obdobjeOdPicker: DateRangePicker, obdobjeDoPicker: DateRangePicker) {
        ev.preventDefault();
        var now = new Date();
        var prejsnjiDan = new Date();
        prejsnjiDan.setDate(prejsnjiDan.getDate() - 2);
        obdobjeOdPicker.setStartDate(new Date(prejsnjiDan.getFullYear(), prejsnjiDan.getMonth(), prejsnjiDan.getDate(), 0, 0, 0));
        obdobjeDoPicker.setStartDate(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0));
    }

    private _onBtnZadnjiTedenClick(ev: Event, obdobjeOdPicker: DateRangePicker, obdobjeDoPicker: DateRangePicker) {
        ev.preventDefault();
        var now = new Date();
        var prejsnjiTedenStart = new Date();
        prejsnjiTedenStart.setDate(prejsnjiTedenStart.getDate() - 7);
        obdobjeOdPicker.setStartDate(new Date(prejsnjiTedenStart.getFullYear(), prejsnjiTedenStart.getMonth(), prejsnjiTedenStart.getDate()));
        obdobjeDoPicker.setStartDate(new Date(now.getFullYear(), now.getMonth(), now.getDate()));
    }

    private _onBtnZadnjiMesecClick(ev: Event, obdobjeOdPicker: DateRangePicker, obdobjeDoPicker: DateRangePicker) {
        ev.preventDefault();
        var now = new Date();
        obdobjeOdPicker.setStartDate(new Date(now.getFullYear(), now.getMonth() - 1, 1));
        obdobjeDoPicker.setStartDate(new Date(now.getFullYear(), now.getMonth(), 1));
    }

    private _loadSkupnaPorabaInfo(porabaSumarno: any) {
        var etSum = 0;
        var mtSum = 0;
        var vtSum = 0;
        var sum = 0;
        for (let i = 0; i < porabaSumarno.length; i++) {
            etSum += porabaSumarno[i].kolicina.et;
            mtSum += porabaSumarno[i].kolicina.mt;
            vtSum += porabaSumarno[i].kolicina.vt;
            sum += porabaSumarno[i].kolicina.sum;
        }
        var enota = porabaSumarno[0].kolicina.enotaMere;
        $(".vt-data")[0].innerHTML = vtSum + " " + enota;
        $(".mt-data")[0].innerHTML = mtSum + " " + enota;
        $(".et-data")[0].innerHTML = etSum + " " + enota;
        $(".sum-data")[0].innerHTML = sum + " " + enota;
        // Če samo ET ali samo VT in MT
        if (etSum == 0) {
            $(".et-info")[0].style.display = "none";
        }
        else {
            $(".vt-info")[0].style.display = "none";
            $(".mt-info")[0].style.display = "none";
        }
        $(".pregled-minutne-meritve")[0].style.display = "block";
    }

    private _getBesediloGraf() {
        if (this._casovnaEnota == "15") return "15-minutne meritve porabe za obdobje";
        else if (this._casovnaEnota == "60") return "Urne meritve porabe za obdobje";
        else if (this._casovnaEnota == "DAN") return "Dnevne meritve porabe za obdobje";
        else if (this._casovnaEnota == "MESEC") return "Mesečne meritve porabe za obdobje";
        else if (this._casovnaEnota == "LETO") return "Letne meritve porabe za obdobje";
        return "";
    }

    private _loadMinutmeMeritveGraf(porabaSumarno: any) {
        this._formElement = $(("#prikaz-minutne-meritve"));
        var formData = new FormData(this._formElement[0] as HTMLFormElement);
        var obdobjeOdString = formData.get("minutneMeritveObdobjeOd") as string;
        var obdobjeDoString = formData.get("minutneMeritveObdobjeDo") as string;
        var casovnaEnota = this._getBesediloGraf();
        $(".minutne-meritve-obdobje")[0].innerHTML = casovnaEnota + " " + obdobjeOdString + " - " + obdobjeDoString;
        const html = Mustache.render(minutneMeritveGrafView, {
            translations: this._translationService.currentTranslations
        });

        var cene = new Array<number>();
        var datume = new Array<string>();
        var enotaMere = "";

        for (let i = 0; i < porabaSumarno.length; i++) {
            // Enota mere
            enotaMere = porabaSumarno[i].kolicina.enotaMere;

            // Cena
            cene.push(porabaSumarno[i].kolicina.sum);

            // Datum
            var datum = new Date(porabaSumarno[i].datum);
            var dan = datum.getDate().toString();
            if (dan.length == 1) {
                dan = "0" + dan;
            }
            var mesec = (datum.getMonth() + 1).toString();
            if (mesec.length == 1) {
                mesec = "0" + mesec;
            }
            var leto = datum.getFullYear().toString();

            // Čas
            var cas = new Date(porabaSumarno[i].cas);
            var ura = cas.getHours().toString();
            if (ura.length == 1) {
                ura = "0" + ura;
            }
            var minuta = cas.getMinutes().toString();
            if (minuta.length == 1) {
                minuta = "0" + minuta;
            }

            var datumInCas = dan + "." + mesec + "." + leto + ", " + ura + ":" + minuta;
            datume.push(datumInCas);
        }

        $("#minutne-meritve-graf").html(html);
        var chartOptions = {
            chart: {
                width: "100%",
                height: 380,
                type: 'bar',
                toolbar: {
                    tools: {
                        download: false
                    }
                }
            },
            series: [
                {
                    data: cene
                }
            ],

            xaxis: {
                categories: datume
            },

            title: {
                alight: 'left',
                // text: 
                floating: true,
                offsetX: -10,
                offsetY: 10,
                style: {
                    fontFamily: 'Red Hat Display',
                    fontWeight: '400',
                    fontSize: '15px',
                    colour: 'rgb(33, 37, 41)',

                }
            },
            yaxis: {
                labels: {
                    show: true,
                    formatter: ((val: any) => { return val + " " + enotaMere })
                },
                tooltip: {
                    enabled: true
                }
            },
            legend: {
                show: true,
                position: 'top',
                horizontalAlign: 'right',
                offsetY: 10,
                labels: {
                    useSeriesColors: false
                }
            },

            fill: {
                colors: ['#008FFB', '#00E396', '#FFB019']
            },

            grid: {
                show: true,
                borderColor: "#e8e4e4",
                strokeDashArray: 0,
                position: 'back',
                yaxis: {
                    lines: {
                        show: true
                    }
                },
                xaxis: {
                    lines: {
                        show: true
                    }
                },
            },

            dataLabels: {
                enabled: false
            },
            tooltip: {
                x: {
                    show: true,
                    format: 'dd MMM HH:mm'
                }
            }
        };
        //.apexcharts-legend-series.custom-marker-down .apexcharts-legend-marker
        var chart = new ApexCharts($("#minutne-meritve-graf").find("#minutne-meritve-graf-div")[0], chartOptions);
        chart.render();
        $(".graf-meritve").show();
    }
}
