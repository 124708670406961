import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { KategorijaPogostaVprasanjaResponse } from "../models/kategorija-pogosta-vprasanja-response";
import { PogostoVprasanjeResponse } from "../models/pogosto-vprasanje-response";
// import { ListResponse } from "../models/list-response";

@injectable()
export class PogostaVprasanjaApiClient extends ApiClientBase {
    public name = "PogostaVprasanjaApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator") authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async getKategorije(filter?: string): Promise<Array<KategorijaPogostaVprasanjaResponse>> {
        const data = {
            "filter": filter
        };
        return await this._callApi('/pogostavprasanja/kategorije', 'GET', data, false) as Array<KategorijaPogostaVprasanjaResponse>;
    }

    public async getPogostaVprasanja(kategorijaId: string, page: number, take: number, filter?: string): Promise<Array<PogostoVprasanjeResponse>> {
        const data = {
            "kategorijaId": kategorijaId,
            "page": page,
            "take": take,
            "filter": filter
        };
        return await this._callApi('/pogostavprasanja/pogosta-vprasanja', 'GET', data, false) as Array<PogostoVprasanjeResponse>;
    }
} 