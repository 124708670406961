import { inject, injectable, interfaces } from "inversify";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { MainLayout } from "../../views/main-layout";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import { TranslationService } from "../translation-service";
import { FullscreenLoader } from "../../views/fullscreen-loader";
import { CurrentUserAccessor } from "../utilities/current-user-accessor";
import { KlicniCenterSeznamView } from "../../views/klicni-center-seznam-view";
import * as Mustache from "mustache";
import template from "../pages/klicni-center-seznam-page.html";
import { KontaktiInPomocApiClient } from "../clients/kontakti-in-pomoc-api-client";
import { Notyf } from "notyf";

@injectable()
export class KlicniCenterSeznamPage extends PageBase {
    public name = "KlicniCenterSeznamPage";
    private _router: Router;
    private _translationService: TranslationService;
    private _klicniCenterSeznamView: KlicniCenterSeznamView;
    private _klicniCenterSeznamElement: JQuery<HTMLElement>;
    private _loader: FullscreenLoader;
    private _currentUserAccessor: CurrentUserAccessor;
    private _kontaktiInPomocApiClient: KontaktiInPomocApiClient;
    private _notyf: Notyf;

    public constructor(@inject("Provider<MainLayout>") private _mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("Router") router: Router,
        @inject("TranslationService") translationService: TranslationService,
        @inject("CurrentUserAccessor") currentUserAccessor: CurrentUserAccessor,
        @inject("FullscreenLoader") loader: FullscreenLoader,
        @inject("KontaktiInPomocApiClient") kontaktiInPomocApiClient: KontaktiInPomocApiClient,
        @inject("Notyf") notyf: Notyf) {
        super();

        this._router = router;
        this._translationService = translationService;
        this._loader = loader;
        this._currentUserAccessor = currentUserAccessor;
        this._kontaktiInPomocApiClient = kontaktiInPomocApiClient;
        this._notyf = notyf;

        const html = Mustache.render(template, {});
        $('#main').html(html);

        this._klicniCenterSeznamElement = $('#main').find("#klicni-center-seznam");
        this._klicniCenterSeznamView = new KlicniCenterSeznamView(this._klicniCenterSeznamElement,
            this._translationService, this._kontaktiInPomocApiClient, this._notyf);
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        if (!url.pathname.startsWith("/klicni-center-seznam")) {
            return true;
        }
        var mainLayout = await this._mainLayoutProvider() as MainLayout;
        try {
            var user = await this._currentUserAccessor.getUser();
            var klicniCenterRole = user.roles.find(a => a.role1 == "ROLE_KLICNI_CENTER");
            if (klicniCenterRole) {
                mainLayout.hideFooter();
                this._loader.show();
                await this._klicniCenterSeznamView.load();
                window.scrollTo(0, 0);
                $(".title-strani")[0].innerHTML = this._translationService.currentTranslations["CallCenter"];
            }
        } catch (e) {
            mainLayout.errorView.report(e);
        }
        finally {
            this._loader.hide();
            mainLayout.showFooter();
        }
        return false;
    }
}