import { injectable } from "inversify";
import * as moment from "moment";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { MojPlusApiClient } from "../ts/clients/moj-plus-api-client";
import { MojPlusUgodnostiRequest } from "../ts/models/moj-plus-ugodnosti-request";
import { MojPlusUgodnostiResponse } from "../ts/models/moj-plus-ugodnosti-response";
import { OdjavaMojPlus } from "../ts/models/odjava-moj-plus";
import { PodatkiMojPlusResponse } from "../ts/models/podatki-moj-plus-response";
import { UnovcitevMojPlusUgodnostiRequest } from "../ts/models/unovcitev-moj-plus-ugodnosti-request";
import { TranslationService } from "../ts/translation-service";
import template from "./moj-plus-pregled-view.html";

@injectable()
export class MojPlusPregledView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _mojPlusApiClient: MojPlusApiClient;
    private _izbranaKontoPogodba: string;
    private _ugodnosti: Array<MojPlusUgodnostiResponse>;
    private _podatkiMojPlus: PodatkiMojPlusResponse | null;
    private _dropdownStevilkaRacuna: JQuery<HTMLElement> | null;
    private _stevilkeKontoPogodb: Array<string>;
    private _izbranaUgodnostIndex: number | null;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        merilnoMestoApiClient: MerilnoMestoApiClient,
        mojPlusApiClient: MojPlusApiClient, 
        private _notyf: Notyf) {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._mojPlusApiClient = mojPlusApiClient;
        this._dropdownStevilkaRacuna = null;
        this._ugodnosti = new Array<MojPlusUgodnostiResponse>();
        this._izbranaUgodnostIndex = null;
        this._podatkiMojPlus = null;
        this._izbranaKontoPogodba = "";
        this._stevilkeKontoPogodb = new Array<string>();
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;
            await this._merilnoMestoApiClient.getStevilkeKontoPogodb()
                .then((odgovor) => {
                    this._stevilkeKontoPogodb = odgovor.filter((value: any, index: any, array: any) => array.indexOf(value) === index);
                    if (this._stevilkeKontoPogodb.length > 0) {
                        // poiscemo ali obstaja selektira �tevilka racuna (v querystringu)
                        var url = window.location.href.split("/");
                        var izbranaKontoPogodbaId = url[url.length - 1];
                        var kontoPogodba = this._stevilkeKontoPogodb.find(a => a == izbranaKontoPogodbaId);
                        if (kontoPogodba) {
                            this._izbranaKontoPogodba = kontoPogodba;
                        }
                        else {
                            // ce v querystringu ni dolocena konto pogodba, selektiramo prvo pogodbo iz liste
                            this._izbranaKontoPogodba = this._stevilkeKontoPogodb[0];
                        }
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    return;
                });

            if (this._izbranaKontoPogodba) {
                await this._mojPlusApiClient.getMojPlusPodatki(this._izbranaKontoPogodba)
                    .then((odgovor) => {
                        if (odgovor.status) {
                            this._podatkiMojPlus = odgovor;
                        }
                    })
                    .catch(() => {
                        this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    });

                if (this._podatkiMojPlus) {
                    var mojPlusUgodnostiRequest: MojPlusUgodnostiRequest = {
                        stevilkaKontoPogodbe: this._izbranaKontoPogodba,
                        kljucProgramZvestobe: "EPLUS"
                    };
                    await this._mojPlusApiClient.getUgodnosti(mojPlusUgodnostiRequest)
                        .then((odgovor: any) => {
                            if (odgovor.status) {
                                if (odgovor.razpolozljiveUgodnosti) {
                                    this._ugodnosti = odgovor.razpolozljiveUgodnosti;
                                }
                            }
                            else {
                                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                            }
                        })
                        .catch(() => {
                            this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                        });
                }               
            }

            await this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;
            if (this._izbranaKontoPogodba) {
                this._initForm();
            } 
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(translations: any): void {
        var localeFormat = moment().creationData().locale.longDateFormat("L");
        var formators = {
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            }//tukaj se lahko dodajajo formatorji
        };

        const viewModel = {
            formators: formators,
            stevilkeKontoPogodb: this._stevilkeKontoPogodb,
            podatkiMojPlus: this._podatkiMojPlus,
            mojPlusRacunObstaja: this._podatkiMojPlus != null ? true : false,
            ugodnosti: this._ugodnosti,
            obstajaUgodnost: this._ugodnosti.length > 0,
            izbranaKontoPogodba: this._izbranaKontoPogodba,
            translations: translations,
            obstajaKontoPogodba: this._izbranaKontoPogodba == "" ? false : true
        } as any;
        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _initForm() {

        //if (this._podatkiMojPlus) {
        //    var maxTocke = this._podatkiMojPlus.skupajRazpolozljiveTocke + 1000;
        //    var tocke = this._podatkiMojPlus.skupajRazpolozljiveTocke / maxTocke * 100;
        //    $(".tocke-progress-bar")[0].style.width = tocke.toString() + "%";
        //    $(".maxTocke")[0].innerHTML = maxTocke.toString();
        //}

        this._dropdownStevilkaRacuna = $("#dropdownStevilkaRacuna");
        if (this._izbranaKontoPogodba) {
            this._dropdownStevilkaRacuna.val(this._izbranaKontoPogodba);
        }
        this._dropdownStevilkaRacuna.on("change", (ev: Event) => this._onDropDownStevilkaRacunaChange(ev));

        if (this._ugodnosti && this._ugodnosti.length > 0) {
            var buttonsKoristiteUgodnost = $(".btnKoristiteUgodnost");
            for (let i = 0; i < buttonsKoristiteUgodnost.length; i++) {
                buttonsKoristiteUgodnost[i].addEventListener("click", () => {
                    if (this._ugodnosti && this._podatkiMojPlus) {
                        this._izbranaUgodnostIndex = i;
                        $("#koristi-ugodnost-modal").show();
                    }
                });
            }
        }

        var btnKoristiteUgodnostModal = $("#btn-koristi-ugodnost-modal");
        btnKoristiteUgodnostModal.on("click", (ev: Event) => this._onBtnKoristiteUgodnostClick(ev));
        var btnZapriteModal = $("#btn-zaprite-modal");
        btnZapriteModal.on("click", () => {
            $("#koristi-ugodnost-modal").hide();
        });
        var btnCloseModal = $("#btn-close-modal");
        btnCloseModal.on("click", () => {
            $("#koristi-ugodnost-modal").hide();
        });

        var btnOdjavaMojPlus = $("#btn-odjava-moj-plus");
        btnOdjavaMojPlus.on("click", () => {
            $("#odjava-iz-programa-modal").show();
        });
        var btnOdjavaMojPlus = $("#btn-odjava-mojplus");
        btnOdjavaMojPlus.on("click", (ev: Event) => this._onBtnOdjavaMojPlusClick(ev));
        var btnZapriModalOdjava = $("#btn-zaprite-odjava-modal");
        btnZapriModalOdjava.on("click", () => {
            $("#odjava-iz-programa-modal").hide();
        });
        var btnCloseModalOdjava = $("#btn-close-odjava-modal");
        btnCloseModalOdjava.on("click", () => {
            $("#odjava-iz-programa-modal").hide();
        });
    }

    private async _onBtnOdjavaMojPlusClick(ev: Event) {
        ev.preventDefault;
        var odjavaMojPlus: OdjavaMojPlus = {
            stevilkaKontoPogodbe: this._izbranaKontoPogodba,
            kljucProgramZvestobe: "EPLUS"
        };
        await this._mojPlusApiClient.odjavaIzLojalnostnegaPrograma(odjavaMojPlus, -1)
            .then((odgovor) => {
                $("#odjava-iz-programa-modal").hide();
                if (odgovor.status) {
                    this._notyf.success(this._translationService.currentTranslations["SaveSuccessful"]);
                    window.location.reload();
                }
                else {
                    this._notyf.error(odgovor.sporocilo);
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
    }

    private _onBtnKoristiteUgodnostClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        if (this._izbranaUgodnostIndex != null && this._podatkiMojPlus) {
            var izbranaUgodnost = this._ugodnosti[this._izbranaUgodnostIndex];
            if (this._podatkiMojPlus.skupajRazpolozljiveTocke < izbranaUgodnost.potrebnoSteviloTock) {
                // uporabnik nima dovolj tocke
                var manjkajoceTocke = Math.abs(this._podatkiMojPlus.skupajRazpolozljiveTocke - izbranaUgodnost.potrebnoSteviloTock);
                var sporocilo = this._translationService.currentTranslations["ForUsingTheBenefitsYouAreMissing"] + " " + manjkajoceTocke.toString();
                this._notyf.error(sporocilo);
                return;
            }

            var data: UnovcitevMojPlusUgodnostiRequest = {
                kljucNagrade: izbranaUgodnost.kljucNagrade,
                kljucProgramZvestobe: "EPLUS",
                stevilkaKontoPogodbe: this._izbranaKontoPogodba,
                lokacijaNagradeK: izbranaUgodnost.obstajaLokacija ? "002" : "" // vedno po�ljemo to kodo
            };

            this._mojPlusApiClient.unovcitevUgodnosti(data)
                .then((odgovor: any) => {
                    if (odgovor.status) {
                        this._notyf.success(this._translationService.currentTranslations["TheBenefitHasBeenSuccessfullyActivated"]);
                        $("#koristi-ugodnost-modal").hide();
                    }
                    else {
                        this._notyf.error(odgovor.sporocilo);
                        $("#koristi-ugodnost-modal").hide();
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    $("#koristi-ugodnost-modal").hide();
                });
        }
    }

    private _onDropDownStevilkaRacunaChange(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        this._dropdownStevilkaRacuna = $("#dropdownStevilkaRacuna");
        var stevilkaKontoPogodbe = (this._dropdownStevilkaRacuna[0] as any).value;
        window.location.href = "#/moj-plus/pregled/" + stevilkaKontoPogodbe;
    }
}