import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { ListResponse } from "../models/list-response";
import { Novica } from "../models/novica";
import { NovicaPodrobnosti } from "../models/novica-podrobnosti";

@injectable()
export class NoviceApiClient extends ApiClientBase {
    public name = "NoviceApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator") authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async getNovice(): Promise<ListResponse<Novica>> {
        return await this._callApi('/novice/list', 'GET', null, false) as ListResponse<Novica>;
    }

    public async getNovicePodrobnosti(): Promise<NovicaPodrobnosti> {
        return await this._callApi('/novice/podrobnosti', 'GET', null, false) as NovicaPodrobnosti;
    }

} 