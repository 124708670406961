import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { AnketniVprasalnikDodajOdgovorRequest } from "../models/akcije-anketa-dodaj-odgovor";
import { AktivacijaPopustovRequestModel } from "../models/aktivacija-popusta-request-model";
import { MarketinskeAkcijeRequest } from "../models/marketinske-akcije-request";
import { MarketinskeAkcijeResponse } from "../models/marketinske-akcije-response";
import { PregledPopustiRequestModel } from "../models/pregled-popustov-request";
import { UgodnostiUmbracoResponse } from "../models/ugodnosti-umbraco-response";

@injectable()
export class AkcijeApiClient extends ApiClientBase {
    public name = "AkcijeApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator") authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async getMarketinskeAkcije(data: MarketinskeAkcijeRequest): Promise<MarketinskeAkcijeResponse> {
        return await this._callApi('/akcije/get-marketinske-akcije', 'POST', JSON.stringify(data), false) as MarketinskeAkcijeResponse;     
    }

    public async getAkcije(data: PregledPopustiRequestModel): Promise<any> {
        return await this._callApi('/akcije/list', 'POST', JSON.stringify(data), false) as any;
    }

    public async aktivirajPopust(data: AktivacijaPopustovRequestModel): Promise<any> {
        return await this._callApi('/akcije/aktiviraj', 'POST', JSON.stringify(data), false) as any;
    }

    public async addAnketaOdgovor(data: AnketniVprasalnikDodajOdgovorRequest): Promise<any> {
        return await this._callApi('/akcije/anketni-vprasalnik-dodaj-odgovor', 'POST', JSON.stringify(data), false) as any;
    }

    public async getUgodnosti(page?: number, take?: number): Promise<UgodnostiUmbracoResponse> {
        if (page === undefined || page === undefined) {
            return await this._callApi('/akcije/get-ugodnosti', 'GET', null, false) as UgodnostiUmbracoResponse;
        }
        return await this._callApi('/akcije/get-ugodnosti/' + page + '/' + take, 'GET', null, false) as UgodnostiUmbracoResponse;
    }
} 