import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";

@injectable()
export class IntranetApiClient extends ApiClientBase {
    public name = "IntranetApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator") authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async getNoviceUrejanjeUrl(): Promise<any> {
        return await this._callApi("/intranet/umbracoUrejanjeNovicUrl", "GET", undefined, false) as any;
    }

    public async getVprasanjaUrejanjeUrl(): Promise<any> {
        return await this._callApi("/intranet/umbracoUrejanjeVprasanjUrl", "GET", undefined, false) as any;
    }

    public async getDodajanjeKategorijoUrl(): Promise<any> {
        return await this._callApi("/intranet/umbracoDodajanjeKategorijoUrl", "GET", undefined, false) as any;
    }

    public async getBrisanjeKategorijoUrl(): Promise<any> {
        return await this._callApi("/intranet/umbracoBrisanjeKategorijoUrl", "GET", undefined, false) as any;
    }
} 