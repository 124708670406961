import { injectable } from "inversify";
import * as moment from "moment";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { MerilnoMesto } from "../ts/models/merilno-mesto";
import { UporabnikMerilnoMestoRequest } from "../ts/models/uporabnik-merilno-mesto-request";
import { UporabnikMerilnoMestoResponse } from "../ts/models/uporabnik-merilno-mesto-response";
import { TranslationService } from "../ts/translation-service";
import { FullscreenLoader } from "./fullscreen-loader";
import { MerilnoMestoOdstraniVarnostniKorakView } from "./merilno-mesto-odstrani-varnostni-korak-view";
import template from "./merilno-mesto-odstrani-view.html";

@injectable()
export class MerilnoMestoOdstraniView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _merilnaMesta: Array<MerilnoMesto> | null;
    private _currentPage: number;
    private _limit: number;
    private _maxPages: number;
    private _izbranaMerilnaMesta: Array<MerilnoMesto>;
    private _porabaMediana: number;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        merilnoMestoApiClient: MerilnoMestoApiClient,
        private _notyf: Notyf,
        private _loader: FullscreenLoader)
    {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._merilnaMesta = null;
        this._currentPage = 1;
        this._limit = 10;
        this._maxPages = -1;
        this._izbranaMerilnaMesta = new Array<MerilnoMesto>();
        this._porabaMediana = 0;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations: any = this._translationService.currentTranslations;
            await this._getMerilnaMesta("", this._limit, this._currentPage);
            this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;
            this._initForm();
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(translations: any): void {
        var localeFormat = moment().creationData().locale.longDateFormat("L");
        var formators = {
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            }//tukaj se lahko dodajajo formatorji
        };

        const viewModel = {
            formators: formators,
            currentPage: this._currentPage,
            maxPages: this._maxPages,
            merilnaMesta: this._merilnaMesta ? this._merilnaMesta : null,
            obstajaMerilnoMesto: this._merilnaMesta && this._merilnaMesta.length > 0 ? true : false,
            translations: translations
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
        window.scrollTo(0, 0);
    }

    private _initForm(): void {
        // v vsakem primeru dodamo event listener iskalniku
        var btnPoiscite = $("#poiscite");
        btnPoiscite.on("click", (ev: Event) => this._onIsciMerilnoMestoClick(ev));

        if (this._merilnaMesta == null) {
            return;
        }

        var odstraniButtons = $(".btn-odstrani");
        for (let i = 0; i < odstraniButtons.length; i++) {
            odstraniButtons[i].addEventListener("click", (ev: Event) => this._onBtnOdstraniMerilnoMestoClick(ev));
        }

        // uporabimo za sprememba izgleda ko je checkbox selektiran
        var allCheckBoxes = $(".icon-bar-checkbox :checkbox");
        allCheckBoxes.on("change", function () {
            var clickedCheckBox = $(this).closest(".tile-icon-bar")[0];
            clickedCheckBox = $(clickedCheckBox).find(".form-check-input")[0];
            $(this).closest(".tile-icon-bar").toggleClass("checked", (clickedCheckBox as HTMLInputElement).checked);
        });

        // vrnili smo se nazaj na stari page, selektiramo merilna mesta, ki so bila �e izbrana
        for (let i = 0; i < allCheckBoxes.length; i++) {
            var merilnoMestoId = $(".icon-bar-checkbox :checkbox")[i].dataset["merilnomesto"];
            var merilnaTockaId = $(".icon-bar-checkbox :checkbox")[i].dataset["merilnatocka"];
            var selektiranoMerilnoMesto: MerilnoMesto | undefined = undefined;
            if (merilnaTockaId == "0") {
                selektiranoMerilnoMesto = this._izbranaMerilnaMesta.find(a => a.merilnoMesto == merilnoMestoId && a.jeElektrika == false);
            }
            else {
                var selektiranoMerilnoMesto = this._izbranaMerilnaMesta.find(a => a.merilnoMesto == merilnoMestoId && a.merilnaTocka == merilnaTockaId);
            }
            if (selektiranoMerilnoMesto) {
                (allCheckBoxes[i] as HTMLInputElement).checked = true;
                var tileIconId = "";
                if (selektiranoMerilnoMesto.jeElektrika) {
                    tileIconId = ".tile-icon-bar-" + merilnoMestoId + "-" + merilnaTockaId;
                }
                else {
                    tileIconId = ".tile-icon-bar-" + selektiranoMerilnoMesto.stevilkaKontoPogodbe;
                }
                $(tileIconId).toggleClass("checked", true);
            }
        }

        // uporabimo za dodajanje / odstranjevanje merilnega mesta iz seznama izbranih
        var checkBoxes = $(".form-check-input");
        for (let i = 0; i < checkBoxes.length; i++) {
            checkBoxes[i].addEventListener("click", () => {
                var izbranoMerilnoMesto: MerilnoMesto | undefined = undefined;
                var merilnoMestoId = checkBoxes[i].dataset['merilnomesto'];
                var merilnaTockaId = checkBoxes[i].dataset['merilnatocka'];
                if (merilnaTockaId == "0") {
                    izbranoMerilnoMesto = this._merilnaMesta?.find(a => a.merilnoMesto == merilnoMestoId && a.jeElektrika == false);
                }
                else {
                    izbranoMerilnoMesto = this._merilnaMesta?.find(a => a.merilnoMesto == merilnoMestoId && a.merilnaTocka == merilnaTockaId);
                }
                // dodano merilno mesto v seznam za odstranjevanje
                if ((checkBoxes[i] as HTMLInputElement).checked && izbranoMerilnoMesto) {
                    this._izbranaMerilnaMesta.push(izbranoMerilnoMesto);
                }
                // odstranimo merilno mesto iz seznama za odstranjevanje
                else {
                    this._izbranaMerilnaMesta = this._izbranaMerilnaMesta.filter(a => a.merilnoMesto != izbranoMerilnoMesto?.merilnoMesto);
                }
            });
        }

        // klik na izberi vsa merilna mesta
        var izberiVsaMerilnaMestaBtn = $(".select-all-checkboxes");
        izberiVsaMerilnaMestaBtn.on("click", async () => {
            var allCheckBoxes = $(".form-check-input");
            if (izberiVsaMerilnaMestaBtn.data("id") == "select-all-yes") {
                for (let i = 0; i < allCheckBoxes.length; i++) {
                    (allCheckBoxes[i] as HTMLInputElement).checked = true;
                }
                $(".tile-icon-bar").toggleClass("checked", true);
                izberiVsaMerilnaMestaBtn.data("id", "select-all-no");
                // v seznam dodamo vsa merilna mesta
                var merilnoMestoSearch = ($(".merilno-mesto-search-field")[0] as HTMLInputElement).value
                await this._getMerilnaMesta(merilnoMestoSearch, 0, 0)
                if (this._merilnaMesta) {
                    this._izbranaMerilnaMesta = this._merilnaMesta;
                }
            }
            else {
                for (let i = 0; i < allCheckBoxes.length; i++) {
                    (allCheckBoxes[i] as HTMLInputElement).checked = false;
                }
                $(".tile-icon-bar").toggleClass("checked", false);
                izberiVsaMerilnaMestaBtn.data("id", "select-all-yes");
                // iz seznama odstranimo vsa merilna mesta
                this._izbranaMerilnaMesta = new Array<MerilnoMesto>();
            }
        });

        if (this._merilnaMesta && this._merilnaMesta.length > 0) {
            for (let i = 0; i < this._merilnaMesta.length; i++) {
                var porabaProcent = this._merilnaMesta[i].porabaEnergije / this._porabaMediana * 100;
                if (porabaProcent > 100) {
                    porabaProcent = 100;
                }
                var tekocaPoraba = $(".progress-bar-tekoca-poraba");
                if (tekocaPoraba.length != 0) {
                    tekocaPoraba[i].style.width = porabaProcent.toString() + "%";
                    $(".progress-number-tekoca-poraba")[i].style.right = "calc(50% - " + porabaProcent.toString() + "%)";
                    $(".progress-number-tekoca-poraba")[i].innerHTML = this._merilnaMesta[i].porabaEnergije + " " + this._merilnaMesta[i].mernaEnota;
                }
            }
        }

        var mesecnaPorabaButtons = $(".mesecna-poraba");
        for (let i = 0; i < mesecnaPorabaButtons.length; i++) {
            mesecnaPorabaButtons[i].addEventListener("click", (ev: Event) => this._onMesecnaPorabaClick(ev, i));
        }

        var btnPreviousPage = $(".btnPreviousPage");
        btnPreviousPage[0].addEventListener("click", async () => {
            if (this._currentPage - 1 == 0) return;
            this._loader.show();
            this._currentPage -= 1;
            var merilnoMestoSearch = ($(".merilno-mesto-search-field")[0] as HTMLInputElement).value;
            await this._getMerilnaMesta(merilnoMestoSearch, this._limit, this._currentPage);
            this._renderData(this._translationService.currentTranslations);
            this._initForm();
            ($(".merilno-mesto-search-field")[0] as HTMLInputElement).value = merilnoMestoSearch;
            this._loader.hide();
        });

        var btnNextPage = $(".btnNextPage");
        btnNextPage[0].addEventListener("click", async () => {
            if (this._currentPage + 1 > this._maxPages) return;
            this._loader.show();
            this._currentPage += 1;
            var merilnoMestoSearch = ($(".merilno-mesto-search-field")[0] as HTMLInputElement).value;
            await this._getMerilnaMesta(merilnoMestoSearch, this._limit, this._currentPage);
            this._renderData(this._translationService.currentTranslations);
            this._initForm();
            ($(".merilno-mesto-search-field")[0] as HTMLInputElement).value = merilnoMestoSearch;
            this._loader.hide();
        });
    }

    private async _onIsciMerilnoMestoClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        this._loader.show();
        var merilnoMestoSearch = ($(".merilno-mesto-search-field")[0] as HTMLInputElement).value;
        this._currentPage = 1;
        await this._getMerilnaMesta(merilnoMestoSearch, this._limit, this._currentPage);
        this._renderData(this._translationService.currentTranslations);
        this._initForm();
        ($(".merilno-mesto-search-field")[0] as HTMLInputElement).value = merilnoMestoSearch;
        if (this._merilnaMesta == null) {
            $(".ni-merilnih-mest")[0].innerHTML = this._translationService.currentTranslations["MeasuringPointDoesNotExist"];
        }
        this._loader.hide();
    }

    private async _getMerilnaMesta(merilnoMestoSearch: string, limit: number, stevilkaStrani: number) {
        var uporabnikMerilnoMestoRequest: UporabnikMerilnoMestoRequest = {
            limit: limit,
            stevilkaStrani: stevilkaStrani,
            saldo: true,
            skupnaPorabaEnergije: true,
            merilnoMesto: merilnoMestoSearch,
            merilnaTocka: "" // ne iscemo po merilno tocko
        };
        await this._merilnoMestoApiClient.getUporabnikMerilnaMesta(uporabnikMerilnoMestoRequest)
            .then((odgovor: UporabnikMerilnoMestoResponse) => {
                this._maxPages = Math.ceil(odgovor.steviloVsehMerilnihMestUporabniku / this._limit);
                this._merilnaMesta = odgovor.merilnaMesta;
                this._porabaMediana = odgovor.porabaMediana;
                if (this._merilnaMesta && this._merilnaMesta.length > 0) {
                    this._merilnaMesta.forEach((item) => {
                        if (item.obdobjePorabeOd == null || item.obdobjePorabeDo == null) {
                            item.obstajaOdcitekPorabe = false;
                        }
                        else {
                            item.obstajaOdcitekPorabe = true;
                        }
                    })
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSearchingForMeasuringPoints"]);
            });
    }

    private _onMesecnaPorabaClick(ev: Event, indexMerilnegaMesta: number) {
        console.debug(ev);
        ev.preventDefault();
        if (!this._merilnaMesta) {
            return;
        }
        var merilnoMesto = this._merilnaMesta[indexMerilnegaMesta];
        if (merilnoMesto.jeElektrika) {
            window.location.href = "#/merilno-mesto/poraba/" + "poraba-pregled/" + merilnoMesto.merilnaTocka;
        }
        else {
            window.location.href = "#/merilno-mesto/poraba/" + "poraba-pregled/" + merilnoMesto.merilnoMesto;
        }
    }

    private async _onBtnOdstraniMerilnoMestoClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        this._loader.show();
        if (this._izbranaMerilnaMesta.length > 0) {
            var view: MerilnoMestoOdstraniVarnostniKorakView = new MerilnoMestoOdstraniVarnostniKorakView(this.rootElement,
                this._translationService, this._merilnoMestoApiClient, this._izbranaMerilnaMesta, this._notyf, this._loader);
            view.load();
        }
        else {
            this._notyf.error(this._translationService.currentTranslations["YouMustSelectAtLeastOneMeasuringSamplingPoint"]);
        }
        this._loader.hide();
    }
}