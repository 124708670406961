import "bootstrap";

export class RazrezNavigationView {


    public constructor(
        ) {
    }

    /*
     * Render the view
     */
    /* eslint-disable max-len */
    public load(): void {

        const html =
            `
<div class="dropdown" style="display:none;">
  <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
    Razrez navigacija
  </button>

<ul class="dropdown-menu">
                <li>
                    <a id='btnHome' href="#" class="dropdown-item">Home</a>
                </li>
                <li>
                    <a id='btnKontaktiEboxNovoSporocilo' href="#/sporocilo-novo" class="dropdown-item">Novo sporo?ilo</a>
                </li>
                <li>
                    <a id='btnKontaktiEboxSporociloOdgovor' href="#/sporocilo-odgovor" class="dropdown-item">Sporo?ilo odgovor</a>
                </li>
                <li>
                    <a id='btnAkcijeIzredneAkcije' href="#/akcije-izredne" class="dropdown-item">AkcijeIzredneAkcije</a>
                </li>
                <li>
                    <a id='btnAkcijeRedneAkcije' href="#/akcije-redne" class="dropdown-item">AkcijeRedneAkcije</a>
                </li>
                <li>
                    <a id='btnKontaktiEbox' href="#/kontakti-ebox" class="dropdown-item">KontaktiEbox</a>
                </li>
                <li>
                    <a id='btnKontaktiKontaktInProdajnaMesta' href="#/kontakti-kontakt-in-prodajna-mesta" class="dropdown-item">KontaktiKontaktInProdajnaMesta</a>
                </li>
                <li>
                    <a id='btnKontaktiNarociloObiska' href="#/kontakti-narocilo-obiska" class="dropdown-item">KontaktiNarociloObiska</a>
                </li>
                <li>
                    <a id='btnKontaktiNovice' href="#/kontakti-novice" class="dropdown-item">KontaktiNovice</a>
                </li>
                <li>
                    <a id='btnKontaktiPogostaVprasanja' href="#/kontakti-pogosta-vprasanja" class="dropdown-item">KontaktiPogostaVprasanja</a>
                </li>
                <li>
                    <a id='btnMerilnoMestoInformacije' href="#/merilno-mesto-informacije" class="dropdown-item">MerilnoMestoInformacije</a>
                </li>
                <li>
                    <a id='btnMerilnoMestoPorabaInformativniIzracun' href="#/merilno-mesto-poraba-informativni-izracun" class="dropdown-item">MerilnoMestoPorabaInformativniIzracun</a>
                </li>
                <li>
                    <a id='btnMerilnoMestoPorabaMinutneMeritve' href="#/merilno-mesto-poraba-minutne-meritve" class="dropdown-item">MerilnoMestoPorabaMinutneMeritve</a>
                </li>
                <li>
                    <a id='btnMerilnoMestoPorabaNaprav' href="#/merilno-mesto-poraba-naprav" class="dropdown-item">MerilnoMestoPorabaNaprav</a>
                </li>
                <li>
                    <a id='btnMerilnoMestoPorabaSestevekPorabe' href="#/merilno-mesto-poraba-sestevek-porabe" class="dropdown-item">MerilnoMestoPorabaSestevekPorabe</a>
                </li>
                <li>
                    <a id='btnMerilnoMestoRacuni' href="#/merilno-mesto-racuni" class="dropdown-item">MerilnoMestoRacuni</a>
                </li>
                <li>
                    <a id='btnMerilnoMestoVloge' href="#/merilno-mesto-vloge" class="dropdown-item">MerilnoMestoVloge</a>
                </li>
                <li>
                    <a id='btnMerilnoMestoVnosStanjaStevca' href="#/merilno-mesto-vnos-stanja-stevca" class="dropdown-item">MerilnoMestoVnosStanjaStevca</a>
                </li>
                <li>
                    <a id='btnMojPlusMojeTocke' href="#/moj-plus-moje-tocke" class="dropdown-item">MojPlusMojeTocke</a>
                </li>
                <li>
                    <a id='btnMojPlusPomembneInformacije' href="#/moj-plus-pomembne-informacije" class="dropdown-item">MojPlusPomembneInformacije</a>
                </li>
                <li>
                    <a id='btnMojPlusUgodnosti' href="#/moj-plus-ugodnosti" class="dropdown-item">MojPlusUgodnosti</a>
                </li>
                <li>
                    <a id='btnMojPlusZbiranjeTock' href="#/moj-plus-zbiranje-tock" class="dropdown-item">MojPlusZbiranjeTock</a>
                </li>
                <li>
                    <a id='btnProfilObvescanje' href="#/profil-obvescanj" class="dropdown-item">ProfilObvescanje</a>
                </li>
                <li>
                    <a id='btnProfilPodatkiRacuna' href="#/profil-podatki-racuna" class="dropdown-item">ProfilPodatkiRacuna</a>
                </li>
            </li>
</div>
`;
        $('#razrez-navigation').html(html);
        $('#razrez-navigation').find("[data-bs-toggle]").dropdown({});

        
    }
}
