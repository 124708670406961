import { inject, injectable } from "inversify";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import { IntranetApiClient } from "../clients/intranet-api-client";
import { Notyf } from "notyf";
import { CurrentUserAccessor } from "../utilities/current-user-accessor";

@injectable()
export class AdminDodajanjeNovicePage extends PageBase {
    public name = "AdminDodajanjeNovicePage";
    private _router: Router;
    private _intranetApiClient: IntranetApiClient;
    private _notyf: Notyf;
    private _currentUserAccessor: CurrentUserAccessor;

    public constructor(@inject("Router") router: Router,
        @inject("Notyf") notyf: Notyf,
        @inject("IntranetApiClient") intranetApiClient: IntranetApiClient,
        @inject("CurrentUserAccessor") currentUserAccessor: CurrentUserAccessor) {
        super();

        this._router = router;
        this._notyf = notyf;
        this._intranetApiClient = intranetApiClient;
        this._currentUserAccessor = currentUserAccessor;
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        if (!url.pathname.startsWith("/admin/dodaj-novice")) {
            return true;
        }
        try {
            var user = await this._currentUserAccessor.getUser();
            var adminRole = user.roles.find(a => a.role1 == "ROLE_ADMINISTRATOR");
            if (adminRole) {
                await this._intranetApiClient.getNoviceUrejanjeUrl().then((data) => {
                    window.open(data.url, "_blank");
                });
            }
        } catch (e) {
            this._notyf.error("Pri�lo je do napake pri preusmeritvi!");
            console.debug(e);
        }
        finally {
            history.back();
        };
        return false;
    }
}