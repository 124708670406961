import { inject, injectable, interfaces } from "inversify";
import { MerilnoMestoPorabaInformativniIzracunPorabeView } from "../../views/merilno-mesto-poraba-informativni-izracun-porabe-view";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { MainLayout } from "../../views/main-layout";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import * as Mustache from "mustache";
import template from "../pages/merilno-mesto-poraba-informativni-izracun-page.html";
import { TranslationService } from "../translation-service";
import { MerilnoMestoApiClient } from "../clients/merilno-mesto-api-client";
import { Notyf } from "notyf";
import { FullscreenLoader } from "../../views/fullscreen-loader";

@injectable()
export class MerilnoMestoPorabaInformativniIzracunPage extends PageBase {
    public name = "MerilnoMestoPorabaInformativniIzracunPage";
    private _router: Router;
    private _translationService: TranslationService;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _notyf: Notyf;
    private _loader: FullscreenLoader;
    private _merilnoMestoPorabaInformativniIzracunPorabeView: MerilnoMestoPorabaInformativniIzracunPorabeView;
    private _informativniIzracunPorabeElement: JQuery<HTMLElement>;

    constructor(@inject("Provider<MainLayout>") private _mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("Router") router: Router,
        @inject("TranslationService") translationService: TranslationService,
        @inject("MerilnoMestoApiClient") merilnoMestoApiClient: MerilnoMestoApiClient,
        @inject("Notyf") notyf: Notyf,
        @inject("FullscreenLoader") loader: FullscreenLoader)
    {
        super();
        
        this._router = router;
        this._translationService = translationService;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._notyf = notyf;
        this._loader = loader;

        const html = Mustache.render(template, {});
        $('#main').html(html);

        //this._informativniIzracunPorabeElement = $('#main').find("#informativni-izracun-porabe");
        this._informativniIzracunPorabeElement = $('#main').find("#blur-page-content");
        this._merilnoMestoPorabaInformativniIzracunPorabeView = new MerilnoMestoPorabaInformativniIzracunPorabeView(
            this._informativniIzracunPorabeElement, this._translationService, this._merilnoMestoApiClient, this._notyf, this._loader);
    }

    public async execute(): Promise<boolean> {
        console.log(this._router);
        console.log(this._merilnoMestoPorabaInformativniIzracunPorabeView);
        console.log(this._mainLayoutProvider);
        return true;
        //var url = this._router.getVirtualUrl();
        //if (!url.pathname.startsWith("/merilno-mesto/informativni-izracun")) {
        //    return true;
        //}
        //var mainLayout = await this._mainLayoutProvider() as MainLayout;
        //try {
        //    mainLayout.hideFooter();
        //    this._loader.show();
        //    await this._merilnoMestoPorabaInformativniIzracunPorabeView.load();
        //    window.scrollTo(0, 0);
        //    $(".title-strani")[0].innerHTML = this._translationService.currentTranslations["InformativeConsumptionCalculation"];
        //} catch (e) {
        //    mainLayout.errorView.report(e);
        //}
        //finally {
        //    this._loader.hide();
        //    mainLayout.showFooter();
        //}
        //return false;
    }
}