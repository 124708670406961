import { inject, injectable } from "inversify";
import * as Mustache from "mustache";
import template from "./header-view.html";
import "bootstrap";
import { SiteMapManager } from "../ts/utilities/sitemap-manager";
import { CurrentUserAccessor } from "../ts/utilities/current-user-accessor";
import { TranslationService } from "../ts/translation-service";
import { KontaktiInPomocApiClient } from "../ts/clients/kontakti-in-pomoc-api-client";
import { Notyf } from "notyf";
import { UserApiClient } from "../ts/clients/users-api-client";
import { NotificationHeaderDetails, NotificationHeaderResponse } from "../ts/models/notification-header-response";
import * as moment from "moment";
import notificationElementDesktop from "./widget-notification-header-desktop.html";
import notificationElementMobile from "./widget-notification-header-mobile.html";
import notificationDataElementDesktop from "./widget-notification-data-header-desktop.html";
import notificationDataElementMobile from "./widget-notification-data-header-mobile.html";

@injectable()
export class HeaderView {

    private _header: JQuery<HTMLElement> = null!;    
    private _currentUserAccessor: CurrentUserAccessor;
    private _translationService: TranslationService;
    private _kontaktiInPomocApiClient: KontaktiInPomocApiClient;
    private _userApiClient: UserApiClient;
    private _notyf: Notyf;
    private _sporocilaHeader: any;
    private _notifications: Array<NotificationHeaderDetails>;
    private _newNotifications: Array<NotificationHeaderDetails>;
    private _formators: any;
    private _takeNotifications: number;
    private _skipNotifications: number;
    private _totalNumberOfNotifications: number;
    private _obstajaNeprebranNotification: boolean;
    private _obstajaNeprebranSkritNotification: boolean;

    constructor(@inject("SiteMapManager") private sitemapManager: SiteMapManager,
        @inject("CurrentUserAccessor") currentUserAccessor: CurrentUserAccessor,
        @inject("TranslationService") translationService: TranslationService,
        @inject("KontaktiInPomocApiClient") kontaktiInPomocApiClient: KontaktiInPomocApiClient,
        @inject("UserApiClient") userApiClient: UserApiClient,
        @inject("Notyf") notyf: Notyf,
        header: JQuery<HTMLElement>)
    {
        
        this._header = header;
        this._currentUserAccessor = currentUserAccessor;
        this._translationService = translationService;
        this._kontaktiInPomocApiClient = kontaktiInPomocApiClient;
        this._userApiClient = userApiClient;
        this._notyf = notyf;
        this._sporocilaHeader = null;
        this._notifications = new Array<NotificationHeaderDetails>();
        this._newNotifications = new Array<NotificationHeaderDetails>();
        this._formators = null;
        this._takeNotifications = 3;
        this._skipNotifications = 0;
        this._totalNumberOfNotifications = 0;
        this._obstajaNeprebranNotification = false;
        this._obstajaNeprebranSkritNotification = false;
    }

    public async load(): Promise<void> {
        try {
            await this._renderData();
            await this._renderNotifications();  
            this._setupNavbarToggler();
            this._setMobileButtonsEvents();

            // Vsaki minuti preverimo ali so nove notifikacije
            setInterval(await this._setInterValFunction, 1000 * 60, this); // time in seconds
        }
        catch (e) {

            // Clear previous content on error
            //$('#main').text('Napaka pri nalaganju');
            throw e;
        }
    }

    private async _setInterValFunction(self: any) {
        var openNotifications = false;
        if (window.innerWidth <= 991) {
            var canvasMobile = $("#offCanvasMobile");
            openNotifications = canvasMobile.hasClass("show");
        }
        else {
            var notDropdownDesktop = $("#notifications-dropdown-desktop");
            openNotifications = notDropdownDesktop.hasClass("show");
        }
        if (!openNotifications) {
            self._notifications = new Array<NotificationHeaderDetails>();
            self._newNotifications = new Array<NotificationHeaderDetails>();
            self._totalNumberOfNotifications = 0;
            self._obstajaNeprebranNotification = false;
            self._obstajaNeprebranSkritNotification = false;
            self._skipNotifications = 0;
            self._takeNotifications = 3;
            await self._renderNotifications();
        }
    }

    // we need this for android client since navive code is rendering styles a bit differently
    private _setupNavbarToggler(): void {        
        // Select the element
        var $element = $('.navbar-collapse.collapse');
       
        // Create a new MutationObserver instance
        var observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                if (mutation.attributeName === "class") {
                    var attributeValue = $(mutation.target).prop(mutation.attributeName);
                    if (attributeValue.includes("show")) {
                        let navbar = $('nav.navbar.navbar-expand-lg.fixed-top');
                        navbar.addClass('show');                        
                        // your code here to handle 'show' class addition
                    } else {
                        let navbar = $('nav.navbar.navbar-expand-lg.fixed-top');
                        navbar.removeClass('show');
                        // your code here to handle 'show' class removal
                    }
                }
            });
        });

        // Start observing
        observer.observe($element[0], {
            attributes: true
        });
    }

    private _setMobileButtonsEvents() {
        var btnNovoSporociloMobile = $("#btn-header-novo-sporocilo-mobile");
        btnNovoSporociloMobile.on("click", () => {
            var btnCloseNotMobile = $("#btn-notifications-close-mobile");
            btnCloseNotMobile[0].click();
        });

        var btnPorabaMobile = $("#btn-header-poraba-mobile");
        btnPorabaMobile.on("click", () => {
            var btnCloseNotMobile = $("#btn-notifications-close-mobile");
            btnCloseNotMobile[0].click();
        });

        var btnRacuniMobile = $("#btn-header-racuni-mobile");
        btnRacuniMobile.on("click", () => {
            var btnCloseNotMobile = $("#btn-notifications-close-mobile");
            btnCloseNotMobile[0].click();
        });

        var btnMojPlusMobile = $("#btn-header-moj-plus-mobile");
        btnMojPlusMobile.on("click", () => {
            var btnCloseNotMobile = $("#btn-notifications-close-mobile");
            btnCloseNotMobile[0].click();
        });
    }

    public showHeader() {       
        this._header.show();
    }
    public hideHeader() {     
        this._header.hide();
    }

    private isIOS() {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    private isOpenInBrowser() {
        var userAgent = navigator.userAgent;
        if (this.isIOS()) {
            if (userAgent.includes("Safari/")) {
                return true; // odprto v brskalniku
            }
            return false; // odprto v mobilni aplikaciji
        }
        else {
            if (userAgent.includes("Build/")) {
                return false; //odprto v mobilni aplikaciji
            }
            return true; // odprto v brskalniku
        }
    }

    private getCookie(cname: string) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    private async _renderNotifications() {
        await this._getNotifications();

        const viewModel = {
            "notifications": this._notifications,
            "obstajaNeprebranNotification": this._obstajaNeprebranNotification,
            "prikaziGumbNaloziVec": this._notifications.length != this._totalNumberOfNotifications,
            "formators": this._formators
        } as any;

        var htmlTemplateDesktop = notificationElementDesktop;
        const htmlDesktop = Mustache.render(htmlTemplateDesktop, viewModel);
        $('#notification-wrap-desktop').html(htmlDesktop);

        var htmlTemplateMobile = notificationElementMobile;
        const htmlMobile = Mustache.render(htmlTemplateMobile, viewModel);
        $('#notification-wrap-mobile').html(htmlMobile);

        this._setNotificationsOnClickEvent();
        this._setNotificationsButtonsEvents();
    }

    private async _renderData(): Promise<void> {
        // Build a view model from the API data
        var currentUser = await this._currentUserAccessor.getUser();
        var nodes = this.sitemapManager.getNodesForUser(currentUser.permissions, currentUser.roles);
        var translations = this._translationService.currentTranslations;
        var localeFormat = moment().creationData().locale.longDateFormat("L");
        this._formators = {
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            }//tukaj se lahko dodajajo formatorji
        };

        await this._kontaktiInPomocApiClient.eboxSporocilaHeader()
            .then((odgovor) => {
                this._sporocilaHeader = odgovor;
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });

        var user = await this._currentUserAccessor.getUser();
        var adminRole = user.roles.find(a => a.role1 == "ROLE_ADMINISTRATOR");
        var klicniCenterRole = user.roles.find(a => a.role1 == "ROLE_KLICNI_CENTER");
        var specialUserRole = user.roles.find(a => a.role1 == "ROLE_MESTNA_BLAGAJNA" || a.role1 == "ROLE_UPRAVNIK" || a.role1 == "ROLE_TOPLOTNI_UPORABNIK"); 

        var openInBrowser = this.isOpenInBrowser();
        var mobilnaAppCookie = this.getCookie(".MobileAppDownload");
        var showAppDownload = openInBrowser && mobilnaAppCookie != "opened";

        const viewModel = {
            onlyForAdmin: adminRole,
            onlyForKlicniCenter: klicniCenterRole,
            onlyForSpecialUsers: specialUserRole,
            "HeaderElements": nodes,
            "formators": this._formators,
            "ios": this.isIOS(),
            "showAppDownload": showAppDownload,
            "translate": () => {
                return function (template: any, render: any) {
                    var val = render(template);
                    return translations[val];
                };
            }
        } as any;

        const htmlTemplate = template;

        const html = Mustache.render(htmlTemplate, viewModel);

        this._header.replaceWith(html);

        var body = $("#body");
        if (showAppDownload) {
            body.addClass("app-down");
            var appDownloadCloseBtn = $("#app-download-close-btn");
            appDownloadCloseBtn.on("click", () => {
                body.removeClass("app-down");
                document.cookie = `.MobileAppDownload=opened`;
            });
            var appDownloadBtn = $("#mobile-app-download-btn");
            appDownloadBtn.on("click", () => {
                document.cookie = `.MobileAppDownload=opened`;
            });
        }
        else {
            body.removeClass("app-down");
        }

        var msgsNoHeader = $("#msgs-no-header");
        var msgsNoMobile = $("#msgs-no-mobile");
        if (msgsNoHeader && msgsNoMobile) {
            msgsNoHeader[0].innerHTML = this._sporocilaHeader.steviloNeprebranihSporocil.toString();
            msgsNoMobile[0].innerHTML = this._sporocilaHeader.steviloNeprebranihSporocil.toString();
            var btnSporocilaHeader = $(".btnSporocilaHeader");
            var btnSporocilaMobile = $("#btnSporocilaMobile");
            if (this._sporocilaHeader.steviloNeprebranihSporocil > 0) {
                btnSporocilaHeader.on("click", () => {
                    if (this._sporocilaHeader.jeElektrika) {
                        window.location.href = "#/kontakti/ebox/pogovor?" + this._sporocilaHeader.threadId + "_"
                            + this._sporocilaHeader.merilnaTocka + "_true_" + this._sporocilaHeader.merilnoMesto + "_" +
                            this._sporocilaHeader.stevilkaKontoPogodbe;
                    }
                    else {
                        window.location.href = "#/kontakti/ebox/pogovor?" + this._sporocilaHeader.threadId + "_"
                            + "_false_" + this._sporocilaHeader.merilnoMesto + "_" + this._sporocilaHeader.stevilkaKontoPogodbe;
                    }
                });

                btnSporocilaMobile.on("click", () => {
                    if (this._sporocilaHeader.jeElektrika) {
                        window.location.href = "#/kontakti/ebox/pogovor?" + this._sporocilaHeader.threadId + "_"
                            + this._sporocilaHeader.merilnaTocka + "_true_" + this._sporocilaHeader.merilnoMesto + "_" +
                            this._sporocilaHeader.stevilkaKontoPogodbe;
                    }
                    else {
                        window.location.href = "#/kontakti/ebox/pogovor?" + this._sporocilaHeader.threadId + "_"
                            + "_false_" + this._sporocilaHeader.merilnoMesto + "_" + this._sporocilaHeader.stevilkaKontoPogodbe;
                    }
                });
                msgsNoMobile.show();
            }
            else {
                btnSporocilaHeader.on("click", () => {
                    window.location.href = "#/kontakti/ebox";
                });
                btnSporocilaMobile.on("click", () => {
                    window.location.href = "#/kontakti/ebox";
                });
                if ($(".moj-profil-alert")) {
                    $(".moj-profil-alert").removeClass("moj-profil-alert");
                }
                msgsNoMobile.hide();
            }
        }
    } 

    private async _getNotifications() {
        await this._userApiClient.getHeaderNotifications(this._takeNotifications, this._skipNotifications)
            .then((odgovor: NotificationHeaderResponse) => {
                if (odgovor != null && odgovor.userNotifications.length != 0) {
                    this._obstajaNeprebranNotification = odgovor.unreadNotificationExist;
                    this._obstajaNeprebranSkritNotification = odgovor.unreadHiddenNotificationExist;
                    this._newNotifications = odgovor.userNotifications;
                    this._notifications = this._notifications.concat(this._newNotifications);
                    this._skipNotifications += this._takeNotifications;
                    this._totalNumberOfNotifications = odgovor.totalNumberOfNotifications;
                }
                else {
                    this._newNotifications = new Array<NotificationHeaderDetails>();
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
    }

    private _setNotificationsOnClickEvent() {
        var notifications = $(".notification");
        if (notifications != null && notifications.length != 0) {
            for (let i = 0; i < notifications.length; i++) {
                var eventListenerSet = notifications[i].dataset["eventlistenerset"] as string;
                if (eventListenerSet == "false") {
                    notifications[i].dataset["eventlistenerset"] = "true";
                    var element = $(".notification-title")[i].dataset["sporociloPrebrano"] as string;
                    if (element == "false") {
                        notifications[i].addEventListener("click", () => {
                            var notificationId = notifications[i].dataset["notificationid"] as string;
                            this._onNotificationClick(notificationId, i);
                            // zaprimo okna za notifications
                            var btnCloseNotMobile = $("#btn-notifications-close-mobile");
                            btnCloseNotMobile[0].click();
                        });
                    }
                    else {
                        notifications[i].addEventListener("click", () => {
                            // zaprimo okna za notifications
                            var btnCloseNotMobile = $("#btn-notifications-close-mobile");
                            btnCloseNotMobile[0].click();
                        });
                    }
                }
            }
        }
    }

    private async _onNotificationClick(notificationId: string, index: number) {
        await this._userApiClient.markNotificationAsRead(notificationId)
            .then((response: any) => {
                if (response.success) {
                    // označimo kot prebrano za desktop in mobile in preverimo ali so vsa prebrana
                    var prikazaniNotifications = this._notifications.length;
                    var drugiIndex = 0;
                    if (index + prikazaniNotifications < prikazaniNotifications * 2) {
                        drugiIndex = index + prikazaniNotifications;
                    }
                    else {
                        drugiIndex = index - prikazaniNotifications;
                    }
                    var notifications = $(".notification-title");
                    if (notifications != null && notifications.length != 0) {
                        notifications[index].dataset["sporociloPrebrano"] = "true";
                        notifications[drugiIndex].dataset["sporociloPrebrano"] = "true";
                        if (!this._obstajaNeprebranSkritNotification) {
                            var obstajaNeprebrano = false;
                            for (let i = 0; i < notifications.length; i++) {
                                if (notifications[i].dataset["sporociloPrebrano"] == "false") {
                                    obstajaNeprebrano = true;
                                }
                            }
                            if (!obstajaNeprebrano) {
                                $("#obstaja-neprebrano-desktop").removeClass("unread");
                                $("#obstaja-neprebrano-mobile").removeClass("unread");
                            }
                        }    
                    }
                }
                else {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });  
    }

    private async _naloziVecNotifications(ev: Event) {
        ev.preventDefault();
        await this._getNotifications();
        if (this._newNotifications.length != 0) {
            const viewModel = {
                "notifications": this._newNotifications,
                "formators": this._formators,
            } as any;

            var htmlTemplateDesktop = notificationDataElementDesktop;
            const htmlDesktop = Mustache.render(htmlTemplateDesktop, viewModel);
            $('#notifications-okno-desktop').append(htmlDesktop);

            var htmlTemplateMobile = notificationDataElementMobile;
            const htmlMobile = Mustache.render(htmlTemplateMobile, viewModel);
            $('#notifications-okno-mobile').append(htmlMobile);

            this._setNotificationsOnClickEvent();
        }
        if (this._notifications.length == this._totalNumberOfNotifications) {
            $("#btn-notifications-nalozi-desktop").hide();
            $("#btn-notifications-nalozi-mobile").hide();
        }
    }

    private _setNotificationsButtonsEvents() {
        // mark all notifications as read
        var btnAllReadMobile = $("#mark-all-notifications-read-mobile");
        btnAllReadMobile.on("click", (ev: Event) => {
            this._onAllNotificationsReadClick(ev);
        });
        var btnAllReadDesktop = $("#mark-all-notifications-read-desktop");
        btnAllReadDesktop.on("click", (ev: Event) => {
            this._onAllNotificationsReadClick(ev);
        });

        // button nalozi vec
        var btnNaloziMobile = $("#btn-notifications-nalozi-mobile");
        btnNaloziMobile.on("click", (ev: Event) => this._naloziVecNotifications(ev));
        var btnNaloziDesktop = $("#btn-notifications-nalozi-desktop");
        btnNaloziDesktop.on("click", (ev: Event) => this._naloziVecNotifications(ev));

        // button settings (mobile)
        var btnNotSettingsMobile = $("#btn-notifications-settings-mobile");
        btnNotSettingsMobile.on("click", () => {
            var btnCloseNotMobile = $("#btn-notifications-close-mobile");
            btnCloseNotMobile[0].click();
        });
    }

    private async _onAllNotificationsReadClick(ev: Event) {
        ev.preventDefault();
        await this._userApiClient.markAllNotificationAsRead()
            .then((response: any) => {
                if (response.success) {
                    // označimo vsa notificationa kot prebrana za desktop in mobile
                    this._obstajaNeprebranSkritNotification = false;
                    this._obstajaNeprebranNotification = false;
                    var notifications = $(".notification-title");
                    if (notifications != null && notifications.length != 0) {
                        for (let i = 0; i < notifications.length; i++) {
                            notifications[i].dataset["sporociloPrebrano"] = "true";
                        }
                        $("#obstaja-neprebrano-desktop").removeClass("unread");
                        $("#obstaja-neprebrano-mobile").removeClass("unread");
                    }
                }
                else {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
    }
}
