import { inject, injectable, interfaces } from "inversify";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { MainLayout } from "../../views/main-layout";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import * as Mustache from "mustache";
import template from "../pages/piskotki-page.html";
import { TranslationService } from "../translation-service";
import { FullscreenLoader } from "../../views/fullscreen-loader";
import { Notyf } from "notyf";
import { PiskotkiApiClient } from "../clients/piskotki-api-client";
import { PiskotkiView } from "../../views/piskotki-view";

@injectable()
export class PiskotkiPage extends PageBase {
    public name = "PiskotkiPage";
    private _router: Router;
    private _translationService: TranslationService;
    private _loader: FullscreenLoader;
    private _piskotkiApiClient: PiskotkiApiClient;
    private _notyf: Notyf;
    private _piskotkiView: PiskotkiView;
    private _piskotkiElement: JQuery<HTMLElement>;

    constructor(@inject("Provider<MainLayout>") private _mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("Router") router: Router,
        @inject("TranslationService") translationService: TranslationService,
        @inject("PiskotkiApiClient") piskotkiApiClient: PiskotkiApiClient,
        @inject("Notyf") notyf: Notyf,
        @inject("FullscreenLoader") loader: FullscreenLoader) {
        super();

        this._router = router;
        this._translationService = translationService;
        this._loader = loader;
        this._piskotkiApiClient = piskotkiApiClient;
        this._notyf = notyf;

        const html = Mustache.render(template, {});
        $('#main').html(html);

        this._piskotkiElement = $('#main').find("#piskotki-page");
        this._piskotkiView = new PiskotkiView(this._piskotkiElement, this._translationService,
            this._piskotkiApiClient, this._notyf);
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        if (!url.pathname.startsWith("/piskotki")) {
            return true;
        }
        var mainLayout = await this._mainLayoutProvider() as MainLayout;
        try {
            mainLayout.hideFooter();
            this._loader.show();
            await this._piskotkiView.load();
            window.scrollTo(0, 0);
            $(".title-strani")[0].innerHTML = "Piškotki";
        } catch (e) {
            mainLayout.errorView.report(e);
        }
        finally {
            this._loader.hide();
            mainLayout.showFooter();
        }
        return false;
    }
}