import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { MojPlusApiClient } from "../ts/clients/moj-plus-api-client";
import { TranslationService } from "../ts/translation-service";
import template from "./moj-plus-vclanitev-view.html";
import "jquery-validation-unobtrusive";
import { FullscreenLoader } from "./fullscreen-loader";
import { MojPlusPodatkiVclanitevRequest } from "../ts/models/moj-plus-podatki-vclanitev-request";
import { MojPlusVclanitev } from "../ts/models/moj-plus-vclanitev";
import { MojPlusVclanitevRequest } from "../ts/models/moj-plus-vclanitev-request";

@injectable()
export class MojPlusVclanitevView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _mojPlusApiClient: MojPlusApiClient;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _dropdownStevilkaRacuna: JQuery<HTMLElement> | null;
    private _formElement: JQuery<HTMLElement> | null;
    private _stevilkeKontoPogodb: Array<string>;
    private _izbranaKontoPogodba: string;
    private _vclanitev: MojPlusVclanitev | null;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        mojPlusApiClient: MojPlusApiClient,
        merilnoMestoApiClient: MerilnoMestoApiClient,
        private _notyf: Notyf,
        private _loader: FullscreenLoader)
    {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._mojPlusApiClient = mojPlusApiClient;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._dropdownStevilkaRacuna = null;
        this._formElement = null;
        this._stevilkeKontoPogodb = new Array<string>();
        this._izbranaKontoPogodba = "";
        this._vclanitev = null;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;
            await this._merilnoMestoApiClient.getStevilkeKontoPogodb()
                .then((odgovor) => {                    
                    this._stevilkeKontoPogodb = odgovor.filter((value: any, index: any, array: any) => array.indexOf(value) === index);
                    if (this._stevilkeKontoPogodb.length > 0) {
                        // poiscemo ali obstaja selektira številka racuna (v querystringu)
                        var url = window.location.href.split("/");
                        var izbranaKontoPogodbaId = url[url.length - 1];
                        var kontoPogodba = this._stevilkeKontoPogodb.find(a => a == izbranaKontoPogodbaId);
                        
                        if (kontoPogodba) {
                            this._izbranaKontoPogodba = kontoPogodba;
                        }
                        else {
                            // ce v querystringu ni dolocena konto pogodba, selektiramo prvo pogodbo iz liste
                            this._izbranaKontoPogodba = this._stevilkeKontoPogodb[0];
                        }                       

                    }
                    else {
                        this.rootElement.text(this._translationService.currentTranslations["YouDontHaveAnyContractAccount"]);
                        return;
                    }
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    return;
                });

            if (this._izbranaKontoPogodba) {
                var mojPlusPodatkiVclanitevRequest: MojPlusPodatkiVclanitevRequest = {
                    stevilkaKontoPogodbe: this._izbranaKontoPogodba,
                    kljucProgramZvestobe: "EPLUS"
                };                
                await this._mojPlusApiClient.podatkiVclanitev(mojPlusPodatkiVclanitevRequest)
                    .then((odgovor) => {
                        this._vclanitev = odgovor;
                        if (!odgovor.kreiranoDne)  //Racun ne obstaja v programu zvestobe MojPlus
                            this._vclanitev = null;
                        
                    })
                    .catch(() => {
                        this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    });
            }

            this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;
            if (this._izbranaKontoPogodba) {
                this._initForm();
            }
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(translations: any): void {
        const viewModel = {
            translations: translations,
            stevilkeKontoPogodbe: this._stevilkeKontoPogodb,
            vclanitev: this._vclanitev,
            mojPlusRacunObstaja: this._vclanitev != null ? true : false,
            izbranaKontoPogodba: this._izbranaKontoPogodba,
            obstajaKontoPogodba: this._izbranaKontoPogodba == "" ? false : true
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _initForm(): void {
        this._formElement = $(("#vclanitev-form"));
        $.validator.unobtrusive.parse(this._formElement);
        this._formElement.validate();

        this._dropdownStevilkaRacuna = $("#dropdownStevilkaRacuna");
        this._dropdownStevilkaRacuna.on("change", (ev: Event) => this._onDropDownStevilkaRacunaChange(ev));
        if (this._izbranaKontoPogodba) {
            this._dropdownStevilkaRacuna.val(this._izbranaKontoPogodba);
        }

        var btnMerilnaMestaOthers = $("#btnMerilnaMestaOthers");
        btnMerilnaMestaOthers.on("click", (ev: Event) => this._onBtnMerilnaMestaOthersClick(ev));

        var btnVclaniteSe = $("#btnVclaniteSe");
        btnVclaniteSe.on("click", (ev: Event) => this._onBtnVclaniteSeClick(ev, this._formElement));
    }

    private redirectToDropDownSelectedValue() {
        this._dropdownStevilkaRacuna = $("#dropdownStevilkaRacuna");
        var dropdownMerilnoMestoValue = (this._dropdownStevilkaRacuna[0] as any).value;
        location.href = "#/moj-plus/vclanitev/" + dropdownMerilnoMestoValue;
    }

    private _onDropDownStevilkaRacunaChange(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        this.redirectToDropDownSelectedValue();        
    }

    private _onBtnMerilnaMestaOthersClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        $(".merilna-mesta-not-all")[0].style.display = "none";
        $(".merilna-mesta-all")[0].style.display = "block";
    }

    private async _onBtnVclaniteSeClick(ev: Event, formElement: any) {       
        console.debug(ev);
        ev.preventDefault();
        if (!formElement.valid()) {
            this._notyf.error(this._translationService.currentTranslations.CheckAllFields);
            return;
        }
        this._loader.show();
        var formData = new FormData(formElement[0] as HTMLFormElement);
        var davcnaStevilka = formData.get("davcnaStevilka") as string;
        var mojPlusVclanitevRequest: MojPlusVclanitevRequest = {
            davcnaStevilka: davcnaStevilka,
            uporabnikId: -1,
            kljucProgramZvestobe: "EPLUS",
            stevilkaKontoPogodbe: this._izbranaKontoPogodba,
            zelimPostatiClan: ($("#zelimPostatiClan")[0] as HTMLInputElement).checked,
            strinjamSeSSplosniPogoji: ($("#strinjamSeSPogoji")[0] as HTMLInputElement).checked,
            privolitevDoPreklica: ($("#privolitev")[0] as HTMLInputElement).checked
        };
        await this._mojPlusApiClient.vclanitevMojPlus(mojPlusVclanitevRequest)
            .then((odgovor) => {
                this._loader.hide();
                if (odgovor.status) {
                    $("#vclanitev-moj-plus-podatki").hide();
                    this._notyf.success(this._translationService.currentTranslations["SaveSuccessful"]);
                }
                else
                    this._notyf.error(odgovor.sporocilo);                    
            })
            .catch(() => {               
                this._loader.hide();
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
    }
}