import { inject, injectable, interfaces } from "inversify";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { MainLayout } from "../../views/main-layout";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import * as Mustache from "mustache";
import template from "../pages/novice-pregled-page.html";
import { TranslationService } from "../translation-service";
import { Notyf } from "notyf";
import { FullscreenLoader } from "../../views/fullscreen-loader";
import { NoviceApiClient } from "../clients/novice-api-client";
import { NovicePregledView } from "../../views/novice-pregled-view";

@injectable()
export class NovicePregledPage extends PageBase {
    public name = "NovicePregledPage";
    private _router: Router;
    private _translationService: TranslationService;
    private _notyf: Notyf;
    private _noviceApiClient: NoviceApiClient;
    private _novicePregledView: NovicePregledView;
    private _novicePregledElement: JQuery<HTMLElement>;
    private _loader: FullscreenLoader;

    public constructor(@inject("Provider<MainLayout>") private _mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("Router") router: Router,
        @inject("TranslationService") translationService: TranslationService,
        @inject("NoviceApiClient") noviceApiClient: NoviceApiClient,
        @inject("Notyf") notyf: Notyf,
        @inject("FullscreenLoader") loader: FullscreenLoader) {
        super();

        this._router = router;
        this._translationService = translationService;
        this._noviceApiClient = noviceApiClient;
        this._notyf = notyf;
        this._loader = loader;

        const html = Mustache.render(template, {});
        $('#main').html(html);

        this._novicePregledElement = $('#main').find("#novice-pregled");
        this._novicePregledView = new NovicePregledView(this._novicePregledElement,
            this._translationService, this._noviceApiClient, this._notyf);
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        if (!url.pathname.startsWith("/novice-pregled")) {
            return true;
        }
        var mainLayout = await this._mainLayoutProvider() as MainLayout;
        try {
            mainLayout.hideFooter();
            this._loader.show();
            await this._novicePregledView.load();
            window.scrollTo(0, 0);
            $(".title-strani")[0].innerHTML = this._translationService.currentTranslations["News"];
        } catch (e) {
            mainLayout.errorView.report(e);
        }
        finally {
            this._loader.hide();
            mainLayout.showFooter();
        }
        return false;
    }
}