import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { User } from "../ts/models/user";
import { TranslationService } from "../ts/translation-service";
import template from "./admin-uporabniki-pregled-view.html";
import { ListResponse } from "../ts/models/list-response";
import { StatusUporabnika } from "../ts/models/status-uporabnika-enum";
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { GetUsersRequest } from "../ts/models/get-users-request";
import { UporabnikMerilnoMestoRequest } from "../ts/models/uporabnik-merilno-mesto-request";
import { UporabnikMerilnoMestoResponse } from "../ts/models/uporabnik-merilno-mesto-response";
import { AdminApiClient } from "../ts/clients/admin-api-client";
import { UserDto } from "../ts/models/userDto";
import { TipUporabnika } from "../ts/models/tip-uporabnika-enum";

@injectable()
export class AdminUporabnikiPregledView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _adminApiClient: AdminApiClient;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _selectedUsers: Array<any>;
    private _limit: number;
    private _maxPages: number;
    private _steviloZadetkov: number;
    private _sortirajNarascajoce: boolean;
    private _tipUporabnikov: TipUporabnika;
    private _izbranRole: string;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        adminApiClient: AdminApiClient,
        merilnoMestoApiClient: MerilnoMestoApiClient,
        private _notyf: Notyf)
    {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._adminApiClient = adminApiClient;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._selectedUsers = new Array<User>();
        this._limit = 10;
        this._maxPages = 1;
        this._steviloZadetkov = 0;
        this._sortirajNarascajoce = true;
        this._tipUporabnikov = TipUporabnika.Uporabnik;
        this._izbranRole = "ROLE_UPORABNIK";
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            this._tipUporabnikov = this._getTipUporabnikov();
            var translations = this._translationService.currentTranslations;
            this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;
            await this._getUporabnike(1);
            this._refreshTables(1);
            this._initForm();
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _getTipUporabnikov() {
        // 1 - Uporabnik
        // 2 - Mestna blagajna
        // 3 - Upravnik
        // 4 - Toplotni uporabnik
        // Ostalo - Uporabnik
        var url = window.location.href.split("/");
        var izbranRole = url[url.length - 1];
        if (Number(izbranRole) == 1) {
            this._izbranRole = "ROLE_UPORABNIK";
            return TipUporabnika.Uporabnik;
        }
        else if (Number(izbranRole) == 2) {
            this._izbranRole = "ROLE_MESTNA_BLAGAJNA";
            return TipUporabnika.MestnaBlagajna;
        }
        else if (Number(izbranRole) == 3) {
            this._izbranRole = "ROLE_UPRAVNIK";
            return TipUporabnika.Upravnik;
        }
        else if (Number(izbranRole) == 4) {
            this._izbranRole = "ROLE_TOPLOTNI_UPORABNIK";
            return TipUporabnika.ToplotniUporabnik;
        }
        return TipUporabnika.Uporabnik;
    }

    private _renderData(translations: any): void {
        const viewModel = {
            translations: translations,
            jeUporabnik: this._tipUporabnikov == TipUporabnika.Uporabnik ? true : false,
            jeMestnaBlagajna: this._tipUporabnikov == TipUporabnika.MestnaBlagajna ? true : false,
            jeUpravnik: this._tipUporabnikov == TipUporabnika.Upravnik ? true : false,
            jeToplotniUporabnik: this._tipUporabnikov == TipUporabnika.ToplotniUporabnik ? true : false,
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _initForm(): void {
        var btnPocistiFiltre = $("#btnPocistiFiltre");
        btnPocistiFiltre.on("click", (ev: Event) => this._onBtnPocistiFiltreClick(ev));

        var btnPoiscite = $("#poiscite");
        btnPoiscite.on("click", (ev: Event) => this._onBtnPoisciteClick(ev));

        // radio buttons
        var statusUporabnikaAktiven = $(".statusUporabnikaAktiven");
        statusUporabnikaAktiven.on("click", async () => {
            this._showLoader();
            await this._getUporabnike(1);
            this._refreshTables(1);
        });

        var statusUporabnikaNeaktiven = $(".statusUporabnikaNeaktiven");
        statusUporabnikaNeaktiven.on("click", async () => {
            this._showLoader();
            await this._getUporabnike(1);
            this._refreshTables(1);
        });

        var statusUporabnikaOnemogocen = $(".statusUporabnikaOnemogocen");
        statusUporabnikaOnemogocen.on("click", async () => {
            this._showLoader();
            await this._getUporabnike(1);
            this._refreshTables(1);
        });

        var statusUporabnikaVse = $(".statusUporabnikaVse");
        statusUporabnikaVse.on("click", async () => {
            this._showLoader();
            await this._getUporabnike(1);
            this._refreshTables(1);
        });
    }

    private _showLoader() {
        $("input:radio").prop("disabled", true);
        this.rootElement.find(".table-responsive").html(`<div class="text-center"><div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status"></div></div>`);
    }

    private _onBtnPocistiFiltreClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        ($(".statusUporabnikaVse")[0] as HTMLInputElement).checked = true;
    }

    private async _getUporabnike(page: number) {
        var getUsersRequest: GetUsersRequest = {
            search: ($(".user-search-field")[0] as HTMLInputElement).value,
            page: page,
            limit: this._limit,
            statusUporabnika: this._preberiStatusUporabnika(),
            role: this._izbranRole
        };
        await this._adminApiClient.getUsersDto(getUsersRequest)
            .then((odgovor: ListResponse<UserDto>) => {
                this._steviloZadetkov = odgovor.totalCount;
                this._selectedUsers = odgovor.items;
                this._maxPages = odgovor.pageCount;               
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });

        if (this._selectedUsers && this._selectedUsers.length > 0) {
            for (let i = 0; i < this._selectedUsers.length; i++) {
                var uporabnikMerilnoMestoRequest: UporabnikMerilnoMestoRequest = {
                    limit: 0,
                    stevilkaStrani: 0,
                    saldo: false,
                    skupnaPorabaEnergije: false,
                    merilnoMesto: "",
                    merilnaTocka: ""
                };
                await this._merilnoMestoApiClient.getUporabnikMerilnaMesta(uporabnikMerilnoMestoRequest, this._selectedUsers[i].id)
                    .then((odgovor: UporabnikMerilnoMestoResponse) => {
                        this._selectedUsers[i].merilnaMesta = odgovor.merilnaMesta;
                    })
                    .catch(() => {
                        this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                    });
            }
        }
    }

    private async _onBtnPoisciteClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        this._showLoader();
        await this._getUporabnike(1);
        this._refreshTables(1);
    }

    private _refreshTables(page: number) {
        this._refreshTableDesktop();
        this._refreshTableMobile();
        this._refreshPagination(page);
        $(".tabela-desktop").addClass("d-lg-block");
        $(".tabela-desktop").removeClass("d-lg-none");
        $(".tabela-mobile").addClass("d-block");
        $(".tabela-mobile").removeClass("d-none");
        $("input:radio").prop("disabled", false);
    }

    private async _onBtnPageChangeClick(ev: Event, previousClicked: boolean) {
        console.debug(ev);
        ev.preventDefault();
        this._showLoader();
        var pagesString = $(".pages")[0].innerHTML;
        var currentPage = Number(pagesString.split("/")[0]);
        if (previousClicked) {
            currentPage -= 1;
        }
        else {
            currentPage += 1;
        }
        await this._getUporabnike(currentPage);
        this._refreshTables(currentPage);
    }

    private _refreshPagination(page: number) {
        if (!this._selectedUsers || this._selectedUsers && this._selectedUsers.length <= 0) {
            $("#pagination")[0].style.display = "none";
            return;
        }
        const templateString = `
             <div class="pagination">
                <%#obstajaPrejsnjaStran%>
                <div>
                    <a class="btnPreviousPage">&lt</a>
                </div>
                <%/obstajaPrejsnjaStran%>
                <div class="pages"><%{page}%>/<%{maxPage}%></div>
                <%#obstajaNaslednjaStran%>
                <div>
                    <a class="btnNextPage">&gt</a>
                </div>
                <%/obstajaNaslednjaStran%>
            </div>
        `;
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "page": page,
                "maxPage": this._maxPages,
                "obstajaPrejsnjaStran": page != 1 ? true : false,
                "obstajaNaslednjaStran": page != this._maxPages ? true : false
            },
            undefined,
            customTags
        );
        $("#pagination")[0].style.display = "block";
        $("#pagination").html(html);
        $(".btnPreviousPage").on("click", async (ev: Event) => await this._onBtnPageChangeClick(ev, true));
        $(".btnNextPage").on("click", async (ev: Event) => await this._onBtnPageChangeClick(ev, false));
    }

    private _refreshTableDesktop() {
        const templateString = `
            <%#obstajaUser%>
                <%#jeUporabnik%>
                    <h3 class="margin-small"><%{translations.UsersHouseholdAndBusinessCustomers}%> (<%{steviloZadetkov}%>)</h3>
                <%/jeUporabnik%>
                <%#jeMestnaBlagajna%>
                    <h3 class="margin-small"><%{translations.UsersCashRegister}%> (<%{steviloZadetkov}%>)</h3>
                <%/jeMestnaBlagajna%>
                <%#jeUpravnik%>
                    <h3 class="margin-small"><%{translations.UsersAdministrators}%> (<%{steviloZadetkov}%>)</h3>
                <%/jeUpravnik%>
                <%#jeToplotniUporabnik%>
                    <h3 class="margin-small"><%{translations.UsersHeat}%> (<%{steviloZadetkov}%>)</h3>
                <%/jeToplotniUporabnik%>
                <table class="table vloge-obrazci">
                    <thead>
                    <tr>
                        <th scope="col"><a id="sortirajId" class="bi sorting"><%{translations.Id}%></a></th>
                        <th scope="col"><a id="sortirajEmail" class="bi sorting"><%{translations.Email1}%></a></th>
                        <th scope="col"><a id="sortirajIme" class="bi sorting"><%{translations.FullName}%></a></th>
                        <th scope="col"><%{translations.Status}%></th>
                        <th scope="col"><%{translations.MeasuringSamplingPoints}%></th>
                        <th scope="col"><%{translations.ContractAccounts}%></th>
                        <th scope="col">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <%#selectedUsers%>
                    <tr>
                        <td scope="row"><%{id}%></td>
                        <td><%{email}%></td>
                        <td><%{firstName}%></td>             
                        <td><%{statusUporabnika}%></td>
                        <td><%#merilnaMesta%><%{merilnoMesto}%> <br> <%/merilnaMesta%></td>
                        <td><%#merilnaMesta%><%{stevilkaKontoPogodbe}%> <br> <%/merilnaMesta%></td>
                            <td>
                            <%#jeUporabnik%>
                                <a href="#/admin/urejanje-uporabnika/tip-uporabnik/<%{id}%>"><%{translations.Edit}%></a>
                            <%/jeUporabnik%>
                            <%#jeMestnaBlagajna%>
                                <a href="#/admin/urejanje-uporabnika/tip-mestna-blagajna/<%{id}%>"><%{translations.Edit}%></a>
                            <%/jeMestnaBlagajna%>
                            <%#jeUpravnik%>
                                <a href="#/admin/urejanje-uporabnika/tip-upravnik/<%{id}%>"><%{translations.Edit}%></a>
                            <%/jeUpravnik%>
                            <%#jeToplotniUporabnik%>
                                <a href="#/admin/urejanje-uporabnika/tip-toplota/<%{id}%>"><%{translations.Edit}%></a>
                            <%/jeToplotniUporabnik%>
                        </td>
                    </tr>
                    <%/selectedUsers%>
                </tbody>
            </table>
            <%/obstajaUser%>
            <%^obstajaUser%>
                <div><%{translations.NoUsersFound}%></div>
            <%/obstajaUser%>
        `;

        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "obstajaUser": this._selectedUsers && this._selectedUsers.length > 0 ? true : false,
                "steviloZadetkov": this._steviloZadetkov,
                "selectedUsers": this._selectedUsers,
                "translations": this._translationService.currentTranslations,
                "jeUporabnik": this._tipUporabnikov == TipUporabnika.Uporabnik ? true : false,
                "jeMestnaBlagajna": this._tipUporabnikov == TipUporabnika.MestnaBlagajna ? true : false,
                "jeUpravnik": this._tipUporabnikov == TipUporabnika.Upravnik ? true : false,
                "jeToplotniUporabnik": this._tipUporabnikov == TipUporabnika.ToplotniUporabnik ? true : false
            },
            undefined,
            customTags
        );
        $(".tabela-desktop").html(html);
        $("#sortirajId").on("click", (ev: Event) => this._onBtnSortirajClick(ev, "id"));
        $("#sortirajEmail").on("click", (ev: Event) => this._onBtnSortirajClick(ev, "email"));
        $("#sortirajIme").on("click", (ev: Event) => this._onBtnSortirajClick(ev, "ime"));
    }

    private _refreshTableMobile() {
        const templateString = `
            <%#obstajaUser%>
            <h3 class="margin-small"><%{translations.UsersHouseholdAndBusinessCustomers}%> (<%{steviloZadetkov}%>)</h3>
            <table class="table vloge-obrazci-mobile table-borderless">
                <%#selectedUsers%>
                <tbody>
                    <tr>
                        <th scope="row"><%{translations.Id}%></th>
                        <td><%{id}%></td>
                    </tr>
                    <tr>
                        <th scope="row"><%{translations.Email1}%></th>
                        <td><%{email}%></td>
                    </tr>
                    <tr>
                        <th scope="row"><%{translations.FullName}%></th>
                        <td><%{firstName}%></td>
                    </tr>
                    <tr>
                        <th scope="row"><%{translations.Status}%></th>
                        <td><%{statusUporabnika}%></td>
                    </tr>
                    <tr>
                        <th scope="row"><%{translations.MeasuringSamplingPoints}%></th>
                        <td><%#merilnaMesta%><%{merilnoMesto}%> <br> <%/merilnaMesta%></td>
                    </tr>
                    <tr>
                        <th scope="row"><%{translations.ContractAccounts}%></th>
                        <td><%#merilnaMesta%><%{stevilkaKontoPogodbe}%> <br> <%/merilnaMesta%></td>
                    </tr>
                    <tr>
                        <th scope="row">&nbsp;</th>
                        <td>
                            <a href="#/admin/urejanje-uporabnika/tip-uporabnik/<%{id}%>"><%{translations.Edit}%></a>
                        </td>
                    </tr>
                </tbody>
                <%/selectedUsers%>
            </table>  
            <%/obstajaUser%>
            <%^obstajaUser%>
            <div><%{translations.NoUsersFound}%></div>
            <%/obstajaUser%>
        `;
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "obstajaUser": this._selectedUsers && this._selectedUsers.length > 0 ? true : false,
                "steviloZadetkov": this._steviloZadetkov,
                "selectedUsers": this._selectedUsers,
                "translations": this._translationService.currentTranslations
            },
            undefined,
            customTags
        );
        $(".tabela-mobile").html(html);
    }

    private _preberiStatusUporabnika() {
        if (($(".statusUporabnikaAktiven")[0] as HTMLInputElement).checked) {
            return StatusUporabnika.Aktiven;
        }
        else if (($(".statusUporabnikaNeaktiven")[0] as HTMLInputElement).checked) {
            return StatusUporabnika.Neaktiven;
        }
        else if (($(".statusUporabnikaOnemogocen")[0] as HTMLInputElement).checked) {
            return StatusUporabnika.Onemogocen;
        }
        return null;
    }

    private _onBtnSortirajClick(ev: Event, polje: string) {
        console.debug(ev);
        ev.preventDefault();
        // id
        if (polje == "id") {
            if (this._sortirajNarascajoce) {
                this._selectedUsers.sort(function (a, b) {
                    if (a.id > b.id) {
                        return -1;
                    }
                    if (a.id < b.id) {
                        return 1;
                    }
                    return 0;
                });
            }
            else {
                this._selectedUsers.sort(function (a, b) {
                    if (a.id < b.id) {
                        return -1;
                    }
                    if (a.id > b.id) {
                        return 1;
                    }
                    return 0;
                });
            }
            this._sortirajNarascajoce = !this._sortirajNarascajoce;
        }
        // email
        if (polje == "email") {
            if (this._sortirajNarascajoce) {
                this._selectedUsers.sort(function (a, b) {
                    if (a.email.toLowerCase() > b.email.toLowerCase()) {
                        return -1;
                    }
                    if (a.email.toLowerCase() < b.email.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
            }
            else {
                this._selectedUsers.sort(function (a, b) {
                    if (a.email.toLowerCase() < b.email.toLowerCase()) {
                        return -1;
                    }
                    if (a.email.toLowerCase() > b.email.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
            }
            this._sortirajNarascajoce = !this._sortirajNarascajoce;
        }
        // polno ime
        if (polje == "ime") {
            if (this._sortirajNarascajoce) {
                this._selectedUsers.sort(function (a, b) {
                    if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
                        return -1;
                    }
                    if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
            }
            else {
                this._selectedUsers.sort(function (a, b) {
                    if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
                        return -1;
                    }
                    if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
            }
            this._sortirajNarascajoce = !this._sortirajNarascajoce;
        }
        var pagesString = $(".pages")[0].innerHTML;
        var currentPage = Number(pagesString.split("/")[0]);
        this._refreshTables(currentPage);
    }
}
