import { inject, injectable, interfaces } from "inversify";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { MainLayout } from "../../views/main-layout";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import * as Mustache from "mustache";
import template from "../pages/admin-dashboard-page.html";
import { TranslationService } from "../translation-service";
import { FullscreenLoader } from "../../views/fullscreen-loader";
import { CurrentUserAccessor } from "../utilities/current-user-accessor";
import { AdminDashboardView } from "../../views/admin-dashboard-view";
import { IntranetApiClient } from "../clients/intranet-api-client";

@injectable()
export class AdminDashboardPage extends PageBase {
    public name = "AdminDashboardPage";
    private _router: Router;
    private _translationService: TranslationService;
    private _adminDashboardView: AdminDashboardView;
    private _dashboardPlaceholderElement: JQuery<HTMLElement>;
    private _loader: FullscreenLoader;
    private _currentUserAccessor: CurrentUserAccessor;
    private _intranetApiClient: IntranetApiClient;

    constructor(@inject("Provider<MainLayout>") private _mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("Router") router: Router,
        @inject("TranslationService") translationService: TranslationService,
        @inject("FullscreenLoader") loader: FullscreenLoader,
        @inject("CurrentUserAccessor") currentUserAccessor: CurrentUserAccessor,
        @inject("IntranetApiClient") intranetApiClient: IntranetApiClient) {
        super();

        this._router = router;
        this._translationService = translationService;
        this._loader = loader;
        this._currentUserAccessor = currentUserAccessor;
        this._intranetApiClient = intranetApiClient;

        const viewModel = {
            translations: this._translationService.currentTranslations
        } as any;
        const html = Mustache.render(template, viewModel);
        $('#main').html(html);

        this._dashboardPlaceholderElement = $('#main').find("#admin-dashboard");
        this._adminDashboardView = new AdminDashboardView(this._dashboardPlaceholderElement,
            this._translationService, this._intranetApiClient)
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        if (!url.pathname.startsWith("/admin/dashboard")) {
            return true;
        }
        var mainLayout = await this._mainLayoutProvider() as MainLayout;
        try {
            var user = await this._currentUserAccessor.getUser();
            var adminRole = user.roles.find(a => a.role1 == "ROLE_ADMINISTRATOR");
            if (adminRole) {
                mainLayout.hideFooter();
                mainLayout.hideHeader();
                Promise.all([
                    this._loader.show(),
                    await this._adminDashboardView.load()
                ]).then(() => {
                    window.scrollTo(0, 0);
                    mainLayout.showFooter();
                    mainLayout.showHeader();
                    $(".title-strani")[0].innerHTML = this._translationService.currentTranslations["Homepage"];
                    this._loader.hide();
                }).catch((reason: any) => {
                    console.log(reason);
                    this._loader.hide();
                })
            }
        } catch (e) {
            mainLayout.errorView.report(e);
            this._loader.hide();
        }
        return false;
    }
}