import { inject, injectable, interfaces } from "inversify";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { MainLayout } from "../../views/main-layout";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import * as Mustache from "mustache";
import template from "../pages/test-2-page.html";
import { TranslationService } from "../translation-service";
import { Test2View } from "../../views/test-2-view";

@injectable()
export class Test2Page extends PageBase {
    public name = "Test2Page";
    private _router: Router;
    private _translationService: TranslationService;

    private _testniView: Test2View;

    private _testniElement: JQuery<HTMLElement>;

    public constructor(@inject("Provider<MainLayout>") private _mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("Router") router: Router,
        @inject("TranslationService") translationService: TranslationService) {
        super();

        this._router = router;
        this._translationService = translationService;

        const html = Mustache.render(template, {});
        $('#main').html(html);

        this._testniElement = $('#main').find("#test2");

        this._testniView = new Test2View(this._testniElement, this._translationService);
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        if (!url.pathname.startsWith("/drugi-test")) {
            return true;
        }
        var mainLayout = await this._mainLayoutProvider() as MainLayout; try {
            // TODO: popravi
            this._testniView.load();
        } catch (e) {
            // Report failures
            mainLayout.errorView.report(e);
        }
        return false;
    }
}