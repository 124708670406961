import { injectable } from "inversify";
import * as moment from "moment";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { AdminApiClient } from "../ts/clients/admin-api-client";
import { StatistikaResponse } from "../ts/models/statistika-response";
import { TranslationService } from "../ts/translation-service";
import template from "./admin-statistika-view.html";

@injectable()
export class AdminStatistikaView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _adminApiClient: AdminApiClient;
    private _btnUporabnikiIzvoz: JQuery<HTMLElement> | null;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        adminApiClient: AdminApiClient,
        private _notyf: Notyf) {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._adminApiClient = adminApiClient;
        this._btnUporabnikiIzvoz = null;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;
            await this._adminApiClient.getStatistika()
                .then((statistika: StatistikaResponse) => {
                    this._renderData(translations, statistika);
                    if ($("#page-loaded")[0] == undefined) return;
                    this._initForm();
                })
                .catch(() => {
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                });
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private _renderData(translations: any, statistika: StatistikaResponse): void {
        var localeFormat = moment().creationData().locale.longDateFormat("LLL");
        var formators = {
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format(localeFormat);
                };
            }//tukaj se lahko dodajajo formatorji
        };

        const viewModel = {
            formators: formators,
            obstajaDatumPosodobljeno: statistika.posodobljeno ? true : false,
            statistika: statistika,
            translations: translations
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _initForm() {
        this._btnUporabnikiIzvoz = $("#btn-uporabniki-izvoz");
        this._btnUporabnikiIzvoz.on("click", (ev: Event) => this._onBtnUporabnikiIzvozClick(ev));
    }

    private async _onBtnUporabnikiIzvozClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        var downloadingNotyf = this._notyf.open({
            type: "info",
            message: this._translationService.currentTranslations["Downloading"],
            duration: 50000
        });
        await this._adminApiClient.seznamUporabnikovDownload()
            .then((fileContent) => {
                var blob = new Blob([fileContent.fileContent], { type: fileContent.fileType });
                var url = URL.createObjectURL(blob);
                var fileName: string = fileContent.fileName;
                var documentName = "seznam_uporabnikov" + ".xlsx";
                if (!fileName) {
                    fileName = documentName;
                }
                var fileLink = document.createElement('a');
                fileLink.href = url;
                fileLink.download = fileName;
                fileLink.click();

            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["ErrorWhileDownloadingFile"]);
            }).finally(() => {
                this._notyf.dismiss(downloadingNotyf);
            })
    }
}
