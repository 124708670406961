import { inject, injectable, interfaces } from "inversify";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { MainLayout } from "../../views/main-layout";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import * as Mustache from "mustache";
import template from "../pages/dashboard-page.html";
import { TranslationService } from "../translation-service";
import { NoviceView } from "../../views/novice-view";
import { NoviceApiClient } from "../clients/novice-api-client";
import { MerilnoMestoApiClient } from "../clients/merilno-mesto-api-client";
import { Notyf } from "notyf";
import { FullscreenLoader } from "../../views/fullscreen-loader";
import { UserApiClient } from "../clients/users-api-client";
import { DashboardView } from "../../views/dashboard-view";
import { CurrentUserAccessor } from "../utilities/current-user-accessor";

@injectable()
export class DashboardPage extends PageBase {
    public name = "DashboardPage";
    private _router: Router;
    private _translationService: TranslationService;
    private _notyf: Notyf;
    private _noviceApiClient: NoviceApiClient;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _dashboardView: DashboardView;
    private _noviceView: NoviceView;
    private _dashboardPlaceholderElement: JQuery<HTMLElement>;
    private _novicePlaceholderElement: JQuery<HTMLElement>;
    private _loader: FullscreenLoader;
    private _userApiClient: UserApiClient;
    private _currentUserAccessor: CurrentUserAccessor;

    constructor(@inject("Provider<MainLayout>") private _mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("Router") router: Router,
        @inject("TranslationService") translationService: TranslationService,
        @inject("Notyf") notyf: Notyf,
        @inject("NoviceApiClient") noviceApiClient: NoviceApiClient,
        @inject("MerilnoMestoApiClient") merilnoMestoApiClient: MerilnoMestoApiClient,
        @inject("FullscreenLoader") loader: FullscreenLoader,
        @inject("UserApiClient") userApiClient: UserApiClient,
        @inject("CurrentUserAccessor") currentUserAccessor: CurrentUserAccessor) {
        super();

        this._router = router;
        this._translationService = translationService;
        this._notyf = notyf;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._noviceApiClient = noviceApiClient;
        this._loader = loader;
        this._userApiClient = userApiClient;
        this._currentUserAccessor = currentUserAccessor;

        const viewModel = {
            translations: this._translationService.currentTranslations
        } as any;
        const html = Mustache.render(template, viewModel);
        $('#main').html(html);

        this._dashboardPlaceholderElement = $('#main').find("#dashboard-view");
        this._novicePlaceholderElement = $('#main').find("#dashboard-novice");

        this._dashboardView = new DashboardView(this._dashboardPlaceholderElement,
            this._translationService, this._merilnoMestoApiClient, this._userApiClient, this._notyf);
        this._noviceView = new NoviceView(this._novicePlaceholderElement, this._translationService, this._noviceApiClient);
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        if (url.pathname != "" && url.pathname != "/") {
            return true;
        }
        var mainLayout = await this._mainLayoutProvider() as MainLayout;
        try {
            mainLayout.hideFooter();
            mainLayout.hideHeader();
            var currentUser = await this._currentUserAccessor.getUser();
            if (currentUser.prvaPrijava) {
                window.location.href = "#/moj-profil";
            }
            else {
                this._loader.show();
                await this._dashboardView.load();
                await this._noviceView.load();
                window.scrollTo(0, 0);
                mainLayout.showFooter();
                mainLayout.showHeader();
                $(".title-strani")[0].innerHTML = this._translationService.currentTranslations["Homepage"];
                this._loader.hide();
            }
        } catch (e) {
            mainLayout.errorView.report(e);
            this._loader.hide();
        }
        return false;
    }
}