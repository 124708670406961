import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { MerilnoMestoApiClient } from "../ts/clients/merilno-mesto-api-client";
import { TranslationService } from "../ts/translation-service";
import template from "./vloge-vnos-podatkov-view.html";
import "jquery-validation-unobtrusive";
import { FullscreenLoader } from "./fullscreen-loader";
import { TipElektronskeVloge } from "../ts/models/tipElektronskeVloge";
import { CurrentUserAccessor } from "../ts/utilities/current-user-accessor";
import * as moment from "moment";
import * as DateRangePicker from 'daterangepicker';
import { VlogeSoglasjeLastnikaZaEvidentiranjePlacnikaRequest } from "../ts/models/vloge-soglasje-lastnika-za-evidentiranje-placnika-request";
import { VlogePrijavaPrejemaElektronskegaRacunaRequest } from "../ts/models/vloge-prijava-prejema-elektronskega-racuna-request";
import { VlogeEvidentiranjeNaslovaZaPosiljanjePosteRequest } from "../ts/models/vloge-evidentiranje-naslova-za-posiljanje-poste-request";
import * as intlTelInput from "intl-tel-input";
import { MerilnoMesto } from "../ts/models/merilno-mesto";
import { UporabnikMerilnoMestoRequest } from "../ts/models/uporabnik-merilno-mesto-request";
import { UporabnikMerilnoMestoResponse } from "../ts/models/uporabnik-merilno-mesto-response";
import { UporabnikPoslovnePartnerjeRequest } from "../ts/models/uporabnik-poslovne-partnerje-request";
import { UporabnikPoslovnePartnerjeResponse, UporabnikPoslovniPartnerResponseData } from "../ts/models/uporabnik-poslovne-partnerje-response";
import { VlogeDirektnaObremenitevRequest } from "../ts/models/vloge-direktna-obremenitev-request";
import priponkeTemplate from "./widget-priloge.html";
import { VlogeZlataUraRequest } from "../ts/models/vloge-zlata-ura-request";

@injectable()
export class VlogeVnosPodatkovView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _tipElektronskeVloge: TipElektronskeVloge;
    private _mobileInput: any;
    private _mobileInput2: any;
    private _merilnaMesta: Array<MerilnoMesto> | null;
    private _izbranoMerilnoMesto: MerilnoMesto | null | undefined;
    private _poslovniPartner: UporabnikPoslovniPartnerResponseData | null | undefined;
    private _lastnistvoFileList: Array<any>;
    private _lastnistvoFileViewList: Array<any>;
    private _primopredajaFileList: Array<any>;
    private _primopredajaFileViewList: Array<any>;
    private _lastnistvoUploadFilesElement: HTMLInputElement | null;
    private _primopredajaUploadFilesElement: HTMLInputElement | null;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        private _merilnoMestoApiClient: MerilnoMestoApiClient,
        private _currentUserAccessor: CurrentUserAccessor,
        private _notyf: Notyf,
        private _loader: FullscreenLoader)
    {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._tipElektronskeVloge = {
            evidentiranjeNaslovaZaPosiljanjePoste: false,
            spremembaLastnikaMerilnoMesto: false,
            soglasjeLastnikaZaEvidentiranjePlacnika: false,
            prijavaPrejemanjeElektronskegaRacuna: false,
            direktnaObremenitev: false,
            penzion: false,
            zlataUra: false
        };
        this._mobileInput = null;
        this._mobileInput2 = null;
        this._merilnaMesta = null;
        this._izbranoMerilnoMesto = null;
        this._poslovniPartner = null;
        this._lastnistvoFileList = new Array<File>();
        this._lastnistvoFileViewList = new Array<any>();
        this._primopredajaFileList = new Array<File>();
        this._primopredajaFileViewList = new Array<any>();
        this._lastnistvoUploadFilesElement = null;
        this._primopredajaUploadFilesElement = null;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            this._getTipElektronskeVloge();
            if (this._tipElektronskeVloge.prijavaPrejemanjeElektronskegaRacuna ||
                this._tipElektronskeVloge.penzion ||
                this._tipElektronskeVloge.direktnaObremenitev ||
                this._tipElektronskeVloge.zlataUra) {
                await this._getMerilnaMesta();
                await this._getPoslovniPartner();
            }
            await this._renderData();
            if ($("#page-loaded")[0] == undefined) return;
            if (this._tipElektronskeVloge.spremembaLastnikaMerilnoMesto) {
                this._renderLastnistvoAttachments();
                this._renderPrimopredajaAttachments();
            }
            this._initForm();
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    }

    private async _renderData(): Promise<void> {
        var user = await this._currentUserAccessor.getUser();
        const viewModel = {
            "tipElektronskeVloge": this._tipElektronskeVloge,
            "user": user,
            "merilnaMesta": this._merilnaMesta,
            "izbranoMerilnoMesto": this._izbranoMerilnoMesto,
            "poslovniPartner": this._poslovniPartner,
            translations: this._translationService.currentTranslations,
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }    

    private async _getMerilnaMesta() {
        var request: UporabnikMerilnoMestoRequest = {
            limit: 0,
            stevilkaStrani: 0,
            saldo: false,
            skupnaPorabaEnergije: false,
            merilnoMesto: "",
            merilnaTocka: ""
        };
        await this._merilnoMestoApiClient.getUporabnikMerilnaMesta(request)
            .then((odgovor: UporabnikMerilnoMestoResponse) => {
                if (odgovor != null && odgovor.merilnaMesta != null && odgovor.merilnaMesta.length > 0) {
                    this._merilnaMesta = odgovor.merilnaMesta;
                    this._izbranoMerilnoMesto = odgovor.merilnaMesta[0];
                }
                else {
                    this.rootElement.text(this._translationService.currentTranslations["YouDoNotHaveAnyMeasuringPoint"]);
                    return;
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["AnErrorOccurredWhileSearchingForMeasuringPoints"]);
            });
    }

    private async _getPoslovniPartner() {
        var request: UporabnikPoslovnePartnerjeRequest = {
            limit: 1,
            stStrani: 0,
            stevilkaPoslovniPartner: this._izbranoMerilnoMesto?.poslovniPartner
        };
        await this._merilnoMestoApiClient.uporabnikPoslovnePartnerje(request)
            .then((odgovor: UporabnikPoslovnePartnerjeResponse) => {
                if (odgovor != null && odgovor.poslovniPartnerData != null && odgovor.poslovniPartnerData.length > 0) {
                    var partner = odgovor.poslovniPartnerData[0];
                    var nameAndSurname = this._getNameAndSurname(partner);
                    partner.ime = nameAndSurname[0];
                    partner.priimek = nameAndSurname[1];
                    this._poslovniPartner = odgovor.poslovniPartnerData[0];
                }
                else {
                    this._notyf.error(odgovor.sporocilo);
                }
            })
            .catch(() => {
                this._notyf.error("Prišlo je do napake pri iskanju poslovnih partnerjev.");
            });
    }

    private async _onDropdownMerilnaMestaChange(ev: Event) {
        ev.preventDefault();
        var value = $("#dropdownMerilnaMesta").val() as string;
        var data = value.split("_");
        if (data[0] == 'true') {
            this._izbranoMerilnoMesto = this._merilnaMesta?.find(a => a.jeElektrika == true && a.stevilkaKontoPogodbe == data[1] && a.merilnoMesto == data[2] && a.merilnaTocka == data[3]);
        }
        else {
            this._izbranoMerilnoMesto = this._merilnaMesta?.find(a => a.jeElektrika == false && a.stevilkaKontoPogodbe == data[1] && a.merilnoMesto == data[2]);
        }
        await this._getPoslovniPartner();
        if (this._tipElektronskeVloge.prijavaPrejemanjeElektronskegaRacuna) {
            var nameAndSurname = this._getNameAndSurname(this._poslovniPartner);
            $("#ime").val(nameAndSurname[0]);
            $("#priimek").val(nameAndSurname[1]);
            $("#naziv").val(nameAndSurname[0] + " " + nameAndSurname[1]);
            if (this._poslovniPartner?.tip == "1") {
                $("#ime-div").show();
                $("#priimek-div").show();
                $("#naziv-div").hide();
            }
            else {
                $("#ime-div").hide();
                $("#priimek-div").hide();
                $("#naziv-div").show();
            }
            $("#ulica").val(this._poslovniPartner?.ulica as string + " " + this._poslovniPartner?.hisnaStevilka as string);
            $("#posta-in-kraj").val(this._poslovniPartner?.postnaStevilka as string + " " + this._poslovniPartner?.kraj as string);
            $("#davcna-stevilka").val(this._poslovniPartner?.davcnaStevilka as string);
            $("#sifra-placnika").val(this._izbranoMerilnoMesto?.poslovniPartner as string);
            $("#stevilka-pogodbenega-racuna").val(this._izbranoMerilnoMesto?.stevilkaKontoPogodbe as string);
            $("#merilno-mesto-stevilka").val(this._izbranoMerilnoMesto?.merilnoMesto as string);
        }
        if (this._tipElektronskeVloge.direktnaObremenitev || this._tipElektronskeVloge.penzion) {
            $("#stevilka-pogodbenega-racuna").val(this._izbranoMerilnoMesto?.stevilkaKontoPogodbe as string);
            $("#merilno-mesto").val(this._izbranoMerilnoMesto?.merilnoMesto as string);
            var nameAndSurname = this._getNameAndSurname(this._poslovniPartner);
            $("#placnik-ime").val(nameAndSurname[0]);
            $("#placnik-priimek").val(nameAndSurname[1]);
            $("#placnik-naziv").val(nameAndSurname[0] + " " + nameAndSurname[1]);
            if (this._poslovniPartner?.tip == "1") {
                $("#placnik-ime-div").show();
                $("#placnik-priimek-div").show();
                $("#placnik-naziv-div").hide();
            }
            else {
                $("#placnik-ime-div").hide();
                $("#placnik-priimek-div").hide();
                $("#placnik-naziv-div").show();
            }
            $("#placnik-ulica").val(this._poslovniPartner?.ulica as string + " " + this._poslovniPartner?.hisnaStevilka as string);
            $("#placnik-posta").val(this._poslovniPartner?.postnaStevilka as string + " " + this._poslovniPartner?.kraj as string);
            $("#placnik-davcna-stevilka").val(this._poslovniPartner?.davcnaStevilka as string);
        }
        if (this._tipElektronskeVloge.zlataUra) {
            var nameAndSurname = this._getNameAndSurname(this._poslovniPartner);
            $("#ime").val(nameAndSurname[0]);
            $("#priimek").val(nameAndSurname[1]);
            $("#naziv").val(nameAndSurname[0] + " " + nameAndSurname[1]);
            if (this._poslovniPartner?.tip == "1") {
                $("#ime-div").show();
                $("#priimek-div").show();
                $("#naziv-div").hide();
            }
            else {
                $("#ime-div").hide();
                $("#priimek-div").hide();
                $("#naziv-div").show();
            }
            $("#merilno-mesto-stevilka").val(this._izbranoMerilnoMesto?.merilnoMesto as string);
            $("#naslov").val(this._poslovniPartner?.ulica as string + " " + this._poslovniPartner?.hisnaStevilka as string);
            $("#posta-in-kraj").val(this._poslovniPartner?.postnaStevilka as string + " " + this._poslovniPartner?.kraj as string);
            $("#davcna-stevilka").val(this._poslovniPartner?.davcnaStevilka as string);
        }
    }

    private _getNameAndSurname(partner: UporabnikPoslovniPartnerResponseData | null | undefined) {
        if (partner != null && partner.naziv != null) {
            if (partner.naziv.trim().indexOf(' ') > -1) {
                var ime = partner.naziv.trim().substring(0, partner.naziv.trim().indexOf(' '));
                var priimek = partner.naziv.trim().substring(partner.naziv.trim().indexOf(' ') + 1);
                return [ime, priimek];
            }
            else {
                return [partner.naziv, ''];
            }
        }
        else {
            return ['', ''];
        }
    }

    private _getTipElektronskeVloge() {
        var url = window.location.href;
        if (url.includes("evidentiranje-naslova-za-posiljanje-poste")) {
            this._tipElektronskeVloge.evidentiranjeNaslovaZaPosiljanjePoste = true;
        }
        if (url.includes("sprememba-lastnika-merilno-mesto")) {
            this._tipElektronskeVloge.spremembaLastnikaMerilnoMesto = true;
        }
        if (url.includes("soglasje-lastnika-za-evidentiranje-placnika")) {
            this._tipElektronskeVloge.soglasjeLastnikaZaEvidentiranjePlacnika = true;
        }
        if (url.includes("prijava-prejemanje-elektronskega-racuna")) {
            this._tipElektronskeVloge.prijavaPrejemanjeElektronskegaRacuna = true;
        }
        if (url.includes("direktna-obremenitev")) {
            this._tipElektronskeVloge.direktnaObremenitev = true;
        }
        if (url.includes("penzion")) {
            this._tipElektronskeVloge.penzion = true;
        } 
        if (url.includes("zlata-ura")) {
            this._tipElektronskeVloge.zlataUra = true;
        } 
    }

    private _initForm(): void {
        this._dodajValidacijskeFunkcije();

        var btnPodpis = $("#btn-podpis-eperes");
        btnPodpis.on("click", (ev: Event) => {
            this._onBtnPodpisPrekoEperesClick(ev);
        });

        var btnPrenesiPdf = $("#btn-prenesi-pdf");
        btnPrenesiPdf.on("click", (ev: Event) => {
            this._onBtnPrenesitePdfClick(ev);
        });

        if (this._tipElektronskeVloge.direktnaObremenitev || this._tipElektronskeVloge.penzion) {
            if (this._poslovniPartner?.tip == "1") {
                $("#placnik-naziv-div").hide();
                $("#placnik-priimek-div").show();
                $("#placnik-ime-div").show();
            }
            else {
                $("#placnik-naziv-div").show();
                $("#placnik-priimek-div").hide();
                $("#placnik-ime-div").hide();
            }
            var dropdownMerilnaMesta = $("#dropdownMerilnaMesta");
            dropdownMerilnaMesta.on("change", (ev: Event) => {
                this._onDropdownMerilnaMestaChange(ev);
            });
            var telefon1 = $("#placnik-telefon");
            if (telefon1 != null && telefon1.length > 0) {
                this._mobileInput = intlTelInput(telefon1[0], {
                    preferredCountries: ["si"],
                    utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js"
                });
            }
            var telefon2 = $("#sklenitelj-telefon");
            if (telefon2 != null && telefon2.length > 0) {
                this._mobileInput2 = intlTelInput(telefon2[0], {
                    preferredCountries: ["si"],
                    utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js"
                });
            }
            var veljavnostSoglasja = new DateRangePicker($('#main').find("#veljavnost-soglasja")[0], {
                showDropdowns: true,
                singleDatePicker: true,
                autoApply: true,
                minDate: moment().add(-20, 'years'),
                maxDate: moment().add(20, 'years')
            });
            console.log(veljavnostSoglasja);
        }

        else if (this._tipElektronskeVloge.zlataUra) {
            if (this._poslovniPartner?.tip == "1") {
                $("#naziv-div").hide();
                $("#priimek-div").show();
                $("#ime-div").show();
            }
            else {
                $("#naziv-div").show();
                $("#priimek-div").hide();
                $("#ime-div").hide();
            }
            var dropdownMerilnaMesta = $("#dropdownMerilnaMesta");
            dropdownMerilnaMesta.on("change", (ev: Event) => {
                this._onDropdownMerilnaMestaChange(ev);
            });
            var telefon1 = $("#telefon");
            if (telefon1 != null && telefon1.length > 0) {
                this._mobileInput = intlTelInput(telefon1[0], {
                    preferredCountries: ["si"],
                    utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js"
                });
            }
            var datum = new DateRangePicker($('#main').find("#datum")[0], {
                showDropdowns: true,
                singleDatePicker: true,
                autoApply: true,
                minDate: moment().add(-20, 'years'),
                maxDate: moment().add(20, 'years')
            });
            console.log(datum); 
        }

        else if (this._tipElektronskeVloge.prijavaPrejemanjeElektronskegaRacuna) {
            if (this._poslovniPartner?.tip == "1") {
                $("#naziv-div").hide();
                $("#priimek-div").show();
                $("#ime-div").show();
            }
            else {
                $("#naziv-div").show();
                $("#priimek-div").hide();
                $("#ime-div").hide();
            }
            var dropdownMerilnaMesta = $("#dropdownMerilnaMesta");
            dropdownMerilnaMesta.on("change", (ev: Event) => {
                this._onDropdownMerilnaMestaChange(ev);
            });
            var telefon = $("#telefon");
            if (telefon != null && telefon.length > 0) {
                this._mobileInput = intlTelInput(telefon[0], {
                    preferredCountries: ["si"],
                    utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js"
                });
            }
            var datum = new DateRangePicker($('#main').find("#datum")[0], {
                showDropdowns: true,
                singleDatePicker: true,
                autoApply: true,
                minDate: moment().add(-20, 'years'),
                maxDate: moment()
            });
            console.log(datum); 
        }

        else if (this._tipElektronskeVloge.soglasjeLastnikaZaEvidentiranjePlacnika) {
            var telefon = $("#lastnik-telefon");
            if (telefon != null && telefon.length > 0) {
                this._mobileInput = intlTelInput(telefon[0], {
                    preferredCountries: ["si"],
                    utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js"
                });
            }
            var telefon2 = $("#placnik-telefon");
            if (telefon2 != null && telefon2.length > 0) {
                this._mobileInput2 = intlTelInput(telefon2[0], {
                    preferredCountries: ["si"],
                    utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js"
                });
            }
            var datumPogodbe = new DateRangePicker($('#main').find("#datum-spremembe-placnika")[0], {
                showDropdowns: true,
                singleDatePicker: true,
                autoApply: true,
                minDate: moment().add(-20, 'years'),
                maxDate: moment()
            });
            console.log(datumPogodbe);
        }

        else if (this._tipElektronskeVloge.evidentiranjeNaslovaZaPosiljanjePoste) {
            var telefon1 = $("#placnik-telefon");
            if (telefon1 != null && telefon1.length > 0) {
                this._mobileInput = intlTelInput(telefon1[0], {
                    preferredCountries: ["si"],
                    utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js"
                });
            }
            var telefon2 = $("#posiljanje-poste-telefon");
            if (telefon2 != null && telefon2.length > 0) {
                this._mobileInput2 = intlTelInput(telefon2[0], {
                    preferredCountries: ["si"],
                    utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js"
                });
            }
        }

        else if (this._tipElektronskeVloge.spremembaLastnikaMerilnoMesto) {
            var telefon1 = $("#stari-lastnik-telefon");
            if (telefon1 != null && telefon1.length > 0) {
                this._mobileInput = intlTelInput(telefon1[0], {
                    preferredCountries: ["si"],
                    utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js"
                });
            }
            var telefon2 = $("#novi-lastnik-telefon");
            if (telefon2 != null && telefon2.length > 0) {
                this._mobileInput2 = intlTelInput(telefon2[0], {
                    preferredCountries: ["si"],
                    utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js"
                });
            }
            $("#dokazilo-primopredaje").on("change", () => {
                if ($("#dokazilo-primopredaje").val() == "Primopredajni zapisnik") {
                    $("#stari-lastnik-naziv-wrap").hide();
                    $("#stari-lastnik-telefon-wrap").hide();
                    $("#stari-lastnik-email-wrap").hide();
                    $("#old-owner-label").hide();
                    $("#old-owner-disclaimer").hide();
                    $("#dokazilo-o-primopredaji-wrap").show();
                }
                else {
                    $("#dokazilo-o-primopredaji-wrap").hide();
                    $("#stari-lastnik-naziv-wrap").show();
                    $("#stari-lastnik-telefon-wrap").show();
                    $("#stari-lastnik-email-wrap").show();
                    $("#signature-disclaimer").show();
                    $("#old-owner-label").show();
                    $("#old-owner-disclaimer").show();
                }
            });
            var datumSpremembaLastnika = new DateRangePicker($('#main').find("#datum-spremembe-lastnika")[0], {
                showDropdowns: true,
                singleDatePicker: true,
                autoApply: true,
                minDate: moment().add(-20, 'years'),
                maxDate: moment()
            });
            console.log(datumSpremembaLastnika);
        }
    }

    private _dodajValidacijskeFunkcije() {
        $.validator.addMethod("emailIsValid", function (email) {
            email = email.toLowerCase();
            if (email.match(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-||_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/)) {
                return true;
            }
            return false;
        });
        $.validator.addMethod("emailNeobveznoIsValid", function (email) {
            if (email == "") {
                return true;
            }
            email = email.toLowerCase();
            if (email.match(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-||_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/)) {
                return true;
            }
            return false;
        });
        $.validator.addMethod("stanjeStevcaIsValid", function () {
            var vt = $("#vt").val() as string;
            var mt = $("#mt").val() as string;
            var et = $("#et").val() as string;
            if (vt == "" && mt == "" && et == "") {
                return false;
            }
            if ((vt != "" && mt == "") || (vt == "" && mt != "")) {
                return false;
            }
            return true;
        });
        $.validator.addMethod("disSmmOrGs1IsValid", function () {
            var disSmm1 = $("#dis-smm-prvi-del").val() as string;
            var disSmm2 = $("#dis-smm-drugi-del").val() as string;
            var gs1 = $("#gs1").val() as string;
            disSmm1 = disSmm1.replace(/\s/g, '');
            disSmm2 = disSmm2.replace(/\s/g, '');
            gs1 = gs1.replace(/\s/g, '');
            if (disSmm1 == "" && disSmm2 == "" && gs1 == "") {
                $("#dis-smm-gs1-error")[0].innerHTML = "Morate vpisati DIS-SMM in / ali GS1.";
                $("#dis-smm-gs1-error-wrap").show();
                return false;
            }
            if ((disSmm1 != "" && disSmm2 == "") || (disSmm1 == "" && disSmm2 != "")) {
                $("#dis-smm-gs1-error")[0].innerHTML = "Morate vpisati DIS-SMM in / ali GS1.";
                $("#dis-smm-gs1-error-wrap").show();
                return false;
            }
            if (gs1 != "") {
                var gs1Zacetek = $("#gs1-zacetek")[0].innerHTML as string;
                if (gs1.length != 18) {
                    $("#dis-smm-gs1-error")[0].innerHTML = "Številka merilnega mesta GS1 se mora začeti s " + gs1Zacetek + " in mora biti dolga 18 znakov.";
                    $("#dis-smm-gs1-error-wrap").show();
                    return false;
                }        
                if (!gs1.startsWith(gs1Zacetek)) {
                    $("#dis-smm-gs1-error")[0].innerHTML = "Številka merilnega mesta GS1 se mora začeti s " + gs1Zacetek + " in mora biti dolga 18 znakov.";
                    $("#dis-smm-gs1-error-wrap").show();
                    return false;
                }
                $("#dis-smm-gs1-error-wrap").hide();
                return true;
            }
            $("#dis-smm-gs1-error-wrap").hide();
            return true;
        });
        $.validator.addMethod("nazivIsValid", function (naziv) {
            naziv = naziv.trim();
            var nazivData = naziv.split(" ");
            if (nazivData.length <= 1) {
                return false;
            }
            return true;
        });
        $.validator.addMethod("gsrnIsValid", function (gsrn) {
            gsrn.replace(/\s/g, '');
            if (gsrn.length != 18 || !gsrn.startsWith("3831115801")) {
                return false;
            }
            return true;
        });
        $.validator.addMethod("davcnaStevilkaIsValid", function (davcna) {
            davcna.replace(/\s/g, '');
            if (davcna.length != 8) {
                return false;
            }
            return true;
        });
        $.validator.addMethod("maticnaStevilkaEmptyIsValid", function (maticna) {
            maticna.replace(/\s/g, '');
            if (maticna == "") {
                return true;
            }
            if (maticna.length != 13) {
                return false;
            }
            return true;
        });
    }

    private _onBtnPodpisPrekoEperesClick(ev: Event): void {
        ev.preventDefault();
        var formElement = $(("#podatki-form"));
        $.validator.unobtrusive.parse(formElement);
        formElement.validate();
        if (!formElement.valid()) {
            this._notyf.error(this._translationService.currentTranslations.CheckAllFields);
            return;
        }
        var vlogeRequest = this._pripraviRequest(formElement);
        this._eperoCall(vlogeRequest);
    }

    private _onBtnPrenesitePdfClick(ev: Event) {
        ev.preventDefault();
        var formElement = $(("#podatki-form"));
        $.validator.unobtrusive.parse(formElement);
        formElement.validate();
        if (!formElement.valid()) {
            this._notyf.error(this._translationService.currentTranslations.CheckAllFields);
            return;
        }
        var vlogeRequest = this._pripraviRequest(formElement);
        this._downloadVlogaPdfCall(vlogeRequest);
    }

    private _pripraviRequest(formElement: any) {
        if (this._tipElektronskeVloge.direktnaObremenitev || this._tipElektronskeVloge.penzion) {
            var formData = new FormData(formElement[0] as HTMLFormElement);
            var datumString = formData.get("veljavnost-soglasja") as string;
            var datumSplit = datumString.split(".");
            var veljavnostSoglasja = new Date(Number(datumSplit[2]), Number(datumSplit[1]) - 1, Number(datumSplit[0]));
            var direktnaObremenitevRequest: VlogeDirektnaObremenitevRequest = {
                jePenzion: this._tipElektronskeVloge.penzion,
                odprtje: ($("#checkbox-odprtje")[0] as HTMLInputElement).checked,
                ukinitev: ($("#checkbox-ukinitev")[0] as HTMLInputElement).checked,
                stevilkaPogodbenegaRacuna: $("#stevilka-pogodbenega-racuna").val() as string,
                merilnoMesto: $("#merilno-mesto").val() as string,
                placnikIme: $("#placnik-ime").val() as string,
                placnikPriimek: $("#placnik-priimek").val() as string,
                placnikUlica: $("#placnik-ulica").val() as string,
                placnikPosta: $("#placnik-posta").val() as string,
                placnikDavcnaStevilka: $("#placnik-davcna-stevilka").val() as string,
                placnikTelefon: this._mobileInput.getNumber((intlTelInputUtils.numberFormat.E164)) as string,
                placnikEmail: $("#placnik-email").val() as string,
                skleniteljIme: $("#sklenitelj-ime").val() as string,
                skleniteljPriimek: $("#sklenitelj-priimek").val() as string,
                skleniteljUlica: $("#sklenitelj-ulica").val() as string,
                skleniteljPosta: $("#sklenitelj-posta").val() as string,
                skleniteljDavcnaStevilka: $("#sklenitelj-davcna-stevilka").val() as string,
                skleniteljTelefon: this._mobileInput2.getNumber((intlTelInputUtils.numberFormat.E164)) as string,
                skleniteljEmail: $("#sklenitelj-email").val() as string,
                bankaNaziv: $("#banka-naziv").val() as string,
                iban: $("#iban").val() as string,
                swift: $("#swift").val() as string,
                soglasje: ($("#checkbox-soglasje")[0] as HTMLInputElement).checked,
                krajInDatum: $("#kraj-in-datum").val() as string,
                veljavnostSoglasja: veljavnostSoglasja
            }
            return direktnaObremenitevRequest;
        }

        else if (this._tipElektronskeVloge.zlataUra) {
            var formData = new FormData(formElement[0] as HTMLFormElement);
            var datumString = formData.get("datum") as string;
            var datumSplit = datumString.split(".");
            var datum = new Date(Number(datumSplit[2]), Number(datumSplit[1]) - 1, Number(datumSplit[0]));
            var zlataUraRequest: VlogeZlataUraRequest = {
                ime: $("#ime").val() as string,
                priimek: $("#priimek").val() as string,
                naslov: $("#naslov").val() as string,
                postaInkraj: $("#posta-in-kraj").val() as string,
                davcnaStevilka: $("#davcna-stevilka").val() as string,
                email: $("#email").val() as string,
                analitika: ($("#checkbox-prejem-email")[0] as HTMLInputElement).checked,
                telefon: this._mobileInput.getNumber((intlTelInputUtils.numberFormat.E164)) as string,
                stevilkaMerilnegaMesta: $("#merilno-mesto-stevilka").val() as string,
                kraj: $("#kraj").val() as string,
                datum: datum
            };
            return zlataUraRequest;
        }

        else if (this._tipElektronskeVloge.prijavaPrejemanjeElektronskegaRacuna) {
            var formData = new FormData(formElement[0] as HTMLFormElement);
            var datumString = formData.get("datum") as string;
            var datumSplit = datumString.split(".");
            var datum = new Date(Number(datumSplit[2]), Number(datumSplit[1]) - 1, Number(datumSplit[0]));
            var prijavaPrejemaElektronskegaRacunaRequest: VlogePrijavaPrejemaElektronskegaRacunaRequest = {
                ime: $("#ime").val() as string,
                priimek: $("#priimek").val() as string,
                ulica: $("#ulica").val() as string,
                kraj: $("#posta-in-kraj").val() as string,
                davcnaStevilkaPlacnika: $("#davcna-stevilka").val() as string,
                sifraPlacnika: $("#sifra-placnika").val() as string,
                stevilkaPogodbenegaRacuna: $("#stevilka-pogodbenega-racuna").val() as string,
                stevilkaMerilnegaMesta: $("#merilno-mesto-stevilka").val() as string,
                zelimPrejematiElektronskiRacun: ($("#checkbox-prejem-elektronski-racun")[0] as HTMLInputElement).checked,
                emailPrejemRacuna: $("#prejemanje-email").val() as string,
                telefon: this._mobileInput.getNumber((intlTelInputUtils.numberFormat.E164)) as string,
                email: $("#email").val() as string,
                datum: datum
            };
            return prijavaPrejemaElektronskegaRacunaRequest;
        }

        else if (this._tipElektronskeVloge.soglasjeLastnikaZaEvidentiranjePlacnika) {
            var formData = new FormData(formElement[0] as HTMLFormElement);
            var datumSpremembePlacnikaString = formData.get("datum-spremembe-placnika") as string;
            var datumSpremembePlacnikaSplit = datumSpremembePlacnikaString.split(".");
            var datumSpremembePlacnika = new Date(Number(datumSpremembePlacnikaSplit[2]), Number(datumSpremembePlacnikaSplit[1]) - 1, Number(datumSpremembePlacnikaSplit[0]));
            var vt = $("#vt").val() as string;
            var mt = $("#mt").val() as string;
            var et = $("#et").val() as string;
            var soglasjeLastnikaZaEvidentiranjePlacnikaRequest: VlogeSoglasjeLastnikaZaEvidentiranjePlacnikaRequest = {
                soglasje: ($("#soglasje")[0] as HTMLInputElement).checked,
                dis_smm_prvi_del: $("#dis-smm-prvi-del").val() as string,
                dis_smm_drugi_del: $("#dis-smm-drugi-del").val() as string,
                gs1: $("#gs1").val() as string,
                izvajalecNalog: $("#izvajalec-nalog").val() as string,
                merilnoMestoNaziv: $("#merilno-mesto-naziv").val() as string,
                merilnoMestoNaslov: $("#merilno-mesto-naslov").val() as string,
                stevecStevilka: $("#stevilka-stevca").val() as string,
                datumSpremembePlacnika: datumSpremembePlacnika,
                vt: vt != "" ? Number(vt) : null,
                mt: mt != "" ? Number(mt) : null,
                et: et != "" ? Number(et) : null,
                lastnikNaziv: $("#lastnik-naziv").val() as string,
                lastnikNaslov: $("#lastnik-naslov").val() as string,
                lastnikDavcnaStevilka: $("#lastnik-davcna-stevilka").val() as string,
                lastnikMaticnaStevilka: $("#lastnik-maticna-stevilka").val() as string,
                lastnikTelefon: this._mobileInput.getNumber((intlTelInputUtils.numberFormat.E164)) as string,
                lastnikEmail: $("#lastnik-email").val() as string,
                placnikMerilnaTockaGsrn: $("#placnik-merilna-tocka-gsrn").val() as string,
                placnikNaziv: $("#placnik-naziv").val() as string,
                placnikKraj: $("#placnik-kraj").val() as string,
                placnikPosta: $("#placnik-posta").val() as string,
                placnikDavcnaStevilka: $("#placnik-davcna-stevilka").val() as string,
                placnikMaticnaStevilka: $("#placnik-maticna-stevilka").val() as string,
                placnikTelefon: this._mobileInput2.getNumber((intlTelInputUtils.numberFormat.E164)) as string,
                placnikEmail: $("#placnik-email").val() as string,
                krajInDatum: $("#kraj-in-datum").val() as string,
            };
            return soglasjeLastnikaZaEvidentiranjePlacnikaRequest;
        }

        else if (this._tipElektronskeVloge.evidentiranjeNaslovaZaPosiljanjePoste) {
            var evidentiranjeNaslovaZaPosiljanjePosteRequest: VlogeEvidentiranjeNaslovaZaPosiljanjePosteRequest = {
                dis_smm_prvi_del: $("#dis-smm-prvi-del").val() as string,
                dis_smm_drugi_del: $("#dis-smm-drugi-del").val() as string,
                gs1: $("#gs1").val() as string,
                izvajalecNalog: $("#izvajalec-nalog").val() as string,
                merilnoMestoNaziv: $("#merilno-mesto-naziv").val() as string,
                merilnoMestoNaslov: $("#merilno-mesto-naslov").val() as string,
                placnikGsrn: $("#placnik-gsrn").val() as string,
                placnikNaziv: $("#placnik-naziv").val() as string,
                placnikNaslov: $("#placnik-naslov").val() as string,
                placnikDavcnaStevilka: $("#placnik-davcna-stevilka").val() as string,
                placnikMaticnaStevilka: $("#placnik-maticna-stevilka").val() as string,
                placnikTelefon: this._mobileInput.getNumber((intlTelInputUtils.numberFormat.E164)) as string,
                placnikEmail: $("#placnik-email").val() as string,
                posiljanjePosteNaziv: $("#posiljanje-poste-naziv").val() as string,
                posiljanjePosteKraj: $("#posiljanje-poste-kraj").val() as string,
                posiljanjePostePosta: $("#posiljanje-poste-posta").val() as string,
                posiljanjePosteDavcnaStevilka: $("#posiljanje-poste-davcna-stevilka").val() as string,
                posiljanjePosteMaticnaStevilka: $("#posiljanje-poste-maticna-stevilka").val() as string,
                posiljanjePosteTelefon: this._mobileInput2.getNumber((intlTelInputUtils.numberFormat.E164)) as string,
                posiljanjePosteEmail: $("#posiljanje-poste-email").val() as string,
                krajInDatum: $("#kraj-in-datum").val() as string,
            };
            return evidentiranjeNaslovaZaPosiljanjePosteRequest;
        }

        else if (this._tipElektronskeVloge.spremembaLastnikaMerilnoMesto) {
            var valid = true;
            if (this._lastnistvoFileList == null || this._lastnistvoFileList.length < 1) {
                $("#dokazilo-o-lastnistvu-error").show();
                valid = false;
            }
            if ($("#dokazilo-primopredaje").val() == "Primopredajni zapisnik" && (this._primopredajaFileList == null || this._primopredajaFileList.length < 1)) {
                $("#dokazilo-o-primopredaji-error").show();
                valid = false;
            }
            if (!valid) {
                this._notyf.error(this._translationService.currentTranslations.CheckAllFields);
                return;
            }
            var datumSpremembaLastnikaString = $("#datum-spremembe-lastnika").val() as string;
            var datumSpremembaLastnikaSplit = datumSpremembaLastnikaString.split(".");
            var datumSpremembaLastnika = new Date(Number(datumSpremembaLastnikaSplit[2]), Number(datumSpremembaLastnikaSplit[1]) - 1, Number(datumSpremembaLastnikaSplit[0]));
            var formData = new FormData();
            var vt = $("#vt").val() as string;
            var mt = $("#mt").val() as string;
            var et = $("#et").val() as string;
            formData.append("soglasje", ($("#soglasje")[0] as HTMLInputElement).checked.toString());
            formData.append("dis_smm_prvi_del", $("#dis-smm-prvi-del").val() as string);
            formData.append("dis_smm_drugi_del", $("#dis-smm-drugi-del").val() as string);
            formData.append("gs1", $("#gs1").val() as string);
            formData.append("izvajalecNalog", $("#izvajalec-nalog").val() as string);
            formData.append("merilnoMestoNaziv", $("#merilno-mesto-naziv").val() as string);
            formData.append("merilnoMestoNaslov", $("#merilno-mesto-naslov").val() as string);
            formData.append("stevecStevilka", $("#stevilka-stevca").val() as string);
            formData.append("datumSpremembeLastnika", datumSpremembaLastnika.toISOString());
            formData.append("vt", vt);
            formData.append("mt", mt);
            formData.append("et", et);
            formData.append("stariLastnikNaziv", $("#stari-lastnik-naziv").val() as string);
            formData.append("stariLastnikTelefon", this._mobileInput.getNumber((intlTelInputUtils.numberFormat.E164)) as string);
            formData.append("stariLastnikEmail", $("#stari-lastnik-email").val() as string);
            formData.append("noviLastnikNaziv", $("#novi-lastnik-naziv").val() as string);
            formData.append("noviLastnikKraj", $("#novi-lastnik-kraj").val() as string);
            formData.append("noviLastnikPosta", $("#novi-lastnik-posta").val() as string);
            formData.append("noviLastnikDavcnaStevilka", $("#novi-lastnik-davcna-stevilka").val() as string);
            formData.append("noviLastnikMaticnaStevilka", $("#novi-lastnik-maticna-stevilka").val() as string);
            formData.append("noviLastnikTelefon", this._mobileInput2.getNumber((intlTelInputUtils.numberFormat.E164)) as string);
            formData.append("noviLastnikEmail", $("#novi-lastnik-email").val() as string);
            formData.append("dokaziloLastnistvo", $("#dokazilo-lastnistva").val() as string);
            formData.append("dokaziloOPrimopredaji", $("#dokazilo-primopredaje").val() as string);
            formData.append("krajInDatum", $("#kraj-in-datum").val() as string);

            for (var i = 0; i < this._lastnistvoFileList.length; i++) {
                formData.append("priloge", this._lastnistvoFileList[i]);
            }
            if ($("#dokazilo-primopredaje").val() == "Primopredajni zapisnik") {
                for (var i = 0; i < this._primopredajaFileList.length; i++) {
                    formData.append("priloge", this._primopredajaFileList[i]);
                }
            }
            return formData;
        }
    }

    private async _downloadVlogaPdfCall(vlogeRequest: any) {
        var translations = this._translationService.currentTranslations;
        var downloadingNotyf = this._notyf.open({
            type: "info",
            message: translations.Downloading,
            duration: 50000
        });

        if (this._tipElektronskeVloge.direktnaObremenitev || this._tipElektronskeVloge.penzion) {
            await this._merilnoMestoApiClient.vlogeDirektnaObremenitevPdfDownload(vlogeRequest)
                .then((response) => {
                    const downloadUrl = response.downloadUrl;
                    if (downloadUrl != 'undefined') {
                        const fileLink = document.createElement('a');
                        fileLink.href = downloadUrl;
                        fileLink.click();
                    }
                })
                .catch(() => {
                    this._notyf.error(translations.ErrorWhileDownloadingFile);
                }).finally(() => {
                    this._notyf.dismiss(downloadingNotyf);
                });
        }

        else if (this._tipElektronskeVloge.zlataUra) {
            await this._merilnoMestoApiClient.vlogeZlataUraPdfDownload(vlogeRequest)
                .then((response) => {
                    const downloadUrl = response.downloadUrl;
                    if (downloadUrl != 'undefined') {
                        const fileLink = document.createElement('a');
                        fileLink.href = downloadUrl;
                        fileLink.click();
                    }
                })
                .catch(() => {
                    this._notyf.error(translations.ErrorWhileDownloadingFile);
                }).finally(() => {
                    this._notyf.dismiss(downloadingNotyf);
                });
        }

        else if (this._tipElektronskeVloge.prijavaPrejemanjeElektronskegaRacuna) {
            await this._merilnoMestoApiClient.vlogePrijavaPrejemanjeElektronskegaRacunaDownload(vlogeRequest)
                .then((response) => {
                    const downloadUrl = response.downloadUrl;
                    if (downloadUrl != 'undefined') {
                        const fileLink = document.createElement('a');
                        fileLink.href = downloadUrl;
                        fileLink.click();
                    }
                })
                .catch(() => {
                    this._notyf.error(translations.ErrorWhileDownloadingFile);
                }).finally(() => {
                    this._notyf.dismiss(downloadingNotyf);
                });
        }

        else if (this._tipElektronskeVloge.soglasjeLastnikaZaEvidentiranjePlacnika) {
            await this._merilnoMestoApiClient.vlogeSoglasjeLastnikaZaEvidentiranjePlacnikaDownload(vlogeRequest)
                .then((response) => {
                    const downloadUrl = response.downloadUrl;
                    if (downloadUrl != 'undefined') {
                        const fileLink = document.createElement('a');
                        fileLink.href = downloadUrl;
                        fileLink.click();
                    }
                })
                .catch(() => {
                    this._notyf.error(translations.ErrorWhileDownloadingFile);
                }).finally(() => {
                    this._notyf.dismiss(downloadingNotyf);
                });
        }

        else if (this._tipElektronskeVloge.evidentiranjeNaslovaZaPosiljanjePoste) {
            await this._merilnoMestoApiClient.vlogeEvidentiranjeNaslovaZaPosiljanjePosteDownload(vlogeRequest)
                .then((response) => {
                    const downloadUrl = response.downloadUrl;
                    if (downloadUrl != 'undefined') {
                        const fileLink = document.createElement('a');
                        fileLink.href = downloadUrl;
                        fileLink.click();
                    }
                })
                .catch(() => {
                    this._notyf.error(translations.ErrorWhileDownloadingFile);
                }).finally(() => {
                    this._notyf.dismiss(downloadingNotyf);
                });
        }


        else if (this._tipElektronskeVloge.spremembaLastnikaMerilnoMesto) {
            await this._merilnoMestoApiClient.vlogeSpremembaLastnikaMerilnoMestoDownload(vlogeRequest)
                .then((response) => {
                    const downloadUrl = response.downloadUrl;
                    if (downloadUrl != 'undefined') {
                        const fileLink = document.createElement('a');
                        fileLink.href = downloadUrl;
                        fileLink.click();
                    }
                })
                .catch(() => {
                    this._notyf.error(translations.ErrorWhileDownloadingFile);
                }).finally(() => {
                    this._notyf.dismiss(downloadingNotyf);
                });
        }
    }

    private async _eperoCall(vlogeRequest: any) {
        if (this._tipElektronskeVloge.direktnaObremenitev || this._tipElektronskeVloge.penzion) {
            this._loader.show();
            await this._merilnoMestoApiClient.eperoDirektnaObremenitev(vlogeRequest)
                .then((odgovor: any) => {
                    console.log(odgovor);
                    this._loader.hide();
                    window.location.href = "#/vloge/zahvalna-stran/";
                })
                .catch((reason) => {
                    console.log(reason);
                    this._loader.hide();
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                });
        }

        else if (this._tipElektronskeVloge.zlataUra) {
            this._loader.show();
            await this._merilnoMestoApiClient.eperoZlataUra(vlogeRequest)
                .then((odgovor: any) => {
                    console.log(odgovor);
                    this._loader.hide();
                    window.location.href = "#/vloge/zahvalna-stran/";
                })
                .catch((reason) => {
                    console.log(reason);
                    this._loader.hide();
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                });
        }

        else if (this._tipElektronskeVloge.prijavaPrejemanjeElektronskegaRacuna) {          
            this._loader.show();
            await this._merilnoMestoApiClient.eperoPrijavaPrejemaElektronskegaRacuna(vlogeRequest)
                .then((odgovor: any) => {
                    console.log(odgovor);
                    this._loader.hide();
                    window.location.href = "#/vloge/zahvalna-stran/";
                })
                .catch((reason) => {
                    console.log(reason);
                    this._loader.hide();
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                });
        }

        else if (this._tipElektronskeVloge.soglasjeLastnikaZaEvidentiranjePlacnika) {
            this._loader.show();
            await this._merilnoMestoApiClient.eperoSoglasjeLastnikaZaEvidentiranjePlacnika(vlogeRequest)
                .then((odgovor: any) => {
                    console.log(odgovor);
                    this._loader.hide();
                    window.location.href = "#/vloge/zahvalna-stran/";          
                })
                .catch((reason) => {
                    console.log(reason);
                    this._loader.hide();
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                });
        }

        else if (this._tipElektronskeVloge.evidentiranjeNaslovaZaPosiljanjePoste) {
            this._loader.show();
            await this._merilnoMestoApiClient.eperoEvidentiranjeNaslovaZaPosiljanjePoste(vlogeRequest)
                .then((odgovor: any) => {
                    console.log(odgovor);
                    this._loader.hide();
                    window.location.href = "#/vloge/zahvalna-stran/";
                })
                .catch((reason) => {
                    console.log(reason);
                    this._loader.hide();
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                });
        }

        else if (this._tipElektronskeVloge.spremembaLastnikaMerilnoMesto) {         
            this._loader.show();
            await this._merilnoMestoApiClient.eperoSpremembaLastnikaMerilnoMesto(vlogeRequest)
                .then((odgovor: any) => {
                    console.log(odgovor);
                    this._loader.hide();
                    window.location.href = "#/vloge/zahvalna-stran/";
                })
                .catch((reason) => {
                    console.log(reason);
                    this._loader.hide();
                    this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                });
        }
    }

    public _onLastnistvoUploadChange(ev: Event) {
        var th = this;
        const target = ev.target as HTMLInputElement;
        let list: FileList | null = target.files;
        if (list) {
            $(list).each(function (_index, element) {
                th._lastnistvoFileList.push(element);
            });
            $("#dokazilo-o-lastnistvu-error").hide();
        }
        //Datoteke prekopiramo v view model, zaradi indexa
        this._lastnistvoFileViewList = [];
        for (let i in this._lastnistvoFileList) {
            this._lastnistvoFileViewList.push({ 'index': i, 'name': this._lastnistvoFileList[i].name });
        }
        this._renderLastnistvoAttachments();
    }

    private _renderLastnistvoAttachments(): void {
        // Build a view model from the API data
        const viewModel = {
            "imageUrlDelete": "../img/icon-delete.svg",
            "files": this._lastnistvoFileViewList,
            "translations": this._translationService.currentTranslations,
            "id": "dokazilo-o-lastnistvu",
            "name": "",
            "accept": "image/jpeg,image/gif,image/png,application/pdf"
        } as any;
        // Construct a template
        const htmlTemplate = priponkeTemplate;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#lastnistvo-upload-widget').html(html);

        this._initLastnistvoUploadField();
        this._initDeleteLastnistvoAttachment();
    }

    public _initDeleteLastnistvoAttachment() {
        var self = this;
        $(".delete-attachment-btn").each(function () {
            var element = this as HTMLInputElement;
            element.addEventListener("click", (ev: Event) => self._onDeleteLastnistvoAttachment(ev));
        });
    }

    public _onDeleteLastnistvoAttachment(ev: Event) {
        const target = ev.currentTarget as HTMLInputElement;
        var indexString = target.getAttribute("data-index");
        if (!indexString) {
            return;
        }
        var index = parseInt(indexString);
        if (index || index == 0) {
            this._lastnistvoFileList.splice(index, 1);
        }
        this._lastnistvoFileViewList = [];
        for (let idx in this._lastnistvoFileList) {
            this._lastnistvoFileViewList.push({ 'index': idx, 'name': this._lastnistvoFileList[idx].name });
        }
        this._renderLastnistvoAttachments();
    }

    public _initLastnistvoUploadField() {
        this._lastnistvoUploadFilesElement = document.getElementById("dokazilo-o-lastnistvu") as HTMLInputElement;
        this._lastnistvoUploadFilesElement.addEventListener("change", (ev: Event) => this._onLastnistvoUploadChange(ev));
    }

    public _onPrimopredajaUploadChange(ev: Event) {
        var th = this;
        const target = ev.target as HTMLInputElement;
        let list: FileList | null = target.files;
        if (list) {
            $(list).each(function (_index, element) {
                th._primopredajaFileList.push(element);
            });
            $("#dokazilo-o-primopredaji-error").hide();     
        }
        //Datoteke prekopiramo v view model, zaradi indexa
        this._primopredajaFileViewList = [];
        for (let i in this._primopredajaFileList) {
            this._primopredajaFileViewList.push({ 'index': i, 'name': this._primopredajaFileList[i].name });
        }
        this._renderPrimopredajaAttachments();
    }

    private _renderPrimopredajaAttachments(): void {
        // Build a view model from the API data
        const viewModel = {
            "imageUrlDelete": "../img/icon-delete.svg",
            "files": this._primopredajaFileViewList,
            "translations": this._translationService.currentTranslations,
            "id": "dokazilo-o-primopredaji",
            "name": "",
            "accept": "image/jpeg,image/gif,image/png,application/pdf"
        } as any;
        // Construct a template
        const htmlTemplate = priponkeTemplate;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#primopredaja-upload-widget').html(html);

        this._initPrimopredajaUploadField();
        this._initDeletePrimopredajaAttachment();
    }

    //inicializira gumb za brisanje priponk
    public _initDeletePrimopredajaAttachment() {
        var self = this;
        $(".delete-attachment-btn").each(function () {
            var element = this as HTMLInputElement;
            element.addEventListener("click", (ev: Event) => self._onDeletePrimopredajaAttachment(ev));
        });
    }

    //event za brisanje priponk
    public _onDeletePrimopredajaAttachment(ev: Event) {
        const target = ev.currentTarget as HTMLInputElement;
        var indexString = target.getAttribute("data-index");
        if (!indexString) {
            return;
        }
        var index = parseInt(indexString);
        if (index || index == 0) {
            this._primopredajaFileList.splice(index, 1);
        }
        this._primopredajaFileViewList = [];
        for (let idx in this._primopredajaFileList) {
            this._primopredajaFileViewList.push({ 'index': idx, 'name': this._primopredajaFileList[idx].name });
        }
        this._renderPrimopredajaAttachments();
    }

    public _initPrimopredajaUploadField() {
        this._primopredajaUploadFilesElement = document.getElementById("dokazilo-o-primopredaji") as HTMLInputElement;
        this._primopredajaUploadFilesElement.addEventListener("change", (ev: Event) => this._onPrimopredajaUploadChange(ev));
    }
}
