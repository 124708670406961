import { inject, injectable, interfaces } from "inversify";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { MainLayout } from "../../views/main-layout";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import * as Mustache from "mustache";
import template from "../pages/admin-uporabniki-pregled-page.html";
import { TranslationService } from "../translation-service";
import { Notyf } from "notyf";
import { FullscreenLoader } from "../../views/fullscreen-loader";
import { AdminUporabnikiPregledView } from "../../views/admin-uporabniki-pregled-view";
import { MerilnoMestoApiClient } from "../clients/merilno-mesto-api-client";
import { AdminApiClient } from "../clients/admin-api-client";
import { CurrentUserAccessor } from "../utilities/current-user-accessor";

@injectable()
export class AdminUporabnikiPregledPage extends PageBase {
    public name = "AdminUporabnikiPregledPage";
    private _router: Router;
    private _translationService: TranslationService;
    private _adminApiClient: AdminApiClient;
    private _merilnoMestoApiClient: MerilnoMestoApiClient;
    private _notyf: Notyf;
    private _adminUporabnikiPregledView: AdminUporabnikiPregledView;
    private _adminUporabnikiPregledElement: JQuery<HTMLElement>;
    private _loader: FullscreenLoader;
    private _currentUserAccessor: CurrentUserAccessor;

    public constructor(@inject("Provider<MainLayout>") private _mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("Router") router: Router,
        @inject("TranslationService") translationService: TranslationService,
        @inject("AdminApiClient") adminApiClient: AdminApiClient,
        @inject("MerilnoMestoApiClient") merilnoMestoApiClient: MerilnoMestoApiClient,
        @inject("CurrentUserAccessor") currentUserAccessor: CurrentUserAccessor,
        @inject("Notyf") notyf: Notyf,
        @inject("FullscreenLoader") loader: FullscreenLoader) {
        super();

        this._router = router;
        this._translationService = translationService;
        this._adminApiClient = adminApiClient;
        this._merilnoMestoApiClient = merilnoMestoApiClient;
        this._notyf = notyf;
        this._loader = loader;
        this._currentUserAccessor = currentUserAccessor;

        const html = Mustache.render(template, {});
        $('#main').html(html);

        this._adminUporabnikiPregledElement = $('#main').find("#admin-uporabniki-pregled");
        this._adminUporabnikiPregledView = new AdminUporabnikiPregledView(this._adminUporabnikiPregledElement,
            this._translationService, this._adminApiClient, this._merilnoMestoApiClient, this._notyf);
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        if (!url.pathname.startsWith("/admin/uporabniki-pregled")) {
            return true;
        }
        var mainLayout = await this._mainLayoutProvider() as MainLayout;
        try {
            var user = await this._currentUserAccessor.getUser();
            var adminRole = user.roles.find(a => a.role1 == "ROLE_ADMINISTRATOR");
            if (adminRole) {
                mainLayout.hideFooter();
                this._loader.show();
                await this._adminUporabnikiPregledView.load();
                window.scrollTo(0, 0);
                $(".title-strani")[0].innerHTML = this._translationService.currentTranslations["UsersHouseholdAndBusinessCustomers"];
            } 
        } catch (e) {
            mainLayout.errorView.report(e);
        }
        finally {
            this._loader.hide();
            mainLayout.showFooter();
        }
        return false;
    }
}