import { injectable } from "inversify";
import * as Mustache from "mustache";
import { AkcijeApiClient } from "../ts/clients/akcije-api-client";
import { AnketniVprasalnikDodajOdgovorRequest } from "../ts/models/akcije-anketa-dodaj-odgovor";
import { TranslationService } from "../ts/translation-service";
import { FullscreenLoader } from "./fullscreen-loader";
import template from "./akcije-anketni-vprasalnik-view.html";
import { Notyf } from "notyf";

@injectable()
export class AkcijeAnketniVprasalnikView {
    private rootElement: JQuery<HTMLElement>;
    private _translationService: TranslationService;
    private _akcijeApiClient: AkcijeApiClient;
    private _btnPosljiOdgovore: JQuery<HTMLElement> | null;

    constructor(rootElement: JQuery<HTMLElement>,
        translationService: TranslationService,
        akcijeApiClient: AkcijeApiClient,
        private _notyf: Notyf,
        private _loader: FullscreenLoader)
    {
        this.rootElement = rootElement;
        this._translationService = translationService;
        this._akcijeApiClient = akcijeApiClient;
        this._btnPosljiOdgovore = null;
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {
            var translations = this._translationService.currentTranslations;
            this._renderData(translations);
            if ($("#page-loaded")[0] == undefined) return;
            this._initForm();
        }
        catch (e) {
            this.rootElement.text(this._translationService.currentTranslations["LoadingError"]);
            throw e;
        }
    } 

    private _renderData(translations: any): void {
        const viewModel = {
            translations: translations
        } as any;

        const html = Mustache.render(template, viewModel);
        this.rootElement.html(html);
    }

    private _initForm() {
        this._btnPosljiOdgovore = $("#btnPosljiOdgovore");
        this._btnPosljiOdgovore.on("click", (ev: Event) => this._onBtnPosljiOdgovoreClick(ev));
    }

    private async _onBtnPosljiOdgovoreClick(ev: Event) {
        console.debug(ev);
        ev.preventDefault();
        this._loader.show();
        var formCheckInputs = $(".form-check-input");
        var ocena = 0;
        for (let i = 0; i < formCheckInputs.length; i++) {
            if ((formCheckInputs[i] as HTMLInputElement).checked) {
                ocena = i;
                break;
            }
        }
        var dodajOdgovorRequest: AnketniVprasalnikDodajOdgovorRequest = {
            enostavnaUporaba: ($(".enostavnaUporaba")[0] as HTMLInputElement).value,
            zadovoljniUporaba: ($(".zadovoljniUporaba")[0] as HTMLInputElement).value,
            ocena: ocena
        };
        await this._akcijeApiClient.addAnketaOdgovor(dodajOdgovorRequest)
            .then((odgovor: any) => {
                console.log(odgovor);
                // TODO: prebrati odgovora
                this._loader.hide();
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
                this._loader.hide();
            });
    }
}